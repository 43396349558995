import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { tdrawConfig } from '../../../tdraw.config';
import mapboxgl from 'mapbox-gl';
import { LocationSearchResult } from 'src/app/tdraw/models/location-search-result.model';
import { NewGisVersionData } from 'src/app/tdraw/models/new-gis-version-data.model';
import { MapboxStyleSwitcherControl } from 'mapbox-gl-style-switcher';
import { OrganizationStoreService } from 'src/app/organization/store';
import { Subscription } from 'rxjs';
import { Unsubscribe } from 'src/app/core/decorators';
import { OrganizationDto } from 'src/api';

@Unsubscribe()
@Component({
  selector: 'tdraw-create-gis-version-modal',
  templateUrl: './create-gis-version-modal.component.html',
  styleUrls: ['./create-gis-version-modal.component.scss'],
})
export class CreateGisVersionModalComponent implements OnInit, OnDestroy {
  private organizationSubscription: Subscription;
  organization: OrganizationDto;
  newMap: {
    name: string;
  };
  map: mapboxgl.Map;

  constructor(
    private readonly organizationStoreService: OrganizationStoreService,
    public dialogRef: MatDialogRef<CreateGisVersionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.newMap = {
      name: '',
    };
  }

  ngOnInit(): void {
    this.organizationSubscription = this.organizationStoreService
      .getCurrentOrganization()
      .subscribe(async (organization) => {
        if (!organization) {
          return;
        }
        this.organization = organization as OrganizationDto;
        this.initMap();
      });
  }

  ngOnDestroy(): void {
    this.map.remove();
  }

  onSubmit() {
    const center = this.map.getCenter();
    const zoom = this.map.getZoom();
    this.dialogRef.close(
      new NewGisVersionData({
        name: this.newMap.name,
        center,
        zoom,
      })
    );
  }

  onClose() {
    this.dialogRef.close();
  }

  onLocationSelected(result: LocationSearchResult) {
    const center = result.coordinates;
    const zoom = 13;
    this.map.flyTo({ center, zoom });
  }

  private initMap() {
    let location: mapboxgl.LngLatLike;
    if (this.organization?.location?.lat && this.organization.location?.lng) {
      location = this.organization.location;
    }
    this.map = new mapboxgl.Map({
      accessToken: tdrawConfig.mapAccessToken,
      container: 'create-version-map',
      style: tdrawConfig.mapStyle,
      center: location ?? tdrawConfig.defaultMapCenter,
      zoom: 13,
    });

    this.map.addControl(new MapboxStyleSwitcherControl());
  }
}
