import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-media-picture',
  templateUrl: './media-picture.component.html',
  styleUrls: ['./media-picture.component.scss'],
})
export class MediaPictureComponent {
  @Input()
  mediaUrl: string;
}
