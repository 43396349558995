<app-mid-right-panel #panelNewList="appMidRightPanel" [title]="'DISTRIBUTION_LIST_ACTIONS.ADD' | translate">
  <app-mid-right-panel-content>
    <div class="m-0 px-4 w-100">
      <app-mid-input
        required
        size="wide"
        type="text"
        #listName="appMidInput"
        name="listName"
        [label]="'CREATE_DISTRIBUTION_LIST.NAME' | translate"
        [placeholder]="'CREATE_DISTRIBUTION_LIST.ENTER_NAME' | translate"
        [(value)]="name"
      >
        <span *ngIf="!listName.pristine && listName.invalid" class="warning-input-text">{{
          'COMMON_REQUIRED_FIELD' | translate
        }}</span>
      </app-mid-input>
    </div>
  </app-mid-right-panel-content>
  <app-mid-right-panel-footer>
    <div class="row m-0 py-3 px-4">
      <app-mid-button
        type="primary"
        icon="fas fa-check"
        (action)="createList()"
        class="pr-2"
        [disabled]="isEmpty(this.name)"
      >
        {{ 'ADD' | translate }}</app-mid-button
      >
      <app-mid-button class="pr-2" type="secondary" (action)="panelNewList.close()">{{
        'DIALOGS.COMMON.CANCEL' | translate
      }}</app-mid-button>
    </div>
  </app-mid-right-panel-footer>
</app-mid-right-panel>
