import { isFunction } from 'lodash';
import { Subscription } from 'rxjs';
import { Assert } from 'src/app/shared';

export function Unsubscribe() {
  return (constructor: Function) => {
    const originalNgOnDestroy: Function = constructor.prototype['ngOnDestroy'];
    // Lifecycle methods must be declared at compile time, otherwise AOT will ignore them.
    Assert.isNotNull(originalNgOnDestroy, constructor.name, '{0} must implement OnDestroy.');

    const ngOnDestroy = function () {
      for (const propName in this) {
        if (!this.hasOwnProperty(propName)) {
          continue;
        }

        const property = this[propName] as Subscription;
        const isSubscription = property && property.unsubscribe && isFunction(property.unsubscribe);
        if (!isSubscription) {
          continue;
        }

        try {
          property.unsubscribe();
        } catch (error) {
          console.warn(`Failed to unsubscribe from ${constructor.name}.${propName}.`, error);
        }
      }

      originalNgOnDestroy.apply(this);
    };

    constructor.prototype['ngOnDestroy'] = ngOnDestroy;
  };
}
