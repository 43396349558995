import { Injectable } from '@angular/core';
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class MonitoringService {
  private readonly appInsights: ApplicationInsights = new ApplicationInsights({
    config: { instrumentationKey: environment.appInsightsKey },
  });

  constructor() {
    this.appInsights.loadAppInsights();
    this.appInsights.addTelemetryInitializer((item) => {
      item.tags['ai.cloud.role'] = 'midgard.ui';
    });
  }

  setSecurityContext(email: string): void {
    this.appInsights.setAuthenticatedUserContext(email);
  }

  logException(exception: Error, type: 'Backend' | 'Frontend' = 'Frontend'): void {
    const properties = { type };
    this.appInsights.trackException({ exception, properties, severityLevel: SeverityLevel.Error });
  }

  logError(errorMessage: string): void {
    const exception = Error(errorMessage);
    this.appInsights.trackException({
      exception,
      properties: { type: 'Frontend' },
      severityLevel: SeverityLevel.Error,
    });
  }

  trackPageView(name: string): void {
    this.appInsights.trackPageView({ name });
  }

  logEvent(name: string, properties?: { [key: string]: any }): void {
    this.appInsights.trackEvent({ name }, properties);
  }
}
