<div class="container-fluid dashboard-page-container">
  <div class="row">
    <app-intervention-details-card
      class="col-12 col-md-12 col-lg-4"
      [intervention]="intervention"
      [numberDrones]="dronesDashboard?.dataSource?.filteredItems?.length || 0"
      [numberMedia]="mediaDashboard?.dataSourceLength || 0"
    ></app-intervention-details-card>
    <app-drones-dashboard-block
      #dronesDashboard
      class="col-12 col-md-12 col-lg-8"
      [intervention]="intervention"
      [isInterventionRequired]="true"
      [isLinkBtnActive]="true"
    ></app-drones-dashboard-block>
  </div>

  <div class="row mt-3">
    <mid-simple-dashboard-block title="{{ 'TDRAW.LAST_MAP' | translate }}" class="map-block col-12 col-md-12 col-lg-4">
      <div class="top-btns w-100 d-flex justify-content-end">
        <app-mid-icon-button type="secondary" (action)="goToInterventionCartography()"
          ><i class="fas fa-eye"></i>
        </app-mid-icon-button>
      </div>

      <img
        *ngIf="snapshotSrc"
        class="w-100"
        [attr.src]="snapshotSrc | secureImage | async"
        (click)="goToInterventionCartography()"
      />

      <div *ngIf="!snapshotSrc" class="h-100" style="position: relative">
        <img class="w-100 h-100" src="assets/images/demo-map2.png" alt="" (click)="goToInterventionCartography()" />

        <div class="demo-content">
          <p>{{ 'INTERVENTION_SNAPSHOT_DEMO' | translate }}</p>

          <app-mid-button (action)="goToInterventionCartography()" type="primary">
            <i class="fas fa-plus pr-2"></i> {{ 'TDRAW.NEW_VERSION' | translate }}
          </app-mid-button>
        </div>
      </div>
    </mid-simple-dashboard-block>
    <app-media-dashboard-block
      #mediaDashboard
      class="col-12 col-md-12 col-lg-8"
      [intervention]="intervention"
    ></app-media-dashboard-block>
  </div>
</div>
