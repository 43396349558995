import { NgModule, InjectionToken } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { StoreStateService, featureStoreConfigFactory } from 'src/app/core/store';
import { StoreConfig } from '@ngrx/store/src/store_module';
import { interventionConfig } from '../intervention.config';
import { interventionInitialState, interventionReducer, InterventionState } from './intervention.reducer';

const featureStoreKey = interventionConfig.store.key;

export const interventionFeatureStoreKeyToken = new InjectionToken(`FeatureStoreKeyInjectionToken:${featureStoreKey}`);
export const interventionFeatureStoreInitialStateToken = new InjectionToken<InterventionState>(
  `FeatureStoreInitialStateInjectionToken:${featureStoreKey}`
);
export const interventionStoreFeatueConfigToken = new InjectionToken<StoreConfig<InterventionState>>(
  `FeatureStoreConfigInjectionToken:${featureStoreKey}`
);

@NgModule({
  imports: [StoreModule.forFeature(featureStoreKey, interventionReducer, interventionStoreFeatueConfigToken)],
  providers: [
    {
      provide: interventionFeatureStoreKeyToken,
      useValue: featureStoreKey,
    },
    {
      provide: interventionFeatureStoreInitialStateToken,
      useValue: interventionInitialState,
    },
    {
      provide: interventionStoreFeatueConfigToken,
      deps: [interventionFeatureStoreKeyToken, interventionFeatureStoreInitialStateToken, StoreStateService],
      useFactory: featureStoreConfigFactory,
    },
  ],
})
export class InterventionStoreModule {}
