import { CircleType } from './models/circle-type.enum';
import { ConeWindValues } from './models/cone-wind-values';
import { FeatureColorProperty } from './models/feature-color-property.enum';
import { FeatureProperty } from './models/feature-property.enum';
import { GeometryType } from './models/geometry-type.enum';

export const tdrawConfig = {
  mapAccessToken: 'pk.eyJ1IjoiYXNjYWRyZSIsImEiOiJja3pvY3NvbnUwYTg3MnBwZTZscTRpdDhtIn0.pEHaGNxB0Bj0GKs9PggzaA',
  mapStyle: 'mapbox://styles/mapbox/streets-v11',
  // mapStyle: 'mapbox://styles/mapbox/dark-v10',
  // satelliteStyle: 'mapbox://styles/mapbox/satellite-v9',
  newAnnotationDefaultName: 'New Annotation',
  dfciRegex: /^[ABCDEFGHKLMN]{2}([02468]{2}([ABCDEFGHKL][0-9][1-5]?)?)?$/,

  markerPopupClass: 'marker-div',
  defaultMapCenter: [8.7522323, 41.9366314] as mapboxgl.LngLatLike,
  interventionsMap: {
    defaultMaxZoom: 9,
    defaultZoom: 6.6,
    defaultCenter: [9.073778, 42.18105] as mapboxgl.LngLatLike,
  },

  // Default properties for all created or drawn features
  // Can be overidden by defaultDrawFeatureProperties or defaultCustomFeatureProperties
  defaultFeatureProperties: {
    [FeatureProperty.Title]: '',
    [FeatureProperty.Description]: '',
    [FeatureProperty.Opacity]: 1,
  },

  // Default properties for types natively handled by MapboxDraw
  defaultDrawFeatureProperties: {
    [GeometryType.Point]: {
      [FeatureProperty.Opacity]: 1,
    },
    [GeometryType.LineString]: {
      [FeatureProperty.Color]: FeatureColorProperty.Cyan,
    },
    [GeometryType.Polygon]: {
      [FeatureProperty.Color]: FeatureColorProperty.Cyan,
    },
  },

  // Default properties for code-specific feature types
  defaultCustomFeatureProperties: {
    Cone: {
      [FeatureProperty.Color]: FeatureColorProperty.Orange,
      [FeatureProperty.Opacity]: 1,
    },
    [CircleType.Small]: {
      [FeatureProperty.Color]: FeatureColorProperty.Red,
      [FeatureProperty.RadiusInKm]: 0.05,
    },
    [CircleType.Medium]: {
      [FeatureProperty.Color]: FeatureColorProperty.Orange,
      [FeatureProperty.RadiusInKm]: 0.1,
    },
    [CircleType.Large]: {
      [FeatureProperty.Color]: FeatureColorProperty.Green,
      [FeatureProperty.RadiusInKm]: 0.2,
    },
  },

  cones: {
    20: {
      wind: 20,
      step: 0.15,
      length: 1.8,
      propagation: {
        byHour: 600,
        byMinute: 10,
      },
      distance: {
        for10min: 100,
        for15min: 150,
        for30min: 300,
        for60min: 600,
      },
    },
    30: {
      wind: 30,
      step: 0.225,
      length: 2.7,
      propagation: {
        byHour: 900,
        byMinute: 15,
      },
      distance: {
        for10min: 150,
        for15min: 225,
        for30min: 450,
        for60min: 900,
      },
    },
    40: {
      wind: 40,
      step: 0.3,
      length: 3.6,
      propagation: {
        byHour: 1200,
        byMinute: 20,
      },
      distance: {
        for10min: 200,
        for15min: 300,
        for30min: 600,
        for60min: 1200,
      },
    },
    50: {
      wind: 50,
      step: 0.375,
      length: 4.5,
      propagation: {
        byHour: 1500,
        byMinute: 25,
      },
      distance: {
        for10min: 250,
        for15min: 375,
        for30min: 750,
        for60min: 1500,
      },
    },
    60: {
      wind: 60,
      step: 0.45,
      length: 5.4,
      propagation: {
        byHour: 1800,
        byMinute: 30,
      },
      distance: {
        for10min: 300,
        for15min: 450,
        for30min: 900,
        for60min: 1800,
      },
    },
    70: {
      wind: 70,
      step: 0.525,
      length: 6.3,
      propagation: {
        byHour: 2100,
        byMinute: 35,
      },
      distance: {
        for10min: 350,
        for15min: 525,
        for30min: 1050,
        for60min: 2100,
      },
    },
    80: {
      wind: 80,
      step: 0.6,
      length: 7.2,
      propagation: {
        byHour: 2400,
        byMinute: 40,
      },
      distance: {
        for10min: 400,
        for15min: 600,
        for30min: 1200,
        for60min: 2400,
      },
    },
    90: {
      wind: 90,
      step: 0.675,
      length: 8.1,
      propagation: {
        byHour: 2700,
        byMinute: 45,
      },
      distance: {
        for10min: 450,
        for15min: 675,
        for30min: 1350,
        for60min: 2700,
      },
    },
    100: {
      wind: 100,
      step: 0.75,
      length: 9.0,
      propagation: {
        byHour: 3000,
        byMinute: 50,
      },
      distance: {
        for10min: 500,
        for15min: 750,
        for30min: 1500,
        for60min: 3000,
      },
    },
    110: {
      wind: 110,
      step: 0.825,
      length: 10.5,
      propagation: {
        byHour: 3300,
        byMinute: 55,
      },
      distance: {
        for10min: 550,
        for15min: 825,
        for30min: 1750,
        for60min: 3500,
      },
    },
  } as { [wind: number]: ConeWindValues },
  store: {
    key: 'mapStates',
  },
  featureColors: [
    FeatureColorProperty.Blue,
    FeatureColorProperty.SoftRed,
    FeatureColorProperty.Red,
    FeatureColorProperty.Cyan,
    FeatureColorProperty.Orange,
    FeatureColorProperty.Green,
  ],
};
