import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Intervention, MediaType, Organization } from 'src/app/autogenerated/model';
import { Unsubscribe } from 'src/app/core/decorators';
import { MediaSourceType } from 'src/app/intervention/common/select-media-button-toggle/select-media-button-toggle.component';
import { MediaUploadDialogComponent } from 'src/app/media/common/media-upload/media-upload-dialog/media-upload-dialog.component';
import { UploadingFile } from 'src/app/media/common/media-upload/models/uploading-file';
import { MediaDialogService } from 'src/app/media/media-dialog.service';
import { OrganizationStoreService } from 'src/app/organization/store';
import { PhotogrammetryUploadService, UploadProgress } from './photogrammetry-upload.service';

@Unsubscribe()
@Component({
  selector: 'app-photogrammetry-upload-dialog',
  templateUrl: './photogrammetry-upload-dialog.component.html',
  styleUrls: ['./photogrammetry-upload-dialog.component.scss'],
})
export class PhotogrammetryUploadDialogComponent implements OnInit, OnDestroy {
  private organization: Organization;
  private organizationSubscription: Subscription;

  organizationId: string;
  selectedIntervention: Intervention;

  projectName: string = '';
  mustGenerate3D: boolean = false;
  alertOnEmptyProjectName: boolean = false;
  alertOnNumberOfFile: boolean = false;

  files: UploadingFile[] = [];
  displayedFiles: UploadingFile[] = [];
  nbOfDisplayedFiles: number = 10;

  uploadProgress: UploadProgress;
  mediaSourceType: MediaSourceType = MediaSourceType.File;
  mediaIds: string[] = [];
  @ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef;
  @ViewChild('directoryDropRef', { static: false }) directoryDropEl: ElementRef;
  photogrammetryExtensions: string[] = ['.jpg', '.jpeg', '.tif', '.tiff'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { intervention: Intervention },
    private readonly translate: TranslateService,
    private photogrammetryUploadService: PhotogrammetryUploadService,
    private readonly organizationStoreService: OrganizationStoreService,
    private dialogRef: MatDialogRef<MediaUploadDialogComponent>,
    private mediaDialogService: MediaDialogService
  ) {
    this.selectedIntervention = data.intervention;
  }

  ngOnInit() {
    this.organizationSubscription = this.organizationStoreService
      .getCurrentOrganization()
      .subscribe((organization: Organization) => {
        if (organization?._id === this.organizationId) {
          return;
        }
        this.organization = organization;
        this.organizationId = organization._id;
      });
  }

  ngOnDestroy(): void {
    // for Unsubscribe
  }

  /** prepare the file list of the upload service */
  private async prepareFiles(files: File[]) {
    const rePExt = new RegExp('(.*)(\\' + this.photogrammetryExtensions?.join('|\\') + ')$', 'i');
    const validFiles = [...files].filter((it) => rePExt.test(it?.name));
    if (!validFiles?.length) {
      return;
    }
    for (const validFile of validFiles) {
      this.files.push(
        new UploadingFile({
          file: validFile,
          intervention: this.selectedIntervention,
        })
      );
    }
    console.log({ files: this.files });
    this.displayedFiles = [];
    if (this.files.length > this.nbOfDisplayedFiles) {
      for (let i: number = this.files.length - this.nbOfDisplayedFiles + 1; i < this.files.length; i++) {
        this.displayedFiles.push(this.files[i]);
      }
    }
    if (this.fileDropEl?.nativeElement?.value) {
      this.fileDropEl.nativeElement.value = '';
    }
    if (this.directoryDropEl?.nativeElement?.value) {
      this.directoryDropEl.nativeElement.value = '';
    }
  }

  /**
   * on file drop handler
   */
  async onFileDropped($event) {
    console.log('onFileDropped: ', $event);
    await this.prepareFiles($event);
  }

  /**
   * handle file from browsing
   */
  async fileBrowseHandler(files: File[]) {
    await this.prepareFiles(files);
  }

  getSelectedIntervention(selectedIntervention: Intervention) {
    this.selectedIntervention = selectedIntervention;
  }

  createProject() {
    if (this.projectName === '') {
      this.alertOnEmptyProjectName = true;
      return;
    }
    switch (this.mediaSourceType) {
      case MediaSourceType.File:
      case MediaSourceType.Folder:
        this.sendMedia();
        break;
      case MediaSourceType.MediaLibrary:
        this.sendMediaIds();
        break;
    }
  }
  async sendMediaIds() {
    console.log('start project using media from BDD');
    if (this.mediaIds.length < 4) {
      this.alertOnNumberOfFile = true;
      return;
    }
    if (this.mediaIds.length != 0) {
      console.info(`[Orthophotos Uploads] - Starting project ${this.projectName}`);
      //Sending infos for project creation
      const mediaInfo = await this.photogrammetryUploadService.startProject(
        this.organizationId,
        this.selectedIntervention._id,
        this.projectName
      );
      const mediaId = mediaInfo?._id;
      console.info(`[Orthophotos Uploads] - Sending file for project ${this.projectName}`);
      //Sending child media (as media ids)
      await this.photogrammetryUploadService.sendMedia(
        this.organizationId,
        this.selectedIntervention._id,
        mediaId,
        this.mediaIds
      );

      console.info(`[Orthophotos Uploads] - Finalizing project ${this.projectName}`);
      // Finalizing project
      await this.photogrammetryUploadService.finalizeProject(
        this.organizationId,
        this.selectedIntervention._id,
        mediaId,
        {
          mustGenerate3D: this.mustGenerate3D,
        }
      );
    }
    this.dialogRef.close(this.uploadProgress);
  }

  async sendMedia() {
    if (this.files.length < 4) {
      this.alertOnNumberOfFile = true;
      return;
    }
    this.uploadProgress = this.photogrammetryUploadService.startProgressBarStatus(
      this.files.length,
      this.projectName,
      new Date(Date.now())
    );
    this.sendMediaUsingWorker();
    this.dialogRef.close(this.uploadProgress);
  }

  async sendMediaUsingWorker() {
    if (typeof Worker !== 'undefined') {
      // Create a new
      const worker = new Worker(new URL('./photogrammetry-upload-dialog.worker', import.meta.url), { type: 'module' });
      this.uploadProgress.addWorker(worker);
      worker.onmessage = async ({ data }) => {
        const service = this.photogrammetryUploadService;
        const files = data.files as UploadingFile[];
        const uploadProgress = this.uploadProgress;
        if (files.length != 0) {
          console.info(`[Orthophotos Uploads] - Starting project ${this.projectName}`);
          //Sending infos for project creation (no file)
          const mediaInfo = await service.startProject(
            this.organizationId,
            this.selectedIntervention._id,
            this.projectName
          );
          const mediaId = mediaInfo?._id;
          uploadProgress.mediaId = mediaId;
          console.info(`[Orthophotos Uploads] - Sending file for project ${this.projectName}`);
          //Sending files
          await service.sendFiles(files, this.organizationId, this.selectedIntervention._id, mediaId, uploadProgress);
          if (!uploadProgress.aborted) {
            console.info(`[Orthophotos Uploads] - Finalizing project ${this.projectName}`);
            // Finalizing project
            await service.finalizeProject(this.organizationId, this.selectedIntervention._id, mediaId, {
              mustGenerate3D: this.mustGenerate3D,
            });
          }
        }
      };
      worker.postMessage({ files: this.files, interventionId: this.selectedIntervention._id });
    } else {
      // Web Workers are not supported in this environment.
      // You should add a fallback so that your program still executes correctly.
      console.error('web workers unsupported');
    }
  }

  getNbOfFiles() {
    if (this.files.length === 0) {
      return '';
    }
    return ` (${this.files.length})`;
  }

  mediaSourceTypeChange(event) {
    if (event == MediaSourceType.MediaLibrary) {
      this.openSelectMediasDialog();
    }
  }
  async openSelectMediasDialog() {
    this.mediaIds = await this.mediaDialogService.openMultiSelectMediaDialog(
      'SELECT_MEDIA_TO_INTERVENTION',
      MediaType.picture,
      this.mediaIds,
      this.data.intervention._id,
      false
    );
  }
}
