import {
  EditDefaultLanguageAction,
  EditDefaultThemeAction,
  LayoutActions,
  LayoutActionType,
  SetDronesViewStyleAction,
} from './layout.actions';
import { Assert } from 'src/app/shared';
import { DronesViewStyle } from '../../midgard-controls/enums/drone-view-style.enum';

export interface LayoutState {
  layoutDefaultLanguage: string;
  layoutDefaultTheme: string;
  selectDronesViewStyle: DronesViewStyle;
  isLandingIntroShown: boolean;
  isDroneIntroShown: boolean;
  isFirstDroneIntroShown: boolean;
}

export const layoutInitialState: LayoutState = {
  layoutDefaultLanguage: 'fr',
  layoutDefaultTheme: 'dark',
  selectDronesViewStyle: DronesViewStyle.block,
  isLandingIntroShown: false,
  isDroneIntroShown: false,
  isFirstDroneIntroShown: false,
};

export function layoutReducer(state: LayoutState, action: LayoutActions): LayoutState {
  Assert.isNotNull(action, 'action');

  switch (action.type) {
    case LayoutActionType.EditDefaultLanguage:
      return editDefaultLanguageReducer(state, action);

    case LayoutActionType.EditDefaultTheme:
      return editDefaultThemeReducer(state, action);

    case LayoutActionType.SetDronesViewStyle:
      return setDronesViewStyleReducer(state, action);

    case LayoutActionType.SetLandingIntroShownFlag:
      return setLandingIntroShownFlagReducer(state);

    case LayoutActionType.SetDroneIntroShownFlag:
      return setDroneIntroShownFlagReducer(state);

    case LayoutActionType.SetLandingIntroShownFlag:
      return setLandingIntroShownFlagReducer(state);

    default:
      return state;
  }
}

export function editDefaultLanguageReducer(state: LayoutState, action: EditDefaultLanguageAction): LayoutState {
  Assert.isNotNull(state, 'state');
  Assert.isNotNull(action, 'action');
  Assert.isNotNull(action.language, 'action.language');

  const newState: LayoutState = {
    ...state,
    layoutDefaultLanguage: action.language,
  };

  return newState;
}

export function editDefaultThemeReducer(state: LayoutState, action: EditDefaultThemeAction): LayoutState {
  Assert.isNotNull(state, 'state');
  Assert.isNotNull(action, 'action');
  Assert.isNotNull(action.theme, 'action.theme');

  const newState: LayoutState = {
    ...state,
    layoutDefaultTheme: action.theme,
  };

  return newState;
}

export function setDronesViewStyleReducer(state: LayoutState, action: SetDronesViewStyleAction): LayoutState {
  Assert.isNotNull(state, 'state');
  Assert.isNotNull(action, 'action');
  Assert.isNotNull(action.viewStyle, 'action.viewStyle');

  const newState: LayoutState = {
    ...state,
    selectDronesViewStyle: action.viewStyle,
  };
  return newState;
}

export function setLandingIntroShownFlagReducer(state: LayoutState): LayoutState {
  Assert.isNotNull(state, 'state');

  const newState: LayoutState = {
    ...state,
    isLandingIntroShown: true,
  };

  return newState;
}

export function setDroneIntroShownFlagReducer(state: LayoutState): LayoutState {
  Assert.isNotNull(state, 'state');

  const newState: LayoutState = {
    ...state,
    isDroneIntroShown: true,
  };

  return newState;
}

export function setFirstDroneIntroShownFlagReducer(state: LayoutState): LayoutState {
  Assert.isNotNull(state, 'state');

  const newState: LayoutState = {
    ...state,
    isFirstDroneIntroShown: true,
  };

  return newState;
}
