import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MidButtonSize } from 'src/app/midgard-controls/enums/button-size.enum';

export interface DialogData {
  message: string;
  align: 'left' | 'right' | 'center';
  status: 'validate' | 'remove';
}

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ConfirmModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {}

  get MidButtonSize() {
    return MidButtonSize;
  }
}
