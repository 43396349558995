import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MID_RIGHT_PANEL_DATA } from 'src/app/midgard-controls/services/dom.service';
import { ClipboardService } from 'src/app/core/utils/clipboard.service';
import { MapState } from 'src/app/tdraw/models/map-state.model';
import { SharedMapState } from 'src/app/tdraw/models/shared-map-state.model';
import { TdrawService } from 'src/app/autogenerated/tdrawService';
import { SharedMapStateCreationDto } from 'src/app/tdraw/models/dto/shared-map-state-creation.dto';
import { UserApiClientService } from 'src/api-services/user-api-client.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MidSelectItem } from 'src/app/midgard-controls/models';
import { isEmpty } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { required, validate } from '../../../../shared';
import { OrganizationService } from '../../../../autogenerated/organizationService';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { OrganizationDistributionListDto, OrganizationDistributionListService } from 'src/api';
import { OrganizationStoreService } from 'src/app/organization/store';
import { Subscription } from 'rxjs';
import { MidInputComponent } from 'src/app/midgard-controls/mid-input/mid-input.component';

enum TdrawSharingMode {
  SMS = 'sms',
  EMAIL = 'email',
  QRCODE = 'qrcode',
  LIST_SMS = 'list_sms',
}

@Component({
  selector: 'tdraw-mapstate-share-panel',
  templateUrl: './mapstate-share-panel.component.html',
  styleUrls: ['./mapstate-share-panel.component.scss'],
})
export class MapStateSharePanelComponent implements OnInit {
  mapState: MapState;
  existingShares: SharedMapState[] = [];
  duration: number = null;
  durationOptions = [1, 2, 7, 15, 30];
  optionsSelectItems: MidSelectItem[] = [];
  usernames: { [userId: string]: string } = {};

  dataToShare: {
    phoneNumber: any;
    mail: string;
  };

  tdrawShareLink: string;
  sharingMode?: TdrawSharingMode;
  organizationId: string;

  @ViewChild('listInput')
  private listInput: MidInputComponent;
  selectedDistributionList: OrganizationDistributionListDto;
  distributionLists: OrganizationDistributionListDto[];

  // Subscriptions
  private organizationSubscription: Subscription;

  get TdrawSharingMode() {
    return TdrawSharingMode;
  }

  constructor(
    private tdrawService: TdrawService,
    @Inject(MID_RIGHT_PANEL_DATA) private readonly data: { mapState: MapState },
    private readonly clipboardService: ClipboardService,
    private readonly usersService: UserApiClientService,
    private readonly router: Router,
    private readonly location: Location,
    private translate: TranslateService,
    private readonly organizationService: OrganizationService,
    private readonly organizationStoreService: OrganizationStoreService,
    private readonly organizationDistributionListService: OrganizationDistributionListService
  ) {
    this.mapState = data.mapState;

    this.dataToShare = {
      phoneNumber: null,
      mail: null,
    };

    this.tdrawShareLink = null;

    this.organizationSubscription = this.organizationStoreService
      .getCurrentOrganization()
      .subscribe(async (organization) => {
        if (!organization || this.organizationId === organization._id) {
          return;
        }
        this.organizationId = organization._id;
        this.loadDistributionLists();
      });
  }

  async ngOnInit() {
    this.existingShares = await this.tdrawService.getMapStateShares(this.mapState._id);
    this.optionsSelectItems = await this.convertDurationInMidSelectItems(this.durationOptions);
    // Gather user full names
    for (const share of this.existingShares) {
      const userId = share.sharedBy;
      if (!(userId in this.usernames)) {
        this.usernames[userId] = (await this.usersService.getUser(userId))?.fullname;
      }
    }
  }

  async onGenerateNewShareLink() {
    const share = await this.tdrawService.shareMap(
      new SharedMapStateCreationDto({
        mapStateId: this.mapState._id,
        duration: this.duration,
        type: this.mapState.type,
      })
    );
    if (!isEmpty(share)) {
      this.existingShares.push(share);
    }
    this.duration = null;
  }

  async deleteShare(share: SharedMapState) {
    await this.tdrawService.deleteSharedMapState(share._id);
    this.existingShares = this.existingShares.filter((x) => x._id !== share._id);
  }

  async copyShareLink(share: SharedMapState) {
    await this.clipboardService.copyToClipboard(this.getShareLink(share));
  }

  private getShareLink(share: SharedMapState): string {
    const urlTree = this.router.createUrlTree(['share', `tdraw${share.type}`, share._id]);
    const path = this.location.prepareExternalUrl(urlTree.toString());
    return window.location.origin + path;
  }

  private async convertDurationInMidSelectItems(options) {
    const midSelectItems = [];
    const singular = ' ' + (await this.translate.get('DURATION.DAY.ONE').toPromise());
    const plural = ' ' + (await this.translate.get('DURATION.DAY.MANY').toPromise());

    for (const option of options) {
      const translatedLabel = option + (option > 1 ? plural : singular);
      const midSelectItem: MidSelectItem = {
        isSelected: false,
        name: translatedLabel,
        value: option,
      };
      midSelectItems.push(midSelectItem);
    }
    return midSelectItems;
  }

  cancel(): void {
    this.tdrawShareLink = null;
    this.sharingMode = null;
  }

  async sendMail(): Promise<void> {
    if (!this.dataToShare.mail) {
      return;
    }
    await this.sendMailRequest(this.dataToShare.mail);
    this.sharingMode = null;
  }

  async sendSMS(): Promise<void> {
    if (!this.dataToShare.phoneNumber) {
      return;
    }
    await this.sendSmsRequest(this.dataToShare.phoneNumber.e164Number);
    this.sharingMode = null;
  }

  shareUsingMode(share: SharedMapState, mode: TdrawSharingMode) {
    this.tdrawShareLink = this.getShareLink(share);
    this.sharingMode = mode;
  }

  async sendSMSList() {
    const phones = this.selectedDistributionList.phones;
    phones.forEach(async (phone) => {
      await this.sendSmsRequest(phone);
    });
    this.sharingMode = null;
  }

  selectDistributionList(event: MatAutocompleteSelectedEvent): void {
    const list = event.option.value;
    this.selectedDistributionList = list;
    this.listInput.input.control.setValue(list.name);
  }

  private async loadDistributionLists() {
    this.distributionLists = await this.organizationDistributionListService
      .orgOrganizationDistributionListControllerGetOrgDistributionLists(this.organizationId)
      .toPromise();
  }

  @validate
  private async sendMailRequest(@required mail: string): Promise<void> {
    await this.organizationService.sendTdrawShareLinkByMail(mail, this.tdrawShareLink);
    this.tdrawShareLink = null;
  }

  @validate
  private async sendSmsRequest(@required phoneNumber: string): Promise<void> {
    await this.organizationService.sendTdrawShareLinkBySMS(phoneNumber, this.tdrawShareLink);
    this.tdrawShareLink = null;
  }
}
