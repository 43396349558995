import { DbClass, User, ChangeUserPassword, requestPasswordReset, ResetPasswordData } from '../model';
import { BackendClientService } from '../../global/backend-client.service';
import { ApiService } from 'src/app/core/api';

export class UserServiceAutoGenerated {
  constructor(private beClient: BackendClientService, private apiService: ApiService) {}

  async getUser(input: DbClass): Promise<User> {
    let result: User;
    try {
      result = await this.beClient.post<User>('userService/getUser', input).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return new User({ ...result });
  }

  async putUser(input: User): Promise<User> {
    let result: User;
    try {
      result = await this.beClient.put<User>('userService/putUser', input).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return new User({ ...result });
  }

  async changePassword(changeUserPassword: ChangeUserPassword): Promise<boolean> {
    let result: boolean;
    try {
      result = await this.beClient.put<boolean>('userService/changePassword', changeUserPassword).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return result;
  }

  async requestPasswordReset(requestPasswordReset: requestPasswordReset): Promise<boolean> {
    let result: boolean;
    try {
      result = await this.beClient.post<boolean>('userService/requestPasswordReset', requestPasswordReset).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return result;
  }

  async resetPassword(resetPasswordData: ResetPasswordData): Promise<boolean> {
    let result: boolean;
    try {
      result = await this.beClient.post<boolean>('userService/resetPassword', resetPasswordData).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return result;
  }
}
