import { NgModule, InjectionToken } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { StoreStateService, featureStoreConfigFactory } from 'src/app/core/store';
import { StoreConfig } from '@ngrx/store/src/store_module';
import { layoutReducer, LayoutState, layoutInitialState } from './layout.reducer';
import { layoutConfig } from '../layout.config';

const featureStoreKey = layoutConfig.store.key;

export const layoutFeatureStoreKeyToken = new InjectionToken(`FeatureStoreKeyInjectionToken:${featureStoreKey}`);
export const layoutFeatureStoreInitialStateToken = new InjectionToken<LayoutState>(
  `FeatureStoreInitialStateInjectionToken:${featureStoreKey}`
);
export const layoutStoreFeatueConfigToken = new InjectionToken<StoreConfig<LayoutState>>(
  `FeatureStoreConfigInjectionToken:${featureStoreKey}`
);

@NgModule({
  imports: [StoreModule.forFeature(featureStoreKey, layoutReducer, layoutStoreFeatueConfigToken)],
  providers: [
    {
      provide: layoutFeatureStoreKeyToken,
      useValue: featureStoreKey,
    },
    {
      provide: layoutFeatureStoreInitialStateToken,
      useValue: layoutInitialState,
    },
    {
      provide: layoutStoreFeatueConfigToken,
      deps: [layoutFeatureStoreKeyToken, layoutFeatureStoreInitialStateToken, StoreStateService],
      useFactory: featureStoreConfigFactory,
    },
  ],
})
export class LayoutStoreModule {}
