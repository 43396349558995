export enum FeatureProperty {
  Title = 'title',
  Description = 'description',
  Trigram = 'trigram',
  Icon = 'icon',
  Opacity = 'opacity',
  Size = 'size',
  Rotation = 'rotation',
  Color = 'color',
  Wind = 'wind',
  IsCone = 'isCone',
  IsCircle = 'isCircle',
  RadiusInKm = 'radiusInKm',
  Center = 'center',
  SectorTitle = 'sectorTitle',
  SectorChief = 'sectorChief',
  HighFreq = 'highFreq',
  LowFreq = 'lowFreq',
  SectorBelong = 'sectorFeatureId',
}
