import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrganizationDto } from 'src/api';
import { OrganizationService } from 'src/api-services/organization.service';

@Component({
  selector: 'app-organization-groups-route',
  templateUrl: './organization-groups-route.component.html',
  styleUrls: ['./organization-groups-route.component.scss'],
})
export class OrganizationGroupsRouteComponent implements OnInit {
  organization: OrganizationDto;
  organizationId: string;

  constructor(private readonly route: ActivatedRoute, private organizationService: OrganizationService) {}

  async ngOnInit(): Promise<void> {
    await this.loadOrganization();
  }

  private async loadOrganization() {
    this.route.parent.params.subscribe(async (params) => {
      this.organizationId = params.id;
      if (!this.organizationId) {
        return;
      }
      this.organization = await this.organizationService.getOrganization(this.organizationId);
    });
  }
}
