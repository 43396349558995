<div class="edit-panel">
  <div class="d-flex">
    <app-mid-icon-button type="primary" (action)="close()">
      <i class="fas fa-times"></i>
    </app-mid-icon-button>

    <!-- <div class="button-box ml-3">
      <app-mid-button type="secondary">
        <i class="fas fa-undo btn-icon-color mr-1"></i> {{ 'UNDO' | translate }}
      </app-mid-button>
      <app-mid-button type="secondary">
        <i class="fas fa-redo btn-icon-color mr-1"></i> {{ 'REDO' | translate }}
      </app-mid-button>
    </div> -->

    <div class="labels ml-3">
      <div class="description-box">
        <div class="description">
          {{ date | date : 'dd MMMM yyyy / HH:mm' : undefined : translate.getDefaultLang() }}
        </div>
        <div class="description" *ngIf="currentInfo">
          <span>{{ currentInfo.lng }} / {{ currentInfo.lat }}</span>
          <span class="ml-3" *ngIf="currentInfo.elevation">{{ currentInfo.elevation }} m</span>
          <span class="dfci">{{ currentInfo.dfci }}</span>
          <span class="ml-3"
            >{{ currentInfo.utmCoords[0] }} / {{ currentInfo.utmCoords[1] }} (EPSG:{{ currentInfo.utmEpsg }})</span
          >
        </div>
      </div>
      <!--
      <div class="drone-box">
        <div class="drone-tactic">{{ 'INTERVENTION.LABEL.DRONE_CHANNEL' | translate }} :</div>
        <div class="dfci">{{ currentInfo?.dfci }}</div>
      </div>
      -->
    </div>
  </div>

  <app-mid-button [matMenuTriggerFor]="actionsMenu" type="secondary">
    {{ 'TDRAW.ACTIONS' | translate }} <i class="fas fa-ellipsis-v ml-3"></i>
  </app-mid-button>
</div>

<mat-menu #actionsMenu="matMenu" class="mid-menu" panelClass="pt-0">
  <div class="mid-menu-item" (click)="geolocalizeMe()">
    <i class="fas fa-location mr-2 mid-menu-item-icon"></i>
    {{ 'TDRAW.GEOLOCALIZE_ME' | translate }}
  </div>
  <div class="mid-menu-item" (click)="printSnapshot()">
    <i class="fas fa-print mr-2 mid-menu-item-icon"></i>
    {{ 'TDRAW.PRINT' | translate }}
  </div>
  <div class="mid-menu-item" (click)="downloadSnapshot()">
    <i class="fas fa-camera mr-2 mid-menu-item-icon"></i>
    {{ 'TDRAW.SCREENSHOT' | translate }}
  </div>
  <div class="mid-menu-item" (click)="toggleCompare()">
    <i class="fas fa-equals mr-2 mid-menu-item-icon"></i>
    {{ (isCompareMode ? 'COMPARE_STOP' : 'COMPARE_START') | translate }}
  </div>
  <div class="mid-menu-item" (click)="saveData()">
    <i class="fas fa-clipboard-check mr-2 mid-menu-item-icon"></i>
    {{ 'SAVE' | translate }}
  </div>
  <div class="mid-menu-item" (click)="openExportModal()">
    <i class="fas fa-file-export mr-2 mid-menu-item-icon"></i>
    {{ 'EXPORT' | translate }}
  </div>
  <div class="mid-menu-item" (click)="importInput.click()">
    <i class="fas fa-file-import mr-2 mid-menu-item-icon"></i>
    {{ 'IMPORT' | translate }}
    <input hidden type="file" accept=".kml,.kmz" (change)="importFile($event.target.files)" #importInput />
  </div>
  <div class="mid-menu-item" (click)="publishHistory()">
    <i class="fas fa-reply mr-2 mid-menu-item-icon"></i>
    {{ 'PUBLISH' | translate }}
  </div>
</mat-menu>

<app-media-right-panel
  [displayState]="pinpointMediaPanelDisplayState"
  [media]="pinpointMedia"
  [type]="PinpointMediaPanelType.default"
  (displayStateChanged)="pinpointMediaPanelDisplayState = $event"
>
</app-media-right-panel>

<div class="map-container">
  <div class="mapbox-container">
    <div id="map-gis-edit"></div>
    <tdraw-mapmode-info [isEditable]="true"> </tdraw-mapmode-info>

    <div class="right-toolbox" [class.sticky-toolbox]="rightPanelData.mode === RightPanelMode.Hidden">
      <div class="right-tool-button">
        <button (click)="onDrawLine()">
          <img src="/assets/images/tool-icons/tool1.svg" width="20" height="20" />
        </button>
      </div>
      <div class="right-tool-button">
        <button (click)="onDrawPolygon()">
          <img src="/assets/images/tool-icons/tool2.svg" width="20" height="20" />
        </button>
      </div>
      <div class="right-tool-button" *ngIf="Model3dStore.toolbarIsVisible">
        <button
          (click)="Model3dStore.toggleActiveTool(Model3dToolType.HeightMeasure)"
          [class.active]="Model3dStore.activeTool == Model3dToolType.HeightMeasure"
        >
          <img src="/assets/images/tool-icons/tool3.png" width="20" height="20" />
        </button>
      </div>
    </div>

    <tdraw-map-loading-bar
      *ngIf="gisManager"
      [title]="'TDRAW.3D_MODEL_LOADING' | translate"
      [percent]="gisManager.loading3dModelsInfo?.percent"
    >
    </tdraw-map-loading-bar>
  </div>

  <tdraw-right-panel [data]="rightPanelData">
    <tdraw-gis-edition-properties-panel
      *ngIf="rightPanelData.mode === RightPanelMode.Properties"
      [editModeManager]="editModeManager"
      (goToToolbox)="setRightPanelMode(RightPanelMode.Navigation)"
      (delete)="onDeleteSource()"
    >
    </tdraw-gis-edition-properties-panel>

    <tdraw-tools-panel *ngIf="gisManager?.isLoaded" [class.d-none]="rightPanelData.mode !== RightPanelMode.Navigation">
      <tdraw-tools-panel-tab [title]="'BASEMAP' | translate" fa-icon="fas fa-map">
        <tdraw-basemap-tab [gisManager]="gisManager" (selectLocation)="flyMapToLocation($event)"></tdraw-basemap-tab>
      </tdraw-tools-panel-tab>

      <tdraw-tools-panel-tab [title]="'SYMBOLS' | translate" fa-icon="fas fa-flag-checkered">
        <tdraw-gis-symbols-tab
          (drawIcon)="onDrawIcon($event)"
          (drawCircle)="onDrawCircle($event)"
          (drawAllCircles)="onDrawAllCircles()"
          (openConeCreationModal)="coneCreationSelection()"
          (openMarkerCreationModal)="markerCreationSelection()"
          [(statistics)]="mapState.statistics"
        >
        </tdraw-gis-symbols-tab>
      </tdraw-tools-panel-tab>

      <tdraw-tools-panel-tab [title]="'LAYERS' | translate" fa-icon="fas fa-layer-group">
        <tdraw-layers-tab [mapModeManager]="editModeManager" [statistics]="mapState.statistics"> </tdraw-layers-tab>
      </tdraw-tools-panel-tab>
      <tdraw-tools-panel-tab [title]="'DATA' | translate" fa-icon="fas fa-photo-video">
        <tdraw-data-tab [gisManager]="gisManager"></tdraw-data-tab>
      </tdraw-tools-panel-tab>
    </tdraw-tools-panel>
  </tdraw-right-panel>
</div>
