import { Component, OnInit } from '@angular/core';
import { CustomClassService } from 'src/app/layout/services/custom-class.service';

@Component({
  selector: 'app-access-code-profile',
  templateUrl: './access-code-profile.component.html',
  styleUrls: ['./access-code-profile.component.scss'],
})
export class AccessCodeProfileComponent implements OnInit {
  constructor(public customClassesService: CustomClassService) {}

  ngOnInit(): void {}
}
