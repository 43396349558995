import 'reflect-metadata';
import { Assert } from './utils/assert';

const requiredMetadataKey: symbol = Symbol('required');

export function required(target: object, propertyKey: string | symbol, parameterIndex: number) {
  const existingRequiredParameters: number[] = Reflect.getOwnMetadata(requiredMetadataKey, target, propertyKey) || [];
  existingRequiredParameters.push(parameterIndex);
  Reflect.defineMetadata(requiredMetadataKey, existingRequiredParameters, target, propertyKey);
}

export function validate(target: any, propertyName: string, descriptor: TypedPropertyDescriptor<Function>) {
  const method = descriptor.value;

  descriptor.value = function () {
    const requiredParameters: number[] = Reflect.getOwnMetadata(requiredMetadataKey, target, propertyName);
    if (requiredParameters) {
      for (const parameterIndex of requiredParameters) {
        Assert.isTrue(parameterIndex < arguments.length, propertyName, 'Missing required argument: {0}.');
        Assert.isNotNull(arguments[parameterIndex], propertyName, '{0}: required parameter is missing');
      }
    }

    return method.apply(this, arguments);
  };
}
