import { Type } from '@angular/core';
import { InterventionDto, OrganizationGroupDto, UserDto } from '../../api';
import { DroneModel } from '../drone/models/drone-model.enum';
import { InterventionStatistics } from '../midgard-controls/models';
import { StatusCode } from '../core/api';

export class DbClass {
  id?: string;
  _id?: string;
  __t?: string;
  public constructor(init?: Partial<DbClass>) {
    Object.assign(this, init);
  }

  public toString = (): string => this._id;
}

export class ActivityStreamItem extends DbClass {
  organization?: Organization | string | null;
  summary?: string;
  date?: Date;
  createdBy?: User | string | null;
  public constructor(init?: Partial<ActivityStreamItem>) {
    super();
    Object.assign(this, init);
  }
}

// export class AnnotationBox extends DbClass {
//   id?: string;
//   name?: string;
//   intervention?: Intervention | string | null;
//   organization?: Organization | string | null;
//   annotations?: Annotation[];
//   public constructor(init?: Partial<AnnotationBox>) {
//     super();
//     Object.assign(this, init);
//   }
// }

export class SaveAnnotationsDto {
  interventionId?: string;
  annotations?: Annotation[];
  public constructor(init?: Partial<SaveAnnotationsDto>) {
    Object.assign(this, init);
  }
}

export class InterventionFilterParameters extends DbClass {
  filtersParameters?: FilterParameter[];
}

export enum FilterName {
  blur = 'blur',
}

export default class FilterParameter {
  constructor(name: FilterName, active = false, applied = false) {
    this.name = name;
    this.active = active;
    this.applied = applied;
  }
  name: FilterName;
  active: boolean;
  applied: boolean;
}

export class InterventionFilterParametersDto {
  filtersParameters?: FilterParameter[];
}

export class InterventionNestedStatisticsDto extends InterventionStatistics {
  constructor(init: InterventionNestedStatisticsDto) {
    super(init);
  }
}

export class Intervention extends DbClass {
  name?: string;
  description?: string;
  organization?: Organization | string | null;
  interventionType?: InterventionDto.InterventionTypeEnum;
  type?: InterventionType;
  nature?: string;
  motive?: string;
  interventionNumber?: string;
  city?: string;
  droneChannel?: string;
  tags?: string[];
  remotePilots?: string;
  notes?: string;
  statistics?: InterventionNestedStatisticsDto;
  startDate?: Date;
  endDate?: Date;
  updatedDate?: Date;
  isDeleted?: boolean;
  owner?: User | string | null;
  isClosed?: boolean;
  isArchived?: boolean;
  isGlobalRecording?: boolean;
  public constructor(init?: Partial<Intervention>) {
    super();
    Object.assign(this, init);
  }
}

export class CreateInterventionDto {
  organizationId?: string;
  interventionType?: InterventionType;
  nature?: string;
  motive?: string;
  interventionNumber?: string;
  name?: string;
  city?: string;
  public constructor(init?: Partial<CreateInterventionDto>) {
    Object.assign(this, init);
  }
}

export class UpdateInterventionDto {
  type: InterventionType;
  nature?: string;
  motive?: string;
  startDate: Date;
  endDate?: Date;
  interventionNumber?: string;
  name?: string;
  city?: string;
  droneChannel?: string;
  tags?: string[];
  remotePilots?: string;
  notes?: string;
  statistics?: InterventionNestedStatisticsDto;
  favoriteForUsers?: string[];
  attachedDronesIds?: string[];
  isGlobalRecording?: boolean;
  public constructor(init: UpdateInterventionDto) {
    this.type = init.type;
    this.nature = init.nature;
    this.motive = init.motive;
    this.startDate = init.startDate;
    this.endDate = init.endDate || null;
    this.interventionNumber = init.interventionNumber;
    this.name = init.name;
    this.city = init.city;
    this.droneChannel = init.droneChannel;
    this.tags = init.tags || [];
    this.remotePilots = init.remotePilots;
    this.notes = init.notes;
    this.statistics = init.statistics || {
      victims: [],
      engagedResources: [],
      engagedStaff: [],
      otherServices: [],
    };
    this.favoriteForUsers = init.favoriteForUsers || [];
    this.attachedDronesIds = init.attachedDronesIds || [];
  }
}

export type TimeOfDay = 'day' | 'night' | 'all';

export class Alert extends DbClass {
  organizationId: string;
  interventionId: string;
  droneId: string;
  droneName: string;
  streamId: string;
  statusAlert: string;
  date: Date;
  image_path?: string;
  video_path?: string;
  description?: string;
  box?: {
    min_row: number;
    min_col: number;
    max_row: number;
    max_col: number;
  };
  metadata?: {
    timestamp: number;
    longitude: number;
    latitude: number;
    altitude: number;
    timeOfDay: TimeOfDay;

    azimuth: number;
    elevation: number;
    roll: number;
    slant: number;

    fov: number;
    fovh: number;
    fovv: number;

    platform_heading: number;
    platform_pitch: number;
    platform_roll: number;

    target_width: number;
    target_longitude: number;
    target_latitude: number;
    target_elevation: number;
    target_distance: number;
  };
}

export class UpdateInterventionAlertDto {
  statusAlert?: string;
  description?: string;
}

export enum MediaFilterType {
  picture = 'picture',
  video = 'video',
  gps = 'gps',
  tiled = 'tiled',
}

export class DroneLiveSnapshotMedia extends DbClass {
  drone?: Drone | string | null;
  liveStream?: DroneLiveStream | string | null;
  __t?: string;
  organization?: Organization | string | null;
  name?: string;
  url?: string;
  ext?: string;
  creationDate?: Date;
  intervention?: Intervention | string | null;
  mediaType?: MediaType;
  uploadedBy?: User | string | null;
  public constructor(init?: Partial<DroneLiveSnapshotMedia>) {
    super();
    Object.assign(this, init);
  }
}

export class startEventInput {
  drone?: Drone | string | null;
  intervention?: Intervention | string | null;
  public constructor(init?: Partial<startEventInput>) {
    Object.assign(this, init);
  }
}

export class Drone extends DbClass {
  name?: string;
  organization?: Organization | string | null;
  streamingStatus?: string;
  rtmpStreamId?: string;
  lastEvent?: Date;
  droneModel?: DroneModel;
  serialNumber?: string;
  currentIntervention?: Intervention | string | null;
  lastConnection?: Date;
  lastDisconnection?: Date;
  outputCreation?: Date;
  outputDeletion?: Date;
  outputAssetName?: string;
  liveEventStarted?: Date;
  currentLiveStream?: DroneLiveStream | string | null;
  isRecording?: boolean;
  recording?: DroneRecording | string | null;
  creationDate?: Date;
  public constructor(init?: Partial<Drone>) {
    super();
    Object.assign(this, init);
  }
}

export class GetDroneBySerialNumberInput {
  serialNumber?: string;
  public constructor(init?: Partial<GetDroneBySerialNumberInput>) {
    Object.assign(this, init);
  }
}

export class addDroneOutput {
  success?: boolean;
  msg?: string;
  drone?: Drone | string | null;
  public constructor(init?: Partial<addDroneOutput>) {
    Object.assign(this, init);
  }
}

export class UpdateDroneInput {
  id?: string;
  name?: string;
  droneModel?: string;
  serialNumber?: string;
  currentIntervention?: string | Intervention;
  public constructor(init?: Partial<UpdateDroneInput>) {
    Object.assign(this, init);
  }
}

export class DroneTimeInput {
  droneId?: string;
  time?: number;
  public constructor(init?: Partial<DroneTimeInput>) {
    Object.assign(this, init);
  }
}

export class SnapshotOutput {
  valid?: boolean;
  message?: string;
  public constructor(init?: Partial<SnapshotOutput>) {
    Object.assign(this, init);
  }
}

export class DroneStreamSharing extends DbClass {
  drone?: Drone | string | null;
  organization?: Organization | string | null;
  createdBy?: User | string | null;
  validUntil?: Date;
  createAt?: Date;
  intervention?: Intervention | string | null;
  public constructor(init?: Partial<DroneStreamSharing>) {
    super();
    Object.assign(this, init);
  }
}

export class OrganizationMedia extends DbClass {
  __t?: string;
  __guid?: string;
  __isLoading?: boolean;
  organization?: Organization | string | null;
  name?: string;
  url?: string;
  ext?: string;
  creationDate?: Date;
  intervention?: Intervention | string | null;
  mediaType?: MediaType;
  uploadedBy?: User | string | null;
  gps?: {
    latitude: number;
    longitude: number;
    altitude: number;
  };
  model3d?: {
    lat: number;
    lng: number;
  };
  tiled?: boolean;
  bounds?: [number, number, number, number];
  tags?: string[];
  favoriteForUsers?: string[];

  public constructor(init?: Partial<OrganizationMedia>) {
    super();
    Object.assign(this, init);
  }
}

export enum MediaDeleteStatus {
  REJECTED = 'REJECTED',
  FULLFILLED = 'FULLFILLED',
}
export interface BatchMediaDeleteResults {
  status: MediaDeleteStatus;
  value?: OrganizationMedia;
  reason?: BatchDeleteFailReason;
}

export interface BatchDeleteFailReason {
  status: StatusCode;
  message: string;
}

export enum TaskStatus {
  RUNNING = 'RUNNING',
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED',
  NO_RUN = 'NO_RUN',
}

export interface TiffStatusMessage {
  name: string;
  organization?: Organization | string | null;
  intervention?: Intervention | string | null;
  media?: OrganizationMedia | string | null;
  mediaId: string;
  status: TaskStatus;
  progress: null | number;
  message: string;
  timestamp: string | number;
}

export class MediaFilterResponse {
  results: OrganizationMedia[];
  limit: number;
  offset: number;
  count: number;
  public constructor(init?: Partial<MediaFilterResponse>) {
    this.results = init?.results || [];
    this.limit = init?.limit ?? 0;
    this.offset = init?.offset ?? 0;
    this.count = init?.count ?? 0;
  }
}

export class MediaNavigationInfo {
  index?: number;
  total?: number;
  nextMedia?: OrganizationMedia | string | null;
  previousMedia?: OrganizationMedia | string | null;
  public constructor(init?: Partial<MediaNavigationInfo>) {
    Object.assign(this, init);
  }
}

export class DroneLiveStreamMedia extends DbClass {
  startDate?: Date;
  endDate?: Date;
  drone?: Drone | string | null;
  liveStream?: DroneLiveStream | string | null;
  thumbnail?: DroneLiveStreamThumbnailMedia | string | null;
  thumbnailUrl?: string;
  __t?: string;
  organization?: Organization | string | null;
  name?: string;
  url?: string;
  ext?: string;
  creationDate?: Date;
  intervention?: Intervention | string | null;
  mediaType?: MediaType;
  uploadedBy?: User | string | null;
  public constructor(init?: Partial<DroneLiveStreamMedia>) {
    super();
    Object.assign(this, init);
  }
}

export class RenameMediaInput {
  newName?: string;
  _id?: string;
  __t?: string;
  public constructor(init?: Partial<RenameMediaInput>) {
    Object.assign(this, init);
  }
}

export class UpdateMediaTags {
  _id?: string;
  tags?: string[];
  public constructor(init?: Partial<UpdateMediaTags>) {
    Object.assign(this, init);
  }
}

export class GetPaginationInput {
  org?: Organization | string | null;
  limit?: number;
  page?: number;
  intervention?: Intervention | string | null;
  public constructor(init?: Partial<GetPaginationInput>) {
    Object.assign(this, init);
  }
}

export class Organization extends DbClass {
  name?: string;
  logoFileName?: string;
  public constructor(init?: Partial<Organization>) {
    super();
    Object.assign(this, init);
  }
}

export class getUsersOutput {
  users?: User[];
  members?: OrganizationMember[];
  public constructor(init?: Partial<getUsersOutput>) {
    Object.assign(this, init);
  }
}

export class UserOrganizationInput {
  organization?: Organization | string | null;
  user?: User | string | null | UserDto;
  role?: OrganizationRole;
  public constructor(init?: Partial<UserOrganizationInput>) {
    Object.assign(this, init);
  }
}

export class AddUserOutput {
  user?: User | string | null;
  startingPassword?: string;
  organizationMember?: OrganizationMember | string | null;
  msg?: string;
  public constructor(init?: Partial<AddUserOutput>) {
    Object.assign(this, init);
  }
}

export class OrganizationMember extends DbClass {
  user?: User | string | null;
  organization?: Organization | string | null;
  role?: OrganizationRole;
  public constructor(init?: Partial<OrganizationMember>) {
    super();
    Object.assign(this, init);
  }
}

export class User extends DbClass {
  email?: string;
  fullname?: string;
  defaultOrganization?: Organization | string | null;
  defaultLanguage?: UiLanguage;
  phoneNumber?: string;
  public constructor(init?: Partial<User>) {
    super();
    Object.assign(this, init);
  }
}

export class ChangeUserPassword extends DbClass {
  newPassword?: string;
  email?: string;
  password?: string;
  public constructor(init?: Partial<ChangeUserPassword>) {
    super();
    Object.assign(this, init);
  }
}

export class requestPasswordReset {
  email?: string;
  public constructor(init?: Partial<requestPasswordReset>) {
    Object.assign(this, init);
  }
}

export class ResetPasswordData {
  email?: string;
  code?: string;
  password?: string;
  public constructor(init?: Partial<ResetPasswordData>) {
    Object.assign(this, init);
  }
}

export class Annotation {
  id?: string;
  name?: string;
  featureCollection?: any;
  isShown?: boolean;
  isCollapsed?: boolean;
  public constructor(init?: Partial<Annotation>) {
    Object.assign(this, init);
  }
}

export class InvalidRequest {
  message?: string;
  public constructor(init?: Partial<InvalidRequest>) {
    Object.assign(this, init);
  }
}

export class ActiveStreamOutput extends DbClass {
  assetName?: string;
  drone?: Drone | string | null;
  creationDate?: Date;
  endDate?: Date;
  organization?: Organization | string | null;
  outputAssetName?: string;
  jobName?: string;
  organizationMedia?: OrganizationMedia | string | null;
  jobOutput?: string;
  liveStream?: DroneLiveStream | string | null;
  deleteTransformOnCompletionName?: string;
  performClippingOnCompletion?: boolean;
  public constructor(init?: Partial<ActiveStreamOutput>) {
    super();
    Object.assign(this, init);
  }
}

export class ProcessedStreamOutput extends DbClass {
  assetName?: string;
  drone?: Drone | string | null;
  creationDate?: Date;
  endDate?: Date;
  organization?: Organization | string | null;
  outputAssetName?: string;
  jobName?: string;
  organizationMedia?: OrganizationMedia | string | null;
  jobOutput?: string;
  liveStream?: DroneLiveStream | string | null;
  deleteTransformOnCompletionName?: string;
  performClippingOnCompletion?: boolean;
  public constructor(init?: Partial<ProcessedStreamOutput>) {
    super();
    Object.assign(this, init);
  }
}

export class DroneJob extends DbClass {
  __t?: string;
  requested?: Date;
  drone?: Drone | string | null;
  intervention?: Intervention | string | null;
  stream?: DroneLiveStream | string | null;
  assetCreated?: Date;
  inputAssetName?: string;
  outputAssetName?: string;
  jobCreated?: Date;
  jobName?: string;
  jobStarted?: Date;
  jobProgress?: number;
  jobEnded?: Date;
  public constructor(init?: Partial<DroneJob>) {
    super();
    Object.assign(this, init);
  }
}

export class DroneSnapshotJob extends DbClass {
  time?: number;
  transformName?: string;
  transformCreated?: Date;
  transformDeleted?: Date;
  result?: DroneLiveSnapshotMedia | string | null;
  uploaded?: Date;
  __t?: string;
  requested?: Date;
  drone?: Drone | string | null;
  intervention?: Intervention | string | null;
  stream?: DroneLiveStream | string | null;
  assetCreated?: Date;
  inputAssetName?: string;
  outputAssetName?: string;
  jobCreated?: Date;
  jobName?: string;
  jobStarted?: Date;
  jobProgress?: number;
  jobEnded?: Date;
  public constructor(init?: Partial<DroneSnapshotJob>) {
    super();
    Object.assign(this, init);
  }
}

export class DroneLiveEvent extends DbClass {
  name?: string;
  status?: LiveEventStatus;
  lastUserActivation?: Date;
  lastUserDeactivation?: Date;
  lastUserAutoShutDownReschedule?: Date;
  lastAutoDeactivation?: Date;
  lastHeartBeat?: Date;
  lastConnection?: Date;
  lastDisconnection?: Date;
  lastOutputCreationRequest?: Date;
  lastOutputCreationResponse?: Date;
  assetName?: string;
  healthy?: boolean;
  public constructor(init?: Partial<DroneLiveEvent>) {
    super();
    Object.assign(this, init);
  }
}

export class DroneLiveStream extends DbClass {
  name?: string;
  drone?: Drone | string | null;
  organization?: Organization | string | null;
  streamOutputUrl?: string;
  isEventRunning?: boolean;
  outputAssetName?: string;
  startDate?: Date;
  endDate?: Date;
  intervention?: Intervention | string | null;
  public constructor(init?: Partial<DroneLiveStream>) {
    super();
    Object.assign(this, init);
  }
}

export class DroneRecording extends DbClass {
  drone?: Drone | string | null;
  organization?: Organization | string | null;
  media?: OrganizationMedia | string | null;
  stream?: DroneLiveStream | string | null;
  startTime?: number;
  endTime?: number;
  outputAssetName?: string;
  jobName?: string;
  startDate?: Date;
  endDate?: Date;
  intervention?: Intervention | string | null;
  public constructor(init?: Partial<DroneRecording>) {
    super();
    Object.assign(this, init);
  }
}

export enum InterventionType {
  OPS = 'OPS',
  PRV = 'PRV',
  COM = 'COM',
  TRAINING = 'TRAINING',
}

export enum MediaEventType {
  NewMedia = 'NewMedia',
}

export class MediaEvent {
  eventType?: MediaEventType;
  mediaId?: string;
  organizationId?: string;
  public constructor(init?: Partial<MediaEvent>) {
    Object.assign(this, init);
  }
}

export enum DroneEventType {
  DroneConnected = 'DroneConnected',
  DroneConnecting = 'DroneConnecting',
  DroneDisconnectedEcho = 'DroneDisconnectedEcho',
  DroneDisconnected = 'DroneDisconnected',
  DroneStartRecording = 'DroneStartRecording',
  DroneStoppedRecording = 'DroneStoppedRecording',
  DroneStopLiveStreaming = 'DroneStopLiveStreaming',
  DroneStopLiveStreamingReminder = 'DroneStopLiveStreamingReminder',
}

export class DroneEvent {
  eventType?: DroneEventType;
  droneId?: string;
  organizationId?: string;
  public constructor(init?: Partial<DroneEvent>) {
    Object.assign(this, init);
  }
}

export class DroneCommand {
  commandId?: string;
  eventType?: DroneEventType;
  droneId?: string;
  organizationId?: string;
  public constructor(init?: Partial<DroneCommand>) {
    Object.assign(this, init);
  }
}

export enum MessageType {
  subscription = 'subscription',
}

export class FeMessage {
  messageType?: MessageType;
  public constructor(init?: Partial<FeMessage>) {
    Object.assign(this, init);
  }
}

class OrganizationWithUser extends Organization {
  user?: User | string | null;
}

export class OrganizationSubscription {
  organization?: OrganizationWithUser | string | null;
  messageType?: MessageType;
  public constructor(init?: Partial<OrganizationSubscription>) {
    Object.assign(this, init);
  }
}

export class DeletedOrganizationMedia extends DbClass {
  __t?: string;
  organization?: Organization | string | null;
  name?: string;
  url?: string;
  ext?: string;
  creationDate?: Date;
  intervention?: Intervention | string | null;
  mediaType?: MediaType;
  uploadedBy?: User | string | null;
  public constructor(init?: Partial<DeletedOrganizationMedia>) {
    super();
    Object.assign(this, init);
  }
}

export class DroneLiveStreamThumbnailMedia extends DbClass {
  drone?: Drone | string | null;
  liveStream?: DroneLiveStream | string | null;
  __t?: string;
  organization?: Organization | string | null;
  name?: string;
  url?: string;
  ext?: string;
  creationDate?: Date;
  intervention?: Intervention | string | null;
  mediaType?: MediaType;
  uploadedBy?: User | string | null;
  public constructor(init?: Partial<DroneLiveStreamThumbnailMedia>) {
    super();
    Object.assign(this, init);
  }
}

export class UploadedPictureMedia extends DbClass {
  __t?: string;
  organization?: Organization | string | null;
  name?: string;
  url?: string;
  ext?: string;
  creationDate?: Date;
  intervention?: Intervention | string | null;
  mediaType?: MediaType;
  uploadedBy?: User | string | null;
  public constructor(init?: Partial<UploadedPictureMedia>) {
    super();
    Object.assign(this, init);
  }
}

export class UploadedVideoMedia extends DbClass {
  __t?: string;
  organization?: Organization | string | null;
  name?: string;
  url?: string;
  ext?: string;
  creationDate?: Date;
  intervention?: Intervention | string | null;
  mediaType?: MediaType;
  uploadedBy?: User | string | null;
  public constructor(init?: Partial<UploadedVideoMedia>) {
    super();
    Object.assign(this, init);
  }
}

export enum OrganizationRole {
  Admin = 'Admin',
  Member = 'Member',
  Guest = 'Guest',
}

export class ActivityNewMedia extends DbClass {
  media?: OrganizationMedia | string | null;
  organization?: Organization | string | null;
  summary?: string;
  date?: Date;
  createdBy?: User | string | null;
  public constructor(init?: Partial<ActivityNewMedia>) {
    super();
    Object.assign(this, init);
  }
}

export class ActivityDroneLaunch extends DbClass {
  drone?: Drone | string | null;
  organization?: Organization | string | null;
  summary?: string;
  date?: Date;
  createdBy?: User | string | null;
  public constructor(init?: Partial<ActivityDroneLaunch>) {
    super();
    Object.assign(this, init);
  }
}

export class ActivityInterventionAdded extends DbClass {
  intervention?: Intervention | string | null;
  organization?: Organization | string | null;
  summary?: string;
  date?: Date;
  createdBy?: User | string | null;
  public constructor(init?: Partial<ActivityInterventionAdded>) {
    super();
    Object.assign(this, init);
  }
}

export class UserPassword extends DbClass {
  email?: string;
  password?: string;
  public constructor(init?: Partial<UserPassword>) {
    super();
    Object.assign(this, init);
  }
}

export class Admin extends DbClass {
  user?: User | string | null;
  public constructor(init?: Partial<Admin>) {
    super();
    Object.assign(this, init);
  }
}

export class UserAuth extends DbClass {
  token?: string;
  email?: string;
  fullname?: string;
  defaultOrganization?: Organization | string | null;
  defaultLanguage?: UiLanguage;
  public constructor(init?: Partial<UserAuth>) {
    super();
    Object.assign(this, init);
  }
}

export class RequestPasswordReset extends DbClass {
  email?: string;
  code?: string;
  creationDate?: Date;
  public constructor(init?: Partial<RequestPasswordReset>) {
    super();
    Object.assign(this, init);
  }
}

export enum MediaType {
  picture = 'picture',
  video = 'video',
  thumbnail = 'thumbnail',
  partial = 'partial',
  other = 'other',
}

export enum StreamingStatus {
  waitingDroneInput = 'waitingDroneInput',
  streaming = 'streaming',
  networkError = 'networkError',
}

export enum UiLanguage {
  en = 'en',
  it = 'it',
  fr = 'fr',
  de = 'de',
  es = 'es',
}

export interface UserWithRole {
  user: User;
  role: OrganizationRole;
}

export interface UserWithGroups {
  user: UserDto;
  groups: OrganizationGroupDto[];
}

export enum LiveEventStatus {
  Deleting = 'Deleting',
  Running = 'Running',
  Starting = 'Starting',
  Stopped = 'Stopped',
  Stopping = 'Stopping',
  StandBy = 'StandBy',
}

export interface IHostedComponent {
  componentType: Type<any>;
  componentData?: any;
}

export interface IComponentWithData {
  data: any;
}
