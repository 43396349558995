import { Injectable, Inject } from '@angular/core';
import { StorageFactoryService } from '../storage';
import { Feature } from './feature';
import { validate, required } from '../../shared';
import { featuresConfig } from './features.config';
import { has, omit, find } from 'lodash';
import { featuresToken } from './features-token';
import { LoggerService } from '../logger.service';
import { FeaturesManager } from './features-manager';

@Injectable({
  providedIn: 'root',
})
export class FeaturesService {
  private readonly storageFactoryService: StorageFactoryService;
  private readonly loggerService: LoggerService;
  private readonly featuresDefaults: Feature[];

  constructor(
    storageFactoryService: StorageFactoryService,
    loggerService: LoggerService,
    @Inject(featuresToken) featuresDefaults: Feature[]
  ) {
    this.storageFactoryService = storageFactoryService;
    this.loggerService = loggerService;
    this.featuresDefaults = featuresDefaults;
  }

  getFeatures(): Feature[] {
    const features = FeaturesManager.getFeatures();
    return features;
  }

  @validate
  getFeatureById(@required featureId: string): Feature {
    const feature = FeaturesManager.getFeatureById(featureId);

    return feature;
  }

  @validate
  checkIsFeatureEnabled(@required featureId: string): boolean {
    const isEnabled = FeaturesManager.checkIsFeatureEnabled(featureId);

    return isEnabled;
  }

  @validate
  overrideFeature(@required featureId: string, @required isEnabled: boolean) {
    const featureDefaults = this.getFeatureDefaultsById(featureId);
    const featuresOverrides = this.getFeaturesOverrides();

    const isOverrided = has(featuresOverrides, featureId);

    let updatedFeaturesOverrides: StringMap<boolean>;
    if (isOverrided && featureDefaults.isEnabledByDefault === isEnabled) {
      updatedFeaturesOverrides = omit(featuresOverrides, featureId);
    } else if (featureDefaults.isEnabledByDefault === isEnabled) {
      updatedFeaturesOverrides = featuresOverrides;
    } else {
      updatedFeaturesOverrides = { ...featuresOverrides, [featureId]: isEnabled };
    }

    this.setFeaturesOverrides(updatedFeaturesOverrides);
  }

  clearFeaturesOverrides() {
    this.setFeaturesOverrides({});
  }

  @validate
  private getFeatureDefaultsById(@required featureId: string): Feature {
    const featureDefaults = find(this.featuresDefaults, (feature: Feature) => feature.id === featureId);

    if (!featureDefaults) {
      this.loggerService.warning(`Feature "${featureId}" is not defined.`);
      return featuresConfig.undefinedFeature;
    }

    return featureDefaults;
  }

  private getFeaturesOverrides(): StringMap<boolean> {
    const storeService = this.storageFactoryService.getStorage(featuresConfig.storageType);

    const featuresOverrides = storeService.get<StringMap<boolean>>(featuresConfig.storageKey) || {};

    return featuresOverrides;
  }

  private setFeaturesOverrides(featuresOverrides: StringMap<boolean>) {
    const storeService = this.storageFactoryService.getStorage(featuresConfig.storageType);

    storeService.set(featuresConfig.storageKey, featuresOverrides || {});
    FeaturesManager.intitialize(this.featuresDefaults, featuresOverrides || {});
  }
}
