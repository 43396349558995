/**
 * MidGard API
 * MidGard API summary
 *
 * OpenAPI spec version: 2.0
 * Contact: contact@midgard-ai.co
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ApiHttpException } from '../model/apiHttpException';
import { ContextPermissionDto } from '../model/contextPermissionDto';
import { ContextPermissionsDto } from '../model/contextPermissionsDto';
import { CreateUserDto } from '../model/createUserDto';
import { CreateUserWithGroupsDto } from '../model/createUserWithGroupsDto';
import { CreatedUserDto } from '../model/createdUserDto';
import { ObjectIdDto } from '../model/objectIdDto';
import { OrganizationGroupCreationDto } from '../model/organizationGroupCreationDto';
import { OrganizationGroupDto } from '../model/organizationGroupDto';
import { SimpleIdDto } from '../model/simpleIdDto';
import { UserDto } from '../model/userDto';
import { UserWithGroupDto } from '../model/userWithGroupDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class OrganizationGroupService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Get the organization members
     * 
     * @param orgId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public orgOrganizationGroupControllerGetAllOrgUsers(orgId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<UserWithGroupDto>>;
    public orgOrganizationGroupControllerGetAllOrgUsers(orgId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UserWithGroupDto>>>;
    public orgOrganizationGroupControllerGetAllOrgUsers(orgId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UserWithGroupDto>>>;
    public orgOrganizationGroupControllerGetAllOrgUsers(orgId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling orgOrganizationGroupControllerGetAllOrgUsers.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<UserWithGroupDto>>('get',`${this.basePath}/api/v2/organizations/${encodeURIComponent(String(orgId))}/all-users`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the organization groups
     * 
     * @param orgId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public orgOrganizationGroupControllerGetOrgGroups(orgId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<OrganizationGroupDto>>;
    public orgOrganizationGroupControllerGetOrgGroups(orgId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<OrganizationGroupDto>>>;
    public orgOrganizationGroupControllerGetOrgGroups(orgId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<OrganizationGroupDto>>>;
    public orgOrganizationGroupControllerGetOrgGroups(orgId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling orgOrganizationGroupControllerGetOrgGroups.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<OrganizationGroupDto>>('get',`${this.basePath}/api/v2/organizations/${encodeURIComponent(String(orgId))}/groups`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the organization groups with the given role
     * 
     * @param orgId 
     * @param role 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public orgOrganizationGroupControllerGetOrgGroupsByRole(orgId: string, role: string, observe?: 'body', reportProgress?: boolean): Observable<Array<OrganizationGroupDto>>;
    public orgOrganizationGroupControllerGetOrgGroupsByRole(orgId: string, role: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<OrganizationGroupDto>>>;
    public orgOrganizationGroupControllerGetOrgGroupsByRole(orgId: string, role: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<OrganizationGroupDto>>>;
    public orgOrganizationGroupControllerGetOrgGroupsByRole(orgId: string, role: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling orgOrganizationGroupControllerGetOrgGroupsByRole.');
        }

        if (role === null || role === undefined) {
            throw new Error('Required parameter role was null or undefined when calling orgOrganizationGroupControllerGetOrgGroupsByRole.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<OrganizationGroupDto>>('get',`${this.basePath}/api/v2/organizations/${encodeURIComponent(String(orgId))}/groups/${encodeURIComponent(String(role))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get organization groups permission, create etc..
     * 
     * @param orgId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public orgOrganizationGroupControllerGetOrgGroupsPermission(orgId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ContextPermissionDto>>;
    public orgOrganizationGroupControllerGetOrgGroupsPermission(orgId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ContextPermissionDto>>>;
    public orgOrganizationGroupControllerGetOrgGroupsPermission(orgId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ContextPermissionDto>>>;
    public orgOrganizationGroupControllerGetOrgGroupsPermission(orgId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling orgOrganizationGroupControllerGetOrgGroupsPermission.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ContextPermissionDto>>('get',`${this.basePath}/api/v2/organizations/${encodeURIComponent(String(orgId))}/groups/my-permissions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the organization members
     * 
     * @param orgId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public orgOrganizationGroupControllerGetOrgMembers(orgId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<UserDto>>;
    public orgOrganizationGroupControllerGetOrgMembers(orgId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UserDto>>>;
    public orgOrganizationGroupControllerGetOrgMembers(orgId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UserDto>>>;
    public orgOrganizationGroupControllerGetOrgMembers(orgId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling orgOrganizationGroupControllerGetOrgMembers.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<UserDto>>('get',`${this.basePath}/api/v2/organizations/${encodeURIComponent(String(orgId))}/members`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * return if the current user have admin  role in the organization
     * 
     * @param orgId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public orgOrganizationGroupControllerIsOrgAdmin(orgId: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public orgOrganizationGroupControllerIsOrgAdmin(orgId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public orgOrganizationGroupControllerIsOrgAdmin(orgId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public orgOrganizationGroupControllerIsOrgAdmin(orgId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling orgOrganizationGroupControllerIsOrgAdmin.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('get',`${this.basePath}/api/v2/organizations/${encodeURIComponent(String(orgId))}/roles/admin`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * remove user from organization
     * 
     * @param body user id
     * @param orgId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public orgOrganizationGroupControllerRemoveUser(body: SimpleIdDto, orgId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<UserWithGroupDto>>;
    public orgOrganizationGroupControllerRemoveUser(body: SimpleIdDto, orgId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UserWithGroupDto>>>;
    public orgOrganizationGroupControllerRemoveUser(body: SimpleIdDto, orgId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UserWithGroupDto>>>;
    public orgOrganizationGroupControllerRemoveUser(body: SimpleIdDto, orgId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling orgOrganizationGroupControllerRemoveUser.');
        }

        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling orgOrganizationGroupControllerRemoveUser.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<UserWithGroupDto>>('delete',`${this.basePath}/api/v2/organizations/${encodeURIComponent(String(orgId))}/all-users`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add user to the group
     * 
     * @param body user id
     * @param groupId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organizationGroupControllerAddUser(body: ObjectIdDto, groupId: string, observe?: 'body', reportProgress?: boolean): Observable<OrganizationGroupDto>;
    public organizationGroupControllerAddUser(body: ObjectIdDto, groupId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrganizationGroupDto>>;
    public organizationGroupControllerAddUser(body: ObjectIdDto, groupId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrganizationGroupDto>>;
    public organizationGroupControllerAddUser(body: ObjectIdDto, groupId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling organizationGroupControllerAddUser.');
        }

        if (groupId === null || groupId === undefined) {
            throw new Error('Required parameter groupId was null or undefined when calling organizationGroupControllerAddUser.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<OrganizationGroupDto>('put',`${this.basePath}/api/v2/organization-groups/${encodeURIComponent(String(groupId))}/users`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create organization group
     * 
     * @param body new group data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organizationGroupControllerCreate(body: OrganizationGroupCreationDto, observe?: 'body', reportProgress?: boolean): Observable<OrganizationGroupDto>;
    public organizationGroupControllerCreate(body: OrganizationGroupCreationDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrganizationGroupDto>>;
    public organizationGroupControllerCreate(body: OrganizationGroupCreationDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrganizationGroupDto>>;
    public organizationGroupControllerCreate(body: OrganizationGroupCreationDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling organizationGroupControllerCreate.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<OrganizationGroupDto>('post',`${this.basePath}/api/v2/organization-groups`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create user in this group
     * 
     * @param body new user
     * @param groupId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organizationGroupControllerCreateUser(body: CreateUserDto, groupId: string, observe?: 'body', reportProgress?: boolean): Observable<CreatedUserDto>;
    public organizationGroupControllerCreateUser(body: CreateUserDto, groupId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreatedUserDto>>;
    public organizationGroupControllerCreateUser(body: CreateUserDto, groupId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreatedUserDto>>;
    public organizationGroupControllerCreateUser(body: CreateUserDto, groupId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling organizationGroupControllerCreateUser.');
        }

        if (groupId === null || groupId === undefined) {
            throw new Error('Required parameter groupId was null or undefined when calling organizationGroupControllerCreateUser.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CreatedUserDto>('post',`${this.basePath}/api/v2/organization-groups/${encodeURIComponent(String(groupId))}/users`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create user in the given groups
     * 
     * @param body new user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organizationGroupControllerCreateUserWithGroups(body: CreateUserWithGroupsDto, observe?: 'body', reportProgress?: boolean): Observable<CreatedUserDto>;
    public organizationGroupControllerCreateUserWithGroups(body: CreateUserWithGroupsDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreatedUserDto>>;
    public organizationGroupControllerCreateUserWithGroups(body: CreateUserWithGroupsDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreatedUserDto>>;
    public organizationGroupControllerCreateUserWithGroups(body: CreateUserWithGroupsDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling organizationGroupControllerCreateUserWithGroups.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CreatedUserDto>('post',`${this.basePath}/api/v2/organization-groups/users`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Group
     * 
     * @param groupId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organizationGroupControllerDelete(groupId: string, observe?: 'body', reportProgress?: boolean): Observable<OrganizationGroupDto>;
    public organizationGroupControllerDelete(groupId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrganizationGroupDto>>;
    public organizationGroupControllerDelete(groupId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrganizationGroupDto>>;
    public organizationGroupControllerDelete(groupId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (groupId === null || groupId === undefined) {
            throw new Error('Required parameter groupId was null or undefined when calling organizationGroupControllerDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<OrganizationGroupDto>('delete',`${this.basePath}/api/v2/organization-groups/${encodeURIComponent(String(groupId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Organizations group
     * 
     * @param groupId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organizationGroupControllerGet(groupId: string, observe?: 'body', reportProgress?: boolean): Observable<OrganizationGroupDto>;
    public organizationGroupControllerGet(groupId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrganizationGroupDto>>;
    public organizationGroupControllerGet(groupId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrganizationGroupDto>>;
    public organizationGroupControllerGet(groupId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (groupId === null || groupId === undefined) {
            throw new Error('Required parameter groupId was null or undefined when calling organizationGroupControllerGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<OrganizationGroupDto>('get',`${this.basePath}/api/v2/organization-groups/${encodeURIComponent(String(groupId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get authenticated user&#x27;s Organizations groups
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organizationGroupControllerGetAll(observe?: 'body', reportProgress?: boolean): Observable<Array<OrganizationGroupDto>>;
    public organizationGroupControllerGetAll(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<OrganizationGroupDto>>>;
    public organizationGroupControllerGetAll(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<OrganizationGroupDto>>>;
    public organizationGroupControllerGetAll(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<OrganizationGroupDto>>('get',`${this.basePath}/api/v2/organization-groups`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the Group permission
     * 
     * @param groupId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organizationGroupControllerGetGroupPermissions(groupId: string, observe?: 'body', reportProgress?: boolean): Observable<ContextPermissionsDto>;
    public organizationGroupControllerGetGroupPermissions(groupId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ContextPermissionsDto>>;
    public organizationGroupControllerGetGroupPermissions(groupId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ContextPermissionsDto>>;
    public organizationGroupControllerGetGroupPermissions(groupId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (groupId === null || groupId === undefined) {
            throw new Error('Required parameter groupId was null or undefined when calling organizationGroupControllerGetGroupPermissions.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ContextPermissionsDto>('get',`${this.basePath}/api/v2/organization-groups/${encodeURIComponent(String(groupId))}/my-permissions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * remove user to the group
     * 
     * @param body user to remove id
     * @param groupId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organizationGroupControllerRemoveUser(body: ObjectIdDto, groupId: string, observe?: 'body', reportProgress?: boolean): Observable<OrganizationGroupDto>;
    public organizationGroupControllerRemoveUser(body: ObjectIdDto, groupId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrganizationGroupDto>>;
    public organizationGroupControllerRemoveUser(body: ObjectIdDto, groupId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrganizationGroupDto>>;
    public organizationGroupControllerRemoveUser(body: ObjectIdDto, groupId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling organizationGroupControllerRemoveUser.');
        }

        if (groupId === null || groupId === undefined) {
            throw new Error('Required parameter groupId was null or undefined when calling organizationGroupControllerRemoveUser.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<OrganizationGroupDto>('delete',`${this.basePath}/api/v2/organization-groups/${encodeURIComponent(String(groupId))}/users`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get user groups in the organization
     * 
     * @param orgId 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userOrganizationGroupsControllerGetUserGroupsInOrganization(orgId: string, userId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<OrganizationGroupDto>>;
    public userOrganizationGroupsControllerGetUserGroupsInOrganization(orgId: string, userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<OrganizationGroupDto>>>;
    public userOrganizationGroupsControllerGetUserGroupsInOrganization(orgId: string, userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<OrganizationGroupDto>>>;
    public userOrganizationGroupsControllerGetUserGroupsInOrganization(orgId: string, userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling userOrganizationGroupsControllerGetUserGroupsInOrganization.');
        }

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling userOrganizationGroupsControllerGetUserGroupsInOrganization.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<OrganizationGroupDto>>('get',`${this.basePath}/api/v2/users/${encodeURIComponent(String(userId))}/organizations/${encodeURIComponent(String(orgId))}/groups`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * return if the user have admin  role in the organization
     * 
     * @param orgId 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userOrganizationGroupsControllerGetUserRolesInOrganization(orgId: string, userId: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public userOrganizationGroupsControllerGetUserRolesInOrganization(orgId: string, userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public userOrganizationGroupsControllerGetUserRolesInOrganization(orgId: string, userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public userOrganizationGroupsControllerGetUserRolesInOrganization(orgId: string, userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling userOrganizationGroupsControllerGetUserRolesInOrganization.');
        }

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling userOrganizationGroupsControllerGetUserRolesInOrganization.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('get',`${this.basePath}/api/v2/users/${encodeURIComponent(String(userId))}/organizations/${encodeURIComponent(String(orgId))}/roles/admin`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
