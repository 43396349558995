<mid-simple-dashboard-block [title]="'MEDIA' | translate" class="media-dashboard-block-container">
  <mid-block-carousel
    class="w-100"
    (leftArrowClick)="onPreviousMedia()"
    (rightArrowClick)="onNextMedia()"
    [isLeftArrowEnabled]="isPreviousPageExists"
    [isRightArrowEnabled]="isNextPageExists"
  >
    <div class="media-dashboard" [ngSwitch]="dashboardBehavior$?.getValue()">
      <!-- import media -->
      <div class="import-media" *ngSwitchCase="DashboardBehavior.importMedia">
        {{ 'MEDIA_DASHBOARD.MSG.IMPORT_MEDIA' | translate }}
      </div>
      <!-- media cards -->
      <ng-container *ngSwitchDefault>
        <app-media-card
          class="media-card"
          *ngFor="let media of dataSource.paginatedItems"
          [media]="media"
          [interventionIdFilter]="intervention?._id"
          [mediaPageLocation]="MediaPageLocation.intervention"
          [canDelete]="canDelete"
          (mediaToDelete)="onMediaDeleted($event)"
        ></app-media-card>
      </ng-container>
    </div>
  </mid-block-carousel>
  <div class="d-flex justify-content-center pt-3 pb-3 mt-2" [ngSwitch]="dashboardBehavior$?.getValue()">
    <app-mid-button class="mr-1" type="secondary" (action)="goToAllMedia()" icon="fas fa-photo-video">
      {{ 'COMMON_SEE_ALL' | translate }} ({{ dataSourceLength }})
    </app-mid-button>
    <app-media-upload [intervention]="intervention"></app-media-upload>
  </div>
</mid-simple-dashboard-block>
