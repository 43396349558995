import { Injectable } from '@angular/core';
import { omit } from 'lodash';
import { StorageFactoryService } from '../storage';
import { coreConfig } from '../core.config';
import { required, StringUtils, validate } from 'src/app/shared';

@Injectable({
  providedIn: 'root',
})
export class StoreStateService {
  private readonly storageFactoryService: StorageFactoryService;

  constructor(storageFactoryService: StorageFactoryService) {
    this.storageFactoryService = storageFactoryService;
  }

  saveState<T>(state: Partial<T> = {}, ignore: string[] = coreConfig.state.ingoredStates) {
    const stateToPersist = omit(state, ignore);

    this.storage.set(coreConfig.state.key, stateToPersist);
  }

  getState<T>(): T {
    const state = this.storage.get<T>(coreConfig.state.key);

    return state;
  }

  @validate
  restoreFeatureState<TFeatureState>(
    @required featureKey: string,
    @required initialState: TFeatureState
  ): TFeatureState {
    const stateHash = StringUtils.hash(JSON.stringify(initialState));
    const featureHashKey = StringUtils.format(coreConfig.state.featureHashKeyFormat, featureKey);
    const previousStateHash = this.storage.get(featureHashKey);

    if (stateHash !== previousStateHash) {
      this.storage.set(featureHashKey, stateHash);
      return initialState;
    }

    const restoredState = this.getFeatureState<TFeatureState>(featureKey);
    if (!restoredState) {
      return initialState;
    }

    return restoredState;
  }

  clearState() {
    this.storage.remove(coreConfig.state.key);
  }

  @validate
  private getFeatureState<TFeatureState, TState extends { [key: string]: TFeatureState } = any>(
    @required featureKey: string
  ): TFeatureState {
    const state = this.getState<TState>();

    if (!state) {
      return null;
    }

    const featureState: TFeatureState = state[featureKey];

    return featureState;
  }

  private get storage() {
    return this.storageFactoryService.getStorage(coreConfig.state.storage);
  }
}
