import { NgModule, InjectionToken } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { authenticationReducer, authenticationInitialState, AuthenticationState } from './authentication.reducer';
import { authenticationConfig } from '../authentication.config';
import { StoreStateService, featureStoreConfigFactory } from 'src/app/core/store';
import { StoreConfig } from '@ngrx/store/src/store_module';

const featureStoreKey = authenticationConfig.store.key;

export const authenticationFeatureStoreKeyToken = new InjectionToken(
  `FeatureStoreKeyInjectionToken:${featureStoreKey}`
);
export const authenticationFeatureStoreInitialStateToken = new InjectionToken<AuthenticationState>(
  `FeatureStoreInitialStateInjectionToken:${featureStoreKey}`
);
export const authenticationStoreFeatureConfigToken = new InjectionToken<StoreConfig<AuthenticationState>>(
  `FeatureStoreConfigInjectionToken:${featureStoreKey}`
);

@NgModule({
  imports: [StoreModule.forFeature(featureStoreKey, authenticationReducer, authenticationStoreFeatureConfigToken)],
  providers: [
    {
      provide: authenticationFeatureStoreKeyToken,
      useValue: featureStoreKey,
    },
    {
      provide: authenticationFeatureStoreInitialStateToken,
      useValue: authenticationInitialState,
    },
    {
      provide: authenticationStoreFeatureConfigToken,
      deps: [authenticationFeatureStoreKeyToken, authenticationFeatureStoreInitialStateToken, StoreStateService],
      useFactory: featureStoreConfigFactory,
    },
  ],
})
export class AuthenticationStoreModule {}
