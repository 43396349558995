<div class="select-container" [class.select-label]="!!label">
  <label *ngIf="label" [class.required]="required">{{ label }}</label>
  <div
    class="select-wrapper"
    [class.default]="size === MidSelectSize.default"
    [class.wide]="size === MidSelectSize.wide"
  >
    <select
      appCountryFlags
      [(ngModel)]="selectedItem"
      (ngModelChange)="onItemsChange($event)"
      [name]="name"
      [disabled]="disabled"
      [required]="required"
      [class.placeholder]="selectedItem === ''"
    >
      <option class="placeholder" *ngIf="placeholder" [ngValue]="''" [selected]="selectedItem === ''">
        {{ placeholder }}
      </option>
      <option class="item" *ngFor="let item of items" [value]="item.icon">
        {{ item.name }}
      </option>
    </select>
  </div>
  <ng-content></ng-content>
</div>
