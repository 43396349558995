import { Intervention } from '../../autogenerated/model';
import { Component, ComponentFactoryResolver, ComponentRef, OnDestroy, OnInit, Type, ViewChild } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { Subscription } from 'rxjs';
import { Organization } from 'src/app/autogenerated/model';
import { OrganizationService } from 'src/app/autogenerated/organizationService';
import { AdHostDirective } from 'src/app/core/ad-host.directive';
import { OrganizationStoreService } from 'src/app/organization/store';
import { required, validate } from 'src/app/shared';
import { interventionConfig } from '../intervention.config';
import { InterventionStoreService } from '../store';
import { InterventionService } from 'src/app/autogenerated/interventionService';
import { Unsubscribe } from '../../core/decorators';
import { OrganizationGroupService } from 'src/api';

export interface InterventionTabContentComponent {
  intervention: Intervention;
  organization: Organization;
  hasAdminPermissions: boolean;
}

@Unsubscribe()
@Component({
  selector: 'app-intervention-tab-container',
  templateUrl: './intervention-tab-container.component.html',
  styleUrls: ['./intervention-tab-container.component.scss'],
})
export class InterventionTabContainerComponent implements OnInit, OnDestroy {
  @ViewChild(AdHostDirective, { static: true })
  adHost: AdHostDirective;

  organization: Organization;

  private isAdmin: boolean;
  private routeDataSubscription: Subscription;
  private organizationSubscription: Subscription;
  private interventionSubscription: Subscription;
  private componentRef: ComponentRef<any>;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly interventionService: InterventionService,
    private readonly interventionStoreService: InterventionStoreService,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly organizationStoreService: OrganizationStoreService,
    private readonly organizationGroupService: OrganizationGroupService,
    private readonly organizationService: OrganizationService
  ) {
    this.isAdmin = false;
  }

  ngOnInit() {
    this.routeDataSubscription = this.route.data.subscribe((routeData: Data) => this.onRouteDataChanged(routeData));
    this.subscribeOnOrganization();
  }

  ngOnDestroy(): void {}

  private subscribeOnOrganization() {
    this.organizationSubscription = this.organizationStoreService
      .getCurrentOrganization()
      .subscribe(async (organization) => {
        if (!organization || this.organization === organization) {
          return;
        }
        this.organization = organization;
        this.isAdmin = await this.checkForAdminRole(organization._id);
      });
  }

  private async checkForAdminRole(organizationId: string): Promise<boolean> {
    try {
      const isAdmin = await this.organizationGroupService
        .orgOrganizationGroupControllerIsOrgAdmin(organizationId)
        .toPromise();
      return isAdmin;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  @validate
  private async onRouteDataChanged(@required routeData: Data) {
    this.cancelInterventionSubscription();

    const componentType = routeData.componentType;
    if (componentType) {
      this.loadComponent(componentType);
    }

    const interventionId: string = routeData[interventionConfig.routes.params.interventionId] || null;
    if (interventionId) {
      await this.interventionIdChanged(interventionId);
    }
  }

  private async interventionIdChanged(interventionId: string): Promise<void> {
    // take from cache
    this.interventionSubscription = this.interventionStoreService
      .getInterventionById(interventionId)
      .subscribe((cacheIntervention: Intervention) =>
        this.onInterventionChanged(this.organization, cacheIntervention, this.isAdmin)
      );

    // + refresh from backend
    try {
      const intervention = await this.interventionService.getInterventionsWithMapping(this.organization);
      this.interventionStoreService.setOrganizationInterventions(this.organization._id, intervention);
    } catch {
      // TODO: show an error on UI
    }
  }

  @validate
  private loadComponent(@required componentType: Type<InterventionTabContentComponent>) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
    const viewContainerRef = this.adHost.viewContainerRef;
    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent<InterventionTabContentComponent>(componentFactory);
  }

  private onInterventionChanged(organization: Organization, intervention: Intervention, hasAdminPermissions: boolean) {
    if (!this.componentRef) {
      return;
    }

    const component = this.componentRef.instance as InterventionTabContentComponent;
    component.intervention = intervention;
    component.organization = organization;
    component.hasAdminPermissions = hasAdminPermissions;
  }

  private cancelInterventionSubscription() {
    if (!this.interventionSubscription) {
      return;
    }

    try {
      this.interventionSubscription.unsubscribe();
    } catch {
      // ignore
    } finally {
      this.interventionSubscription = null;
    }
  }
}
