import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mid-right-panel-header',
  templateUrl: './mid-right-panel-header.component.html',
  styleUrls: ['./mid-right-panel-header.component.scss'],
})
export class MidRightPanelHeaderComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
