import { AdHostDirective } from 'src/app/core/ad-host.directive';
import { Component, OnInit, ViewChild, ComponentRef, Input, ComponentFactoryResolver } from '@angular/core';
import { IComponentWithData, IHostedComponent } from '../../autogenerated/model';

@Component({
  selector: 'app-activity-stream-item-host',
  templateUrl: './activity-stream-item-host.component.html',
  styleUrls: ['./activity-stream-item-host.component.scss'],
})
export class ActivityStreamItemHostComponent implements OnInit {
  @ViewChild(AdHostDirective, { static: true }) adHost: AdHostDirective;

  @Input() hostedComponent: IHostedComponent;

  private componentRef: ComponentRef<any>;

  constructor(private readonly componentFactoryResolver: ComponentFactoryResolver) {}
  ngOnInit(): void {
    this.loadComponent();
  }

  private loadComponent() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.hostedComponent.componentType);
    const viewContainerRef = this.adHost.viewContainerRef;
    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent<IComponentWithData>(componentFactory);
    this.componentRef.instance.data = this.hostedComponent.componentData;
  }
}
