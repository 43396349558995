import { DroneModule } from './../drone/drone.module';
import { DroneLiveComponent } from './items/DroneLive/DroneLive.component';
import { RouterModule } from '@angular/router';
import { NewMediaComponent } from './items/new-media/new-media.component';
import { SimpleTextComponent } from './items/SimpleText/SimpleText.component';
import { ActivityStreamItemHostComponent } from './activity-stream-item-host/activity-stream-item-host.component';
import { CoreModule } from './../core/core.module';
import { OrganizationActivityStreamComponent } from './organization-activity-stream/organization-activity-stream.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    OrganizationActivityStreamComponent,
    ActivityStreamItemHostComponent,
    SimpleTextComponent,
    NewMediaComponent,
    DroneLiveComponent,
  ],
  imports: [CommonModule, CoreModule, RouterModule, DroneModule],
  exports: [OrganizationActivityStreamComponent],
})
export class ActivityStreamModule {}
