import { Component, OnInit } from '@angular/core';
import { MediaUploadService } from '../media-upload.service';
import { MediaType } from '../../../../autogenerated/model';

@Component({
  selector: 'app-media-notification-progress',
  templateUrl: './media-notification-progress.component.html',
  styleUrls: ['./media-notification-progress.component.scss'],
})
export class MediaNotificationProgressComponent implements OnInit {
  get MediaType() {
    return MediaType;
  }
  constructor(public mediaUploadService: MediaUploadService) {}

  ngOnInit(): void {}
  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
