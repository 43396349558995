<div *ngIf="media" class="mid-notification-item-panel">
  <div class="row">
    <div class="col-3 my-auto" [ngSwitch]="media.mediaType">
      <img *ngSwitchCase="MediaType.picture" src="/assets/images/mid-activity-stream-icon-image.png" alt="" />
      <img *ngSwitchCase="MediaType.video" src="/assets/images/mid-activity-stream-icon-video.png" alt="" />
    </div>

    <div class="col-7 pl-0 pr-1">
      <div class="row">
        <div class="col-12 mid-activity-stream-new-media-title">
          {{ media.name }}
        </div>

        <div class="col-12 mid-activity-stream-new-media-name">
          {{ media.creationDate | date : 'dd/MM/yyyy HH:mm' }}
        </div>
      </div>
    </div>
    <div class="col-2 p-0 my-auto">
      <button class="preview-btn" (click)="goToMedia()">
        <i class="fas fa-eye"></i>
      </button>
    </div>
  </div>
</div>
