import { StreamApplication } from '../app/drone/models/stream-application.enum';

export const environment = {
  production: true,
  domain: 'app.midgard-ai.com',
  backend: 'https://midgard-prd-app-api.azurewebsites.net/',
  webSocket: 'wss://midgard-prd-app-api.azurewebsites.net/',
  streamApplications: {
    [StreamApplication.LiveApp]: {
      baseUrl: 'https://live.midgard-ai.com/LiveApp/',
      playUrl: 'https://live.midgard-ai.com/LiveApp/play.html?name=broadcast_',
    },
    [StreamApplication.WebRTCApp]: {
      baseUrl: 'https://live.midgard-ai.com/WebRTCAppEE/',
      playUrl: 'https://live.midgard-ai.com/WebRTCAppEE/play.html?name=',
    },
  },
  tinyMceKey: 'o8lmdg34cv3v4a2d3lnxbacqiorr2msll01lvxc63m5y5cus',
  appInsightsKey: '4307dc11-b960-44ca-9244-e205a88b599b',
};
