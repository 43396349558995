import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'tdraw-pinpoint-image-modal',
  templateUrl: './pinpoint-image-modal.component.html',
  styleUrls: ['./pinpoint-image-modal.component.scss'],
})
export class PinpointImageModalComponent {
  imageUrl: string;

  constructor(private dialogRef: MatDialogRef<PinpointImageModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.imageUrl = data.imageUrl;
  }

  onClose() {
    this.dialogRef.close();
  }
}
