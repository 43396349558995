import { AfterContentInit, Component, ContentChildren, QueryList } from '@angular/core';
import { ToolsPanelTabComponent } from '../tools-panel-tab/tools-panel-tab.component';

@Component({
  selector: 'tdraw-tools-panel',
  templateUrl: './tools-panel.component.html',
  styleUrls: ['./tools-panel.component.scss'],
})
export class ToolsPanelComponent implements AfterContentInit {
  @ContentChildren(ToolsPanelTabComponent) tabs: QueryList<ToolsPanelTabComponent>;

  ngAfterContentInit() {
    const activeTabs = this.tabs.filter((tab) => tab.active);
    if (activeTabs.length === 0) {
      this.selectTab(this.tabs.first);
    }
  }

  selectTab(tab: ToolsPanelTabComponent) {
    this.tabs.forEach((tab) => (tab.active = false));
    tab.active = true;
  }
}
