<div class="edit-panel">
  <div class="d-flex">
    <app-mid-icon-button type="primary" (action)="close()">
      <i class="fas fa-times"></i>
    </app-mid-icon-button>

    <div class="labels ml-3">
      <div class="description-box">
        <div class="description">
          {{ date | date : 'dd MMMM yyyy / HH:mm' : undefined : translate.getDefaultLang() }}
        </div>
      </div>
    </div>
  </div>

  <app-mid-button [matMenuTriggerFor]="actionsMenu" type="secondary">
    {{ 'TDRAW.ACTIONS' | translate }} <i class="fas fa-ellipsis-v ml-3"></i>
  </app-mid-button>
</div>

<mat-menu #actionsMenu="matMenu" class="mid-menu" panelClass="pt-0">
  <div class="mid-menu-item" (click)="printSnapshot()">
    <i class="fas fa-print mr-2 mid-menu-item-icon"></i>
    {{ 'TDRAW.PRINT' | translate }}
  </div>
  <div class="mid-menu-item" (click)="downloadSnapshot()">
    <i class="fas fa-camera mr-2 mid-menu-item-icon"></i>
    {{ 'TDRAW.SCREENSHOT' | translate }}
  </div>
  <div class="mid-menu-item" (click)="openExportModal()">
    <i class="fas fa-file-export mr-2 mid-menu-item-icon"></i>
    {{ 'EXPORT' | translate }}
  </div>
  <div class="mid-menu-item" (click)="duplicateHistory()">
    <i class="fas fa-copy mr-2 mid-menu-item-icon"></i>
    {{ 'TDRAW.DUPLICATE_AND_MODIFY' | translate }}
  </div>
</mat-menu>

<div class="map-container">
  <div class="mapbox-container">
    <div id="map-pic-view"></div>
    <tdraw-mapmode-info [isEditable]="false"> </tdraw-mapmode-info>
  </div>

  <tdraw-right-panel [data]="rightPanelData" *ngIf="viewModeManager">
    <tdraw-readonly-properties-panel
      *ngIf="rightPanelData.mode === RightPanelMode.Properties"
      [viewModeManager]="viewModeManager"
      (goToToolbox)="setRightPanelMode(RightPanelMode.Navigation)"
    >
    </tdraw-readonly-properties-panel>

    <tdraw-tools-panel [class.d-none]="rightPanelData.mode !== RightPanelMode.Navigation">
      <tdraw-tools-panel-tab [title]="'INFORMATIONS' | translate" fa-icon="far fa-file-alt">
        <tdraw-pic-info-tab [mediaId]="mapState?.backgroundMedia?.mediaId"> </tdraw-pic-info-tab>
      </tdraw-tools-panel-tab>

      <tdraw-tools-panel-tab [title]="'LAYERS' | translate" fa-icon="fas fa-layer-group">
        <tdraw-layers-tab [mapModeManager]="viewModeManager" [statistics]="mapState?.statistics"> </tdraw-layers-tab>
      </tdraw-tools-panel-tab>
    </tdraw-tools-panel>
  </tdraw-right-panel>
</div>
