import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanMatch,
  Route,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { take } from 'rxjs/operators';
import { AuthenticationStoreService } from './store';
import { authenticationConfig } from './authentication.config';
import { AuthenticationService } from './authentication.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate, CanMatch {
  private readonly authenticationStoreService: AuthenticationStoreService;
  private readonly authenticationService: AuthenticationService;
  private readonly router: Router;

  constructor(
    authenticationStoreService: AuthenticationStoreService,
    authenticationService: AuthenticationService,
    router: Router
  ) {
    this.authenticationStoreService = authenticationStoreService;
    this.authenticationService = authenticationService;
    this.router = router;
  }
  async canMatch(route: Route, segments: UrlSegment[]): Promise<boolean> {
    return await this.canActivate(null, this.router.routerState.snapshot);
  }

  async canActivate(_next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const isAuthenticated: boolean = await this.authenticationStoreService
      .checkIsAuthenticated()
      .pipe(take(1))
      .toPromise();

    if (!isAuthenticated) {
      await this.authenticationService.setReturnUrl(state.url, true);
    }

    return isAuthenticated;
  }
}
