import { MidSelectItem } from '../models/select-item';

export class MidSelectItemUtils {
  static fromMap(items: { [k: string]: string }): MidSelectItem[] {
    return Object.keys(items).map((key) => ({
      isSelected: false,
      value: key,
      name: items[key],
    }));
  }
}
