import { AnnotationCategory } from '../models/annotation-category.enum';
import { MapState } from '../models/map-state.model';
import { MapStateKmlExporter } from './mapstate-kml-exporter';

/**
 * Class to export MapState in various formats
 */
export class MapStateExporter {
  constructor(private mapState: MapState) {}

  downloadKML() {
    const exporter = new MapStateKmlExporter(this.mapState);
    const kml = exporter.getKML();
    this.initDownload('kml', kml);
  }

  downloadGeoJSON() {
    const annotations = this.mapState.annotations;
    const exportedFeatures = [];

    for (const annotation of annotations) {
      if (annotation.category != AnnotationCategory.Shapes) {
        continue;
      }
      exportedFeatures.push(...annotation.data.features);
    }

    const data = JSON.stringify({
      features: exportedFeatures,
      type: 'FeatureCollection',
    });

    this.initDownload('json', data);
  }

  // TODO: Rework it when we have more export formats
  private initDownload(format: string, data: string) {
    const link = document.createElement('a');
    link.download = `file.${format}`;
    link.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(data);
    link.click();
  }
}
