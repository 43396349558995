import { createFeatureSelector, MemoizedSelector, createSelector } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { AuthenticationState } from './authentication.reducer';
import { authenticationConfig } from '../authentication.config';

export const selectAuthenticationState: MemoizedSelector<AppState, AuthenticationState> = createFeatureSelector(
  authenticationConfig.store.key
);

export const selectAccessToken = createSelector(selectAuthenticationState, (state: AuthenticationState) =>
  state.accessToken ? state.accessToken : null
);

export const selectIsAuthenticated = createSelector(
  selectAuthenticationState,
  (state: AuthenticationState) => state.isAuthenticated
);

export const selectAuthenticatedUserId = createSelector(
  selectAuthenticationState,
  (state: AuthenticationState) => state.userId
);

export const selectAuthenticatedOrganizationId = createSelector(
  selectAuthenticationState,
  (state: AuthenticationState) => state.organizationId
);

export const selectReturnUrl = createSelector(
  selectAuthenticationState,
  (state: AuthenticationState) => state.returnUrl
);
