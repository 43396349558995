import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InterventionNameBadgeComponent } from './intervention-name-badge/intervention-name-badge.component';
import { FormsModule } from '@angular/forms';
import { CoreModule } from 'src/app/core/core.module';
import { SelectInterventionComponent } from './select-intervention/select-intervention.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from 'src/app/shared/shared.module';
import { MidgardControlsModule } from 'src/app/midgard-controls/midgard-controls.module';
import { SelectInterventionTypeButtonTogglesComponent } from './select-intervention-type-button-toggles/select-intervention-type-button-toggles.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InterventionAlertModalComponent } from './intervention-alert-modal/intervention-alert-modal.component';
import { InterventionAlertsComponent } from './intervention-alerts/intervention-alerts.component';
import { InterventionAlertComponent } from './intervention-alert/intervention-alert.component';
import { SelectMediaButtonToggleComponent } from './select-media-button-toggle/select-media-button-toggle.component';

@NgModule({
  declarations: [
    InterventionNameBadgeComponent,
    SelectInterventionComponent,
    SelectInterventionTypeButtonTogglesComponent,
    InterventionAlertModalComponent,
    InterventionAlertsComponent,
    InterventionAlertComponent,
    SelectMediaButtonToggleComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    CoreModule,
    SharedModule,
    MatDialogModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatDividerModule,
    MatIconModule,
    MatButtonToggleModule,
    MatTooltipModule,
    MidgardControlsModule,
  ],
  exports: [
    InterventionNameBadgeComponent,
    SelectInterventionComponent,
    SelectInterventionTypeButtonTogglesComponent,
    InterventionAlertModalComponent,
    SelectMediaButtonToggleComponent,
  ],
})
export class InterventionCommonModule {}
