import { Component, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { FeaturesService, Feature } from '../../core/features';
import { each } from 'lodash';
import { DataSourceService, DataSource, DataSourceState } from '../../core/collections';
import { Subject, Subscription } from 'rxjs';
import { Unsubscribe } from '../../core/decorators';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { coreConfig } from 'src/app/core/core.config';

@Unsubscribe()
@Component({
  selector: 'app-features-form',
  templateUrl: './features-form.component.html',
  styleUrls: ['./features-form.component.scss'],
})
export class FeaturesFormComponent implements OnInit, OnDestroy {
  private readonly featuresService: FeaturesService;
  private readonly dataSourceService: DataSourceService;

  private features: Feature[];

  dataSource: DataSource<Feature>;
  dataSourceState: DataSourceState<Feature>;

  @Output()
  readonly saved: EventEmitter<void>;

  state: {
    filter$: Subject<string>;
  };

  filterSubscription: Subscription;

  get homeRoute() {
    return [];
  }

  constructor(featuresService: FeaturesService, dataSourceService: DataSourceService) {
    this.featuresService = featuresService;
    this.dataSourceService = dataSourceService;

    this.state = {
      filter$: new Subject(),
    };

    this.dataSource = new DataSource();
    this.dataSourceState = new DataSourceState<Feature>({
      searchableFields: ['id', 'name', 'description'] as FieldsOf<Feature>,
      page: 1,
      pageSize: Number.MAX_SAFE_INTEGER,
    });

    this.saved = new EventEmitter();
  }

  ngOnInit() {
    this.loadFeatures();

    this.filterSubscription = this.state.filter$
      .pipe(debounceTime(coreConfig.filter.textFilterDebounceTime), distinctUntilChanged())
      .subscribe((filter: string) => this.updateFilter(filter));
  }

  ngOnDestroy(): void {}

  onFilterInput(filter: string) {
    this.state.filter$.next(filter);
  }

  onSave() {
    each(this.features, (feature: Feature) => {
      this.featuresService.overrideFeature(feature.id, feature.isEnabled);
    });

    this.saved.next();
  }

  onReset() {
    this.featuresService.clearFeaturesOverrides();
    this.loadFeatures();
    this.saved.next();
  }

  private updateFilter(filter: string) {
    this.dataSourceState.filter = filter;
    this.dataSource = this.dataSourceService.filterDataSource(this.dataSource, this.dataSourceState);
  }

  private loadFeatures() {
    this.features = this.featuresService.getFeatures();
    this.dataSource = this.dataSourceService.createDataSource(this.features, this.dataSourceState);
  }
}
