<div class="assign-drone-header">
  <div class="assign-drone-title">
    <span class="title">{{ 'LINK_DRONE_TO_INTERVENTION' | translate }}</span>
  </div>
  <div class="close-icon" mat-dialog-close>
    <i class="fas fa-times icon"></i>
  </div>
</div>

<div class="assign-drone-body">
  <div class="steps">
    <div class="step">
      <div class="step-main">
        <div class="step-info">
          <span class="info">{{ 'DRONE_SELECT' | translate }}</span>
        </div>
      </div>
    </div>
  </div>
  <app-mid-select
    size="wide"
    [(items)]="data.drones"
    [placeholder]="'DIALOGS.CREATE_INTERVENTION.ASSOCIATED_DRONE_PLACEHOLDER' | translate"
  ></app-mid-select>
</div>

<div class="d-flex justify-content-center pt-3 pb-3 mt-2">
  <app-mid-button type="secondary" (action)="onCancel()">{{ 'COMMON_CANCEL' | translate }}</app-mid-button>
  <app-mid-button type="primary" icon="fas fa-check" (action)="onSelectDrone()" class="ml-1" [disabled]="!data">{{
    'SUBMIT' | translate
  }}</app-mid-button>
</div>
