import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { FeatureModule, OrganizationDto, OrganizationsService } from 'src/api';
import { Unsubscribe } from 'src/app/core/decorators';
import { TemplateService } from 'src/app/global/template.service';
import { UserProfilesStoreService } from 'src/app/user-profile/store';
import { AuthenticationService } from '../../authentication/authentication.service';
import { FeaturesDialogComponent } from './features-dialog/features-dialog.component';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

export class FeaturesActivation implements FeatureModule {
  name: string;
  iconFilename: string;
  featureId: string;
  isEnabled: boolean = false;
  translatedName?: string;
}

export class OrganizationFeaturesActivation implements OrganizationDto {
  location?: { lng: number; lat: number };
  id: string;
  name: string;
  logoFileName?: string;
  features: FeaturesActivation[];
  featureIds?: string[];
  logoUrl?: string;
}

@Unsubscribe()
@Component({
  selector: '[app-organizations]',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.scss'],
})
export class OrganizationsComponent implements OnInit, OnDestroy {
  public organizations: OrganizationFeaturesActivation[] = [];
  public filteredOrganizations: OrganizationFeaturesActivation[] = [];
  public newOrganization = { name: '', features: [] as FeaturesActivation[] };
  public createdOrganization: OrganizationDto;
  public loadingOrgs = true;
  public loadingFeatures = true;
  public availableFeatures: FeatureModule[] = [];

  // status submit button
  public inSaving = false;
  public saved = false;
  public errorForm = false;

  public readonly logoUrlPrefix: string = 'public/logos/';

  //filter
  public filterArg: string = '';

  private currentUserSubscription: Subscription;

  constructor(
    public dialog: MatDialog,
    private readonly organizationsService: OrganizationsService,
    private readonly userProfilesStoreService: UserProfilesStoreService,
    private readonly templateService: TemplateService,
    private readonly authenticationService: AuthenticationService,
    private readonly translate: TranslateService
  ) {}

  ngOnInit() {
    this.templateService.$activeComponent.next('Admin - Organizations');
    // when the user login we download the list of organizations

    this.loadOrganizations();
    this.loadFeatures();
  }

  private loadFeatures() {
    this.organizationsService
      .organizationControllerGetAllFeatures()
      .toPromise()
      .then((features) => {
        this.availableFeatures = features;
        this.loadingFeatures = false;
        if (this.isReady()) {
          this.manageOrganizationFeatures();
        }
      });
  }

  isReady(): boolean {
    return !this.loadingOrgs && !this.loadingFeatures;
  }

  private loadOrganizations() {
    this.currentUserSubscription = this.userProfilesStoreService.getCurrentUser().subscribe(async (user) => {
      if (!user) {
        await this.authenticationService.signOut();
      }
      this.organizationsService
        .organizationControllerGetOrganizations()
        .toPromise()
        .then((all: OrganizationFeaturesActivation[]) => {
          this.organizations = all.sort((org1, org2) => (org1.name > org2.name ? 1 : -1));
          this.filteredOrganizations = [...this.organizations];
          this.loadingOrgs = false;
          if (this.isReady()) {
            this.manageOrganizationFeatures();
          }
        });
    });
  }

  ngOnDestroy(): void {
    // for Unsubscribe
  }

  public createOrganization(): void {
    // check if name is empty
    if (this.newOrganization.name === '') {
      this.errorForm = true;
      setTimeout(() => {
        this.errorForm = false;
      }, 1000);
      return;
    }

    // change status for form button
    this.inSaving = true;

    this.organizationsService
      .organizationControllerCreate(this.newOrganization)
      .toPromise()
      .then((org) => {
        // change status for form button
        this.inSaving = false;
        this.saved = true;
        // set Timeout for last change status
        setTimeout(() => {
          this.saved = false;
        }, 1000);

        this.createdOrganization = org;
        if (org) {
          this.newOrganization = {
            name: '',
            features: this.availableFeatures.map((x) => {
              return { ...x, isEnabled: false };
            }),
          };

          this.organizations.push({
            ...org,
            features: this.availableFeatures.map((x) => {
              return { ...x, isEnabled: false };
            }),
          });

          this.organizations = this.organizations.sort((org1, org2) => (org1.name > org2.name ? 1 : -1));
        }
      });
  }

  openDialog(org: OrganizationFeaturesActivation) {
    const config = {
      id: 'featuresDialog',
      width: '50%',
      data: {
        org,
      },
    };
    const dialogRef = this.dialog.open(FeaturesDialogComponent, config);
  }

  async manageOrganizationFeatures(): Promise<void> {
    this.organizations = await Promise.all(
      this.organizations.map(async (org) => {
        const features = this.availableFeatures.map((f) => {
          return { ...f, isEnabled: false };
        });
        org.features = [...features];
        org.logoUrl = '';
        if (org?.logoFileName) {
          org.logoUrl = environment.backend + this.logoUrlPrefix + org.logoFileName;
        }
        org.features = await Promise.all(
          org.features.map(async (f) => {
            const isEnabled =
              org.featureIds.length != 0 && org.featureIds.find((fIds) => fIds == f.featureId) != undefined;
            const translatedName = await this.translate
              .get('ADMINISTRATION.ORGANIZATIONS.FEATURES.' + f.featureId.toUpperCase())
              .toPromise();
            return { ...f, isEnabled, translatedName };
          })
        );
        return org;
      })
    );
  }

  filterOrg() {
    this.filteredOrganizations = this.organizations.filter((org) =>
      org.name?.toLocaleLowerCase().includes(this.filterArg.toLocaleLowerCase())
    );
  }
}
