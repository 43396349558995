<tdraw-right-panel-container>
  <div role="content">
    <form #formAnnotationProperty="ngForm">
      <a class="back-link mb-1 font-weight-bold" href="javascript:void(0)" (click)="backToToolbox()"
        ><i class="fas fa-chevron-left"></i> {{ 'BACK_TOOLBOX' | translate }}</a
      >
      <h4 class="mb-4 font-weight-bold">{{ 'OBJECT_INFORMATION' | translate }}</h4>

      <div *ngIf="selectedFeature.properties[FeatureProperty.IsCone]">
        <div class="text-with-border mt-4">{{ 'CONE_CONTROL' | translate }}</div>

        <div class="fields mt-2">
          <app-mid-button
            (action)="editModeManager.mapManager.coneMoveMode()"
            type="secondary"
            class="control-btn mr-2"
            [class.active]="!isConeRotateMode()"
          >
            <i class="fas fa-expand-arrows-alt"></i>
          </app-mid-button>

          <app-mid-button
            (action)="editModeManager.mapManager.coneRotateMode()"
            type="secondary"
            class="control-btn"
            [class.active]="isConeRotateMode()"
          >
            <i class="fas fa-sync-alt"></i>
          </app-mid-button>
        </div>
      </div>

      <div class="text-with-border mt-4">{{ 'GENERAL_INFORMATION' | translate }}</div>

      <div class="fields mt-2">
        <div *ngIf="selectedFeature.geometry?.type === 'Point'">
          <span>{{ 'TRIGRAM' | translate }}</span>
          <app-mid-input
            [value]="selectedFeature.properties[FeatureProperty.Trigram]"
            (valueChange)="setFeatureProperty(FeatureProperty.Trigram, $event)"
          >
          </app-mid-input>

          <div *ngIf="mapIcon?.trigrams" class="trigram-selector-container">
            <app-mid-button
              *ngFor="let trigram of mapIcon.trigrams"
              type="secondary"
              (action)="setFeatureProperty(FeatureProperty.Trigram, trigram)"
            >
              <span class="trigram-btn-text">{{ trigram }}</span>
            </app-mid-button>
          </div>
        </div>

        <span>{{ 'NAME' | translate }}</span>
        <app-mid-input
          [value]="selectedFeature.properties[FeatureProperty.Title]"
          (valueChange)="setFeatureProperty(FeatureProperty.Title, $event)"
        >
        </app-mid-input>

        <span>{{ 'DESCRIPTION' | translate }}</span>
        <app-mid-input
          [value]="selectedFeature.properties[FeatureProperty.Description]"
          (valueChange)="setFeatureProperty(FeatureProperty.Description, $event)"
        >
        </app-mid-input>
      </div>

      <div class="mt-3" *ngIf="reversedIcon">
        <span class="mb-2">{{ 'TDRAW.REVERSE' | translate }}</span>
        <app-mid-button type="secondary" (action)="reversePolyline()" size="wide">{{
          'TDRAW.REVERSE_LINE' | translate
        }}</app-mid-button>
      </div>

      <div class="mt-3" *ngIf="selectedFeature.geometry?.type === 'Point'">
        <span class="mb-2">{{ 'TDRAW.CHANGE' | translate }}</span>
        <app-mid-button type="secondary" (action)="openSitacSelectorModal()" size="wide">{{
          'TDRAW.CHANGE_ICON' | translate
        }}</app-mid-button>
      </div>

      <div class="mt-3" *ngIf="!selectedFeature.properties?.icon">
        <span class="mb-2">{{ 'COLOR' | translate }}</span>
        <div class="color-picker">
          <div
            *ngFor="let color of featureColors"
            class="color-picker-item"
            [style.background-color]="color"
            [class.selected-color]="selectedFeature.properties[FeatureProperty.Color] === color"
            (click)="setFeatureProperty(FeatureProperty.Color, color)"
          ></div>
          <i class="fas fa-question-circle question-icon" matTooltip="{{ 'COLOR_PICKER_HINT' | translate }}"></i>
        </div>
      </div>

      <div class="mt-3">
        <span class="mb-0">{{ 'OPACITY' | translate }}</span>
        <mat-slider
          class="w-100 ml-0"
          min="0.2"
          max="1"
          step="0.1"
          [value]="
            selectedFeature.properties?.hasOwnProperty(FeatureProperty.Opacity)
              ? selectedFeature.properties[FeatureProperty.Opacity]
              : 0.5
          "
          (input)="setFeatureProperty(FeatureProperty.Opacity, $event.value)"
          (change)="setFeatureProperty(FeatureProperty.Opacity, $event.value)"
        ></mat-slider>
        <div class="slider-percent">
          <div>20%</div>
          <div>100%</div>
        </div>
      </div>

      <div class="mt-3" *ngIf="selectedFeature.geometry?.type === 'Point'">
        <span class="mb-0">{{ 'SIZE' | translate }}</span>
        <mat-slider
          class="w-100 ml-0"
          min="0.5"
          max="5"
          step="0.1"
          [value]="
            selectedFeature.properties?.hasOwnProperty(FeatureProperty.Size)
              ? selectedFeature.properties[FeatureProperty.Size]
              : 1
          "
          (input)="setFeatureProperty(FeatureProperty.Size, $event.value)"
          (change)="setFeatureProperty(FeatureProperty.Size, $event.value)"
        ></mat-slider>
        <div class="slider-percent">
          <div>50%</div>
          <div>500%</div>
        </div>
      </div>

      <div class="mt-3" *ngIf="selectedFeature.geometry?.type === 'Point'">
        <span class="mb-0">{{ 'ROTATION' | translate }}</span>
        <mat-slider
          class="w-100 ml-0"
          min="0"
          max="360"
          step="1"
          [value]="
            selectedFeature.properties?.hasOwnProperty(FeatureProperty.Rotation)
              ? selectedFeature.properties[FeatureProperty.Rotation]
              : 0
          "
          (input)="setFeatureProperty(FeatureProperty.Rotation, $event.value)"
          (change)="setFeatureProperty(FeatureProperty.Rotation, $event.value)"
        ></mat-slider>
        <div class="slider-percent">
          <div>0°</div>
          <div>360°</div>
        </div>
      </div>

      <div class="mt-3" *ngIf="selectedFeature.properties[FeatureProperty.IsCone]">
        <span class="mb-0">{{ 'TDRAW.CONE_LENGTH' | translate }}</span>
        <mat-slider
          class="w-100 ml-0"
          min="20"
          max="110"
          step="1"
          [value]="selectedFeature.properties[FeatureProperty.Wind]"
          (input)="onChangeConeWind($event.value)"
        ></mat-slider>
        <div class="slider-percent">
          <div></div>
          <div></div>
        </div>
      </div>
    </form>
  </div>

  <div role="footer">
    <div class="px-3">
      <app-mid-button type="destructive" (action)="onDeleteElementFromMap()" size="wide">{{
        'REMOVE' | translate
      }}</app-mid-button>
    </div>
  </div>
</tdraw-right-panel-container>
