import { Component, Input, OnInit } from '@angular/core';
import { OrganizationDto, OrganizationGroupService, OrganizationsService } from 'src/api';
import { OrganizationNavConfiguration } from '../organization-nav-configuration';

@Component({
  selector: 'app-organization-tab-header',
  templateUrl: './organization-tab-header.component.html',
  styleUrls: ['./organization-tab-header.component.scss'],
})
export class OrganizationTabHeaderComponent implements OnInit {
  @Input()
  organization: OrganizationDto;

  canManageAccessCodeTab = false;

  constructor(private readonly organizationsService: OrganizationsService) {}

  async ngOnInit() {
    await this.getOrganizationPermissions();
  }

  private async getOrganizationPermissions() {
    const orgPermissions = await this.organizationsService
      .organizationControllerGetOrganizationPermissions(this.organization.id)
      .toPromise();
    this.canManageAccessCodeTab = orgPermissions.update;
  }

  get organizationPropertiesRoute(): string[] {
    if (!this.organization) return [];
    return ['/', 'organization', this.organization.id];
  }

  get organizationUsersRoute(): string[] {
    if (!this.organization) return [];
    return ['/', 'organization', this.organization.id, OrganizationNavConfiguration.routes.users];
  }

  get organizationGroupRoute(): string[] {
    if (!this.organization) return [];
    return ['/', 'organization', this.organization.id, OrganizationNavConfiguration.routes.groups];
  }

  get organizationDistributionRoute(): string[] {
    if (!this.organization) return [];
    return ['/', 'organization', this.organization.id, OrganizationNavConfiguration.routes.distribution];
  }

  get organizationProfileRoute(): string[] {
    if (!this.organization) return [];
    return ['/', 'organization', this.organization.id, OrganizationNavConfiguration.routes.profile];
  }
  get organizationAccessCodeRoute(): string[] {
    if (!this.organization) return [];
    return ['/', 'organization', this.organization.id, OrganizationNavConfiguration.routes.accessCode];
  }
}
