<app-modal-header
  text="{{ 'TDRAW.MODAL.UPDATE_STATISTICS.TITLE' | translate }}"
  (closeAction)="onClose()"
></app-modal-header>
<div class="body">
  <form #formTDrawUpdateStatistics="ngForm">
    <p>{{ 'TDRAW.MODAL.UPDATE_STATISTICS.TEXT' | translate }}</p>
    <div class="buttons">
      <app-mid-button type="secondary" (action)="onClose()">{{ 'COMMON_CANCEL' | translate }}</app-mid-button>
      <app-mid-button type="primary" (action)="onSubmit()">
        <i class="fas fa-check"></i> {{ 'VALIDATE' | translate }}
      </app-mid-button>
    </div>
  </form>
</div>
