import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { CheckboxItem } from 'src/app/midgard-controls/models';

@Component({
  selector: 'app-mid-statistics-other-services',
  templateUrl: './mid-statistics-other-services.component.html',
  styleUrls: ['./mid-statistics-other-services.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  exportAs: 'appMidStatisticsOtherServices',
})
export class MidStatisticsOtherServicesComponent implements OnInit, OnChanges {
  @Input()
  items: string[];

  @Input()
  isEditable: boolean;

  @Input()
  isExpanded: boolean;

  @Output()
  itemsChange: EventEmitter<string[]>;

  total: number;

  name = 'appMidStatisticsOtherServices_' + ((Math.random() * 10000) | 0);
  newServiceName: string;

  services: CheckboxItem[] = [];
  defaultServices: CheckboxItem[] = [
    { name: 'Police', value: 'Police', isChecked: false },
    { name: 'EDF', value: 'EDF', isChecked: false },
    { name: 'GDF', value: 'GDF', isChecked: false },
    { name: 'ONF', value: 'ONF', isChecked: false },
    { name: 'SAMU', value: 'SAMU', isChecked: false },
    { name: 'Préfet/Maire', value: 'PrefetMaire', isChecked: false },
  ];

  constructor() {
    this.itemsChange = new EventEmitter<string[]>();
    this.total = 0;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.services = [...this.defaultServices];
    if (this.items?.length) {
      this.items.forEach((it) => {
        const service = this.services?.find((s) => s.value === it);
        if (!service) {
          this.services.push({ name: it, value: it, isChecked: true });
        } else {
          service.isChecked = true;
        }
      });
      this.total = this.items?.length || 0;
    }
  }

  ngOnInit(): void {}

  AddService(newService: string) {
    const check = this.services.find((o) => o.name === newService);
    if (newService.length > 0 && !check) {
      this.services.push({ name: newService, value: newService, isChecked: true });
      this.newServiceName = '';
      this.emitCheckedServices();
    }
  }

  onChange(event: any) {
    const service = this.services?.find((it) => it.value === event?.target?.value);
    if (service) {
      service.isChecked = event.target.checked;
      this.emitCheckedServices();
    }
  }

  private emitCheckedServices() {
    this.items = this.services?.filter((it) => it.isChecked)?.map((it) => it.value);
    this.total = this.items?.length || 0;
    this.itemsChange.emit(this.items);
  }
}
