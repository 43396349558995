import { createFeatureSelector, MemoizedSelector, createSelector } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { organizationConfig } from '../organization.config';
import { OrganizationState } from './organization.reducer';

export const selectOrganizationState: MemoizedSelector<AppState, OrganizationState> = createFeatureSelector(
  organizationConfig.store.key
);

export const selectCurrentOrganization = createSelector(
  selectOrganizationState,
  (state: OrganizationState) => state.currentOrganization
);
