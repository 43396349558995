import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { OrganizationGroupService } from 'src/api';
import { Drone } from 'src/app/autogenerated/model';
import { OrganizationStoreService } from 'src/app/organization/store';
import { Unsubscribe } from '../../../core/decorators';
import { DroneUtils } from '../../utils/drone-utils';

@Unsubscribe()
@Component({
  selector: 'app-drone-stream-player',
  templateUrl: './drone-stream-player.component.html',
  styleUrls: ['./drone-stream-player.component.scss'],
})
export class DroneStreamPlayerComponent implements OnInit, OnDestroy {
  @Input()
  drone: Drone;

  @Input()
  isFreeSpaceClickable: boolean = false;

  @Input()
  isShareButtonVisible: boolean = false;

  @Output()
  freeSpaceClicked: EventEmitter<boolean>;

  organizationId: string;
  isAdmin: boolean;

  private organizationSubscription: Subscription;

  constructor(
    private organizationStoreService: OrganizationStoreService,
    private organizationGroupService: OrganizationGroupService
  ) {
    this.freeSpaceClicked = new EventEmitter<boolean>();
    this.isAdmin = false;
  }

  async ngOnInit(): Promise<void> {
    this.organizationSubscription = this.organizationStoreService
      .getCurrentOrganization()
      .subscribe(async (organization) => {
        if (!organization || this.organizationId === organization._id) {
          return;
        }
        this.isAdmin = await this.checkForAdminRole(organization._id);
      });
  }

  getUrlVideo = DroneUtils.getHttpsStreamUrl;

  onFreeSpaceClicked() {
    if (this.isFreeSpaceClickable) {
      this.freeSpaceClicked.next(true);
    }
  }

  ngOnDestroy(): void {}

  private async checkForAdminRole(organizationId: string): Promise<boolean> {
    try {
      const isAdmin = await this.organizationGroupService
        .orgOrganizationGroupControllerIsOrgAdmin(organizationId)
        .toPromise();
      return isAdmin;
    } catch (e) {
      console.error(e);
      return false;
    }
  }
}
