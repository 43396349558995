<app-modal-header
  text="{{ 'TDRAW.MODAL.CONE_CREATION.TITLE' | translate }}"
  (closeAction)="onClose()"
></app-modal-header>
<div class="body">
  <p class="font-weight-bold">{{ 'TDRAW.MODAL.CONE_CREATION.BODY_TITLE' | translate }}</p>
  <p>{{ 'TDRAW.MODAL.CONE_CREATION.BODY_SUBTITLE' | translate }}</p>

  <mat-radio-group [(ngModel)]="wind">
    <table>
      <thead>
        <tr>
          <td colspan="3">{{ 'TDRAW.MODAL.CONE_CREATION.SPEED' | translate }}</td>
          <td colspan="4">{{ 'TDRAW.MODAL.CONE_CREATION.DISTANCE_TRAVELED' | translate }}</td>
        </tr>
        <tr>
          <td>vent</td>
          <td colspan="2">{{ 'TDRAW.MODAL.CONE_CREATION.PROPAGATION' | translate }}</td>
          <td colspan="4">{{ 'TDRAW.MODAL.CONE_CREATION.DISTANCE_ACCORDING_TO_DURATION' | translate }}</td>
        </tr>
        <tr>
          <td>km/h</td>
          <td>m/h</td>
          <td>m/mn</td>
          <td>10 mn</td>
          <td>15 mn</td>
          <td>30 mn</td>
          <td>60 mn</td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let v of coneWindValues">
          <td class="font-weight-bold">
            <mat-radio-button [value]="v.wind">{{ v.wind }} km/h</mat-radio-button>
          </td>
          <td>{{ v.propagation.byHour }}</td>
          <td>{{ v.propagation.byMinute }}</td>
          <td>{{ v.distance.for10min }}</td>
          <td>{{ v.distance.for15min }}</td>
          <td>{{ v.distance.for30min }}</td>
          <td>{{ v.distance.for60min }}</td>
        </tr>
      </tbody>
    </table>
  </mat-radio-group>

  <div class="buttons">
    <app-mid-button type="secondary" (action)="onClose()">{{ 'COMMON_CANCEL' | translate }}</app-mid-button>
    <app-mid-button type="primary" (action)="onSubmit()"
      ><i class="fas fa-check"></i> {{ 'VALIDATE_AND_CREATE' | translate }}</app-mid-button
    >
  </div>
</div>
