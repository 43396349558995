import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CoreModule } from '../core/core.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MidgardControlsModule } from '../midgard-controls/midgard-controls.module';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTabsModule } from '@angular/material/tabs';
import { InterventionModule } from '../intervention/intervention.module';
import { DroneCommonModule } from '../drone/common/drone-common.module';
import { InterventionCommonModule } from '../intervention/common/intervention-common.module';
import { AccessCodeLoginRouteComponent } from './access-code-login-route/access-code-login-route.component';
import { AccessCodeProfileComponent } from './access-code-profile/access-code-profile.component';
import { LayoutModule } from '../layout/layout.module';

@NgModule({
  declarations: [AccessCodeLoginRouteComponent, AccessCodeProfileComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    CoreModule,
    MatSnackBarModule,
    MatMenuModule,
    MidgardControlsModule,
    MatBadgeModule,
    MatTabsModule,
    MidgardControlsModule,
    InterventionModule,
    InterventionCommonModule,
    DroneCommonModule,
    LayoutModule,
  ],
})
export class AccessCodeLayoutModule {}
