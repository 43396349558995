<div class="intervention-card" [attr.disabled]="!intervention">
  <div *ngIf="!intervention">
    <span>{{ 'INTERVENTION_CARD.CONTENT.EMPTY' | translate }}</span>
  </div>
  <div *ngIf="intervention" role="button" [routerLink]="getInterventionDashboardRoute(intervention?._id)">
    <div class="d-flex justify-content-between mb-2">
      <div class="name text-truncate">
        {{ intervention.name }}
        <!-- <i class="fas fa-star"></i> -->
      </div>
      <div>
        <app-mid-icon-button type="secondary" [matMenuTriggerFor]="menu" click-stop-propagation>
          <i class="fas fa-ellipsis-v fa-sm mid-btn-icon"></i>
        </app-mid-icon-button>
        <mat-menu #menu="matMenu" class="mid-menu">
          <div class="mid-menu-item" (click)="goToMap()">
            <i class="fas fa-map mr-1 mid-menu-item-icon"></i>
            <span>{{ 'INTERVENTION_SETTINGS.CARTOGRAPHY' | translate }}</span>
          </div>
          <div class="mid-menu-item" *ngIf="permissions?.update" (click)="editIntervention()">
            <i class="fas fa-pen mr-1 mid-menu-item-icon"></i>
            <span>{{ 'INTERVENTION_EDIT' | translate }}</span>
          </div>
          <div
            class="mid-menu-item"
            *ngIf="!intervention.isClosed && permissions?.update"
            (click)="associateWithDrone()"
          >
            <i class="fas fa-plus mr-1 mid-menu-item-icon"></i>
            <span>{{ 'LINK_DRONE_TO_INTERVENTION' | translate }}</span>
          </div>
          <div class="mid-menu-item" (click)="importMedia()">
            <i class="fas fa-plus mr-1 mid-menu-item-icon"></i>
            <span>{{ 'IMPORT_MEDIA' | translate }}</span>
          </div>
          <div
            *ngIf="!intervention.isClosed && permissions?.update"
            class="mid-menu-item mid-menu-item-fill-red"
            (click)="onCloseIntervention()"
          >
            <i class="fas fa-clipboard-check mr-1 mid-menu-item-icon"></i>
            <span>{{ 'INTERVENTION_SETTINGS.BUTTON_CLOSE' | translate }}</span>
          </div>
        </mat-menu>
      </div>
    </div>

    <div class="text-truncate">
      {{ 'COMMON.INTERVENTION' | translate }} № {{ intervention.interventionNumber | defaultValue }}
    </div>

    <div class="badges row mx-0 mb-3">
      <app-mid-badge *ngIf="!intervention.isClosed" class="pr-2" badgeClass="badge-success">
        {{ 'INTERVENTION.COMMON.OPENED' | translate }}
      </app-mid-badge>
      <app-mid-badge *ngIf="intervention.isClosed" class="pr-2" badgeClass="badge-warning">
        {{ 'INTERVENTION.COMMON.CLOSED' | translate }}
      </app-mid-badge>
      <app-mid-badge *ngIf="intervention.isArchived" class="pr-2" badgeClass="badge-danger">
        {{ 'INTERVENTION.COMMON.ARCHIVE' | translate }}
      </app-mid-badge>
      <ng-container [ngSwitch]="intervention.type">
        <app-mid-badge *ngSwitchCase="InterventionType.OPS" class="pr-2" badgeClass="badge-danger">
          {{ 'INTERVENTION_TYPE.OPS' | translate }}
        </app-mid-badge>
        <app-mid-badge *ngSwitchCase="InterventionType.PRV" class="pr-2" badgeClass="badge-success">
          {{ 'INTERVENTION_TYPE.PRV' | translate }}
        </app-mid-badge>
        <app-mid-badge *ngSwitchCase="InterventionType.COM" class="pr-2" badgeClass="badge-warning">
          {{ 'INTERVENTION_TYPE.COM' | translate }}
        </app-mid-badge>
        <app-mid-badge *ngSwitchCase="InterventionType.TRAINING" class="pr-2" badgeClass="badge-success">
          {{ 'INTERVENTION_TYPE.TRAINING' | translate }}
        </app-mid-badge>
      </ng-container>
    </div>

    <!--
    <div class="mid-intervention-number">
      <span class="badge badge-light">{{ intervention.interventionNumber }}</span>
    </div>
    -->

    <div class="dates mb-3" *ngIf="intervention.startDate || intervention.updatedDate">
      <p class="date" *ngIf="intervention.startDate">
        <span>{{ 'INTERVENTION_CARD.CONTENT.START_AT' | translate }}</span>
        <span>{{ intervention.startDate | date : 'dd/MM/yyyy HH:mm' }}</span>
      </p>
      <p class="date" *ngIf="intervention.updatedDate">
        <span>{{ 'INTERVENTION_CARD.CONTENT.UPDATED_AT' | translate }}</span>
        <span>{{ intervention.updatedDate | date : 'dd/MM/yyyy HH:mm' }}</span>
      </p>
    </div>

    <!-- todo: Implement drones & media part  -->
    <!--<div class="labels">
      <p>
        <i class="fas fa-helicopter pr-1"></i>
        {{ numberDrones | defaultValue: 0 }} {{ 'COMMON.DRONES' | translate }}
      </p>
      <p>
        <i class="fas fa-photo-video pr-1"></i>
        {{ numberMedia | defaultValue: 0 }} {{ 'COMMON.MEDIA' | translate }}
      </p>
    </div>-->
  </div>
</div>
