import { Inject, Injectable, Optional } from '@angular/core';
import { BASE_PATH, Configuration, OrganizationDto } from '../api';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  private basePath = '/';
  private defaultHeaders = new HttpHeaders();
  private configuration = new Configuration();

  constructor(
    private httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  async getOrganization(organizationId: string): Promise<OrganizationDto> {
    if (!organizationId) {
      return null;
    }

    let headers = this.defaultHeaders;

    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    const response = await this.httpClient.request<OrganizationDto>(
      'get',
      `${this.basePath}/api/v2/organizations/${encodeURIComponent(organizationId as string)}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
      }
    );

    const organization = await response.toPromise();
    return organization;
  }
}
