import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { CustomClass } from '../models/customclass/custom-class.model';
import { ICustomclass } from '../models/customclass/custom-class.interface';

@Injectable({
  providedIn: 'root',
})
export class CustomClassService {
  private customClasses: CustomClass;

  public get CustomClasses() {
    return this.customClasses;
  }

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.initDefaultCustomClasses();
    this.subscribeRouterEvents();
  }

  private initDefaultCustomClasses() {
    this.customClasses = { customContainerClass: 'mid-container', customContentClass: 'content' };
  }

  private subscribeRouterEvents() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          const data = this.getRouteCustomClasses(this.activatedRoute.firstChild);
          return data?.customClasses;
        })
      )
      .subscribe(async (customClassesInfo: ICustomclass) => {
        if (customClassesInfo) {
          this.customClasses = {
            customContainerClass: customClassesInfo.customContainerClass,
            customContentClass: customClassesInfo.customContentClass,
          };
        } else {
          this.initDefaultCustomClasses();
        }
      });
  }

  private getRouteCustomClasses(customClass) {
    while (customClass) {
      if (customClass.firstChild) {
        customClass = customClass.firstChild;
        continue;
      }
      if (customClass.snapshot?.data) {
        return customClass.snapshot.data;
      }
      return null;
    }
    return null;
  }
}
