import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { CreateUserDto, OrganizationDto } from 'src/api';
import { Unsubscribe } from 'src/app/core/decorators';
import { MidRightPanelService } from 'src/app/midgard-controls/services/mid-right-panel.service';
import { NewOrganizationUserComponent } from '../new-organization-user/new-organization-user.component';
import { isEmpty } from 'lodash';

// export class CreateUserData {}

@Unsubscribe()
@Component({
  selector: 'app-add-user-button',
  templateUrl: './add-user-button.component.html',
  styleUrls: ['./add-user-button.component.scss'],
})
export class AddUserButtonComponent implements OnDestroy {
  @Input()
  org: OrganizationDto;

  @Output()
  create: EventEmitter<void>;

  private newOrganizationUserSubscription: Subscription;

  constructor(private readonly midRightPanelService: MidRightPanelService) {
    this.create = new EventEmitter();
  }

  async onAddUserClick(): Promise<void> {
    const data = { org: this.org };
    this.newOrganizationUserSubscription = this.midRightPanelService
      .open(NewOrganizationUserComponent, data)
      .subscribe((result) => {
        if (!isEmpty(result.data)) {
          this.onCreate(result.data);
        }
      });
  }

  onCreate(result): void {
    this.create.emit(result);
  }

  ngOnDestroy(): void {}
}
