import { LoggerService } from '../../../core/logger.service';
import { AuthenticationStoreService } from '../../../authentication/store';
import { environment } from '../../../../environments/environment';
import { GuidService } from '../../../global/guid.service';
import { Injectable } from '@angular/core';
import _ from 'lodash';
import HugeUploader from 'huge-uploader';
import { firstValueFrom, Observable, Subject } from 'rxjs';
import { UploadingFile } from './models/uploading-file';

@Injectable({
  providedIn: 'root',
})
export class MediaUploadService {
  mediaUploadFinished: Observable<void>;
  uploadStartDate: Date;
  fileUploadingProgress$: Subject<UploadingFile>;
  filesChange$: Subject<UploadingFile[]>;

  files: UploadingFile[] = [];

  private hasBeforeClosingSubscription: boolean;
  private mediaUploadFinishedSubject: Subject<void> = new Subject();
  private isUploadingState: boolean;

  constructor(
    private guidService: GuidService,
    private readonly authenticationStoreService: AuthenticationStoreService,
    private readonly loggerService: LoggerService
  ) {
    this.mediaUploadFinished = this.mediaUploadFinishedSubject.asObservable();
    this.isUploadingState = false;
    this.hasBeforeClosingSubscription = false;
    this.fileUploadingProgress$ = new Subject();
    this.filesChange$ = new Subject();
  }

  upload(uploadingFiles: UploadingFile[]) {
    this.uploadStartDate = new Date();
    if (uploadingFiles.length === 0) {
      return;
    }

    this.subscribeBeforeClosing();

    for (const uploadingFile of uploadingFiles) {
      const file = new UploadingFile({
        ...uploadingFile,
        progress: 0,
        guid: this.guidService.generate(),
      });

      this.files.push(file);
      this.sendToServer(file);
      this.fileUploadingProgress$.next(file);
    }
    this.filesChange$.next(this.files);
  }

  async sendToServer(uploadingFile: UploadingFile) {
    this.isUploadingState = true;

    const token = await firstValueFrom(this.authenticationStoreService.getAccessToken());

    const uploader = new HugeUploader({
      endpoint: environment.backend + `mediaService/${uploadingFile.intervention._id}/uploadMedia?token=${token}`,
      file: uploadingFile.file,
      postParams: { filename: uploadingFile.file.name },
    });
    // subscribe to events
    uploader.on('error', (err) => {
      this.fileUploadingProgress$.next(new UploadingFile({ ...uploadingFile, loadingError: true }));
      this.loggerService.error(err.detail);
      console.error('Something bad happened', err.detail);
      _.remove(this.files, (item) => item.guid === uploadingFile.guid);

      if (_.isEmpty(this.files)) {
        this.onUploadFinished();
      }
    });

    uploader.on('progress', (progress) => {
      uploadingFile.progress = progress.detail;
      this.fileUploadingProgress$.next(new UploadingFile({ ...uploadingFile, loadingError: false }));
    });

    uploader.on('finish', (body) => {
      _.remove(this.files, (item) => item.guid === uploadingFile.guid);

      if (_.isEmpty(this.files)) {
        this.onUploadFinished();
      }
    });
  }

  private subscribeBeforeClosing() {
    if (this.hasBeforeClosingSubscription) {
      return;
    }

    window.addEventListener('beforeunload', this.beforeClosingWindow, false);
    this.hasBeforeClosingSubscription = true;
  }

  private onUploadFinished() {
    window.removeEventListener('beforeunload', this.beforeClosingWindow);
    this.hasBeforeClosingSubscription = false;
    this.isUploadingState = false;
    this.mediaUploadFinishedSubject.next();
  }

  private beforeClosingWindow(e) {
    e.returnValue = true;
    return true;
  }

  // TODO: uncomment later if needed
  // sendToServerInOneShoot(file: UploadingFile) {
  //   let formData = new FormData();

  //   formData.set('file', file, file.name);

  //   this.beClient
  //     .postAndObserve<OrganizationMedia>('uploadMedia/' + file.intervention._id, formData)
  //     .subscribe((event) => {
  //       let done = false;

  //       if (event.type === HttpEventType.UploadProgress) {
  //         file.progress = Math.floor((event.loaded * 100) / event.total);
  //         done = event.loaded === event.total;
  //         return;
  //       }
  //       if (event.type === HttpEventType.Response) {
  //         console.log('upload done!');
  //         _.remove(this.files, (item) => item.guid === file.guid);
  //         return;
  //         // next we would give a notification, but actually it is done on all the medias at the org level
  //         // see app component
  //         // const newMedia =  event.body;
  //         // this.notificationService.openStandardNotification(NewMediaNotificationComponent,{media:newMedia});
  //       }
  //       //console.log(event);
  //     });
  // }
}
