<tdraw-version-list-element
  *ngFor="let item of versionItems"
  [item]="item"
  [interventionId]="interventionId"
  [isSelected]="item._id === selectedMapState._id"
  (mapStateSelected)="onMapStateSelected(item)"
  (publish)="onMapStatePublish(item)"
  (rename)="onMapStateRename(item)"
  (duplicate)="onMapStateDuplicate(item)"
  (download)="onMapStateDownload(item)"
  (deletingMapState)="onDeleteMapState(item)"
>
</tdraw-version-list-element>
