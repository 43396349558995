<div class="video-container">
  <div class="video-thumb" [class.d-block]="isVideoThumbVisible" [class.d-none]="!isVideoThumbVisible" #thumbContainer>
    <video #thumb style="width: 100%; height: 100%">
      <source [attr.src]="previewUrl" />
    </video>
    <div class="text-center">
      <i class="fa-solid fa-film" *ngIf="!streamTime"></i>
      <i class="fa-solid fa-clock" *ngIf="streamTime"></i>
      <span class="pl-2 d-none d-md-inline">{{ thumbCurrentTime | date : 'HH:mm:ss' }}</span>
    </div>
  </div>
  <video
    #video
    controls
    autoplay
    loop
    muted
    class="media-video"
    (pointermove)="mouseOnVideo($event)"
    (mouseleave)="mouseLeaveVideo()"
  >
    <source [attr.src]="mediaUrl" />
  </video>
</div>
