import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Unsubscribe } from 'src/app/core/decorators';
import { ChangeUserPassword, UiLanguage, User } from '../../autogenerated/model';
import { TemplateService } from '../../global/template.service';
import { UserProfilesStoreService } from '../store';
import { AuthenticationService } from '../../authentication/authentication.service';
import { UserService } from '../../autogenerated/userService';
import { NgForm } from '@angular/forms';
import { trim } from 'lodash';
import { PromiseUtils } from '../../core/utils/promise-utils';
import { coreConfig } from '../../core/core.config';
import { MidSelectFlag } from '../../midgard-controls/models';
import { LayoutStoreService } from '../../layout/store';
import { authenticationConfig } from '../../authentication/authentication.config';

@Unsubscribe()
@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss'],
})
export class MyProfileComponent implements OnInit, OnDestroy {
  user: User;

  data: {
    email: string;
    fullname: string;
    phoneNumber: any;
    defaultLanguage: UiLanguage;
    currentPassword: string;
    newPassword: string;
    newPasswordConfirmation: string;
  };

  state: {
    isLoadingDetails: boolean;
    isUpdatedDetails: boolean;
    isFailedDetails: boolean;
    isLoadingPassword: boolean;
    isUpdatedPassword: boolean;
    isFailedPassword: boolean;
    passwordVisibility: boolean;
    confirmPasswordVisibility: boolean;
  };

  languages: MidSelectFlag[] = [
    {
      value: UiLanguage.fr,
      name: 'Français',
      isSelected: true,
      icon: 'fr',
    },
    {
      value: UiLanguage.en,
      name: 'English',
      isSelected: false,
      icon: 'gb',
    },
    {
      value: UiLanguage.de,
      name: 'Deutsch',
      isSelected: false,
      icon: 'de',
    },
    {
      value: UiLanguage.it,
      name: 'Italiano',
      isSelected: false,
      icon: 'it',
    },
    {
      value: UiLanguage.es,
      name: 'Español',
      isSelected: false,
      icon: 'es',
    },
  ];

  private currentUserSubscription: Subscription;

  get minPasswordLength() {
    return authenticationConfig.password.validation.minLength;
  }

  get maxPasswordLength() {
    return authenticationConfig.password.validation.maxLength;
  }

  get passwordSpecialCharacters() {
    return authenticationConfig.password.validation.specialCharacters;
  }

  get passwordLowerCase() {
    return authenticationConfig.password.validation.lowercase;
  }

  get passwordUpperCase() {
    return authenticationConfig.password.validation.uppercase;
  }

  get passwordDigit() {
    return authenticationConfig.password.validation.numbers;
  }

  constructor(
    private readonly userProfilesStoreService: UserProfilesStoreService,
    private readonly templateService: TemplateService,
    private readonly authenticationService: AuthenticationService,
    private readonly userService: UserService,
    private readonly layoutStoreService: LayoutStoreService
  ) {
    this.state = {
      isLoadingDetails: false,
      isUpdatedDetails: false,
      isFailedDetails: false,
      isLoadingPassword: false,
      isUpdatedPassword: false,
      isFailedPassword: false,
      passwordVisibility: false,
      confirmPasswordVisibility: false,
    };

    this.data = {
      email: '',
      fullname: '',
      phoneNumber: null,
      defaultLanguage: UiLanguage.fr,
      currentPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',
    };

    this.templateService.$activeComponent.next('MAIN_APP_MENU.MY_PROFILE');
  }

  ngOnInit() {
    this.state.isLoadingDetails = true;
    this.currentUserSubscription = this.userProfilesStoreService
      .getCurrentUser()
      .subscribe(async (currentUser: User) => {
        if (!currentUser) {
          await this.authenticationService.signOut();
        }
        this.user = currentUser;
        this.state.isLoadingDetails = false;
      });
    if (!this.user) {
      this.data.email = '';
      this.data.fullname = '';
      this.data.phoneNumber = null;
      this.data.defaultLanguage = UiLanguage.fr;
      return;
    }

    this.data.email = this.user.email;
    this.data.fullname = this.user.fullname;
    this.data.phoneNumber = this.user.phoneNumber.replace('+33', '0');
    this.data.defaultLanguage = this.user.defaultLanguage;
  }

  ngOnDestroy(): void {
    // for Unsubscribe
  }

  onUserUpdated(user: User): void {
    this.user = user;
  }

  async saveUserName(userDetailsForm: NgForm) {
    this.state.isLoadingDetails = true;
    this.state.isUpdatedDetails = false;
    this.state.isFailedDetails = false;

    this.data.fullname = trim(this.data.fullname.replace(/\s+/g, ' '));

    try {
      const userForUpdate: User = new User({
        ...this.user,
        fullname: this.data.fullname,
        phoneNumber: this.data.phoneNumber.e164Number,
      });
      const newUserPromise = this.userService.putUser(userForUpdate);
      const minTimeoutPromise = PromiseUtils.delay(coreConfig.forms.minRefreshTimeout);
      const [newUser] = await Promise.all([newUserPromise, minTimeoutPromise]);
      this.userProfilesStoreService.setCurrentUser(newUser);
      this.state.isUpdatedDetails = true;

      setTimeout(() => {
        this.state.isUpdatedDetails = false;
        userDetailsForm.form.markAsPristine();
      }, coreConfig.forms.showNotificationTime);
    } catch (ex) {
      this.state.isFailedDetails = true;
    } finally {
      this.state.isLoadingDetails = false;
    }
  }

  async changeLanguage(): Promise<void> {
    this.state.isLoadingDetails = true;
    this.state.isUpdatedDetails = false;
    this.state.isFailedDetails = false;

    const selectedLanguage = this.languages.find((language) => language.isSelected);
    if (selectedLanguage) {
      this.data.defaultLanguage = selectedLanguage.value as UiLanguage;
    }

    try {
      const userForUpdate: User = new User({
        ...this.user,
        defaultLanguage: this.data.defaultLanguage,
      });
      const newUserPromise = this.userService.putUser(userForUpdate);
      const minTimeoutPromise = PromiseUtils.delay(coreConfig.forms.minRefreshTimeout);

      const [newUser] = await Promise.all([newUserPromise, minTimeoutPromise]);
      this.state.isUpdatedDetails = true;

      const defaultLanguage = newUser.defaultLanguage || UiLanguage.fr;
      this.layoutStoreService.setDefaultLanguage(defaultLanguage);

      setTimeout(() => {
        this.state.isUpdatedDetails = false;
      }, coreConfig.forms.showNotificationTime);
    } catch (ex) {
      this.state.isFailedDetails = true;
    } finally {
      this.state.isLoadingDetails = false;
    }
  }

  onCurrentPasswordChanged() {
    this.state.isFailedPassword = false;
  }

  hasCurrentPassword() {
    return this.data.currentPassword?.length > 0;
  }

  hasPasswordEightCharacters() {
    return (
      this.data.newPassword?.length >= this.minPasswordLength && this.data.newPassword.length <= this.maxPasswordLength
    );
  }

  hasPasswordUpperCase() {
    return this.passwordUpperCase.test(this.data.newPassword);
  }

  hasPasswordLowerCase() {
    return this.passwordLowerCase.test(this.data.newPassword);
  }

  hasPasswordDigit() {
    return this.passwordDigit.test(this.data.newPassword);
  }

  hasSpecialCharacter() {
    return this.passwordSpecialCharacters.test(this.data.newPassword);
  }

  matchNewPasswordToPasswordRules() {
    const passwordRules =
      this.hasPasswordEightCharacters() &&
      this.hasPasswordUpperCase() &&
      this.hasPasswordLowerCase() &&
      this.hasPasswordEightCharacters() &&
      this.hasSpecialCharacter() &&
      this.hasCurrentPassword();

    return passwordRules && this.data.newPassword === this.data.newPasswordConfirmation;
  }

  async changePassword(form: NgForm) {
    this.state.isLoadingPassword = true;
    this.state.isFailedPassword = false;
    this.state.isUpdatedPassword = false;

    if (!this.user || !this.user.email) {
      return;
    }

    try {
      const changeUserPassword: ChangeUserPassword = new ChangeUserPassword({
        email: this.user.email,
        password: this.data.currentPassword,
        newPassword: this.data.newPassword,
      });
      const changePasswordPromise = this.userService.changePassword(changeUserPassword);
      const minTimeoutPromise = PromiseUtils.delay(coreConfig.forms.minRefreshTimeout);

      await Promise.all([changePasswordPromise, minTimeoutPromise]);

      form.reset();
      this.state.isUpdatedPassword = true;
      setTimeout(() => {
        this.state.isUpdatedPassword = false;
      }, coreConfig.forms.showNotificationTime);
    } catch (ex) {
      this.state.isFailedPassword = true;
    }
    this.state.isLoadingPassword = false;
  }

  onPasswordVisibilityChange(): void {
    this.state.passwordVisibility = !this.state.passwordVisibility;
  }

  onConfirmPasswordVisibilityChange(): void {
    this.state.confirmPasswordVisibility = !this.state.confirmPasswordVisibility;
  }
}
