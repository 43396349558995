import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mid-right-panel-content',
  templateUrl: './mid-right-panel-content.component.html',
  styleUrls: ['./mid-right-panel-content.component.scss'],
})
export class MidRightPanelContentComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
