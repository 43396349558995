import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'tdraw-edit-regular-marker-modal',
  templateUrl: './edit-regular-marker-modal.component.html',
  styleUrls: ['./edit-regular-marker-modal.component.scss'],
})
export class EditRegularMarkerModalComponent {
  content: string;
  isCreatingMarker: boolean = true;

  constructor(
    private dialogRef: MatDialogRef<EditRegularMarkerModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  setContent(content: string) {
    this.content = content;
    this.isCreatingMarker = false;
  }

  onSubmit() {
    this.dialogRef.close(this.content);
  }

  onClose() {
    this.dialogRef.close();
  }
}
