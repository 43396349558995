import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mid-right-panel-footer',
  templateUrl: './mid-right-panel-footer.component.html',
  styleUrls: ['./mid-right-panel-footer.component.scss'],
})
export class MidRightPanelFooterComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
