<div
  class="alert-box"
  [class.active-modal]="isPanelOpened"
  [style.height.px]="isPanelOpened ? containerHeight : triggerHeight"
  *ngIf="this.smockFeatureActivated && alerts.length >= 0"
>
  <div class="alert-trigger">
    <app-mid-button type="secondary" (click)="onOpenModal()">
      <ng-container *ngIf="!isPanelOpened">
        <i class="fas fa-bell"></i>
        <div class="alert-number-header">
          <div class="number">{{ numberOfDetectedAlerts }}</div>
        </div>
      </ng-container>
      <ng-container *ngIf="isPanelOpened">
        <i class="fas fa-angles-down"></i>
      </ng-container>
    </app-mid-button>
  </div>
  <div class="alert-modal" [style.height.px]="containerHeight - triggerHeight">
    <div class="resize-bar"></div>

    <div class="alert-content">
      <div class="alert-information">
        <div class="alert-icon">
          <i class="fas fa-bell"></i>
          <span class="number">{{ numberOfDetectedAlerts }}</span>
        </div>
        <span> {{ 'ALERTS.ALERTS' | translate }} </span>
        <app-mid-icon-button class="alert-sound" size="wide" type="secondary" (action)="stopSoundAlarm()">
          <i class="fa-solid fa-volume-high" *ngIf="!sound"></i>
          <i class="fa-solid fa-volume-xmark" *ngIf="sound"></i>
        </app-mid-icon-button>
      </div>

      <div class="alert-container">
        <div class="alerts" [class.alerts-selection]="!!selectedAlert">
          <app-intervention-alerts
            [interventionId]="intervention._id"
            [organizationId]="organization._id"
            [(filter)]="filter"
            [alerts]="alerts"
            [selectedAlert]="selectedAlert"
            (alertSelected)="onAlertSelected($event)"
          ></app-intervention-alerts>
        </div>
        <div class="selected-alert">
          <ng-container *ngIf="selectedAlert">
            <div class="title">
              <i class="fas fa-triangle-exclamation"></i>
              <span class="pl-1">{{ 'ALERTS.SMOKE_DETECTED' | translate }} {{ selectedAlert.droneName }}</span>
            </div>

            <div class="info">
              <video class="video" controls loop muted [src]="selectedAlert.video_path" type="video/webm">
                Your browser does not support HTML5 video.
              </video>

              <div id="alert-map" *ngIf="hasAlertMetadata()"></div>

              <form class="validation row m-0" #formCreateDrone="ngForm" novalidate>
                <app-mid-input
                  class="col-md-12 py-2"
                  size="wide"
                  [(value)]="selectedAlert.description"
                  #name="appMidInput"
                  name="description"
                  [required]="false"
                  [label]="'ALERTS.DESCRIPTION' | translate"
                  [placeholder]="'ALERTS.ALERTS_DESCRIPTION' | translate"
                >
                </app-mid-input>
                <app-mid-button
                  class="col-md-6 pb-1"
                  size="wide"
                  type="primary"
                  (action)="updateInterventionAlert('Validated')"
                  [disabled]="formCreateDrone.form?.invalid"
                  >{{ 'ALERTS.ALERTS_CONFIRMED' | translate }}</app-mid-button
                >
                <app-mid-button
                  class="col-md-6 pb-1"
                  size="wide"
                  type="secondary"
                  (action)="updateInterventionAlert('FalsePositive')"
                  >{{ 'ALERTS.ALERTS_NO_CONFIRMED' | translate }}</app-mid-button
                >
              </form>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
