import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { required, validate } from 'src/app/shared';
import {
  EditDefaultLanguageAction,
  EditDefaultThemeAction,
  SetDroneIntroShownFlagAction,
  SetDronesViewStyleAction,
  SetFirstDroneIntroShownFlagAction,
  SetLandingIntroShownFlagAction,
} from './layout.actions';
import {
  selectDefaultLanguage,
  selectDefaultTheme,
  selectDronesViewStyle,
  selectIsDroneIntroShown,
  selectIsFirstDroneIntroShown,
  selectIsLandingIntroShown,
} from './layout.selector';
import { DronesViewStyle } from '../../midgard-controls/enums/drone-view-style.enum';

@Injectable({
  providedIn: 'root',
})
export class LayoutStoreService {
  private readonly store: Store<AppState>;

  constructor(store: Store<AppState>) {
    this.store = store;
  }

  @validate
  setDefaultLanguage(@required language: string) {
    this.store.dispatch(new EditDefaultLanguageAction(language));
  }

  getDefaultLanguage(): Observable<string> {
    const selectDefaultLanguage$ = this.store.select(selectDefaultLanguage);
    return selectDefaultLanguage$;
  }

  @validate
  setDefaultTheme(@required theme: string) {
    this.store.dispatch(new EditDefaultThemeAction(theme));
  }

  getDefaultTheme(): Observable<string> {
    const selectDefaultTheme$ = this.store.select(selectDefaultTheme);
    return selectDefaultTheme$;
  }

  @validate
  setDronesViewStyle(@required viewStyle: DronesViewStyle) {
    this.store.dispatch(new SetDronesViewStyleAction(viewStyle));
  }

  getDronesViewStyle(): Observable<DronesViewStyle> {
    const selectDronesViewStyle$ = this.store.select(selectDronesViewStyle);
    return selectDronesViewStyle$;
  }

  setIsLandingIntroShown() {
    this.store.dispatch(new SetLandingIntroShownFlagAction());
  }

  setIsDroneIntroShown() {
    this.store.dispatch(new SetDroneIntroShownFlagAction());
  }

  setIsFirstDroneIntroShown() {
    this.store.dispatch(new SetFirstDroneIntroShownFlagAction());
  }

  getIsLandingIntroShown(): Observable<boolean> {
    const selectIsLandingIntroShown$ = this.store.select(selectIsLandingIntroShown);
    return selectIsLandingIntroShown$;
  }

  getIsDroneIntroShown(): Observable<boolean> {
    const selectIsDroneIntroShown$ = this.store.select(selectIsDroneIntroShown);
    return selectIsDroneIntroShown$;
  }

  getIsFirstDroneIntroShown(): Observable<boolean> {
    const selectIsFirstDroneIntroShown$ = this.store.select(selectIsFirstDroneIntroShown);
    return selectIsFirstDroneIntroShown$;
  }
}
