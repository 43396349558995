import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LayoutStoreService } from '../../layout/store';
import { DronesViewStyle } from '../enums/drone-view-style.enum';

@Component({
  selector: 'app-view-block-buttons',
  templateUrl: './mid-view-block-buttons.component.html',
  styleUrls: ['./mid-view-block-buttons.component.scss'],
})
export class MidViewBlockButtonsComponent {
  constructor(private router: Router, private layoutStoreService: LayoutStoreService) {}

  onDisplay(): boolean {
    const URL = this.router.url;
    const splittedURL = URL.split('/');
    const route = splittedURL[splittedURL.length - 1];
    return route === 'streaming' || route === 'drones';
  }

  onSetBlockView() {
    this.layoutStoreService.setDronesViewStyle(DronesViewStyle.block);
  }

  onSetGridView() {
    this.layoutStoreService.setDronesViewStyle(DronesViewStyle.grid);
  }
}
