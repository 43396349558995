import { WebSocketService } from 'src/app/core/web-socket.service';
import { ActivityStreamItem } from './../autogenerated/model';
import { Injectable } from '@angular/core';
import { WebSocketEntityEventService } from '../core/web-socket-entity-event.service';
import { IMessageForClient, MessageForClient } from '../core/shared/web-socket-messages.dto';

@Injectable({
  providedIn: 'root',
})
export class ActivityStreamEventService extends WebSocketEntityEventService<ActivityStreamItem> {
  constructor(webSocketService: WebSocketService) {
    super(webSocketService, 'ActivityStreamItem');
  }

  convertToMessageForClient(message: IMessageForClient): MessageForClient<ActivityStreamItem> {
    const messageForClient = new MessageForClient({
      actionType: message.actionType,
      subject: new ActivityStreamItem(message.subject),
      subjectType: 'ActivityStreamItem',
    });

    return messageForClient;
  }
}
