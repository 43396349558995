import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LabelPosition } from '../enums/label-position.enum';
import { SwitchType } from '../enums/switch-type.enum';
@Component({
  selector: 'app-mid-toggle-switch',
  templateUrl: './mid-toggle-switch.component.html',
  styleUrls: ['./mid-toggle-switch.component.scss'],
})
export class MidToggleSwitchComponent implements OnInit {
  @Input()
  textOn: string;

  @Input()
  textOff: string;

  @Input()
  disabled: boolean;

  @Input()
  state: boolean;

  @Input()
  type: SwitchType;

  @Input()
  labelPosition: LabelPosition;

  @Output()
  action: EventEmitter<any>;

  get LabelPosition() {
    return LabelPosition;
  }

  get SwitchType() {
    return SwitchType;
  }

  constructor() {
    this.action = new EventEmitter();
  }

  ngOnInit(): void {}

  toggle() {
    setTimeout(() => {
      this.action.emit(this.state);
    }, 0);
  }
}
