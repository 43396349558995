<mid-component-modal [title]="modalData.title">
  <div role="content">
    <app-media-page
      [isInterventionRequired]="true"
      [hasAdminPermissions]="false"
      [displayMode]="displayMode"
      [mediaType]="modalData.mediaType"
      [interventionId]="interventionId"
      [mediaPageLocation]="MediaPageLocation.mediaSelect"
      (onSelectMedia)="onSelectMedia($event)"
      [edithMode]="edithMode"
    >
    </app-media-page>
  </div>
  <div role="footer">
    <div class="button-section">
      <span *ngIf="selectedMediaIds.length" class="selected-item-info">{{ 'MEDIA_IS_SELECTED' | translate }}</span>
      <app-mid-button type="secondary" size="default" (action)="closeModal()">
        {{ 'COMMON_CANCEL' | translate }}
      </app-mid-button>
      <app-mid-button
        type="primary"
        size="default"
        (action)="confirmSelection()"
        [disabled]="displayMode === DisplayMode.select && !selectedMediaIds.length"
      >
        {{ 'SUBMIT' | translate }}
      </app-mid-button>
    </div>
  </div>
</mid-component-modal>
