import { OrganizationMedia, DroneLiveStreamMedia, DroneLiveSnapshotMedia } from '../autogenerated/model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PagingService {
  /** starting page for snapshot pagination */
  public snapshotPage = 1;
  /** starting page for video pagination */
  public videoPage = 1;
  /** page media element count, 8 is ideal for bootstrap grids making 2 row of 4 */
  public limit = 8;
  // I expect this to come in place when we not "just navigate to it"
  // public currentMedias: {
  //   selected: OrganizationMedia | null;
  //   video: DroneLiveStreamMedia | null;
  //   snapshot: DroneLiveSnapshotMedia | null;
  // } = {
  //     selected: null,
  //     video: null,
  //     snapshot: null,
  //   };

  constructor() {}
}
