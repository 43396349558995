import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import _ from 'lodash';
import mapboxgl from 'mapbox-gl';
import { MapboxStyleSwitcherControl } from 'mapbox-gl-style-switcher';
import { OrganizationDto, OrganizationsService, OrgLocation } from 'src/api';
import { UpdateOrganizationDto } from 'src/api/model/updateOrganizationDto';
import { coreConfig } from 'src/app/core/core.config';
import { PromiseUtils } from 'src/app/core/utils/promise-utils';
import { MidSelectFlag } from 'src/app/midgard-controls/models';
import { MidRightPanelService } from 'src/app/midgard-controls/services/mid-right-panel.service';
import { OrganizationStoreService } from 'src/app/organization/store';
import { LocationSearchResult } from 'src/app/tdraw/models/location-search-result.model';
import { tdrawConfig } from 'src/app/tdraw/tdraw.config';
import { environment } from 'src/environments/environment';
import { OrganizationChangeLogoComponent } from './organization-change-logo/organization-change-logo.component';

@Component({
  selector: 'app-organization-properties',
  templateUrl: './organization-properties.component.html',
  styleUrls: ['./organization-properties.component.scss'],
})
export class OrganizationPropertiesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() org: OrganizationDto;

  state: {
    isLoading: boolean;
    isUpdated: boolean;
    isFailed: boolean;
  } = {
    isLoading: false,
    isUpdated: false,
    isFailed: false,
  };
  errorMessage: string;

  @ViewChild('formUpdateOrganization', { static: true })
  private form: NgForm;

  canUpdate = false;

  organizationName: string;

  languages: MidSelectFlag[] = [
    { isSelected: true, value: 'fr', name: 'Français', icon: 'fr' },
    { isSelected: false, value: 'en', name: 'English', icon: 'gb' },
    { isSelected: false, value: 'it', name: 'Italiano', icon: 'it' },
    { isSelected: false, value: 'de', name: 'Deutsch', icon: 'de' },
    { isSelected: false, value: 'es', name: 'Español', icon: 'es' },
  ];

  startingOrgProperties: UpdateOrganizationDto;

  get organizationLogoUrl(): string | null {
    if (!this.org?.logoFileName) {
      return '/assets/images/mid-placeholder-media.png';
    }
    return environment.backend + 'public/logos/' + this.org.logoFileName;
  }

  orgLocation: OrgLocation;
  map: mapboxgl.Map;

  constructor(
    private readonly organizationService: OrganizationsService,
    private readonly organizationStoreService: OrganizationStoreService,
    private readonly midRightPanelService: MidRightPanelService
  ) {
    this.orgLocation = this.defaultLocation();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const componentChanges = changes as PropertyMap<OrganizationPropertiesComponent, SimpleChange>;
    if (componentChanges.org.currentValue) {
      this.loadData();
    }
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.map?.remove();
  }

  async revertChanges() {
    this.form.control.markAsPristine();
    this.state.isLoading = false;
    this.state.isUpdated = false;
    this.state.isFailed = false;
    if (!this.startingOrgProperties) return;
    this.org = await this.organizationService
      .organizationControllerUpdate(this.startingOrgProperties, this.org.id)
      .toPromise();
    this.organizationName = this.org.name;
  }

  async saveChanges() {
    if (!this.canUpdate) {
      return;
    }

    this.state.isLoading = true;
    this.state.isUpdated = false;
    this.state.isFailed = false;

    try {
      this.org.name = this.organizationName;
      this.updateLocation();
      this.org.location = this.orgLocation;
      // this.org.logoFileName = this.logoFileName;
      const organizationPromise = this.organizationService
        .organizationControllerUpdate(this.org, this.org.id)
        .toPromise();
      const minTimeoutPromise = PromiseUtils.delay(coreConfig.forms.minRefreshTimeout);

      const [currentOrganization, _] = await Promise.all([organizationPromise, minTimeoutPromise]);
      this.organizationStoreService.setCurrentOrganization(currentOrganization);
      this.org = currentOrganization;

      this.state.isUpdated = true;

      setTimeout(() => {
        this.state.isUpdated = false;
        this.form.control.markAsPristine();
      }, coreConfig.forms.showNotificationTime);
    } catch (ex) {
      this.errorMessage = ex.message;
      this.state.isFailed = true;
    } finally {
      this.state.isLoading = false;
    }
  }

  onOpenChangeLogo(): void {
    const data = { org: this.org };
    this.midRightPanelService.open(OrganizationChangeLogoComponent, data);
  }

  onLocationSelected(result: LocationSearchResult) {
    const center = result.coordinates;
    const zoom = 13;
    this.map.flyTo({ center, zoom });
    // this.orgLocation.address = result?.title;
    this.orgLocation.lng = result.coordinates[0];
    this.orgLocation.lat = result.coordinates[1];
  }

  private async loadData() {
    await this.getOrgPermission();
    if (this.org) {
      this.organizationName = this.org.name;
      this.languages.forEach((lang) => {
        lang.isSelected = lang.value === this.org.defaultLanguage;
      });
      this.startingOrgProperties = _.cloneDeep(this.org);
    }
    this.updateLocation();
    this.initMap();
  }

  private async getOrgPermission() {
    try {
      const permissions = await this.organizationService
        .organizationControllerGetOrganizationPermissions(this.org.id)
        .toPromise();
      this.canUpdate = permissions.update;
    } catch (error) {
      this.canUpdate = false;
    }
  }

  private defaultLocation() {
    return {
      address: '', // 'Ajaccio, Corse-du-Sud, France',
      lng: tdrawConfig.defaultMapCenter[0],
      lat: tdrawConfig.defaultMapCenter[1],
    } as OrgLocation;
  }

  private updateLocation(): OrgLocation {
    if (!this.org) {
      return this.orgLocation;
    }
    // from organization location
    if (!this.map && this.org.location) {
      this.orgLocation.address = this.org.location?.address;
      this.orgLocation.lng = this.org.location?.lng;
      this.orgLocation.lat = this.org.location?.lat;
      return this.orgLocation;
    }
    // update location from the map and the address input
    // console.log('map center: ', this.orgLocation?.address, this.map?.getCenter());
    const center = this.map?.getCenter();
    this.orgLocation.lng = center?.lng;
    this.orgLocation.lat = center?.lat;
    return this.orgLocation;
  }

  private initMap() {
    const isCenter = this.orgLocation?.lng && this.orgLocation?.lat;
    this.map = new mapboxgl.Map({
      accessToken: tdrawConfig.mapAccessToken,
      container: 'org-location-map',
      style: tdrawConfig.mapStyle,
      center: isCenter ? this.orgLocation : this.defaultLocation(),
      zoom: 13,
    });
    this.map.addControl(new MapboxStyleSwitcherControl());
  }
}
