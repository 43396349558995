import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Drone } from 'src/app/autogenerated/model';
@Component({
  selector: 'app-drone-stream-status',
  templateUrl: './drone-stream-status.component.html',
  styleUrls: ['./drone-stream-status.component.scss'],
})
export class DroneStreamStatusComponent {
  @Input() drone: Drone;
}
