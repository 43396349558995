import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MediaService } from 'src/app/autogenerated/mediaService';
import { MediaType, Organization, OrganizationMedia } from 'src/app/autogenerated/model';
import { DisplayMode } from 'src/app/midgard-controls/enums/display-mode.enum';
import { required, validate } from 'src/app/shared';
import { UserProfilesStoreService } from '../../../user-profile/store';
import { MediaPageLocation } from 'src/app/midgard-controls/enums/media-page-location.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-media-list',
  templateUrl: './media-list.component.html',
  styleUrls: ['./media-list.component.scss'],
})
export class MediaListComponent implements OnInit {
  @Input()
  organization: Organization;

  @Input()
  mediaTypeFilter?: MediaType;

  @Input()
  interventionIdFilter?: string;

  @Input()
  mediaPageLocation: MediaPageLocation;

  @Input()
  mediaList: OrganizationMedia[] = [];

  @Input()
  canDelete: boolean;

  @Output()
  deleteMedia: EventEmitter<string>;

  @Output()
  refreshMedia: EventEmitter<string>;

  @Input()
  displayMode: DisplayMode = DisplayMode.default;

  @Input()
  previousSelectedMediaId: string;

  @Input()
  selectedMediaIds: string[];

  @Output()
  onSelectedMediaId: EventEmitter<string>;

  @Output()
  selectedMediaProperties: EventEmitter<OrganizationMedia>;

  @Output()
  toggleFavoriteMedia: EventEmitter<OrganizationMedia>;

  state: {
    isLoading: boolean;
    isFailed: boolean;
  };

  userId: string;

  constructor(private mediaService: MediaService, private readonly userProfilesStoreService: UserProfilesStoreService) {
    this.state = {
      isLoading: false,
      isFailed: false,
    };
    this.deleteMedia = new EventEmitter();
    this.refreshMedia = new EventEmitter();
    this.onSelectedMediaId = new EventEmitter();
    this.selectedMediaProperties = new EventEmitter();
    this.toggleFavoriteMedia = new EventEmitter<boolean>();
  }

  ngOnInit(): void {
    this.userProfilesStoreService
      .getCurrentUser()
      .pipe(untilDestroyed(this))
      .subscribe(async (user) => {
        this.userId = user._id;
      });
  }

  onDeleteMedia(mediaId: string) {
    this.deleteMedia.emit(mediaId);
  }

  onRefreshMedia(mediaId: string) {
    this.refreshMedia.emit(mediaId);
  }

  onDisplaySelectedMediaProperties(media: OrganizationMedia) {
    this.selectedMediaProperties.emit(media);
  }

  selectedMediaId(mediaId: string) {
    this.onSelectedMediaId.emit(mediaId);
  }

  trackByItem(index: number, item: OrganizationMedia): string {
    return item._id;
  }

  async onToggleFavorite(media: OrganizationMedia) {
    await this.mediaService.toggleFavoriteMedia(this.organization?._id, media?._id);
    this.toggleFavoriteMedia.emit(media);
  }

  @validate
  getDownloadPath(@required media: OrganizationMedia): string {
    return this.mediaService.getDownloadUrl(media);
  }
}
