import { Component, Input } from '@angular/core';
import { RightPanelData, RightPanelMode } from '../../../models/right-panel-data';

@Component({
  selector: 'tdraw-right-panel',
  templateUrl: './right-panel.component.html',
  styleUrls: ['./right-panel.component.scss'],
})
export class RightPanelComponent {
  @Input()
  data: RightPanelData;

  private previousMode?: RightPanelMode;

  get RightPanelMode() {
    return RightPanelMode;
  }

  toggle() {
    if (this.data.mode !== RightPanelMode.Hidden) {
      this.previousMode = this.data.mode;
      this.data.mode = RightPanelMode.Hidden;
    } else {
      this.data.mode = this.previousMode ?? RightPanelMode.Navigation;
    }
  }
}
