import {
  DbClass,
  Drone,
  GetDroneBySerialNumberInput,
  addDroneOutput,
  UpdateDroneInput,
  DroneTimeInput,
  SnapshotOutput,
  DroneStreamSharing,
} from '../model';
import { BackendClientService } from '../../global/backend-client.service';
import { ApiService } from 'src/app/core/api';
import { trim } from 'lodash';

export class DroneServiceAutoGenerated {
  constructor(private beClient: BackendClientService, private apiService: ApiService) {}

  async getDrone(input: DbClass): Promise<Drone> {
    try {
      const result = await this.beClient.post<Drone>('droneService/getDrone', input).toPromise();
      return result;
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }

  async getDroneBySerialNumber(getDroneBySerialNumberInput: GetDroneBySerialNumberInput): Promise<Drone> {
    try {
      const result = await this.beClient
        .post<Drone>('droneService/getDroneBySerialNumber', getDroneBySerialNumberInput)
        .toPromise();
      return result;
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }

  async addDrone(drone: Drone): Promise<addDroneOutput> {
    try {
      const result = await this.beClient.post<addDroneOutput>('droneService/addDrone', drone).toPromise();
      return result;
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }

  async updateDrone(updateDroneInput: UpdateDroneInput): Promise<Drone> {
    try {
      const result = await this.beClient.post<Drone>('droneService/updateDrone', updateDroneInput).toPromise();
      return result;
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }

  async deleteDrone(input: DbClass): Promise<Drone> {
    try {
      const result = await this.beClient.post<Drone>('droneService/removeDrone', input).toPromise();
      return result;
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }

  async snapshot(droneTimeInput: DroneTimeInput): Promise<SnapshotOutput> {
    try {
      const result = await this.beClient.post<SnapshotOutput>('droneService/snapshot', droneTimeInput).toPromise();
      return result;
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }

  async startRecording(droneTimeInput: DroneTimeInput): Promise<Drone> {
    try {
      const result = await this.beClient.post<Drone>('droneService/startRecording', droneTimeInput).toPromise();
      return result;
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }

  async stopRecording(droneTimeInput: DroneTimeInput): Promise<Drone> {
    try {
      const result = await this.beClient.post<Drone>('droneService/stopRecording', droneTimeInput).toPromise();
      return result;
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }

  async createSharing(input: DbClass): Promise<DroneStreamSharing> {
    try {
      const result = await this.beClient.post<DroneStreamSharing>('droneService/createSharing', input).toPromise();
      return result;
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }

  async deleteSharing(input: DbClass): Promise<DroneStreamSharing> {
    try {
      const result = await this.beClient.post<DroneStreamSharing>('droneService/deleteSharing', input).toPromise();
      return result;
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }

  async deleteSharings(input: DbClass): Promise<DroneStreamSharing[]> {
    try {
      const result = await this.beClient.post<DroneStreamSharing[]>('droneService/deleteSharings', input).toPromise();
      return result;
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }

  async getSharings(input: DbClass): Promise<DroneStreamSharing[]> {
    try {
      const result = await this.beClient.post<DroneStreamSharing[]>('droneService/getSharings', input).toPromise();
      return result;
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }

  async getSharing(input: DbClass): Promise<DroneStreamSharing> {
    try {
      const result = await this.beClient.post<DroneStreamSharing>('droneService/getSharing', input).toPromise();
      return result;
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }

  async sendShareLinkBySMS(phoneNumber: string, shareLink: string): Promise<void> {
    const body = {
      phoneNumber: trim(phoneNumber),
      url: shareLink,
    };

    try {
      await this.beClient.post('droneService/sendShareLinkBySMS', body).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }

  async sendShareLinkByMail(mail: string, shareLink: string): Promise<void> {
    const body = {
      email: trim(mail),
      url: shareLink,
    };

    try {
      await this.beClient.post('droneService/sendShareLinkByMail', body).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }
}
