import { WebSocketService } from '../core/web-socket.service';
import {
  ActionForClientType,
  BaseMessage,
  IMessageForClient,
  MessageForClient,
} from '../core/shared/web-socket-messages.dto';
import { merge, Observable, Subject } from 'rxjs';

function activator<T extends BaseMessage>(type: new () => T): T {
  return new type();
}

export abstract class WebSocketEntityEventService<T extends BaseMessage> {
  get created(): Observable<T> {
    return this.createdSubject$.asObservable();
  }
  get updated(): Observable<T> {
    return this.updatedSubject$.asObservable();
  }
  get deleted(): Observable<T> {
    return this.deletedSubject$.asObservable();
  }
  get changed(): Observable<T> {
    return merge(this.deletedSubject$, this.updatedSubject$, this.createdSubject$);
  }

  private typeName: string;

  private createdSubject$: Subject<T>;
  private updatedSubject$: Subject<T>;
  private deletedSubject$: Subject<T>;

  constructor(private readonly webSocketService: WebSocketService, typeName: string) {
    this.typeName = typeName;

    this.createdSubject$ = new Subject<T>();
    this.updatedSubject$ = new Subject<T>();
    this.deletedSubject$ = new Subject<T>();

    this.subscribeToWebSocket();
  }

  private subscribeToWebSocket() {
    this.webSocketService.incomingData.subscribe((message: IMessageForClient) =>
      this.webSocketIncomeDataChanged(message)
    );
  }

  convertToMessageForClient(message: IMessageForClient): MessageForClient<T> {
    throw new Error('Have to be implemented in child');
  }

  webSocketIncomeDataChanged(message: IMessageForClient): MessageForClient<T> {
    if (message.subjectType !== this.typeName) {
      return;
    }

    const messageForClient = this.convertToMessageForClient(message);

    switch (messageForClient.actionType) {
      case ActionForClientType.Created:
        this.createdSubject$.next(messageForClient.subject);
        break;

      case ActionForClientType.Updated:
        this.updatedSubject$.next(messageForClient.subject);
        break;

      case ActionForClientType.Deleted:
        this.deletedSubject$.next(messageForClient.subject);
        break;
    }

    return messageForClient;
  }
}
