import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { TdrawService } from 'src/app/autogenerated/tdrawService';
import { Intervention } from 'src/app/autogenerated/model';
import { MapState } from 'src/app/tdraw/models/map-state.model';
import { InterventionStoreService } from 'src/app/intervention/store';
import { SettingsPageComponent } from '../../intervention-page-settings/settings-page/settings-page.component';
import { interventionConfig } from 'src/app/intervention/intervention.config';
import { Unsubscribe } from 'src/app/core/decorators';

@Unsubscribe()
@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss'],
})
export class DashboardPageComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  organizationId: string;

  @Input()
  intervention: Intervention;
  interventionId: string;

  @Input()
  hasAdminPermissions: boolean = false;

  snapshotSrc?: string;

  private interventionSubscription: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private interventionStore: InterventionStoreService,
    private readonly tdrawService: TdrawService
  ) {}

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const componentChanges = changes as PropertyMap<SettingsPageComponent, SimpleChange>;
    const interventionChange = componentChanges.intervention.currentValue;
    if (!interventionChange) {
      return;
    }
    this.interventionId = interventionChange?._id;
    this.organizationId = interventionChange?.organization;

    const mapState = await this.getMostRecentMapStateWithSnapshot();
    if (mapState) {
      this.setInterventionMapSnapshot(mapState);
    } else {
      this.setDemoInterventionMapSnapshot();
    }
  }

  async ngOnInit() {
    // TODO: ActivatedRoute doesn't work as expected here
    // this.interventionId = this.activatedRoute.snapshot.params['id'];
    // this.onInterventionChanged();
  }

  goToInterventionCartography() {
    this.router.navigate([`interventions/${this.intervention._id}/${interventionConfig.routes.tdraw}/home`]);
  }

  private setDemoInterventionMapSnapshot() {
    this.snapshotSrc = null;
  }

  private setInterventionMapSnapshot(mapState: MapState) {
    this.snapshotSrc = this.tdrawService.getSnapshotDownloadUrl(this.organizationId, this.interventionId, mapState);
  }

  private async getMostRecentMapStateWithSnapshot() {
    const mapStates = await this.tdrawService.getMapStates(this.organizationId, this.interventionId);
    const mapState = _.filter(mapStates, (e) => {
      return e.hasSnapshot && e.publishedAt != null;
    }).sort((a, b) => {
      return b.publishedAt.getTime() - a.publishedAt.getTime();
    })?.[0];
    return mapState;
  }

  ngOnDestroy(): void {}

  onInterventionChanged() {
    if (!this.interventionId) {
      return;
    }

    this.interventionSubscription = this.interventionStore
      .getInterventionById(this.interventionId)
      .subscribe(async (intervention) => {
        this.intervention = intervention;

        // TODO: returns null!
        // this.intervention = await this.interventionService.getInterventionV1(
        //   this.intervention.organization.toString(),
        //   this.intervention._id
        // );
      });
  }
}
