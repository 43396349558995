import {
  Component,
  Output,
  EventEmitter,
  OnDestroy,
  Input,
  OnChanges,
  SimpleChanges,
  SimpleChange,
} from '@angular/core';
import { Assert, required, validate } from 'src/app/shared';
import { AuthenticationApiService } from '../authentication-api.service';
import { Unsubscribe } from 'src/app/core/decorators';
import { Subscription, interval } from 'rxjs';
import { take } from 'rxjs/operators';
import { authenticationConfig } from '../authentication.config';
import { LoggerService } from 'src/app/core/logger.service';
import { AuthenticationError } from '../shared';
import { User } from 'src/app/autogenerated/model';
import { MidButtonSize } from 'src/app/midgard-controls/enums/button-size.enum';
import { MidInputSize } from 'src/app/midgard-controls/enums/input-size.enum';
import { RecoverPasswordState } from '../../midgard-controls/enums/recover-password-state.enum';

@Unsubscribe()
@Component({
  selector: 'app-recover-password-form',
  templateUrl: './recover-password-form.component.html',
  styleUrls: ['./recover-password-form.component.scss'],
})
export class RecoverPasswordFormComponent implements OnChanges, OnDestroy {
  @Input()
  email: string;

  @Input()
  code: string;

  @Output()
  readonly canceled: EventEmitter<void>;

  @Output()
  readonly passwordChanged: EventEmitter<User>;

  @Output()
  readonly navigateToHome: EventEmitter<void>;

  data: {
    username: string;
    resetCode: string;
    newPassword: string;
    newPasswordConfirmation: string;
  };

  state: {
    secondsBeforeResend: number;
    isLoading: boolean;
    isResetCodeRequested: boolean;
    isResetCodeResendEnabled: boolean;
    hasErrors: boolean;
    isInvalidCredentials: boolean;
    authError?: AuthenticationError;
    passwordVisibility: boolean;
    confirmPasswordVisibility: boolean;
    sendBy: string;
    triesLeft: number;
    isNewResetCodeRequested: boolean;
  };

  recoverPasswordState: RecoverPasswordState;

  private resendCounterSubscription: Subscription;

  get minPasswordLength() {
    return authenticationConfig.password.validation.minLength;
  }

  get maxPasswordLength() {
    return authenticationConfig.password.validation.maxLength;
  }

  get passwordSpecialCharacters() {
    return authenticationConfig.password.validation.specialCharacters;
  }

  get passwordLowerCase() {
    return authenticationConfig.password.validation.lowercase;
  }

  get passwordUpperCase() {
    return authenticationConfig.password.validation.uppercase;
  }

  get passwordDigit() {
    return authenticationConfig.password.validation.numbers;
  }

  get MidButtonSize() {
    return MidButtonSize;
  }

  get MidInputSize() {
    return MidInputSize;
  }

  get RecoverPasswordState() {
    return RecoverPasswordState;
  }

  constructor(
    private readonly loggerService: LoggerService,
    private readonly authenticationApiService: AuthenticationApiService
  ) {
    this.canceled = new EventEmitter();
    this.passwordChanged = new EventEmitter();
    this.navigateToHome = new EventEmitter();

    this.data = {
      username: '',
      resetCode: '',
      newPassword: '',
      newPasswordConfirmation: '',
    };

    this.state = {
      isLoading: false,
      hasErrors: false,
      isResetCodeResendEnabled: false,
      isResetCodeRequested: false,
      isInvalidCredentials: false,
      secondsBeforeResend: 0,
      authError: null,
      passwordVisibility: false,
      confirmPasswordVisibility: false,
      sendBy: 'email',
      isNewResetCodeRequested: false,
      triesLeft: 0,
    };

    this.recoverPasswordState = RecoverPasswordState.waitingEmailForSentCode;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const componentChanges = changes as PropertyMap<RecoverPasswordFormComponent, SimpleChange>;

    const emailChange = componentChanges.email;
    if (emailChange && emailChange.currentValue) {
      this.data.username = emailChange.currentValue;
    }

    const codeChange = componentChanges.code;
    if (codeChange && codeChange.currentValue) {
      this.data.resetCode = codeChange.currentValue;
    }

    this.state.isResetCodeRequested = !!this.data.username;
    if (this.state.isResetCodeRequested) {
      this.recoverPasswordState = RecoverPasswordState.resetCodeRequested;
      this.startResendResetCodeCounter();
    }
  }

  ngOnDestroy(): void {}

  async onSendResetCode() {
    Assert.isNotNull(this.data.username, 'this.data.username');
    try {
      await this.requestPasswordReset(this.data.username);
      this.recoverPasswordState = RecoverPasswordState.checkMailForCode;
      this.state.isResetCodeRequested = true;
      this.startResendResetCodeCounter();
    } catch {
      // ignore
    }
  }

  onReadCheckEmailMessage() {
    this.recoverPasswordState = RecoverPasswordState.resetCodeRequested;
  }

  async onResendResetCode() {
    this.data.resetCode = '';
    try {
      await this.requestPasswordReset(this.data.username);
      this.startResendResetCodeCounter();
    } catch {
      this.cancelResendCodeCounter();
    }
  }

  onChangeUsername() {
    this.cancelResendCodeCounter();

    this.state.hasErrors = false;
    this.state.isInvalidCredentials = false;
    this.state.isResetCodeRequested = false;

    this.data = {
      username: '',
      resetCode: '',
      newPassword: '',
      newPasswordConfirmation: '',
    };
  }

  onResetCodeChanged() {
    if (!this.state.isInvalidCredentials) {
      return;
    }

    this.state.isInvalidCredentials = false;
    this.state.hasErrors = false;
  }

  async onSendResetCodeBySMS() {
    this.state.sendBy = 'sms';
    Assert.isNotNull(this.data.username, 'this.data.username');
    try {
      await this.requestPasswordReset(this.data.username);
      this.recoverPasswordState = RecoverPasswordState.checkSMSForCode;
      this.state.isResetCodeRequested = true;
      this.startResendResetCodeCounter();
    } catch {
      // ignore
    }
  }

  async onResetPassword() {
    Assert.isNotNull(this.data.resetCode, 'this.data.resetCode');
    Assert.isNotNull(this.data.newPassword, 'this.data.newPassword');

    this.state.isLoading = true;
    this.state.hasErrors = false;
    this.state.isInvalidCredentials = false;

    try {
      const response = await this.authenticationApiService.resetPassword(
        this.data.username,
        this.data.resetCode,
        this.data.newPassword
      );
      if (!response.isResetCodeCorrect) {
        this.state.hasErrors = true;
        this.state.isInvalidCredentials = true;
        this.state.isLoading = false;
        this.state.triesLeft = response.triesLeft;
        this.state.isNewResetCodeRequested = response.isNewResetCodeRequested;
        return;
      }
      // 2. login into the app
      const user = await this.authenticationApiService.login(this.data.username, this.data.newPassword);
      this.passwordChanged.next(user);

      this.recoverPasswordState = RecoverPasswordState.passwordChanged;
    } catch (error) {
      this.loggerService.error('Failed to reset password', error);
      this.onResetPasswordFailed(error);
    } finally {
      this.state.isLoading = false;
    }
  }

  async onCancel() {
    this.canceled.next();
  }

  onNavigateToApp() {
    this.navigateToHome.emit();
  }

  @validate
  private async requestPasswordReset(@required username: string) {
    this.state.isLoading = true;
    this.state.hasErrors = false;
    this.state.isInvalidCredentials = false;

    try {
      await this.authenticationApiService.requestPasswordReset(username, this.state.sendBy);
    } catch (error) {
      this.state.hasErrors = true;
      this.loggerService.error('Failed to request password reset', error);
      throw error;
    } finally {
      this.state.isLoading = false;
    }
  }

  private startResendResetCodeCounter() {
    this.state.isResetCodeResendEnabled = false;

    this.state.secondsBeforeResend = authenticationConfig.recoverPassword.resendResetCodeDelay;
    this.resendCounterSubscription = interval(1000)
      .pipe(take(authenticationConfig.recoverPassword.resendResetCodeDelay))
      .subscribe(() => {
        this.onResendCodeCounterTick();
      });
  }

  private onResendCodeCounterTick() {
    this.state.secondsBeforeResend -= 1;
    if (this.state.secondsBeforeResend <= 0) {
      this.cancelResendCodeCounter();
    }
  }

  private cancelResendCodeCounter() {
    this.state.isResetCodeResendEnabled = true;
    this.state.secondsBeforeResend = 0;

    if (this.resendCounterSubscription) {
      this.resendCounterSubscription.unsubscribe();
      this.resendCounterSubscription = null;
    }
  }

  hasPasswordEightCharacters() {
    return (
      this.data.newPassword?.length >= this.minPasswordLength && this.data.newPassword.length <= this.maxPasswordLength
    );
  }

  hasPasswordUpperCase() {
    return this.passwordUpperCase.test(this.data.newPassword);
  }

  hasPasswordLowerCase() {
    return this.passwordLowerCase.test(this.data.newPassword);
  }

  hasPasswordDigit() {
    return this.passwordDigit.test(this.data.newPassword);
  }

  hasSpecialCharacter() {
    return this.passwordSpecialCharacters.test(this.data.newPassword);
  }

  matchNewPasswordToPasswordRules() {
    const passwordRules =
      this.hasPasswordEightCharacters() &&
      this.hasPasswordUpperCase &&
      this.hasPasswordLowerCase() &&
      this.hasPasswordEightCharacters() &&
      this.hasSpecialCharacter();

    return passwordRules && this.data.newPassword === this.data.newPasswordConfirmation && this.data.resetCode;
  }

  changePasswordVisibility() {
    this.state.passwordVisibility = !this.state.passwordVisibility;
  }

  changeConfirmPasswordVisibility() {
    this.state.confirmPasswordVisibility = !this.state.confirmPasswordVisibility;
  }

  private onResetPasswordFailed(error: any) {
    this.state.hasErrors = true;

    // TODO
    // if (error && error instanceof ErrorResponse) {
    //   if (error.status === StatusCode.Forbidden) {
    //     this.state.isInvalidCredentials = true;
    //     this.data.resetCode = '';
    //   }
    // }
  }
}
