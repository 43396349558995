import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlContainer, NgForm, NgModel } from '@angular/forms';
import { EngagedStaff } from '../models';

@Component({
  selector: 'app-mid-statistics-engaged-staff',
  templateUrl: './mid-statistics-engaged-staff.component.html',
  styleUrls: ['./mid-statistics-engaged-staff.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  exportAs: 'appMidStatisticsEngagedStaff',
})
export class MidStatisticsEngagedStaffComponent implements OnInit, OnChanges {
  @Input()
  items: EngagedStaff[];

  @Input()
  isEditable: boolean;

  @Input()
  isExpanded: boolean;

  @Output()
  itemsChange: EventEmitter<EngagedStaff[]>;

  get total(): number {
    return this.items?.reduce((sum, i) => sum + i.value, 0);
  }

  name = 'appMidStatisticsEngagedStaff_' + ((Math.random() * 10000) | 0);
  @ViewChild('appMidStatisticsEngagedStaff')
  input: NgModel;

  private readonly defaultEngagedStaffs = [
    { name: 'OFF', value: 0 },
    { name: 'S-OFF', value: 0 },
    { name: 'HDR', value: 0 },
  ];

  constructor() {
    this.itemsChange = new EventEmitter<EngagedStaff[]>();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.items?.length) {
      this.items = this.defaultEngagedStaffs;
    }
  }

  ngOnInit(): void {}

  add(name: any) {
    const object = this.items.findIndex((obj) => obj.name === name);
    if (this.items[object].value >= 0) {
      this.input.control.markAsDirty();
      this.items[object].value++;
      this.itemsChange.emit(this.items);
    }
  }

  remove(name: any) {
    const object = this.items.findIndex((obj) => obj.name === name);
    if (this.items[object].value !== 0) {
      this.input.control.markAsDirty();
      this.items[object].value--;
      this.itemsChange.emit(this.items);
    }
  }
}
