import { OrganizationServiceAutoGenerated } from './services/organizationService.service';
import { Injectable } from '@angular/core';
import { BackendClientService } from '../global/backend-client.service';
import { ApiService } from '../core/api';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService extends OrganizationServiceAutoGenerated {
  constructor(client: BackendClientService, apiService: ApiService) {
    super(client, apiService);
  }
}
