import { Injectable } from '@angular/core';
import { TiffStatusMessage } from '../autogenerated/model';
import { IMessageForClient, MessageForClient } from '../core/shared/web-socket-messages.dto';
import { WebSocketEntityEventService } from '../core/web-socket-entity-event.service';
import { WebSocketService } from '../core/web-socket.service';

@Injectable({
  providedIn: 'root',
})
export class InterventionPhotogrammetryEventService extends WebSocketEntityEventService<TiffStatusMessage> {
  constructor(webSocketService: WebSocketService) {
    super(webSocketService, 'TiffStatusMessage');
  }

  convertToMessageForClient(message: IMessageForClient): MessageForClient<TiffStatusMessage> {
    const messageForClient = new MessageForClient({
      actionType: message.actionType,
      subject: { ...message.subject } as TiffStatusMessage,
      subjectType: 'OrganizationMedia',
    });

    return messageForClient;
  }
}
