<!-- This is displayed above all other elements -->
<div class="video-stream-container" *ngIf="currentDrone">
  <div class="video-stream-header">
    <span>{{ currentDrone.name }}</span>
    <app-mid-icon-button class="video-stream-header-close" type="primary" shadow="false" (action)="currentDrone = null">
      <i class="fas fa-times"></i>
    </app-mid-icon-button>
  </div>
  <div class="video-stream-content">
    <app-drone-stream-player class="video-stream" [drone]="currentDrone"></app-drone-stream-player>
  </div>
</div>

<app-mid-expansion-panel
  *ngIf="drones.length"
  title="{{ 'VIDEO_STREAMS' | translate }}"
  isExpanded="true"
  type="primary"
>
  <div *ngFor="let drone of drones" class="video-stream-item">
    <span>{{ drone.name }} - </span>
    <span class="video-stream-id">[{{ drone.rtmpStreamId }}]</span>
    <div class="toggle-stream-ctrl" (click)="onToggleVideoStream(drone)">
      <i class="fas mr-2 fa-eye" [class.inactive]="drone !== currentDrone"></i>
    </div>
  </div>
</app-mid-expansion-panel>

<app-mid-expansion-panel
  *ngIf="flightsByDrone"
  title="{{ 'TRAJECTORIES' | translate }}"
  isExpanded="true"
  type="primary"
>
  <app-mid-expansion-panel
    *ngFor="let item of flightsByDrone | keyvalue"
    [title]="item.key"
    isExpanded="true"
    type="secondary"
  >
    <div *ngFor="let flight of item.value" class="flight-item">
      <span>{{ flight.startDate | date : 'dd/MM/yy - HH:mm' }}</span>
      <div class="toggle-flight-ctrl" (click)="onToggleFlightPath(flight._id)">
        <i
          class="fas mr-2"
          [class.fa-eye]="flightStates[flight._id]?.isPathActive"
          [class.fa-eye-slash]="!flightStates[flight._id]?.isPathActive"
          [class.inactive]="!flightStates[flight._id]?.isPathActive"
        ></i>
      </div>
      <div class="toggle-flight-ctrl" (click)="onToggleFlightIcon(flight._id)">
        <i class="fas mr-2 fa-map-marker" [class.inactive]="!flightStates[flight._id]?.isIconActive"></i>
      </div>
    </div>
  </app-mid-expansion-panel>
</app-mid-expansion-panel>

<app-mid-expansion-panel title="{{ 'MEDIA' | translate }}" isExpanded="true" type="primary">
  <div class="header" *ngIf="withMediaSelector">
    <app-mid-button type="primary" size="wide" (click)="openSelectMediasDialog()">
      {{ 'TDRAW.BUTTON_SELECT_MEDIAS' | translate }}
    </app-mid-button>
  </div>

  <div class="medias-box">
    <div class="medias-box-header">
      <span>{{ 'MEDIA' | translate }}</span>
      <app-mid-button type="primary" size="small" icon="fas fa-location-dot" (click)="enableAllPinpoints()">
        {{ 'TDRAW.BUTTON_GEOLOCATE_ALL' | translate }}
      </app-mid-button>
      <app-mid-button type="primary" size="small" icon="fas fa-map" (click)="enableAllTiles()">
        {{ 'TDRAW.BUTTON_PROJECT_ALL' | translate }}
      </app-mid-button>
    </div>
    <div class="media-container" *ngFor="let photo of selectedPhotos">
      <img [attr.src]="photoUrls[photo._id] | secureImage | async" class="media-img" />
      <label class="media-label">{{ photo.name }}</label>

      <div class="media-actions">
        <mat-slider
          *ngIf="mediaStates[photo._id].active3d"
          class="slider"
          min="-75"
          max="75"
          step="0.01"
          [value]="mediaStates[photo._id].offset3d"
          (input)="onChange3dModelOffset(photo, $event.value)"
          (change)="onChange3dModelOffset(photo, $event.value)"
        ></mat-slider>

        <div style="margin-left: auto">
          <app-mid-icon-button
            class="toggle-button"
            type="secondary"
            [bgColor]="mediaStates[photo._id].active3d ? 'white' : null"
            (click)="onToggle3dModel(photo)"
            *ngIf="photo.model3d"
          >
            <i class="fas fa-cube fa-sm mid-btn-icon"></i>
          </app-mid-icon-button>

          <app-mid-icon-button
            class="toggle-button"
            type="secondary"
            [bgColor]="mediaStates[photo._id].activePin ? 'white' : null"
            (click)="onTogglePinpoint(photo)"
            *ngIf="photo.gps"
          >
            <i class="fas fa-location-dot fa-sm mid-btn-icon"></i>
          </app-mid-icon-button>

          <app-mid-icon-button
            class="toggle-button"
            type="secondary"
            [bgColor]="mediaStates[photo._id].activeProj ? 'white' : null"
            (click)="onToggleTiles(photo)"
            *ngIf="photo.tiled"
          >
            <i class="fas fa-map fa-sm mid-btn-icon"></i>
          </app-mid-icon-button>
        </div>
      </div>
    </div>
  </div>
</app-mid-expansion-panel>
