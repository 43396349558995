<div class="p-2 drones-page">
  <div class="drone-header" *ngIf="this.router.url === '/drones'">
    <p>{{ 'DRONE_INTRO' | translate }}</p>
    <mid-view-block></mid-view-block>
  </div>

  <app-mid-button
    *ngIf="isAdmin && !intervention"
    type="primary"
    icon="fas fa-plus"
    (action)="onOpenCreateDrone()"
    data-step="6"
    [attr.data-intro]="'INTRO_NEW_DRONE' | translate"
    [attr.data-title]="'INTRO_TITLE' | translate"
  >
    {{ 'DRONES_ADD' | translate }}
  </app-mid-button>
  <app-mid-button
    *ngIf="intervention"
    type="primary"
    icon="fas fa-plus"
    (click)="onOpenAssignDroneToInterventionDialog()"
    [disabled]="intervention.isArchived"
  >
    {{ 'LINK_DRONE_TO_INTERVENTION' | translate }}
  </app-mid-button>

  <div class="drone-block-view" *ngIf="!state.isFailed && !state.isLoading">
    <app-drone-list [drones]="drones" (droneDeleted)="tryToLoadDrones()"></app-drone-list>
  </div>

  <app-intervention-alert-modal *ngIf="intervention" [intervention]="intervention"></app-intervention-alert-modal>
</div>
