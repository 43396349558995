import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import _ from 'lodash';
import { MapState } from 'src/app/tdraw/models/map-state.model';
import { interventionConfig } from 'src/app/intervention/intervention.config';

@Component({
  selector: 'tdraw-version-list-element',
  templateUrl: './version-list-element.component.html',
  styleUrls: ['./version-list-element.component.scss'],
})
export class VersionListElementComponent {
  @Input()
  isSelected: boolean;

  @Input()
  item: MapState;

  @Input()
  interventionId: string;

  @Output()
  mapStateSelected: EventEmitter<void>;

  @Output()
  publish: EventEmitter<void>;

  @Output()
  rename: EventEmitter<void>;

  @Output()
  duplicate: EventEmitter<void>;

  @Output()
  download: EventEmitter<void>;

  @Output()
  deletingMapState: EventEmitter<void>;

  constructor(private router: Router) {
    this.mapStateSelected = new EventEmitter();
    this.publish = new EventEmitter();
    this.rename = new EventEmitter();
    this.duplicate = new EventEmitter();
    this.download = new EventEmitter();
    this.deletingMapState = new EventEmitter();
  }

  onSelectMapState() {
    this.mapStateSelected.next();
  }

  onPublish() {
    this.publish.next();
  }

  openGisEditMap() {
    this.router.navigate([
      `interventions/${this.interventionId}/${interventionConfig.routes.tdraw}/gis/${this.item._id}/edit`,
    ]);
  }

  openPicEditMap() {
    this.router.navigate([
      `interventions/${this.interventionId}/${interventionConfig.routes.tdraw}/pic/${this.item._id}/edit`,
    ]);
  }

  openGisViewMap() {
    this.router.navigate([
      `interventions/${this.interventionId}/${interventionConfig.routes.tdraw}/gis/${this.item._id}/view`,
    ]);
  }

  openPicViewMap() {
    this.router.navigate([
      `interventions/${this.interventionId}/${interventionConfig.routes.tdraw}/pic/${this.item._id}/view`,
    ]);
  }

  onRename() {
    this.rename.next();
  }

  onDuplicate() {
    this.duplicate.next();
  }

  onDownload() {
    this.download.next();
  }

  onDeleteMapState() {
    this.deletingMapState.next();
  }
}
