import { ActivatedRouteSnapshot } from '@angular/router';

export class RouterUtils {
  static getRouteParamRecursively(name: string, route: ActivatedRouteSnapshot): string {
    if (!route) {
      return null;
    }

    let value: string;
    if (route.paramMap.has(name)) {
      value = route.paramMap.get(name);
    } else {
      value = RouterUtils.getRouteParamRecursively(name, route.parent);
    }

    return value;
  }
}
