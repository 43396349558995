<div class="{{ customClassesService.CustomClasses.customContainerClass }}">
  <!-- header -->
  <app-header class="main-header"></app-header>

  <!-- content -->
  <div class="{{ customClassesService.CustomClasses.customContentClass }}">
    <router-outlet></router-outlet>
  </div>

  <!-- footer -->
</div>
