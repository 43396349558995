import { User, InvalidRequest } from '../model';

import { BackendClientService } from '../../global/backend-client.service';
import { ApiService } from 'src/app/core/api';

export class AdminServiceAutoGenerated {
  constructor(private beClient: BackendClientService, private apiService: ApiService) {}

  async crash(): Promise<string> {
    try {
      const result = await this.beClient.post<string>('adminService/crash', {}).toPromise();
      return result;
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }
}
