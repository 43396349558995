import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SharedMediaFileDto, OrganizationsMediaService } from 'src/api';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class MediaApiClientService {
  constructor(
    private readonly organizationsMediaService: OrganizationsMediaService,
    private readonly router: Router,
    private readonly location: Location
  ) {}
  getShareLink(share: SharedMediaFileDto) {
    if (!share) return null;
    const urlTree = this.router.createUrlTree(['share', 'media', share.id]);
    const path = this.location.prepareExternalUrl(urlTree.toString());
    return window.location.origin + path;
  }
}
