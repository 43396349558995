import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MapIcon } from 'src/app/tdraw/models/map-icon';

@Component({
  selector: 'tdraw-sitac-selector-modal',
  templateUrl: './sitac-selector-modal.component.html',
  styleUrls: ['./sitac-selector-modal.component.scss'],
})
export class SitacSelectorModalComponent {
  constructor(private dialogRef: MatDialogRef<SitacSelectorModalComponent>) {}

  onSubmit(icon: MapIcon) {
    this.dialogRef.close(icon);
  }

  onClose() {
    this.dialogRef.close();
  }
}
