import { Injectable } from '@angular/core';
import { WebStorageService } from './web-storage-service';
import { LoggerService } from '../logger.service';
import { Assert } from 'src/app/shared';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService extends WebStorageService {
  constructor(loggerService: LoggerService) {
    Assert.isNotNull(
      localStorage,
      'localStorage',
      'Cannot use LocalStorageService because "{0}" not supported by browser.'
    );
    super(localStorage, loggerService);
  }
}
