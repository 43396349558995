import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { saveAs } from 'file-saver';
import { takeUntil } from 'rxjs/operators';
import { OrganizationMedia } from 'src/app/autogenerated/model';

@Injectable({
  providedIn: 'root',
})
export class MediaOperationsService implements OnDestroy {
  private readonly destroy$ = new Subject<boolean>();

  constructor(private http: HttpClient) {}

  downloadMedia(mediaPath: string, media: OrganizationMedia): void {
    this.getMediaBlob(mediaPath)
      .pipe(takeUntil(this.destroy$))
      .subscribe((blob: Blob) => {
        saveAs(blob, `${media.name}${media.ext}`);
      });
  }

  openPrintDialog(mediaPath: string): void {
    this.getMediaBlob(mediaPath)
      .pipe(takeUntil(this.destroy$))
      .subscribe((blob: Blob) => {
        const url = URL.createObjectURL(blob);
        const win = window.open('about:blank', '_new');
        win.document.open();
        win.document.write(
          [
            `<html>`,
            `   <head>`,
            `   </head>`,
            `   <body onload="window.print()" onafterprint="window.close()">`,
            `       <img src="${url}"/>`,
            `   </body>`,
            `</html>`,
          ].join(``)
        );
        win.document.close();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  private getMediaBlob(url: string): Observable<Blob> {
    return this.http.get(url, { responseType: 'blob' });
  }
}
