import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  FilterName,
  Intervention,
  InterventionFilterParameters,
  InterventionFilterParametersDto,
  TaskStatus,
  TiffStatusMessage,
  UpdateInterventionDto,
} from 'src/app/autogenerated/model';
import { InterventionService } from 'src/app/autogenerated/interventionService';
import { Subscription, timer } from 'rxjs';
import { OrganizationStoreService } from 'src/app/organization/store';
import { MatCheckbox } from '@angular/material/checkbox';
import { InterventionStoreService } from 'src/app/intervention/store';
import { MatDialog } from '@angular/material/dialog';
import { PhotogrammetryUploadDialogComponent } from '../photogrammetry-upload-dialog/photogrammetry-upload-dialog.component';
import {
  PhotogrammetryUploadService,
  UploadProgress,
} from '../photogrammetry-upload-dialog/photogrammetry-upload.service';
import { filter, first } from 'rxjs/operators';
import { InterventionPhotogrammetryEventService } from 'src/app/intervention/intervention-photogrammetry-event.service';
import { LabelPosition } from 'src/app/midgard-controls/enums/label-position.enum';
import { OrganizationsService } from 'src/api';
import { MidToggleSwitchComponent } from 'src/app/midgard-controls/mid-toggle-switch/mid-toggle-switch.component';
import { ConfirmModalService } from 'src/app/midgard-controls/services/confirm-modal.service';

@Component({
  selector: 'app-analyses-page',
  templateUrl: './analyses-page.component.html',
  styleUrls: ['./analyses-page.component.scss'],
})
export class AnalysesPageComponent implements OnInit {
  @Input()
  organizationId: string;

  @Input()
  intervention: Intervention;

  @Input()
  hasAdminPermissions = false;

  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  private INTERVENTION_DRONE_STATE_USED = '';

  filterParameters: InterventionFilterParameters;
  faceblurState: boolean = true;
  smockDetectionState: boolean = false;
  recordingState: boolean = false;
  uploadsProgress: UploadProgress[] = [];
  tiffStatusMessages: TiffStatusMessage[] = [];
  hideProgressBarAfterCompleteTimer: number = 10; // [seconds]
  childToggleSwitchStateTimer: number = 1; // [seconds]

  incomingDataSubscription: Subscription;

  withStatusTranslation: string;
  taskStatusRunningTranslation: string;
  taskStatusCompletedTranslation: string;
  taskStatusFailedTranslation: string;
  taskStatusNoRunTranslation: string;

  private organizationSubscription: Subscription;

  @ViewChild('blur')
  private blurSwitch: MidToggleSwitchComponent;

  blurFeatureActivated: boolean = false;
  smockFeatureActivated: boolean = false;

  get LabelPosition() {
    return LabelPosition;
  }

  constructor(
    public dialog: MatDialog,
    private readonly translate: TranslateService,
    private interventionService: InterventionService,
    private interventionStoreService: InterventionStoreService,
    private readonly organizationStoreService: OrganizationStoreService,
    private readonly organizationsService: OrganizationsService,
    private photogrammetryUploadService: PhotogrammetryUploadService,
    private photogrammetryEventService: InterventionPhotogrammetryEventService,
    private confirmModalService: ConfirmModalService
  ) {}

  async ngOnInit(): Promise<void> {
    this.withStatusTranslation = await this.translate.get('INTERVENTION_ANALYSES.WITH_STATUS').toPromise();
    this.taskStatusRunningTranslation = await this.translate
      .get('INTERVENTION_ANALYSES.TASK_STATUS_' + TaskStatus.RUNNING)
      .toPromise();
    this.taskStatusFailedTranslation = await this.translate
      .get('INTERVENTION_ANALYSES.TASK_STATUS_' + TaskStatus.FAILED)
      .toPromise();
    this.taskStatusCompletedTranslation = await this.translate
      .get('INTERVENTION_ANALYSES.TASK_STATUS_' + TaskStatus.COMPLETED)
      .toPromise();
    this.taskStatusNoRunTranslation = await this.translate
      .get('INTERVENTION_ANALYSES.TASK_STATUS_' + TaskStatus.NO_RUN)
      .toPromise();

    this.organizationSubscription = this.organizationStoreService.getCurrentOrganization().subscribe((org) => {
      if (!org) {
        return;
      }
      this.organizationId = org._id;
    });
    await this.manageFeatures();
    await this.getGlobalRecordingState();
    await this.getFaceBlur();

    // in order to display currently uploading process, we copy current UploadProgress object from service
    this.uploadsProgress = [...this.photogrammetryUploadService.uploadsInProgress];
    for (let uploadProgress of this.uploadsProgress) {
      this.photogrammetryUploadService.uploadProgressObs
        .pipe(filter((data) => data.uuid === uploadProgress.uuid))
        .subscribe(
          (progress) => {
            this.manageProgressBar(progress);
          },
          (err) => console.log(err)
        );
    }
    this.incomingDataSubscription = this.photogrammetryEventService.changed.subscribe((message: TiffStatusMessage) => {
      this.handleMessage(message);
    });
    this.manageTiffStatusMessages();
  }

  handleMessage(message: TiffStatusMessage) {
    console.log(`[AnalysesPageComponent] - status message for project ${message?.name}`);
    this.tiffStatusMessages = this.tiffStatusMessages.filter((msg) => msg.mediaId !== message.mediaId);
    this.tiffStatusMessages.push(message);
    if (this.isCompleted(message) || this.isFailed(message)) {
      //hide progress bar after X [sec]
      timer(this.hideProgressBarAfterCompleteTimer * 1e3).subscribe(() => {
        this.tiffStatusMessages = this.tiffStatusMessages.filter((msg) => msg.mediaId !== message.mediaId);
      });
    }
  }

  async manageFeatures() {
    this.blurFeatureActivated = false;
    this.smockFeatureActivated = false;
    const features = [
      ...(await this.organizationsService
        .organizationControllerGetOrganizationFeatures(this.organizationId)
        .toPromise()),
    ];
    features.forEach((f) => {
      switch (f.featureId) {
        case 'blur':
          this.blurFeatureActivated = true;
          break;
        case 'smock':
          this.smockFeatureActivated = true;
          break;
        default:
          break;
      }
    });
  }

  async getFaceBlur() {
    this.filterParameters = await this.interventionService.getInterventionFilterParametersV1(
      this.organizationId,
      this.intervention._id
    );
    const faceblurParameters = this.filterParameters?.filtersParameters?.find((f) => f.name == FilterName.blur);
    this.faceblurState = faceblurParameters?.active ?? false;
    if (!this.blurFeatureActivated && this.faceblurState) {
      //disable blur features
      this.toggleFaceblur(false);
    }
  }

  async getGlobalRecordingState() {
    this.intervention = await this.interventionService.getInterventionV1(this.organizationId, this.intervention._id);
    this.recordingState = this.intervention?.isGlobalRecording !== false;
  }

  async toggleFaceblur(state: boolean) {
    await this.manageFeatures();
    const dtoFilterParameters: InterventionFilterParametersDto = {};
    if (this.blurFeatureActivated) {
      dtoFilterParameters.filtersParameters = [{ name: FilterName.blur, active: state, applied: false }];
    } else {
      dtoFilterParameters.filtersParameters = [{ name: FilterName.blur, active: false, applied: false }];
      timer(this.childToggleSwitchStateTimer * 1e3).subscribe(() => {
        this.faceblurState = false;
        this.blurSwitch.state = false;
      });
    }
    await this.interventionService.updateInterventionFilterParametersV1(
      this.organizationId,
      this.intervention._id,
      dtoFilterParameters
    );
  }

  async toggleSmockDetection(state: boolean) {
    console.log('TOGGLE SMOCK DETECTION (MOCK)', state);
    // await this.manageFeatures();
  }

  async toggleRecord(state: boolean) {
    const interventionDto: UpdateInterventionDto = {
      ...this.intervention,
      isGlobalRecording: state,
    } as UpdateInterventionDto;
    const updatedIntervention = await this.interventionService.updateInterventionV1(
      this.organizationId,
      this.intervention._id,
      interventionDto
    );
    this.interventionStoreService.updateIntervention(this.organizationId, updatedIntervention);
  }

  openDialog() {
    const config = {
      id: 'newMediaUpload',
      width: '80%',
      data: {
        intervention: this.intervention && this.intervention.isArchived !== true ? this.intervention : null,
      },
    };
    const dialogRef = this.dialog.open(PhotogrammetryUploadDialogComponent, config);

    dialogRef.afterClosed().subscribe((uploadProgress: UploadProgress) => {
      if (uploadProgress) {
        this.uploadsProgress.push(uploadProgress);
        this.photogrammetryUploadService.uploadProgressObs
          .pipe(filter((data) => data.uuid === uploadProgress.uuid))
          .subscribe(
            (progress) => {
              this.manageProgressBar(progress);
              this.manageTiffStatusMessages();
            },
            (err) => console.log(err)
          );
      }
    });
  }

  manageProgressBar(uploadProgress: UploadProgress) {
    this.uploadsProgress = this.uploadsProgress.map((data) =>
      data.uuid === uploadProgress.uuid ? uploadProgress : data
    );
    if (uploadProgress.isComplete()) {
      //hide progress bar after X [sec]
      timer(this.hideProgressBarAfterCompleteTimer * 1e3).subscribe(() => {
        this.uploadsProgress = this.uploadsProgress.reduce((acc, data) => {
          if (data.uuid !== uploadProgress.uuid) {
            acc.push(data);
          }
          return acc;
        }, []);
      });
    }
  }

  getModeOfUploadProgress(uploadProgress: UploadProgress): string {
    if (!uploadProgress.isUploadBegun()) {
      return 'indeterminate';
    }
    return 'determinate';
  }

  getUploadDescription(uploadProgress: UploadProgress): string {
    if (!uploadProgress) {
      return '';
    }
    return `${uploadProgress.projectName} - ${uploadProgress.date.toLocaleString()} - ${
      uploadProgress.numberOfUploadedFiles
    }/${uploadProgress.numberOfFiles}`;
  }

  async pauseUpload(uploadProgress: UploadProgress) {
    const progress = this.photogrammetryUploadService.togglePauseProgressBar(uploadProgress);
    progress.pauseEvent.pipe(first()).subscribe(() => {
      console.info(`[Orthophotos Uploads] - 'All paused' event for project ${uploadProgress.projectName}`);
    });
  }

  async stopUpload(uploadProgress: UploadProgress) {
    const progress = this.photogrammetryUploadService.stopUploadProgressBar(uploadProgress);
    // The pause event is generated when all uploaders have sent the event 'progress',
    // meaning they will now wait before continue upload
    // NB : the "Pause State" of 'HugeUploader' free resources, allowing backend to suppress them and abort the upload
    progress.pauseEvent.pipe(first()).subscribe(async () => {
      console.info(`[Orthophotos Uploads] - 'All paused' event for project ${uploadProgress.projectName}... Aborting`);
      this.uploadsProgress = this.uploadsProgress.reduce((acc, data) => {
        if (data.uuid !== uploadProgress.uuid) {
          acc.push(data);
        }
        return acc;
      }, []);
      console.info(`[Orthophotos Uploads] - Project ${uploadProgress.projectName} will be abort`);
      await this.photogrammetryUploadService.abortProject(
        this.organizationId,
        this.intervention._id,
        uploadProgress.mediaId
      );
    });
    // NB : Obviously, in some particular cases, this event may never be fired
    // For example if the pause request happen when an uploader was already done with its last chunk
    // Here come the necessity to clean periodically tmp files on backend
  }

  getMessageDescription(msg: TiffStatusMessage): string {
    if (!msg) {
      return '';
    }
    const statusMessage = this.withStatusTranslation + ' ' + this.getStatusTranslation(msg);
    const result = `${msg.name} - ${new Date(+msg.timestamp).toLocaleString()} - "${msg.message}" ${statusMessage}`;
    return result;
  }

  getStatusTranslation(msg: TiffStatusMessage): string {
    let result: string = '';
    switch (msg.status) {
      case TaskStatus.COMPLETED:
        result = this.taskStatusCompletedTranslation;
        break;
      case TaskStatus.FAILED:
        result = this.taskStatusFailedTranslation;
        break;
      case TaskStatus.RUNNING:
        result = this.taskStatusRunningTranslation;
        break;
      case TaskStatus.NO_RUN:
        result = this.taskStatusNoRunTranslation;
        break;
    }

    return result;
  }

  getModeOfProjectProgress(msg: TiffStatusMessage): string {
    let mode: string = 'query';
    switch (msg.status) {
      case TaskStatus.FAILED:
        mode = 'buffer';
        break;
      case TaskStatus.NO_RUN:
        mode = 'query';
        break;
      case TaskStatus.RUNNING:
      case TaskStatus.COMPLETED:
        mode = 'determinate';
        break;
    }
    return mode;
  }

  getClassOfProjectProgress(msg: TiffStatusMessage): string {
    let color: string = 'primary';
    switch (msg.status) {
      case TaskStatus.FAILED:
        color = 'progress-warn';
        break;
      case TaskStatus.NO_RUN:
      case TaskStatus.RUNNING:
        color = 'progress-primary';
        break;
      case TaskStatus.COMPLETED:
        color = 'progress-completed';
        break;
    }
    return color;
  }

  isFailed(msg: TiffStatusMessage): boolean {
    return msg.status === TaskStatus.FAILED;
  }
  isCompleted(msg: TiffStatusMessage): boolean {
    return msg.status === TaskStatus.COMPLETED;
  }
  haveProgress(msg: TiffStatusMessage): boolean {
    return !isNaN(msg?.progress);
  }
  getBufferValue(msg: TiffStatusMessage): number {
    return msg.progress + (100 - msg.progress) / 2;
  }

  async deleteProject(msg: TiffStatusMessage, withModal: boolean = false) {
    const isDeletionConfirmed: boolean =
      !withModal ||
      (await this.confirmModalService.showConfirmModal('INTERVENTION_ANALYSES.CONFIRMATION_MESSAGE', 'validate'));

    if (isDeletionConfirmed) {
      console.info(`[Orthophotos Project] - Failed project ${msg.name} will be deleted`);
      await this.photogrammetryUploadService.abortProject(this.organizationId, this.intervention._id, msg.mediaId);
      // NB : Obviously, in some particular cases, this event may never be fired
      // For example if the pause request happen when an uploader was already done with its last chunk
      // Here come the necessity to clean periodically tmp files on backend

      //update messages
      this.manageTiffStatusMessages();
    }
  }

  async manageTiffStatusMessages() {
    this.tiffStatusMessages = await this.photogrammetryUploadService.getStatusProject(
      this.organizationId,
      this.intervention?._id
    );

    for (const message of this.tiffStatusMessages) {
      if (this.isCompleted(message) || this.isFailed(message)) {
        //hide progress bar after X [sec]
        timer(this.hideProgressBarAfterCompleteTimer * 1e3).subscribe(() => {
          this.tiffStatusMessages = this.tiffStatusMessages.filter((msg) => msg.mediaId !== message.mediaId);
        });
      }
    }
  }
}
