export const authenticationConfig = {
  store: {
    key: 'authentication',
  },
  routes: {
    resetPassword: 'reset-password',
    loginAccessCode: 'access-code-login',
    params: {
      action: 'action',
      provider: 'provider',
      error: 'error',
    },
  },
  recoverPassword: {
    resendResetCodeDelay: 30, // in seconds
  },
  registration: {
    codeExpirationTime: 15, // in minutes
  },
  password: {
    validation: {
      minLength: 8,
      maxLength: 64,
      numbers: /(?=.*\d)/,
      lowercase: /(?=.*[a-z])/,
      uppercase: /(?=.*[A-Z])/,
      // forbiddenCharacters: ['\\'],
      specialCharacters: /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_]).*$/,
    },
  },
};
