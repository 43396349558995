import { NgModule, InjectionToken } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { organizationReducer, organizationInitialState, OrganizationState } from './organization.reducer';
import { organizationConfig } from '../organization.config';
import { StoreStateService, featureStoreConfigFactory } from 'src/app/core/store';
import { StoreConfig } from '@ngrx/store/src/store_module';

const featureStoreKey = organizationConfig.store.key;

export const organizationFeatureStoreKeyToken = new InjectionToken(`FeatureStoreKeyInjectionToken:${featureStoreKey}`);
export const organizationFeatureStoreInitialStateToken = new InjectionToken<OrganizationState>(
  `FeatureStoreInitialStateInjectionToken:${featureStoreKey}`
);
export const organizationStoreFeatueConfigToken = new InjectionToken<StoreConfig<OrganizationState>>(
  `FeatureStoreConfigInjectionToken:${featureStoreKey}`
);

@NgModule({
  imports: [StoreModule.forFeature(featureStoreKey, organizationReducer, organizationStoreFeatueConfigToken)],
  providers: [
    {
      provide: organizationFeatureStoreKeyToken,
      useValue: featureStoreKey,
    },
    {
      provide: organizationFeatureStoreInitialStateToken,
      useValue: organizationInitialState,
    },
    {
      provide: organizationStoreFeatueConfigToken,
      deps: [organizationFeatureStoreKeyToken, organizationFeatureStoreInitialStateToken, StoreStateService],
      useFactory: featureStoreConfigFactory,
    },
  ],
})
export class OrganizationStoreModule {}
