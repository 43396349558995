export class FeatureCalculation {
  featureType?: string;
  area?: number;
  length?: number;
  perimeter?: number;
  latitude?: number;
  longitude?: number;
  radiusInKm?: number;
  wind?: number;
  bearing?: number;
  invBearing?: number;

  constructor({
    featureType,
    area,
    length,
    perimeter,
    latitude,
    longitude,
    radiusInKm,
    wind,
    bearing,
    invBearing,
  }: Partial<FeatureCalculation> = {}) {
    this.featureType = featureType || null;
    this.area = area || null;
    this.length = length || null;
    this.perimeter = perimeter || null;
    this.latitude = latitude || null;
    this.longitude = longitude || null;
    this.radiusInKm = radiusInKm || null;
    this.wind = wind || null;
    this.bearing = bearing ?? null;
    this.invBearing = invBearing ?? null;
  }
}
