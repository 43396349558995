import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Feature } from '@turf/turf';
import { tdrawConfig } from '../../../../tdraw.config';
import { FeatureCalculation } from '../../../../models/feature-calculation.model';
import { FeatureColorProperty } from '../../../../models/feature-color-property.enum';
import { FeatureProperty } from '../../../../models/feature-property.enum';
import { ConfirmModalService } from '../../../../../midgard-controls/services/confirm-modal.service';
import { MapIconsService } from '../../../../services/map-icons.service';
import { MatDialog } from '@angular/material/dialog';
import { MapIcon, SpecialType } from '../../../../models/map-icon';
import { SitacSelectorModalComponent } from '../../../modals/sitac-selector-modal/sitac-selector-modal.component';
import { EditModeManager } from 'src/app/tdraw/misc/edit-mode-manager';
import { Subscription } from 'rxjs';
import { Unsubscribe } from 'src/app/core/decorators';
import { AnnotationCategory } from 'src/app/tdraw/models/annotation-category.enum';
import { MidSelectItem } from 'src/app/midgard-controls/models';

@Unsubscribe()
@Component({
  selector: 'tdraw-gis-edition-properties-panel',
  templateUrl: './gis-edition-properties-panel.component.html',
  styleUrls: ['./gis-edition-properties-panel.component.scss'],
})
export class GisEditionPropertiesPanelComponent implements OnInit, OnDestroy {
  @Input()
  editModeManager: EditModeManager;

  @Output()
  goToToolbox: EventEmitter<any>;

  @Output()
  delete: EventEmitter<any>;

  calculation: FeatureCalculation;
  selectedFeature: Feature;
  featureColors: FeatureColorProperty[];
  reversedIcon?: string;
  mapIcon: MapIcon;
  sectorItems: MidSelectItem[] = [];

  // Subscriptions
  private featureSelectedChangedSubscription: Subscription;

  // Enum
  get SpecialType() {
    return SpecialType;
  }
  get AnnotationCategory() {
    return AnnotationCategory;
  }

  constructor(
    private confirmModalService: ConfirmModalService,
    private mapIconsService: MapIconsService,
    private dialog: MatDialog
  ) {
    this.goToToolbox = new EventEmitter();
    this.delete = new EventEmitter();
  }

  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.featureColors = tdrawConfig.featureColors;
    this.selectedFeature = this.editModeManager.selectedFeature;
    this.onFeatureSelectedChanged();

    this.featureSelectedChangedSubscription = this.editModeManager.onFeatureSelectedChanged((data) => {
      this.selectedFeature = data.feature;
      this.onFeatureSelectedChanged();
    });

    this.populateSectorItems();
  }

  private populateSectorItems() {
    this.sectorItems = [
      {
        value: '',
        name: 'N/A',
        isSelected: true,
      },
    ];
    const sectors = this.editModeManager.getSectors();
    for (const sector of sectors) {
      this.sectorItems.push({
        value: sector.featureId,
        name: sector.title,
        isSelected: false,
      });
    }
  }

  private onFeatureSelectedChanged() {
    const key = this.selectedFeature.properties?.icon;
    this.mapIcon = this.mapIconsService.getMapIcon(key);
    if (!this.mapIcon) {
      const ref = this.mapIconsService.getMapIconReference(key);
      this.mapIcon = this.mapIconsService.getMapIcon(ref.guiIcon);
    }
    this.reversedIcon = this.mapIconsService.getMapIconReference(key).reversedIcon ?? null;
    this.calculation = this.editModeManager.mapManager.calculateMetrics(this.selectedFeature);
  }

  onChangeConeWind(wind: number): void {
    this.editModeManager.changeCurrentConeWind(wind);
  }

  onChangeCircleRadius(radiusInMeters: number): void {
    this.editModeManager.changeCurrentCircleRadius(radiusInMeters);
  }

  openSitacSelectorModal() {
    const dialogRef = this.dialog.open(SitacSelectorModalComponent, {
      width: '349px',
    });
    dialogRef.afterClosed().subscribe((icon?: MapIcon) => {
      if (!icon) {
        return;
      }
      this.setFeatureProperty(FeatureProperty.Icon, icon.defaultMapIcon);
    });
  }

  reversePolyline() {
    this.setFeatureProperty(FeatureProperty.Icon, this.reversedIcon);
  }

  setFeatureProperty(property: FeatureProperty, value: any): void {
    this.editModeManager.setCurrentFeatureProperty(property, value);
  }

  onChangeSector(event) {
    console.log(event);
  }

  async onDeleteElementFromMap() {
    const isDeletingElementFromMapConfirmed = await this.confirmModalService.showConfirmModal(
      'FEATURE_DELETE_CONFIRM_MESSAGE',
      'remove'
    );
    if (isDeletingElementFromMapConfirmed) {
      this.delete.emit();
      this.backToToolbox();
    }
  }

  backToToolbox() {
    this.goToToolbox.emit();
  }

  isConeRotateMode(): boolean {
    return this.editModeManager.mapManager.getDrawMode() === 'cone_rotate_mode';
  }

  // Enum
  get FeatureProperty() {
    return FeatureProperty;
  }
}
