import { Injectable } from '@angular/core';
import { required, validate } from 'src/app/shared';

@Injectable({
  providedIn: 'root',
})
export class PaginationService {
  @validate
  getPageItems<T>(@required allItems: T[], @required page: number, @required pageSize: number): T[] {
    const from = (page - 1) * pageSize;
    const to = page * pageSize;

    const pageItems = allItems.slice(from, to);

    return pageItems;
  }
}
