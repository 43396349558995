<div class="panel-container" [class.inner]="type === MidExpansionPanelType.secondary">
  <div
    class="header"
    (click)="toggle()"
    [class.primary]="type === MidExpansionPanelType.primary"
    [class.secondary]="type === MidExpansionPanelType.secondary"
  >
    <span>{{ title }}</span>
    <div>
      <i class="fas" [class.fa-chevron-down]="!isExpanded" [class.fa-chevron-up]="isExpanded"></i>
    </div>
  </div>
  <div *ngIf="isExpanded" class="inner-content">
    <ng-content></ng-content>
  </div>
</div>
