import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mid-block-carousel',
  templateUrl: './mid-block-carousel.component.html',
  styleUrls: ['./mid-block-carousel.component.scss'],
})
export class MidBlockCarouselComponent {
  constructor() {
    this.leftArrowClick = new EventEmitter<any>();
    this.rightArrowClick = new EventEmitter<any>();
  }

  @Input()
  isLeftArrowEnabled: boolean;

  @Input()
  isRightArrowEnabled: boolean;

  @Output()
  leftArrowClick: EventEmitter<any>;

  @Output()
  rightArrowClick: EventEmitter<any>;

  onLeftArrowClick() {
    if (this.isLeftArrowEnabled) {
      this.leftArrowClick.emit();
    }
  }

  onRightArrowClick() {
    if (this.isRightArrowEnabled) {
      this.rightArrowClick.emit();
    }
  }
}
