import { Drone } from '../../autogenerated/model';
import { cloneDeep, filter, forEach, map } from 'lodash';
import { Assert } from 'src/app/shared';
import {
  DeleteDroneAction,
  DroneActions,
  DroneActionType,
  SetOrganizationDroneAction,
  SetOrganizationDronesAction,
  UpdateDroneAction,
} from './drone.actions';
import { CoreActionTypes } from '../../core/store';

export interface DroneState {
  allByDroneId: StringMap<Drone>;
  groupByOrganizationId: StringMap<Drone[]>;
}

export const droneInitialState: DroneState = {
  allByDroneId: {},
  groupByOrganizationId: {},
};

export function droneReducer(state: DroneState, action: DroneActions): DroneState {
  Assert.isNotNull(action, 'action');

  switch (action.type) {
    case DroneActionType.SetOrganizationDrone:
      return setOrganizationDrone(state, action);
    case DroneActionType.SetOrganizationDrones:
      return setOrganizationDrones(state, action);
    case DroneActionType.UpdateDroneAction:
      return updateDrone(state, action);
    case DroneActionType.DeleteDroneAction:
      return deleteDrone(state, action);
    case CoreActionTypes.ResetState:
      return resetStateActionReducer();
    default:
      return state;
  }
}

export function setOrganizationDrones(state: DroneState, action: SetOrganizationDronesAction): DroneState {
  Assert.isNotNull(state, 'state');
  Assert.isNotNull(action, 'action');

  forEach(action.drones, (drone: Drone) => {
    state.allByDroneId[drone._id] = drone;
  });

  const newState: DroneState = {
    ...state,
    groupByOrganizationId: {
      ...state.groupByOrganizationId,
      [action.organizationId]: action.drones,
    },
  };

  return newState;
}

export function setOrganizationDrone(state: DroneState, action: SetOrganizationDroneAction): DroneState {
  Assert.isNotNull(state, 'state');
  Assert.isNotNull(action, 'action');

  state.allByDroneId[action.drone._id] = action.drone;

  const organizationDrones = state.groupByOrganizationId[action.organizationId];
  const newOrganizationDrones = [...organizationDrones, action.drone];

  const newState: DroneState = {
    ...state,
    groupByOrganizationId: {
      ...state.groupByOrganizationId,
      [action.organizationId]: newOrganizationDrones,
    },
  };

  return newState;
}

export function updateDrone(state: DroneState, action: UpdateDroneAction): DroneState {
  Assert.isNotNull(state, 'state');
  Assert.isNotNull(action, 'action');

  state.allByDroneId[action.drone._id] = action.drone;

  const drones = map(state.groupByOrganizationId[action.organizationId], (drone: Drone) =>
    drone._id === action.drone._id ? action.drone : drone
  );

  const newState: DroneState = {
    ...state,
    allByDroneId: {
      ...state.allByDroneId,
    },
    groupByOrganizationId: {
      ...state.groupByOrganizationId,
      [action.organizationId]: drones,
    },
  };
  return newState;
}

export function deleteDrone(state: DroneState, action: DeleteDroneAction): DroneState {
  Assert.isNotNull(state, 'state');
  Assert.isNotNull(action, 'action');

  const filterAll = filter(state.allByDroneId[action.organizationId], (drone: Drone) => drone._id !== action.drone._id);
  const filteredForOrganization = filter(
    state.groupByOrganizationId[action.organizationId],
    (drone: Drone) => drone._id !== action.drone._id
  );

  const newState: DroneState = {
    ...state,
    allByDroneId: {
      ...filterAll,
    },
    groupByOrganizationId: {
      ...state.groupByOrganizationId,
      [action.organizationId]: filteredForOrganization,
    },
  };
  return newState;
}

export function resetStateActionReducer(): DroneState {
  const newState: DroneState = cloneDeep(droneInitialState);

  return newState;
}
