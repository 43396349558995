<div class="alerts-container">
  <div class="alerts-title">
    <div class="title">{{ 'ALERTS.RECENT_VALIDATED_ALERTS' | translate }}</div>
    <div class="filter">
      <div class="inline-container">
        <button
          class="icon-button"
          [class.selected]="filter?.timeOfDay === 'all' || filter?.timeOfDay === 'day'"
          (click)="setFilterTimeOfDay('day')"
        >
          <i class="fas fa-sun"></i>
        </button>
        <div class="icon-button-spacer">|</div>
        <button
          class="icon-button"
          [class.selected]="filter?.timeOfDay === 'all' || filter?.timeOfDay === 'night'"
          (click)="setFilterTimeOfDay('night')"
        >
          <i class="fas fa-moon"></i>
        </button>
      </div>
      <div class="inline-container">
        <button class="icon-button" [class.selected]="filter?.days === 1" (click)="setFilterDay(1)">1j</button>
        <div class="icon-button-spacer">|</div>
        <button class="icon-button" [class.selected]="filter?.days === 3" (click)="setFilterDay(3)">3j</button>
        <div class="icon-button-spacer">|</div>
        <button class="icon-button" [class.selected]="filter?.days === 7" (click)="setFilterDay(7)">7j</button>
      </div>
      <div class="inline-container">
        <app-mid-icon-button class="alert-sound" size="wide" type="secondary" (action)="resetFilter()">
          <i class="fa-solid fa-filter-circle-xmark"></i>
        </app-mid-icon-button>
      </div>
    </div>
  </div>
  <div class="alerts">
    <app-intervention-alert
      *ngFor="let alert of alerts; trackBy: trackByItem"
      [alert]="alert"
      [selectedAlert]="selectedAlert"
      (alertSelected)="onAlertSelected($event)"
    ></app-intervention-alert>
  </div>
</div>
