import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { InterventionService } from 'src/app/autogenerated/interventionService';
import { Alert, Drone, Intervention, Organization, UpdateInterventionAlertDto } from 'src/app/autogenerated/model';
import { Unsubscribe } from '../../../core/decorators';
import { Subscription, firstValueFrom } from 'rxjs';
import { OrganizationStoreService } from '../../../organization/store';
import { DroneService } from '../../../autogenerated/droneService';
import { isEmpty } from 'lodash';
import { interventionConfig } from '../../intervention.config';
import mapboxgl from 'mapbox-gl';
import { tdrawConfig } from 'src/app/tdraw/tdraw.config';
import * as turf from '@turf/turf';
import { Filter } from '../intervention-alerts/intervention-alerts.component';
import { OrganizationsService } from 'src/api';

@Unsubscribe()
@Component({
  selector: 'app-intervention-alert-modal',
  templateUrl: './intervention-alert-modal.component.html',
  styleUrls: ['./intervention-alert-modal.component.scss'],
})
export class InterventionAlertModalComponent implements OnInit, OnDestroy {
  @Input()
  intervention: Intervention;

  organization: Organization;
  smockFeatureActivated: boolean = false;

  isPanelOpened: boolean = false;
  filter: Filter;
  alerts: Alert[];
  selectedAlert: Alert;
  currentDroneName: string;

  private organizationSubscription: Subscription;
  private refreshTimeoutId: any;
  private alertsRefreshTimeout: number = 0;

  sound = true;
  numberOfDetectedAlerts = 0;

  // For resizing
  isResizing = false;
  containerHeight = 300;
  minContainerHeight = 300;
  triggerHeight = 31;

  // Alert map
  map?: mapboxgl.Map;

  constructor(
    private droneService: DroneService,
    private interventionService: InterventionService,
    private organizationStoreService: OrganizationStoreService,
    private organizationsService: OrganizationsService
  ) {
    this.alerts = [];
    this.currentDroneName = '';
    this.selectedAlert = null;
    this.map = null;
    this.filter = { timeOfDay: 'all', days: 3 };
  }

  ngOnInit(): void {
    this.organizationSubscription = this.organizationStoreService
      .getCurrentOrganization()
      .subscribe(async (organization) => {
        this.organization = organization;
        await this.manageFeatures(organization._id);
        if (this.smockFeatureActivated) {
          await this.getAlertsWithoutWebSocket(organization._id);
        }
      });
  }

  ngOnDestroy(): void {
    this.stopAlertsRefresh();
  }

  async manageFeatures(organizationId: string) {
    this.smockFeatureActivated = false;
    const features = [
      ...(await firstValueFrom(
        this.organizationsService.organizationControllerGetOrganizationFeatures(organizationId)
      )),
    ];
    features.forEach((f) => {
      if (f.featureId === 'smock') {
        this.smockFeatureActivated = true;
      }
    });
  }

  async getAlertsWithoutWebSocket(organizationId: string) {
    this.stopAlertsRefresh();

    const innerRefreshTimeoutId = setTimeout(async () => {
      this.alertsRefreshTimeout = interventionConfig.alertsRefreshTimeout;
      this.alerts = await this.getAlerts(organizationId);

      if (!!this.refreshTimeoutId && innerRefreshTimeoutId === this.refreshTimeoutId) {
        await this.getAlertsWithoutWebSocket(organizationId);
      }
    }, this.alertsRefreshTimeout);

    this.refreshTimeoutId = innerRefreshTimeoutId;
  }

  onOpenModal() {
    this.isPanelOpened = !this.isPanelOpened;
  }

  async getDroneName(droneId: string) {
    const drone = await this.getDrone(droneId);
    if (isEmpty(drone)) {
      return '';
    }
    return drone.name;
  }

  async onAlertSelected(alert: Alert) {
    if (this.selectedAlert?._id === alert?._id) {
      this.selectedAlert = null;
      return;
    }
    this.selectedAlert = alert;
    this.currentDroneName = await this.getDroneName(alert.droneId);
    this.loadAlertMap();
  }

  private stopAlertsRefresh() {
    clearTimeout(this.refreshTimeoutId);
    this.refreshTimeoutId = null;
  }

  private async getDrone(droneId: string): Promise<Drone> {
    try {
      const drone = await this.droneService.getDrone({ _id: droneId });
      return drone;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  private async getAlerts(organizationId: string): Promise<Alert[]> {
    try {
      const alerts = await this.interventionService.getAlerts(
        organizationId,
        this.intervention?._id || undefined,
        this.filter
      );
      // const numberOfDetectedAlerts = this.alerts?.filter((it) => it.statusAlert === 'Detected')?.length;
      let numberOfDetectedAlerts = 0;
      for (const alert of this.alerts) {
        if (alert.statusAlert !== 'Detected') break; // continue;
        numberOfDetectedAlerts++;
      }
      if (this.sound && this.numberOfDetectedAlerts < numberOfDetectedAlerts) {
        this.startSoundAlarm(100);
        setTimeout(() => {
          this.startSoundAlarm(100);
        }, 7000);
      }
      this.numberOfDetectedAlerts = numberOfDetectedAlerts;
      return alerts;
    } catch (err) {
      console.log(err);
      return [];
    }
  }

  async updateInterventionAlert(status: 'Validated' | 'FalsePositive') {
    try {
      const dto: UpdateInterventionAlertDto = {
        statusAlert: status,
        description: this.selectedAlert?.description,
      };
      const alert = await this.interventionService.updateInterventionAlert(
        this.organization._id,
        this.selectedAlert._id,
        dto
      );
    } catch (err) {
      console.log(err);
      return err;
    }
  }

  stopSoundAlarm() {
    this.sound = !this.sound;
  }

  private async startSoundAlarm(volume: number) {
    return new Promise<boolean>((resolve, reject) => {
      try {
        if (!this.sound) {
          resolve(false);
        }
        // You're in charge of providing a valid AudioFile that can be reached by your web app
        const soundAlarm = new Audio('assets/audio/alert-1.mp3');
        // Set volume
        soundAlarm.volume = volume / 100;
        soundAlarm.loop = false;
        soundAlarm.onended = () => {
          resolve(true);
        };
        soundAlarm?.play();
      } catch (error) {
        reject(error);
      }
    });
  }

  hasAlertMetadata() {
    return (
      turf.isNumber(this.selectedAlert?.metadata?.longitude) &&
      turf.isNumber(this.selectedAlert?.metadata?.latitude) &&
      turf.isNumber(this.selectedAlert?.metadata?.azimuth)
      // && turf.isNumber(this.selectedAlert?.metadata?.fov)
    );
  }

  private loadAlertMap() {
    if (this.map) {
      console.log('Map already existing, destroying...');
      this.map?.remove();
      this.map = null;
    }
    if (!this.hasAlertMetadata()) {
      return;
    }
    console.log('Alert: ', this.selectedAlert);
    const alert = this.selectedAlert?.metadata;
    const camera = [alert.longitude, alert.latitude] as [number, number];
    const target = isFinite(alert.target_longitude) && isFinite(alert.target_latitude) ? [alert.target_longitude, alert.target_latitude] as [number, number] : null;

    this.map = new mapboxgl.Map({
      accessToken: tdrawConfig.mapAccessToken,
      container: 'alert-map',
      style: tdrawConfig.mapStyle,
      center: camera,
      zoom: 10,
    });

    // Build a Polygon based on camera Fov and Azimuth
    const { azimuth, fov } = alert;
    const lengthKm = 20;
    const angle = 10;
    const bearingP1 = (azimuth - angle / 2) % 360;
    const bearingP2 = (azimuth + angle / 2) % 360;
    const p1 = turf.destination(turf.point(camera), lengthKm, bearingP1);
    const p2 = turf.destination(turf.point(camera), lengthKm, bearingP2);

    // Once the map is loaded, display the Polygon and the camera position
    this.map.once('load', (e) => {
      new mapboxgl.Marker().setLngLat(camera).addTo(this.map);

      if (target) {
        const el = document.createElement('div');
        el.className = 'map-fire-marker';
        new mapboxgl.Marker(el).setLngLat(target).addTo(this.map);
      }

      this.map.addSource('cone', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: [[camera, p1.geometry.coordinates, p2.geometry.coordinates]],
              },
            },
          ] as any,
        },
      });

      this.map.addLayer({
        id: 'cone',
        type: 'fill',
        source: 'cone',
        filter: ['all'],
        paint: {
          'fill-color': '#00ffff',
          'fill-opacity': 0.4,
        },
      });
    });
  }

  // === For Resizing ===
  @HostListener('window:mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    const element = event.target as HTMLElement;
    if (element && element.className == 'resize-bar') {
      this.isResizing = true;
    }
  }
  @HostListener('window:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (this.isResizing) {
      this.containerHeight -= event.movementY;
      if (this.containerHeight < this.minContainerHeight) {
        this.containerHeight = this.minContainerHeight;
      }
    }
  }
  @HostListener('window:mouseup', ['$event'])
  onMouseUp(event: MouseEvent) {
    this.isResizing = false;
  }
}
