<app-mid-right-panel
  #panelCreateDrone="appMidRightPanel"
  [title]="('DIALOGS.CREATE_DRONE.TITLE' | translate) + ' ' + organization?.name"
>
  <app-mid-right-panel-content>
    <form #formCreateDrone="ngForm" novalidate>
      <div class="row m-0 px-4 pb-4">
        <section class="section col-md-12">
          <app-mid-input
            size="wide"
            [(value)]="data.drone.name"
            #name="appMidInput"
            name="name"
            [required]="true"
            [label]="'DIALOGS.CREATE_DRONE.NAME_LABEL' | translate"
            [placeholder]="'DIALOGS.CREATE_DRONE.NAME_PLACEHOLDER' | translate"
          >
            <span *ngIf="!formCreateDrone.pristine && name.invalid">{{ 'COMMON_REQUIRED_FIELD' | translate }}</span>
          </app-mid-input>

          <app-mid-select
            size="wide"
            [(items)]="data.droneModel"
            #droneModel="appMidSelect"
            name="droneModel"
            [required]="true"
            [label]="'DIALOGS.CREATE_DRONE.MODEL_LABEL' | translate"
            [placeholder]="'DIALOGS.CREATE_DRONE.MODEL_PLACEHOLDER' | translate"
            [(selectedItem)]="data.drone.droneModel"
          >
            <span *ngIf="!formCreateDrone.pristine && droneModel.invalid">{{
              'COMMON_REQUIRED_FIELD' | translate
            }}</span>
            <span *ngIf="data.drone.droneModel === DroneModel.Other">{{
              'DIALOGS.CREATE_DRONE.OTHERS_DRONES_MIGHT_NOT_OPTIMIZED_FOR_YOUR_APP' | translate
            }}</span>
          </app-mid-select>

          <!--
          <div class="section col-md-12">
            <label>{{ 'DIALOGS.CREATE_DRONE.DRONE_USED_FOR_INTERVENTION' | translate }}</label>
            <app-mid-select-intervention-combobox
              [organizationId]="organizationId"
              (interventionSelected)="getSelectedIntervention($event)"
              [isHiddenHint]="true"
            ></app-mid-select-intervention-combobox>
          </div>
          -->
        </section>
      </div>
    </form>
  </app-mid-right-panel-content>

  <app-mid-right-panel-footer>
    <div class="row m-0 py-3 px-4">
      <app-mid-button class="pr-2" type="primary" (action)="onCreate()" [disabled]="formCreateDrone.form?.invalid">{{
        'DIALOGS.COMMON.CREATE' | translate
      }}</app-mid-button>
      <app-mid-button class="pr-2" type="secondary" (action)="panelCreateDrone.close()">{{
        'DIALOGS.COMMON.CANCEL' | translate
      }}</app-mid-button>
    </div>
  </app-mid-right-panel-footer>
</app-mid-right-panel>
