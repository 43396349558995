<app-mid-expansion-panel title="{{ 'SITAC_SYMBOLS' | translate }}" isExpanded="true" type="primary">
  <div class="filters-box">
    <div>
      <p class="d-inline-block">{{ 'TDRAW.FILTERS' | translate }}</p>
      <app-mid-icon-button
        type="primary"
        class="reset-btn"
        (click)="applyColorFilter(IconColor.Black); applyStateFilter(null)"
      >
        <i class="fa-solid fa-filter-circle-xmark"></i>
      </app-mid-icon-button>
    </div>

    <p class="subtitle">{{ 'TDRAW.CATEGORY' | translate }}</p>
    <div class="color-selection-box">
      <button
        class="color-button black"
        [class.selected]="filterColor === IconColor.Black"
        (click)="applyColorFilter(IconColor.Black)"
        [title]="'TDRAW.ICON_FILTERS.DEFAULT' | translate"
      ></button>
      <button
        class="color-button purple"
        [class.selected]="filterColor === IconColor.Purple"
        (click)="applyColorFilter(IconColor.Purple)"
        [title]="'TDRAW.ICON_FILTERS.PURPLE' | translate"
      ></button>
      <button
        class="color-button green"
        [class.selected]="filterColor === IconColor.Green"
        (click)="applyColorFilter(IconColor.Green)"
        [title]="'TDRAW.ICON_FILTERS.GREEN' | translate"
      ></button>
      <button
        class="color-button yellow"
        [class.selected]="filterColor === IconColor.Yellow"
        (click)="applyColorFilter(IconColor.Yellow)"
        [title]="'TDRAW.ICON_FILTERS.YELLOW' | translate"
      ></button>
      <button
        class="color-button blue"
        [class.selected]="filterColor === IconColor.Blue"
        (click)="applyColorFilter(IconColor.Blue)"
        [title]="'TDRAW.ICON_FILTERS.BLUE' | translate"
      ></button>
      <button
        class="color-button red"
        [class.selected]="filterColor === IconColor.Red"
        (click)="applyColorFilter(IconColor.Red)"
        [title]="'TDRAW.ICON_FILTERS.RED' | translate"
      ></button>
    </div>

    <p class="subtitle">{{ 'TDRAW.STATUS' | translate }}</p>
    <div class="state-selection-box">
      <button [class.selected]="filterState === null" (click)="applyStateFilter(null)">
        {{ 'TDRAW.ALL' | translate }}
      </button>
      <button [class.selected]="filterState === IconState.Planned" (click)="applyStateFilter(IconState.Planned)">
        {{ 'TDRAW.ICON_STATE.PLANNED' | translate }}
      </button>
      <button [class.selected]="filterState === IconState.Active" (click)="applyStateFilter(IconState.Active)">
        {{ 'TDRAW.ICON_STATE.ACTIVE' | translate }}
      </button>
    </div>

    <hr />
  </div>

  <app-mid-expansion-panel
    title="{{ index + 1 }}. {{ group.nameLangkey | translate }}"
    type="secondary"
    *ngFor="let group of groups; let index = index"
    [isExpanded]="true"
  >
    <div *ngFor="let item of group.subcategorizedIcons | keyvalue" class="pb-2">
      <p class="subcategory">{{ (AnnotationSubCategoryLangKey[item.key] | translate) || 'Others' }}</p>
      <button *ngFor="let icon of item.value" class="icon-button">
        <div class="{{ icon.cssclass }}" (click)="onElementSelected(icon)"></div>
      </button>
    </div>
  </app-mid-expansion-panel>
</app-mid-expansion-panel>
