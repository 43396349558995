import { validate, required } from '../required';
import { Assert } from './assert';

export class MathUtils {
  // Uses a secure, unpredictable random number generator
  @validate
  static getCryptographicallyRandomInt(@required length: number): number {
    Assert.isTrue(length > 0, 'length less or equal zero');

    const $window = window as any;
    const crypto: Crypto = $window.crypto || $window.msCrypto;
    if (!crypto) {
      console.error('window.crypto is undefined');
      return MathUtils.getRandomInt(length);
    }

    const single = new Uint32Array(1);
    crypto.getRandomValues(single);
    const result = single[0] % length;

    return result;
  }

  // Math random number
  @validate
  static getRandomInt(@required length: number): number {
    Assert.isTrue(length > 0, 'length less or equal zero');

    const max = Math.floor(length);
    const result = Math.random() % max;

    return result;
  }

  // Returns a random integer between min (inclusive) and max (inclusive).
  @validate
  static getRandomIntRange(@required minimum: number, @required maximum: number): number {
    Assert.isTrue(minimum >= 0, 'minimum less zero');
    Assert.isTrue(maximum >= 0, 'maximum less zero');
    Assert.isTrue(maximum >= minimum, 'maximum less minimum');

    const min = Math.ceil(minimum);
    const max = Math.floor(maximum);

    const result = Math.floor(Math.random() * (max - min + 1)) + min;

    return result;
  }
}
