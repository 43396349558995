import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import {
  ContextPermissionsDto,
  ContextPermissionDto,
  OrganizationDto,
  OrganizationGroupDto,
  OrganizationGroupService,
  UserDto,
  UsersService,
} from 'src/api';
import { MidRightPanelService } from 'src/app/midgard-controls/services/mid-right-panel.service';
import {
  NewOrganizationGroupComponent,
  NewOrganizationGroupComponentInput,
} from '../new-organization-group/new-organization-group.component';
import { Params, Router } from '@angular/router';
import { UserApiClientService } from 'src/api-services/user-api-client.service';

@Component({
  selector: 'app-organization-groups',
  templateUrl: './organization-groups.component.html',
  styleUrls: ['./organization-groups.component.scss'],
})
export class OrganizationGroupsComponent implements OnInit, OnChanges {
  @Input()
  organization: OrganizationDto;

  groups: OrganizationGroupDto[] = [];
  groupUserMap = new Map<OrganizationGroupDto, UserDto[]>();
  groupPermissionMap = new Map<OrganizationGroupDto, ContextPermissionsDto>();
  groupCreatePermission: ContextPermissionDto;

  constructor(
    private readonly organizationGroupService: OrganizationGroupService,
    private readonly usersService: UserApiClientService,
    private readonly midRightPanelService: MidRightPanelService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {}

  async ngOnChanges(changes: SimpleChanges) {
    const componentChanges = changes as PropertyMap<OrganizationGroupsComponent, SimpleChange>;
    if (componentChanges.organization.currentValue) {
      await this.loadOrganizationData();
    }
  }

  async onAddGroup() {
    const modalData: NewOrganizationGroupComponentInput = { org: this.organization };
    this.midRightPanelService.open(NewOrganizationGroupComponent, modalData).subscribe(async (result) => {
      if (!result?.data) return;
      await Promise.all([this.loadGroupUsers(result.data), this.loadGroups()]);
    });
  }

  async deleteGroup(group: OrganizationGroupDto) {
    await this.organizationGroupService.organizationGroupControllerDelete(group.id).toPromise();
    this.groups = this.groups.filter((g) => g.id !== group.id);
  }

  async onFilterUsers(group: OrganizationGroupDto) {
    await this.router.navigate(['/organization', this.organization.id, 'users'], {
      queryParams: { group: group?.id },
    });
  }

  private async loadOrganizationData() {
    await this.loadGroups();
    await this.getOrganizationPermissions();
    this.groups.map((x) => this.loadGroupUsers(x));
  }

  private async getOrganizationPermissions() {
    const orgPermissions = await this.organizationGroupService
      .orgOrganizationGroupControllerGetOrgGroupsPermission(this.organization.id)
      .toPromise();
    this.groupCreatePermission = orgPermissions.find((p) => {
      return p.permission === 'Create' && p.modelName === 'OrganizationGroup';
    });
  }

  private async loadGroups() {
    this.groups = await this.organizationGroupService
      .orgOrganizationGroupControllerGetOrgGroups(this.organization.id)
      .toPromise();
    this.groups.map(async (g) => {
      const permissions = await this.organizationGroupService
        .organizationGroupControllerGetGroupPermissions(g.id)
        .toPromise();
      this.groupPermissionMap.set(g, permissions);
    });
    this.groups.sort((one, two) => (one.name > two.name ? 1 : -1));
  }

  private async loadGroupUsers(group: OrganizationGroupDto) {
    const users = await Promise.all(
      group.users.map(async (userId) => {
        return await this.usersService.getUser(userId);
      })
    );
    users.sort((one, two) => (one.fullname > two.fullname ? 1 : -1));
    this.groupUserMap.set(group, users);
  }
}
