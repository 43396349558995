import {
  Injectable,
  Injector,
  ComponentFactoryResolver,
  EmbeddedViewRef,
  ApplicationRef,
  ComponentRef,
} from '@angular/core';

export const MID_RIGHT_PANEL_DATA = 'data';

@Injectable({
  providedIn: 'root',
})
export class DomService {
  constructor(
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly appRef: ApplicationRef,
    private readonly injector: Injector
  ) {}

  createComponentRef(component: any, data: any): ComponentRef<any> {
    const customInjector: Injector = Injector.create({
      providers: [
        {
          provide: MID_RIGHT_PANEL_DATA,
          useValue: data,
        },
      ],
      parent: this.injector,
    });

    const componentRef = this.componentFactoryResolver.resolveComponentFactory(component).create(customInjector);
    this.appRef.attachView(componentRef.hostView);
    return componentRef;
  }

  getDomElementFromComponentRef(componentRef: ComponentRef<any>): HTMLElement {
    return (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
  }

  addChild(child: HTMLElement, parent: HTMLElement = document.body) {
    parent.appendChild(child);
  }

  destroyRef(componentRef: ComponentRef<any>) {
    this.appRef.detachView(componentRef.hostView);
    componentRef.destroy();
  }

  setDynamicStyles(styles: object, componentRef: ComponentRef<any>) {
    Object.keys(styles).forEach((cssRule) => {
      const cssValue = styles[cssRule];
      componentRef.instance.renderer.setElementStyle(componentRef.instance.elementRef.nativeElement, cssRule, cssValue);
    });
  }
}
