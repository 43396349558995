import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AccessCodeService } from 'src/api';
import { appConfig } from 'src/app/app.config';
import { UserAuth, User } from 'src/app/autogenerated/model';
import { ErrorResponse, StatusCode } from 'src/app/core/api';
import { LoggerService } from 'src/app/core/logger.service';
import { MidInputSize } from 'src/app/midgard-controls/enums/input-size.enum';
import { Assert, required, validate } from 'src/app/shared';
import { TestHelperService } from 'src/app/shared/utils/test-helper.service';
import { AccessTokenAuthenticationService } from '../access-code-authentication.service';
import { AuthenticationApiService } from '../authentication-api.service';
import { authenticationConfig } from '../authentication.config';
import { AuthenticationError } from '../shared/authentication-error';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent {
  @Output()
  readonly loggedIn: EventEmitter<UserAuth>;

  credentials: {
    username: string;
    password: string;
    code: string;
  };

  state: {
    isLoading: boolean;
    authError?: AuthenticationError;
    passwordVisibility: boolean;
    codeVisibility: boolean;
  };

  get MidInputSize() {
    return MidInputSize;
  }

  get AuthenticationError() {
    return AuthenticationError;
  }

  get recoverPasswordRoute(): string[] {
    return ['/', authenticationConfig.routes.resetPassword];
  }

  constructor(
    private readonly authenticationApiService: AuthenticationApiService,
    private readonly loggerService: LoggerService,
    private readonly testHelperService: TestHelperService,
    private readonly accessTokenAuthenticationService: AccessTokenAuthenticationService,
    private readonly accessCodeService: AccessCodeService,
    private readonly router: Router
  ) {
    this.testHelperService.addComponent(this);
    this.loggedIn = new EventEmitter();

    this.credentials = {
      username: '',
      password: '',
      code: '',
    };

    this.state = {
      isLoading: false,
      authError: null,
      passwordVisibility: false,
      codeVisibility: false,
    };
  }

  async onLogin() {
    Assert.isNotNull(this.credentials, 'credentials');

    this.state = {
      isLoading: true,
      authError: null,
      passwordVisibility: false,
      codeVisibility: false,
    };

    if (this.credentials.username && this.credentials.password) {
      await this.loginWithUsernameAndPassword();
    } else if (this.credentials.code) {
      await this.loginWithAccessCode();
    }
  }
  async loginWithAccessCode() {
    const accessCodeToken = await this.accessCodeService
      .accessCodeControllerLogin({ code: this.credentials.code })
      .toPromise();
    this.accessTokenAuthenticationService.setAccessCode(accessCodeToken);
    this.onLoginSuccess(accessCodeToken as UserAuth);
    this.router.navigate([appConfig.routes.home]);
  }

  private async loginWithUsernameAndPassword() {
    let authDetails: UserAuth;
    try {
      authDetails = await this.authenticationApiService.login(this.credentials.username, this.credentials.password);
      this.onLoginSuccess(authDetails);
    } catch (error) {
      this.onLoginFailed(error);
    }
  }

  onEmailChange(email: string) {
    this.credentials.username = email;
  }

  onPasswordChange(password: string) {
    this.credentials.password = password;
  }

  onPasswordVisibilityChange(): void {
    this.state.passwordVisibility = !this.state.passwordVisibility;
  }

  onCodeVisibilityChange(): void {
    this.state.codeVisibility = !this.state.codeVisibility;
  }

  @validate
  hasAuthenticationErrorType(@required error: AuthenticationError): boolean {
    const hasError = this.state.authError === error;

    return hasError;
  }

  @validate
  private onLoginSuccess(@required authDetails: UserAuth) {
    this.loggedIn.emit(authDetails);
  }

  private onLoginFailed(error?: ErrorResponse) {
    const authError =
      error && (error.status === StatusCode.Forbidden || error.status === StatusCode.BadRequest)
        ? AuthenticationError.invalidCredentials
        : AuthenticationError.unexpectedError;

    this.state.isLoading = false;
    this.state.authError = authError;
  }
  onCodeChange(code: string) {
    this.credentials.code = code;
    if (code) {
      this.credentials.username = '';
      this.credentials.password = '';
    }
  }
}
