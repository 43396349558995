<app-mid-right-panel
  #panelMediaFilter="appMidRightPanel"
  [title]="'DIALOGS.MEDIA_FILTERS.TITLE' | translate"
  [closeCallback]="onClose()"
>
  <app-mid-right-panel-content>
    <div class="row m-0 px-4">
      <app-mid-input-date-time
        size="wide"
        #startDate="appMidInputDateTime"
        name="startDate"
        [(value)]="startDateSelected"
        [required]="false"
        [label]="'DIALOGS.MEDIA_FILTERS.START_DATE' | translate"
      >
      </app-mid-input-date-time>
    </div>
    <div class="row m-0 px-4">
      <app-mid-input-date-time
        size="wide"
        #endDate="appMidInputDateTime"
        name="endDate"
        [(value)]="endDateSelected"
        [required]="false"
        [label]="'DIALOGS.MEDIA_FILTERS.END_DATE' | translate"
      >
      </app-mid-input-date-time>
    </div>
    <div class="answer-buttons">
      <app-mid-button type="primary" (click)="onFilter()">
        {{ 'DIALOGS.MEDIA_FILTERS.SUBMIT' | translate }}
      </app-mid-button>
      <app-mid-button type="secondary" (click)="onReset()">
        {{ 'DIALOGS.MEDIA_FILTERS.RESET' | translate }}
      </app-mid-button>
    </div>
  </app-mid-right-panel-content>
</app-mid-right-panel>
