import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { CoreModule } from 'src/app/core/core.module';
import { ConfirmModalComponent } from './modals/confirm-modal/confirm-modal.component';
import { InformModalComponent } from './modals/inform-modal/inform-modal.component';
import { MidgardControlsModule } from '../midgard-controls/midgard-controls.module';
import { CommonModule } from '@angular/common';
import { TestHelperService } from './utils/test-helper.service';
import { SimpleHeaderComponent } from './simple-header/simple-header.component';

@NgModule({
  imports: [MatDialogModule, MatButtonModule, CoreModule, MidgardControlsModule, CommonModule],
  declarations: [ConfirmModalComponent, InformModalComponent, SimpleHeaderComponent],
  providers: [TestHelperService],
  exports: [ConfirmModalComponent, InformModalComponent, SimpleHeaderComponent],
})
export class SharedModule {}
