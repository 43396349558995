<div *ngIf="onDisplay()" class="view-panel-buttons">
  <div (click)="onSetBlockView()" class="blockView">
    <div class="grey-block"></div>
    <div class="grey-block"></div>
    <div class="grey-block"></div>
    <div class="grey-block"></div>
  </div>

  <div (click)="onSetGridView()" class="gridView">
    <div class="wide">
      <div class="grey-block"></div>
    </div>
    <div class="column">
      <div class="grey-block"></div>
      <div class="grey-block"></div>
    </div>
  </div>
</div>
