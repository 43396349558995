<app-modal-header text="{{ 'TDRAW.MODAL.RENAME_MAP.TITLE' | translate }}" (closeAction)="onClose()"></app-modal-header>
<div class="body">
  <form name="formRename" #formRename="ngForm" novalidate>
    <app-mid-input
      size="wide"
      [(value)]="name"
      #versionName="appMidInput"
      [required]="true"
      [label]="'TDRAW.MODAL.RENAME_MAP.NAME_LABEL' | translate"
      [placeholder]="'TDRAW.MODAL.RENAME_MAP.NAME_PLACEHOLDER' | translate"
    >
      <span *ngIf="formRename.invalid && versionName.invalid" class="text-danger font-weight-bold">{{
        'COMMON_REQUIRED_FIELD' | translate
      }}</span>
    </app-mid-input>

    <div class="buttons">
      <app-mid-button type="secondary" (action)="onClose()">{{ 'COMMON_CANCEL' | translate }}</app-mid-button>
      <app-mid-button type="primary" (action)="onSubmit()" [disabled]="formRename.invalid || formRename.pristine">
        <i class="fas fa-check"></i> {{ 'RENAME' | translate }}
      </app-mid-button>
    </div>
  </form>
</div>
