import { createFeatureSelector, MemoizedSelector, createSelector } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { interventionConfig } from '../intervention.config';
import { InterventionState } from './intervention.reducer';

export const selectInterventionState: MemoizedSelector<AppState, InterventionState> = createFeatureSelector(
  interventionConfig.store.key
);

export const selectCurrentInterventionByOrganizationId = createSelector(
  selectInterventionState,
  (state: InterventionState, props: { id: string }) => state.selectedByOrganizationId[props.id]
);

export const selectOrganizationInterventionsByOrganizationId = createSelector(
  selectInterventionState,
  (state: InterventionState, props: { id: string }) => state.groupByOrganizationId[props.id]
);

export const selectInterventionById = createSelector(
  selectInterventionState,
  (state: InterventionState, props: { id: string }) => state.allByInterventionId[props.id]
);
