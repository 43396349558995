/**
 * MidGard API
 * MidGard API summary
 *
 * OpenAPI spec version: 2.0
 * Contact: contact@midgard-ai.co
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AccessCodeDtoWithInterventions } from '../model/accessCodeDtoWithInterventions';
import { ApiHttpException } from '../model/apiHttpException';
import { ContextPermissionsDto } from '../model/contextPermissionsDto';
import { CreateOrganizationDto } from '../model/createOrganizationDto';
import { FeatureModule } from '../model/featureModule';
import { OrgLocation } from '../model/orgLocation';
import { OrganizationDto } from '../model/organizationDto';
import { OrganizationRenameDto } from '../model/organizationRenameDto';
import { SimpleIdDto } from '../model/simpleIdDto';
import { UpdateOrganizationDto } from '../model/updateOrganizationDto';
import { UserDto } from '../model/userDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class OrganizationsService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Get the organization access code
     * 
     * @param orgId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public orgAccessCodeControllerGetOrgAccessCode(orgId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<AccessCodeDtoWithInterventions>>;
    public orgAccessCodeControllerGetOrgAccessCode(orgId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AccessCodeDtoWithInterventions>>>;
    public orgAccessCodeControllerGetOrgAccessCode(orgId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AccessCodeDtoWithInterventions>>>;
    public orgAccessCodeControllerGetOrgAccessCode(orgId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling orgAccessCodeControllerGetOrgAccessCode.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<AccessCodeDtoWithInterventions>>('get',`${this.basePath}/api/v2/organizations/${encodeURIComponent(String(orgId))}/access-codes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * activate feature
     * 
     * @param body feature id
     * @param orgId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organizationControllerActivateFeature(body: SimpleIdDto, orgId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<FeatureModule>>;
    public organizationControllerActivateFeature(body: SimpleIdDto, orgId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FeatureModule>>>;
    public organizationControllerActivateFeature(body: SimpleIdDto, orgId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FeatureModule>>>;
    public organizationControllerActivateFeature(body: SimpleIdDto, orgId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling organizationControllerActivateFeature.');
        }

        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling organizationControllerActivateFeature.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<FeatureModule>>('put',`${this.basePath}/api/v2/organizations/${encodeURIComponent(String(orgId))}/features`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change Organization address
     * 
     * @param body new location
     * @param orgId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organizationControllerChangeLocation(body: OrgLocation, orgId: string, observe?: 'body', reportProgress?: boolean): Observable<OrganizationDto>;
    public organizationControllerChangeLocation(body: OrgLocation, orgId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrganizationDto>>;
    public organizationControllerChangeLocation(body: OrgLocation, orgId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrganizationDto>>;
    public organizationControllerChangeLocation(body: OrgLocation, orgId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling organizationControllerChangeLocation.');
        }

        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling organizationControllerChangeLocation.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<OrganizationDto>('put',`${this.basePath}/api/v2/organizations/${encodeURIComponent(String(orgId))}/location`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * create Organization
     * 
     * @param body Organization fields
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organizationControllerCreate(body: CreateOrganizationDto, observe?: 'body', reportProgress?: boolean): Observable<OrganizationDto>;
    public organizationControllerCreate(body: CreateOrganizationDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrganizationDto>>;
    public organizationControllerCreate(body: CreateOrganizationDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrganizationDto>>;
    public organizationControllerCreate(body: CreateOrganizationDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling organizationControllerCreate.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<OrganizationDto>('post',`${this.basePath}/api/v2/organizations`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deactivate feature
     * 
     * @param body feature id
     * @param orgId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organizationControllerDeactivateFeature(body: SimpleIdDto, orgId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<FeatureModule>>;
    public organizationControllerDeactivateFeature(body: SimpleIdDto, orgId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FeatureModule>>>;
    public organizationControllerDeactivateFeature(body: SimpleIdDto, orgId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FeatureModule>>>;
    public organizationControllerDeactivateFeature(body: SimpleIdDto, orgId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling organizationControllerDeactivateFeature.');
        }

        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling organizationControllerDeactivateFeature.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<FeatureModule>>('delete',`${this.basePath}/api/v2/organizations/${encodeURIComponent(String(orgId))}/features`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the Organizations available features
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organizationControllerGetAllFeatures(observe?: 'body', reportProgress?: boolean): Observable<Array<FeatureModule>>;
    public organizationControllerGetAllFeatures(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FeatureModule>>>;
    public organizationControllerGetAllFeatures(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FeatureModule>>>;
    public organizationControllerGetAllFeatures(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<FeatureModule>>('get',`${this.basePath}/api/v2/organizations/features`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the Organization
     * 
     * @param orgId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organizationControllerGetOrganization(orgId: string, observe?: 'body', reportProgress?: boolean): Observable<OrganizationDto>;
    public organizationControllerGetOrganization(orgId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrganizationDto>>;
    public organizationControllerGetOrganization(orgId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrganizationDto>>;
    public organizationControllerGetOrganization(orgId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling organizationControllerGetOrganization.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<OrganizationDto>('get',`${this.basePath}/api/v2/organizations/${encodeURIComponent(String(orgId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the Organization active features
     * 
     * @param orgId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organizationControllerGetOrganizationFeatures(orgId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<FeatureModule>>;
    public organizationControllerGetOrganizationFeatures(orgId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FeatureModule>>>;
    public organizationControllerGetOrganizationFeatures(orgId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FeatureModule>>>;
    public organizationControllerGetOrganizationFeatures(orgId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling organizationControllerGetOrganizationFeatures.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<FeatureModule>>('get',`${this.basePath}/api/v2/organizations/${encodeURIComponent(String(orgId))}/features`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the Organization permission
     * 
     * @param orgId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organizationControllerGetOrganizationPermissions(orgId: string, observe?: 'body', reportProgress?: boolean): Observable<ContextPermissionsDto>;
    public organizationControllerGetOrganizationPermissions(orgId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ContextPermissionsDto>>;
    public organizationControllerGetOrganizationPermissions(orgId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ContextPermissionsDto>>;
    public organizationControllerGetOrganizationPermissions(orgId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling organizationControllerGetOrganizationPermissions.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ContextPermissionsDto>('get',`${this.basePath}/api/v2/organizations/${encodeURIComponent(String(orgId))}/my-permissions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the Organization users
     * 
     * @param orgId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organizationControllerGetOrganizationUsers(orgId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<UserDto>>;
    public organizationControllerGetOrganizationUsers(orgId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UserDto>>>;
    public organizationControllerGetOrganizationUsers(orgId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UserDto>>>;
    public organizationControllerGetOrganizationUsers(orgId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling organizationControllerGetOrganizationUsers.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<UserDto>>('get',`${this.basePath}/api/v2/organizations/${encodeURIComponent(String(orgId))}/users`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get authenticated user&#x27;s Organizations
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organizationControllerGetOrganizations(observe?: 'body', reportProgress?: boolean): Observable<Array<OrganizationDto>>;
    public organizationControllerGetOrganizations(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<OrganizationDto>>>;
    public organizationControllerGetOrganizations(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<OrganizationDto>>>;
    public organizationControllerGetOrganizations(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<OrganizationDto>>('get',`${this.basePath}/api/v2/organizations`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * remove user from organization
     * 
     * @param body user id
     * @param orgId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organizationControllerRemoveOrganizationUser(body: SimpleIdDto, orgId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<UserDto>>;
    public organizationControllerRemoveOrganizationUser(body: SimpleIdDto, orgId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UserDto>>>;
    public organizationControllerRemoveOrganizationUser(body: SimpleIdDto, orgId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UserDto>>>;
    public organizationControllerRemoveOrganizationUser(body: SimpleIdDto, orgId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling organizationControllerRemoveOrganizationUser.');
        }

        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling organizationControllerRemoveOrganizationUser.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<UserDto>>('delete',`${this.basePath}/api/v2/organizations/${encodeURIComponent(String(orgId))}/users`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Rename Organization
     * 
     * @param body new name
     * @param orgId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organizationControllerRename(body: OrganizationRenameDto, orgId: string, observe?: 'body', reportProgress?: boolean): Observable<OrganizationDto>;
    public organizationControllerRename(body: OrganizationRenameDto, orgId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrganizationDto>>;
    public organizationControllerRename(body: OrganizationRenameDto, orgId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrganizationDto>>;
    public organizationControllerRename(body: OrganizationRenameDto, orgId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling organizationControllerRename.');
        }

        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling organizationControllerRename.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<OrganizationDto>('put',`${this.basePath}/api/v2/organizations/${encodeURIComponent(String(orgId))}/name`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Organization
     * 
     * @param body Organization fields
     * @param orgId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organizationControllerUpdate(body: UpdateOrganizationDto, orgId: string, observe?: 'body', reportProgress?: boolean): Observable<OrganizationDto>;
    public organizationControllerUpdate(body: UpdateOrganizationDto, orgId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrganizationDto>>;
    public organizationControllerUpdate(body: UpdateOrganizationDto, orgId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrganizationDto>>;
    public organizationControllerUpdate(body: UpdateOrganizationDto, orgId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling organizationControllerUpdate.');
        }

        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling organizationControllerUpdate.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<OrganizationDto>('put',`${this.basePath}/api/v2/organizations/${encodeURIComponent(String(orgId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Upload organization logo
     * 
     * @param orgId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organizationControllerUploadLogo(orgId: string, observe?: 'body', reportProgress?: boolean): Observable<OrganizationDto>;
    public organizationControllerUploadLogo(orgId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrganizationDto>>;
    public organizationControllerUploadLogo(orgId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrganizationDto>>;
    public organizationControllerUploadLogo(orgId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter orgId was null or undefined when calling organizationControllerUploadLogo.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<OrganizationDto>('post',`${this.basePath}/api/v2/organizations/${encodeURIComponent(String(orgId))}/logo`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
