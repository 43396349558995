import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, map } from 'rxjs/operators';
import { Breadcrumb } from '../models/breadcrumb/breadcrumb.model';
import { layoutConfig } from '../layout.config';
import { forEach, forOwn } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbStateService {
  private breadcrumbs: Breadcrumb[] = [];
  private data = {};

  get Breadcrumbs() {
    return this.breadcrumbs;
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private readonly translate: TranslateService
  ) {
    this.setup();
  }

  setBreadcrumbDataState(data: StringMap<string>) {
    this.data = data;
    this.prepareBreadcrumbs(data);
  }

  private prepareBreadcrumbs(data: StringMap<string>) {
    forEach(this.breadcrumbs, async (breadcrumb) => {
      if (!breadcrumb.formatterLabel) {
        breadcrumb.label = await this.translate.get(breadcrumb.label).toPromise();
        return;
      }
      forOwn(data, async (value, key) => {
        const translatedLabel = await this.translate.get(breadcrumb.formatterLabel).toPromise();
        breadcrumb.label = translatedLabel.replace(`{${key}}`, value);
      });
    });
  }

  private setup() {
    this.initDefaultBreadcrumbs();
    this.subscribeRouterEvents();
  }

  private initDefaultBreadcrumbs() {
    this.breadcrumbs = [layoutConfig.initialBreadcrumb];
  }

  private subscribeRouterEvents() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          const data = this.getRouteBreadcrumbData(this.activatedRoute.firstChild);
          return data?.breadcrumb;
        })
      )
      .subscribe(async (breadcrumbData: Breadcrumb) => {
        let breadCrumb: Breadcrumb;
        if (breadcrumbData?.label !== undefined) {
          breadCrumb = new Breadcrumb({
            label: breadcrumbData.label,
            icon: 'fa-chevron-right',
            additionalIcon: breadcrumbData.additionalIcon,
            formatterLabel: breadcrumbData.formatterLabel,
          });
          this.setAdditionalBreadcrumb(breadCrumb);
        } else {
          this.initDefaultBreadcrumbs();
        }
      });
  }

  private setAdditionalBreadcrumb(breadcrumb: Breadcrumb) {
    this.breadcrumbs[1] = breadcrumb;
    this.prepareBreadcrumbs(this.data);
  }

  private getRouteBreadcrumbData(child): any {
    while (child) {
      if (child.firstChild) {
        child = child.firstChild;
        continue;
      }
      if (child.snapshot?.data) {
        return child.snapshot?.data;
      }
      return null;
    }
    return null;
  }
}
