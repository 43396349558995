<tdraw-right-panel-container>
  <div role="content">
    <div class="history-header">
      <div class="history-header-buttons">
        <app-mid-button type="primary" (action)="onNewGisVersionClick()" [disabled]="isArchived">
          <i class="fas fa-plus mr-2"></i> {{ 'TDRAW.NEW_GIS_VERSION' | translate }}
        </app-mid-button>
        <app-mid-button type="primary" (action)="onNewPicVersionClick()" [disabled]="isArchived">
          <i class="fas fa-plus mr-2"></i> {{ 'TDRAW.NEW_PIC_VERSION' | translate }}
        </app-mid-button>
      </div>
      <div class="history-label">{{ 'TDRAW.HISTORY' | translate }}</div>
    </div>

    <div
      *ngIf="!mapStateGroups?.length"
      [innerHTML]="'TDRAW.NO_HISTORY' | translate"
      class="alert-warning p-3 text-center"
    ></div>

    <div *ngFor="let group of mapStateGroups">
      <span class="text-in-lines"> {{ group.date | date : 'fullDate' : undefined : translate.getDefaultLang() }} </span>
      <tdraw-version-list
        [interventionId]="interventionId"
        [versionItems]="group.mapStates"
        [selectedMapState]="selectedMapState"
        (mapStateSelected)="onMapStateSelected($event)"
        (publish)="onMapStatePublish($event)"
        (rename)="onMapStateRename($event)"
        (duplicate)="onMapStateDuplicate($event)"
        (download)="onMapStateDownload($event)"
        (deletingMapState)="onMapStateDelete($event)"
      ></tdraw-version-list>
    </div>
  </div>
  <div role="footer">
    <div class="infopoint" *ngIf="infopoint">
      <span>{{ infopoint.lng }} / {{ infopoint.lat }}</span>
      <span class="ml-3" *ngIf="infopoint.elevation">{{ infopoint.elevation }} m</span>
      <span class="dfci">{{ infopoint.dfci }}</span>
      <div>{{ infopoint.utmCoords[0] }} / {{ infopoint.utmCoords[1] }} (EPSG:{{ infopoint.utmEpsg }})</div>
    </div>
  </div>
</tdraw-right-panel-container>
