<app-mid-right-panel [title]="'MAIN_MEDIA_MENU.TITLE' | translate">
  <app-mid-right-panel-content>
    <div class="d-flex justify-content-center pt-3 pb-3 mt-2">
      <app-mid-button (action)="openDialog()" type="primary" icon="fas fa-plus" class="ml-1">
        {{ 'IMPORT_ONE_MEDIA' | translate }}
      </app-mid-button>
    </div>
    <div class="items p-3">
      <app-media-notification-progress></app-media-notification-progress>
      <app-organization-activity-stream></app-organization-activity-stream>
    </div>
  </app-mid-right-panel-content>
</app-mid-right-panel>
