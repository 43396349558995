import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { required, validate } from '../shared';
import { authenticationConfig } from './authentication.config';
import { AuthenticationService } from './authentication.service';
import { ApiService, StatusCode } from '../core/api';
import { AuthenticationStoreService } from './store';
import { appConfig } from '../app.config';

@Injectable()
export class AuthenticationHttpInterceptor implements HttpInterceptor {
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly apiService: ApiService,
    private readonly router: Router,
    private authenticationStoreService: AuthenticationStoreService
  ) {}

  @validate
  intercept(request: HttpRequest<any>, @required next: HttpHandler): Observable<HttpEvent<any>> {
    for (let url of appConfig.interceptorsExcludeUrls) {
      if (request.url.startsWith(url)) {
        return next.handle(request);
      }
    }

    return this.authenticationStoreService.getAccessToken().pipe(
      take(1),
      switchMap((accessToken) => {
        const isRenewTokenRequest = request.url.indexOf('token/renew') > 0;

        if (!accessToken || isRenewTokenRequest) {
          return next.handle(request);
        }

        const newReq = request.clone({
          setHeaders: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        return next.handle(newReq);
      }),
      tap({
        error: (error: any) => this.onError(error),
      })
    );
  }

  private async onError(error: any) {
    if (error && error instanceof HttpErrorResponse) {
      if (error.status === StatusCode.Unauthorized) {
        const errorResponse = this.apiService.mapErrorResponse(error.error, error.status);

        await this.authenticationService.setReturnUrl(this.router.url, false);

        this.authenticationService.expireAuthentication();
        this.router.navigate(['']);
      }
    }
  }
}
