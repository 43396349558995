<div class="assign-media-header">
  <div class="assign-media-title">
    <span class="title"> {{ 'ASSIGN_TO_ANOTHER_INTERVENTION' | translate }} </span>
  </div>
  <div class="close-icon" mat-dialog-close>
    <i class="fas fa-times icon"></i>
  </div>
</div>

<div class="assign-media-body">
  <div class="steps">
    <div class="step">
      <div class="step-main">
        <div class="step-info">
          <span class="info">{{ 'INTERVENTION_SELECT_F0R_LINK_MEDIA' | translate }}</span>
        </div>
      </div>
    </div>
  </div>
  <label>{{ 'INTERVENTION_NAME_ASSIGN' | translate }}</label>
  <app-mid-select-intervention-combobox
    (interventionSelected)="getSelectedIntervention($event)"
    [organizationId]="organizationId"
  ></app-mid-select-intervention-combobox>
</div>

<div class="d-flex justify-content-center pt-3 pb-3 mt-2">
  <app-mid-button type="secondary" (action)="onClose()">{{ 'COMMON_CANCEL' | translate }}</app-mid-button>
  <app-mid-button
    type="primary"
    icon="fas fa-check"
    (action)="onSubmit()"
    class="ml-1"
    [disabled]="!selectedIntervention"
    >{{ 'ASSOCIATE' | translate }}</app-mid-button
  >
</div>
