import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appCountryFlags]',
})
export class CountryFlagDirective implements AfterViewInit {
  constructor(private el: ElementRef, private renderer: Renderer2) {
    renderer.addClass(el.nativeElement, 'country-flags');
  }

  ngAfterViewInit() {
    const select = this.el.nativeElement as any;
    for (let opt of select.options) {
      const flag = this.toFlag(opt.value);
      this.renderer.setAttribute(opt, 'data-before', flag);
      opt.innerHTML = `${flag} ${opt.innerHTML}`;
    }
  }

  private toFlag(code: string) {
    const base = 127462 - 65;
    const cc = code.toUpperCase();
    return String.fromCodePoint(...cc.split('').map((c) => base + c.charCodeAt(0)));
  }
}
