<div
  class="w-100 mid-notification-item-panel mid-notification-item-progress"
  *ngFor="let file of mediaUploadService.filesChange$ | async"
>
  <div class="row">
    <div class="col-3 my-auto" [ngSwitch]="file.type.split('/')[0]">
      <ng-container>
        <img *ngSwitchCase="MediaType.video" src="/assets/images/mid-activity-stream-icon-video.png" alt="" />
        <img *ngSwitchDefault src="/assets/images/mid-activity-stream-icon-image.png" alt="" />
      </ng-container>
    </div>

    <div class="col-9 pl-0 pr-1">
      <div class="row">
        <div class="col-12 mid-activity-stream-new-media-title">
          {{ file?.name }}
        </div>

        <div class="col-12 mid-activity-stream-new-media-date">
          <div class="mid-activity-stream-new-media-date">
            {{ mediaUploadService.uploadStartDate | date : 'dd/MM/yyyy HH:mm' }}
          </div>
          <div class="mid-notification-item-progress">
            <app-progress [progress]="file?.progress"></app-progress>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
