import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Drone, Intervention } from 'src/app/autogenerated/model';
import { DroneStoreService } from '../../store';
import { Subscription } from 'rxjs';
import { OrganizationStoreService } from 'src/app/organization/store';
import { Unsubscribe } from '../../../core/decorators';
import { OrganizationGroupService } from 'src/api';
import { NavigationService } from '../../../midgard-controls/navigation.service';
import { InterventionStoreService } from '../../../intervention/store';

@Unsubscribe()
@Component({
  selector: 'app-drone-single-stream',
  templateUrl: './drone-single-stream.component.html',
  styleUrls: ['./drone-single-stream.component.scss'],
})
export class DroneSingleStreamComponent implements OnInit, OnDestroy {
  droneId: string;
  drone: Drone;
  isAdmin: boolean;
  organizationId: string;
  intervention: Intervention;

  private organizationSubscription: Subscription;
  private droneSubscription: Subscription;
  private interventionSubscription: Subscription;

  constructor(
    route: ActivatedRoute,
    private droneStoreService: DroneStoreService,
    private organizationStoreService: OrganizationStoreService,
    private organizationGroupService: OrganizationGroupService,
    private navigationService: NavigationService,
    private interventionStoreService: InterventionStoreService
  ) {
    this.isAdmin = false;
    route.params.subscribe(async (params) => {
      this.droneId = params.id;
    });
  }

  async ngOnInit(): Promise<void> {
    this.subscribeOnOrganization();
    this.droneSubscription = this.droneStoreService.getDroneById(this.droneId).subscribe((drone) => {
      this.drone = drone;
    });
    if (this.drone.currentIntervention) {
      this.interventionSubscription = this.interventionStoreService
        .getInterventionById(this.drone.currentIntervention as string)
        .subscribe((intervention) => {
          this.intervention = intervention;
        });
    }
  }

  ngOnDestroy(): void {}

  private subscribeOnOrganization() {
    this.organizationSubscription = this.organizationStoreService
      .getCurrentOrganization()
      .subscribe(async (organization) => {
        if (!organization || this.organizationId === organization._id) {
          return;
        }
        this.organizationId = organization._id;
        this.isAdmin = await this.checkForAdminRole(organization._id);
      });
  }

  private async checkForAdminRole(organizationId: string): Promise<boolean> {
    try {
      const isAdmin = await this.organizationGroupService
        .orgOrganizationGroupControllerIsOrgAdmin(organizationId)
        .toPromise();
      return isAdmin;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async close() {
    await this.navigationService.goBack();
  }
}
