import { Component, OnInit } from '@angular/core';
import { authenticationConfig } from '../authentication.config';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthenticationStoreService } from '../store';
import { UserProfilesStoreService } from 'src/app/user-profile/store';
import { LayoutStoreService } from 'src/app/layout/store';
import { UserAuth, UiLanguage, User } from 'src/app/autogenerated/model';
import { required, validate } from 'src/app/shared';
import { appConfig } from 'src/app/app.config';
import { MidButtonSize } from 'src/app/midgard-controls/enums/button-size.enum';
import { MidInputSize } from 'src/app/midgard-controls/enums/input-size.enum';

@Component({
  selector: 'app-recover-password-page',
  templateUrl: './recover-password-page.component.html',
  styleUrls: ['./recover-password-page.component.scss'],
})
export class RecoverPasswordPageComponent implements OnInit {
  data: {
    email: string;
    code: string;
    password: string;
  };

  state: {
    isLoading: boolean;
    passwordVisibility: boolean;
    confirmPasswordVisibility: boolean;
  };

  private queryParameters = {
    email: 'email',
    code: 'code',
  };

  get MidButtonSize() {
    return MidButtonSize;
  }

  get MidInputSize() {
    return MidInputSize;
  }

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly location: Location,
    private readonly authenticationStoreService: AuthenticationStoreService,
    private readonly userProfilesStoreService: UserProfilesStoreService,
    private readonly layoutStoreService: LayoutStoreService
  ) {
    this.data = {
      email: null,
      code: null,
      password: null,
    };

    this.state = {
      isLoading: false,
      passwordVisibility: false,
      confirmPasswordVisibility: false,
    };
  }

  ngOnInit() {
    this.data.email = this.route.snapshot.queryParams[this.queryParameters.email] || null;
    this.data.code = this.route.snapshot.queryParams[this.queryParameters.code] || null;
    this.location.replaceState(authenticationConfig.routes.resetPassword);
  }

  @validate
  onPasswordResetCompleted(@required authDetails: UserAuth) {
    this.authenticationStoreService.authenticate(
      authDetails._id,
      authDetails.token,
      authDetails.defaultOrganization.toString()
    );
    this.userProfilesStoreService.setCurrentUser(authDetails);
    this.layoutStoreService.setDefaultLanguage(authDetails.defaultLanguage || UiLanguage.fr);
  }

  onCanceled() {
    this.navigateToLoginPage();
  }

  onNavigateToHome() {
    this.router.navigate([appConfig.routes.home]);
  }

  private navigateToLoginPage() {
    this.router.navigate(['']);
  }

  changePasswordVisibility() {
    this.state.passwordVisibility = !this.state.passwordVisibility;
  }

  changeConfirmPasswordVisibility() {
    this.state.confirmPasswordVisibility = !this.state.confirmPasswordVisibility;
  }
}
