<div class="organization-container" *ngIf="isReady()">
  <!-- title -->
  <div class="title text-center">
    <h3>{{ 'ADMINISTRATION.ORGANIZATIONS.TITLE' | translate }}</h3>
  </div>

  <!--  organizations management -->
  <section class="section-form-group col-md-12">
    <h2 class="section-header">{{ 'ADMINISTRATION.ORGANIZATIONS.MANAGE_ORGANIZATION' | translate }}</h2>
    <div class="row">
      <app-mid-input
        class="col my-2"
        size="wide"
        [(value)]="filterArg"
        #filterOrgName="appMidInput"
        name="filterOrgName"
        [required]="false"
        [placeholder]="'ADMINISTRATION.ORGANIZATIONS.FILTER_BY_NAME_PLACEHOLDER' | translate"
        (valueChange)="filterOrg()"
      >
      </app-mid-input>
    </div>
    <div class="organizations-list">
      <mid-simple-dashboard-block
        *ngFor="let org of filteredOrganizations"
        class="organization-card"
        [title]="org.name"
      >
        <div class="action">
          <app-mid-icon-button class="link" type="secondary" role="button" [routerLink]="['/organization', org.id]">
            <i class="fas fa-eye"></i>
          </app-mid-icon-button>
          <app-mid-icon-button class="conf" type="secondary" role="button" (action)="openDialog(org)">
            <i class="fas fa-cog"></i>
          </app-mid-icon-button>
        </div>
        <div class="organization-content">
          <img src="{{ org.logoUrl }}" />
        </div>
      </mid-simple-dashboard-block>
    </div>
  </section>

  <!-- new organization -->
  <section class="section-form-group col-md-12">
    <h2 class="section-header">{{ 'ADMINISTRATION.ORGANIZATIONS.CREATE_ORGANIZATION' | translate }}</h2>
    <form class="row" name="formCreateOrg" #formCreateOrg="ngForm" novalidate>
      <app-mid-input
        class="col-md-10"
        size="wide"
        [(value)]="newOrganization.name"
        #orgName="appMidInput"
        name="orgName"
        [required]="true"
        [label]="'ADMINISTRATION.ORGANIZATIONS.NEW_ORGANIZATION_NAME_LABEL' | translate"
        [placeholder]="'ADMINISTRATION.ORGANIZATIONS.NEW_ORGANIZATION_NAME_PLACEHOLDER' | translate"
      >
      </app-mid-input>
      <app-mid-button
        class="col-md-2 ml-auto mt-auto mb-1"
        type="primary"
        size="wide"
        [disabled]="formCreateOrg.invalid || formCreateOrg.pristine"
        (action)="createOrganization()"
        [ngClass]="{
          'mid-saved': saved,
          'mid-in-saving': inSaving,
          'mid-error': errorForm
        }"
      >
        <span *ngIf="!inSaving && !saved && !errorForm">{{ 'ADMINISTRATION.ORGANIZATIONS.CREATE' | translate }}</span>
        <span *ngIf="inSaving"><i class="fas fa-cog fa-spin"></i></span>
        <span *ngIf="errorForm"><i class="fas fa-exclamation-triangle"></i></span>
        <span *ngIf="saved"><i class="fas fa-check-circle mid-fa-success"></i></span>
      </app-mid-button>
    </form>
  </section>
</div>
