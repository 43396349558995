<div class="profile-container">
  <div class="row">
    <div class="col-md-6">
      <!-- user details from -->
      <form
        #userDetailsForm="ngForm"
        novalidate
        autocomplete="off"
        class="section-form-group d-flex flex-column m-0 pb-4"
      >
        <h6 class="section-header">
          {{ 'USER_PROFILE.USER_DETAILS.UPDATE_NAME_PROFILE' | translate }}
        </h6>

        <app-mid-input
          size="wide"
          [(value)]="data.email"
          #email="appMidInput"
          name="email"
          [required]="true"
          [label]="'EMAIL' | translate"
          [disabled]="true"
        >
          <span *ngIf="!userDetailsForm.pristine && email.invalid" class="text-danger font-weight-bold">{{
            'USER_PROFILE.USER_DETAILS.COMMON_REQUIRED_FIELD' | translate
          }}</span>
        </app-mid-input>

        <app-mid-input
          size="wide"
          [(value)]="data.fullname"
          #fullname="appMidInput"
          name="fullname"
          [required]="true"
          [label]="'USER_PROFILE.USER_DETAILS.PROFILE_PLACEHOLDER_NAME' | translate"
          [disabled]="state.isLoadingDetails"
        >
          <span *ngIf="!userDetailsForm.pristine && fullname.invalid" class="text-danger font-weight-bold">{{
            'USER_PROFILE.USER_DETAILS.UNEXPECTED_ERROR' | translate
          }}</span>
        </app-mid-input>

        <app-mid-input-phone-number
          size="wide"
          [label]="'USER_PROFILE.USER_DETAILS.PHONE_NUMBER' | translate"
          [required]="true"
          name="phoneNumber"
          #phoneNumber="appMidPhoneNumberInput"
          [(value)]="data.phoneNumber"
        >
          <span *ngIf="!userDetailsForm.pristine && phoneNumber.invalid" class="text-danger font-weight-bold">
            {{ 'USER_PROFILE.USER_DETAILS.PHONE_VALIDATOR_LABEL' | translate }}</span
          >
        </app-mid-input-phone-number>

        <!-- save user details -->
        <div class="mt-2 align-self-end">
          <app-mid-button
            class="pr-2"
            [disabled]="!userDetailsForm.valid || userDetailsForm.pristine"
            type="primary"
            (action)="saveUserName(userDetailsForm)"
            [ngClass]="{
              'mid-in-saving': state.isLoadingDetails,
              'mid-saved': state.isUpdatedDetails,
              'mid-error': state.isFailedDetails
            }"
          >
            <span *ngIf="state.isLoadingDetails"><i class="fa fa-sync-alt fa-spin"></i></span>
            <span *ngIf="state.isFailedDetails"><i class="fa fa-exclamation-triangle"></i></span>
            <span *ngIf="state.isUpdatedDetails"><i class="fa fa-check"></i></span>
            {{ 'USER_PROFILE.USER_DETAILS.SAVE_USERNAME_BUTTON' | translate }}
          </app-mid-button>
        </div>
      </form>

      <!-- user language from -->
      <section class="section-form-group d-flex flex-column m-0 pb-4">
        <h6 class="section-header col-md-12">
          {{ 'USER_PROFILE.USER_LANGUAGE.SETTINGS' | translate }}
        </h6>

        <app-mid-select-lang
          size="wide"
          [selectedItem]="user?.defaultLanguage"
          [label]="'USER_PROFILE.USER_LANGUAGE.LANGUAGE_LABEL' | translate"
          [(items)]="languages"
          (itemsChange)="changeLanguage()"
        >
        </app-mid-select-lang>
      </section>
    </div>
    <div class="col-md-6">
      <!-- user change password form -->
      <form
        #changePasswordForm="ngForm"
        novalidate
        autocomplete="off"
        class="section-form-group d-flex flex-column m-0 pb-4"
      >
        <h6 class="section-header">
          {{ 'USER_PROFILE.CHANGE_PASSWORD.UPDATE_PASSWORD' | translate }}
        </h6>

        <app-mid-input
          size="wide"
          type="password"
          [(value)]="data.currentPassword"
          (valueChange)="onCurrentPasswordChanged()"
          #name="appMidInput"
          name="currentPassword"
          [required]="true"
          [label]="'USER_PROFILE.CHANGE_PASSWORD.CURRENT_PLACEHOLDER_PASSWORD' | translate"
        >
          <span *ngIf="state.isFailedDetails" class="text-danger font-weight-bold">
            {{ 'USER_PROFILE.CHANGE_PASSWORD.ERROR_PASSWORD_TEXT' | translate }}
          </span>
        </app-mid-input>

        <div class="col-md-12 col-sm-12 p-0">
          <app-mid-input
            size="wide"
            [type]="state.passwordVisibility ? 'text' : 'password'"
            [fasType]="state.passwordVisibility ? 'fa-eye-slash' : 'fa-eye'"
            (fasChange)="onPasswordVisibilityChange()"
            (valueChange)="data.newPassword = $event"
            #name="appMidInput"
            name="newPassword"
            [required]="true"
            [label]="'USER_PROFILE.CHANGE_PASSWORD.NEW_PASSWORD_PLACEHOLDER' | translate"
            [disabled]="state.isLoadingDetails"
          >
          </app-mid-input>
          <div class="col-md-12 col-sm-12 mt-3 d-flex flex-column validation-labels">
            {{ 'USER_PROFILE.PASSWORD_VALIDATION_RULES.LABEL' | translate }}
            <span class="rule" [class.checked]="hasPasswordEightCharacters()"
              ><i class="fas fa-check mr-1"></i> 8
              {{ 'USER_PROFILE.PASSWORD_VALIDATION_RULES.LENGTH' | translate }}</span
            >
            <span class="rule" [class.checked]="hasPasswordLowerCase()"
              ><i class="fas fa-check mr-1"></i>
              {{ 'USER_PROFILE.PASSWORD_VALIDATION_RULES.LOWERCASE' | translate }} (a-z)</span
            >
            <span class="rule" [class.checked]="hasPasswordUpperCase()"
              ><i class="fas fa-check mr-1"></i>
              {{ 'USER_PROFILE.PASSWORD_VALIDATION_RULES.UPPERCASE' | translate }} (A-Z)</span
            >
            <span class="rule" [class.checked]="hasPasswordDigit()"
              ><i class="fas fa-check mr-1"></i>
              {{ 'USER_PROFILE.PASSWORD_VALIDATION_RULES.NUMBER' | translate }} (0-9)</span
            >
            <span class="rule" [class.checked]="hasSpecialCharacter()"
              ><i class="fas fa-check mr-1"></i>
              {{ 'USER_PROFILE.PASSWORD_VALIDATION_RULES.SPECIAL' | translate }}</span
            >
          </div>

          <app-mid-input
            size="wide"
            [type]="state.confirmPasswordVisibility ? 'text' : 'password'"
            [fasType]="state.confirmPasswordVisibility ? 'fa-eye-slash' : 'fa-eye'"
            (fasChange)="onConfirmPasswordVisibilityChange()"
            [(value)]="data.newPasswordConfirmation"
            #name="appMidInput"
            name="newPasswordConfirmation"
            [required]="true"
            [label]="'USER_PROFILE.CHANGE_PASSWORD.CONFIRM_PASSWORD_PLACEHOLDER' | translate"
          >
          </app-mid-input>
        </div>
        <div class="pb-2 mt-3 align-self-end">
          <app-mid-button
            class="pr-2"
            [disabled]="!matchNewPasswordToPasswordRules() || !user"
            type="primary"
            (action)="changePassword(changePasswordForm)"
            [ngClass]="{
              'mid-in-saving': state.isLoadingPassword,
              'mid-saved': state.isUpdatedPassword,
              'mid-error': state.isFailedPassword
            }"
          >
            <span *ngIf="state.isLoadingPassword"><i class="fa fa-sync-alt fa-spin"></i></span>
            <span *ngIf="state.isFailedPassword"><i class="fa fa-exclamation-triangle"></i></span>
            <span *ngIf="state.isUpdatedPassword"><i class="fa fa-check"></i></span>
            {{ 'USER_PROFILE.CHANGE_PASSWORD.SAVE_PASSWORD_BUTTON' | translate }}
          </app-mid-button>
        </div>
      </form>
    </div>
  </div>
</div>
