import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { layoutConfig } from '../layout.config';
import { LayoutState } from './layout.reducer';

export const selectLayoutState: MemoizedSelector<AppState, LayoutState> = createFeatureSelector(layoutConfig.store.key);

export const selectDefaultLanguage = createSelector(
  selectLayoutState,
  (state: LayoutState) => state.layoutDefaultLanguage
);

export const selectDefaultTheme = createSelector(selectLayoutState, (state: LayoutState) => state.layoutDefaultTheme);

export const selectDronesViewStyle = createSelector(
  selectLayoutState,

  (state: LayoutState) => state.selectDronesViewStyle
);

export const selectIsLandingIntroShown = createSelector(
  selectLayoutState,
  (state: LayoutState) => state.isLandingIntroShown
);

export const selectIsDroneIntroShown = createSelector(
  selectLayoutState,
  (state: LayoutState) => state.isDroneIntroShown
);

export const selectIsFirstDroneIntroShown = createSelector(
  selectLayoutState,
  (state: LayoutState) => state.isFirstDroneIntroShown
);
