import { Injectable } from '@angular/core';
import { AuthenticationStoreService } from './store';
import { validate, required, StringUtils } from '../shared';
import { take } from 'rxjs/operators';
import { CoreStoreService } from '../core/store';
import { authenticationConfig } from './authentication.config';
import { AuthenticationApiService } from './authentication-api.service';
import { Router } from '@angular/router';
import { AccessTokenAuthenticationService } from './access-code-authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    private readonly authenticationStoreService: AuthenticationStoreService,
    private readonly coreStoreService: CoreStoreService,
    private readonly authenticationApiService: AuthenticationApiService,
    private accessToken: AccessTokenAuthenticationService,

    private readonly router: Router
  ) {}

  async signOut(): Promise<void> {
    await this.authenticationApiService.logout();
    this.clearReturnUrl();
    this.coreStoreService.resetState();
    this.accessToken.setAccessCode(null);
    this.router.navigate(['']);
  }

  expireAuthentication() {
    this.authenticationStoreService.expireAuthentication();
    this.coreStoreService.resetState();
  }

  @validate
  async setReturnUrl(@required returnUrl: string, isForceUpdate: boolean = false) {
    const existingReturnUrl = await this.getReturnUrl();

    if (isForceUpdate || !existingReturnUrl) {
      this.authenticationStoreService.setReturnUrl(returnUrl);
    }
  }

  clearReturnUrl() {
    this.authenticationStoreService.setReturnUrl(null);
  }

  async getReturnUrl(): Promise<string> {
    const returnUrl = this.authenticationStoreService.getReturnUrl().pipe(take(1)).toPromise();

    return returnUrl;
  }
}
