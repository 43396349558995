import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isEmpty } from 'lodash';
import { MediaFileDto, OrganizationsMediaService, SharedMediaFileDto } from 'src/api';
import { TemplateService } from 'src/app/global/template.service';

@Component({
  selector: 'app-shared-media',
  templateUrl: './shared-media.component.html',
  styleUrls: ['./shared-media.component.scss'],
})
export class SharedMediaComponent implements OnInit {
  sharing: SharedMediaFileDto;
  media: MediaFileDto;
  invalidSharing = false;
  @ViewChild('video', { static: false }) video: ElementRef;

  srcUrl: string;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly templateService: TemplateService,
    private readonly organizationsMediaService: OrganizationsMediaService
  ) {}

  get isEmpty() {
    return isEmpty;
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(async (params) => {
      const sharingId = params['id'];
      this.sharing = await this.getCurrentSharing(sharingId);
      if (isEmpty(this.sharing)) {
        this.invalidSharing = true;
        return;
      }
      this.media = this.sharing.media;
      const shareFileUrl = `${this.organizationsMediaService.configuration.basePath}/api/v2/shared-media-files/${sharingId}/file`;
      this.srcUrl = shareFileUrl;
      this.templateService.$activeComponent.next(this.media.name);
      this.templateService.pushToAll('mid-share-stream');
      if (this.media.fileType === MediaFileDto.FileTypeEnum.Video) {
        this.initPlayer();
      }
    });
  }

  private async getCurrentSharing(sharingId: string) {
    try {
      const sharing = await this.organizationsMediaService.mediaShareControllerFindOne(sharingId).toPromise();
      if (!isEmpty(sharing)) {
        return sharing;
      }
      return null;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  private initPlayer() {
    if (!this.video || !this.video.nativeElement) {
      return;
    }
    this.video.nativeElement.load();
    this.video.nativeElement.muted = true;
  }
}
