<app-mid-right-panel [title]="'INTERVENTION_SETTINGS.PLACEHOLDER_DRONES' | translate">
  <app-mid-right-panel-content>
    <div class="row m-0 px-4 pb-4">
      <section class="section-type col-md-12">
        <p class="text-left">
          {{ 'ASSOCIATE_WITH_DRONE' | translate }}
        </p>

        <div class="drone-selector">
          <div
            class="drone"
            *ngFor="let drone of drones"
            (click)="onSelectDrone(drone)"
            [class.selected]="selectedDroneId === drone._id"
          >
            <img width="60" src="assets/images/drone.png" class="mr-2" />
            <p>{{ drone.name }}</p>
          </div>
        </div>
        <div class="text-in-lines">
          {{ 'OR' | translate | uppercase }}
        </div>

        <p class="text-left">
          {{ 'ASSOCIATE_WITH_DRONE_MESSAGE' | translate }}
        </p>

        <div class="text-center">
          <img src="assets/images/drone.png" />
        </div>
        <div class="text-center">
          <app-mid-button type="primary" icon="fas fa-plus">{{ 'DRONES_ADD' | translate }}</app-mid-button>
        </div>
      </section>
    </div>
  </app-mid-right-panel-content>

  <app-mid-right-panel-footer>
    <div class="row m-0 py-3 px-4">
      <app-mid-button class="pr-2" type="primary" (action)="associate()" [disabled]="checkBeforeAssociate()">{{
        'ASSOCIATE' | translate
      }}</app-mid-button>
      <app-mid-button class="pr-2" type="secondary" (action)="close()">{{
        'COMMON_CANCEL' | translate
      }}</app-mid-button>
    </div>
  </app-mid-right-panel-footer>
</app-mid-right-panel>
