/**
 * MidGard API
 * MidGard API summary
 *
 * OpenAPI spec version: 2.0
 * Contact: contact@midgard-ai.co
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MediaExifData } from './mediaExifData';
import { MediaNestedGps } from './mediaNestedGps';

export interface MediaFileDto { 
    /**
     * The ObjectId of the MediaFile
     */
    id?: string;
    /**
     * The media file name
     */
    name: string;
    /**
     * The media file extension
     */
    ext: string;
    /**
     * The creation date of the Media
     */
    creationDate?: Date;
    /**
     * The file type
     */
    fileType: MediaFileDto.FileTypeEnum;
    /**
     * The ObjectId of the Organization
     */
    organization: string;
    /**
     * The ObjectId of the User
     */
    createdBy: string;
    /**
     * The media tags
     */
    tags?: Array<string>;
    /**
     * Define the members id (Favorites medias)
     */
    favoriteForUsers?: Array<string>;
    /**
     * The ObjectId of the Intervention
     */
    intervention?: string;
    /**
     * Define if the media has generated TMS tiles
     */
    tiled?: Array<string>;
    /**
     * Define the bounding box of the tiles if any
     */
    bounds?: Array<number>;
    /**
     * The media GPS coordinates
     */
    gps?: MediaNestedGps;
    /**
     * The media EXIF data
     */
    exifData?: MediaExifData;
}
export namespace MediaFileDto {
    export type FileTypeEnum = 'picture' | 'video' | 'document' | 'partial' | 'other';
    export const FileTypeEnum = {
        Picture: 'picture' as FileTypeEnum,
        Video: 'video' as FileTypeEnum,
        Document: 'document' as FileTypeEnum,
        Partial: 'partial' as FileTypeEnum,
        Other: 'other' as FileTypeEnum
    };
}