/**
 * MidGard API
 * MidGard API summary
 *
 * OpenAPI spec version: 2.0
 * Contact: contact@midgard-ai.co
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ApiHttpException } from '../model/apiHttpException';
import { ContextPermissionDto } from '../model/contextPermissionDto';
import { SharedMediaFileCreationDto } from '../model/sharedMediaFileCreationDto';
import { SharedMediaFileDto } from '../model/sharedMediaFileDto';
import { SharedMediaFileWithPermissionDto } from '../model/sharedMediaFileWithPermissionDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class OrganizationsMediaService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * create a new share
     * 
     * @param body share data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mediaShareControllerCreate(body: SharedMediaFileCreationDto, observe?: 'body', reportProgress?: boolean): Observable<SharedMediaFileDto>;
    public mediaShareControllerCreate(body: SharedMediaFileCreationDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SharedMediaFileDto>>;
    public mediaShareControllerCreate(body: SharedMediaFileCreationDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SharedMediaFileDto>>;
    public mediaShareControllerCreate(body: SharedMediaFileCreationDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling mediaShareControllerCreate.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SharedMediaFileDto>('post',`${this.basePath}/api/v2/shared-media-files`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * remove file share
     * 
     * @param shareId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mediaShareControllerDelete(shareId: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public mediaShareControllerDelete(shareId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public mediaShareControllerDelete(shareId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public mediaShareControllerDelete(shareId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shareId === null || shareId === undefined) {
            throw new Error('Required parameter shareId was null or undefined when calling mediaShareControllerDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('delete',`${this.basePath}/api/v2/shared-media-files/${encodeURIComponent(String(shareId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Download a media file
     * 
     * @param shareId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mediaShareControllerDownloadMedia(shareId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public mediaShareControllerDownloadMedia(shareId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public mediaShareControllerDownloadMedia(shareId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public mediaShareControllerDownloadMedia(shareId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shareId === null || shareId === undefined) {
            throw new Error('Required parameter shareId was null or undefined when calling mediaShareControllerDownloadMedia.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/v2/shared-media-files/${encodeURIComponent(String(shareId))}/file`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get a share info
     * 
     * @param shareId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mediaShareControllerFindOne(shareId: string, observe?: 'body', reportProgress?: boolean): Observable<SharedMediaFileDto>;
    public mediaShareControllerFindOne(shareId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SharedMediaFileDto>>;
    public mediaShareControllerFindOne(shareId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SharedMediaFileDto>>;
    public mediaShareControllerFindOne(shareId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shareId === null || shareId === undefined) {
            throw new Error('Required parameter shareId was null or undefined when calling mediaShareControllerFindOne.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<SharedMediaFileDto>('get',`${this.basePath}/api/v2/shared-media-files/${encodeURIComponent(String(shareId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get media share permission
     * 
     * @param mediaId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mediaShareControllerGetCreateSharePermission(mediaId: string, observe?: 'body', reportProgress?: boolean): Observable<ContextPermissionDto>;
    public mediaShareControllerGetCreateSharePermission(mediaId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ContextPermissionDto>>;
    public mediaShareControllerGetCreateSharePermission(mediaId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ContextPermissionDto>>;
    public mediaShareControllerGetCreateSharePermission(mediaId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mediaId === null || mediaId === undefined) {
            throw new Error('Required parameter mediaId was null or undefined when calling mediaShareControllerGetCreateSharePermission.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ContextPermissionDto>('get',`${this.basePath}/api/v2/shared-media-files/my-permissions/${encodeURIComponent(String(mediaId))}/create`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get media share permission
     * 
     * @param mediaId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sharedMediaFileControllerGetMediaShares(mediaId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<SharedMediaFileWithPermissionDto>>;
    public sharedMediaFileControllerGetMediaShares(mediaId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SharedMediaFileWithPermissionDto>>>;
    public sharedMediaFileControllerGetMediaShares(mediaId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SharedMediaFileWithPermissionDto>>>;
    public sharedMediaFileControllerGetMediaShares(mediaId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mediaId === null || mediaId === undefined) {
            throw new Error('Required parameter mediaId was null or undefined when calling sharedMediaFileControllerGetMediaShares.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<SharedMediaFileWithPermissionDto>>('get',`${this.basePath}/api/v2/media/${encodeURIComponent(String(mediaId))}/shares`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
