export class ErrorResponse {
  status: number;
  code: number;
  error: string;
  message: string;
  details: string[];

  constructor({ status, code, error, message, details }: Partial<ErrorResponse> = {}) {
    this.status = status;
    this.code = code;
    this.error = error;
    this.message = message;
    this.details = details || [];
  }
}
