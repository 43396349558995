export enum AnnotationCategory {
  Analyse = 'analyse',
  Actions = 'actions',
  Resources = 'resources',
  Operational = 'operational',
  Sinisters = 'sinisters',
  Facades = 'facades',
  Shapes = 'shape',
  Others = 'others',
}

const acBase = 'TDRAW.LAYERS_NAME';

export const AnnotationCategoryLangkey = {
  [AnnotationCategory.Analyse]: `${acBase}.ANALYSE`,
  [AnnotationCategory.Actions]: `${acBase}.ACTIONS`,
  [AnnotationCategory.Resources]: `${acBase}.RESOURCES`,
  [AnnotationCategory.Operational]: `${acBase}.OPERATIONAL`,
  [AnnotationCategory.Sinisters]: `${acBase}.SINISTERS`,
  [AnnotationCategory.Facades]: `${acBase}.FACADES`,
  [AnnotationCategory.Shapes]: `${acBase}.SHAPES`,
  [AnnotationCategory.Others]: `${acBase}.OTHERS`,
};

export enum AnnotationSubCategory {
  Pathways,
  WaterIntakes,
  Morphology,
  SourcesOfDanger,
  SensitivePoints,
  ActionAreas,
  OffensiveActions,
  DefensiveActions,
  ReconnaissanceActions,
  Airdrops,
  CommandPost,
  TransitPoint,
  FFLandResources,
  NonFFLandResources,
  AirResources,
  ResourcesAndLogistic,
  GeographicSectorization,
  FunctionalSectorization,
}

const ascBase = 'TDRAW.SUBCATEGORY_NAME';

export const AnnotationSubCategoryLangKey = {
  [AnnotationSubCategory.Pathways]: `${ascBase}.PATHWAYS`,
  [AnnotationSubCategory.WaterIntakes]: `${ascBase}.WATERINTAKES`,
  [AnnotationSubCategory.Morphology]: `${ascBase}.MORPHOLOGY`,
  [AnnotationSubCategory.SourcesOfDanger]: `${ascBase}.SOURCESOFDANGER`,
  [AnnotationSubCategory.SensitivePoints]: `${ascBase}.SENSITIVEPOINTS`,
  [AnnotationSubCategory.ActionAreas]: `${ascBase}.ACTIONAREAS`,
  [AnnotationSubCategory.OffensiveActions]: `${ascBase}.OFFENSIVEACTIONS`,
  [AnnotationSubCategory.DefensiveActions]: `${ascBase}.DEFENSIVEACTIONS`,
  [AnnotationSubCategory.ReconnaissanceActions]: `${ascBase}.RECONNAISSANCEACTIONS`,
  [AnnotationSubCategory.Airdrops]: `${ascBase}.AIRDROPS`,
  [AnnotationSubCategory.CommandPost]: `${ascBase}.COMMANDPOST`,
  [AnnotationSubCategory.TransitPoint]: `${ascBase}.TRANSITPOINT`,
  [AnnotationSubCategory.FFLandResources]: `${ascBase}.FFLANDRESOURCES`,
  [AnnotationSubCategory.NonFFLandResources]: `${ascBase}.NONFFLANDRESOURCES`,
  [AnnotationSubCategory.AirResources]: `${ascBase}.AIRRESOURCES`,
  [AnnotationSubCategory.ResourcesAndLogistic]: `${ascBase}.RESOURCESANDLOGISTIC`,
  [AnnotationSubCategory.GeographicSectorization]: `${ascBase}.GEOGRAPHICSECTORIZATION`,
  [AnnotationSubCategory.FunctionalSectorization]: `${ascBase}.FUNCTIONALSECTORIZATION`,
};
