import { Action } from '@ngrx/store';

export enum AuthenticationActionType {
  Authenticate = '[Authentication] Authenticate',
  ExpireAuthentication = '[Authentication] ExpireAuthentication',
  SetReturnUrl = '[Authentication] SetReturnUrl',
}

export class AuthenticateAction implements Action {
  readonly type = AuthenticationActionType.Authenticate;

  readonly userId: string;
  readonly accessToken: string;
  readonly organizationId: string;

  constructor(userId: string, accessToken: string, organizationId: string) {
    this.userId = userId;
    this.accessToken = accessToken;
    this.organizationId = organizationId;
  }
}

export class ExpireAuthenticationAction implements Action {
  readonly type = AuthenticationActionType.ExpireAuthentication;
}

export class SetReturnUrlAction implements Action {
  readonly type = AuthenticationActionType.SetReturnUrl;

  readonly returnUrl: string;

  constructor(returnUrl: string) {
    this.returnUrl = returnUrl;
  }
}

export type AuthenticationActions = AuthenticateAction | ExpireAuthenticationAction | SetReturnUrlAction;
