<form class="mid-content-box" #featuresForm="ngForm" novalidate autocomplete="on">
  <div class="card-body">
    <div class="d-flex w-100 justify-content-between">
      <h3>{{ 'FEATURES' | translate }}</h3>
      <div class="form-group">
        <input
          type="text"
          (input)="onFilterInput($event.target.value)"
          class="mid-features-search form-control form-control-sm"
        />
      </div>
    </div>

    <div *ngIf="dataSource.filteredItems | isEmpty" class="text-center text-muted my-3">
      {{ 'COMMON_NOTHING_TO_DISPLAY' | translate }}.
    </div>

    <div class="features-list" *ngIf="!(dataSource.filteredItems | isEmpty)">
      <div class="list-group list-group-flush" *ngFor="let feature of dataSource.filteredItems">
        <li class="list-group-item mid-list-group-item">
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1">
              <i
                title="Feature is overrided"
                *ngIf="feature.isEnabled !== feature.isEnabledByDefault"
                class="fas fa-cog mr-1"
              ></i
              ><span>{{ feature?.name }}</span>
            </h5>
            <div class="custom-control custom-switch red-green-toggle-switch d-inline-block">
              <input
                type="checkbox"
                class="custom-control-input"
                id="isFeatureEnabled-{{ feature.id }}"
                name="isFeatureEnabled-{{ feature.id }}"
                [(ngModel)]="feature.isEnabled"
              />
              <label class="custom-control-label" for="isFeatureEnabled-{{ feature.id }}"> </label>
            </div>
          </div>
          <div class="mb-1 text-muted text-small">
            <ng-container> {{ 'COMMON_DEFAULT' | translate }}: </ng-container>
            <span class="badge badge-success text-uppercase" *ngIf="feature?.isEnabledByDefault">{{
              'COMMON_ENABLED' | translate
            }}</span>
            <span class="badge badge-danger text-uppercase" *ngIf="!feature?.isEnabledByDefault">{{
              'COMMON_DISABLED' | translate
            }}</span>
          </div>
          <div class="mb-1">{{ feature?.description }}</div>
        </li>
      </div>
    </div>

    <div class="mt-2 text-right">
      <a [routerLink]="homeRoute" class="float-left mt-2 mid-back-button"
        ><i class="fas fa-angle-left mr-1"></i><ng-container>{{ 'COMMON_BACK' | translate }}</ng-container></a
      >
      <button
        type="button"
        class="btn btn-sm mid-btn-primary mr-2 mb-0"
        [disabled]="!featuresForm.dirty"
        (click)="onSave()"
      >
        {{ 'COMMON_SAVE_AND_RELOAD' | translate }}
      </button>

      <button type="button" class="btn btn-sm btn-secondary" (click)="onReset()">
        {{ 'COMMON_RESET' | translate }}
      </button>
    </div>
  </div>
</form>
