import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { AuthenticationStoreService } from 'src/app/authentication/store';
import { DroneService } from 'src/app/autogenerated/droneService';
import { DroneStreamSharing, Drone, User } from 'src/app/autogenerated/model';
import { TemplateService } from 'src/app/global/template.service';
import { UserProfilesStoreService } from 'src/app/user-profile/store';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-share-stream',
  templateUrl: './share-stream.component.html',
  styleUrls: ['./share-stream.component.scss'],
})
export class ShareStreamComponent implements OnInit {
  public sharing: DroneStreamSharing;
  public drone: Drone;
  public inValid = false;
  private guest = new User({
    fullname: 'Guest',
  });
  public srcUrl: string;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly templateService: TemplateService,
    private readonly droneService: DroneService
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe(async (params) => {
      const sharingId = params['id'];
      this.sharing = await this.droneService.getSharing({ _id: sharingId });
      if (!this.sharing._id) {
        this.inValid = true;
        return;
      }
      this.drone = this.sharing.drone;

      const videoUrl = environment.backend + 'live-stream-share/' + this.sharing._id + '/manifest.ism/manifest';
      this.srcUrl = videoUrl;
      // change header name component and set css Class
      this.templateService.$activeComponent.next(this.drone.name);
      this.templateService.pushToAll('mid-share-stream');
    });
  }
}
