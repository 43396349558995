import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MediaService } from 'src/app/autogenerated/mediaService';
import moment from 'moment';

@Component({
  selector: 'tdraw-pic-info-tab',
  templateUrl: './pic-info-tab.component.html',
  styleUrls: ['./pic-info-tab.component.scss'],
})
export class PicInfoTabComponent implements OnChanges {
  @Input()
  mediaId: string;

  media: {
    name: string;
    importDate: string;
    importTime: string;
  };

  constructor(private mediaService: MediaService) {}

  ngOnChanges(changes: SimpleChanges): void {
    const mediaId = changes?.mediaId?.currentValue;
    if (mediaId) {
      this.loadMediaInfo(mediaId);
    }
  }

  private loadMediaInfo(mediaId: string) {
    this.mediaService.getMedia({ _id: mediaId }).then((media) => {
      const creationDate = moment(media.creationDate);
      this.media = {
        name: media.name,
        importDate: creationDate.format('L'),
        importTime: creationDate.format('hh:mm'),
      };
    });
  }
}
