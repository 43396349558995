import { OnChanges } from '@angular/core';
import { Component, Input, OnDestroy, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  CreatedUserDto,
  FeatureModule,
  OrganizationDto,
  OrganizationGroupDto,
  OrganizationGroupService,
  OrganizationsService,
  UserDto,
  UsersService,
  UserWithGroupDto,
} from 'src/api';
import { UserWithGroups } from 'src/app/autogenerated/model';
import { OrganizationService } from 'src/app/autogenerated/organizationService';
import { UserService } from 'src/app/autogenerated/userService';
import { MonitoringService } from 'src/app/global/monitoring.service';
import { TemplateService } from 'src/app/global/template.service';
import { ConfirmModalService } from 'src/app/midgard-controls/services/confirm-modal.service';
import { InformModalComponent } from 'src/app/shared/modals/inform-modal/inform-modal.component';
import { UserProfilesStoreService } from 'src/app/user-profile/store';
import { Unsubscribe } from '../../core/decorators';
import { Subscription } from 'rxjs';
import { differenceWith, filter, includes, isEmpty, isEqual, map } from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizationNavConfiguration } from '../organization-nav-configuration';

@Unsubscribe()
@Component({
  selector: 'app-organization-users',
  templateUrl: './organization-users.component.html',
  styleUrls: ['./organization-users.component.scss'],
})
export class OrganizationUsersComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  org: OrganizationDto;

  currentUser: UserDto;
  users: UserDto[];

  state: {
    isLoading: boolean;
  };

  isAdmin: boolean;
  hasGlobalEditMode: boolean;

  userGroupMap: Map<UserDto, OrganizationGroupDto[]>;
  features: FeatureModule[];
  organizationGroups: OrganizationGroupDto[];
  paginationPageNumber: number;
  filterGroup: OrganizationGroupDto;
  private usersSubscriptions: Subscription;
  private groupsSubscriptions: Subscription;
  private organizationFeaturesSubscription: Subscription;

  constructor(
    private readonly organizationService: OrganizationService,
    private readonly organizationsService: OrganizationsService,
    private readonly userProfilesStoreService: UserProfilesStoreService,
    private readonly templateService: TemplateService,
    private readonly monitoringService: MonitoringService,
    private readonly confirmModalService: ConfirmModalService,
    private readonly userService: UserService,
    private readonly usersService: UsersService,
    private readonly organizationGroupService: OrganizationGroupService,
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) {
    this.users = [];
    this.organizationGroups = [];

    this.state = {
      isLoading: true,
    };

    this.isAdmin = false;
    this.hasGlobalEditMode = false;

    this.userGroupMap = new Map<UserDto, OrganizationGroupDto[]>();
    this.paginationPageNumber = 1;
    this.templateService.$activeComponent.next('COMMON.ORGANIZATION');
    this.features = [];

    this.filterGroup = null;
  }

  async ngOnInit(): Promise<void> {
    this.getPageNumber(this.paginationPageNumber);
    this.monitoringService.trackPageView('OrganizationUsers');
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const componentChanges = changes as PropertyMap<OrganizationUsersComponent, SimpleChange>;
    if (componentChanges.org.currentValue) {
      await this.loadOrgData();
    }
  }

  ngOnDestroy(): void {}

  onCreateUser(event): void {
    this.state.isLoading = true;
    this.usersService
      .userControllerCreate({
        defaultOrganization: this.org.id,
        email: event.user.email,
        fullname: event.user.fullname,
        phoneNumber: event.user.phoneNumber.e164Number,
      })
      .toPromise()
      .then(async (result: CreatedUserDto) => {
        await this.addUserGroups(result.userId, event.groups);
        // TODO: check the starting password existence to decide the message
        // https://midgardai.atlassian.net/browse/MID-984
        const data = {
          message: result.startingPassword ? 'PASSWORD_NEW_ACCOUNT' : 'PASSWORD_EXISTING_ACCOUNT',
          password: result.startingPassword,
        };
        this.showModal(data);
        this.state.isLoading = false;
        this.hasGlobalEditMode = false;
        await this.loadOrgData();
      })
      .catch((err) => {
        this.state.isLoading = false;
        this.hasGlobalEditMode = false;
        console.log(err);
        const data = {
          message: 'ERROR',
          error: err?.message,
        };
        this.showModal(data);
      });
  }

  async onSaveUser(userWithGroup: UserWithGroups): Promise<void> {
    this.hasGlobalEditMode = false;
    if (!isEmpty(userWithGroup)) {
      await this.updateUser(userWithGroup.user, userWithGroup.groups);
    }
  }

  onEditUser(): void {
    this.hasGlobalEditMode = !this.hasGlobalEditMode;
  }

  async onDeleteUser(user: UserDto): Promise<void> {
    const isDeletingUserConfirmed = await this.confirmModalService.showConfirmModal(
      'DELETE_USER_CONFIRM_MESSAGE',
      'remove'
    );
    if (isDeletingUserConfirmed) {
      await this.deleteUser(user);
    }
  }

  getPageNumber(pageNumber: number): void {
    this.state.isLoading = true;
    this.paginationPageNumber = pageNumber;
    setTimeout(() => {
      this.state.isLoading = false;
    }, 500);
  }

  async resetFilter() {
    this.filterGroup = null;
    await this.router.navigate(['/', 'organization', this.org.id, OrganizationNavConfiguration.routes.users]);
    this.loadUsers();
  }

  private async updateUser(user: UserDto, groups: OrganizationGroupDto[]): Promise<void> {
    this.state.isLoading = true;

    this.updateUserGroups(user.id, groups)
      .then(() => {
        this.loadUsers();
        this.hasGlobalEditMode = false;
        const data = {
          message: 'USER_UPDATED',
        };
        this.showModal(data);
      })
      .catch((err) => {
        this.hasGlobalEditMode = false;
        console.log(err);
        const data = {
          message: 'ERROR',
          error: err?.message,
        };
        this.showModal(data);
      });

    this.state.isLoading = false;
  }

  private async deleteUser(user: UserDto): Promise<void> {
    this.state.isLoading = true;
    this.organizationGroupService
      .orgOrganizationGroupControllerRemoveUser({ id: user.id }, this.org.id)
      .toPromise()
      .then((users) => {
        this.users = this.sortUsers(users);
        this.loadUserGroups(this.users);
        const data = {
          message: 'USER_DELETED',
        };
        this.showModal(data);
      })
      .catch((err) => {
        console.log(err);
        const data = {
          message: 'ERROR',
          error: err?.message,
        };
        this.showModal(data);
      });
    this.state.isLoading = false;
  }

  private async loadOrgData(): Promise<void> {
    this.getOrgPermission();
    this.loadUsers();
    this.loadOrganizationsFeatures();
  }

  private loadUsers(): void {
    this.state.isLoading = true;

    this.usersSubscriptions = this.organizationGroupService
      .orgOrganizationGroupControllerGetAllOrgUsers(this.org.id)
      .subscribe(async (users) => {
        const filteredUsers = await this.filterUsers(users);
        this.users = this.sortUsers(filteredUsers);
        this.loadUserGroups(this.users);
        this.state.isLoading = false;
      });
  }

  private async filterUsers(users: UserWithGroupDto[]) {
    const groupId = this.getFilterGroupId();
    if (!groupId) {
      return users;
    }

    this.filterGroup = await this.organizationGroupService.organizationGroupControllerGet(groupId).toPromise();
    const filteredUsers = filter(users, (user) => includes(user.groups, this.filterGroup));
    return filteredUsers;
  }

  private loadUserGroups(users: UserDto[]) {
    this.groupsSubscriptions = this.organizationGroupService
      .orgOrganizationGroupControllerGetOrgGroups(this.org.id)
      .subscribe((groups) => {
        const currentUserGroup = new Map<UserDto, OrganizationGroupDto[]>();
        this.organizationGroups = groups;

        for (const user of users) {
          let userGroups = [];
          for (const group of groups) {
            for (const groupUserId of group.users) {
              if (groupUserId === user.id) {
                userGroups.push(group);
              }
            }
          }
          currentUserGroup.set(user, userGroups);
        }
        this.userGroupMap = currentUserGroup;
      });
  }

  private loadOrganizationsFeatures() {
    this.organizationFeaturesSubscription = this.organizationsService
      .organizationControllerGetOrganizationFeatures(this.org.id)
      .subscribe((features) => {
        this.features = features;
      });
  }

  private async updateUserGroups(userId: string, groups: OrganizationGroupDto[]) {
    const currentUser = await this.usersService.userControllerGet(userId).toPromise();
    const currentUserGroup = this.userGroupMap.get(currentUser);
    const addedGroups = this.findDifferenceWithGroups(groups, currentUserGroup);
    const deletedGroups = this.findDifferenceWithGroups(currentUserGroup, groups);
    await this.addUserGroups(userId, addedGroups);
    await this.deleteUserGroups(userId, deletedGroups);
  }

  private async addUserGroups(userId: string, groups: OrganizationGroupDto[]) {
    if (isEmpty(groups)) {
      return;
    }
    await Promise.all(
      groups.map(async (group) => {
        await this.organizationGroupService.organizationGroupControllerAddUser({ id: userId }, group.id).toPromise();
      })
    );
  }

  private async deleteUserGroups(userId: string, groups: OrganizationGroupDto[]) {
    if (isEmpty(groups)) {
      return;
    }

    const userGroupRequests = [];
    for (let group of groups) {
      const request = await this.organizationGroupService
        .organizationGroupControllerRemoveUser({ id: userId }, group.id)
        .toPromise();
      userGroupRequests.push(request);
    }
    await Promise.all(userGroupRequests);
  }

  private findDifferenceWithGroups(groupOne: OrganizationGroupDto[], groupTwo: OrganizationGroupDto[]) {
    const addedGroups = differenceWith(groupOne, groupTwo, isEqual);
    return addedGroups;
  }

  private getOrgPermission(): void {
    this.organizationsService
      .organizationControllerGetOrganizationPermissions(this.org.id)
      .toPromise()
      .then((permissions) => {
        this.isAdmin = permissions.update;
      });
  }

  private getFilterGroupId() {
    const queryParams = this.route.snapshot.queryParams;
    const group = queryParams?.group;
    return group;
  }

  private sortUsers(users: UserDto[]): UserDto[] {
    if (!users) return;
    return users.sort((a, b) => (a.fullname > b.fullname ? 1 : -1));
  }

  private showModal(data): void {
    this.dialog.open(InformModalComponent, {
      width: '450px',
      data: data,
    });
  }
}
