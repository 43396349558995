<div class="dialog">
  <div class="dialog-header">
    <div class="dialog-title">
      <span class="title"> {{ title | translate }} </span>
    </div>
  </div>
  <div class="dialog-body" [class.with-footer]="footer.hasChildNodes()">
    <ng-content select="div[role=content]"></ng-content>
  </div>
  <div class="dialog-footer" #footer>
    <ng-content select="div[role=footer]"></ng-content>
  </div>
</div>
