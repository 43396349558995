<div class="multi-select" *ngIf="allGroups">
  <mat-form-field appearance="fill" class="multi-select-dropdown-field">
    <ng-container *ngIf="multiple; else singleMode">
      <mat-select
        [(value)]="currentGroups"
        (selectionChange)="onGroupsChanged()"
        placeholder="{{ 'GROUP_ROLE_HINT' | translate }}"
        [multiple]="true"
        class="multi-select-dropdown"
      >
        <mat-option *ngFor="let group of allGroups" [value]="group.value">
          {{ group.viewValue | translate }}
        </mat-option>
      </mat-select>
    </ng-container>

    <ng-template #singleMode>
      <mat-select
        [(value)]="currentGroup"
        (selectionChange)="onGroupsChanged()"
        placeholder="{{ 'GROUP_ROLE_HINT' | translate }}"
        [multiple]="false"
        class="multi-select-dropdown"
      >
        <mat-option *ngFor="let group of allGroups" [value]="group.value">
          {{ group.viewValue | translate }}
        </mat-option>
      </mat-select>
    </ng-template>
  </mat-form-field>
</div>
