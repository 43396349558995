import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TdrawHomeComponent } from './pages/tdraw-home/tdraw-home.component';
import { CoreModule } from '../core/core.module';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatSliderModule } from '@angular/material/slider';
import { HistoryPanelComponent } from './components/right-panel-structure/right-panel-elements/history-panel/history-panel.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VersionListElementComponent } from './components/common/version-list-element/version-list-element.component';
import { VersionListComponent } from './components/common/version-list/version-list.component';
import { MidgardControlsModule } from '../midgard-controls/midgard-controls.module';
import { MatRadioModule } from '@angular/material/radio';
import { CreateGisVersionModalComponent } from './components/modals/create-gis-version-modal/create-gis-version-modal.component';
import { TdrawGisEditModePageComponent } from './pages/tdraw-gis-edit-mode-page/tdraw-gis-edit-mode-page.component';
import { VersionListElementMenuComponent } from './components/common/version-list-element-menu/version-list-element-menu.component';
import { MatMenuModule } from '@angular/material/menu';
import { LayersTabComponent } from './components/right-panel-structure/right-panel-elements/tools-panel-structure/tools-panel-tabs/layers-tab/layers-tab.component';
import { BasemapTabComponent } from './components/right-panel-structure/right-panel-elements/tools-panel-structure/tools-panel-tabs/basemap-tab/basemap-tab.component';
import { GisSymbolsTabComponent } from './components/right-panel-structure/right-panel-elements/tools-panel-structure/tools-panel-tabs/gis-symbols-tab/gis-symbols-tab.component';
import { DataTabComponent } from './components/right-panel-structure/right-panel-elements/tools-panel-structure/tools-panel-tabs/data-tab/data-tab.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DuplicateVersionModalComponent } from './components/modals/duplicate-version-modal/duplicate-version-modal.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { LocationSearchBarComponent } from './components/common/location-search-bar/location-search-bar.component';
import { CreateConeModalComponent } from './components/modals/create-cone-modal/create-cone-modal.component';
import { RightPanelComponent } from './components/right-panel-structure/right-panel/right-panel.component';
import { GisEditionPropertiesPanelComponent } from './components/right-panel-structure/right-panel-elements/gis-edition-properties-panel/gis-edition-properties-panel.component';
import { MapmodeInfoComponent } from './components/common/mapmode-info/mapmode-info.component';
import { RightPanelContainerComponent } from './components/right-panel-structure/right-panel-container/right-panel-container.component';
import { PinpointImageModalComponent } from './components/modals/pinpoint-image-modal/pinpoint-image-modal.component';
import { TdrawGisViewModePageComponent } from './pages/tdraw-gis-view-mode-page/tdraw-gis-view-mode-page.component';
import { ReadonlyPropertiesPanelComponent } from './components/right-panel-structure/right-panel-elements/readonly-properties-panel/readonly-properties-panel.component';
import { RenameVersionModalComponent } from './components/modals/rename-version-modal/rename-version-modal.component';
import { EditRegularMarkerModalComponent } from './components/modals/edit-regular-marker-modal/edit-regular-marker-modal.component';
import { TdrawPicEditModePageComponent } from './pages/tdraw-pic-edit-mode-page/tdraw-pic-edit-mode-page.component';
import { PicEditionPropertiesPanelComponent } from './components/right-panel-structure/right-panel-elements/pic-edition-properties-panel/pic-edition-properties-panel.component';
import { PicSymbolsTabComponent } from './components/right-panel-structure/right-panel-elements/tools-panel-structure/tools-panel-tabs/pic-symbols-tab/pic-symbols-tab.component';
import { TdrawPicViewModePageComponent } from './pages/tdraw-pic-view-mode-page/tdraw-pic-view-mode-page.component';
import { PicInfoTabComponent } from './components/right-panel-structure/right-panel-elements/tools-panel-structure/tools-panel-tabs/pic-info-tab/pic-info-tab.component';
import { ToolsPanelComponent } from './components/right-panel-structure/right-panel-elements/tools-panel-structure/tools-panel/tools-panel.component';
import { ToolsPanelTabComponent } from './components/right-panel-structure/right-panel-elements/tools-panel-structure/tools-panel-tab/tools-panel-tab.component';
import { CreatePicVersionModalComponent } from './components/modals/create-pic-version-modal/create-pic-version-modal.component';
import { SitacSelectorComponent } from './components/common/sitac-selector/sitac-selector.component';
import { SitacSelectorModalComponent } from './components/modals/sitac-selector-modal/sitac-selector-modal.component';
import { InterventionCommonModule } from '../intervention/common/intervention-common.module';
import { UpdateStatisticsModalComponent } from './components/modals/update-statistics-modal/update-statistics-modal.component';
import { TdrawSharedGisPageComponent } from './pages/tdraw-shared-gis-page/tdraw-shared-gis-page.component';
import { MapStateSharePanelComponent } from './components/common/mapstate-share-panel/mapstate-share-panel.component';
import { TdrawSharedPicPageComponent } from './pages/tdraw-shared-pic-page/tdraw-shared-pic-page.component';
import { QRCodeModule } from 'angularx-qrcode';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MapLoadingBarComponent } from './components/common/map-loading-bar/map-loading-bar.component';
import { ExportMapStateModalComponent } from './components/modals/export-mapstate-modal/export-mapstate-modal.component';
import { MediaCommonModule } from '../media/common/media-common.module';
import { DroneCommonModule } from '../drone/common/drone-common.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    TdrawHomeComponent,
    RightPanelComponent,
    HistoryPanelComponent,
    VersionListElementComponent,
    VersionListComponent,
    CreateGisVersionModalComponent,
    CreatePicVersionModalComponent,
    ExportMapStateModalComponent,
    RenameVersionModalComponent,
    CreateConeModalComponent,
    EditRegularMarkerModalComponent,
    LocationSearchBarComponent,
    TdrawGisEditModePageComponent,
    TdrawPicEditModePageComponent,
    VersionListElementMenuComponent,
    LayersTabComponent,
    ToolsPanelComponent,
    ToolsPanelTabComponent,
    BasemapTabComponent,
    PicInfoTabComponent,
    GisSymbolsTabComponent,
    PicSymbolsTabComponent,
    SitacSelectorComponent,
    GisEditionPropertiesPanelComponent,
    PicEditionPropertiesPanelComponent,
    DataTabComponent,
    DuplicateVersionModalComponent,
    SitacSelectorModalComponent,
    MapmodeInfoComponent,
    RightPanelContainerComponent,
    PinpointImageModalComponent,
    TdrawGisViewModePageComponent,
    TdrawPicViewModePageComponent,
    ReadonlyPropertiesPanelComponent,
    UpdateStatisticsModalComponent,
    TdrawSharedGisPageComponent,
    TdrawSharedPicPageComponent,
    MapStateSharePanelComponent,
    MapLoadingBarComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    RouterModule,
    MatButtonModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatInputModule,
    MatSliderModule,
    FormsModule,
    MidgardControlsModule,
    MatRadioModule,
    MatMenuModule,
    MatTooltipModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    InterventionCommonModule,
    QRCodeModule,
    MatProgressBarModule,
    MediaCommonModule,
    DroneCommonModule,
    SharedModule,
  ],
  exports: [TdrawHomeComponent, LocationSearchBarComponent],
})
export class TdrawModule {}
