import { LocationSearchResultType } from './location-search-result-type.enum';

export class LocationSearchResult {
  title: string;
  coordinates: [number, number]; // lng, lat
  type: LocationSearchResultType;
  index?: number;

  constructor({ title, coordinates, type, index }: Partial<LocationSearchResult> = {}) {
    this.title = title;
    this.coordinates = coordinates;
    this.type = type;
    this.index = index;
  }
}
