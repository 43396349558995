<ng-container [ngSwitch]="viewStyle">
  <ng-container *ngSwitchCase="DronesViewStyle.block">
    <div class="drone-block-view">
      <div *ngIf="drones | isEmpty">
        {{ 'DRONES_EMPTY' | translate }}
      </div>
      <app-drone-card
        (droneDeleted)="onDroneDeleted()"
        [isAdmin]="isAdmin"
        [organizationId]="organizationId"
        *ngFor="let drone of drones"
        [drone]="drone"
        class="drone-card"
      ></app-drone-card>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="DronesViewStyle.grid">
    <div class="drone-grid-view">
      <div class="main-drone">
        <div *ngIf="drones | isEmpty">
          {{ 'DRONES_EMPTY' | translate }}
        </div>
        <app-drone-card
          [isAdmin]="isAdmin"
          [organizationId]="organizationId"
          *ngIf="!(drones | isEmpty)"
          [drone]="drones[0]"
          class="drone-card"
          (droneDeleted)="onDroneDeleted()"
        ></app-drone-card>
      </div>
      <div class="side-drones">
        <ng-container *ngFor="let drone of drones; index as i; first as isFirst">
          <app-drone-card
            *ngIf="!isFirst"
            [isAdmin]="isAdmin"
            [organizationId]="organizationId"
            [isFreeSpaceClickable]="true"
            [drone]="drone"
            [isSmallCard]="true"
            (freeSpaceClicked)="onMainDroneChange($event)"
            (droneDeleted)="onDroneDeleted()"
            class="drone-card"
          ></app-drone-card>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>
