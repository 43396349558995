import { Drone } from '../../autogenerated/model';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { required, validate } from 'src/app/shared';
import {
  DeleteDroneAction,
  SetOrganizationDroneAction,
  SetOrganizationDronesAction,
  UpdateDroneAction,
} from './drone.actions';
import { Observable } from 'rxjs';
import { selectDroneById, selectOrganizationDronesByOrganizationId } from './drone.selectors';

@Injectable({
  providedIn: 'root',
})
export class DroneStoreService {
  private readonly store: Store<AppState>;

  constructor(store: Store<AppState>) {
    this.store = store;
  }

  @validate
  addDrone(@required organizationId: string, @required drone: Drone) {
    this.store.dispatch(new SetOrganizationDroneAction(organizationId, drone));
  }

  @validate
  setOrganizationDrones(@required organizationId: string, @required drones: Drone[]) {
    this.store.dispatch(new SetOrganizationDronesAction(organizationId, drones));
  }

  @validate
  getDroneById(@required droneId: string): Observable<Drone> {
    const drone$ = this.store.pipe(select(selectDroneById, { id: droneId }));
    return drone$;
  }

  @validate
  getOrganizationDrones(@required organizationId: string): Observable<Drone[]> {
    const drones$ = this.store.pipe(select(selectOrganizationDronesByOrganizationId, { id: organizationId }));
    return drones$;
  }

  updateDrone(@required organizationId: string, @required drone: Drone) {
    this.store.dispatch(new UpdateDroneAction(organizationId, drone));
  }

  deleteDrone(@required organizationId: string, @required drone: Drone) {
    this.store.dispatch(new DeleteDroneAction(organizationId, drone));
  }
}
