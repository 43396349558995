import { Injectable } from '@angular/core';
import { IconColor, IconState, IconType, MapIcon, MapIconGroup, SpecialType } from '../models/map-icon';
import mapboxgl from 'mapbox-gl';
import _ from 'lodash';
import {
  AnnotationCategory,
  AnnotationCategoryLangkey,
  AnnotationSubCategory,
} from '../models/annotation-category.enum';

const CssClassPrefix = 'mapicon mapicon-';
const GuiIcons: { [key: string]: MapIcon } = {};

function registerGuiIcon(obj: Partial<MapIcon> = {}) {
  const key = obj.key;
  obj.cssclass = CssClassPrefix + key;

  if (!obj.defaultMapIcon) {
    obj.defaultMapIcon = key;
  }

  GuiIcons[key] = new MapIcon(obj);
}

// Actions
registerGuiIcon({
  key: 'act_actionsoffensives_actif_symb_blue',
  type: IconType.Symbol,
  category: AnnotationCategory.Actions,
  subcategory: AnnotationSubCategory.OffensiveActions,
  colors: [IconColor.Blue],
  state: IconState.Active,
  textOffset: [0, 1.8],
  trigrams: ['EPUIS', 'SAUV', 'ALIM'],
});
registerGuiIcon({
  key: 'act_actionsoffensives_actif_symb_yellow',
  type: IconType.Symbol,
  category: AnnotationCategory.Actions,
  subcategory: AnnotationSubCategory.OffensiveActions,
  colors: [IconColor.Yellow],
  state: IconState.Active,
  textOffset: [0, 1.8],
  trigrams: ['REC', 'TRAIT', 'MES', 'OBST'],
});
registerGuiIcon({
  key: 'act_actionsoffensives_actif_symb_green',
  type: IconType.Symbol,
  category: AnnotationCategory.Actions,
  subcategory: AnnotationSubCategory.OffensiveActions,
  colors: [IconColor.Green],
  state: IconState.Active,
  textOffset: [0, 1.8],
  trigrams: ['MED', 'EVAC', 'SR', 'RECH', 'SD'],
});
registerGuiIcon({
  key: 'act_actionsoffensives_actif_symb_red',
  type: IconType.Symbol,
  category: AnnotationCategory.Actions,
  subcategory: AnnotationSubCategory.OffensiveActions,
  colors: [IconColor.Red],
  state: IconState.Active,
  textOffset: [0, 1.8],
  trigrams: ['EMUL', 'VENT'],
});
registerGuiIcon({
  key: 'act_actionsoffensives_prev_symb_blue',
  type: IconType.Symbol,
  category: AnnotationCategory.Actions,
  subcategory: AnnotationSubCategory.OffensiveActions,
  colors: [IconColor.Blue],
  state: IconState.Planned,
  textOffset: [0, 1.8],
  trigrams: ['EPUIS', 'SAUV', 'ALIM'],
});
registerGuiIcon({
  key: 'act_actionsoffensives_prev_symb_yellow',
  type: IconType.Symbol,
  category: AnnotationCategory.Actions,
  subcategory: AnnotationSubCategory.OffensiveActions,
  colors: [IconColor.Yellow],
  state: IconState.Planned,
  textOffset: [0, 1.8],
  trigrams: ['REC', 'TRAIT', 'MES', 'OBST'],
});
registerGuiIcon({
  key: 'act_actionsoffensives_prev_symb_green',
  type: IconType.Symbol,
  category: AnnotationCategory.Actions,
  subcategory: AnnotationSubCategory.OffensiveActions,
  colors: [IconColor.Green],
  state: IconState.Planned,
  textOffset: [0, 1.8],
  trigrams: ['MED', 'EVAC', 'SR', 'RECH', 'SD'],
});
registerGuiIcon({
  key: 'act_actionsoffensives_prev_symb_red',
  type: IconType.Symbol,
  category: AnnotationCategory.Actions,
  subcategory: AnnotationSubCategory.OffensiveActions,
  colors: [IconColor.Red],
  state: IconState.Planned,
  textOffset: [0, 1.8],
  trigrams: ['EMUL', 'VENT'],
});
registerGuiIcon({
  key: 'act_actionsreco_actif_symb_red',
  type: IconType.Symbol,
  category: AnnotationCategory.Actions,
  subcategory: AnnotationSubCategory.ReconnaissanceActions,
  colors: [IconColor.Red],
  state: IconState.Active,
  textOffset: [0, -0.3],
});
registerGuiIcon({
  key: 'act_actionsreco_actif_symb_blue',
  type: IconType.Symbol,
  category: AnnotationCategory.Actions,
  subcategory: AnnotationSubCategory.ReconnaissanceActions,
  colors: [IconColor.Blue],
  state: IconState.Active,
  textOffset: [0, -0.3],
});
registerGuiIcon({
  key: 'act_actionsreco_actif_symb_yellow',
  type: IconType.Symbol,
  category: AnnotationCategory.Actions,
  subcategory: AnnotationSubCategory.ReconnaissanceActions,
  colors: [IconColor.Yellow],
  state: IconState.Active,
  textOffset: [0, -0.3],
});
registerGuiIcon({
  key: 'act_actionsreco_actif_symb_green',
  type: IconType.Symbol,
  category: AnnotationCategory.Actions,
  subcategory: AnnotationSubCategory.ReconnaissanceActions,
  colors: [IconColor.Green],
  state: IconState.Active,
  textOffset: [0, -0.3],
});
registerGuiIcon({
  key: 'act_actionsreco_prev_symb_red',
  type: IconType.Symbol,
  category: AnnotationCategory.Actions,
  subcategory: AnnotationSubCategory.ReconnaissanceActions,
  colors: [IconColor.Red],
  state: IconState.Planned,
  textOffset: [0, -0.3],
});
registerGuiIcon({
  key: 'act_actionsreco_prev_symb_blue',
  type: IconType.Symbol,
  category: AnnotationCategory.Actions,
  subcategory: AnnotationSubCategory.ReconnaissanceActions,
  colors: [IconColor.Blue],
  state: IconState.Planned,
  textOffset: [0, -0.3],
});
registerGuiIcon({
  key: 'act_actionsreco_prev_symb_yellow',
  type: IconType.Symbol,
  category: AnnotationCategory.Actions,
  subcategory: AnnotationSubCategory.ReconnaissanceActions,
  colors: [IconColor.Yellow],
  state: IconState.Planned,
  textOffset: [0, -0.3],
});
registerGuiIcon({
  key: 'act_actionsreco_prev_symb_green',
  type: IconType.Symbol,
  category: AnnotationCategory.Actions,
  subcategory: AnnotationSubCategory.ReconnaissanceActions,
  colors: [IconColor.Green],
  state: IconState.Planned,
  textOffset: [0, -0.3],
});
registerGuiIcon({
  key: 'act_defensivecirculeauactif_symb_blue',
  type: IconType.Symbol,
  category: AnnotationCategory.Actions,
  subcategory: AnnotationSubCategory.DefensiveActions,
  colors: [IconColor.Blue],
  state: IconState.Active,
  textOffset: [0, 1.8],
});
registerGuiIcon({
  key: 'act_defensivecirculeauprev_symb_blue',
  type: IconType.Symbol,
  category: AnnotationCategory.Actions,
  subcategory: AnnotationSubCategory.DefensiveActions,
  colors: [IconColor.Blue],
  state: IconState.Planned,
  textOffset: [0, 1.8],
});
registerGuiIcon({
  key: 'act_defensivecirculhumactif_symb_green',
  type: IconType.Symbol,
  category: AnnotationCategory.Actions,
  subcategory: AnnotationSubCategory.DefensiveActions,
  colors: [IconColor.Green],
  state: IconState.Active,
  textOffset: [0, 1.8],
});
registerGuiIcon({
  key: 'act_defensivecirculhumprev_symb_green',
  type: IconType.Symbol,
  category: AnnotationCategory.Actions,
  subcategory: AnnotationSubCategory.DefensiveActions,
  colors: [IconColor.Green],
  state: IconState.Planned,
  textOffset: [0, 1.8],
});
registerGuiIcon({
  key: 'act_defensivecirculincactif_symb_red',
  type: IconType.Symbol,
  category: AnnotationCategory.Actions,
  subcategory: AnnotationSubCategory.DefensiveActions,
  colors: [IconColor.Red],
  state: IconState.Active,
  textOffset: [0, 1.8],
});
registerGuiIcon({
  key: 'act_defensivecirculincprev_symb_red',
  type: IconType.Symbol,
  category: AnnotationCategory.Actions,
  subcategory: AnnotationSubCategory.DefensiveActions,
  colors: [IconColor.Red],
  state: IconState.Planned,
  textOffset: [0, 1.8],
});
registerGuiIcon({
  key: 'act_defensivecirculrpactif_symb_yellow',
  type: IconType.Symbol,
  category: AnnotationCategory.Actions,
  subcategory: AnnotationSubCategory.DefensiveActions,
  colors: [IconColor.Yellow],
  state: IconState.Active,
  textOffset: [0, 1.8],
});
registerGuiIcon({
  key: 'act_defensivecirculrpprev_symb_yellow',
  type: IconType.Symbol,
  category: AnnotationCategory.Actions,
  subcategory: AnnotationSubCategory.DefensiveActions,
  colors: [IconColor.Yellow],
  state: IconState.Planned,
  textOffset: [0, 1.8],
});
registerGuiIcon({
  key: 'act_actionsoffensivesactif_line_black_gui',
  type: IconType.Line,
  category: AnnotationCategory.Actions,
  subcategory: AnnotationSubCategory.OffensiveActions,
  colors: [IconColor.Black],
  defaultMapIcon: 'act_actionsoffensivesactif_line_black_map1',
  state: IconState.Active,
});
registerGuiIcon({
  key: 'act_actionsoffensivesprev_line_black_gui',
  type: IconType.Line,
  category: AnnotationCategory.Actions,
  subcategory: AnnotationSubCategory.OffensiveActions,
  colors: [IconColor.Black],
  defaultMapIcon: 'act_actionsoffensivesprev_line_black_map1',
  state: IconState.Planned,
});
registerGuiIcon({
  key: 'act_defenselineaireactif_line_black_gui',
  type: IconType.Line,
  category: AnnotationCategory.Actions,
  subcategory: AnnotationSubCategory.DefensiveActions,
  colors: [IconColor.Black],
  defaultMapIcon: 'act_defenselineaireactif_line_black_map1',
  state: IconState.Active,
});
registerGuiIcon({
  key: 'act_defenselineaireactif_line_blue_gui',
  type: IconType.Line,
  category: AnnotationCategory.Actions,
  subcategory: AnnotationSubCategory.DefensiveActions,
  colors: [IconColor.Blue],
  defaultMapIcon: 'act_defenselineaireactif_line_blue_map1',
  state: IconState.Active,
});
registerGuiIcon({
  key: 'act_defenselineaireactif_line_green_gui',
  type: IconType.Line,
  category: AnnotationCategory.Actions,
  subcategory: AnnotationSubCategory.DefensiveActions,
  colors: [IconColor.Green],
  defaultMapIcon: 'act_defenselineaireactif_line_green_map1',
  state: IconState.Active,
});
registerGuiIcon({
  key: 'act_defenselineaireactif_line_red_gui',
  type: IconType.Line,
  category: AnnotationCategory.Actions,
  subcategory: AnnotationSubCategory.DefensiveActions,
  colors: [IconColor.Red],
  defaultMapIcon: 'act_defenselineaireactif_line_red_map1',
  state: IconState.Active,
});
registerGuiIcon({
  key: 'act_defenselineaireactif_line_yellow_gui',
  type: IconType.Line,
  category: AnnotationCategory.Actions,
  subcategory: AnnotationSubCategory.DefensiveActions,
  colors: [IconColor.Yellow],
  defaultMapIcon: 'act_defenselineaireactif_line_yellow_map1',
  state: IconState.Active,
});
registerGuiIcon({
  key: 'act_defenselineaireprev_line_black_gui',
  type: IconType.Line,
  category: AnnotationCategory.Actions,
  subcategory: AnnotationSubCategory.DefensiveActions,
  colors: [IconColor.Black],
  defaultMapIcon: 'act_defenselineaireprev_line_black_map1',
  state: IconState.Planned,
});
registerGuiIcon({
  key: 'act_defenselineaireprev_line_blue_gui',
  type: IconType.Line,
  category: AnnotationCategory.Actions,
  subcategory: AnnotationSubCategory.DefensiveActions,
  colors: [IconColor.Blue],
  defaultMapIcon: 'act_defenselineaireprev_line_blue_map1',
  state: IconState.Planned,
});
registerGuiIcon({
  key: 'act_defenselineaireprev_line_green_gui',
  type: IconType.Line,
  category: AnnotationCategory.Actions,
  subcategory: AnnotationSubCategory.DefensiveActions,
  colors: [IconColor.Green],
  defaultMapIcon: 'act_defenselineaireprev_line_green_map1',
  state: IconState.Planned,
});
registerGuiIcon({
  key: 'act_defenselineaireprev_line_red_gui',
  type: IconType.Line,
  category: AnnotationCategory.Actions,
  subcategory: AnnotationSubCategory.DefensiveActions,
  colors: [IconColor.Red],
  defaultMapIcon: 'act_defenselineaireprev_line_red_map1',
  state: IconState.Planned,
});
registerGuiIcon({
  key: 'act_defenselineaireprev_line_yellow_gui',
  type: IconType.Line,
  category: AnnotationCategory.Actions,
  subcategory: AnnotationSubCategory.DefensiveActions,
  colors: [IconColor.Yellow],
  defaultMapIcon: 'act_defenselineaireprev_line_yellow_map1',
  state: IconState.Planned,
});
registerGuiIcon({
  key: 'act_largageabe_symb_blue',
  type: IconType.Symbol,
  category: AnnotationCategory.Actions,
  subcategory: AnnotationSubCategory.Airdrops,
  colors: [IconColor.Blue],
  textOffset: [0, 1],
});
registerGuiIcon({
  key: 'act_largageabe_symb_yellow',
  type: IconType.Symbol,
  category: AnnotationCategory.Actions,
  subcategory: AnnotationSubCategory.Airdrops,
  colors: [IconColor.Yellow],
  textOffset: [0, 1],
});
registerGuiIcon({
  key: 'act_largagehbe_symb_blue',
  type: IconType.Symbol,
  category: AnnotationCategory.Actions,
  subcategory: AnnotationSubCategory.Airdrops,
  colors: [IconColor.Blue],
  textOffset: [0, 1.5],
});
registerGuiIcon({
  key: 'act_largagehbe_symb_yellow',
  type: IconType.Symbol,
  category: AnnotationCategory.Actions,
  subcategory: AnnotationSubCategory.Airdrops,
  colors: [IconColor.Yellow],
  textOffset: [0, 1.5],
});

// Analyse
registerGuiIcon({
  key: 'ana_cheminementacces_line_black',
  type: IconType.Line,
  category: AnnotationCategory.Analyse,
  subcategory: AnnotationSubCategory.Pathways,
  colors: [IconColor.Black],
});
registerGuiIcon({
  key: 'ana_cheminementcirculationimpossible_line_black',
  type: IconType.Line,
  category: AnnotationCategory.Analyse,
  subcategory: AnnotationSubCategory.Pathways,
  colors: [IconColor.Black],
});
registerGuiIcon({
  key: 'ana_cheminementevolutionlimitee_line_black_map1',
  type: IconType.Line,
  category: AnnotationCategory.Analyse,
  subcategory: AnnotationSubCategory.Pathways,
  colors: [IconColor.Black],
});
registerGuiIcon({
  key: 'ana_cheminementrocade_line_black',
  type: IconType.Line,
  category: AnnotationCategory.Analyse,
  subcategory: AnnotationSubCategory.Pathways,
  colors: [IconColor.Black],
});
registerGuiIcon({
  key: 'ana_cheminementsensdecirculation_line_black_map1',
  type: IconType.Line,
  category: AnnotationCategory.Analyse,
  subcategory: AnnotationSubCategory.Pathways,
  colors: [IconColor.Black],
});
registerGuiIcon({
  key: 'ana_declivitefaible_symb_black',
  type: IconType.Symbol,
  category: AnnotationCategory.Analyse,
  subcategory: AnnotationSubCategory.Morphology,
  colors: [IconColor.Black],
});
registerGuiIcon({
  key: 'ana_decliviteforte_symb_black',
  type: IconType.Symbol,
  category: AnnotationCategory.Analyse,
  subcategory: AnnotationSubCategory.Morphology,
  colors: [IconColor.Black],
});
registerGuiIcon({
  key: 'ana_declivitemodere_symb_black',
  type: IconType.Symbol,
  category: AnnotationCategory.Analyse,
  subcategory: AnnotationSubCategory.Morphology,
  colors: [IconColor.Black],
});
registerGuiIcon({
  key: 'ana_pepointdetravitaillement_symb_blue',
  type: IconType.Symbol,
  category: AnnotationCategory.Analyse,
  subcategory: AnnotationSubCategory.WaterIntakes,
  colors: [IconColor.Blue],
  textOffset: [0, 1.8],
});
registerGuiIcon({
  key: 'ana_peprisedeaunonperenne_symb_blue',
  type: IconType.Symbol,
  category: AnnotationCategory.Analyse,
  subcategory: AnnotationSubCategory.WaterIntakes,
  colors: [IconColor.Blue],
  textOffset: [0, 1.8],
});
registerGuiIcon({
  key: 'ana_peprisedeauperenne_symb_blue',
  type: IconType.Symbol,
  category: AnnotationCategory.Analyse,
  subcategory: AnnotationSubCategory.WaterIntakes,
  colors: [IconColor.Blue],
  textOffset: [0, 1.8],
});
registerGuiIcon({
  key: 'ana_pssdpointsensible_symb_black',
  type: IconType.Symbol,
  category: AnnotationCategory.Analyse,
  subcategory: AnnotationSubCategory.SensitivePoints,
  colors: [IconColor.Black],
  textOffset: [0, 2.1],
});
registerGuiIcon({
  key: 'ana_pssdpointsensible_symb_blue',
  type: IconType.Symbol,
  category: AnnotationCategory.Analyse,
  subcategory: AnnotationSubCategory.SensitivePoints,
  colors: [IconColor.Blue],
  textOffset: [0, 2.1],
});
registerGuiIcon({
  key: 'ana_pssdpointsensible_symb_green',
  type: IconType.Symbol,
  category: AnnotationCategory.Analyse,
  subcategory: AnnotationSubCategory.SensitivePoints,
  colors: [IconColor.Green],
  textOffset: [0, 2.1],
});
registerGuiIcon({
  key: 'ana_pssdpointsensible_symb_red',
  type: IconType.Symbol,
  category: AnnotationCategory.Analyse,
  subcategory: AnnotationSubCategory.SensitivePoints,
  colors: [IconColor.Red],
  textOffset: [0, 2.1],
});
registerGuiIcon({
  key: 'ana_pssdpointsensible_symb_yellow',
  type: IconType.Symbol,
  category: AnnotationCategory.Analyse,
  subcategory: AnnotationSubCategory.SensitivePoints,
  colors: [IconColor.Yellow],
  textOffset: [0, 2.1],
});
registerGuiIcon({
  key: 'ana_pssdsourcededanger_symb_black',
  type: IconType.Symbol,
  category: AnnotationCategory.Analyse,
  subcategory: AnnotationSubCategory.SourcesOfDanger,
  colors: [IconColor.Black],
  textOffset: [0, 2.1],
});
registerGuiIcon({
  key: 'ana_pssdsourcededanger_symb_blue',
  type: IconType.Symbol,
  category: AnnotationCategory.Analyse,
  subcategory: AnnotationSubCategory.SourcesOfDanger,
  colors: [IconColor.Blue],
  textOffset: [0, 2.1],
});
registerGuiIcon({
  key: 'ana_pssdsourcededanger_symb_green',
  type: IconType.Symbol,
  category: AnnotationCategory.Analyse,
  subcategory: AnnotationSubCategory.SourcesOfDanger,
  colors: [IconColor.Green],
  textOffset: [0, 2.1],
});
registerGuiIcon({
  key: 'ana_pssdsourcededanger_symb_red',
  type: IconType.Symbol,
  category: AnnotationCategory.Analyse,
  subcategory: AnnotationSubCategory.SourcesOfDanger,
  colors: [IconColor.Red],
  textOffset: [0, 2.1],
});
registerGuiIcon({
  key: 'ana_pssdsourcededanger_symb_yellow',
  type: IconType.Symbol,
  category: AnnotationCategory.Analyse,
  subcategory: AnnotationSubCategory.SourcesOfDanger,
  colors: [IconColor.Yellow],
  textOffset: [0, 2.1],
});

// Resources
registerGuiIcon({
  key: 'moy_aerienactif_symb_black',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.AirResources,
  colors: [IconColor.Black],
  state: IconState.Active,
  textOffset: [0, 2],
});
registerGuiIcon({
  key: 'moy_aerienactif_symb_blue',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.AirResources,
  colors: [IconColor.Blue],
  state: IconState.Active,
  textOffset: [0, 2],
});
registerGuiIcon({
  key: 'moy_aerienactif_symb_green',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.AirResources,
  colors: [IconColor.Green],
  state: IconState.Active,
  textOffset: [0, 2],
});
registerGuiIcon({
  key: 'moy_aerienactif_symb_purple',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.AirResources,
  colors: [IconColor.Purple],
  state: IconState.Active,
  textOffset: [0, 2],
});
registerGuiIcon({
  key: 'moy_aerienactif_symb_red',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.AirResources,
  colors: [IconColor.Red],
  state: IconState.Active,
  textOffset: [0, 2],
});
registerGuiIcon({
  key: 'moy_aerienactif_symb_yellow',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.AirResources,
  colors: [IconColor.Yellow],
  state: IconState.Active,
  textOffset: [0, 2],
});
registerGuiIcon({
  key: 'moy_aerienprev_symb_black',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.AirResources,
  colors: [IconColor.Black],
  state: IconState.Planned,
  textOffset: [0, 2],
});
registerGuiIcon({
  key: 'moy_aerienprev_symb_blue',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.AirResources,
  colors: [IconColor.Blue],
  state: IconState.Planned,
  textOffset: [0, 2],
});
registerGuiIcon({
  key: 'moy_aerienprev_symb_green',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.AirResources,
  colors: [IconColor.Green],
  state: IconState.Planned,
  textOffset: [0, 2],
});
registerGuiIcon({
  key: 'moy_aerienprev_symb_purple',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.AirResources,
  colors: [IconColor.Purple],
  state: IconState.Planned,
  textOffset: [0, 2],
});
registerGuiIcon({
  key: 'moy_aerienprev_symb_red',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.AirResources,
  colors: [IconColor.Red],
  state: IconState.Planned,
  textOffset: [0, 2],
});
registerGuiIcon({
  key: 'moy_aerienprev_symb_yellow',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.AirResources,
  colors: [IconColor.Yellow],
  state: IconState.Planned,
  textOffset: [0, 2],
});
registerGuiIcon({
  key: 'moy_commandementpcdecolonneactif_symb_purple',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.CommandPost,
  colors: [IconColor.Purple],
  state: IconState.Active,
  textOffset: [0, -0.2],
});
registerGuiIcon({
  key: 'moy_commandementpcdecolonneprev_symb_purple',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.CommandPost,
  colors: [IconColor.Purple],
  state: IconState.Planned,
  textOffset: [0, -0.2],
});
registerGuiIcon({
  key: 'moy_commandementpcdesiteactif_symb_purple',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.CommandPost,
  colors: [IconColor.Purple],
  state: IconState.Active,
  textOffset: [0, -0.2],
});
registerGuiIcon({
  key: 'moy_commandementpcdesiteprev_symb_purple',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.CommandPost,
  colors: [IconColor.Purple],
  state: IconState.Planned,
  textOffset: [0, -0.2],
});
registerGuiIcon({
  key: 'moy_commandementpointdetransitactif_symb_purple',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.TransitPoint,
  colors: [IconColor.Purple],
  state: IconState.Active,
});
registerGuiIcon({
  key: 'moy_commandementpointdetransitprev_symb_purple',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.TransitPoint,
  colors: [IconColor.Purple],
  state: IconState.Planned,
});
registerGuiIcon({
  key: 'moy_mitnonspactif_symb_black',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.NonFFLandResources,
  trigrams: ['CRS', 'EDF', 'GDF', 'GEND', 'PN', 'SAMU31'],
  colors: [IconColor.Black],
  state: IconState.Active,
});
registerGuiIcon({
  key: 'moy_mitnonspactif_symb_blue',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.NonFFLandResources,
  trigrams: ['CRS', 'EDF', 'GDF', 'GEND', 'PN', 'SAMU31'],
  colors: [IconColor.Blue],
  state: IconState.Active,
});
registerGuiIcon({
  key: 'moy_mitnonspactif_symb_green',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.NonFFLandResources,
  trigrams: ['CRS', 'EDF', 'GDF', 'GEND', 'PN', 'SAMU31'],
  colors: [IconColor.Green],
  state: IconState.Active,
});
registerGuiIcon({
  key: 'moy_mitnonspactif_symb_red',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.NonFFLandResources,
  trigrams: ['CRS', 'EDF', 'GDF', 'GEND', 'PN', 'SAMU31'],
  colors: [IconColor.Red],
  state: IconState.Active,
});
registerGuiIcon({
  key: 'moy_mitnonspactif_symb_yellow',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.NonFFLandResources,
  trigrams: ['CRS', 'EDF', 'GDF', 'GEND', 'PN', 'SAMU31'],
  colors: [IconColor.Yellow],
  state: IconState.Active,
});
registerGuiIcon({
  key: 'moy_mitnonspprev_symb_black',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.NonFFLandResources,
  trigrams: ['CRS', 'EDF', 'GDF', 'GEND', 'PN', 'SAMU31'],
  colors: [IconColor.Black],
  state: IconState.Planned,
});
registerGuiIcon({
  key: 'moy_mitnonspprev_symb_blue',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.NonFFLandResources,
  trigrams: ['CRS', 'EDF', 'GDF', 'GEND', 'PN', 'SAMU31'],
  colors: [IconColor.Blue],
  state: IconState.Planned,
});
registerGuiIcon({
  key: 'moy_mitnonspprev_symb_green',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.NonFFLandResources,
  trigrams: ['CRS', 'EDF', 'GDF', 'GEND', 'PN', 'SAMU31'],
  colors: [IconColor.Green],
  state: IconState.Planned,
});
registerGuiIcon({
  key: 'moy_mitnonspprev_symb_red',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.NonFFLandResources,
  trigrams: ['CRS', 'EDF', 'GDF', 'GEND', 'PN', 'SAMU31'],
  colors: [IconColor.Red],
  state: IconState.Planned,
});
registerGuiIcon({
  key: 'moy_mitnonspprev_symb_yellow',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.NonFFLandResources,
  trigrams: ['CRS', 'EDF', 'GDF', 'GEND', 'PN', 'SAMU31'],
  colors: [IconColor.Yellow],
  state: IconState.Planned,
});
registerGuiIcon({
  key: 'moy_mitspcolonneseulactif_symb_black',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.FFLandResources,
  trigrams: ['VID', 'VTP', 'VTU'],
  colors: [IconColor.Black],
  state: IconState.Active,
  textOffset: [0, 0.3],
});
registerGuiIcon({
  key: 'moy_mitspcolonneseulactif_symb_blue',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.FFLandResources,
  trigrams: ['CCGC', 'CDEHR', 'ALIM'],
  colors: [IconColor.Blue],
  state: IconState.Active,
  textOffset: [0, 0.3],
});
registerGuiIcon({
  key: 'moy_mitspcolonneseulactif_symb_green',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.FFLandResources,
  trigrams: ['CAMA', 'SAP', 'PMA', 'VML', 'VSAV', 'VSR'],
  colors: [IconColor.Green],
  state: IconState.Active,
  textOffset: [0, 0.3],
});
registerGuiIcon({
  key: 'moy_mitspcolonneseulactif_symb_red',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.FFLandResources,
  trigrams: ['CCF', 'EAP', 'FPTL', 'FPT', 'FDF', 'INC', 'LIF', 'VAR', 'VEV', 'VGD'],
  colors: [IconColor.Red],
  state: IconState.Active,
  textOffset: [0, 0.3],
});
registerGuiIcon({
  key: 'moy_mitspcolonneseulactif_symb_yellow',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.FFLandResources,
  trigrams: ['CPOL', 'GRIMP', 'INOND', 'RAD', 'RCH', 'SD', 'VCYNO', 'VICH', 'VLD', 'VLP', 'VSD'],
  colors: [IconColor.Yellow],
  state: IconState.Active,
  textOffset: [0, 0.3],
});
registerGuiIcon({
  key: 'moy_mitspcolonneseulprev_symb_black',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.FFLandResources,
  trigrams: ['VID', 'VTP', 'VTU'],
  colors: [IconColor.Black],
  state: IconState.Planned,
  textOffset: [0, 0.3],
});
registerGuiIcon({
  key: 'moy_mitspcolonneseulprev_symb_blue',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.FFLandResources,
  trigrams: ['CCGC', 'CDEHR', 'ALIM'],
  colors: [IconColor.Blue],
  state: IconState.Planned,
  textOffset: [0, 0.3],
});
registerGuiIcon({
  key: 'moy_mitspcolonneseulprev_symb_green',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.FFLandResources,
  trigrams: ['CAMA', 'SAP', 'PMA', 'VML', 'VSAV', 'VSR'],
  colors: [IconColor.Green],
  state: IconState.Planned,
  textOffset: [0, 0.3],
});
registerGuiIcon({
  key: 'moy_mitspcolonneseulprev_symb_red',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.FFLandResources,
  trigrams: ['CCF', 'EAP', 'FPTL', 'FPT', 'FDF', 'INC', 'LIF', 'VAR', 'VEV', 'VGD'],
  colors: [IconColor.Red],
  state: IconState.Planned,
  textOffset: [0, 0.3],
});
registerGuiIcon({
  key: 'moy_mitspcolonneseulprev_symb_yellow',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.FFLandResources,
  trigrams: ['CPOL', 'GRIMP', 'INOND', 'RAD', 'RCH', 'SD', 'VCYNO', 'VICH', 'VLD', 'VLP', 'VSD'],
  colors: [IconColor.Yellow],
  state: IconState.Planned,
  textOffset: [0, 0.3],
});
registerGuiIcon({
  key: 'moy_mitspgroupeseulactif_symb_black',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.FFLandResources,
  trigrams: ['VID', 'VTP', 'VTU'],
  colors: [IconColor.Black],
  state: IconState.Active,
  textOffset: [0, 0.3],
});
registerGuiIcon({
  key: 'moy_mitspgroupeseulactif_symb_blue',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.FFLandResources,
  trigrams: ['CCGC', 'CDEHR', 'ALIM'],
  colors: [IconColor.Blue],
  state: IconState.Active,
  textOffset: [0, 0.3],
});
registerGuiIcon({
  key: 'moy_mitspgroupeseulactif_symb_green',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.FFLandResources,
  trigrams: ['CAMA', 'SAP', 'PMA', 'VML', 'VSAV', 'VSR'],
  colors: [IconColor.Green],
  state: IconState.Active,
  textOffset: [0, 0.3],
});
registerGuiIcon({
  key: 'moy_mitspgroupeseulactif_symb_red',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.FFLandResources,
  trigrams: ['CCF', 'EAP', 'FPTL', 'FPT', 'FDF', 'INC', 'LIF', 'VAR', 'VEV', 'VGD'],
  colors: [IconColor.Red],
  state: IconState.Active,
  textOffset: [0, 0.3],
});
registerGuiIcon({
  key: 'moy_mitspgroupeseulactif_symb_yellow',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.FFLandResources,
  trigrams: ['CPOL', 'GRIMP', 'INOND', 'RAD', 'RCH', 'SD', 'VCYNO', 'VICH', 'VLD', 'VLP', 'VSD'],
  colors: [IconColor.Yellow],
  state: IconState.Active,
  textOffset: [0, 0.3],
});
registerGuiIcon({
  key: 'moy_mitspgroupeseulprev_symb_black',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.FFLandResources,
  trigrams: ['VID', 'VTP', 'VTU'],
  colors: [IconColor.Black],
  state: IconState.Planned,
  textOffset: [0, 0.3],
});
registerGuiIcon({
  key: 'moy_mitspgroupeseulprev_symb_blue',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.FFLandResources,
  trigrams: ['CCGC', 'CDEHR', 'ALIM'],
  colors: [IconColor.Blue],
  state: IconState.Planned,
  textOffset: [0, 0.3],
});
registerGuiIcon({
  key: 'moy_mitspgroupeseulprev_symb_green',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.FFLandResources,
  trigrams: ['CAMA', 'SAP', 'PMA', 'VML', 'VSAV', 'VSR'],
  colors: [IconColor.Green],
  state: IconState.Planned,
  textOffset: [0, 0.3],
});
registerGuiIcon({
  key: 'moy_mitspgroupeseulprev_symb_red',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.FFLandResources,
  trigrams: ['CCF', 'EAP', 'FPTL', 'FPT', 'FDF', 'INC', 'LIF', 'VAR', 'VEV', 'VGD'],
  colors: [IconColor.Red],
  state: IconState.Planned,
  textOffset: [0, 0.3],
});
registerGuiIcon({
  key: 'moy_mitspgroupeseulprev_symb_yellow',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.FFLandResources,
  trigrams: ['CPOL', 'GRIMP', 'INOND', 'RAD', 'RCH', 'SD', 'VCYNO', 'VICH', 'VLD', 'VLP', 'VSD'],
  colors: [IconColor.Yellow],
  state: IconState.Planned,
  textOffset: [0, 0.3],
});
registerGuiIcon({
  key: 'moy_mitspvehiculeseulactif_symb_black',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.FFLandResources,
  trigrams: ['VID', 'VTP', 'VTU'],
  colors: [IconColor.Black],
  state: IconState.Active,
  textOffset: [0, 0.3],
});
registerGuiIcon({
  key: 'moy_mitspvehiculeseulactif_symb_blue',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.FFLandResources,
  trigrams: ['CCGC', 'CDEHR', 'ALIM'],
  colors: [IconColor.Blue],
  state: IconState.Active,
  textOffset: [0, 0.3],
});
registerGuiIcon({
  key: 'moy_mitspvehiculeseulactif_symb_green',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.FFLandResources,
  trigrams: ['CAMA', 'SAP', 'PMA', 'VML', 'VSAV', 'VSR'],
  colors: [IconColor.Green],
  state: IconState.Active,
  textOffset: [0, 0.3],
});
registerGuiIcon({
  key: 'moy_mitspvehiculeseulactif_symb_red',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.FFLandResources,
  trigrams: ['CCF', 'EAP', 'FPTL', 'FPT', 'FDF', 'INC', 'LIF', 'VAR', 'VEV', 'VGD'],
  colors: [IconColor.Red],
  state: IconState.Active,
  textOffset: [0, 0.3],
});
registerGuiIcon({
  key: 'moy_mitspvehiculeseulactif_symb_yellow',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.FFLandResources,
  trigrams: ['CPOL', 'GRIMP', 'INOND', 'RAD', 'RCH', 'SD', 'VCYNO', 'VICH', 'VLD', 'VLP', 'VSD'],
  colors: [IconColor.Yellow],
  state: IconState.Active,
  textOffset: [0, 0.3],
});
registerGuiIcon({
  key: 'moy_mitspvehiculeseulprev_symb_black',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.FFLandResources,
  trigrams: ['VID', 'VTP', 'VTU'],
  colors: [IconColor.Black],
  state: IconState.Planned,
  textOffset: [0, 0.3],
});
registerGuiIcon({
  key: 'moy_mitspvehiculeseulprev_symb_blue',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.FFLandResources,
  trigrams: ['CCGC', 'CDEHR', 'ALIM'],
  colors: [IconColor.Blue],
  state: IconState.Planned,
  textOffset: [0, 0.3],
});
registerGuiIcon({
  key: 'moy_mitspvehiculeseulprev_symb_green',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.FFLandResources,
  trigrams: ['CAMA', 'SAP', 'PMA', 'VML', 'VSAV', 'VSR'],
  colors: [IconColor.Green],
  state: IconState.Planned,
  textOffset: [0, 0.3],
});
registerGuiIcon({
  key: 'moy_mitspvehiculeseulprev_symb_red',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.FFLandResources,
  trigrams: ['CCF', 'EAP', 'FPTL', 'FPT', 'FDF', 'INC', 'LIF', 'VAR', 'VEV', 'VGD'],
  colors: [IconColor.Red],
  state: IconState.Planned,
  textOffset: [0, 0.3],
});
registerGuiIcon({
  key: 'moy_mitspvehiculeseulprev_symb_yellow',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.FFLandResources,
  trigrams: ['CPOL', 'GRIMP', 'INOND', 'RAD', 'RCH', 'SD', 'VCYNO', 'VICH', 'VLD', 'VLP', 'VSD'],
  colors: [IconColor.Yellow],
  state: IconState.Planned,
  textOffset: [0, 0.3],
});
registerGuiIcon({
  key: 'moy_ressourceactif_symb_black',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.ResourcesAndLogistic,
  trigrams: [
    'P',
    'H',
    'PRV',
    'PMA',
    'CH',
    'MOR',
    'MED',
    'PSY',
    'VET',
    'PHAR',
    'CRM',
    'REST',
    'MEC',
    'OIL',
    'ELEC',
    'EMUL',
  ],
  colors: [IconColor.Black],
  state: IconState.Active,
  textOffset: [0, 0.2],
});
registerGuiIcon({
  key: 'moy_ressourceactif_symb_blue',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.ResourcesAndLogistic,
  trigrams: [
    'P',
    'H',
    'PRV',
    'PMA',
    'CH',
    'MOR',
    'MED',
    'PSY',
    'VET',
    'PHAR',
    'CRM',
    'REST',
    'MEC',
    'OIL',
    'ELEC',
    'EMUL',
  ],
  colors: [IconColor.Blue],
  state: IconState.Active,
  textOffset: [0, 0.2],
});
registerGuiIcon({
  key: 'moy_ressourceactif_symb_green',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.ResourcesAndLogistic,
  trigrams: [
    'P',
    'H',
    'PRV',
    'PMA',
    'CH',
    'MOR',
    'MED',
    'PSY',
    'VET',
    'PHAR',
    'CRM',
    'REST',
    'MEC',
    'OIL',
    'ELEC',
    'EMUL',
  ],
  colors: [IconColor.Green],
  state: IconState.Active,
  textOffset: [0, 0.2],
});
registerGuiIcon({
  key: 'moy_ressourceactif_symb_red',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.ResourcesAndLogistic,
  trigrams: [
    'P',
    'H',
    'PRV',
    'PMA',
    'CH',
    'MOR',
    'MED',
    'PSY',
    'VET',
    'PHAR',
    'CRM',
    'REST',
    'MEC',
    'OIL',
    'ELEC',
    'EMUL',
  ],
  colors: [IconColor.Red],
  state: IconState.Active,
  textOffset: [0, 0.2],
});
registerGuiIcon({
  key: 'moy_ressourceactif_symb_yellow',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.ResourcesAndLogistic,
  trigrams: [
    'P',
    'H',
    'PRV',
    'PMA',
    'CH',
    'MOR',
    'MED',
    'PSY',
    'VET',
    'PHAR',
    'CRM',
    'REST',
    'MEC',
    'OIL',
    'ELEC',
    'EMUL',
  ],
  colors: [IconColor.Yellow],
  state: IconState.Active,
  textOffset: [0, 0.2],
});
registerGuiIcon({
  key: 'moy_ressourceprev_symb_black',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.ResourcesAndLogistic,
  trigrams: [
    'P',
    'H',
    'PRV',
    'PMA',
    'CH',
    'MOR',
    'MED',
    'PSY',
    'VET',
    'PHAR',
    'CRM',
    'REST',
    'MEC',
    'OIL',
    'ELEC',
    'EMUL',
  ],
  colors: [IconColor.Black],
  state: IconState.Planned,
  textOffset: [0, 0.2],
});
registerGuiIcon({
  key: 'moy_ressourceprev_symb_blue',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.ResourcesAndLogistic,
  trigrams: [
    'P',
    'H',
    'PRV',
    'PMA',
    'CH',
    'MOR',
    'MED',
    'PSY',
    'VET',
    'PHAR',
    'CRM',
    'REST',
    'MEC',
    'OIL',
    'ELEC',
    'EMUL',
  ],
  colors: [IconColor.Blue],
  state: IconState.Planned,
  textOffset: [0, 0.2],
});
registerGuiIcon({
  key: 'moy_ressourceprev_symb_green',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.ResourcesAndLogistic,
  trigrams: [
    'P',
    'H',
    'PRV',
    'PMA',
    'CH',
    'MOR',
    'MED',
    'PSY',
    'VET',
    'PHAR',
    'CRM',
    'REST',
    'MEC',
    'OIL',
    'ELEC',
    'EMUL',
  ],
  colors: [IconColor.Green],
  state: IconState.Planned,
  textOffset: [0, 0.2],
});
registerGuiIcon({
  key: 'moy_ressourceprev_symb_red',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.ResourcesAndLogistic,
  trigrams: [
    'P',
    'H',
    'PRV',
    'PMA',
    'CH',
    'MOR',
    'MED',
    'PSY',
    'VET',
    'PHAR',
    'CRM',
    'REST',
    'MEC',
    'OIL',
    'ELEC',
    'EMUL',
  ],
  colors: [IconColor.Red],
  state: IconState.Planned,
  textOffset: [0, 0.2],
});
registerGuiIcon({
  key: 'moy_ressourceprev_symb_yellow',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.ResourcesAndLogistic,
  trigrams: [
    'P',
    'H',
    'PRV',
    'PMA',
    'CH',
    'MOR',
    'MED',
    'PSY',
    'VET',
    'PHAR',
    'CRM',
    'REST',
    'MEC',
    'OIL',
    'ELEC',
    'EMUL',
  ],
  colors: [IconColor.Yellow],
  state: IconState.Planned,
  textOffset: [0, 0.2],
});
registerGuiIcon({
  key: 'moy_ressourcesmoyensdugenieactif_symb_black',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.ResourcesAndLogistic,
  trigrams: [
    'P',
    'H',
    'PRV',
    'PMA',
    'CH',
    'MOR',
    'MED',
    'PSY',
    'VET',
    'PHAR',
    'CRM',
    'REST',
    'MEC',
    'OIL',
    'ELEC',
    'EMUL',
  ],
  colors: [IconColor.Black],
  state: IconState.Active,
  textOffset: [0, 2],
});
registerGuiIcon({
  key: 'moy_ressourcesmoyensdugenieprev_symb_black',
  type: IconType.Symbol,
  category: AnnotationCategory.Resources,
  subcategory: AnnotationSubCategory.ResourcesAndLogistic,
  trigrams: [
    'P',
    'H',
    'PRV',
    'PMA',
    'CH',
    'MOR',
    'MED',
    'PSY',
    'VET',
    'PHAR',
    'CRM',
    'REST',
    'MEC',
    'OIL',
    'ELEC',
    'EMUL',
  ],
  colors: [IconColor.Black],
  state: IconState.Planned,
  textOffset: [0, 2],
});

// Operational
registerGuiIcon({
  key: 'org_secteurchefdecolonne_symb_purple',
  type: IconType.Symbol,
  category: AnnotationCategory.Operational,
  subcategory: AnnotationSubCategory.GeographicSectorization,
  colors: [IconColor.Purple],
  textOffset: [0, 1],
});
registerGuiIcon({
  key: 'org_secteurchefdegroupe_symb_purple',
  type: IconType.Symbol,
  category: AnnotationCategory.Operational,
  subcategory: AnnotationSubCategory.GeographicSectorization,
  colors: [IconColor.Purple],
  textOffset: [0, 1],
});
registerGuiIcon({
  key: 'org_secteurchefdesite_symb_purple',
  type: IconType.Symbol,
  category: AnnotationCategory.Operational,
  subcategory: AnnotationSubCategory.GeographicSectorization,
  colors: [IconColor.Purple],
  textOffset: [0, 1],
});
registerGuiIcon({
  key: 'org_sectorisationfonctionnelle_symb_purpleblue',
  type: IconType.Symbol,
  category: AnnotationCategory.Operational,
  subcategory: AnnotationSubCategory.FunctionalSectorization,
  specialType: SpecialType.Sector,
  colors: [IconColor.Purple, IconColor.Blue],
  textOffset: [0, 2],
});
registerGuiIcon({
  key: 'org_sectorisationfonctionnelle_symb_purplegreen',
  type: IconType.Symbol,
  category: AnnotationCategory.Operational,
  subcategory: AnnotationSubCategory.FunctionalSectorization,
  specialType: SpecialType.Sector,
  colors: [IconColor.Purple, IconColor.Green],
  textOffset: [0, 2],
});
registerGuiIcon({
  key: 'org_sectorisationfonctionnelle_symb_purplered',
  type: IconType.Symbol,
  category: AnnotationCategory.Operational,
  subcategory: AnnotationSubCategory.FunctionalSectorization,
  specialType: SpecialType.Sector,
  colors: [IconColor.Purple, IconColor.Red],
  textOffset: [0, 2],
});
registerGuiIcon({
  key: 'org_sectorisationfonctionnelle_symb_purpleyellow',
  type: IconType.Symbol,
  category: AnnotationCategory.Operational,
  subcategory: AnnotationSubCategory.FunctionalSectorization,
  specialType: SpecialType.Sector,
  colors: [IconColor.Purple, IconColor.Yellow],
  textOffset: [0, 2],
});
registerGuiIcon({
  key: 'org_sectorisationgeographique_line_purple_gui',
  type: IconType.Line,
  category: AnnotationCategory.Operational,
  subcategory: AnnotationSubCategory.GeographicSectorization,
  specialType: SpecialType.Sector,
  colors: [IconColor.Purple],
  defaultMapIcon: 'org_sectorisationgeographique_line_purple_map',
});

// Sinisters
registerGuiIcon({
  key: 'sin_czacentredaction2_symb_black',
  type: IconType.Symbol,
  category: AnnotationCategory.Sinisters,
  subcategory: AnnotationSubCategory.ActionAreas,
  colors: [IconColor.Black],
  textOffset: [0, 1.6],
});
registerGuiIcon({
  key: 'sin_czacentredaction2_symb_blue',
  type: IconType.Symbol,
  category: AnnotationCategory.Sinisters,
  subcategory: AnnotationSubCategory.ActionAreas,
  colors: [IconColor.Blue],
  textOffset: [0, 1.6],
});
registerGuiIcon({
  key: 'sin_czacentredaction2_symb_green',
  type: IconType.Symbol,
  category: AnnotationCategory.Sinisters,
  subcategory: AnnotationSubCategory.ActionAreas,
  colors: [IconColor.Green],
  textOffset: [0, 1.6],
});
registerGuiIcon({
  key: 'sin_czacentredaction2_symb_red',
  type: IconType.Symbol,
  category: AnnotationCategory.Sinisters,
  subcategory: AnnotationSubCategory.ActionAreas,
  colors: [IconColor.Red],
  textOffset: [0, 1.6],
});
registerGuiIcon({
  key: 'sin_czacentredaction2_symb_yellow',
  type: IconType.Symbol,
  category: AnnotationCategory.Sinisters,
  subcategory: AnnotationSubCategory.ActionAreas,
  colors: [IconColor.Yellow],
  textOffset: [0, 1.6],
});
registerGuiIcon({
  key: 'sin_czacentredaction_symb_black',
  type: IconType.Symbol,
  category: AnnotationCategory.Sinisters,
  subcategory: AnnotationSubCategory.ActionAreas,
  colors: [IconColor.Black],
  textOffset: [0, 1.8],
});
registerGuiIcon({
  key: 'sin_czacentredaction_symb_blue',
  type: IconType.Symbol,
  category: AnnotationCategory.Sinisters,
  subcategory: AnnotationSubCategory.ActionAreas,
  colors: [IconColor.Blue],
  textOffset: [0, 1.8],
});
registerGuiIcon({
  key: 'sin_czacentredaction_symb_green',
  type: IconType.Symbol,
  category: AnnotationCategory.Sinisters,
  subcategory: AnnotationSubCategory.ActionAreas,
  colors: [IconColor.Green],
  textOffset: [0, 1.8],
});
registerGuiIcon({
  key: 'sin_czacentredaction_symb_red',
  type: IconType.Symbol,
  category: AnnotationCategory.Sinisters,
  subcategory: AnnotationSubCategory.ActionAreas,
  colors: [IconColor.Red],
  textOffset: [0, 1.8],
});
registerGuiIcon({
  key: 'sin_czacentredaction_symb_yellow',
  type: IconType.Symbol,
  category: AnnotationCategory.Sinisters,
  subcategory: AnnotationSubCategory.ActionAreas,
  colors: [IconColor.Yellow],
  textOffset: [0, 1.8],
});
registerGuiIcon({
  key: 'sin_czazonedaction_symb_black',
  type: IconType.Symbol,
  category: AnnotationCategory.Sinisters,
  subcategory: AnnotationSubCategory.ActionAreas,
  colors: [IconColor.Black],
  textOffset: [0, 1.6],
});
registerGuiIcon({
  key: 'sin_czazonedaction_symb_blue',
  type: IconType.Symbol,
  category: AnnotationCategory.Sinisters,
  subcategory: AnnotationSubCategory.ActionAreas,
  colors: [IconColor.Blue],
  textOffset: [0, 1.6],
});
registerGuiIcon({
  key: 'sin_czazonedaction_symb_green',
  type: IconType.Symbol,
  category: AnnotationCategory.Sinisters,
  subcategory: AnnotationSubCategory.ActionAreas,
  colors: [IconColor.Green],
  textOffset: [0, 1.6],
});
registerGuiIcon({
  key: 'sin_czazonedaction_symb_red',
  type: IconType.Symbol,
  category: AnnotationCategory.Sinisters,
  subcategory: AnnotationSubCategory.ActionAreas,
  colors: [IconColor.Red],
  textOffset: [0, 1.6],
});
registerGuiIcon({
  key: 'sin_czazonedaction_symb_yellow',
  type: IconType.Symbol,
  category: AnnotationCategory.Sinisters,
  subcategory: AnnotationSubCategory.ActionAreas,
  colors: [IconColor.Yellow],
  textOffset: [0, 1.6],
});

const MapIcons = {
  act_actionsoffensivesactif_line_black_map1: {
    guiIcon: 'act_actionsoffensivesactif_line_black_gui',
    reversedIcon: 'act_actionsoffensivesactif_line_black_map2',
  },
  act_actionsoffensivesactif_line_black_map2: {
    guiIcon: 'act_actionsoffensivesactif_line_black_gui',
    reversedIcon: 'act_actionsoffensivesactif_line_black_map1',
  },
  act_actionsoffensivesprev_line_black_map1: {
    guiIcon: 'act_actionsoffensivesprev_line_black_gui',
    reversedIcon: 'act_actionsoffensivesprev_line_black_map2',
  },
  act_actionsoffensivesprev_line_black_map2: {
    guiIcon: 'act_actionsoffensivesprev_line_black_gui',
    reversedIcon: 'act_actionsoffensivesprev_line_black_map1',
  },
  act_defenselineaireactif_line_black_map1: {
    guiIcon: 'act_defenselineaireactif_line_black_gui',
    reversedIcon: 'act_defenselineaireactif_line_black_map2',
  },
  act_defenselineaireactif_line_black_map2: {
    guiIcon: 'act_defenselineaireactif_line_black_gui',
    reversedIcon: 'act_defenselineaireactif_line_black_map1',
  },
  act_defenselineaireactif_line_blue_map1: {
    guiIcon: 'act_defenselineaireactif_line_blue_gui',
    reversedIcon: 'act_defenselineaireactif_line_blue_map2',
  },
  act_defenselineaireactif_line_blue_map2: {
    guiIcon: 'act_defenselineaireactif_line_blue_gui',
    reversedIcon: 'act_defenselineaireactif_line_blue_map1',
  },
  act_defenselineaireactif_line_green_map1: {
    guiIcon: 'act_defenselineaireactif_line_green_gui',
    reversedIcon: 'act_defenselineaireactif_line_green_map2',
  },
  act_defenselineaireactif_line_green_map2: {
    guiIcon: 'act_defenselineaireactif_line_green_gui',
    reversedIcon: 'act_defenselineaireactif_line_green_map1',
  },
  act_defenselineaireactif_line_red_map1: {
    guiIcon: 'act_defenselineaireactif_line_red_gui',
    reversedIcon: 'act_defenselineaireactif_line_red_map2',
  },
  act_defenselineaireactif_line_red_map2: {
    guiIcon: 'act_defenselineaireactif_line_red_gui',
    reversedIcon: 'act_defenselineaireactif_line_red_map1',
  },
  act_defenselineaireactif_line_yellow_map1: {
    guiIcon: 'act_defenselineaireactif_line_yellow_gui',
    reversedIcon: 'act_defenselineaireactif_line_yellow_map2',
  },
  act_defenselineaireactif_line_yellow_map2: {
    guiIcon: 'act_defenselineaireactif_line_yellow_gui',
    reversedIcon: 'act_defenselineaireactif_line_yellow_map1',
  },
  act_defenselineaireprev_line_black_map1: {
    guiIcon: 'act_defenselineaireprev_line_black_gui',
    reversedIcon: 'act_defenselineaireprev_line_black_map2',
  },
  act_defenselineaireprev_line_black_map2: {
    guiIcon: 'act_defenselineaireprev_line_black_gui',
    reversedIcon: 'act_defenselineaireprev_line_black_map1',
  },
  act_defenselineaireprev_line_blue_map1: {
    guiIcon: 'act_defenselineaireprev_line_blue_gui',
    reversedIcon: 'act_defenselineaireprev_line_blue_map2',
  },
  act_defenselineaireprev_line_blue_map2: {
    guiIcon: 'act_defenselineaireprev_line_blue_gui',
    reversedIcon: 'act_defenselineaireprev_line_blue_map1',
  },
  act_defenselineaireprev_line_green_map1: {
    guiIcon: 'act_defenselineaireprev_line_green_gui',
    reversedIcon: 'act_defenselineaireprev_line_green_map2',
  },
  act_defenselineaireprev_line_green_map2: {
    guiIcon: 'act_defenselineaireprev_line_green_gui',
    reversedIcon: 'act_defenselineaireprev_line_green_map1',
  },
  act_defenselineaireprev_line_red_map1: {
    guiIcon: 'act_defenselineaireprev_line_red_gui',
    reversedIcon: 'act_defenselineaireprev_line_red_map2',
  },
  act_defenselineaireprev_line_red_map2: {
    guiIcon: 'act_defenselineaireprev_line_red_gui',
    reversedIcon: 'act_defenselineaireprev_line_red_map1',
  },
  act_defenselineaireprev_line_yellow_map1: {
    guiIcon: 'act_defenselineaireprev_line_yellow_gui',
    reversedIcon: 'act_defenselineaireprev_line_yellow_map2',
  },
  act_defenselineaireprev_line_yellow_map2: {
    guiIcon: 'act_defenselineaireprev_line_yellow_gui',
    reversedIcon: 'act_defenselineaireprev_line_yellow_map1',
  },
  ana_cheminementevolutionlimitee_line_black_map1: {
    guiIcon: 'ana_cheminementevolutionlimitee_line_black_map1',
    reversedIcon: 'ana_cheminementevolutionlimitee_line_black_map2',
  },
  ana_cheminementevolutionlimitee_line_black_map2: {
    guiIcon: 'ana_cheminementevolutionlimitee_line_black_map2',
    reversedIcon: 'ana_cheminementevolutionlimitee_line_black_map1',
  },
  ana_cheminementsensdecirculation_line_black_map1: {
    guiIcon: 'ana_cheminementsensdecirculation_line_black_map1',
    reversedIcon: 'ana_cheminementsensdecirculation_line_black_map2',
  },
  ana_cheminementsensdecirculation_line_black_map2: {
    guiIcon: 'ana_cheminementsensdecirculation_line_black_map2',
    reversedIcon: 'ana_cheminementsensdecirculation_line_black_map1',
  },
  org_sectorisationgeographique_line_purple_map: {
    guiIcon: 'org_sectorisationgeographique_line_purple_gui',
  },
};

// Facades: Loop from letter 'a' to 'z'
for (let i = 97; i < 123; i++) {
  const letter = String.fromCharCode(i);
  registerGuiIcon({
    key: `fac_${letter}`,
    type: IconType.Symbol,
    category: AnnotationCategory.Facades,
  });
}

// Create Icon groups
const ActionsGroup = new MapIconGroup({
  nameLangkey: AnnotationCategoryLangkey[AnnotationCategory.Actions],
  icons: _.filter(GuiIcons, (v) => v.category === AnnotationCategory.Actions),
});
const AnalyseGroup = new MapIconGroup({
  nameLangkey: AnnotationCategoryLangkey[AnnotationCategory.Analyse],
  icons: _.filter(GuiIcons, (v) => v.category === AnnotationCategory.Analyse),
});
const ResourcesGroup = new MapIconGroup({
  nameLangkey: AnnotationCategoryLangkey[AnnotationCategory.Resources],
  icons: _.filter(GuiIcons, (v) => v.category === AnnotationCategory.Resources),
});
const OperationalGroup = new MapIconGroup({
  nameLangkey: AnnotationCategoryLangkey[AnnotationCategory.Operational],
  icons: _.filter(GuiIcons, (v) => v.category === AnnotationCategory.Operational),
});
const SinistersGroup = new MapIconGroup({
  nameLangkey: AnnotationCategoryLangkey[AnnotationCategory.Sinisters],
  icons: _.filter(GuiIcons, (v) => v.category === AnnotationCategory.Sinisters),
});
const FacadesGroup = new MapIconGroup({
  nameLangkey: AnnotationCategoryLangkey[AnnotationCategory.Facades],
  icons: _.filter(GuiIcons, (v) => v.category === AnnotationCategory.Facades),
});

// Define SITAC icons groups with respect to the given order
const SITACGroups = [AnalyseGroup, SinistersGroup, ActionsGroup, ResourcesGroup, OperationalGroup];

@Injectable({
  providedIn: 'root',
})
export class MapIconsService {
  constructor() {}

  getSITACGroups(): MapIconGroup[] {
    return SITACGroups;
  }

  getFacadeGroup(): MapIconGroup {
    return FacadesGroup;
  }

  getFacadeLetters(from: string, to: string): MapIcon[] {
    const start = from.charCodeAt(0) - 65;
    const end = to.charCodeAt(0) - 65;
    const output = [];
    // Letter icon are inserted in alphabetical order
    for (let i = start; i <= end; i++) {
      output.push(FacadesGroup.icons[i]);
    }
    return output;
  }

  /**
   * Returns a reference (guiIcon, reversedIcon) from an icon key.
   * "guiIcon" can then be used to retrieve the corresponding MapIcon from the getMapIcon method.
   *
   * This function is mainly used to know if an Icon on the Map should have a different Icon
   * on the GUI. It's also used to access to "reversed" version of Icon, making the "reverse"
   * action possible for Polylines.
   */
  getMapIconReference(key: string): { guiIcon: string; reversedIcon?: string } {
    return MapIcons[key] ?? { guiIcon: key };
  }

  /**
   * Returns a MapIcon from the GuiIcons object
   */
  getMapIcon(key: string): MapIcon {
    return GuiIcons[key] ?? null;
  }

  loadImages(map: mapboxgl.Map): void {
    const location = window.location;
    const spritesheetUrl = `${location.protocol}//${location.host}/assets/images/annotation-icons/spritesheet`;
    (<any>map).style._loadSprite(spritesheetUrl);
  }
}
