import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MidButtonSize } from '../enums/button-size.enum';
import { MidButtonType } from '../enums/button-type.enum';

@Component({
  selector: 'app-mid-button',
  templateUrl: './mid-button.component.html',
  styleUrls: ['./mid-button.component.scss'],
})
export class MidButtonComponent {
  @Input()
  type: MidButtonType;

  @Input()
  size: MidButtonSize;

  @Input()
  icon: string;

  @Input()
  disabled: boolean;

  @Output()
  action: EventEmitter<any>;

  get MidButtonType() {
    return MidButtonType;
  }

  get MidButtonSize() {
    return MidButtonSize;
  }

  constructor() {
    this.action = new EventEmitter();
  }

  onClick(event) {
    setTimeout(() => {
      this.action.emit(event);
    }, 0);
  }
}
