import { Intervention } from './../../autogenerated/model';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InterventionType } from 'src/app/autogenerated/model';
import { KeyboardKey } from 'src/app/core/shared';
import { required, validate } from 'src/app/shared';
import { InterventionService } from 'src/app/autogenerated/interventionService';
import { InterventionStoreService } from '../store';
import _, { find } from 'lodash';
import { Subscription } from 'rxjs';
import { Unsubscribe } from 'src/app/core/decorators';
import { TranslateService } from '@ngx-translate/core';

export interface AddInterventionDialogData {
  organizationId: string;
  intervention: Intervention;
  defaultInterventionType?: InterventionType;
}

@Unsubscribe()
@Component({
  selector: 'app-add-intervention',
  templateUrl: './add-intervention.component.html',
  styleUrls: ['./add-intervention.component.scss'],
})
export class AddInterventionComponent implements OnInit, OnDestroy {
  organizationId: string;
  name: string;
  interventionType?: InterventionType;
  interventionId?: string;
  interventionNumber?: string;
  errorMessage: string;
  interventionsSubscription: Subscription;

  state: {
    isLoading: boolean;
    isFailed: boolean;
    isInterventionTypeRequiredError: boolean;
  };

  get InterventionType() {
    return InterventionType;
  }

  constructor(
    private interventionService: InterventionService,
    private dialogRef: MatDialogRef<AddInterventionComponent>,
    private interventionStoreService: InterventionStoreService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: AddInterventionDialogData
  ) {
    this.organizationId = data.organizationId;
    this.name = '';
    this.interventionType = data.defaultInterventionType ? data.defaultInterventionType : null;
    this.interventionNumber = null;
    this.errorMessage = '';

    if (data.intervention) {
      this.name = data.intervention.name;
      this.interventionType = data.intervention.type;
      this.interventionId = data.intervention._id;
      this.interventionNumber = data.intervention.interventionNumber;
    }

    this.state = {
      isLoading: false,
      isFailed: false,
      isInterventionTypeRequiredError: false,
    };
  }

  ngOnInit() {
    this.interventionsSubscription = this.interventionStoreService
      .getOrganizationInterventions(this.organizationId)
      .subscribe(async (interventions) => {
        this.name = await this.getNextInterventionName(interventions);
      });
  }

  ngOnDestroy() {}

  @validate
  onKeyPress(@required event: KeyboardEvent, isNameValid: boolean, isInterventionTypeValid: boolean) {
    if (event.key !== KeyboardKey.Enter) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    if (isNameValid && isInterventionTypeValid) {
      this.onSaveIntervention();
      return;
    }

    if (!isNameValid) {
      this.state.isFailed = true;
    }

    if (!isInterventionTypeValid) {
      this.state.isInterventionTypeRequiredError = true;
    }
  }

  onNameChanged() {
    this.state.isFailed = false;
  }

  onInterventionTypeChanged() {
    this.state.isInterventionTypeRequiredError = false;
  }

  async onSaveIntervention(): Promise<void> {
    this.state.isLoading = true;
    this.state.isFailed = false;

    try {
      let intervention: Intervention;
      if (this.interventionId) {
        intervention = await this.interventionService.updateIntervention({
          organization: this.organizationId,
          _id: this.interventionId,
          name: this.name,
          interventionNumber: this.interventionNumber,
          type: this.interventionType,
        });
      } else {
        intervention = await this.interventionService.createIntervention({
          name: this.name,
          organizationId: this.organizationId,
          interventionType: this.interventionType,
          interventionNumber: this.interventionNumber,
        });
      }
      this.interventionStoreService.addOrganizationIntervention(this.organizationId, intervention);

      this.dialogRef.close(intervention);
    } catch (ex) {
      this.errorMessage = ex.message;
      this.state.isFailed = true;
    } finally {
      this.state.isLoading = false;
    }
  }

  private async getNextInterventionName(interventions: Intervention[]): Promise<string> {
    let counter = interventions.length;
    let name = '';
    let isUnique = false;
    let whileEmergencyExitCounter = 1000;

    while (!isUnique || whileEmergencyExitCounter === 0) {
      counter = counter + 1;
      const intervetionLocalized = await this.translate.get('COMMON.INTERVENTION').toPromise();
      name = `${intervetionLocalized} ${counter}`;
      isUnique = this.isUniqueName(name, interventions);

      whileEmergencyExitCounter = whileEmergencyExitCounter - 1;
    }

    return name;
  }

  private isUniqueName(interventionName: string, existingInterventions: Intervention[]): boolean {
    const foundIntervention = find(
      existingInterventions,
      (intervention: Intervention) => intervention.name?.toLowerCase() === interventionName.toLowerCase()
    );

    return !foundIntervention;
  }
}
