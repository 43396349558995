import { Feature } from './core/features';
import { AppFeatureId } from './app-feature-id';

export const appFeatures: Feature[] = [
  {
    id: AppFeatureId.Cartography,
    isEnabledByDefault: true,
    name: 'Cartographie',
    description: "Fonctionnalité de carte sur une page de détails d'intervention",
  },
  {
    id: AppFeatureId.NewDesign,
    isEnabledByDefault: false,
    name: 'New design',
    description: 'Nouveau désign de la plateforme',
  },
];
