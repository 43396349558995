import { AnnotationCategory, AnnotationSubCategory } from './annotation-category.enum';

export enum IconColor {
  Green = 'green',
  Yellow = 'orange',
  Purple = 'purple',
  Red = 'red',
  Black = 'black',
  Blue = 'blue',
}

export enum IconState {
  Planned = 'planned',
  Active = 'active',
}

export enum IconType {
  Line = 'line',
  Symbol = 'symbol',
}

export enum SpecialType {
  Sector = 'sector',
}

export class MapIcon {
  key: string;
  type: IconType;
  defaultMapIcon: string;
  category: AnnotationCategory;
  subcategory?: AnnotationSubCategory;
  specialType?: SpecialType;
  colors: IconColor[];
  trigrams?: string[];
  state?: IconState;
  cssclass: string;
  textOffset: [number, number];

  constructor(obj: Partial<MapIcon> = {}) {
    this.key = obj.key;
    this.cssclass = obj.cssclass;
    this.type = obj.type;
    this.defaultMapIcon = obj.defaultMapIcon;
    this.category = obj.category;
    this.subcategory = obj.subcategory;
    this.specialType = obj.specialType ?? null;
    this.colors = obj.colors ?? [];
    this.trigrams = obj.trigrams ?? null;
    this.state = obj.state ?? null;
    this.textOffset = obj.textOffset ?? [0, 0];
  }
}

export class MapIconGroup {
  nameLangkey: string;
  icons: MapIcon[];
  subcategorizedIcons?: { [subcategory: number]: MapIcon[] };

  constructor({ nameLangkey, icons }: Partial<MapIconGroup> = {}) {
    this.nameLangkey = nameLangkey;
    this.icons = icons;
  }
}
