import { DbClass, DroneLiveSnapshotMedia, startEventInput, Drone } from '../model';
import { BackendClientService } from '../../global/backend-client.service';
import { ApiService } from 'src/app/core/api';

export class LiveStreamServiceAutoGenerated {
  constructor(private beClient: BackendClientService, private apiService: ApiService) {}

  async getSnapshots(input: DbClass): Promise<DroneLiveSnapshotMedia[]> {
    let result: DroneLiveSnapshotMedia[];
    try {
      result = await this.beClient.post<DroneLiveSnapshotMedia[]>('liveStreamService/getSnapshots', input).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return result;
  }
  async startEvent(startEventInput: startEventInput): Promise<Drone> {
    let result: Drone;
    try {
      result = await this.beClient.post<Drone>('liveStreamService/startEvent', startEventInput).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return result;
  }
  async stopEvent(input: DbClass): Promise<Drone> {
    let result: Drone;
    try {
      result = await this.beClient.post<Drone>('liveStreamService/stopEvent', input).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return result;
  }
  async extendEvent(input: DbClass): Promise<Drone> {
    let result: Drone;
    try {
      result = await this.beClient.post<Drone>('liveStreamService/extendEvent', input).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return result;
  }
}
