import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Unsubscribe } from './core/decorators';
import { LayoutStoreService } from './layout/store';
import { WebSocketService } from './core/web-socket.service';

@Unsubscribe()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private defaultLanguageSubscription: Subscription;
  private defaultThemeSubscription: Subscription;

  constructor(
    private readonly translate: TranslateService,
    private renderer: Renderer2,
    private readonly layoutStoreService: LayoutStoreService,
    private readonly cookieConsentService: NgcCookieConsentService,
    private readonly webSocketService: WebSocketService
  ) {}

  async ngOnInit() {
    this.defaultLanguageSubscription = this.layoutStoreService
      .getDefaultLanguage()
      .subscribe((defaultLanguage: string) => {
        this.translate.setDefaultLang(defaultLanguage);
        this.translate.use(defaultLanguage);
        this.renderer.setAttribute(document.querySelector('html'), 'lang', defaultLanguage);
        this.initCookieConsent();
      });

    await this.webSocketService.connect();

    this.defaultThemeSubscription = this.layoutStoreService.getDefaultTheme().subscribe((defaultTheme: string) => {
      this.renderer.setAttribute(document.querySelector('html'), 'data-theme', defaultTheme);
    });
  }

  ngOnDestroy(): void {}

  private async initCookieConsent(): Promise<void> {
    this.cookieConsentService.getConfig().content = this.cookieConsentService.getConfig().content || {};
    // Override default messages with the translated ones
    this.cookieConsentService.getConfig().cookie.domain = environment.domain;
    this.cookieConsentService.getConfig().content.message = await this.translate
      .get('COOKIE_CONSENT_MESSAGE')
      .toPromise();
    this.cookieConsentService.getConfig().content.dismiss = await this.translate
      .get('COOKIE_CONSENT_DISMISS')
      .toPromise();

    this.cookieConsentService.destroy(); // remove previous cookie bar (with default messages)
    this.cookieConsentService.init(this.cookieConsentService.getConfig()); // update config with translated messages
  }
}
