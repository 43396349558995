<app-mid-right-panel [title]="'ACCESS_CODE.CREATE' | translate">
  <app-mid-right-panel-content>
    <div class="row m-0 px-4 pb-4">
      <section class="section col-md-12">
        {{ interventionSelection?.data?.selectedIntervention?.name }}
        <form class="new-organization-access-code" #formNewAccessCode="ngForm">
          <app-mid-input
            size="wide"
            type="text"
            #name="appMidInput"
            name="name"
            [required]="false"
            [label]="'NAME' | translate"
            [placeholder]="'NAME' | translate"
            [(value)]="newAccessCode.name"
          ></app-mid-input>

          <div class="input-container input-label">
            <label>{{ 'INTERVENTIONS' | translate }}</label>
            <app-mid-select-intervention-combobox
              [organizationId]="data.org.id"
              (interventionSelected)="onInterventionSelected($event)"
            ></app-mid-select-intervention-combobox>
            <div *ngFor="let intervention of interventions" class="d-flex flex-row new-access-code-intervention-list">
              <div class="p-1 flex-fill new-access-code-intervation-item-label">
                {{ intervention.name }}
              </div>
              <div class="p-1 flex-fill text-right">
                <button class="new-access-code-intervention-item-btn" (click)="removeIntervention(intervention)">
                  <i class="fa fa-times"></i>
                </button>
              </div>
            </div>
          </div>

          <div class="input-container input-label">
            <app-mid-select
              [label]="'ACCESS_CODE.EXPIRED_IN' | translate"
              size="wide"
              [items]="optionsSelectItems"
              [selectedItem]="newAccessCode.duration"
              (selectedItemChange)="newAccessCode.duration = $event"
              [placeholder]="'ACCESS_CODE.SELECT_DURATION' | translate"
            >
            </app-mid-select>
          </div>

          <div class="input-container input-label">
            <label>{{ 'ACCESS_CODE.DRONE_STREAMING' | translate }}</label>
            <div class="form-check new-access-code-intervation-drone-stream-item">
              <input
                class="form-check-input"
                type="radio"
                name="droneAccess"
                [(ngModel)]="newAccessCode.droneAccess"
                [value]="droneAccessValues.All"
                id="droneAccessAll"
                checked
              />
              <label class="form-check-label" for="droneAccessAll">
                {{ 'ACCESS_CODE.ACCESS_ALL_DRONE_STREAMS' | translate }}
              </label>
            </div>
            <div class="form-check new-access-code-intervation-drone-stream-item">
              <input
                class="form-check-input"
                type="radio"
                name="droneAccess"
                id="droneAccessInt"
                [(ngModel)]="newAccessCode.droneAccess"
                [value]="droneAccessValues.Intervention"
              />
              <label class="form-check-label" for="droneAccessInt">
                {{ 'ACCESS_CODE.ACCESS_INTERVENTION_DRONE' | translate }}
              </label>
            </div>
          </div>
        </form>
      </section>
    </div>
  </app-mid-right-panel-content>
  <app-mid-right-panel-footer>
    <div class="row m-0 py-3 px-4">
      <app-mid-button class="pr-2" type="primary" (click)="create()" [disabled]="formNewAccessCode.form?.invalid"
        >{{ 'DIALOGS.COMMON.SAVE' | translate }}
      </app-mid-button>

      <app-mid-button class="pr-2" type="secondary" (action)="close()">{{
        'DIALOGS.COMMON.CANCEL' | translate
      }}</app-mid-button>
    </div>
  </app-mid-right-panel-footer>
</app-mid-right-panel>
