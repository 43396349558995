import { Assert, required, validate } from 'src/app/shared';

export class PromiseUtils {
  @validate
  static delay(@required timeout: number): Promise<void> {
    Assert.isTrue(timeout >= 0, 'timeout', '"{0}" must be a positive number.');

    const promise = new Promise<void>((resolve: Function) => setTimeout(() => resolve(), timeout));

    return promise;
  }
  /** execute the promise right away but it would wait the min timeout anyway before resolving */
  @validate
  static async setMinDelay<T>(@required promise: Promise<T>, @required timeout: number): Promise<T> {
    Assert.isTrue(timeout >= 0, 'timeout', '"{0}" must be a positive number.');
    Assert.isNotNull(promise, 'promise', '"{0}" must be a positive number.');

    const delayPromise = this.delay(timeout);

    const [result] = await Promise.all([promise, delayPromise]);

    return result;
  }
}
