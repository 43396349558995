<app-modal-header text="{{ 'TDRAW.MODAL.NEW_PIC.TITLE' | translate }}" (closeAction)="onClose()"></app-modal-header>
<div class="body">
  <form name="formNewMap" #formNewMap="ngForm" novalidate>
    <app-mid-input
      size="wide"
      [(value)]="data.name"
      #mapName="appMidInput"
      name="mapName"
      [required]="true"
      [label]="'TDRAW.MODAL.NEW_PIC.NAME_LABEL' | translate"
      [placeholder]="'TDRAW.MODAL.NEW_PIC.NAME_PLACEHOLDER' | translate"
    >
      <span *ngIf="formNewMap.invalid && mapName.invalid" class="text-danger font-weight-bold">{{
        'COMMON_REQUIRED_FIELD' | translate
      }}</span>
    </app-mid-input>

    <p class="mb-0 mt-3">{{ 'TDRAW.MODAL.NEW_PIC.SELECT_PICTURE' | translate }}</p>

    <app-mid-button type="primary" (action)="onMediaSelection()" size="wide">
      <i class="fas fa-plus"></i> {{ 'TDRAW.MODAL.NEW_PIC.SELECT_IMAGE' | translate }}
    </app-mid-button>
    <span class="text-danger font-weight-bold" *ngIf="!data.mediaId">
      {{ 'TDRAW.MODAL.NEW_PIC.REQUIRED_IMAGE' | translate }}
    </span>

    <div class="buttons">
      <app-mid-button type="secondary" (action)="onClose()">{{ 'COMMON_CANCEL' | translate }}</app-mid-button>
      <app-mid-button
        type="primary"
        (action)="onSubmit()"
        [disabled]="formNewMap.invalid || formNewMap.pristine || !data.mediaId"
      >
        <i class="fas fa-check"></i> {{ 'VALIDATE_AND_CREATE' | translate }}
      </app-mid-button>
    </div>
  </form>
</div>
