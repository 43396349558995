import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateDroneComponent } from './create-drone/create-drone.component';
import { CoreModule } from 'src/app/core/core.module';
import { FormsModule } from '@angular/forms';
import { MidgardControlsModule } from 'src/app/midgard-controls/midgard-controls.module';
import { InterventionCommonModule } from '../../../intervention/common/intervention-common.module';

@NgModule({
  declarations: [CreateDroneComponent],
  imports: [CommonModule, CoreModule, FormsModule, MidgardControlsModule, InterventionCommonModule],
  exports: [CreateDroneComponent],
})
export class CreateDroneRightPanelModule {}
