import { ApiService } from './../core/api';
import { DroneServiceAutoGenerated } from './services/droneService.service';
import { Injectable } from '@angular/core';
import { BackendClientService } from '../global/backend-client.service';

@Injectable({
  providedIn: 'root',
})
export class DroneService extends DroneServiceAutoGenerated {
  constructor(client: BackendClientService, apiService: ApiService) {
    super(client, apiService);
  }
}
