import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Intervention } from '../../autogenerated/model';
import { DataSource, DataSourceService, DataSourceState, FieldSortOrder } from '../../core/collections';
import _, { map } from 'lodash';
import { InterventionService } from '../../autogenerated/interventionService';
import { LoggerService } from '../../core/logger.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { midSelectInterventionComboboxConfig } from './mid-select-intervention-compbobox.config';
import { required, validate } from '../../shared';
import { MatOptionSelectionChange } from '@angular/material/core';
import { HintState } from '../enums/hint-state.enum';
import { InterventionType } from 'src/app/autogenerated/model';

@Component({
  selector: 'app-mid-select-intervention-combobox',
  templateUrl: './mid-select-intervention-combobox.component.html',
  styleUrls: ['./mid-select-intervention-combobox.component.scss'],
})
export class MidSelectInterventionComboboxComponent implements OnInit {
  @Input()
  organizationId: string;

  @Input()
  excludeClosed = false;

  @Input()
  isHiddenHint = false;

  @Output()
  interventionSelected: EventEmitter<Intervention>;

  state: {
    isLoading: boolean;
    isFailed: boolean;
  };

  data: {
    fieldText: string;
    selectedIntervention: Intervention;
    isInterventionNameExists: boolean;
  };

  hintState: HintState;
  dataSource: DataSource<Intervention>;
  dataSourceState: DataSourceState<Intervention>;

  get interventionsNames(): string[] {
    return map(this.dataSource.allItems, (intervention: Intervention) => intervention.name);
  }

  get InterventionType() {
    return InterventionType;
  }

  get HintState() {
    return HintState;
  }

  constructor(
    private interventionService: InterventionService,
    private loggerService: LoggerService,
    private dataSourceService: DataSourceService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      'arrows',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/arrows.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'caution',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/exclamation-triangle.svg')
    );

    this.dataSource = new DataSource<Intervention>();

    this.dataSourceState = new DataSourceState<Intervention>({
      filter: '',
      searchableFields: midSelectInterventionComboboxConfig.searchableFields,
      sortOrder: new FieldSortOrder(
        midSelectInterventionComboboxConfig.sortOrder.fieldName,
        midSelectInterventionComboboxConfig.sortOrder.sortOrder
      ),
      page: 1,
      pageSize: Number.MAX_VALUE,
    });

    this.state = {
      isLoading: false,
      isFailed: false,
    };

    this.data = {
      fieldText: '',
      selectedIntervention: null,
      isInterventionNameExists: false,
    };

    this.hintState = HintState.none;
    this.interventionSelected = new EventEmitter();
  }

  ngOnInit() {
    this.loadInterventions();
  }

  @validate
  trackByItem(index: number, @required item: Intervention): string {
    return item._id;
  }

  onFieldSelected(item: Intervention, event: MatOptionSelectionChange): void {
    if (!event.isUserInput) {
      return;
    }
    this.onItemSelected(item);
  }

  onFilterChanged(filter: string) {
    this.dataSourceState.filter = filter;
    this.dataSource = this.dataSourceService.filterDataSource(this.dataSource, this.dataSourceState);
    this.data.isInterventionNameExists = this.isInterventionNameExists();
    this.setHintState();
  }

  onItemSelected(intervention: Intervention) {
    this.data.selectedIntervention = intervention;
    this.interventionSelected.next(intervention);
  }

  isInterventionNameExists(): boolean {
    const interventionsNames = map(this.interventionsNames, (value) => value.toLowerCase());
    const currentValue = this.data.fieldText ? this.data.fieldText.toLowerCase() : '';
    const isExists = _.indexOf(interventionsNames, currentValue) !== -1;
    return isExists;
  }

  setHintState(): void {
    if (!this.data.fieldText) {
      this.hintState = HintState.emptyInput;
      return;
    }
    this.hintState = this.data.isInterventionNameExists ? HintState.none : HintState.notFoundIntervention;
  }

  private async loadInterventions() {
    this.state.isLoading = true;
    this.state.isFailed = false;

    this.dataSource = new DataSource<Intervention>();

    try {
      let interventions = await this.interventionService.getInterventionsWithMapping({ _id: this.organizationId });
      // the next line: we filter the interventions => we chose only the opened interventions
      // interventions = _.filter(interventions, (intervention) => !intervention.isClosed);
      interventions = _.filter(
        interventions,
        (intervention) => !intervention.isArchived && (!this.excludeClosed || !intervention.isClosed)
      );
      this.dataSource = this.dataSourceService.createDataSource(interventions, this.dataSourceState);
      this.data.isInterventionNameExists = this.isInterventionNameExists();
    } catch (error) {
      this.state.isFailed = true;
      this.loggerService.error('Failed to load interventions', error);
    } finally {
      this.state.isLoading = false;
    }
  }

  setInterventionSelection(interventions: Intervention[]) {
    this.dataSource = this.dataSourceService.createDataSource(interventions, this.dataSourceState);
    this.data.isInterventionNameExists = this.isInterventionNameExists();
  }
}
