import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private history: string[] = [];
  constructor(private router: Router, private location: Location) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  async goBack() {
    // console.log('>>>>>> history: ', this.history);
    const currentItem: string = this.history.pop();
    if (this.history.length > 0) {
      const routerParams = this.history[this.history.length - 1].split('/');
      const routePosition = 1;
      const previousRoute = routerParams[routePosition];

      if (
        previousRoute === 'media' ||
        previousRoute === 'picture' ||
        previousRoute === 'video' ||
        previousRoute === 'snapshot'
      ) {
        await this.router.navigateByUrl('/media');
        return;
      }

      this.location.back();
      return;
    }
    await this.router.navigateByUrl('/');
  }
}
