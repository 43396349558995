import { Component, Input } from '@angular/core';

@Component({
  selector: 'tdraw-mapmode-info',
  templateUrl: './mapmode-info.component.html',
  styleUrls: ['./mapmode-info.component.scss'],
})
export class MapmodeInfoComponent {
  @Input()
  isEditable: boolean;

  opened = true;

  constructor() {}
}
