<div class="mt-2 mb-3 text-center">{{ 'SYMBOLS_EXPLAINER' | translate }}</div>

<tdraw-sitac-selector (elementSelected)="onDrawIcon($event)"> </tdraw-sitac-selector>

<app-mid-expansion-panel
  title="{{ 'BUSINESS_TOOLS' | translate }}"
  isExpanded="true"
  type="primary"
  class="business-tools"
>
  <p>{{ 'PROPAGATION_CONE' | translate }}</p>

  <img src="/assets/images/prop-cone.svg" width="100" height="100" class="cone-img" (click)="onDrawCone()" />

  <p>{{ facadeGroup.nameLangkey | translate }}</p>
  <div class="dot-container">
    <button *ngFor="let facadeIcon of facadeIconsBasic" class="icon-button">
      <div class="dot {{ facadeIcon.cssclass }}" (click)="onDrawIcon(facadeIcon)"></div>
    </button>

    <div class="last-dot" *ngIf="!facadeIconsExpanded" (click)="facadeIconsExpanded = !facadeIconsExpanded">
      View more
    </div>

    <div *ngIf="facadeIconsExpanded">
      <button *ngFor="let facadeIcon of facadeIconsMore" class="icon-button">
        <div class="dot {{ facadeIcon.cssclass }}" (click)="onDrawIcon(facadeIcon)"></div>
      </button>
    </div>

    <div class="last-dot" *ngIf="facadeIconsExpanded" (click)="facadeIconsExpanded = !facadeIconsExpanded">
      View less
    </div>
  </div>

  <p>{{ 'ZONAGE' | translate }}</p>
  <div class="zonage">
    <img src="/assets/images/zonage/group-158.svg" width="35" height="35" (click)="onDrawCircle(CircleType.Small)" />

    <img src="/assets/images/zonage/group-159.svg" width="40" height="40" (click)="onDrawCircle(CircleType.Medium)" />

    <img src="/assets/images/zonage/group-160.svg" width="50" height="50" (click)="onDrawCircle(CircleType.Large)" />

    <img src="/assets/images/zonage/group-162.svg" width="65" height="65" (click)="onDrawAllCircles()" />
  </div>

  <p>{{ 'POINT_OF_INTEREST' | translate }}</p>
  <div class="zonage">
    <img src="/assets/images/marker.png" width="35" height="35" (click)="onOpenMarkerCreationModal()" />
  </div>
</app-mid-expansion-panel>

<app-mid-statistics-victims
  [isEditable]="true"
  [(items)]="statistics.victims"
  (click)="onUpdateStatistics()"
  [isExpanded]="true"
></app-mid-statistics-victims>
<app-mid-statistics-engaged-staff
  [isEditable]="true"
  [(items)]="statistics.engagedStaff"
  (click)="onUpdateStatistics()"
  [isExpanded]="true"
></app-mid-statistics-engaged-staff>
<app-mid-statistics-engaged-resources
  [isEditable]="true"
  [(items)]="statistics.engagedResources"
  (click)="onUpdateStatistics()"
  [isExpanded]="true"
></app-mid-statistics-engaged-resources>
<app-mid-statistics-other-services
  [isEditable]="true"
  [(items)]="statistics.otherServices"
  (click)="onUpdateStatistics()"
  [isExpanded]="true"
></app-mid-statistics-other-services>

<!-- TODO: future functionality -->
<!-- <app-mid-expansion-panel title="Import element" isExpanded="true" type="primary">
  <div class="center-content">
    <p>{{ 'IMPORTED_ELEMENTS_MESSAGE' | translate }}</p>
    <app-mid-button type="secondary">{{ 'IMPORT_ELEMENTS' | translate }}</app-mid-button>
  </div>
</app-mid-expansion-panel> -->
