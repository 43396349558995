import { Component } from '@angular/core';
import { TemplateService } from 'src/app/global/template.service';
import { ExternalRouterService } from 'src/app/shared/utils/external-router.service';

@Component({
  selector: 'app-features-page',
  templateUrl: './features-page.component.html',
  styleUrls: ['./features-page.component.scss'],
})
export class FeaturesPageComponent {
  private readonly externalRouterService: ExternalRouterService;

  constructor(externalRouterService: ExternalRouterService, private readonly templateService: TemplateService) {
    this.externalRouterService = externalRouterService;

    this.templateService.$activeComponent.next('FEATURES');
  }

  onFeaturesOverridesSaved() {
    this.externalRouterService.reload();
  }
}
