import { Injectable } from '@angular/core';
import { BackendClientService } from 'src/app/global/backend-client.service';
import { BlobReader, TextWriter, ZipReader } from '@zip.js/zip.js';
import * as toGeoJSON from '@tmcw/togeojson';
import { ServiceLocator } from 'src/app/global/locator.service';
import { MapState } from '../models/map-state.model';
import { EditModeManager } from './edit-mode-manager';
import { GeometryType } from '../models/geometry-type.enum';
import { tdrawConfig } from '../tdraw.config';
import { FeatureProperty } from '../models/feature-property.enum';
import { GuidService } from 'src/app/global/guid.service';
import { Feature } from '@turf/turf';

/**
 * Class to import KMZ and KML
 */
// @Injectable({
//   providedIn: 'root',
// })
export class KmzImporter {
  private beClient: BackendClientService;
  private guidService: GuidService;

  constructor(
    private organizationId: string,
    private interventionId: string,
    private editModeManager: EditModeManager,
    private mapState: MapState
  ) {
    this.beClient = ServiceLocator.injector.get(BackendClientService);
    this.guidService = ServiceLocator.injector.get(GuidService);
  }

  async import(file: File) {
    const kmlGuid = this.guidService.generate();

    if (file.type === 'application/vnd.google-earth.kml+xml') {
      const content = await file.text();
      this.parseKML(content, kmlGuid);
    }

    if (file.type === 'application/vnd.google-earth.kmz') {
      this.uploadToServer(file, kmlGuid);
      await this.parseKMZ(file, kmlGuid);
    }
  }

  private async parseKMZ(file: Blob, kmlGuid: string) {
    const zipFileReader = new BlobReader(file);
    const zipReader = new ZipReader(zipFileReader);
    const entries = await zipReader.getEntries();

    for (const entry of entries) {
      if (entry.filename === 'doc.kml') {
        const kmlContent = await entry.getData(new TextWriter());
        this.parseKML(kmlContent, kmlGuid);
        break;
      }
    }

    await zipReader.close();
  }

  private parseKML(content: string, kmlGuid: string) {
    const dom = new DOMParser().parseFromString(content, 'text/xml');
    const converted = toGeoJSON.kml(dom);

    for (const feature of converted.features) {
      this.importFeature(feature as Feature, kmlGuid);
    }
  }

  private importFeature(feature: Feature, kmlGuid: string) {
    feature.id = this.guidService.generate();

    switch (feature.geometry.type) {
      case GeometryType.Point:
        this.importFeaturePoint(feature, kmlGuid);
        break;
      case GeometryType.Polygon:
        this.importFeaturePolygon(feature);
        break;
      case GeometryType.LineString:
        this.importFeatureLineString(feature);
    }
  }

  private importFeaturePoint(feature: Feature, kmlGuid) {
    const coords = (<any>feature.geometry).coordinates;
    const lng = coords[0];
    const lat = coords[1];
    const content = feature.properties.description.value;
    const guid = feature.id as any;
    const img = feature.properties.icon ?? null;

    this.editModeManager.displayRegularMarker(
      {
        lat: lat,
        lng: lng,
        content: content,
        guid: guid,
        img: `${kmlGuid}/${img}`,
      },
      true,
      `public/assets-kmz/${this.mapState._id}`
    );
  }

  private importFeaturePolygon(feature: Feature) {
    feature.properties = {
      ...tdrawConfig.defaultFeatureProperties,
      ...tdrawConfig.defaultDrawFeatureProperties[GeometryType.Polygon],
      ...this.parseFeatureProperties(feature),
    };

    const sourceId = this.editModeManager.createAnnotationFromFeature(feature as any);
    this.editModeManager.mapManager.showSource(sourceId);
  }

  private importFeatureLineString(feature: Feature) {
    feature.properties = {
      ...tdrawConfig.defaultFeatureProperties,
      ...tdrawConfig.defaultDrawFeatureProperties[GeometryType.LineString],
      ...this.parseFeatureProperties(feature),
    };

    const sourceId = this.editModeManager.createAnnotationFromFeature(feature as any);
    this.editModeManager.mapManager.showSource(sourceId);
  }

  private parseFeatureProperties(feature: Feature) {
    const output = {};
    const properties = feature.properties;
    if (!properties) {
      return output;
    }

    // Description
    if (properties.description) {
      output[FeatureProperty.Description] = properties.description?.value ?? properties.description;
    }

    // Color
    if (properties.fill) {
      output[FeatureProperty.Color] = properties.fill;
    }

    return output;
  }

  private async uploadToServer(file: File, kmlGuid) {
    const formData = new FormData();
    formData.append('kmz', file);

    try {
      await this.beClient
        .post(
          `api/v1/organization/${this.organizationId}/intervention/${this.interventionId}/cartography/${this.mapState._id}/uploadkmz/${kmlGuid}`,
          formData
        )
        .toPromise();
    } catch (error) {}
  }
}
