import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecoverPasswordPageComponent } from './recover-password-page/recover-password-page.component';
import { RecoverPasswordFormComponent } from './recover-password-form/recover-password-form.component';
import { FormsModule, NgForm } from '@angular/forms';
import { AuthenticationStoreModule } from './store';
import { CoreModule } from '../core/core.module';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticationHttpInterceptor } from './authentication-http.interceptor';
import { LoginPageComponent } from './login-page/login-page.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { PasswordValidationRulesComponent } from './password-validation-rules/password-validation-rules.component';
import { PasswordValidationRuleComponent } from './password-validation-rule/password-validation-rule.component';
import { MidgardControlsModule } from '../midgard-controls/midgard-controls.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    LoginPageComponent,
    LoginFormComponent,
    RecoverPasswordPageComponent,
    RecoverPasswordFormComponent,
    PasswordValidationRulesComponent,
    PasswordValidationRuleComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    AuthenticationStoreModule,
    CoreModule,
    RouterModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MidgardControlsModule,
    SharedModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationHttpInterceptor,
      multi: true,
    },
    NgForm,
  ],
  exports: [LoginPageComponent, PasswordValidationRulesComponent],
})
export class AuthenticationModule {}
