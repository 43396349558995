<div class="analyses-container">
  <div class="container">
    <div class="row m-0">
      <section class="section-form-group col-md-12">
        <h2 class="section-header">{{ 'INTERVENTION_ANALYSES.SECTION_VIDEO_RECORDING' | translate }}</h2>
        <!-- Rounded switch global recording -->
        <app-mid-toggle-switch
          textOn="INTERVENTION_ANALYSES.LABEL_DIFFUSION_ACTIVATE"
          textOff="INTERVENTION_ANALYSES.LABEL_DIFFUSION_DEACTIVATE"
          [(state)]="recordingState"
          (action)="toggleRecord($event)"
          [labelPosition]="LabelPosition.after"
        ></app-mid-toggle-switch>
      </section>
      <section class="section-form-group col-md-12">
        <h2 class="section-header">{{ 'INTERVENTION_ANALYSES.SECTION_DETECTION' | translate }}</h2>
        <!-- Rounded switch blurfaces -->
        <app-mid-toggle-switch
          #blur
          id="blur"
          textOn="INTERVENTION_ANALYSES.LABEL_BLURFACES_ACTIVATE"
          textOff="INTERVENTION_ANALYSES.LABEL_BLURFACES_DEACTIVATE"
          [(state)]="faceblurState"
          (action)="toggleFaceblur($event)"
          [labelPosition]="LabelPosition.after"
          [disabled]="!blurFeatureActivated"
        ></app-mid-toggle-switch>

        <!-- Rounded switch blurfaces -->
        <!-- <app-mid-toggle-switch 
          textOn="INTERVENTION_ANALYSES.LABEL_SMOCK_DETECTION_ACTIVATE"
          textOff="INTERVENTION_ANALYSES.LABEL_SMOCK_DETECTION_DEACTIVATE" 
          [(state)]="smockDetectionState" 
          (action)="toggleSmockDetection($event)" 
          [labelPosition]="LabelPosition.after"
          [disabled]="!smockFeatureActivated">
        </app-mid-toggle-switch> -->
      </section>

      <section class="section-form-group col-md-12">
        <h2 class="section-header">{{ 'INTERVENTION_ANALYSES.SECTION_PHOTOGRAMMETRY' | translate }}</h2>
        <br />
        <div>
          <span>{{ 'INTERVENTION_ANALYSES.PHOTOGRAMMETRY_DESCRIPTION' | translate }}</span>
        </div>
        <br />
        <div>
          <app-mid-button type="primary" (action)="openDialog()" [disabled]="intervention?.isArchived">
            {{ 'IMPORT_MEDIA' | translate }}
          </app-mid-button>
        </div>
        <br />
        <div *ngIf="uploadsProgress.length !== 0">
          <h2 class="sub-section-header">
            {{ 'INTERVENTION_ANALYSES.PHOTOGRAMMETRY_UPLOAD_PROGRESS_BAR_HEADER' | translate }}
          </h2>
          <br />
          <div *ngFor="let uploadProgress of uploadsProgress">
            <span>{{ getUploadDescription(uploadProgress) }} {{ 'IMAGES' | translate }}</span>
            <mat-progress-bar
              class="example-margin"
              color="primary"
              [mode]="getModeOfUploadProgress(uploadProgress)"
              [value]="uploadProgress.getProgress()"
            >
            </mat-progress-bar>
            <span *ngIf="uploadProgress.isComplete()">{{
              'INTERVENTION_ANALYSES.PHOTOGRAMMETRY_UPLOAD_COMPLETE' | translate
            }}</span>
            <span *ngIf="uploadProgress.aborted">{{
              'INTERVENTION_ANALYSES.PHOTOGRAMMETRY_UPLOAD_PARTIAL_ABORT' | translate
            }}</span>
            <span *ngIf="uploadProgress.paused && !uploadProgress.isComplete() && !uploadProgress.aborted">
              <i class="fas fa-play-circle" (click)="pauseUpload(uploadProgress)"></i>
            </span>
            <span *ngIf="!uploadProgress.paused && !uploadProgress.isComplete() && !uploadProgress.aborted">
              <i class="fas fa-pause-circle" (click)="pauseUpload(uploadProgress)"></i>
            </span>
            <span *ngIf="!uploadProgress.isComplete() && !uploadProgress.aborted">
              <i class="fas fa-stop-circle" (click)="stopUpload(uploadProgress)"></i>
            </span>
          </div>
        </div>
        <div *ngIf="uploadsProgress.length === 0">
          <span>{{ 'INTERVENTION_ANALYSES.PHOTOGRAMMETRY_NO_UPLOAD_IN_PROGRESS' | translate }}</span>
        </div>
        <br />
        <!-- <h2 class="sub-section-header">{{ 'INTERVENTION_ANALYSES.SUB_SECTION_PHOTOGRAMMETRY_PROJECT' | translate }}</h2>
        <br /> -->
        <div *ngIf="tiffStatusMessages.length === 0">
          <span>{{ 'INTERVENTION_ANALYSES.PHOTOGRAMMETRY_NO_PROJECT_IN_PROGRESS' | translate }}</span>
        </div>
        <div *ngIf="tiffStatusMessages.length !== 0">
          <h2 class="sub-section-header">
            {{ 'INTERVENTION_ANALYSES.PHOTOGRAMMETRY_PROGRESS_BAR_HEADER' | translate }}
          </h2>
          <br />
          <div *ngFor="let message of tiffStatusMessages">
            <span>{{ getMessageDescription(message) }}</span>
            <span *ngIf="haveProgress(message)"> {{ message.progress }}% </span>
            <mat-progress-bar
              [class]="getClassOfProjectProgress(message)"
              color="primary"
              [mode]="getModeOfProjectProgress(message)"
              [value]="message.progress"
              [bufferValue]="getBufferValue(message)"
            >
            </mat-progress-bar>

            <span *ngIf="isCompleted(message)">{{
              'INTERVENTION_ANALYSES.PHOTOGRAMMETRY_PROJECT_COMPLETE' | translate
            }}</span>
            <span *ngIf="isFailed(message)">{{
              'INTERVENTION_ANALYSES.PHOTOGRAMMETRY_PROJECT_FAILED' | translate
            }}</span>
            <span *ngIf="isFailed(message)">
              <i class="fas fa-trash-alt" (click)="deleteProject(message)"></i>
            </span>
            <span *ngIf="!isFailed(message) && !isCompleted(message)">
              <i class="fa-solid fa-xmark" (click)="deleteProject(message, true)"></i>
            </span>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
