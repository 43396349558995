import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MediaService } from 'src/app/autogenerated/mediaService';
import { InterventionStoreService } from 'src/app/intervention/store';
import { Intervention, MediaType, OrganizationMedia } from 'src/app/autogenerated/model';
import { MatDialog } from '@angular/material/dialog';
import { MediaInterventionsDialogComponent } from '../media-interventions-dialog/media-interventions-dialog.component';
import { ConfirmModalService } from '../../../midgard-controls/services/confirm-modal.service';
import { MediaOperationsService } from '../media-operations.service';
import { DisplayMode } from '../../../midgard-controls/enums/display-mode.enum';
import { MediaShareComponent } from '../../media-share/media-share.component';
import { MidRightPanelService } from 'src/app/midgard-controls/services/mid-right-panel.service';
import { OrganizationsMediaService } from 'src/api';
import { filter, first, switchMap } from 'rxjs/operators';
import { MediaUploadService } from '../media-upload/media-upload.service';
import { firstValueFrom, of } from 'rxjs';
import { MediaPageLocation } from 'src/app/midgard-controls/enums/media-page-location.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UploadingFile } from '../media-upload/models/uploading-file';

@UntilDestroy()
@Component({
  selector: 'app-media-card',
  templateUrl: './media-card.component.html',
  styleUrls: ['./media-card.component.scss'],
})
export class MediaCardComponent implements OnInit {
  @Input()
  mediaTypeFilter: MediaType;

  @Input()
  interventionIdFilter: string;
  interventionFilter: Intervention;

  @Input()
  media: OrganizationMedia;

  @Input()
  canDelete: boolean;

  @Input()
  displayMode: DisplayMode = DisplayMode.default;

  @Input()
  isSelected = false;

  @Input()
  isFavorite = false;

  @Input()
  mediaPageLocation: MediaPageLocation;

  @Output()
  mediaToDelete: EventEmitter<string>;

  @Output()
  mediaToRefresh: EventEmitter<string>;

  @Output()
  onSelectedMediaId: EventEmitter<string>;

  @Output()
  selectedMediaProperties: EventEmitter<OrganizationMedia>;

  @Output()
  toggleFavoriteMedia: EventEmitter<OrganizationMedia>;

  state: {
    loadingProgress: number;
    shared: boolean;
    loadingError: boolean;
  };

  previewUrl: string;
  interventionName: string;

  get MediaType() {
    return MediaType;
  }

  get DisplayMode() {
    return DisplayMode;
  }

  get MediaPageLocation() {
    return MediaPageLocation;
  }

  get intervention() {
    return this.media.intervention as Intervention;
  }

  get mediaCreationDate() {
    return new Date(this.media.creationDate);
  }

  get isLoading() {
    return this.media?.__isLoading;
  }

  constructor(
    private mediaService: MediaService,
    private interventionStoreService: InterventionStoreService,
    private dialog: MatDialog,
    private confirmModalService: ConfirmModalService,
    private mediaOperationsService: MediaOperationsService,
    private midRightPanelService: MidRightPanelService,
    private readonly organizationsMediaService: OrganizationsMediaService,
    private mediaUploadService: MediaUploadService
  ) {
    this.mediaToDelete = new EventEmitter();
    this.mediaToRefresh = new EventEmitter();
    this.onSelectedMediaId = new EventEmitter();
    this.selectedMediaProperties = new EventEmitter();
    this.toggleFavoriteMedia = new EventEmitter<boolean>();
    this.state = {
      loadingProgress: 0,
      shared: false,
      loadingError: false,
    };
  }

  async ngOnInit() {
    if (!this.isLoading) {
      const sharedMedias = await firstValueFrom(
        this.organizationsMediaService.sharedMediaFileControllerGetMediaShares(this.media._id)
      );
      this.state.shared = sharedMedias.length > 0;
    }
    // TODO: use directly the `interventionFilter` input in order to avoid the local storage call
    if (this.interventionIdFilter) {
      this.interventionFilter = await firstValueFrom(
        this.interventionStoreService.getInterventionById(this.interventionIdFilter as string)
      );
    }
    // subscribing to get first value for preview, then switching to get progress on upload
    this.mediaUploadService.fileUploadingProgress$
      .pipe(
        untilDestroyed(this),
        filter((file: UploadingFile) => file.guid === this.media?.__guid),
        first(),
        switchMap((uploadingFile: UploadingFile) => {
          this.setPreview(uploadingFile);

          return of(uploadingFile).pipe(switchMap(() => this.mediaUploadService.fileUploadingProgress$));
        })
      )
      .subscribe((file: UploadingFile) => {
        this.state.loadingProgress = file.progress;

        if (file.loadingError) {
          this.state.loadingError = true;
        }
      });

    this.interventionName = await this.getInterventionName(this.media.intervention as string);
  }

  getDownloadPath(): string {
    if (!this.media.organization) {
      return '';
    }
    return this.mediaService.getDownloadUrl(this.media, true);
  }

  setPreview(uploadingFile: UploadingFile) {
    const reader = new FileReader();
    if (uploadingFile) {
      reader.readAsDataURL(uploadingFile.file);
    }

    reader.onload = () => {
      this.previewUrl = reader.result?.toString() || '';
    };
  }

  selectMedia() {
    if (this.displayMode === DisplayMode.default) {
      this.mediaService.navigateToMedia(this.media, {
        type: this.mediaTypeFilter,
        interventionId: this.interventionFilter?._id,
      });
      return;
    }
    this.onSelectedMediaId.emit(this.media._id);
  }

  toggleFavorite(): void {
    this.isFavorite = !this.isFavorite;
    this.toggleFavoriteMedia.emit(this.media);
  }

  async onDeleteMedia() {
    const isDeletingMediaConfirmed = await this.confirmModalService.showConfirmModal(
      'DELETE_MEDIA_CONFIRM_MESSAGE',
      'remove'
    );
    if (isDeletingMediaConfirmed) {
      this.deleteMedia();
    }
  }

  deleteMedia() {
    if (!this.canDelete) {
      alert('No permissions');
      return;
    }
    this.mediaToDelete.emit(this.media._id);
  }

  onDisplaySelectedMediaProperties() {
    this.selectedMediaProperties.emit(this.media);
  }

  // open dialog for Assign to another intervention
  openDialog() {
    const config = {
      id: 'assignMediaToIntervention',
      width: '550px',
      data: {
        media: this.media,
        intervention:
          this.interventionFilter && this.interventionFilter.isClosed !== true ? this.interventionFilter : null,
      },
    };

    const dialogRef = this.dialog.open(MediaInterventionsDialogComponent, config);

    dialogRef.afterClosed().subscribe(async (media: OrganizationMedia) => {
      if (media) {
        this.interventionName = await this.getInterventionName(this.media.intervention as string);
        this.mediaToRefresh.emit(media._id);
      }
    });
  }

  async onShare() {
    this.midRightPanelService.open(MediaShareComponent, { media: this.media });
  }

  downloadMedia(): void {
    const mediaPath = this.getDownloadPath().split('?')[0];
    this.mediaOperationsService.downloadMedia(mediaPath, this.media);
  }

  openPrintDialog(): void {
    const mediaPath = this.getDownloadPath().split('?')[0];
    this.mediaOperationsService.openPrintDialog(mediaPath);
  }

  private async getInterventionName(interventionId: string): Promise<string> {
    if (!interventionId) {
      return;
    }

    const intervention = await firstValueFrom(this.interventionStoreService.getInterventionById(interventionId));

    return intervention?.name;
  }
}
