import { Component, Inject, OnInit } from '@angular/core';
import { OrganizationDto, OrganizationGroupCreationDto, OrganizationGroupService } from 'src/api';
import { MID_RIGHT_PANEL_DATA } from 'src/app/midgard-controls/services/dom.service';
import { MidRightPanelService } from 'src/app/midgard-controls/services/mid-right-panel.service';
import { isEmpty } from 'lodash';

export class NewOrganizationGroupComponentInput {
  org: OrganizationDto;
}

@Component({
  selector: 'app-new-organization-group',
  templateUrl: './new-organization-group.component.html',
  styleUrls: ['./new-organization-group.component.scss'],
})
export class NewOrganizationGroupComponent implements OnInit {
  name: string;
  selectedRole: OrganizationGroupCreationDto.RoleEnum;

  constructor(
    @Inject(MID_RIGHT_PANEL_DATA) public data: NewOrganizationGroupComponentInput,
    private readonly midRightPanelService: MidRightPanelService,
    private readonly organizationGroupService: OrganizationGroupService
  ) {
    this.selectedRole = null;
    this.name = '';
  }

  get isEmpty() {
    return isEmpty;
  }

  ngOnInit(): void {}

  async createGroup() {
    if (!this.selectedRole) return;
    const newGroup = await this.organizationGroupService
      .organizationGroupControllerCreate({
        name: this.name,
        organization: this.data.org.id,
        role: this.selectedRole,
      })
      .toPromise();
    this.midRightPanelService.close(newGroup);
  }

  onRoleSelected(selectedRole: OrganizationGroupCreationDto.RoleEnum) {
    this.selectedRole = selectedRole;
  }
}
