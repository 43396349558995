<div class="interventionID-container">
  <app-intervention-tab-header
    class="interventionID-header"
    [interventionId]="interventionId"
    [intervention]="intervention"
  ></app-intervention-tab-header>

  <div class="interventionID-content">
    <router-outlet></router-outlet>
  </div>
</div>
