import { Injectable, ProviderToken, Type } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TestHelperService {
  private _componentInstances: WeakRef<object>[] = [];

  constructor() {}

  addComponent(instance) {
    this.clean();
    if (this._componentInstances.find((x) => x.deref() === instance)) return;
    this._componentInstances.push(new WeakRef(instance));
  }
  private clean() {
    this._componentInstances = this._componentInstances.filter((x) => x.deref());
  }

  findOne<T>(type: ProviderToken<T>): T {
    console.log({ type });
    this.clean();
    const result = this._componentInstances.find((x) => Object.getPrototypeOf(x.deref()).constructor === type);
    if (!!!result) return null;
    return result.deref() as unknown as T;
  }

  flush() {
    this._componentInstances = [];
  }
}
