import { createFeatureSelector, MemoizedSelector, createSelector } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { DroneState } from './drone.reducer';

export const selectDroneState: MemoizedSelector<AppState, DroneState> = createFeatureSelector('drones');

export const selectOrganizationDronesByOrganizationId = createSelector(
  selectDroneState,
  (state: DroneState, props: { id: string }) => state.groupByOrganizationId[props.id]
);

export const selectDroneById = createSelector(
  selectDroneState,
  (state: DroneState, props: { id: string }) => state.allByDroneId[props.id]
);
