import { ApiService } from 'src/app/core/api';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import _ from 'lodash';
import { Flight } from '../tdraw/models/flight.model';

@Injectable({
  providedIn: 'root',
})
export class DroneTelemetryService {
  constructor(private apiService: ApiService) {}

  async getFlights(interventionId: string): Promise<Flight[]> {
    let res;
    try {
      res = await fetch(
        environment.backend + `drone-telemetry/trajectories/flights?intervention=${interventionId}`
      ).then((response) => response.json());
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return _.map(res, (item) => {
      return Flight.buildFromResponse(item);
    });
  }

  async getFlightWithData(flightId: string): Promise<Flight> {
    let res;
    try {
      res = await fetch(environment.backend + `drone-telemetry/trajectories/flight/${flightId}`).then((response) =>
        response.json()
      );
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return Flight.buildFromResponse(res);
  }

  async getFlightWithDataAfterDate(flightId: string, time: number): Promise<Flight> {
    let res;
    try {
      res = await fetch(environment.backend + `drone-telemetry/trajectories/flight/${flightId}/${time}`).then(
        (response) => response.json()
      );
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return Flight.buildFromResponse(res);
  }
}
