import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlContainer, NgForm, NgModel } from '@angular/forms';
import { MidInputSize } from '../enums/input-size.enum';

@Component({
  selector: 'app-mid-textarea',
  templateUrl: './mid-textarea.component.html',
  styleUrls: ['./mid-textarea.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  exportAs: 'appMidTextarea',
})
export class MidTextareaComponent implements OnInit {
  @Input()
  value: string;
  @Output()
  valueChange: EventEmitter<string>;

  @Input()
  name = 'appMidTextareaName_' + ((Math.random() * 10000) | 0);

  @Input()
  label = '';

  @Input()
  placeholder = '';

  @Input()
  disabled = false;

  @Input()
  required = false;

  @Input()
  size: MidInputSize = MidInputSize.default;

  @Input()
  rows: number;

  @Input()
  cols: number;

  @ViewChild('midTextarea')
  textarea: NgModel;

  constructor() {
    this.valueChange = new EventEmitter();
  }

  ngOnInit() {}

  onValueChange() {
    this.valueChange.emit(this.value);
  }

  get MidInputSize() {
    return MidInputSize;
  }

  get valid() {
    return this.textarea?.valid;
  }

  get invalid() {
    return this.textarea?.invalid;
  }

  get dirty() {
    return this.textarea?.dirty;
  }

  get pristine() {
    return this.textarea?.pristine;
  }

  get touched() {
    return this.textarea?.touched;
  }

  get untouched() {
    return this.textarea?.untouched;
  }
}
