import { GeometryTypes, FeatureCollection } from '@turf/helpers';
import { AnyLayer, Expression } from 'mapbox-gl';
import { ServiceLocator } from 'src/app/global/locator.service';
import { MapIconsService } from '../services/map-icons.service';
import { FeatureProperty } from '../models/feature-property.enum';
import { GeometryType } from '../models/geometry-type.enum';

const Defaults = {
  [FeatureProperty.Color]: '#3bb2d0',
  [FeatureProperty.Opacity]: 0.5,
  [FeatureProperty.Size]: 1,
  [FeatureProperty.Rotation]: 0,
  [FeatureProperty.Icon]: 'default-2',
  [FeatureProperty.Trigram]: '',
};

/**
 * Helpers for Annotation style and source manipulation
 */
export default class AnnotationsHelpers {
  private static useProperty(property: FeatureProperty, isDrawing: boolean = false, overrideDefault?: any): Expression {
    const key = this.getKey(property, isDrawing);
    return ['coalesce', ['get', key], overrideDefault ?? Defaults[property]];
  }

  private static getKey(property: FeatureProperty, isDrawing: boolean = false): string {
    return isDrawing ? `user_${property}` : property;
  }

  private static getPointLayerDef(sourceId: string, data: FeatureCollection): AnyLayer[] {
    const iconKey = data.features[0].properties.icon;
    const mapIconService = ServiceLocator.injector.get(MapIconsService);
    const mapIcon = mapIconService.getMapIcon(iconKey);

    return [
      {
        id: sourceId + '-symbol',
        type: 'symbol',
        source: sourceId,
        layout: {
          visibility: 'none',
          'icon-allow-overlap': true,
          'icon-image': this.useProperty(FeatureProperty.Icon),
          'icon-size': this.useProperty(FeatureProperty.Size),
          'icon-rotate': this.useProperty(FeatureProperty.Rotation),
          'text-field': this.useProperty(FeatureProperty.Trigram),
          'text-font': ['Arial Unicode MS Bold'],
          'text-size': ['*', this.useProperty(FeatureProperty.Size), 11],
          'text-anchor': 'center',
          'text-offset': mapIcon.textOffset,
        },
        filter: ['all', ['in', '$type', 'Point']],
        paint: {
          'text-color': mapIcon.colors[0] ?? 'black',
          'text-halo-color': 'white',
          'text-halo-width': 1,
          'icon-opacity': [
            'case',
            ['boolean', ['feature-state', 'highlight'], false],
            0.5,
            this.useProperty(FeatureProperty.Opacity),
          ],
        },
      },
      {
        id: sourceId + '-highlight',
        type: 'circle',
        source: sourceId,
        layout: {
          visibility: 'none',
        },
        filter: ['all', ['in', '$type', 'Point']],
        paint: {
          'circle-radius': 20,
          'circle-stroke-color': '#fff',
          'circle-stroke-width': 2,
          'circle-stroke-opacity': ['case', ['boolean', ['feature-state', 'highlight'], false], 1, 0],
          'circle-opacity': 0,
        },
      },
      {
        id: sourceId + '-highlight2',
        type: 'circle',
        source: sourceId,
        layout: {
          visibility: 'none',
        },
        filter: ['all', ['in', '$type', 'Point']],
        paint: {
          'circle-radius': 22,
          'circle-stroke-color': '#000',
          'circle-stroke-width': 2,
          'circle-stroke-opacity': ['case', ['boolean', ['feature-state', 'highlight'], false], 1, 0],
          'circle-opacity': 0,
        },
      },
    ];
  }

  private static getPolygonLayerDef(sourceId: string): AnyLayer[] {
    return [
      {
        id: sourceId + '-fill',
        type: 'fill',
        source: sourceId,
        layout: {
          visibility: 'none',
        },
        filter: ['all', ['in', '$type', 'Polygon']],
        paint: {
          'fill-color': this.useProperty(FeatureProperty.Color),
          'fill-opacity': this.useProperty(FeatureProperty.Opacity),
        },
      },
      {
        id: sourceId + '-highlight',
        type: 'line',
        source: sourceId,
        layout: {
          visibility: 'none',
        },
        filter: ['all', ['in', '$type', 'Polygon']],
        paint: {
          'line-color': '#000000',
          'line-opacity': ['case', ['boolean', ['feature-state', 'highlight'], false], 1, 0],
          'line-width': 4,
        },
      },
    ];
  }

  private static getLineStringLayerDef(sourceId: string): AnyLayer[] {
    return [
      {
        id: sourceId + '-normal',
        type: 'line',
        source: sourceId,
        layout: {
          visibility: 'none',
        },
        filter: ['all', ['in', '$type', 'LineString'], ['!has', this.getKey(FeatureProperty.Icon)]],
        paint: {
          'line-color': [
            'case',
            ['boolean', ['feature-state', 'highlight'], false],
            '#000000',
            this.useProperty(FeatureProperty.Color),
          ],
          'line-opacity': [
            'case',
            ['boolean', ['feature-state', 'highlight'], false],
            1,
            this.useProperty(FeatureProperty.Opacity),
          ],
          'line-width': ['case', ['boolean', ['feature-state', 'highlight'], false], 4, 2],
        },
      },
      {
        id: sourceId + '-pattern',
        type: 'line',
        source: sourceId,
        layout: {
          visibility: 'none',
        },
        filter: ['all', ['in', '$type', 'LineString'], ['has', this.getKey(FeatureProperty.Icon)]],
        paint: {
          'line-opacity': [
            'case',
            ['boolean', ['feature-state', 'highlight'], false],
            1,
            this.useProperty(FeatureProperty.Opacity),
          ],
          'line-width': ['case', ['boolean', ['feature-state', 'highlight'], false], 16, 12],
          'line-pattern': ['get', this.getKey(FeatureProperty.Icon)],
        },
      },
    ];
  }

  /**
   * TODO: Not in use yet, but could be useful someday
   * @param sourceId
   */
  private static getConeLayerDef(sourceId: string) {
    return [
      {
        id: sourceId,
        type: 'line',
        source: sourceId,
        layout: {
          visibility: 'none',
        },
        filter: ['all', ['case', ['has', 'isCone'], ['get', 'isCone'], false]],
        paint: {
          'line-color': '#414eff',
          'line-opacity': 1,
          'line-width': 3,
        },
      },
    ];
  }

  static getLayerDef(sourceId: string, type: GeometryTypes, data: FeatureCollection): AnyLayer[] {
    switch (type) {
      case GeometryType.Point:
        return this.getPointLayerDef(sourceId, data);
      case GeometryType.Polygon:
        return this.getPolygonLayerDef(sourceId);
      case GeometryType.LineString:
        return this.getLineStringLayerDef(sourceId);
      case GeometryType.MultiLineString:
        // TODO: Use something else than GeometryTypes to identify special features
        return this.getLineStringLayerDef(sourceId);
    }
    console.error('getLayerDef: invalid GeometryType', type);
    return null;
  }

  /**
   * Get the styles used by Mapbox Draw
   */
  static getRegularDrawStyles() {
    return [
      {
        // Polygon: fill
        id: 'gl-draw-polygon',
        type: 'fill',
        filter: ['all', ['==', '$type', 'Polygon']],
        paint: {
          'fill-color': this.useProperty(FeatureProperty.Color, true),
          'fill-opacity': this.useProperty(FeatureProperty.Opacity, true),
        },
      },
      {
        // Line: stroke
        id: 'gl-draw-line',
        type: 'line',
        filter: ['all', ['==', '$type', 'LineString']],
        layout: {
          'line-cap': 'round',
          'line-join': 'round',
        },
        paint: {
          'line-color': this.useProperty(FeatureProperty.Color, true),
          'line-opacity': this.useProperty(FeatureProperty.Opacity, true),
          'line-width': 2,
        },
      },
      {
        // Polygon and Line Vertex (unselected): outline
        id: 'gl-draw-polygon-and-line-vertex-stroke-inactive',
        type: 'circle',
        filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'vertex'], ['==', 'active', 'false']],
        paint: {
          'circle-radius': 5,
          'circle-color': '#fff',
        },
      },
      {
        // Polygon and Line Vertex (unselected): center
        id: 'gl-draw-polygon-and-line-vertex-inactive',
        type: 'circle',
        filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'vertex'], ['==', 'active', 'false']],
        paint: {
          'circle-radius': 3,
          'circle-color': '#fbb03b',
        },
      },
      {
        // Polygon and Line Vertex (selected): outline
        id: 'gl-draw-point-stroke-active',
        type: 'circle',
        filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'vertex'], ['==', 'active', 'true']],
        paint: {
          'circle-radius': 7,
          'circle-color': '#fff',
        },
      },
      {
        // Polygon and Line Vertex (selected): center
        id: 'gl-draw-point-active',
        type: 'circle',
        filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'vertex'], ['==', 'active', 'true']],
        paint: {
          'circle-radius': 5,
          'circle-color': '#fbb03b',
        },
      },
      {
        // Point: icon
        id: 'gl-draw-point',
        type: 'symbol',
        filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'feature']],
        layout: {
          'icon-allow-overlap': true,
          'icon-ignore-placement': true,
          'icon-image': this.useProperty(FeatureProperty.Icon, true),
          'icon-size': this.useProperty(FeatureProperty.Size, true),
          'icon-rotate': this.useProperty(FeatureProperty.Rotation, true),
          'text-field': this.useProperty(FeatureProperty.Trigram, true),
          'text-font': ['Arial Unicode MS Bold'],
          'text-size': ['*', this.useProperty(FeatureProperty.Size, true), 11],
          'text-anchor': 'center',
          'text-offset': [0, 1.6],
        },
        paint: {
          'text-color': 'black',
          'text-halo-color': 'white',
          'text-halo-width': 1,
          'icon-opacity': this.useProperty(FeatureProperty.Opacity, true),
        },
      },
      {
        // Selected Point: outline of circle in the middle
        id: 'gl-draw-point-outline-active',
        type: 'circle',
        filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'feature'], ['==', 'active', 'true']],
        paint: {
          'circle-radius': 7,
          'circle-color': '#fff',
        },
      },
      {
        // Selected Point: center of circle in the middle
        id: 'gl-draw-point-center-active',
        type: 'circle',
        filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'feature'], ['==', 'active', 'true']],
        paint: {
          'circle-radius': 5,
          'circle-color': '#fbb03b',
        },
      },
      {
        // Polygon and Line: midpoint
        id: 'gl-draw-midpoint',
        type: 'circle',
        filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
        paint: {
          'circle-radius': 3,
          'circle-color': '#fbb03b',
        },
      },
    ];
  }

  /**
   * Get the styles used when drawing lines with symbol pattern
   */
  static getLinePatternDrawStyles(iconKey: string) {
    return [
      {
        // Line: pattern
        id: 'gl-draw-line-pattern',
        type: 'line',
        filter: ['all', ['==', '$type', 'LineString']],
        layout: {
          'line-cap': 'round',
          'line-join': 'round',
        },
        paint: {
          'line-opacity': this.useProperty(FeatureProperty.Opacity, true),
          'line-width': 12,
          'line-pattern': this.useProperty(FeatureProperty.Icon, true, iconKey),
        },
      },
      {
        // Polygon and Line Vertex (unselected): outline
        id: 'gl-draw-polygon-and-line-vertex-stroke-inactive',
        type: 'circle',
        filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'vertex'], ['==', 'active', 'false']],
        paint: {
          'circle-radius': 5,
          'circle-color': '#fff',
        },
      },
      {
        // Polygon and Line Vertex (unselected): center
        id: 'gl-draw-polygon-and-line-vertex-inactive',
        type: 'circle',
        filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'vertex'], ['==', 'active', 'false']],
        paint: {
          'circle-radius': 3,
          'circle-color': '#fbb03b',
        },
      },
      {
        // Polygon and Line Vertex (selected): outline
        id: 'gl-draw-point-stroke-active',
        type: 'circle',
        filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'vertex'], ['==', 'active', 'true']],
        paint: {
          'circle-radius': 7,
          'circle-color': '#fff',
        },
      },
      {
        // Polygon and Line Vertex (selected): center
        id: 'gl-draw-point-active',
        type: 'circle',
        filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'vertex'], ['==', 'active', 'true']],
        paint: {
          'circle-radius': 5,
          'circle-color': '#fbb03b',
        },
      },
      {
        // Polygon and Line: midpoint
        id: 'gl-draw-midpoint',
        type: 'circle',
        filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
        paint: {
          'circle-radius': 3,
          'circle-color': '#fbb03b',
        },
      },
    ];
  }

  /**
   * This style is intended to hide vertices that shows up
   * when user is able to perform modifications to a feature
   */
  static getConeDrawStyles() {
    return [
      {
        // Cone highlight
        id: 'gl-draw-polygon-stroke-inactive',
        type: 'line',
        filter: ['all', ['!=', '$type', 'Point']],
        layout: {
          'line-cap': 'round',
          'line-join': 'round',
        },
        paint: {
          'line-color': '#ffffff',
          'line-blur': 10,
          'line-width': 10,
          'line-opacity': 0.3,
        },
      },
      {
        // Cone
        id: 'gl-draw-line-inactive',
        type: 'line',
        filter: ['all', ['==', '$type', 'LineString']],
        layout: {
          'line-cap': 'round',
          'line-join': 'round',
        },
        paint: {
          'line-color': this.useProperty(FeatureProperty.Color, true),
          'line-opacity': this.useProperty(FeatureProperty.Opacity, true),
          'line-width': 2,
        },
      },
    ];
  }
}
