<div class="row m-0 p-0">
  <!-- content -->
  <div class="col-12 m-0 p-0">
    <div class="row m-0 p-0">
      <div class="col-12 m-0 p-0 pt-1 pb-1 mid-activity-stream-simple-text-content">
        {{ data.text | translate }}
      </div>
    </div>
  </div>
</div>
