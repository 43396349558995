import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrganizationsComponent } from './organizations/organizations.component';
import { AdministrationComponent } from './administration/administration.component';
import { FormsModule } from '@angular/forms';
import { CoreModule } from '../core/core.module';
import { RouterModule } from '@angular/router';
import { UserManagementModule } from '../user-management/user-management.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MidgardControlsModule } from 'src/app/midgard-controls/midgard-controls.module';
import { FeaturesDialogComponent } from './organizations/features-dialog/features-dialog.component';

@NgModule({
  declarations: [OrganizationsComponent, AdministrationComponent, FeaturesDialogComponent],
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    RouterModule,
    UserManagementModule,
    MatDialogModule,
    MatGridListModule,
    MatMenuModule,
    MatCardModule,
    MidgardControlsModule,
  ],
  exports: [OrganizationsComponent, AdministrationComponent],
})
export class AdministrationModule {}
