import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MidIconButtonType } from '../enums/icon-button-type.enum';

@Component({
  selector: 'app-mid-icon-button',
  templateUrl: './mid-icon-button.component.html',
  styleUrls: ['./mid-icon-button.component.scss'],
})
export class MidIconButtonComponent {
  @Input()
  type: MidIconButtonType;

  @Input()
  disabled: boolean;

  @Input()
  shadow: boolean = true;

  @Input()
  bgColor?: string;

  @Output()
  action: EventEmitter<any>;

  get MidIconButtonType() {
    return MidIconButtonType;
  }

  constructor() {
    this.action = new EventEmitter();
  }

  onClick(event) {
    this.action.emit(event);
  }
}
