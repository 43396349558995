import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { SetCurrentUserAction } from './user-profiles.actions';
import { selectCurrentUser } from './user-profiles.selectors';
import { required, validate } from 'src/app/shared';
import { User } from 'src/app/autogenerated/model';

@Injectable({
  providedIn: 'root',
})
export class UserProfilesStoreService {
  private readonly store: Store<AppState>;

  constructor(store: Store<AppState>) {
    this.store = store;
  }

  @validate
  setCurrentUser(@required user: User) {
    this.store.dispatch(new SetCurrentUserAction(user));
  }

  getCurrentUser(): Observable<User> {
    const currentUser$ = this.store.select(selectCurrentUser);
    return currentUser$;
  }
}
