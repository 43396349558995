import { Feature } from './feature';
import { validate, required } from '../../shared';
import { reduce, has, values, cloneDeep } from 'lodash';
import { featuresConfig } from './features.config';

export class FeaturesManager {
  private static featuresMap: StringMap<Feature>;

  static getFeatures() {
    const featuresMap = cloneDeep(FeaturesManager.featuresMap || {});
    const features = values(featuresMap);
    return features;
  }

  @validate
  static intitialize(@required featuresDefaults: Feature[], featuresOverrides: StringMap<boolean> = {}) {
    this.featuresMap = reduce(
      featuresDefaults,
      (result: StringMap<Feature>, featureDefault: Feature) => {
        const isOverrided = has(featuresOverrides, featureDefault.id);
        const isFeatureEnabled = isOverrided
          ? !!featuresOverrides[featureDefault.id]
          : featureDefault.isEnabledByDefault;

        const feature = { ...featureDefault, isEnabled: isFeatureEnabled };
        result[featureDefault.id] = feature;

        return result;
      },
      {}
    );
  }

  @validate
  static getFeatureById(@required featureId: string): Feature {
    const featuresMap = FeaturesManager.featuresMap || {};
    const feature = featuresMap[featureId] || featuresConfig.undefinedFeature;

    return feature;
  }

  @validate
  static checkIsFeatureEnabled(@required featureId: string): boolean {
    const feature = FeaturesManager.getFeatureById(featureId);

    const isEnabled = feature.isEnabled;

    return isEnabled;
  }
}
