import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

export enum MediaSourceType {
  File = 'file',
  Folder = 'folder',
  MediaLibrary = 'mediaLibrary',
}

interface MediaSourceButtonToggles {
  value: string;
  name: string;
  color: string;
}

@Component({
  selector: 'app-select-media-button-toggle',
  templateUrl: './select-media-button-toggle.component.html',
  styleUrls: ['./select-media-button-toggle.component.scss'],
})
export class SelectMediaButtonToggleComponent implements OnInit {
  @Input()
  label: string = null;

  @Input()
  type: MediaSourceType = null;
  @Output()
  typeChange: EventEmitter<MediaSourceType> = new EventEmitter<MediaSourceType>();

  required: boolean = true;

  mediaSourceButtonToggles: MediaSourceButtonToggles[];

  constructor(private readonly translate: TranslateService) {}

  @ViewChild('mediaSourceModel')
  private mediaSourceModel: NgModel;

  async ngOnInit(): Promise<void> {
    this.mediaSourceButtonToggles = [
      {
        value: MediaSourceType.File,
        name: await this.translate.get('INTERVENTION_ANALYSES.MEDIA_SOURCE_FILE').toPromise(),
        color: '#f0a33f',
      },
      {
        value: MediaSourceType.Folder,
        name: await this.translate.get('INTERVENTION_ANALYSES.MEDIA_SOURCE_DIRECTORY').toPromise(),
        color: '#f0a33f',
      },
      {
        value: MediaSourceType.MediaLibrary,
        name: await this.translate.get('INTERVENTION_ANALYSES.MEDIA_SOURCE_LIBRARY').toPromise(),
        color: '#f0a33f',
      },
    ];
  }

  onTypeChange(event) {
    this.typeChange.emit(event);
  }

  get valid() {
    return this.mediaSourceModel?.valid;
  }

  get invalid() {
    return this.mediaSourceModel?.invalid;
  }

  get dirty() {
    return this.mediaSourceModel?.dirty;
  }

  get pristine() {
    return this.mediaSourceModel?.pristine;
  }

  get touched() {
    return this.mediaSourceModel?.touched;
  }

  get untouched() {
    return this.mediaSourceModel?.untouched;
  }
}
