import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { MidRightPanelService } from '../services/mid-right-panel.service';

@Component({
  selector: 'app-mid-right-panel',
  templateUrl: './mid-right-panel.component.html',
  styleUrls: ['./mid-right-panel.component.scss'],
  exportAs: 'appMidRightPanel',
})
export class MidRightPanelComponent {
  @Input()
  title = '';

  @Input()
  isMainMenu = false;

  @Input()
  closeCallback: Function = null;

  @Output()
  opened: EventEmitter<boolean> = new EventEmitter();

  private readonly RIGHT_PANEL_WIDTH = 370;

  constructor(private readonly midRightPanelService: MidRightPanelService) {}

  close(): void {
    if (this.closeCallback?.call) {
      this.closeCallback();
      return;
    }
    this.midRightPanelService.close();
  }

  onClickInside($event): void {
    if ($event) {
      $event.stopPropagation();
    }
  }

  @HostListener('document:mousedown', ['$event'])
  onClickOutside($event): void {
    if ($event.x < window.innerWidth - this.RIGHT_PANEL_WIDTH) {
      this.close();
    }
  }
}
