import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { required, RouterUtils, validate } from '../shared';

@Injectable({
  providedIn: 'root',
})
export class MapStateIdResolverService {
  @validate
  resolve(@required route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> {
    const mapStateId = RouterUtils.getRouteParamRecursively('mapStateId', route);
    return of(mapStateId);
  }
}
