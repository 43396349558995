import { Component, Inject } from '@angular/core';
import { MidRightPanelService } from 'src/app/midgard-controls/services/mid-right-panel.service';
import { IMediaFilter } from '../media-page/media-page.component';
import { rightPanelDisplayState } from '../../midgard-controls/enums/display-state.enum';
import { MID_RIGHT_PANEL_DATA } from 'src/app/midgard-controls/services/dom.service';

@Component({
  selector: 'app-media-filter',
  templateUrl: './media-filter.component.html',
  styleUrls: ['./media-filter.component.scss'],
})
export class MediaFilterComponent {
  mediaFilter: IMediaFilter;

  startDateSelected: Date;
  endDateSelected: Date;

  get MediaRightPanelDisplayState() {
    return rightPanelDisplayState;
  }

  constructor(
    private readonly midRightPanelService: MidRightPanelService,
    @Inject(MID_RIGHT_PANEL_DATA) private readonly data: { mediaFilter: IMediaFilter }
  ) {
    this.mediaFilter = data.mediaFilter;
    if (!this.mediaFilter?.more) {
      this.mediaFilter.more = {};
    }
    this.startDateSelected = this.mediaFilter.more?.startDate || null;
    this.endDateSelected = this.mediaFilter.more?.endDate || null;
  }

  onClose(): Function {
    const that = this;
    return () => that.midRightPanelService.close(that.mediaFilter);
  }

  onFilter() {
    this.mediaFilter.more.startDate = this.startDateSelected;
    this.mediaFilter.more.endDate = this.endDateSelected;
    this.midRightPanelService.close(this.mediaFilter);
  }

  onReset(): void {
    this.mediaFilter.more = null;
    this.midRightPanelService.close(this.mediaFilter);
  }
}
