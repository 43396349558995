import { Injectable } from '@angular/core';
import { Configuration } from '../api';
import { AuthenticationStoreService } from 'src/app/authentication/store';
import { environment } from '../environments/environment';
import { AccessTokenAuthenticationService } from './authentication/access-code-authentication.service';

@Injectable({
  providedIn: 'root',
})
export class MidgardApiConfiguration extends Configuration {
  private accountToken: string;
  private codeToken: string;
  constructor(
    private authenticationStoreService: AuthenticationStoreService,
    private accessTokenAuthenticationService: AccessTokenAuthenticationService
  ) {
    super({
      basePath: environment.backend.substring(0, environment.backend.length - 1),
      accessToken: () => {
        if (!!this.accountToken) return this.accountToken;
        if (!!this.codeToken) return this.codeToken;
        return null;
      },
    });
    this.authenticationStoreService.getAccessToken().subscribe((token) => (this.accountToken = token));
    this.accessTokenAuthenticationService.getAccessTokenObservable().subscribe((token) => (this.codeToken = token));
    this.codeToken = this.accessTokenAuthenticationService.token;
  }
}
