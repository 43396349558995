import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { InterventionService } from 'src/app/autogenerated/interventionService';
import { Drone, Intervention } from 'src/app/autogenerated/model';
import { InterventionStoreService } from '../../../store';
import { MidSelectItem } from '../../../../midgard-controls/models';
import { OrganizationService } from '../../../../autogenerated/organizationService';
import { DroneStoreService } from '../../../../drone/store';
import { Unsubscribe } from '../../../../core/decorators';
import { OrganizationStoreService } from '../../../../organization/store';
import { MidRightPanelService } from 'src/app/midgard-controls/services/mid-right-panel.service';
import { DroneService } from 'src/api';

@Unsubscribe()
@Component({
  selector: 'app-create-intervention',
  templateUrl: './create-intervention.component.html',
  styleUrls: ['./create-intervention.component.scss'],
})
export class CreateInterventionComponent implements OnInit, OnDestroy {
  organizationId: string;
  state: {
    isLoading: boolean;
    isFailed: boolean;
  };
  errorMessage: string;

  data: {
    intervention: Intervention;
    drones: MidSelectItem[];
  };
  defaultInterventionName: string;

  private organizationSubscription: Subscription;
  private interventionsSubscription: Subscription;
  private dronesSubscription: Subscription;

  constructor(
    private readonly translate: TranslateService,
    private readonly interventionService: InterventionService,
    private readonly interventionStoreService: InterventionStoreService,
    private readonly droneStoreService: DroneStoreService,
    private readonly droneService: DroneService,
    private readonly organizationStoreService: OrganizationStoreService,
    private readonly router: Router,
    private readonly organizationService: OrganizationService,
    private readonly midRightPanelService: MidRightPanelService
  ) {
    this.data = {
      intervention: new Intervention({
        type: undefined,
      }),
      drones: [],
    };

    this.state = {
      isLoading: false,
      isFailed: false,
    };
  }

  async ngOnInit(): Promise<void> {
    this.organizationSubscription = this.organizationStoreService
      .getCurrentOrganization()
      .subscribe(async (organization) => {
        this.organizationId = organization ? organization._id : null;
        if (!this.organizationId) {
          return;
        }

        this.updateInterventionsSubscription(organization._id);
        this.updateDronesSubscription(organization._id);
        await this.loadDrones(this.organizationId);
      });
  }

  private updateDronesSubscription(organizationId: string): void {
    if (!organizationId) {
      return;
    }

    if (this.dronesSubscription) {
      this.dronesSubscription.unsubscribe();
      this.dronesSubscription = null;
    }

    this.dronesSubscription = this.droneStoreService.getOrganizationDrones(organizationId).subscribe((drones) => {
      this.data.drones = this.convertDronesToMidSelectItem(drones);
    });
  }

  private updateInterventionsSubscription(organizationId: string): void {
    if (!organizationId) {
      return;
    }

    if (this.interventionsSubscription) {
      this.interventionsSubscription.unsubscribe();
      this.interventionsSubscription = null;
    }

    this.interventionsSubscription = this.interventionStoreService
      .getOrganizationInterventions(organizationId)
      .subscribe(async (interventions) => {
        this.defaultInterventionName = await this.getNextInterventionName(interventions);
      });
  }

  close(): void {
    this.midRightPanelService.close();

    this.organizationSubscription?.unsubscribe();
    this.organizationSubscription = null;
  }

  async create(organizationId: string): Promise<void> {
    this.state.isLoading = true;
    this.state.isFailed = false;

    try {
      const intervention: Intervention = await this.interventionService.createInterventionV1(organizationId, {
        interventionType: this.data.intervention.type,
        nature: this.data.intervention.nature,
        motive: this.data.intervention.motive,
        interventionNumber: this.data.intervention.interventionNumber,
        name: this.data.intervention.name || this.defaultInterventionName,
        city: this.data.intervention.city,
      });
      this.interventionStoreService.addOrganizationIntervention(this.organizationId, intervention);
      const selectedDrone = this.data.drones.find((drone) => drone.isSelected);
      if (selectedDrone) {
        await this.interventionService.attachDroneToInterventionV1(
          this.organizationId.toString(),
          intervention._id,
          selectedDrone.value
        );
      }
      this.close();
      await this.router.navigate(['interventions', intervention?._id]);
    } catch (ex) {
      this.errorMessage = ex.message;
      this.state.isFailed = true;
    } finally {
      this.state.isLoading = false;
    }
  }

  ngOnDestroy(): void {}

  private async loadDrones(organizationId: string): Promise<void> {
    this.state.isLoading = true;
    this.state.isFailed = false;

    try {
      const drones = await this.droneService.orgDroneControllerGetOrgDrones(organizationId).toPromise();
      this.droneStoreService.setOrganizationDrones(organizationId, drones);
    } catch (error) {
      console.error(error);
      this.data.drones = [];
      this.state.isFailed = true;
    } finally {
      this.state.isLoading = false;
    }
  }

  private convertDronesToMidSelectItem(drones: Drone[]): MidSelectItem[] {
    if (drones) {
      const midSelectItems = drones.map((drone) => {
        const midSelectItem: MidSelectItem = {
          value: drone._id,
          name: drone.name,
          isSelected: false,
        };
        return midSelectItem;
      });
      return midSelectItems;
    }
    return [];
  }

  private async getNextInterventionName(interventions: Intervention[]): Promise<string> {
    let counter = interventions?.length || 0;
    let interventionName = '';
    let isUnique = false;
    let whileEmergencyExitCounter = 1000;

    while (!isUnique || whileEmergencyExitCounter === 0) {
      counter = counter + 1;
      const interventionLocalized = await this.translate.get('COMMON.INTERVENTION').toPromise();
      interventionName = `${interventionLocalized} ${counter}`;
      isUnique = this.isUniqueName(interventionName, interventions);
      whileEmergencyExitCounter = whileEmergencyExitCounter - 1;
    }

    return interventionName;
  }

  private isUniqueName(interventionName: string, existingInterventions: Intervention[]): boolean {
    const foundIntervention = existingInterventions?.find(
      (intervention: Intervention) => intervention.name?.toLowerCase() === interventionName.toLowerCase()
    );
    return !foundIntervention;
  }
}
