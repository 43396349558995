import { Component, OnDestroy, OnInit } from '@angular/core';
import { Intervention, Organization } from 'src/app/autogenerated/model';
import { TemplateService } from 'src/app/global/template.service';
import { InterventionTabContentComponent } from '../intervention-tab-container/intervention-tab-container.component';

@Component({
  selector: 'app-intervention-tab-settings',
  templateUrl: './intervention-tab-settings.component.html',
  styleUrls: ['./intervention-tab-settings.component.scss'],
})
export class InterventionTabSettingsComponent implements InterventionTabContentComponent, OnInit, OnDestroy {
  intervention: Intervention;
  organization: Organization;
  hasAdminPermissions: boolean;

  constructor(private templateService: TemplateService) {}

  ngOnInit(): void {
    this.templateService.$activeComponent.next('INTERVENTION.TAB.SETTINGS');
  }

  ngOnDestroy(): void {
    // for Unsubscribe
  }
}
