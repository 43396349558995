import { BackendClientService } from '../../global/backend-client.service';
import { ApiService } from 'src/app/core/api';
import _ from 'lodash';
import { MapStateCreationDto, MapStateUpdateDto } from 'src/app/tdraw/models/dto';
import { environment } from 'src/environments/environment';
import { MapState } from 'src/app/tdraw/models/map-state.model';
import { SharedMapStateCreationDto } from 'src/app/tdraw/models/dto/shared-map-state-creation.dto';
import { SharedMapState } from 'src/app/tdraw/models/shared-map-state.model';

export class TdrawServiceAutoGenerated {
  constructor(private beClient: BackendClientService, private apiService: ApiService) {}

  async getMapState(organizationId: string, interventionId: string, mapStateId: string): Promise<MapState> {
    let result;

    try {
      result = await this.beClient
        .get(`api/v1/organization/${organizationId}/intervention/${interventionId}/cartography/${mapStateId}`)
        .toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }

    return MapState.buildFromMapStateResponse(result);
  }

  async getMapStates(organizationId: string, interventionId: string): Promise<MapState[]> {
    let response;
    try {
      response = await this.beClient
        .get(`api/v1/organization/${organizationId}/intervention/${interventionId}/cartography`)
        .toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }

    return _.map(response, (item) => {
      return MapState.buildFromMapStateResponse(item);
    });
  }

  async shareMap(input: SharedMapStateCreationDto): Promise<SharedMapState> {
    try {
      const result = await this.beClient.post(`api/v1/shared-map-states`, input).toPromise();
      return SharedMapState.buildFromSharedMapStateResponse(result);
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }

  async getSharedMapState(sharedMapStateId: string): Promise<SharedMapState> {
    try {
      const result = await this.beClient.get(`api/v1/shared-map-states/${sharedMapStateId}`).toPromise();
      return SharedMapState.buildFromSharedMapStateResponse(result);
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }

  async getMapStateShares(mapStateId: string): Promise<SharedMapState[]> {
    try {
      const result: any = await this.beClient.get(`api/v1/shared-map-states/by-map-state/${mapStateId}`).toPromise();

      return _.map(result, (item) => {
        return SharedMapState.buildFromSharedMapStateResponse(item);
      });
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }

  async getSharedMapStateData(sharedMapStateId: string): Promise<MapState> {
    try {
      const result = await this.beClient.get(`api/v1/shared-map-states/${sharedMapStateId}/data`).toPromise();
      return MapState.buildFromMapStateResponse(result);
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }

  async deleteSharedMapState(sharedMapStateId: string) {
    try {
      const result = await this.beClient.delete('api/v1/shared-map-states', sharedMapStateId).toPromise();
      return result;
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }

  async createMap(organizationId: string, interventionId: string, input: MapStateCreationDto) {
    try {
      const result = await this.beClient
        .post(`api/v1/organization/${organizationId}/intervention/${interventionId}/cartography`, input)
        .toPromise();
      return result;
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }

  async deleteMap(organizationId: string, interventionId: string, mapStateId: string) {
    try {
      const result = await this.beClient
        .delete(`api/v1/organization/${organizationId}/intervention/${interventionId}/cartography`, mapStateId)
        .toPromise();
      return result;
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }

  async publishMap(organizationId: string, interventionId: string, mapStateId: string, snapshot?: string) {
    try {
      const result = await this.beClient
        .put(`api/v1/organization/${organizationId}/intervention/${interventionId}/cartography/${mapStateId}/publish`, {
          snapshot,
        })
        .toPromise();
      return result;
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }

  async duplicateMap(organizationId: string, interventionId: string, mapStateId: string): Promise<MapState> {
    try {
      const result = await this.beClient
        .post<MapState>(
          `api/v1/organization/${organizationId}/intervention/${interventionId}/cartography/${mapStateId}/duplicate`,
          undefined
        )
        .toPromise();

      return result;
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }

  async updateMap(organizationId: string, interventionId: string, mapStateGuid: string, input: MapStateUpdateDto) {
    try {
      const result = await this.beClient
        .put(`api/v1/organization/${organizationId}/intervention/${interventionId}/cartography/${mapStateGuid}`, input)
        .toPromise();
      return result;
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }

  getSharedMapStateBackgroundImageDownloadUrl(sharedMapStateId: string): string {
    return environment.backend + `api/v1/shared-map-states/${sharedMapStateId}/background-image`;
  }

  public getSnapshotDownloadUrl(organizationId: string, interventionId: string, mapState: MapState) {
    if (!mapState) return '';
    const mapStateId = mapState._id;
    return `${environment.backend}api/v1/organization/${organizationId}/intervention/${interventionId}/cartography/snapshot/${mapStateId}`;
  }
}
