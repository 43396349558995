<ng-container [ngSwitch]="displayState">
  <div
    class="right-toggle-button"
    *ngIf="type === RightPanelType.toggle"
    [class.right-sticked-btn]="displayState === MediaRightPanelDisplayState.hidden"
  >
    <button (click)="toggle()">
      <i
        class="fas"
        [class.fa-chevron-right]="displayState !== MediaRightPanelDisplayState.hidden"
        [class.fa-chevron-left]="displayState === MediaRightPanelDisplayState.hidden"
      ></i>
    </button>
  </div>

  <div
    [class.media-right-panel]="type === RightPanelType.default"
    [class.toggle-media-right-panel]="type === RightPanelType.toggle"
    *ngSwitchCase="MediaRightPanelDisplayState.displaying"
  >
    <app-mid-icon-button
      *ngIf="type === RightPanelType.default"
      class="close-icon"
      type="primary"
      shadow="false"
      (action)="onClose()"
    >
      <i class="fas fa-times"></i>
    </app-mid-icon-button>

    <div class="right-panel-container">
      <div class="main-title">
        <h2 class="title">{{ 'MEDIA_RIGHT_PANEL.MEDIA_PROPERTIES' | translate }}</h2>
      </div>

      <ng-container *ngIf="sharingMode === GPSSharingMode.SMS">
        <div class="row m-0 px-4 pb-4">
          <div class="section col-md-12">
            <form #formShareBySMS="ngForm">
              <div class="wrapper">
                <app-mid-input-phone-number
                  size="wide"
                  [label]="'DIALOGS.SHARE_MEDIA_LINK.PHONE_NUMBER' | translate"
                  [required]="true"
                  #phone="appMidPhoneNumberInput"
                  name="phoneNumber"
                  [(value)]="dataToShare.phoneNumber"
                >
                  <span class="text-danger" *ngIf="!formShareBySMS.pristine && phone.invalid">
                    {{ 'DIALOGS.SHARE_MEDIA_LINK.PHONE_VALIDATOR_LABEL' | translate }}</span
                  >
                </app-mid-input-phone-number>
                <section class="section function-buttons">
                  <div class="media-share-gps">
                    <app-mid-button
                      class="w-100 px-1"
                      type="primary"
                      size="wide"
                      (action)="sendSMS()"
                      [disabled]="formShareBySMS.form?.invalid"
                    >
                      {{ 'DIALOGS.SHARE_MEDIA_LINK.SHARE_MEDIA_LINK_WITH_LOCATION' | translate }}
                    </app-mid-button>
                    <app-mid-button class="w-100 px-1" type="secondary" size="wide" (action)="cancel()">
                      {{ 'DIALOGS.SHARE_MEDIA_LINK.SHARE_BY_BACK' | translate }}
                    </app-mid-button>
                  </div>
                </section>
              </div>
            </form>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!sharingMode">
        <form name="formMediaProperties" #formMediaProperties="ngForm" novalidate>
          <div class="row m-0 px-4 pb-4 w-100">
            <div class="right-panel-section col-md-12">
              <h3 class="section-title">{{ 'MEDIA_RIGHT_PANEL.SECTION.DETAILS' | translate }}</h3>

              <app-mid-input
                size="wide"
                [(value)]="currentMedia.mediaName"
                #mediaName
                name="mediaName"
                [required]="true"
                [label]="'MEDIA_RIGHT_PANEL.MEDIA_NAME' | translate"
                [placeholder]="'MEDIA_RIGHT_PANEL.MEDIA_NAME' | translate"
              >
                <span *ngIf="!formMediaProperties.pristine && mediaName.invalid" class="text-danger font-weight-bold">{{
                  'COMMON_REQUIRED_FIELD' | translate
                }}</span>
              </app-mid-input>

              <div class="pt-3"></div>

              <div class="info">
                <span>{{ 'MEDIA_RIGHT_PANEL.MEDIA_IMPORTED' | translate }} </span>
                <span>{{ currentMedia.mediaDate.importedDate }} </span>
                <span>{{ 'AT' | translate }} </span>
                <span>{{ currentMedia.mediaDate.importedTime | date : 'HH:mm:ss' }} </span>
              </div>

              <div class="info" *ngIf="currentMedia.source">
                <span>{{ 'MEDIA_RIGHT_PANEL.MEDIA_SOURCE' | translate }} </span>
                <span>{{ currentMedia.source }}</span>
              </div>

              <div class="info" *ngIf="currentMedia.streamDate">
                <span>{{ 'MEDIA_RIGHT_PANEL.MEDIA_STREAM_STARTED' | translate }} </span>
                <span>{{ currentMedia.streamDate.startedDate }} </span>
                <span>{{ 'AT' | translate }} </span>
                <span>{{ currentMedia.streamDate.startedTime | date : 'HH:mm:ss' }} </span>
              </div>
            </div>

            <div class="right-panel-section col-md-12" *ngIf="hasMediaGPSLngLat()">
              <h3 class="section-title">{{ 'MEDIA_RIGHT_PANEL.SECTION.GEOLOCATION' | translate }}</h3>
              <div id="create-media-map"></div>
              <div class="info">{{ currentMedia.gps?.latitude }} {{ currentMedia.gps?.longitude }}</div>
              <app-mid-button
                type="primary"
                [disabled]="!hasMediaGPSLngLat()"
                (action)="shareUsingMode(GPSSharingMode.SMS)"
              >
                {{ 'MEDIA_RIGHT_PANEL.SECTION.SEND_GPS_POSITION_WITH_SMS' | translate }}
              </app-mid-button>
            </div>

            <div class="right-panel-section col-md-12">
              <h3 class="section-title">{{ 'MEDIA_RIGHT_PANEL.SECTION.TAGS' | translate }}</h3>
              <app-mid-chip-list
                [(values)]="currentMedia.mediaTags"
                #interventionTags="appMidChipList"
                [placeholder]="'MEDIA_RIGHT_PANEL.MEDIA_TAGS_PLACEHOLDER' | translate"
              ></app-mid-chip-list>
              <!--
                <app-media-tags [media]="media" (updatedMediaTags)="updateMediaTags($event)"></app-media-tags>
              -->
            </div>
          </div>
        </form>

        <div class="answer-buttons">
          <app-mid-button
            type="primary"
            [size]="MidButtonSize.default"
            (action)="save()"
            [disabled]="formMediaProperties.invalid || formMediaProperties.pristine"
          >
            <!-- [disabled]="checkToSave()" -->
            {{ 'SAVE' | translate }}
          </app-mid-button>
          <app-mid-button type="secondary" [size]="MidButtonSize.default" (action)="onClose()">
            {{ 'COMMON_CANCEL' | translate }}
          </app-mid-button>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
