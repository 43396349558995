import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlContainer, NgForm, NgModel } from '@angular/forms';
import { EngagedResources } from '../models';

@Component({
  selector: 'app-mid-statistics-engaged-resources',
  templateUrl: './mid-statistics-engaged-resources.component.html',
  styleUrls: ['./mid-statistics-engaged-resources.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  exportAs: 'appMidStatisticsEngagedResources',
})
export class MidStatisticsEngagedResourcesComponent implements OnInit, OnChanges {
  @Input()
  items: EngagedResources[];

  @Input()
  isEditable: boolean;

  @Input()
  isExpanded: boolean;

  @Output()
  itemsChange: EventEmitter<EngagedResources[]>;

  get total(): number {
    return this.items?.reduce((sum, i) => sum + i.value, 0);
  }

  name = 'appMidStatisticsEngagedResources_' + ((Math.random() * 10000) | 0);
  @ViewChild('appMidStatisticsEngagedResources')
  input: NgModel;
  newResourceName = '';

  private readonly defaultEngagedResources = [
    { name: 'SAP', value: 0 },
    { name: 'INC Urb', value: 0 },
    { name: 'INC FF', value: 0 },
    { name: 'Risque techno', value: 0 },
  ];

  constructor() {
    this.itemsChange = new EventEmitter<EngagedResources[]>();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.items?.length) {
      this.items = [...this.defaultEngagedResources];
    }
  }

  ngOnInit(): void {}

  AddResource(newResource: string) {
    if (newResource.length > 0) {
      this.items.push({ name: newResource, value: 0 });
      this.newResourceName = '';
    }
  }

  RemoveRessource(ressourceName: string) {
    this.items = this.items.filter((it) => it.name != ressourceName);
    this.input.control.markAsDirty();
    this.itemsChange.emit(this.items);
  }

  add(name: any) {
    const object = this.items.findIndex((obj) => obj.name === name);
    if (this.items[object].value >= 0) {
      this.input.control.markAsDirty();
      this.items[object].value++;
      this.itemsChange.emit(this.items);
    }
  }

  remove(name: any) {
    const object = this.items.findIndex((obj) => obj.name === name);
    if (this.items[object].value !== 0) {
      this.input.control.markAsDirty();
      this.items[object].value--;
      this.itemsChange.emit(this.items);
    }
  }

  isDefaultRessource(ressource: EngagedResources): boolean {
    return this.defaultEngagedResources.find((ress) => ress.name == ressource.name) != undefined;
  }
}
