<div class="page-header">
  <div class="tab-container">
    <div class="inside-menu">
      <div
        class="menu-component"
        [routerLink]="organizationPropertiesRoute"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <i class="fas fa-file-text menu-component-icon"></i>
        <span class="menu-component-text"> {{ 'ORGANIZATION_TAB.PROPERTIES' | translate }}</span>
      </div>
      <div
        class="menu-component"
        [routerLink]="organizationUsersRoute"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: false }"
      >
        <i class="fas fa-users menu-component-icon"></i>
        <span class="menu-component-text"> {{ 'ORGANIZATION_TAB.USERS' | translate }}</span>
      </div>
      <div
        class="menu-component"
        [routerLink]="organizationGroupRoute"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <i class="fas fa-user-group menu-component-icon"></i>
        <span class="menu-component-text"> {{ 'ORGANIZATION_TAB.GROUPS' | translate }}</span>
      </div>
      <div
        *ngIf="canManageAccessCodeTab"
        class="menu-component"
        [routerLink]="organizationAccessCodeRoute"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <i class="fas fa-user-secret menu-component-icon"></i>
        <span class="menu-component-text"> {{ 'ORGANIZATION_TAB.ACCESS_CODE' | translate }}</span>
      </div>

      <div
        class="menu-component"
        [routerLink]="organizationDistributionRoute"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <i class="fas fa-broadcast-tower menu-component-icon"></i>
        <span class="menu-component-text"> {{ 'ORGANIZATION_TAB.DISTRIBUTION_LISTS' | translate }}</span>
      </div>
    </div>
    <mid-view-block></mid-view-block>
  </div>
</div>
