import { ActionReducerMap } from '@ngrx/store';
import { AuthenticationState } from './authentication/store';
import { getMetaReducersProvider } from './core/store';
import { DroneState } from './drone/store';
import { InterventionState } from './intervention/store/intervention.reducer';
import { LayoutState } from './layout/store';
import { MediaState } from './media/store';
import { OrganizationState } from './organization/store';
import { UserProfilesState } from './user-profile/store';

export interface AppState {
  authentication: AuthenticationState;
  layout: LayoutState;
  users: UserProfilesState;
  organization: OrganizationState;
  interventions: InterventionState;
  drones: DroneState;
  media: MediaState;
}

export const appReducer: ActionReducerMap<AppState> = {} as any;

export const metaReducersProvider = getMetaReducersProvider();
