import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DroneSnapshotComponent } from './drone-snapshot/drone-snapshot.component';
import { DroneStreamStatusComponent } from './drone-stream-status/drone-stream-status.component';
import { ShareButtonComponent } from './share-button/share-button.component';
import { CoreModule } from '../core/core.module';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ShareStreamComponent } from './share-stream/share-stream.component';
import { InterventionCommonModule } from '../intervention/common/intervention-common.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MidgardControlsModule } from '../midgard-controls/midgard-controls.module';
import { DroneCommonModule } from './common/drone-common.module';
import { DroneStoreModule } from './store/drone-store.module';
import { QRCodeModule } from 'angularx-qrcode';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [DroneSnapshotComponent, DroneStreamStatusComponent, ShareButtonComponent, ShareStreamComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    CoreModule,
    InterventionCommonModule,
    MatDialogModule,
    MidgardControlsModule,
    DroneCommonModule,
    DroneStoreModule,
    QRCodeModule,
    SharedModule,
  ],
  exports: [ShareStreamComponent, DroneStreamStatusComponent],
})
export class DroneModule {}
