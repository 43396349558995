import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MidSelectFlag, MidSelectSize } from '../models';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-mid-select-lang',
  templateUrl: './mid-select-lang.component.html',
  styleUrls: ['./mid-select-lang.component.scss'],
  exportAs: 'appMidSelectLang',
})
export class MidSelectLangComponent implements OnChanges {
  @Input()
  items: MidSelectFlag[] = [];
  @Output()
  itemsChange: EventEmitter<MidSelectFlag[]>;

  @Input()
  selectedItem = '';
  @Output()
  selectedItemChange: EventEmitter<string>;

  @Input()
  name = 'appMidSelectName_' + ((Math.random() * 10000) | 0);

  @Input()
  label = '';

  @Input()
  placeholder = '';

  @Input()
  size: MidSelectSize = MidSelectSize.default;

  @Input()
  disabled: boolean;

  @Input()
  required: boolean;

  @ViewChild('midSelect')
  select: NgModel;

  get MidSelectSize() {
    return MidSelectSize;
  }

  constructor() {
    this.itemsChange = new EventEmitter<MidSelectFlag[]>();
    this.selectedItemChange = new EventEmitter<string>();
    this.disabled = false;
    this.required = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.selectedItem) {
      this.selectedItem = '';
    }
  }

  onItemsChange($event: any) {
    this.items.forEach((it) => (it.isSelected = it.icon === $event));
    this.itemsChange.emit(this.items);
    this.selectedItemChange.emit(this.selectedItem);
  }

  get valid() {
    return this.select?.valid;
  }

  get invalid() {
    return this.select?.invalid;
  }

  get dirty() {
    return this.select?.dirty;
  }

  get pristine() {
    return this.select?.pristine;
  }

  get touched() {
    return this.select?.touched;
  }

  get untouched() {
    return this.select?.untouched;
  }
}
