import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { authenticationConfig } from 'src/app/authentication/authentication.config';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { Organization, User } from 'src/app/autogenerated/model';
import { OrganizationService } from 'src/app/autogenerated/organizationService';
import { LoggerService } from 'src/app/core/logger.service';
import { MidRightPanelService } from 'src/app/midgard-controls/services/mid-right-panel.service';
import { OrganizationStoreService } from 'src/app/organization/store';
import { UserProfilesStoreService } from 'src/app/user-profile/store';
import { environment } from 'src/environments/environment';
import { Unsubscribe } from '../../core/decorators';
import { ColorTheme } from 'src/app/midgard-controls/enums/color-theme.enum';
import { LabelPosition } from 'src/app/midgard-controls/enums/label-position.enum';
import { SwitchType } from 'src/app/midgard-controls/enums/switch-type.enum';
import { LayoutStoreService } from '../store';
@Unsubscribe()
@Component({
  selector: 'app-main-app-menu',
  templateUrl: './main-app-menu.component.html',
  styleUrls: ['./main-app-menu.component.scss'],
})
export class MainAppMenuComponent implements OnInit, OnDestroy {
  organizations: Organization[] = [];
  selectedOrganization: Organization;
  currentTheme: ColorTheme;

  private organizationSubscription: Subscription;
  private currentUserSubscription: Subscription;
  private currentThemeSubscription: Subscription;

  get organizationLogoUrl(): string | null {
    if (!this.selectedOrganization?.logoFileName) {
      return null; // '/assets/images/logo2.png';
    }
    return environment.backend + 'public/logos/' + this.selectedOrganization.logoFileName;
  }

  get myProfileRoute(): string[] {
    return ['/', 'my-profile'];
  }

  get homeRoute(): string[] {
    return [];
  }

  get interventionsRoute(): string[] {
    return ['/', 'interventions'];
  }

  get dronesRoute(): string[] {
    return ['/', 'drones'];
  }

  get mapsRoute(): string[] {
    return ['/', 'maps'];
  }

  get mediaRoute(): string[] {
    return ['/', 'media'];
  }

  get organizationRoute(): string[] {
    return ['/', 'organization', this.selectedOrganization._id];
  }

  get ColorTheme(): typeof ColorTheme {
    return ColorTheme;
  }

  get LabelPosition() {
    return LabelPosition;
  }

  get SwitchType() {
    return SwitchType;
  }

  constructor(
    private router: Router,
    private readonly authenticationService: AuthenticationService,
    private readonly loggerService: LoggerService,
    private readonly organizationService: OrganizationService,
    private readonly organizationStoreService: OrganizationStoreService,
    private readonly userProfilesStoreService: UserProfilesStoreService,
    private readonly midRightPanelService: MidRightPanelService,
    private layoutStoreService: LayoutStoreService
  ) {}

  ngOnInit(): void {
    // we change the organization we update our selection
    this.organizationSubscription = this.organizationStoreService.getCurrentOrganization().subscribe((organization) => {
      if (organization && this.selectedOrganization !== organization) {
        this.selectedOrganization = organization;
      }
    });

    // when the user login we download the list of organizations
    this.currentUserSubscription = this.userProfilesStoreService.getCurrentUser().subscribe(async (user: User) => {
      if (!user) {
        await this.authenticationService.signOut();
      }
      const organizations = await this.organizationService.getOrganizations();
      this.organizations = organizations.sort((org1, org2) => (org1.name > org2.name ? 1 : -1));
    });

    this.currentThemeSubscription = this.layoutStoreService.getDefaultTheme().subscribe((theme: ColorTheme) => {
      this.currentTheme = theme;
    });
  }

  ngOnDestroy(): void {}

  close(): void {
    this.midRightPanelService.close();
  }

  goTo(pagePath: string): void {
    this.router.navigate([pagePath]);
    this.close();
  }

  async changeOrganization(organizationId: string) {
    if (!organizationId || this.selectedOrganization._id === organizationId) {
      return;
    }
    const organization = await this.organizationService.getOrganization({ _id: organizationId });
    this.organizationStoreService.setCurrentOrganization(organization);
    // this.isOrgAdmin(organization).then((result) => (this.isUserOrgAdmin = result)); // TODO: think about it
  }

  async onSignOut() {
    try {
      await this.authenticationService.signOut();
    } catch (error) {
      this.loggerService.warning('Failed to sign-out', error);
    } finally {
      this.authenticationService.expireAuthentication();
      this.close();
      this.router.navigate([]);
    }
    return false;
  }

  toggleTheme() {
    if (this.currentTheme === ColorTheme.light) {
      this.currentTheme = ColorTheme.dark;
    } else {
      this.currentTheme = ColorTheme.light;
    }

    this.layoutStoreService.setDefaultTheme(this.currentTheme);
  }
}
