<div class="organization-users">
  <div class="container-fluid">
    <div class="organization-users-btn" *ngIf="isAdmin">
      <app-add-user-button [org]="org" (create)="onCreateUser($event)"></app-add-user-button>
    </div>

    <app-organization-users-filter
      [group]="filterGroup"
      (filterResetted)="resetFilter()"
    ></app-organization-users-filter>

    <div class="organizations-users-wrap">
      <table class="organizations-users-list" [class.overlay-loader]="state.isLoading">
        <tr class="organization-item-header">
          <th width="160">
            <div class="organization-item-header-col">
              <div>
                {{ 'NAME' | translate }}
              </div>
            </div>
          </th>
          <th width="200">
            <div class="organization-item-header-col">
              <div>
                {{ 'GROUPS' | translate }}
              </div>
            </div>
          </th>
          <th width="310">
            <div class="organization-item-header-col">
              <div>
                {{ 'EMAIL' | translate }}
              </div>
            </div>
          </th>
          <th width="130">
            <div class="organization-item-header-col">
              <div>
                {{ 'PHONE_TABLE' | translate }}
              </div>
            </div>
          </th>
          <th width="132">
            <div class="organization-item-header-col">
              <div>
                {{ 'ACTION_TABLE' | translate }}
              </div>
            </div>
          </th>
        </tr>
        <tbody>
          <tr
            app-organization-user-item
            *ngFor="let user of users | paginate : { itemsPerPage: 10, currentPage: paginationPageNumber }"
            [user]="user"
            [org]="org"
            [isAdmin]="isAdmin"
            [features]="features"
            [currentUser]="currentUser"
            [userGroupMap]="userGroupMap"
            [availableGropes]="organizationGroups"
            [hasGlobalEditMode]="hasGlobalEditMode"
            (editMode)="onEditUser()"
            (savedUser)="onSaveUser($event)"
            (delete)="onDeleteUser($event)"
          ></tr>
        </tbody>
      </table>

      <pagination-template class="pagination-control" #p="paginationApi" (pageChange)="getPageNumber($event)">
        <div class="pagination-table" *ngIf="p.pages.length > 1">
          <a
            class="pagination-previous"
            *ngIf="!p.isFirstPage()"
            [class.disabled]="p.isFirstPage()"
            (click)="p.previous()"
          >
            <i class="fa fa-caret-left"></i>
          </a>

          <div *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
            <a class="pagination-button" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
              <span>{{ page.label }}</span>
            </a>
            <div class="pagination-button-active" *ngIf="p.getCurrent() === page.value">
              <span>{{ page.label }}</span>
            </div>
          </div>

          <a class="pagination-next" *ngIf="!p.isLastPage()" [class.disabled]="p.isLastPage()" (click)="p.next()">
            <i class="fa fa-caret-right"></i>
          </a>
        </div>
      </pagination-template>
    </div>
  </div>
</div>
