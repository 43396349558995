<div class="page-header">
  <div class="tab-container">
    <div class="inside-menu">
      <div
        class="menu-component"
        [routerLink]="interventionDashboardRoute"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <i class="fas fa-globe menu-component-icon"></i>
        <span class="menu-component-text"> {{ 'INTERVENTION.TAB.DASHBOARD' | translate }}</span>
      </div>
      <div
        class="menu-component"
        [routerLink]="interventionStreamingRoute"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <i class="fas fa-helicopter menu-component-icon"></i>
        <span class="menu-component-text"> {{ 'INTERVENTION.TAB.VIDEO_STREAM' | translate }}</span>
      </div>
      <div
        class="menu-component"
        [routerLink]="interventionMapRoute"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        *ngIf="AppFeatureId.Cartography | isFeatureEnabled"
      >
        <i class="fas fa-map menu-component-icon"></i>
        <span class="menu-component-text">{{ 'INTERVENTION.TAB.TDRAW' | translate }}</span>
      </div>
      <div
        class="menu-component"
        [routerLink]="interventionMediaRoute"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <i class="fas fa-photo-video menu-component-icon"></i>
        <span class="menu-component-text">{{ 'INTERVENTION.TAB.MEDIA' | translate }}</span>
      </div>
      <div
        class="menu-component"
        [routerLink]="interventionSettingsRoute"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <i class="fas fa-cog menu-component-icon"></i>
        <span class="menu-component-text">{{ 'INTERVENTION.TAB.SETTINGS' | translate }}</span>
      </div>
      <div
        class="menu-component"
        [routerLink]="interventionAnalysesRoute"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <i class="fas fa-search menu-component-icon"></i>
        <span class="menu-component-text">{{ 'INTERVENTION.TAB.ANALYSES' | translate }}</span>
      </div>
    </div>
  </div>

  <mid-view-block></mid-view-block>
</div>
