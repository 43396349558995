import { StorageType } from '../storage';
import { Feature } from './feature';

export const featuresConfig = {
  storageType: StorageType.Local,
  storageKey: 'app-features',
  undefinedFeature: {
    id: 'core.undefined-feature',
    isEnabledByDefault: false,
    isEnabled: false,
    name: 'Undefined feature',
    description: 'Feature was not defined.',
  } as Feature,
};
