import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import mapboxgl from 'mapbox-gl';
import { TdrawService } from 'src/app/autogenerated/tdrawService';
import { tdrawConfig } from '../../tdraw.config';
import { InfopointManager, InfopointData } from '../../misc/infopoint-manager';
import { MapState } from '../../models/map-state.model';
import _ from 'lodash';
import CoordinatesHelpers from '../../misc/coordinates-helpers';
import { Unsubscribe } from 'src/app/core/decorators';
import { TranslateService } from '@ngx-translate/core';
import { RightPanelData, RightPanelMode } from '../../models/right-panel-data';
import { MapIconsService } from '../../services/map-icons.service';
import { GisManager } from '../../misc/gis-manager';
import MapCompareTool from '../../misc/map-compare-tool';
import { LocationSearchResult } from '../../models/location-search-result.model';
import { ViewModeManager } from '../../misc/view-mode-manager';
import { Subscription } from 'rxjs';

@Unsubscribe()
@Component({
  selector: 'tdraw-shared-gis-page',
  templateUrl: './tdraw-shared-gis-page.component.html',
  styleUrls: ['./tdraw-shared-gis-page.component.scss'],
})
export class TdrawSharedGisPageComponent implements OnInit, OnDestroy {
  // Map
  map: mapboxgl.Map;
  currentInfo: InfopointData;
  date = new Date();
  gisManager: GisManager;
  viewModeManager: ViewModeManager;

  // State
  mapState: MapState;
  rightPanelData: RightPanelData = { mode: RightPanelMode.Hidden };
  isNotAvailable: boolean = false;

  // MapCompare
  private mapCompare: MapCompareTool;
  isCompareMode: boolean = false;

  // Subscriptions
  private featureSelectedChangedSubscription: Subscription;
  private featureUnselectedSubscription: Subscription;

  // Enums
  get RightPanelMode() {
    return RightPanelMode;
  }

  constructor(
    readonly translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private tdrawService: TdrawService,
    private mapIconsService: MapIconsService
  ) {}

  ngOnDestroy(): void {}

  async ngOnInit() {
    this.activatedRoute.params.subscribe(async (params) => {
      const sharedMapStateId = params['id'];
      try {
        this.mapState = await this.tdrawService.getSharedMapStateData(sharedMapStateId);
        this.initMap();
      } catch (error) {
        this.isNotAvailable = true;
      }
    });
  }

  private initMap() {
    // Be sure that all projections we use are loaded in proj4
    CoordinatesHelpers.registerProjections();

    this.map = new mapboxgl.Map({
      accessToken: tdrawConfig.mapAccessToken,
      container: 'map-shared-gis',
      style: tdrawConfig.mapStyle,
      center: tdrawConfig.defaultMapCenter,
      zoom: 13,
    });
    this.map.addControl(new mapboxgl.ScaleControl());
    this.map.addControl(new mapboxgl.NavigationControl(), 'bottom-left');

    this.gisManager = new GisManager(this.map);
    this.viewModeManager = new ViewModeManager(this.map, this.mapState);

    // Initialize Infopoint to get informations on map click events
    new InfopointManager(this.map).init((info: InfopointData) => {
      this.currentInfo = info;
    });

    // Listeners
    this.map.on('load', () => this.onMapLoad());
    this.featureSelectedChangedSubscription = this.viewModeManager.onFeatureSelectedChanged((data) => {
      this.setRightPanelMode(RightPanelMode.Properties);
    });
    this.featureUnselectedSubscription = this.viewModeManager.onFeatureUnselected(() => {
      this.exitPropertiesPanel();
    });

    // Hide 204 Errors from TMS Tiles
    this.map.on('error', (e) => {
      if (e.source?.scheme === 'tms') {
        return;
      }
      console.error(e);
    });

    this.mapCompare = new MapCompareTool(this.map);
  }

  private async onMapLoad() {
    await this.gisManager.load();
    this.mapIconsService.loadImages(this.map);

    // Wait for map prerequisites to be fully loaded before loading data
    this.map.once('idle', () => {
      // TODO: Fix pinpoint medias requiring an auth token
      this.gisManager.loadDataOnMap(this.viewModeManager, false);
    });
  }

  private exitPropertiesPanel() {
    if (this.rightPanelData.mode !== RightPanelMode.Hidden) {
      this.rightPanelData.mode = RightPanelMode.Navigation;
    }
  }

  setRightPanelMode(mode: RightPanelMode) {
    this.rightPanelData.mode = mode;
  }

  flyMapToLocation(location: LocationSearchResult) {
    const center = location.coordinates;
    const zoom = 13;
    this.map.flyTo({ center, zoom });
  }

  toggleCompare() {
    if (this.isCompareMode) {
      this.mapCompare.stop();
      this.isCompareMode = false;
    } else {
      this.mapCompare.start();
      this.isCompareMode = true;
    }
  }

  geolocalizeMe() {
    this.gisManager.geolocalizeMe();
  }

  async printSnapshot() {
    this.viewModeManager.mapManager.printSnapshot(true);
  }

  async downloadSnapshot() {
    this.viewModeManager.mapManager.downloadSnapshot(true);
  }
}
