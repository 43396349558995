import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomClassService } from '../services/custom-class.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  constructor(public customClassesService: CustomClassService) {}
}
