import { MapStateNestedBackgroundMediaDto } from './map-state-nested-background-media.dto';
import { MapStateNestedBackgroundDto } from './map-state-nested-background.dto';

export class MapStateCreationDto {
  name: string;
  background?: MapStateNestedBackgroundDto;
  backgroundMedia?: MapStateNestedBackgroundMediaDto;
  type: string;

  constructor({ name, background, backgroundMedia, type }: Partial<MapStateCreationDto> = {}) {
    this.name = name;
    this.background = background;
    this.backgroundMedia = backgroundMedia;
    this.type = type;
  }
}
