<app-mid-right-panel
  #panelCreateIntervention="appMidRightPanel"
  [title]="'DIALOGS.CREATE_INTERVENTION.TITLE' | translate"
>
  <app-mid-right-panel-content>
    <form #formNewIntervention="ngForm" novalidate>
      <div class="row m-0 px-4 pb-4">
        <section class="section col-md-12">
          <h3 class="title">{{ 'DIALOGS.CREATE_INTERVENTION.SECTION_GENERAL_INFO' | translate }}</h3>

          <app-select-intervention-type-button-toggles
            [(type)]="data.intervention.type"
          ></app-select-intervention-type-button-toggles>

          <app-mid-input
            size="wide"
            [(value)]="data.intervention.name"
            #name="appMidInput"
            [label]="'DIALOGS.CREATE_INTERVENTION.NAME_LABEL' | translate"
            [placeholder]="
              (defaultInterventionName ? defaultInterventionName + '; ' : '') +
              ('DIALOGS.CREATE_INTERVENTION.NAME_PLACEHOLDER' | translate)
            "
          >
            <!-- [required]="true" -->
            <!--
              <span *ngIf="name.invalid">{{ 'COMMON_REQUIRED_FIELD' | translate }}</span>
            -->
          </app-mid-input>

          <app-mid-input
            size="wide"
            [(value)]="data.intervention.interventionNumber"
            #interventionNumber="appMidInput"
            [label]="'DIALOGS.CREATE_INTERVENTION.INTERVENTION_NUMBER_LABEL' | translate"
            [placeholder]="'DIALOGS.CREATE_INTERVENTION.INTERVENTION_NUMBER_PLACEHOLDER' | translate"
          ></app-mid-input>

          <app-mid-input
            size="wide"
            [(value)]="data.intervention.city"
            #city="appMidInput"
            [label]="'DIALOGS.CREATE_INTERVENTION.CITY_LABEL' | translate"
            [placeholder]="'DIALOGS.CREATE_INTERVENTION.CITY_PLACEHOLDER' | translate"
          ></app-mid-input>
        </section>

        <section class="section col-md-12">
          <h3 class="title">{{ 'DIALOGS.CREATE_INTERVENTION.SECTION_ADDITIONAL_INFO' | translate }}</h3>
          <app-mid-input
            size="wide"
            [(value)]="data.intervention.nature"
            #nature="appMidInput"
            [label]="'DIALOGS.CREATE_INTERVENTION.NATURE_LABEL' | translate"
            [placeholder]="'DIALOGS.CREATE_INTERVENTION.NATURE_PLACEHOLDER' | translate"
          ></app-mid-input>

          <app-mid-select
            size="wide"
            [(items)]="data.drones"
            [label]="'DIALOGS.CREATE_INTERVENTION.ASSOCIATED_DRONE_LABEL' | translate"
            [placeholder]="'DIALOGS.CREATE_INTERVENTION.ASSOCIATED_DRONE_PLACEHOLDER' | translate"
          ></app-mid-select>
        </section>
      </div>
    </form>
  </app-mid-right-panel-content>

  <app-mid-right-panel-footer>
    <div class="row m-0 py-3 px-4">
      <app-mid-button
        class="pr-2"
        type="primary"
        (action)="create(organizationId)"
        [disabled]="formNewIntervention.form?.invalid"
        >{{ 'DIALOGS.COMMON.CREATE' | translate }}</app-mid-button
      >
      <app-mid-button class="pr-2" type="secondary" (action)="panelCreateIntervention.close()">{{
        'DIALOGS.COMMON.CANCEL' | translate
      }}</app-mid-button>
    </div>
  </app-mid-right-panel-footer>
</app-mid-right-panel>
