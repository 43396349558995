import { MediaService } from 'src/app/autogenerated/mediaService';
import { Drone, Intervention, MediaFilterType, MediaType, OrganizationMedia } from 'src/app/autogenerated/model';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { InterventionService } from 'src/app/autogenerated/interventionService';
import { ActivatedRoute } from '@angular/router';
import { OrganizationStoreService } from 'src/app/organization/store';
import { GisManager } from 'src/app/tdraw/misc/gis-manager';
import { Subscription } from 'rxjs';
import { Unsubscribe } from 'src/app/core/decorators';
import { MapStateNestedMedia } from 'src/app/tdraw/models/map-state-nested-media';
import { MediaDialogService } from 'src/app/media/media-dialog.service';
import { DroneTelemetryService } from 'src/app/autogenerated/droneTelemetryService';
import { Flight } from 'src/app/tdraw/models/flight.model';
import _ from 'lodash';
import { BackgroundView3dType } from 'src/app/tdraw/models/dto/enums/background-view-3d-type.enum';
import { MapStateFlightsDto } from 'src/app/tdraw/models/dto/map-state-flights.dto';
import { DroneService as DroneServiceV2 } from 'src/api';
import { Model3dStore } from 'src/app/tdraw/misc/model3d-layer';

@Unsubscribe()
@Component({
  selector: 'tdraw-data-tab',
  templateUrl: './data-tab.component.html',
  styleUrls: ['./data-tab.component.scss'],
})
export class DataTabComponent implements OnInit, OnDestroy {
  @Input()
  gisManager: GisManager;

  @Input()
  intervention: Intervention;

  @Input()
  withMediaSelector: boolean = true;

  // Flights part
  // flights: Flight[] = [];
  flightsByDrone?: { [droneName: string]: Flight[] } = null;
  flightStates: MapStateFlightsDto = {};

  // Medias part
  selectedPhotos: OrganizationMedia[] = [];
  photos: OrganizationMedia[] = [];
  photoUrls: Map<string, string>;
  mediaStates: { [mediaId: string]: MapStateNestedMedia } = {};

  // Video stream part
  drones: Drone[] = [];
  currentDrone: Drone = null;

  private organizationId: string;
  private interventionId: string;
  private organizationSubscription: Subscription;

  constructor(
    private activateRoute: ActivatedRoute,
    private organizationStoreService: OrganizationStoreService,
    private interventionService: InterventionService,
    private mediaService: MediaService,
    private mediaDialogService: MediaDialogService,
    private droneTelemetryService: DroneTelemetryService,
    private droneServiceV2: DroneServiceV2
  ) {}

  async ngOnInit(): Promise<void> {
    await this.downloadGeolocalizedMedia();

    this.gisManager.onMediasChanged((medias: MapStateNestedMedia[]) => {
      // Sync the list of selected medias
      const filteredIds = medias.map((e) => e.id);
      this.selectedPhotos = this.photos.filter((e) => {
        return filteredIds.includes(e._id);
      });

      // Sync the states of each selected media
      this.mediaStates = {};
      Model3dStore.disableActiveTool();
      Model3dStore.toolbarIsVisible = false;
      for (const media of medias) {
        const mediaId = media.id;
        this.mediaStates[mediaId] = media;

        // Display the 3D toolbar if at least one media have active 3d
        if (this.mediaStates[mediaId].active3d) {
          Model3dStore.toolbarIsVisible = true;
        }
      }
    });

    await this.loadListOfFlights();
    this.flightStates = this.gisManager.flightStates;

    await this.loadDrones();
  }

  private async loadDrones() {
    const drones = await this.droneServiceV2.orgDroneControllerGetOrgDrones(this.organizationId).toPromise();
    this.drones = _.filter(drones, (item) => item.currentIntervention === this.interventionId);
  }

  private async downloadGeolocalizedMedia() {
    this.organizationSubscription = this.organizationStoreService
      .getCurrentOrganization()
      .subscribe(async (organization) => {
        if (!organization || this.organizationId === organization._id) {
          return;
        }

        this.organizationId = organization._id;
        this.interventionId = this.activateRoute.snapshot.data.interventionId;

        await this.interventionService
          .getMediaV1(this.organizationId, this.interventionId, [
            // MediaFilterType.picture,
            MediaFilterType.gps,
            MediaFilterType.tiled,
          ])
          .then((photos) => (this.photos = photos ?? []));

        this.photoUrls = new Map();
        for (const photo of this.photos) {
          this.photoUrls[photo._id] = this.getDownloadPath(photo);
        }
      });
  }

  private async loadListOfFlights() {
    const flights = await this.droneTelemetryService.getFlights(this.interventionId);

    if (!flights.length) {
      this.flightsByDrone = null;
      return;
    }

    this.flightsByDrone = _.groupBy(flights, (e) => {
      return e.droneName;
    });
  }

  async onToggleFlightPath(flightId: string) {
    this.gisManager.toggleRealTimeFlightPath(flightId);
  }

  async onToggleFlightIcon(flightId: string) {
    this.gisManager.toggleRealTimeFlightIcon(flightId);
  }

  async onToggleVideoStream(drone: Drone) {
    if (this.currentDrone === drone) {
      this.currentDrone = null;
      return;
    }
    this.currentDrone = drone;
  }

  onChange3dModelOffset(photo: OrganizationMedia, value: number) {
    this.gisManager.set3dModelOffset(photo._id, value);
  }

  onTogglePinpoint(photo: OrganizationMedia) {
    this.gisManager.togglePinpoint(photo);
  }

  onToggleTiles(photo: OrganizationMedia) {
    this.gisManager.toggleTiles(photo);
  }

  onToggle3dModel(photo: OrganizationMedia) {
    if (!this.mediaStates[photo._id].active3d) {
      this.gisManager.toggleMapBackgroundLayer(BackgroundView3dType.Terrain, true);
    }
    this.gisManager.toggle3dModel(photo);
  }

  enableAllPinpoints() {
    this.selectedPhotos.forEach((media) => {
      this.gisManager.togglePinpoint(media, true, false);
    });
  }

  enableAllTiles() {
    this.selectedPhotos.forEach((media) => {
      this.gisManager.toggleTiles(media, true);
    });
  }

  async openSelectMediasDialog() {
    const mediaIds = await this.mediaDialogService.openMultiSelectMediaDialog(
      'SELECT_MEDIA_TO_INTERVENTION',
      MediaType.picture,
      this.selectedPhotos.map((e) => e._id),
      this.interventionId,
      false
    );
    // We conserve the previous selection in case of cancellation
    if (mediaIds !== null) {
      this.gisManager.synchronizeMediaFeaturesAndStates(mediaIds);
    }
  }

  ngOnDestroy(): void {}

  private getDownloadPath(photo: OrganizationMedia): string {
    return this.mediaService.getDownloadUrl(photo, true);
  }
}
