import { Component, Inject, OnInit } from '@angular/core';
import { OrganizationDistributionListService } from 'src/api';
import { MID_RIGHT_PANEL_DATA } from 'src/app/midgard-controls/services/dom.service';
import { MidRightPanelService } from 'src/app/midgard-controls/services/mid-right-panel.service';
import { isEmpty } from 'lodash';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input-gg';

export class UpdateOrganizationDistributionListComponentInput {
  id: string;
  phones: string[];
}

@Component({
  selector: 'app-update-organization-distribution-list',
  templateUrl: './update-organization-distribution-list.component.html',
  styleUrls: ['./update-organization-distribution-list.component.scss'],
})
export class UpdateOrganizationDistributionListComponent implements OnInit {
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.Italy, CountryISO.UnitedKingdom];

  constructor(
    @Inject(MID_RIGHT_PANEL_DATA) public data: UpdateOrganizationDistributionListComponentInput,
    private readonly midRightPanelService: MidRightPanelService,
    private readonly organizationDistributionListService: OrganizationDistributionListService
  ) {}

  get isEmpty() {
    return isEmpty;
  }

  ngOnInit(): void {}

  async updateList() {
    const phones = this.data.phones.filter((e) => e != '');
    await this.organizationDistributionListService
      .orgOrganizationDistributionListControllerUpdate({ phones }, this.data.id)
      .toPromise();
    this.midRightPanelService.close();
  }

  addItem() {
    this.data.phones.push('');
  }

  deleteItem(index) {
    this.data.phones.splice(index, 1);
  }

  trackByFn(index, item) {
    return index;
  }
}
