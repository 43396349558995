import { Injectable } from '@angular/core';
import { Assert } from 'src/app/shared';
import { LoggerService } from '../logger.service';
import { WebStorageService } from './web-storage-service';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService extends WebStorageService {
  constructor(loggerService: LoggerService) {
    Assert.isNotNull(
      sessionStorage,
      'localStorage',
      'Cannot use SessionStorageService because "{0}" not supported by browser.'
    );
    super(sessionStorage, loggerService);
  }
}
