import { MediaUploadService } from '../../media/common/media-upload/media-upload.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuthenticationStoreService } from 'src/app/authentication/store';
import { Organization, User } from 'src/app/autogenerated/model';
import { OrganizationService } from 'src/app/autogenerated/organizationService';
import { Unsubscribe } from 'src/app/core/decorators';
import { TemplateService } from 'src/app/global/template.service';
import { OrganizationStoreService } from 'src/app/organization/store';
import { UserProfilesStoreService } from 'src/app/user-profile/store';
import { InterventionStoreService } from 'src/app/intervention/store';
import { InterventionService } from 'src/app/autogenerated/interventionService';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { BreadcrumbStateService } from '../services/breadcrumb-state.service';
import _ from 'lodash';
import { AuthenticationService } from '../../authentication/authentication.service';
import { MidRightPanelService } from 'src/app/midgard-controls/services/mid-right-panel.service';
import { MainAppMenuComponent } from '../main-app-menu/main-app-menu.component';
import { MainMediaMenuComponent } from '../main-media-menu/main-media-menu.component';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Unsubscribe()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  suppClass = '';
  componentName = '';
  user: User;
  organization: Organization;
  username: {
    userInitials: string;
    name: string;
    surname: string;
    usernameClass: string;
  };

  get organizationLogoUrl(): string | null {
    if (!this.organization?.logoFileName) {
      return null; // '/assets/images/logo2.png';
    }
    return environment.backend + 'public/logos/' + this.organization.logoFileName;
  }

  private headerClassSubscription: Subscription;
  private activeComponentSubscription: Subscription;
  private organizationSubscription: Subscription;
  private currentUserSubscription: Subscription;

  constructor(
    private readonly templateService: TemplateService,
    private readonly userProfilesStoreService: UserProfilesStoreService,
    private readonly authenticationStoreService: AuthenticationStoreService,
    private readonly organizationService: OrganizationService,
    private readonly organizationStoreService: OrganizationStoreService,
    private readonly interventionService: InterventionService,
    private readonly interventionStoreService: InterventionStoreService,
    private readonly authenticationService: AuthenticationService,
    private readonly midRightPanelService: MidRightPanelService,
    public mediaUploadService: MediaUploadService,
    public breadcrumbStateService: BreadcrumbStateService,
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.username = {
      name: '',
      surname: '',
      userInitials: '',
      usernameClass: '',
    };

    this.matIconRegistry.addSvgIcon(
      'triangleDown',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/triangle-down.svg')
    );
  }

  async ngOnInit(): Promise<void> {
    // use the behavior headerClass for add the suppClass from drone-single-stream
    this.headerClassSubscription = this.templateService.$headerClass.subscribe((cssClass) => {
      this.suppClass = cssClass;
    });

    this.activeComponentSubscription = this.templateService.$activeComponent.subscribe((componentName) => {
      this.componentName = componentName;
    });

    this.organizationSubscription = this.organizationStoreService.getCurrentOrganization().subscribe((organization) => {
      const isNewOrg = this.organization?._id !== organization?._id && organization;
      this.organization = organization;

      // if access code then username is actually derived from org
      if (organization) this.handleUsername();

      if (isNewOrg) {
        this.refreshInterventions();
        // TODO: fix redirection when the organization is changed
        // this.goTo('/');
      }
    });

    this.currentUserSubscription = this.userProfilesStoreService
      .getCurrentUser()
      .subscribe(async (currentUser: User) => {
        if (!currentUser) {
          await this.authenticationService.signOut();
        }
        this.user = currentUser;
        this.handleUsername();
      });

    await this.refreshOrganization();
  }

  ngOnDestroy(): void {
    // for Unsubscribe
  }

  toggleNotificationMenu($event) {
    $event.stopPropagation();
  }

  toggleMediaMenu($event) {
    $event.stopPropagation();
  }

  deleteNotification(event: Event, item) {
    event.stopPropagation();
    console.log('delete notification', item);
  }

  goTo(pagePath: string) {
    this.router.navigate([pagePath]);
  }

  onOpenMainMediaMenu(): void {
    this.midRightPanelService.open(MainMediaMenuComponent);
  }

  onOpenMainAppMenu(): void {
    this.midRightPanelService.open(MainAppMenuComponent);
  }

  private async refreshOrganization(): Promise<void> {
    const currentOrganization = await this.organizationStoreService.getCurrentOrganization().pipe(take(1)).toPromise();
    let currentOrganizationId: string;
    if (currentOrganization) {
      currentOrganizationId = currentOrganization._id;
    } else {
      currentOrganizationId = await this.authenticationStoreService
        .getAuthenticatedOrganizationId()
        .pipe(take(1))
        .toPromise();
    }

    const organization = await this.organizationService.getOrganization({ _id: currentOrganizationId });
    this.organizationStoreService.setCurrentOrganization(organization);
  }

  private async refreshInterventions(): Promise<void> {
    const currentOrganization = await this.organizationStoreService.getCurrentOrganization().pipe(take(1)).toPromise();
    let currentOrganizationId: string;
    if (currentOrganization) {
      currentOrganizationId = currentOrganization._id;
    } else {
      currentOrganizationId = await this.authenticationStoreService
        .getAuthenticatedOrganizationId()
        .pipe(take(1))
        .toPromise();
    }
    const interventions = await this.interventionService.getInterventionsWithMapping({ _id: currentOrganizationId });
    this.interventionStoreService.setOrganizationInterventions(currentOrganizationId, interventions);
  }

  private handleUsername(): void {
    if (!this.user || !this.user.fullname) {
      this.username = {
        name: this.organization?.name,
        surname: '',
        userInitials: this.organization?.name.substring(0, 2),
        usernameClass: 'partname',
      };
      return;
    }
    const splittedUsername = this.user.fullname.split(' ');
    const userSurname = splittedUsername.length > 1 ? splittedUsername[1] : '';
    const userInitials = _.map(splittedUsername, (initial) => initial[0])
      .slice(0, 2)
      .join('');

    this.username = {
      name: splittedUsername[0],
      surname: userSurname,
      userInitials: userInitials,
      usernameClass: userSurname ? 'fullname' : 'partname',
    };
  }
}
