<header class="mid-header">
  <nav class="mid-navbar">
    <div class="flex-container">
      <div class="d-flex">
        <!-- logos -->
        <div class="col-auto">
          <div class="polygon">
            <img class="img-fluid mr-3 mid-logo" src="/assets/images/midgard-logo-black.png" alt="" />
            <img *ngIf="organizationLogoUrl" class="img-fluid mid-org-logo" [src]="organizationLogoUrl" alt="" />
          </div>
          <div class="mobile-logo">
            <img width="115" src="/assets/images/mobile-logo.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </nav>
</header>
