import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { OrganizationDistributionListDto, OrganizationDistributionListService, OrganizationDto } from 'src/api';
import { MidRightPanelService } from 'src/app/midgard-controls/services/mid-right-panel.service';
import {
  NewOrganizationDistributionListComponent,
  NewOrganizationDistributionListComponentInput,
} from '../new-organization-distribution-list/new-organization-distribution-list.component';
import {
  UpdateOrganizationDistributionListComponent,
  UpdateOrganizationDistributionListComponentInput,
} from '../update-organization-distribution-list/update-organization-distribution-list.component';

@Component({
  selector: 'app-organization-distribution-list',
  templateUrl: './organization-distribution-list.component.html',
  styleUrls: ['./organization-distribution-list.component.scss'],
})
export class OrganizationDistributionListComponent implements OnInit, OnChanges {
  @Input()
  organization: OrganizationDto;

  lists: OrganizationDistributionListDto[] = [];

  constructor(
    private readonly organizationDistributionListService: OrganizationDistributionListService,
    private readonly midRightPanelService: MidRightPanelService
  ) {}

  ngOnInit(): void {}

  async ngOnChanges(changes: SimpleChanges) {
    const componentChanges = changes as PropertyMap<OrganizationDistributionListComponent, SimpleChange>;
    if (componentChanges.organization.currentValue) {
      await this.loadLists();
    }
  }

  async onAddList() {
    const modalData: NewOrganizationDistributionListComponentInput = { org: this.organization };
    this.midRightPanelService.open(NewOrganizationDistributionListComponent, modalData).subscribe(async (list) => {
      if (!list) return;
      await this.loadLists();
    });
  }

  async deleteList(list: OrganizationDistributionListDto) {
    await this.organizationDistributionListService.orgOrganizationDistributionListControllerDelete(list.id).toPromise();
    this.lists = this.lists.filter((e) => e.id !== list.id);
  }

  async onEditList(list: OrganizationDistributionListDto) {
    const modalData: UpdateOrganizationDistributionListComponentInput = {
      id: list.id,
      phones: list.phones ?? [],
    };
    this.midRightPanelService.open(UpdateOrganizationDistributionListComponent, modalData).subscribe(async () => {
      await this.loadLists();
    });
  }

  private async loadLists() {
    this.lists = await this.organizationDistributionListService
      .orgOrganizationDistributionListControllerGetOrgDistributionLists(this.organization.id)
      .toPromise();
  }
}
