<app-simple-header [sharingType]="'media'"></app-simple-header>
<div class="container-fluid" class="mid-snapshot">
  <div class="row">
    <div class="col-12">
      <div *ngIf="!invalidSharing">
        <ng-container [ngSwitch]="media?.fileType">
          <div class="mid-snapshot-container" *ngSwitchCase="'picture'">
            <img [attr.src]="srcUrl | secureImage | async" class="mid-snapshot-img" alt="" />
          </div>

          <div class="mid-snapshot-container" *ngSwitchCase="'video'">
            <video #video controls autoplay class="player-container">
              <source [attr.src]="srcUrl" />
            </video>
          </div>
        </ng-container>
      </div>
      <ng-container *ngIf="invalidSharing">
        <div class="invalid-image">
          {{ 'INVALID_SHARE_LINK' | translate }}
        </div>
      </ng-container>
    </div>
  </div>
</div>
