<!-- upload drag & drop -->
<!-- header -->
<div class="upload-header">
  <div class="upload-title">
    <span class="title"> {{ 'IMPORT_MEDIA' | translate }} </span>
  </div>
  <div class="close-icon" mat-dialog-close>
    <i class="fas fa-times icon"></i>
  </div>
</div>
<!-- Upload body -->
<div class="upload-body">
  <div class="steps" *ngIf="data.intervention && data.intervention.isArchived !== true">
    <!-- ; else noIntervention -->
    <div class="step">
      <div class="step-main">
        <div class="step-number">
          <span class="number">1</span>
        </div>
        <div class="step-info">
          <span class="info"> {{ 'INTERVENTION_ANALYSES.PHOTOGRAMMETRY_NAMED_YOUR_PROJECT' | translate }} </span>
        </div>
      </div>
    </div>
  </div>

  <app-mid-input
    class="col-md-6"
    size="wide"
    [(value)]="projectName"
    #name="appMidInput"
    name="name"
    [required]="true"
    [label]=""
    [placeholder]="'INTERVENTION_ANALYSES.PHOTOGRAMMETRY_PROJECT_NAME_PLACEHOLDER' | translate"
  >
    <span *ngIf="alertOnEmptyProjectName && projectName == ''" class="text-danger font-weight-bold">{{
      'COMMON_REQUIRED_FIELD' | translate
    }}</span>
  </app-mid-input>
  <div class="steps" *ngIf="data.intervention && data.intervention.isArchived !== true">
    <!-- ; else noIntervention -->
    <div class="step">
      <div class="step-main">
        <div class="step-number">
          <span class="number">2</span>
        </div>
        <div class="step-info">
          <span class="info">
            {{ 'INTERVENTION_ANALYSES.PHOTOGRAMMETRY_SELECT_FILES' | translate }} (<span class="font-weight-normal">{{
              photogrammetryExtensions
            }}</span
            >)
          </span>
        </div>
      </div>
    </div>
    <app-select-media-button-toggle
      class="col-md-12"
      [(type)]="mediaSourceType"
      [label]="'IMPORT'"
      (typeChange)="mediaSourceTypeChange($event)"
    >
    </app-select-media-button-toggle>
  </div>

  <div
    class="mid-container-upload-drag-drop"
    [ngClass]="{ disabled: !selectedIntervention }"
    appDnd
    (fileDropped)="onFileDropped($event)"
  >
    <ng-container *ngIf="mediaSourceType == 'file'">
      <label for="fileDropRef" class="mid-input-upload-label">
        {{ 'INTERVENTION_ANALYSES.MEDIA_AS_FILES' | translate }}
      </label>
      <input
        [disabled]="!selectedIntervention"
        type="file"
        [accept]="photogrammetryExtensions"
        #fileDropRef
        id="fileDropRef"
        multiple
        (change)="fileBrowseHandler($event.target.files)"
        class="mid-input-upload"
        placeholder="Drag and Drop Files"
      />
    </ng-container>

    <ng-container *ngIf="mediaSourceType == 'folder'">
      <label for="directoryDropRef" class="mid-input-upload-label">
        {{ 'INTERVENTION_ANALYSES.MEDIA_AS_DIRECTORY' | translate }}
      </label>
      <input
        [disabled]="!selectedIntervention"
        type="file"
        [accept]="photogrammetryExtensions"
        #directoryDropRef
        id="directoryDropRef"
        (change)="fileBrowseHandler($event.target.files)"
        class="mid-input-upload"
        webkitDirectory
      />
    </ng-container>

    <ng-container *ngIf="mediaSourceType == 'mediaLibrary'">
      <label for="directoryDropRef" class="mid-input-upload-label">
        {{ 'INTERVENTION_ANALYSES.MEDIA_FROM_BDD' | translate }}
      </label>
      <span class="mid-input-upload" (click)="mediaSourceTypeChange('mediaLibrary')"></span>
    </ng-container>

    <!-- info user -->
    <ng-container *ngIf="mediaSourceType !== 'mediaLibrary'">
      <div *ngIf="files.length === 0" class="mid-user-info">
        <i class="fas fa-photo-video fa-4x mid-upload-files"></i>
        <div class="user-info">
          <h3 class="m-0 pt-2 main-info">{{ 'DRAG_DROP_FILE_HERE' | translate }}</h3>
          <span class="mb-2"> {{ 'OR' | translate }}</span>
          <app-mid-button type="primary" [disabled]="!selectedIntervention">
            {{ 'DRAG_DROP_FILE_BUTTON' | translate }}
          </app-mid-button>
        </div>
      </div>

      <div *ngIf="files.length !== 0" class="mid-user-info">
        <div *ngIf="nbOfDisplayedFiles >= files.length">
          <div *ngFor="let f of files">
            <i class="fas fa-photo-video mid-upload-files"></i>
            {{ f.file.name }}
          </div>
        </div>
        <div *ngIf="files.length > nbOfDisplayedFiles">
          <div>
            <i class="fas fa-photo-video mid-upload-files"></i>
            {{ files[0].file.name }}
          </div>
          <div>...</div>
          <div *ngFor="let f of displayedFiles">
            <i class="fas fa-photo-video mid-upload-files"></i>
            {{ f.file.name }}
          </div>
        </div>
        <div class="flex-box-end">
          <app-mid-button type="primary" [disabled]="!selectedIntervention">
            {{ 'DRAG_DROP_FILE_BUTTON' | translate }}
          </app-mid-button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="mediaSourceType === 'mediaLibrary'">
      <div *ngIf="mediaIds.length === 0" class="mid-user-info">
        <i class="fas fa-photo-video fa-4x mid-upload-files"></i>
        <div class="user-info">
          <h3 class="m-0 pt-2 main-info">{{ 'DRAG_DROP_FILE_HERE' | translate }}</h3>
          <span class="mb-2"> {{ 'OR' | translate }}</span>
          <app-mid-button type="primary" [disabled]="!selectedIntervention">
            {{ 'DRAG_DROP_FILE_BUTTON' | translate }}
          </app-mid-button>
        </div>
      </div>

      <div *ngIf="mediaIds.length !== 0" class="mid-user-info">
        <div>
          <i class="fas fa-photo-video mid-upload-files"></i>
          {{ mediaIds.length }} {{ 'INTERVENTION_ANALYSES.MEDIA_SELECTED_FROM_BDD' | translate }}
        </div>
        <div class="flex-box-end">
          <app-mid-button type="primary" [disabled]="!selectedIntervention">
            {{ 'DRAG_DROP_FILE_BUTTON' | translate }}
          </app-mid-button>
        </div>
      </div>
    </ng-container>

    <!-- upload files -->
  </div>
  <span *ngIf="alertOnNumberOfFile && files.length < 4" class="text-danger font-weight-bold">{{
    'INTERVENTION_ANALYSES.NUMBER_OF_FILE_WARNING' | translate
  }}</span>
  <br />
  <div class="steps" *ngIf="data.intervention && data.intervention.isArchived !== true">
    <!-- ; else noIntervention -->
    <div class="step">
      <div class="step-main">
        <div class="step-number">
          <span class="number">3</span>
        </div>
        <div class="step-info">
          <span class="info"> {{ 'INTERVENTION_ANALYSES.PHOTOGRAMMETRY_SEND_FILES' | translate }} </span>
        </div>
      </div>
    </div>
    <div>
      <mat-checkbox class="mr-2 mt-1" [(ngModel)]="mustGenerate3D">
        {{ '3D_GENERATE_BUTTON' | translate }}
      </mat-checkbox>
    </div>
  </div>
  <br />
  <div class="flex-box-end">
    <app-mid-button type="primary" (action)="createProject()" [disabled]="selectedIntervention?.isArchived">
      {{ 'INTERVENTION_ANALYSES.PHOTOGRAMMETRY_SEND_BUTTON' | translate }}
      {{ getNbOfFiles() }}
    </app-mid-button>
  </div>
</div>
