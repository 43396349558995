import { Assert } from 'src/app/shared';
import { Organization } from 'src/app/autogenerated/model';
import { OrganizationActions, OrganizationActionType, SetCurrentOrganizationAction } from './organization.actions';
import { CoreActionTypes } from 'src/app/core/store';
import { cloneDeep } from 'lodash';

export interface OrganizationState {
  currentOrganization: Organization;
}

export const organizationInitialState: OrganizationState = {
  currentOrganization: null,
};

export function organizationReducer(state: OrganizationState, action: OrganizationActions): OrganizationState {
  Assert.isNotNull(action, 'action');

  switch (action.type) {
    case OrganizationActionType.SetCurrentOrganization:
      return setCurrentOrganization(state, action);

    case CoreActionTypes.ResetState:
      return resetStateActionReducer();

    default:
      return state;
  }
}

export function setCurrentOrganization(
  state: OrganizationState,
  action: SetCurrentOrganizationAction
): OrganizationState {
  Assert.isNotNull(state, 'state');
  Assert.isNotNull(action, 'action');

  const newState: OrganizationState = {
    ...state,
    currentOrganization: action.organization,
  };

  return newState;
}

export function resetStateActionReducer(): OrganizationState {
  const newState: OrganizationState = cloneDeep(organizationInitialState);

  return newState;
}
