import { Component, Inject, OnChanges, OnInit, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { Drone, DroneStreamSharing } from '../../../autogenerated/model';
import { ClipboardService } from '../../../core/utils/clipboard.service';
import { DroneService } from 'src/app/autogenerated/droneService';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ConfirmModalService } from 'src/app/midgard-controls/services/confirm-modal.service';
import { Subscription, timer } from 'rxjs';
import { required, validate } from '../../../shared';
import { SearchCountryField } from 'ngx-intl-tel-input-gg';
import { MID_RIGHT_PANEL_DATA } from 'src/app/midgard-controls/services/dom.service';
import { MidRightPanelService } from 'src/app/midgard-controls/services/mid-right-panel.service';
import { MidInputComponent } from 'src/app/midgard-controls/mid-input/mid-input.component';
import { OrganizationDistributionListDto, OrganizationDistributionListService } from 'src/api';
import { OrganizationStoreService } from 'src/app/organization/store';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

export interface DroneShareLinkInput {
  drone: Drone;
  isAdmin: boolean;
}

@Component({
  selector: 'app-drone-share-link',
  templateUrl: './drone-share-link.component.html',
  styleUrls: ['./drone-share-link.component.scss'],
})
export class DroneShareLinkComponent implements OnInit, OnChanges {
  drone: Drone;
  isAdmin: boolean;

  separateDialCode = false;
  SearchCountryField = SearchCountryField;

  // for timer
  readonly SHARE_LINK_START_TIME = 18000;
  countDown: Subscription;
  counter: number;
  tick = 1000;

  currentSharing: DroneStreamSharing;
  broadcastLink: string;
  isBroadcastLinkGenerated = false;

  errorMessage: string;

  data: {
    phoneNumber: string;
    mail: string;
  };

  state: {
    isLoading: boolean;
    isFailed: boolean;
    shareBy: string;
    isStartTimer: boolean;
    isValid: boolean;
  };

  organizationId: string;

  @ViewChild('listInput')
  private listInput: MidInputComponent;
  selectedDistributionList: OrganizationDistributionListDto;
  distributionLists: OrganizationDistributionListDto[];

  // Subscriptions
  private organizationSubscription: Subscription;

  constructor(
    private readonly clipboardService: ClipboardService,
    private readonly droneService: DroneService,
    private readonly router: Router,
    private readonly location: Location,
    private readonly confirmModalService: ConfirmModalService,
    private readonly midRightPanelService: MidRightPanelService,
    private readonly organizationStoreService: OrganizationStoreService,
    private readonly organizationDistributionListService: OrganizationDistributionListService,
    @Inject(MID_RIGHT_PANEL_DATA) inputtingData: DroneShareLinkInput
  ) {
    this.drone = inputtingData.drone;
    this.isAdmin = inputtingData.isAdmin;
    this.errorMessage = '';
    this.state = {
      isLoading: false,
      isFailed: false,
      shareBy: '',
      isStartTimer: true,
      isValid: false,
    };
    this.data = {
      phoneNumber: '',
      mail: '',
    };

    this.resetData();

    this.organizationSubscription = this.organizationStoreService
      .getCurrentOrganization()
      .subscribe(async (organization) => {
        if (!organization || this.organizationId === organization._id) {
          return;
        }
        this.organizationId = organization._id;
        this.loadDistributionLists();
      });
  }

  async ngOnInit(): Promise<void> {
    this.counter = this.SHARE_LINK_START_TIME;
    await this.initCurrentSharing(this.drone).then(() => {
      if (!this.currentSharing?.createAt) {
        return;
      }
      const createdAt = this.currentSharing.createAt;
      const createdAtDate = new Date(createdAt);
      const todayDate = new Date(Date.now());
      const diffMs = todayDate.getTime() - createdAtDate.getTime();
      const diffS = Math.floor(diffMs / 1000);
      this.counter = this.SHARE_LINK_START_TIME - diffS;
      this.countDown = timer(0, this.tick).subscribe(() => --this.counter);
    });
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const componentChanges = changes as PropertyMap<DroneShareLinkComponent, SimpleChange>;
    const droneChange = componentChanges.drone;
    const hasDroneChanges = droneChange?.currentValue && droneChange?.currentValue !== droneChange?.previousValue;
    if (!hasDroneChanges) {
      return;
    }
    await this.initCurrentSharing(droneChange?.currentValue);
  }

  close(): Function {
    const that = this;
    return () =>
      setTimeout(() => {
        that.midRightPanelService.close({
          isShared: that.isBroadcastLinkGenerated,
        });
      }, 500);
  }

  async onGenerateNewShareLink(): Promise<void> {
    this.state.isLoading = true;
    this.state.isFailed = false;

    if (this.state.isStartTimer) {
      this.countDown = timer(0, this.tick).subscribe(() => --this.counter);
      this.state.isStartTimer = false;
    }

    try {
      this.currentSharing = await this.droneService.createSharing(this.drone);
      this.broadcastLink = await this.formatDroneStreamSharing(this.currentSharing);
      this.isBroadcastLinkGenerated = true;
    } catch (ex) {
      this.errorMessage = ex.message;
      this.state.isFailed = true;
      console.log(this.errorMessage);

      this.resetData();
    } finally {
      this.state.isLoading = false;
    }
  }

  shareBySMS(): void {
    this.state.shareBy = 'sms';
  }

  qrCode(): void {
    this.state.shareBy = 'qrCode';
  }

  shareByMail(): void {
    this.state.shareBy = 'mail';
  }

  shareBySMSList(): void {
    this.state.shareBy = 'smsList';
  }

  async sendSMS(): Promise<void> {
    try {
      await this.sendSmsRequest(this.data.phoneNumber['e164Number']);
    } catch (e) {
      console.log(e);
    } finally {
      this.state.shareBy = '';
    }
  }

  async sendMail(): Promise<void> {
    try {
      await this.sendMailRequest(this.data.mail);
    } catch (e) {
      console.log(e);
    } finally {
      this.state.shareBy = '';
    }
  }

  async sendSMSList() {
    const phones = this.selectedDistributionList.phones;
    phones.forEach(async (phone) => {
      await this.sendSmsRequest(phone);
    });
    this.state.shareBy = '';
  }

  selectDistributionList(event: MatAutocompleteSelectedEvent): void {
    const list = event.option.value;
    this.selectedDistributionList = list;
    this.listInput.input.control.setValue(list.name);
  }

  private async loadDistributionLists() {
    this.distributionLists = await this.organizationDistributionListService
      .orgOrganizationDistributionListControllerGetOrgDistributionLists(this.organizationId)
      .toPromise();
  }

  cancel(): void {
    this.state.shareBy = '';
  }

  async onDeleteLink(): Promise<void> {
    this.state.isLoading = true;
    this.state.isFailed = false;

    try {
      const isDeleteLinkConfirmed = await this.confirmModalService.showConfirmModal(
        'DELETE_DRONE_SHARE_LINK_CONFIRM_MESSAGE',
        'remove'
      );
      if (isDeleteLinkConfirmed) {
        this.resetData();
        await this.droneService.deleteSharing(this.currentSharing);
        this.countDown = null;
        this.counter = this.SHARE_LINK_START_TIME;
        this.state.isStartTimer = false;
        ++this.counter;
      }
    } catch (ex) {
      this.errorMessage = ex.message;
      this.state.isFailed = true;
      console.log(this.errorMessage);
    } finally {
      this.state.isLoading = false;
    }
  }

  async onCopyBroadcastLink(): Promise<void> {
    // TODO show notification
    await this.clipboardService.copyToClipboard(this.broadcastLink);
  }

  private async initCurrentSharing(drone: Drone) {
    try {
      const sharings = await this.droneService.getSharings(drone);
      this.currentSharing = sharings[sharings.length - 1];
      if (this.currentSharing) {
        this.broadcastLink = await this.formatDroneStreamSharing(this.currentSharing);
        this.isBroadcastLinkGenerated = true;
      }
    } catch (e) {
      console.error(e);
      this.resetData();
    }
  }

  private async formatDroneStreamSharing(sharing: DroneStreamSharing): Promise<string> {
    const urlTree = this.router.createUrlTree(['share', 'stream', sharing._id]);
    const path = this.location.prepareExternalUrl(urlTree.toString());
    return window.location.origin + path;
  }

  private resetData(): void {
    this.broadcastLink = '';
    this.isBroadcastLinkGenerated = false;
    this.countDown = null;
  }

  @validate
  private async sendSmsRequest(@required phoneNumber: string): Promise<void> {
    try {
      await this.droneService.sendShareLinkBySMS(phoneNumber, this.broadcastLink);
    } catch (error) {
      throw error;
    }
  }

  @validate
  private async sendMailRequest(@required mail: string): Promise<void> {
    try {
      await this.droneService.sendShareLinkByMail(mail, this.broadcastLink);
    } catch (error) {
      throw error;
    }
  }
}
