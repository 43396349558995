import { Component, Input } from '@angular/core';
import { AppFeatureId } from 'src/app/app-feature-id';
import { Intervention } from 'src/app/autogenerated/model';
import { interventionConfig } from 'src/app/intervention/intervention.config';

@Component({
  selector: 'app-intervention-tab-header',
  templateUrl: './intervention-tab-header.component.html',
  styleUrls: ['./intervention-tab-header.component.scss'],
})
export class InterventionTabHeaderComponent {
  @Input()
  tabType: string;

  @Input()
  interventionId: string;

  @Input()
  intervention: Intervention;

  get AppFeatureId() {
    return AppFeatureId;
  }

  get interventionDashboardRoute(): string[] {
    return ['/', 'interventions', this.interventionId];
  }

  get interventionStreamingRoute(): string[] {
    return ['/', 'interventions', this.interventionId, interventionConfig.routes.streaming];
  }

  get interventionMapRoute(): string[] {
    return ['/', 'interventions', this.interventionId, interventionConfig.routes.tdraw, 'home'];
  }

  get interventionMediaRoute(): string[] {
    return ['/', 'interventions', this.interventionId, interventionConfig.routes.media];
  }

  get interventionSettingsRoute(): string[] {
    return ['/', 'interventions', this.interventionId, interventionConfig.routes.settings];
  }

  get interventionAnalysesRoute(): string[] {
    return ['/', 'interventions', this.interventionId, interventionConfig.routes.analyses];
  }
}
