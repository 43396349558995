<form
  name="loginForm"
  #loginForm="ngForm"
  novalidate
  class="row justify-content-start"
  [class.mid-form-error]="state.authError"
>
  <!-- logo -->
  <div class="col-12 text-center mid-card-logo">
    <img class="img-fluid" src="/assets/images/mid-logo-login.png" />
  </div>

  <div class="col-12">
    Email
    <app-mid-input
      [required]="!credentials.code"
      autocomplete="off"
      (valueChange)="onEmailChange($event)"
      [disabled]="state.isLoading"
      [size]="MidInputSize.wide"
    >
    </app-mid-input>
  </div>

  <div class="col-12 mt-4">
    Password
    <app-mid-input
      [required]="!credentials.code"
      autocomplete="off"
      [type]="state.passwordVisibility ? 'text' : 'password'"
      [fasType]="state.passwordVisibility ? 'fa-eye-slash' : 'fa-eye'"
      (fasChange)="onPasswordVisibilityChange()"
      (valueChange)="onPasswordChange($event)"
      [disabled]="state.isLoading"
      [size]="MidInputSize.wide"
    >
    </app-mid-input>
  </div>

  <div class="col-12 d-flex justify-content-end mt-3">
    <a [routerLink]="recoverPasswordRoute" class="mid-link-primary">{{ 'LOGIN_FORGOT_PASSWORD' | translate }}</a>
  </div>

  <div class="col-12 text-center mid-box-error">
    <span class="mid-text-error">
      <sup>(*)</sup>
      <ng-container *ngIf="hasAuthenticationErrorType(AuthenticationError.invalidCredentials)">
        {{ 'INCORRECT_EMAIL_PASSWORD' | translate }}
      </ng-container>
      <ng-container *ngIf="hasAuthenticationErrorType(AuthenticationError.unexpectedError)">
        {{ 'UNEXPECTED_ERROR' | translate }}
      </ng-container>
    </span>
  </div>

  <div class="col-12">
    <div class="w-100 text-center access-code-header">
      <div class="access-code-header-label">
        {{ 'ACCESS_CODE.OR' | translate }}
      </div>
    </div>
  </div>

  <div class="col-12 mt-2 mb-5">
    {{ 'ACCESS_CODE.USE_ACCESS_CODE' | translate }}
    <app-mid-input
      autocomplete="off"
      [type]="state.codeVisibility ? 'text' : 'password'"
      [fasType]="state.codeVisibility ? 'fa-eye-slash' : 'fa-eye'"
      (fasChange)="onCodeVisibilityChange()"
      (valueChange)="onCodeChange($event)"
      [disabled]="state.isLoading"
      [size]="MidInputSize.wide"
    >
    </app-mid-input>
  </div>

  <mat-checkbox class="ml-3 mb-2"> {{ 'KEEP_ME_LOGGED_IN' | translate }}</mat-checkbox>

  <!-- buttons -->
  <div class="col-12 text-center">
    <button
      type="submit"
      class="btn btn-block mid-btn-primary"
      [disabled]="loginForm.invalid || state.isLoading"
      (click)="onLogin()"
    >
      <span *ngIf="!state.isLoading">{{ 'LOGIN' | translate }}</span>
      <span *ngIf="state.isLoading"><i class="fas fa-sync fa-spin"></i></span>
    </button>
  </div>
</form>
