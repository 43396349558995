import { NgModule } from '@angular/core';
import { NewUserComponent } from './new-user/new-user.component';
import { CoreModule } from '../core/core.module';
import { FormsModule } from '@angular/forms';
import { OrganizationComponent } from './organization/organization.component';
import { CommonModule } from '@angular/common';
import { MidgardControlsModule } from '../midgard-controls/midgard-controls.module';
import { OrganizationPropertiesComponent } from './organization-properties/organization-properties.component';
import { RouterModule } from '@angular/router';
import { OrganizationTabHeaderComponent } from './organization-tab-header/organization-tab-header.component';
import { OrganizationUsersComponent } from './organization-users/organization-users.component';
import { OrganizationTabbedViewComponent } from './organization-tabbed-view/organization-tabbed-view.component';
import { OrganizationPropertiesRouteComponent } from './organization-properties-route/organization-properties-route.component';
import { OrganizationChangeLogoComponent } from './organization-properties/organization-change-logo/organization-change-logo.component';
import { OrganizationUserRouteComponent } from './organization-user-route/organization-user-route.component';
import { OrganizationGroupsComponent } from './organization-groups/organization-groups.component';
import { OrganizationGroupsRouteComponent } from './organization-groups-route/organization-groups-route.component';
import { AddUserInGroupComponent } from './add-user-in-group/add-user-in-group.component';
import { SelectUserComponent } from './select-user/select-user.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatListModule } from '@angular/material/list';
import { NewOrganizationGroupComponent } from './new-organization-group/new-organization-group.component';
import { NewOrganizationUserComponent } from './new-organization-user/new-organization-user.component';
import { AddUserButtonComponent } from './add-user-button/add-user-button.component';
import { SelectOrgGroupComponent } from './select-org-group/select-org-group.component';
import { MatMenuModule } from '@angular/material/menu';
import { OrganizationUserItemComponent } from './organization-user-item/organization-user-item.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { RoleSelectComponent } from './role-select/role-select.component';
import { OrganizationUsersFilterComponent } from './organization-users-filter/organization-users-filter.component';
import { TdrawModule } from '../tdraw/tdraw.module';
import { AccessCodeComponent } from './access-code/access-code.component';
import { AccessCodeRouteComponent } from './access-code-route/access-code-route.component';
import { NewAccessCodeComponent } from './new-access-code/new-access-code.component';
import { InterventionCommonModule } from '../intervention/common/intervention-common.module';
import { AccessCodeQrComponent } from './access-code-qr/access-code-qr.component';
import { QRCodeModule } from 'angularx-qrcode';
import { OrganizationDistributionListComponent } from './organization-distribution-list/organization-distribution-list.component';
import { OrganizationDistributionListRouteComponent } from './organization-distribution-list-route/organization-distribution-list-route.component';
import { NewOrganizationDistributionListComponent } from './new-organization-distribution-list/new-organization-distribution-list.component';
import { UpdateOrganizationDistributionListComponent } from './update-organization-distribution-list/update-organization-distribution-list.component';

@NgModule({
  declarations: [
    NewUserComponent,
    OrganizationComponent,
    OrganizationPropertiesComponent,
    OrganizationTabHeaderComponent,
    OrganizationUsersComponent,
    OrganizationTabbedViewComponent,
    OrganizationPropertiesRouteComponent,
    OrganizationChangeLogoComponent,
    OrganizationUserRouteComponent,
    OrganizationGroupsComponent,
    OrganizationGroupsRouteComponent,
    OrganizationDistributionListComponent,
    OrganizationDistributionListRouteComponent,
    AddUserInGroupComponent,
    SelectUserComponent,
    NewOrganizationGroupComponent,
    NewOrganizationUserComponent,
    NewOrganizationDistributionListComponent,
    UpdateOrganizationDistributionListComponent,
    AddUserButtonComponent,
    SelectOrgGroupComponent,
    OrganizationUserItemComponent,
    RoleSelectComponent,
    OrganizationUsersFilterComponent,
    AccessCodeComponent,
    AccessCodeRouteComponent,
    NewAccessCodeComponent,
    AccessCodeQrComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    MidgardControlsModule,
    RouterModule,
    MatAutocompleteModule,
    MatListModule,
    MatMenuModule,
    NgxPaginationModule,
    TdrawModule,
    QRCodeModule,
    InterventionCommonModule,
  ],
  exports: [NewUserComponent, OrganizationComponent],
})
export class UserManagementModule {}
