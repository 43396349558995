import { Feature } from '@turf/turf';
import * as turf from '@turf/turf';
import _ from 'lodash';
import mapboxgl from 'mapbox-gl';
import { CircleType } from '../models/circle-type.enum';
import { MapAnnotationNestedDto } from '../models/dto';
import { IconType, MapIcon, SpecialType } from '../models/map-icon';
import { MapState } from '../models/map-state.model';
import { MapDrawManager } from './map-draw-manager';
import { FeatureProperty } from '../models/feature-property.enum';
import { MapModeManager } from './map-mode-manager';
import { MapIconsService } from '../services/map-icons.service';
import { ServiceLocator } from 'src/app/global/locator.service';
import { GuidService } from 'src/app/global/guid.service';
import { Model3dStore } from './model3d-layer';

/**
 * EditModeManager handles Map interactions for Edit mode pages
 *
 * Examples of responsabilities:
 * - Keep track of selected feature
 * - Triggers different events about Features
 * - Handles drawing logic related to UI parts
 * - Make modifications to MapState while actions are executed
 */
export class EditModeManager extends MapModeManager {
  isDrawingNewAnnotation: boolean = false;
  onNextMapClickListener = null;

  mapManager: MapDrawManager;

  private mapIconsService: MapIconsService;
  private guidService: GuidService;

  constructor(protected map: mapboxgl.Map, protected mapState: MapState) {
    super(map, mapState);
    this.isEditMode = true;
    this.mapManager = new MapDrawManager(map);
    this.mapIconsService = ServiceLocator.injector.get(MapIconsService);
    this.guidService = ServiceLocator.injector.get(GuidService);
    this.initListeners();
  }

  //=====
  // Internal logic
  //=====

  deleteSelectedSource() {
    // Stop MapboxGL Draw from editing the selected source
    this.mapManager.stopDrawing();
    this.mapManager.deleteSource(this.selectedSourceId);

    // If deleted annotation is a Sector, removes it from Resources icons aswell
    const annotation = this.mapState.annotations.find((annotation) => annotation.sourceId === this.selectedSourceId);
    const feature = annotation.data.features[0];
    const icon = feature.properties[FeatureProperty.Icon];
    if (icon) {
      const ref = this.mapIconsService.getMapIconReference(icon);
      const mapIcon = this.mapIconsService.getMapIcon(ref.guiIcon);
      if (mapIcon.specialType === SpecialType.Sector) {
        const featureId = feature.id;
        for (const an of this.mapState.annotations) {
          if (an.data.features[0].properties[FeatureProperty.SectorBelong] === featureId) {
            an.data.features[0].properties[FeatureProperty.SectorBelong] = null;
          }
        }
      }
    }

    // Remove the annotation in mapState
    _.remove(this.mapState.annotations, (annotation) => annotation.sourceId === this.selectedSourceId);

    // Unselect the source
    this.selectedSourceId = null;

    this.notifyFeatureUnselected();
    this.notifyFeaturesChanged();
  }

  saveCurrentDrawingIfAny() {
    if (this.selectedSourceId) {
      this.mapManager.updateSourceWithDrawnData(this.selectedSourceId);
      this.mapManager.stopDrawing();
      this.updateOrCreateAnnotationFromSource(this.selectedSourceId);
      this.mapManager.showSource(this.selectedSourceId);
    }
    this.selectedSourceId = null;
  }

  private setSelectedFeature(feature: Feature) {
    this.selectedFeature = feature;
    this.notifyFeatureSelectedChanged();
  }

  private startEditingSource(sourceId: string) {
    // Do nothing if we are using a 3D tool
    if (Model3dStore.activeTool !== null) {
      return;
    }

    // If it's the currently edited feature, do nothing
    if (sourceId === this.selectedSourceId || sourceId.startsWith('mapbox-gl-draw')) {
      return;
    }

    // In every case, if we did not clicked on the currently edited feature
    // we want to start by stoping editing if any editing is in progress
    this.closeEditMode();

    // Here we can't assign the feature directly because it may
    // represent a tiled vector feature and it does not contain a String ID
    this.selectedSourceId = sourceId;
    this.mapManager.startDrawingFromSource(sourceId);
    this.setSelectedFeature(this.mapManager.getCurrentlyDrawnFeature());
  }

  private closeEditMode() {
    // Stop listening to "next click" listener
    if (this.onNextMapClickListener) {
      this.map.off('click', this.onNextMapClickListener);
      this.onNextMapClickListener = null;
      this.isDrawingNewAnnotation = false;
    }

    // We need to save before closing the edit mode if necessary
    this.saveCurrentDrawingIfAny();

    // We need to stop drawing new feature aswell
    if (this.isDrawingNewAnnotation) {
      this.mapManager.stopDrawing();
    }

    this.map.getCanvas().style.cursor = '';
    this.selectedSourceId = null;
    this.selectedFeature = null;
    this.isDrawingNewAnnotation = false;
    this.notifyFeatureUnselected();
  }

  private updateOrCreateAnnotationFromSource(sourceId: string) {
    // Grab data from source
    const data = this.mapManager.getAnnotationSourceData(sourceId);

    // Find corresponding annotation
    let annotation = _.find(this.mapState.annotations, (annotation) => annotation.sourceId === sourceId);

    // Or create the annotation if it does not exist and put it in mapState store
    if (!annotation) {
      annotation = new MapAnnotationNestedDto(sourceId);
      if (!this.mapState.annotations || this.mapState.annotations.length === 0) {
        this.mapState.annotations = [];
      }
      this.mapState.annotations.push(annotation);
    }

    // Fill annotation with data
    const feature = data.features[0];
    annotation.data = data;
    annotation.type = this.mapManager.getAnnotationType(feature);
    annotation.category = this.mapManager.getAnnotationCategory(feature);
    this.notifyFeaturesChanged();
  }

  private createAnnotationFromDrawing(): string {
    // Put the drawn data inside a Source
    const data = this.mapManager.getDrawnData();
    const sourceId = this.mapManager.createSource(data);

    // The new source is selected because we are still editing it
    this.selectedSourceId = sourceId;

    // Create the annotation and put it in mapState store
    this.updateOrCreateAnnotationFromSource(sourceId);
    return sourceId;
  }

  createAnnotationFromFeature(feature: Feature): string {
    // Put the feature inside a Source
    const data = turf.featureCollection([feature]);
    const sourceId = this.mapManager.createSource(data);

    // Create the annotation and put it in mapState store
    this.updateOrCreateAnnotationFromSource(sourceId);
    return sourceId;
  }

  //=====
  // Listeners
  //=====

  private initListeners() {
    this.map.on('click', (e) => this.onMapClick(e));
    this.map.on('draw.selectionchange', (e) => this.onMapDrawSelectionChange(e));
    this.map.on('draw.create', (e) => this.onMapDrawCreate(e));
    this.map.on('draw.update', (e) => this.onMapDrawUpdate(e));
  }

  private onMapClick(e) {
    // If a new annotation is under creation, disable click events
    // This won't disable internal MapboxGL Draw events
    // So double-click will still finish the annotation creation
    if (this.isDrawingNewAnnotation) {
      return;
    }

    // Select features in a bbox of 5px around the clicked point
    const features: mapboxgl.MapboxGeoJSONFeature[] = this.map.queryRenderedFeatures([
      [e.point.x - 5, e.point.y - 5],
      [e.point.x + 5, e.point.y + 5],
    ]);
    if (!features.length) {
      this.closeEditMode();
      return;
    }

    // Navigate through features to be able to select the ones in background aswell
    const feature = this.mapManager.navigateThroughFeatures(features);
    if (!feature) {
      this.closeEditMode();
      return;
    }
    this.startEditingSource(feature.source);
  }

  private onMapDrawSelectionChange(e) {
    // When mapbox draw triggers a selectionchange
    // it also means we stopped drawing any new feature
    this.isDrawingNewAnnotation = false;

    const features = e.features;
    if (features.length === 1) {
      const feature = features[0];

      // Avoid edges and vertices modification of Cones
      if (feature.properties?.isCone) {
        this.mapManager.coneMoveMode();
      }

      // Here we can assign the feature directly because it's been
      // handled by MapboxDraw and so it contains a String ID
      this.setSelectedFeature(feature);
    } else {
      this.closeEditMode();
    }
  }

  private onMapDrawCreate(e) {
    const feature = e.features[0];
    if (feature) {
      // Set default feature properties
      this.mapManager.handleFeatureCreation(feature);

      // Create a brand new annotation from MapboxDraw content
      this.createAnnotationFromDrawing();
      this.setSelectedFeature(feature);
    }
  }

  private onMapDrawUpdate(e) {
    // This event is fired after a feature modification in Mapbox Draw
    // It means every time a feature has been moved, or a coordinate has been changed
    const feature = e.features?.[0];
    if (feature) {
      this.setSelectedFeature(feature);
    }
  }

  //=====
  // Drawing interactions
  //=====

  private prepareForDrawingInteraction() {
    Model3dStore.disableActiveTool();
    this.closeEditMode();
  }

  startDrawingPolygon() {
    this.prepareForDrawingInteraction();
    this.isDrawingNewAnnotation = true;
    this.mapManager.drawPolygonMode();
  }

  startDrawingLine() {
    this.prepareForDrawingInteraction();
    this.isDrawingNewAnnotation = true;
    this.mapManager.drawLineMode();
  }

  startDrawingIcon(icon: MapIcon) {
    this.prepareForDrawingInteraction();
    this.isDrawingNewAnnotation = true;
    if (icon.type === IconType.Symbol) {
      this.mapManager.drawIconMode(icon.defaultMapIcon);
    } else if (icon.type === IconType.Line) {
      this.mapManager.drawLineMode(icon.defaultMapIcon);
    }
  }

  startDrawingCircle(type: CircleType) {
    this.prepareForDrawingInteraction();
    this.isDrawingNewAnnotation = true;
    this.map.getCanvas().style.cursor = 'crosshair';
    this.onNextMapClickListener = (e) => this.onDrawCircleClickListener(e, type);
    this.map.once('click', this.onNextMapClickListener);
  }
  private onDrawCircleClickListener(e, type: CircleType) {
    this.prepareForDrawingInteraction();
    const lngLat = e.lngLat;
    const circle = this.mapManager.createCircle(lngLat.lat, lngLat.lng, type);
    const sourceId = this.createAnnotationFromFeature(circle);
    this.mapManager.showSource(sourceId);
  }
  startDrawingAllCircles() {
    this.prepareForDrawingInteraction();
    this.isDrawingNewAnnotation = true;
    this.map.getCanvas().style.cursor = 'crosshair';
    this.onNextMapClickListener = (e) => this.onDrawAllCirclesClickListener(e);
    this.map.once('click', this.onNextMapClickListener);
  }
  private onDrawAllCirclesClickListener(e) {
    this.onDrawCircleClickListener(e, CircleType.Large);
    this.onDrawCircleClickListener(e, CircleType.Medium);
    this.onDrawCircleClickListener(e, CircleType.Small);
  }
  changeCurrentCircleRadius(radiusInMeters: number): void {
    const radiusInKm = radiusInMeters / 1000;
    this.mapManager.changeDrawCircleRadius(radiusInKm);
    this.setSelectedFeature(this.mapManager.getCurrentlyDrawnFeature());
  }

  startDrawingCone(wind: number) {
    this.prepareForDrawingInteraction();
    this.isDrawingNewAnnotation = true;
    this.map.getCanvas().style.cursor = 'crosshair';
    this.onNextMapClickListener = (e) => this.onDrawConeClickListener(e, wind);
    this.map.once('click', this.onNextMapClickListener);
  }
  private onDrawConeClickListener(e, wind: number) {
    this.prepareForDrawingInteraction();
    const lngLat = e.lngLat;
    const cone = this.mapManager.createCone(lngLat.lat, lngLat.lng, wind);
    const sourceId = this.createAnnotationFromFeature(cone);
    this.mapManager.showSource(sourceId);
  }
  changeCurrentConeWind(wind) {
    const drawnFeature = this.mapManager.changeDrawnConeWind(wind);
    this.setSelectedFeature(drawnFeature);
  }

  startDrawingRegularMarker(content: string) {
    this.prepareForDrawingInteraction();
    this.isDrawingNewAnnotation = true;
    this.map.getCanvas().style.cursor = 'crosshair';
    this.onNextMapClickListener = (e) => this.onDrawRegularMarkerClickListener(e, content);
    this.map.once('click', this.onNextMapClickListener);
  }
  private onDrawRegularMarkerClickListener(e, content: string) {
    this.prepareForDrawingInteraction();
    this.displayRegularMarker(
      {
        lat: e.lngLat.lat,
        lng: e.lngLat.lng,
        content: content,
        guid: this.guidService.generate(),
      },
      true,
      `public/assets-kmz/${this.mapState._id}`
    );
  }

  setCurrentFeatureProperty(property: FeatureProperty, value: any): void {
    this.mapManager.setFeatureProperty(this.selectedFeature, property, value);
    this.setSelectedFeature(this.mapManager.getCurrentlyDrawnFeature());
  }

  //=====
  // Misc
  //=====

  initSourceSelection(sourceId: string) {
    this.startEditingSource(sourceId);
  }
}
