import { WebSocketService } from 'src/app/core/web-socket.service';
import { Injectable, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { RequireLowerCaseLetterValidatorDirective } from './forms/require-lower-case-letter-validator.directive';
import { RequireUpperCaseLetterValidatorDirective } from './forms/require-upper-case-letter-validator.directive';
import { RequireNumberValidatorDirective } from './forms/require-number-validator.directive';
import { RequireSpecialCharacterValidatorDirective } from './forms/require-special-character-validator.directive';
import { RequireNotToMatchValidatorDirective } from './forms/require-not-to-match-validator.directive';
import { RequireToMatchValidatorDirective } from './forms/require-to-match-validator.directive';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ForbiddenCharactersValidatorDirective } from './forms/forbidden-characters-validator.directive';
import {
  IncludedCharactersPipe,
  JoinPipe,
  NotIncludedCharactersPipe,
  SecureImagePipe,
  IsEmptyPipe,
  SafePipe,
  DefaultValuePipe,
  FormatTimePipe,
} from './utils';
import { MatErrorStateMatcherDirective } from './forms/mat-error-state-matcher.directive';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSelectErrorStateMatcherDirective } from './forms/mat-select-error-state-matcher.directive';
import { UniqueValidatorDirective } from './forms';
import { AdHostDirective } from './ad-host.directive';
import { IsFeatureEnabledPipe } from './features';
import { ClickStopPropagationDirective } from './utils/click-stop-propagation.directive';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export class MissingTranslationService implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    return `WARN: '${params.key}' is missing in '${params.translateService.currentLang}' locale`;
  }
}

@Injectable()
export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): string {
    return `**MISSING KEY: ${params.key}**`;
  }
}

@NgModule({
  declarations: [
    ForbiddenCharactersValidatorDirective,
    RequireLowerCaseLetterValidatorDirective,
    RequireUpperCaseLetterValidatorDirective,
    RequireNumberValidatorDirective,
    RequireSpecialCharacterValidatorDirective,
    RequireToMatchValidatorDirective,
    RequireNotToMatchValidatorDirective,
    JoinPipe,
    IncludedCharactersPipe,
    NotIncludedCharactersPipe,
    MatErrorStateMatcherDirective,
    MatSelectErrorStateMatcherDirective,
    SecureImagePipe,
    IsEmptyPipe,
    UniqueValidatorDirective,
    AdHostDirective,
    IsFeatureEnabledPipe,
    SafePipe,
    ClickStopPropagationDirective,
    DefaultValuePipe,
    FormatTimePipe,
  ],
  imports: [
    CommonModule,
    MatInputModule,
    MatSelectModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: true,
    }),
  ],
  exports: [
    TranslateModule,
    ForbiddenCharactersValidatorDirective,
    RequireLowerCaseLetterValidatorDirective,
    RequireUpperCaseLetterValidatorDirective,
    RequireNumberValidatorDirective,
    RequireSpecialCharacterValidatorDirective,
    RequireToMatchValidatorDirective,
    RequireNotToMatchValidatorDirective,
    JoinPipe,
    IncludedCharactersPipe,
    NotIncludedCharactersPipe,
    MatErrorStateMatcherDirective,
    MatSelectErrorStateMatcherDirective,
    SecureImagePipe,
    IsEmptyPipe,
    UniqueValidatorDirective,
    AdHostDirective,
    IsFeatureEnabledPipe,
    SafePipe,
    ClickStopPropagationDirective,
    DefaultValuePipe,
    FormatTimePipe,
  ],
  providers: [
    {
      provide: MissingTranslationHandler,
      useClass: MyMissingTranslationHandler,
    },
    WebSocketService,
  ],
})
export class CoreModule {}
