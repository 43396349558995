import { Component, OnDestroy, OnInit } from '@angular/core';
import { Intervention, Organization } from 'src/app/autogenerated/model';
import { Unsubscribe } from 'src/app/core/decorators';
import { InterventionTabContentComponent } from '../intervention-tab-container/intervention-tab-container.component';

@Unsubscribe()
@Component({
  selector: 'app-intervention-tab-streaming',
  templateUrl: './intervention-tab-streaming.component.html',
  styleUrls: ['./intervention-tab-streaming.component.scss'],
})
export class InterventionTabStreamingComponent implements InterventionTabContentComponent, OnInit, OnDestroy {
  hasAdminPermissions: boolean;
  intervention: Intervention;
  organization: Organization;

  ngOnDestroy(): void {}

  ngOnInit(): void {}
}
