import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import mapboxgl from 'mapbox-gl';
import { TdrawService } from 'src/app/autogenerated/tdrawService';
import { OrganizationStoreService } from 'src/app/organization/store';
import { tdrawConfig } from '../../tdraw.config';
import { InfopointManager, InfopointData } from '../../misc/infopoint-manager';
import { GisManager } from '../../misc/gis-manager';
import { MapStateUpdateDto } from '../../models/dto';
import _ from 'lodash';
import CoordinatesHelpers from '../../misc/coordinates-helpers';
import { MatDialog } from '@angular/material/dialog';
import { Unsubscribe } from 'src/app/core/decorators';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LocationSearchResult } from '../../models/location-search-result.model';
import { CreateConeModalComponent } from '../../components/modals/create-cone-modal/create-cone-modal.component';
import { RightPanelData, RightPanelMode } from '../../models/right-panel-data';
import { interventionConfig } from 'src/app/intervention/intervention.config';
import { MapIconsService } from '../../services/map-icons.service';
import { CircleType } from '../../models/circle-type.enum';
import { EditRegularMarkerModalComponent } from '../../components/modals/edit-regular-marker-modal/edit-regular-marker-modal.component';
import { MapIcon } from '../../models/map-icon';
import { OrganizationMedia, UpdateInterventionDto } from '../../../autogenerated/model';
import { UpdateStatisticsModalComponent } from '../../components/modals/update-statistics-modal/update-statistics-modal.component';
import { InterventionService } from '../../../autogenerated/interventionService';
import { InterventionStoreService } from '../../../intervention/store';
import { MapState } from '../../models/map-state.model';
import { EditModeManager } from '../../misc/edit-mode-manager';
import { ExportMapStateModalComponent } from '../../components/modals/export-mapstate-modal/export-mapstate-modal.component';
import { KmzImporter } from '../../misc/kmz-importer';
import MapCompareTool from '../../misc/map-compare-tool';
import { MapStateFlightsDto } from '../../models/dto/map-state-flights.dto';
import { RightPanelType } from 'src/app/midgard-controls/enums/right-panel-type';
import { rightPanelDisplayState } from 'src/app/midgard-controls/enums/display-state.enum';
import { Model3dStore, Model3dToolType } from '../../misc/model3d-layer';

@Unsubscribe()
@Component({
  selector: 'app-tdraw-gis-edit-mode-page',
  templateUrl: './tdraw-gis-edit-mode-page.component.html',
  styleUrls: ['./tdraw-gis-edit-mode-page.component.scss'],
})
export class TdrawGisEditModePageComponent implements OnInit, OnDestroy {
  // Input
  interventionId: string;
  organizationId: string;
  mapStateId: string;

  // Map
  map: mapboxgl.Map;
  currentInfo: InfopointData;
  date = new Date();
  gisManager: GisManager;
  editModeManager: EditModeManager;

  // State
  mapState: MapState;
  rightPanelData: RightPanelData = { mode: RightPanelMode.Navigation };

  // Pinpoint image selection
  pinpointMedia: OrganizationMedia = null;
  pinpointMediaPanelDisplayState = rightPanelDisplayState.hidden;

  // Subscriptions
  private organizationSubscription: Subscription;
  private featureSelectedChangedSubscription: Subscription;
  private featureUnselectedSubscription: Subscription;
  private pinpointOpenedSubscription: Subscription;

  // MapCompare
  private mapCompare: MapCompareTool;
  isCompareMode: boolean = false;

  // Accessor
  get Model3dStore() {
    return Model3dStore;
  }

  // Enums
  get Model3dToolType() {
    return Model3dToolType;
  }
  get RightPanelMode() {
    return RightPanelMode;
  }
  get PinpointMediaPanelType() {
    return RightPanelType;
  }

  constructor(
    readonly translate: TranslateService,
    activatedRoute: ActivatedRoute,
    private router: Router,
    private organizationStoreService: OrganizationStoreService,
    private tdrawService: TdrawService,
    private dialog: MatDialog,
    private mapIconsService: MapIconsService,
    private interventionService: InterventionService,
    private interventionStoreService: InterventionStoreService
  ) {
    this.mapStateId = activatedRoute.snapshot.data.mapStateId;
    this.interventionId = activatedRoute.snapshot.data.interventionId;

    this.organizationSubscription = this.organizationStoreService
      .getCurrentOrganization()
      .subscribe(async (organization) => {
        if (!organization || this.organizationId === organization._id) {
          return;
        }
        this.organizationId = organization._id;
      });
  }

  async ngOnInit() {
    this.mapState = await this.tdrawService?.getMapState(this.organizationId, this.interventionId, this.mapStateId);
    this.initMap();
  }

  ngOnDestroy(): void {}

  close() {
    this.router.navigate([`interventions/${this.interventionId}/${interventionConfig.routes.tdraw}/home`]);
  }

  toggleCompare() {
    if (this.isCompareMode) {
      this.mapCompare.stop();
      this.isCompareMode = false;
    } else {
      this.mapCompare.start();
      this.isCompareMode = true;
    }
  }

  private exitPropertiesPanel() {
    if (this.rightPanelData.mode !== RightPanelMode.Hidden) {
      this.rightPanelData.mode = RightPanelMode.Navigation;
    }
  }

  setRightPanelMode(mode: RightPanelMode) {
    this.rightPanelData.mode = mode;
  }

  geolocalizeMe() {
    this.gisManager.geolocalizeMe();
  }

  async printSnapshot() {
    this.editModeManager.mapManager.printSnapshot(true);
  }

  async downloadSnapshot() {
    this.editModeManager.mapManager.downloadSnapshot(true);
  }

  async saveData() {
    this.editModeManager.saveCurrentDrawingIfAny();

    // Only take what's important for the server to keep about flights
    const flights: MapStateFlightsDto = {};
    Object.entries(this.gisManager.flightStates).forEach(([key, value]) => {
      flights[key] = {
        isPathActive: value.isPathActive ?? false,
        isIconActive: value.isIconActive ?? false,
      };
    });

    // Create a DTO object
    const updateDto = new MapStateUpdateDto({
      name: this.mapState.name,
      background: this.gisManager.getMapBackground(),
      annotations: _.cloneDeep(this.mapState.annotations),
      medias: this.gisManager.mediaStates,
      regularMarkers: this.editModeManager.mapManager.regularMarkers,
      statistics: this.mapState.statistics,
      flights: flights,
    });

    // Send to server
    try {
      await this.tdrawService.updateMap(this.organizationId, this.interventionId, this.mapStateId, updateDto);
    } catch (e) {
      console.error(e);
    }

    this.setRightPanelMode(RightPanelMode.Hidden);
  }

  async publishHistory() {
    const dialogRef = this.dialog.open(UpdateStatisticsModalComponent, {
      id: 'tdrawUpdateStatisticsModal',
      width: '450px',
    });
    const confirm = await dialogRef.afterClosed().toPromise();
    if (confirm) {
      await this.onUpdateStatistics();
    }

    await this.saveData();

    const snapshot = await this.editModeManager.mapManager.takeSnapshot(true);
    await this.tdrawService.publishMap(this.organizationId, this.interventionId, this.mapStateId, snapshot);

    this.router.navigate([
      `interventions/${this.interventionId}/${interventionConfig.routes.tdraw}/gis/${this.mapState._id}/view`,
    ]);
  }

  private async onUpdateStatistics() {
    this.interventionService.getInterventionV1(this.organizationId, this.mapState.interventionId).then(async (data) => {
      data.statistics = this.mapState.statistics;
      try {
        const intervention = await this.interventionService.updateInterventionV1(
          this.organizationId,
          this.mapState.interventionId,
          data as UpdateInterventionDto
        );
        this.interventionStoreService.updateIntervention(this.organizationId, intervention);
      } catch (e) {
        console.log(e);
      }
    });
  }

  coneCreationSelection() {
    const dialogRef = this.dialog.open(CreateConeModalComponent, {
      width: '900px',
    });
    dialogRef.afterClosed().subscribe((wind: number) => {
      if (!wind) {
        return;
      }
      this.onDrawCone(wind);
    });
  }

  markerCreationSelection() {
    const dialogRef = this.dialog.open(EditRegularMarkerModalComponent, {
      width: '900px',
    });
    dialogRef.afterClosed().subscribe((content: string) => {
      if (!content) {
        return;
      }
      this.onDrawRegularMarker(content);
    });
  }

  openExportModal() {
    this.dialog.open(ExportMapStateModalComponent, {
      width: '900px',
      data: {
        mapState: this.mapState,
      },
    });
  }

  async importFile(files) {
    const file: File = files[0];
    if (file) {
      const kmzImporter = new KmzImporter(
        this.organizationId,
        this.interventionId,
        this.editModeManager,
        this.mapState
      );
      await kmzImporter.import(file);
    }
  }

  private initMap() {
    // Be sure that all projections we use are loaded in proj4
    CoordinatesHelpers.registerProjections();

    this.map = new mapboxgl.Map({
      accessToken: tdrawConfig.mapAccessToken,
      container: 'map-gis-edit',
      style: tdrawConfig.mapStyle,
      center: tdrawConfig.defaultMapCenter,
      zoom: 13,
    });
    this.map.addControl(new mapboxgl.ScaleControl());
    this.map.addControl(new mapboxgl.NavigationControl(), 'bottom-left');

    this.gisManager = new GisManager(this.map);
    this.editModeManager = new EditModeManager(this.map, this.mapState);

    // Initialize Infopoint to get informations on map click events
    new InfopointManager(this.map).init((info: InfopointData) => {
      this.currentInfo = info;
    });

    // Listeners
    this.map.on('load', () => this.onMapLoad());
    this.featureSelectedChangedSubscription = this.editModeManager.onFeatureSelectedChanged((data) => {
      this.setRightPanelMode(RightPanelMode.Properties);
    });
    this.featureUnselectedSubscription = this.editModeManager.onFeatureUnselected(() => {
      this.exitPropertiesPanel();
    });
    this.pinpointOpenedSubscription = this.gisManager.onPinpointOpened((image: OrganizationMedia) => {
      this.pinpointMedia = image;
      this.pinpointMediaPanelDisplayState = rightPanelDisplayState.displaying;
    });

    // Hide 204 Errors from TMS Tiles
    this.map.on('error', (e) => {
      if (e.source?.scheme === 'tms') {
        return;
      }
      console.error(e);
    });

    this.mapCompare = new MapCompareTool(this.map);
  }

  private async onMapLoad() {
    await this.gisManager.load();
    this.mapIconsService.loadImages(this.map);

    // Wait for map prerequisites to be fully loaded before loading data
    this.map.once('idle', () => {
      this.gisManager.loadDataOnMap(this.editModeManager);
    });
  }

  flyMapToLocation(location: LocationSearchResult) {
    const center = location.coordinates;
    const zoom = 13;
    this.map.flyTo({ center, zoom });
  }

  onDeleteSource() {
    this.editModeManager.deleteSelectedSource();
  }

  private onDrawRegularMarker(content: string) {
    this.editModeManager.startDrawingRegularMarker(content);
  }

  private onDrawCone(wind: number) {
    this.editModeManager.startDrawingCone(wind);
  }

  changeDrawnConeWind(wind) {
    this.editModeManager.changeCurrentConeWind(wind);
  }

  onDrawCircle(type: CircleType) {
    this.editModeManager.startDrawingCircle(type);
  }

  onDrawAllCircles() {
    this.editModeManager.startDrawingAllCircles();
  }

  onDrawIcon(icon: MapIcon) {
    this.editModeManager.startDrawingIcon(icon);
  }

  onDrawLine() {
    this.editModeManager.startDrawingLine();
  }

  onDrawPolygon() {
    this.editModeManager.startDrawingPolygon();
  }
}
