<div class="">
  <div class="tile-header">
    <h3>{{ data.org.name }}</h3>
    <div class="tile-content"><img src="{{ data.org.logoUrl }}" /></div>
  </div>
  <div class="tile-menu-container" *ngFor="let feature of data.org.features">
    <app-mid-toggle-switch
      textOn="{{ disable }} {{ feature.translatedName }}"
      textOff="{{ enable }} {{ feature.translatedName }}"
      [(state)]="feature.isEnabled"
      (action)="toggleFeature($event, feature)"
      labelPosition="after"
    ></app-mid-toggle-switch>
  </div>
</div>
