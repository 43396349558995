import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Feature } from '@turf/turf';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { Unsubscribe } from 'src/app/core/decorators';
import { ViewModeManager } from 'src/app/tdraw/misc/view-mode-manager';
import { AnnotationCategory } from 'src/app/tdraw/models/annotation-category.enum';
import { MapIcon, SpecialType } from 'src/app/tdraw/models/map-icon';
import { MapIconsService } from 'src/app/tdraw/services/map-icons.service';
import { FeatureCalculation } from '../../../../models/feature-calculation.model';
import { FeatureProperty } from '../../../../models/feature-property.enum';

@Unsubscribe()
@Component({
  selector: 'tdraw-readonly-properties-panel',
  templateUrl: './readonly-properties-panel.component.html',
  styleUrls: ['./readonly-properties-panel.component.scss'],
})
export class ReadonlyPropertiesPanelComponent implements OnInit, OnDestroy {
  @Input()
  viewModeManager: ViewModeManager;

  @Output()
  goToToolbox: EventEmitter<any>;

  calculation: FeatureCalculation;
  selectedFeature: Feature;
  mapIcon: MapIcon;
  sectorsById: { [featureId: string]: { title: string } };

  // Subscriptions
  private featureSelectedChangedSubscription: Subscription;

  // Enum
  get SpecialType() {
    return SpecialType;
  }
  get AnnotationCategory() {
    return AnnotationCategory;
  }

  constructor(private mapIconsService: MapIconsService) {
    this.goToToolbox = new EventEmitter();
  }

  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.selectedFeature = this.viewModeManager.selectedFeature;
    this.onFeatureSelectedChanged();

    this.featureSelectedChangedSubscription = this.viewModeManager.onFeatureSelectedChanged((data) => {
      this.selectedFeature = data.feature;
      this.onFeatureSelectedChanged();
    });

    this.loadSectors();
  }

  private loadSectors() {
    const sectors = this.viewModeManager.getSectors();
    this.sectorsById = _.keyBy(sectors, 'featureId');
  }

  private onFeatureSelectedChanged() {
    const key = this.selectedFeature.properties?.icon;
    this.mapIcon = this.mapIconsService.getMapIcon(key);
    if (!this.mapIcon) {
      const ref = this.mapIconsService.getMapIconReference(key);
      this.mapIcon = this.mapIconsService.getMapIcon(ref.guiIcon);
    }

    this.calculation =
      this.viewModeManager.getMapType() === 'gis'
        ? this.viewModeManager.mapManager.calculateMetrics(this.selectedFeature)
        : null;
  }

  get hasBackButton(): boolean {
    return this.goToToolbox.observers.length !== 0;
  }

  backToToolbox() {
    this.goToToolbox.emit();
  }

  get FeatureProperty() {
    return FeatureProperty;
  }
}
