import { Drone } from 'src/app/autogenerated/model';
import { environment } from 'src/environments/environment';
import { DroneModel } from '../models/drone-model.enum';
import { StreamApplication } from '../models/stream-application.enum';

interface StreamApplicationConfiguration {
  baseUrl: string;
  playUrl: string;
}

export class DroneUtils {
  static getHttpsStreamUrl(drone: Drone | undefined): string {
    if (!drone?.rtmpStreamId) {
      return '';
    }

    return `${DroneUtils.getModelStreamServerUrl(drone).playUrl}${drone.rtmpStreamId}`;
  }

  static getRtmpStreamUrl(drone: Drone): string {
    return `${DroneUtils.getModelStreamServerUrl(drone).baseUrl.replace('https://', 'rtmp://')}${drone.rtmpStreamId}`;
  }

  private static getModelStreamServerUrl(drone: Drone): StreamApplicationConfiguration {
    const applicationStream = this.toStreamApplication(drone);
    return environment.streamApplications[applicationStream];
  }

  private static toStreamApplication(drone: Drone): StreamApplication {
    switch (drone.droneModel) {
      case DroneModel.DJI_Pilot_2:
        return StreamApplication.WebRTCApp;
      case DroneModel.DJI:
      case DroneModel.Parrot:
      case DroneModel.OBS_STUDIO:
      case DroneModel.Other:
      default:
        return StreamApplication.LiveApp;
    }
  }
}
