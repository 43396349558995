import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { appConfig } from 'src/app/app.config';
import { Intervention, MediaType, OrganizationMedia } from 'src/app/autogenerated/model';
import { coreConfig } from 'src/app/core/core.config';
import { debounce } from 'src/app/core/decorators';
import { MidButtonSize } from 'src/app/midgard-controls/enums/button-size.enum';
import { MidButtonType } from 'src/app/midgard-controls/enums/button-type.enum';
import { DisplayMode } from 'src/app/midgard-controls/enums/display-mode.enum';
import { MidRightPanelService } from 'src/app/midgard-controls/services/mid-right-panel.service';
import { MediaFilterComponent } from '../media-filter/media-filter.component';
import { IMediaFilter } from '../media-page/media-page.component';

@Component({
  selector: 'app-search-section',
  templateUrl: './search-section.component.html',
  styleUrls: ['./search-section.component.scss'],
})
export class SearchSectionComponent implements OnInit {
  @Input()
  mediaFilter: IMediaFilter;

  @Input()
  displayMode: DisplayMode = DisplayMode.default;

  @Input()
  intervention: Intervention;

  @Output()
  mediaFilterChange: EventEmitter<IMediaFilter>;

  @Output()
  switchSelectMode: EventEmitter<DisplayMode>;

  @Output()
  switchMultiselectMode: EventEmitter<void>;

  @Output()
  deleteSelectedMedias: EventEmitter<void>;

  @Output()
  downloadSelectedMedias: EventEmitter<void>;

  @Output()
  selectAllMedias: EventEmitter<void>;

  get MediaType() {
    return MediaType;
  }

  get DisplayMode() {
    return DisplayMode;
  }

  get MidButtonType() {
    return MidButtonType;
  }

  get MidButtonSize() {
    return MidButtonSize;
  }

  constructor(private readonly midRightPanelService: MidRightPanelService) {
    this.mediaFilter = {
      interventionId: null,
      searchRequest: '',
      type: null,
      more: null,
      limit: appConfig.media.pageSize,
      offset: 0,
    };
    this.mediaFilterChange = new EventEmitter<IMediaFilter>();
    this.switchSelectMode = new EventEmitter<DisplayMode>();
    this.switchMultiselectMode = new EventEmitter();
    this.deleteSelectedMedias = new EventEmitter();
    this.downloadSelectedMedias = new EventEmitter();
    this.selectAllMedias = new EventEmitter();
  }

  ngOnInit(): void {}

  @debounce(coreConfig.filter.textFilterDebounceTime)
  onSearchRequest() {
    this.mediaFilterChange.emit(this.mediaFilter);
  }

  onMediaTypeChange(type: MediaType | null) {
    this.mediaFilter.type = type;
    this.mediaFilterChange.emit(this.mediaFilter);
  }

  onOpenMediaFilter() {
    this.midRightPanelService
      .open(MediaFilterComponent, {
        mediaFilter: this.mediaFilter,
      })
      .subscribe(({ type, data }: { type: string; data: IMediaFilter }) => {
        // console.log('SearchSectionComponent.onOpenMediaFilter: MediaFilterComponent = ', {type, data});
        this.mediaFilter.more = data.more;
        this.mediaFilterChange.emit(this.mediaFilter);
      });
  }

  onSwitchMultiselectMode() {
    this.switchMultiselectMode.emit();
  }

  onDeleteSelectedMedias() {
    this.deleteSelectedMedias.emit();
  }

  onDownloadSelectedMedias() {
    this.downloadSelectedMedias.emit();
  }

  onSelectAllMedias() {
    this.selectAllMedias.emit();
  }

  switchDisplayMode() {
    this.displayMode = this.displayMode === DisplayMode.multiselect ? DisplayMode.default : DisplayMode.multiselect;
    this.switchSelectMode.emit(this.displayMode);
  }
}
