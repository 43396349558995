export const appConfig = {
  routes: {
    home: '/',
    drones: '/drones',
  },
  locale: {
    default: 'fr',
  },
  interceptorsExcludeUrls: ['https://api-adresse.data.gouv.fr', 'https://api.mapbox.com'],
  format: {
    dateTimeFormat: 'dd/MM/yy HH:mm',
    dateFormat: 'dd/MM/yy',
    longDateTimeFormat: 'EEEE dd MMM HH:mm',
  },
  infiniteScroll: {
    distance: 2,
    throttle: 1500,
    fromRoot: true,
  },
  media: {
    pageSize: 12,
  },
  allowedImageTypes: [
    'image/tiff',
    'image/pjp',
    'image/jfif',
    'image/bmp',
    'image/gif',
    // 'image/svg',
    'image/png',
    'image/xbm',
    'image/dib',
    'image/jxl',
    'image/jpeg',
    'image/svgz',
    'image/jpg',
    'image/webp',
    'image/ico',
    'image/tif',
    'image/pjpeg',
    'image/avif',
    'image/heic',
    'image/heif',
  ],
  allowedVideoTypes: [
    'video/ogm',
    'video/wmv',
    'video/mpg',
    'video/webm',
    'video/ogv',
    'video/mov',
    'video/mpeg',
    'video/mp4',
    'video/m4v',
    'video/avi',
  ],
};
