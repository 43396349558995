export class MapStateNestedMedia {
  id: string;
  activePin: boolean;
  activeProj: boolean;
  active3d: boolean;
  offset3d: number;

  constructor(init: Partial<MapStateNestedMedia> = {}) {
    this.id = init.id;
    this.activePin = init.activePin ?? false;
    this.activeProj = init.activeProj ?? false;
    this.active3d = init.active3d ?? false;
    this.offset3d = init.offset3d ?? 0;
  }
}
