import { Component, OnDestroy, OnInit } from '@angular/core';
import _ from 'lodash';
import { ActivityStreamItem, IHostedComponent } from '../../autogenerated/model';
import { Unsubscribe } from '../../core/decorators';
import { OrganizationStoreService } from '../../organization/store';
import { ActivityStreamEventService } from '../activity-stream-event.service';
import { ActivityStreamApiService } from '../../autogenerated/activityStreamApiService';
import { NewMediaComponent } from '../items/new-media/new-media.component';
import { DroneLiveComponent } from '../items/DroneLive/DroneLive.component';
import { SimpleTextComponent } from '../items/SimpleText/SimpleText.component';

@Unsubscribe()
@Component({
  selector: 'app-organization-activity-stream',
  templateUrl: './organization-activity-stream.component.html',
  styleUrls: ['./organization-activity-stream.component.scss'],
})
export class OrganizationActivityStreamComponent implements OnInit, OnDestroy {
  items: IHostedComponent[] = [];

  constructor(
    private organizationStoreService: OrganizationStoreService,
    private activityStreamEventService: ActivityStreamEventService,
    private activityStreamApiService: ActivityStreamApiService
  ) {}

  ngOnInit(): void {
    this.organizationStoreService.getCurrentOrganization().subscribe(async (organization) => {
      const items = await this.activityStreamApiService.getActivityStream(organization);
      this.items = await Promise.all(_.map(items, async (item) => await this.mapToHostedComponent(item)));
    });

    this.activityStreamEventService.created.subscribe(async (item) => {
      this.items.unshift(await this.mapToHostedComponent(item));
    });
  }

  async mapToHostedComponent(item: ActivityStreamItem): Promise<IHostedComponent> {
    if (item.__t === 'ActivityNewMedia') {
      return {
        componentType: NewMediaComponent,
        componentData: item,
      };
    }

    if (item.__t === 'ActivityDroneLaunch') {
      return {
        componentType: DroneLiveComponent,
        componentData: item,
      };
    }

    return {
      componentType: SimpleTextComponent,
      componentData: { text: 'hello there from ' + item._id },
    };
  }

  ngOnDestroy(): void {}
}
