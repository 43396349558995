import { Component, EventEmitter, Input, OnChanges, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { cloneDeep, isEmpty, isNull, isUndefined, map, some } from 'lodash';
import { FeatureModule, OrganizationDto, OrganizationGroupDto, UserDto, UserWithGroupDto } from 'src/api';
import { UserWithGroups } from 'src/app/autogenerated/model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: '[app-organization-user-item]',
  templateUrl: './organization-user-item.component.html',
  styleUrls: ['./organization-user-item.component.scss'],
})
export class OrganizationUserItemComponent implements OnChanges {
  @Input()
  user: UserDto;

  @Input()
  org: OrganizationDto;

  @Input()
  currentUser: UserDto;

  @Input()
  userGroupMap: Map<UserDto, OrganizationGroupDto[]>;

  @Input()
  isAdmin: boolean;

  @Input()
  hasGlobalEditMode: boolean;

  @Output()
  editMode: EventEmitter<void>;

  @Output()
  savedUser: EventEmitter<UserWithGroups>;

  @Input()
  features: FeatureModule[];

  @Input()
  availableGropes: OrganizationGroupDto[];

  @Output()
  delete: EventEmitter<UserDto>;

  editedUser: UserDto;
  isEditMode: boolean;
  previousSelectedGroups: OrganizationGroupDto[];
  originalGroups: OrganizationGroupDto[];
  selectedGroups: OrganizationGroupDto[];
  selectedGroupsNames: string;
  isGroupModeMultiple: boolean;

  constructor(private translate: TranslateService) {
    this.isEditMode = false;
    this.features = [];
    this.editedUser = null;
    this.originalGroups = [];
    this.selectedGroups = [];
    this.previousSelectedGroups = [];

    this.editMode = new EventEmitter();
    this.savedUser = new EventEmitter();
    this.delete = new EventEmitter();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const componentChanges = changes as PropertyMap<OrganizationUserItemComponent, SimpleChange>;
    if (componentChanges.userGroupMap.currentValue) {
      await this.initUserData();
    }
  }

  // current user need to be org admin and he can't set him self
  canAdmin(user: UserDto): boolean {
    if (!this.isAdmin) {
      return false;
    }
    return user?.id !== this.currentUser?.id;
  }

  onEdit(): void {
    this.getGroupMode();
    this.isEditMode = !this.isEditMode;
    if (!this.isEditMode) {
      this.selectedGroups = this.originalGroups;
    }
    this.editMode.emit();
  }

  onSave(editedUser: UserDto): void {
    this.isEditMode = false;
    if (isEmpty(this.selectedGroups)) {
      this.savedUser.emit(null);
      return;
    }

    let user: UserWithGroups = { user: editedUser, groups: this.selectedGroups };
    this.savedUser.emit(user);
  }

  onDelete(editedUser: UserDto): void {
    this.delete.emit(editedUser);
  }

  onGroupChanged(groups: OrganizationGroupDto[]) {
    this.selectedGroups = groups;
  }

  private async initUserData() {
    this.editedUser = cloneDeep(this.user);
    this.originalGroups = this.previousSelectedGroups = this.userGroupMap.get(this.user);
    if (!isUndefined(this.originalGroups) && !isNull(this.originalGroups)) {
      const groupNames = map(this.previousSelectedGroups, (group) => group.name);
      this.selectedGroupsNames = await this.translateGroupNames(groupNames);
      return;
    }
    this.previousSelectedGroups = this.originalGroups = [];
    this.selectedGroupsNames = '';
  }

  private getGroupMode() {
    const featuresIds = this.features.map((feature) => feature.featureId);
    this.isGroupModeMultiple = some(featuresIds, (featureId) => featureId === 'groups');
  }

  private async translateGroupNames(groupNames: string[]): Promise<string> {
    if (isEmpty(groupNames)) {
      return '';
    }
    const translateGroupNames = [];
    for (let name of groupNames) {
      const translateGroupName = await this.translate.get(name).toPromise();
      translateGroupNames.push(translateGroupName);
    }
    return translateGroupNames.join(',');
  }
}
