import { Component, Input } from '@angular/core';

@Component({
  selector: 'mid-component-modal',
  templateUrl: './mid-component-modal.component.html',
  styleUrls: ['./mid-component-modal.component.scss'],
})
export class MidComponentModalComponent {
  @Input()
  title: string;

  constructor() {}
}
