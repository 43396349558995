import moment from 'moment';

export class SharedMapState {
  _id: string;
  mapStateId: string;
  type: string;
  sharedBy: string;
  sharedDate: Date;
  validUntil: Date;

  static buildFromSharedMapStateResponse(dto) {
    const sharedMapState = new SharedMapState();

    sharedMapState._id = dto._id;
    sharedMapState.mapStateId = dto.mapStateId;
    sharedMapState.type = dto.type;
    sharedMapState.sharedBy = dto.sharedBy;
    sharedMapState.sharedDate = moment.utc(dto.sharedDate).local().toDate();
    sharedMapState.validUntil = moment.utc(dto.validUntil).local().toDate();

    return sharedMapState;
  }
}
