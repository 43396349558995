import { Intervention } from './../../autogenerated/model';
import { Component, OnInit } from '@angular/core';
import { Organization } from 'src/app/autogenerated/model';
import { TemplateService } from 'src/app/global/template.service';
import { InterventionTabContentComponent } from '../intervention-tab-container/intervention-tab-container.component';
import { ActivatedRoute } from '@angular/router';
import { MediaPageLocation } from '../../midgard-controls/enums/media-page-location.enum';

@Component({
  selector: 'app-intervention-tab-media',
  templateUrl: './intervention-tab-media.component.html',
  styleUrls: ['./intervention-tab-media.component.scss'],
})
export class InterventionTabMediaComponent implements InterventionTabContentComponent, OnInit {
  intervention: Intervention;
  interventionId: string;
  organization: Organization;
  hasAdminPermissions: boolean;

  get MediaPageLocation() {
    return MediaPageLocation;
  }

  constructor(private templateService: TemplateService, private readonly route: ActivatedRoute) {
    this.templateService.$activeComponent.next('INTERVENTION.TAB.MEDIA');
  }

  ngOnInit(): void {}
}
