<div class="group" *ngIf="group">
  <div class="group-info">
    <div class="group-name">
      {{ group.name | translate }}
    </div>

    <div class="remove-icon" (click)="resetFilter()">
      <i class="fas fa-times"></i>
    </div>
  </div>
</div>
