import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddInterventionComponent } from './add-intervention/add-intervention.component';
import { CoreModule } from '../core/core.module';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDividerModule } from '@angular/material/divider';
import { InterventionStoreModule } from './store';
import { InterventionsPageComponent } from './interventions-page/interventions-page.component';
import { InterventionTabContainerComponent } from './intervention-tab-container/intervention-tab-container.component';
import { InterventionPageComponent } from './intervention-page/intervention-page.component';
import { RouterModule } from '@angular/router';
import { InterventionTabMediaComponent } from './intervention-tab-media/intervention-tab-media.component';
import { InterventionTabHeaderComponent } from './intervention-tab-header/intervention-tab-header.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MediaModule } from '../media/media.module';
import { InterventionsDashboardComponent } from './interventions-dashboard/interventions-dashboard.component';
import { InterventionCardComponent } from './intervention-card/intervention-card.component';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '../shared/shared.module';
import { InterventionTabStreamingComponent } from './intervention-tab-streaming/intervention-tab-streaming.component';
import { DroneCommonModule } from '../drone/common/drone-common.module';
import { InterventionStreamingSectionComponent } from './intervention-streaming-section/intervention-streaming-section.component';
import { TdrawModule } from '../tdraw/tdraw.module';
import { InterventionTabMapComponent } from './intervention-tab-map/intervention-tab-map.component';
import { InterventionTabSettingsComponent } from './intervention-tab-settings/intervention-tab-settings.component';
import { InterventionPageDashboardModule } from './pages/intervention-page-dashboard/intervention-page-dashboard.module';
import { InterventionPageSettingsModule } from './pages/intervention-page-settings/intervention-page-settings.module';
import { CreateInterventionRightPanelModule } from './dialogs/create-intervention-right-panel/create-intervention-right-panel.module';
import { MidgardControlsModule } from '../midgard-controls/midgard-controls.module';
import { InterventionsDashboardBlockComponent } from './interventions-dashboard-block/interventions-dashboard-block.component';
import { MatMenuModule } from '@angular/material/menu';
import { AssociateWithDroneRightPanelModule } from './dialogs/associate-with-drone-right-panel/associate-with-drone-right-panel.module';
import { InterventionTabDashboardComponent } from './intervention-tab-dashboard/intervention-tab-dashboard.component';
import { InterventionTabAnalysesComponent } from './intervention-tab-analyses/intervention-tab-analyses.component';
import { InterventionPageAnalysesModule } from './pages/intervention-page-analyses/intervention-page-analyses.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  declarations: [
    AddInterventionComponent,
    InterventionsPageComponent,
    InterventionTabContainerComponent,
    InterventionTabHeaderComponent,
    InterventionTabDashboardComponent,
    InterventionTabStreamingComponent,
    InterventionTabMapComponent,
    InterventionTabMediaComponent,
    InterventionTabSettingsComponent,
    InterventionTabAnalysesComponent,
    InterventionPageComponent,
    InterventionsDashboardComponent,
    InterventionCardComponent,
    InterventionStreamingSectionComponent,
    InterventionsDashboardBlockComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    TdrawModule,
    MatDialogModule,
    MidgardControlsModule,
    FormsModule,
    RouterModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatMenuModule,
    MatAutocompleteModule,
    MatDividerModule,
    MatButtonToggleModule,
    InterventionStoreModule,
    InterventionPageDashboardModule,
    InterventionPageSettingsModule,
    InterventionPageAnalysesModule,
    MediaModule,
    MatIconModule,
    SharedModule,
    DroneCommonModule,
    CreateInterventionRightPanelModule,
    AssociateWithDroneRightPanelModule,
  ],
  exports: [
    AddInterventionComponent,
    InterventionsDashboardComponent,
    InterventionsDashboardBlockComponent,
    InterventionCardComponent,
    AssociateWithDroneRightPanelModule,
    InfiniteScrollModule,
  ],
})
export class InterventionModule {}
