import { TdrawService } from '../../../autogenerated/tdrawService';
import { Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import mapboxgl, { LngLatLike } from 'mapbox-gl';
import CoordinatesHelpers from '../../misc/coordinates-helpers';
import { OrganizationStoreService } from 'src/app/organization/store';
import _ from 'lodash';
import { tdrawConfig } from '../../tdraw.config';
import { MatDialog } from '@angular/material/dialog';
import { MapState } from '../../models/map-state.model';
import { MapStateGroup } from '../../models/map-state-group.model';
import moment from 'moment';
import { CreateGisVersionModalComponent } from '../../components/modals/create-gis-version-modal/create-gis-version-modal.component';
import { MapStateCreationDto, MapStateNestedBackgroundDto, MapStateUpdateDto } from '../../models/dto';
import { Unsubscribe } from 'src/app/core/decorators';
import { NewGisVersionData } from '../../models/new-gis-version-data.model';
import { ActivatedRoute, Router } from '@angular/router';
import { RightPanelData, RightPanelMode } from '../../models/right-panel-data';
import { interventionConfig } from 'src/app/intervention/intervention.config';
import { MapIconsService } from '../../services/map-icons.service';
import { GisManager } from '../../misc/gis-manager';
import { RenameVersionModalComponent } from '../../components/modals/rename-version-modal/rename-version-modal.component';
import { MapStateNestedBackgroundMediaDto } from '../../models/dto/map-state-nested-background-media.dto';
import { PicManager } from '../../misc/pic-manager';
import { DuplicateVersionModalComponent } from '../../components/modals/duplicate-version-modal/duplicate-version-modal.component';
import { CreatePicVersionModalComponent } from '../../components/modals/create-pic-version-modal/create-pic-version-modal.component';
import { NewPicVersionData } from '../../models/new-pic-version-data.model';
import { InfopointManager, InfopointData } from '../../misc/infopoint-manager';
import { ViewModeManager } from '../../misc/view-mode-manager';
import { OrganizationMedia } from 'src/app/autogenerated/model';
import { rightPanelDisplayState } from 'src/app/midgard-controls/enums/display-state.enum';
import { RightPanelType } from 'src/app/midgard-controls/enums/right-panel-type';

@Unsubscribe()
@Component({
  selector: 'app-tdraw-home',
  templateUrl: './tdraw-home.component.html',
  styleUrls: ['./tdraw-home.component.scss'],
})
export class TdrawHomeComponent implements OnInit, OnDestroy {
  // Input
  interventionId: string;
  organizationId: string;

  // Map
  private initialMapCenter: LngLatLike;
  map: mapboxgl.Map;
  currentInfo: InfopointData;
  gisManager: GisManager;
  picManager: PicManager;
  viewModeManager: ViewModeManager;

  // State
  mustShowLoad3dModelBtn = false;
  mapStateGroups: MapStateGroup[];
  selectedMapState: MapState;
  private mapStates: MapState[];
  rightPanelData: RightPanelData = { mode: RightPanelMode.Navigation };

  // Pinpoint image selection
  pinpointMedia: OrganizationMedia = null;
  pinpointMediaPanelDisplayState = rightPanelDisplayState.hidden;

  // Subscriptions
  private organizationSubscription: Subscription;
  private featureSelectedChangedSubscription: Subscription;
  private featureUnselectedSubscription: Subscription;
  private pinpointOpenedSubscription: Subscription;

  // Enums
  get RightPanelMode() {
    return RightPanelMode;
  }
  get PinpointMediaPanelType() {
    return RightPanelType;
  }

  constructor(
    activatedRoute: ActivatedRoute,
    private organizationStoreService: OrganizationStoreService,
    private tdrawService: TdrawService,
    private dialog: MatDialog,
    private router: Router,
    private mapIconsService: MapIconsService
  ) {
    this.interventionId = activatedRoute.snapshot.data.interventionId;
    this.initialMapCenter = tdrawConfig.defaultMapCenter;

    this.organizationSubscription = this.organizationStoreService
      .getCurrentOrganization()
      .subscribe(async (organization) => {
        if (!organization || this.organizationId === organization._id) {
          return;
        }
        this.organizationId = organization._id;

        const location = (<any>organization).location;
        if (location && location.lat && location.lng) {
          this.initialMapCenter = [location.lng, location.lat];
        }
      });
  }

  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.initMap();
  }

  private initMap() {
    // Be sure that all projections we use are loaded in proj4
    CoordinatesHelpers.registerProjections();

    this.map = new mapboxgl.Map({
      accessToken: tdrawConfig.mapAccessToken,
      container: 'map-home',
      style: tdrawConfig.mapStyle,
      center: this.initialMapCenter,
      zoom: 13,
    });
    this.map.addControl(new mapboxgl.ScaleControl());
    this.map.addControl(new mapboxgl.NavigationControl(), 'bottom-left');

    this.gisManager = new GisManager(this.map);
    this.picManager = new PicManager(this.map);
    this.viewModeManager = new ViewModeManager(this.map);

    // Initialize Infopoint to get informations on map click events
    new InfopointManager(this.map).init((info: InfopointData) => {
      this.currentInfo = info;
    });

    // Listeners
    this.map.on('load', () => this.onMapLoad());
    this.featureSelectedChangedSubscription = this.viewModeManager.onFeatureSelectedChanged((data) => {
      this.setRightPanelMode(RightPanelMode.Properties);
    });
    this.featureUnselectedSubscription = this.viewModeManager.onFeatureUnselected(() => {
      this.exitPropertiesPanel();
    });
    this.pinpointOpenedSubscription = this.gisManager.onPinpointOpened((image: OrganizationMedia) => {
      this.pinpointMedia = image;
      this.pinpointMediaPanelDisplayState = rightPanelDisplayState.displaying;
    });

    // Hide 204 Errors from TMS Tiles
    this.map.on('error', (e) => {
      if (e.source?.scheme === 'tms') {
        return;
      }
      console.error(e);
    });
  }

  private async onMapLoad() {
    await this.gisManager.load();
    this.mapIconsService.loadImages(this.map);

    // Wait for map prerequisites to be fully loaded before loading data
    this.map.once('idle', () => this.loadData());
  }

  private exitPropertiesPanel() {
    if (this.rightPanelData.mode !== RightPanelMode.Hidden) {
      this.rightPanelData.mode = RightPanelMode.Navigation;
    }
  }

  setRightPanelMode(mode: RightPanelMode) {
    this.rightPanelData.mode = mode;
  }

  private async loadData() {
    if (!this.organizationId || !this.interventionId) {
      return;
    }
    this.mapStates = await this.tdrawService.getMapStates(this.organizationId, this.interventionId);

    this.mapStateGroups = _.chain(this.mapStates)
      .groupBy((mapState) => {
        return moment(mapState.updatedAt).startOf('day').toDate();
      })
      .map((value, key) => new MapStateGroup({ date: moment(key).toDate(), mapStates: value }))
      .orderBy(['date'], ['desc'])
      .value();

    this.selectedMapState = _.first(this.mapStates);
    await this.loadMapStateDetailInfo();
  }

  onNewGisVersion() {
    const dialogRef = this.dialog.open(CreateGisVersionModalComponent, {
      id: 'createGisVersionModal',
      width: '500px',
    });
    dialogRef.afterClosed().subscribe(async (data: NewGisVersionData) => {
      if (!data) {
        return;
      }
      const background = new MapStateNestedBackgroundDto(null);
      background.center = data.center.toArray() as [number, number];
      background.zoom = data.zoom;
      const mapStateCreationDto = new MapStateCreationDto({ name: data.name, background, type: 'gis' });
      const mapState = (await this.tdrawService.createMap(
        this.organizationId,
        this.interventionId,
        mapStateCreationDto
      )) as MapState;
      this.goToGisEditModePage(mapState);
    });
  }

  onNewPicVersion() {
    const dialogRef = this.dialog.open(CreatePicVersionModalComponent, {
      id: 'createPicVersionModal',
      width: '500px',
      data: {
        interventionId: this.interventionId,
      },
    });
    dialogRef.afterClosed().subscribe(async (data: NewPicVersionData) => {
      if (!data) {
        return;
      }

      const backgroundMedia = new MapStateNestedBackgroundMediaDto({ mediaId: data.mediaId });
      const mapStateCreationDto = new MapStateCreationDto({ name: data.name, backgroundMedia, type: 'pic' });
      const mapState = (await this.tdrawService.createMap(
        this.organizationId,
        this.interventionId,
        mapStateCreationDto
      )) as MapState;
      this.goToPicEditModePage(mapState);
    });
  }

  private goToGisEditModePage(mapState: MapState) {
    this.router.navigate([
      `interventions/${this.interventionId}/${interventionConfig.routes.tdraw}/gis/${mapState._id}/edit`,
    ]);
  }

  private goToPicEditModePage(mapState: MapState) {
    this.router.navigate([
      `interventions/${this.interventionId}/${interventionConfig.routes.tdraw}/pic/${mapState._id}/edit`,
    ]);
  }

  async onMapStateDelete(item: MapState) {
    if (item._id === this.selectedMapState._id) {
      this.removeAnnotationsFromMap(this.selectedMapState);
    }
    await this.tdrawService.deleteMap(this.organizationId, item.interventionId, item._id);
    await this.loadData();
  }

  async onMapStateSelected(item: MapState) {
    this.removeAnnotationsFromMap(this.selectedMapState);
    this.selectedMapState = item;
    await this.loadMapStateDetailInfo();
  }

  async onMapStatePublish(item: MapState) {
    const snapshot = await this.viewModeManager.mapManager.takeSnapshot();
    await this.tdrawService.publishMap(this.organizationId, item.interventionId, item._id, snapshot);
    this.removeAnnotationsFromMap(this.selectedMapState);
    await this.loadData();
  }

  async onMapStateRename(item: MapState) {
    const dialogRef = this.dialog.open(RenameVersionModalComponent, { id: 'renameVersionModal', width: '450px' });
    dialogRef.componentInstance.name = item.name;
    dialogRef.afterClosed().subscribe(async (name: any) => {
      if (!name) {
        return;
      }

      const resp: any = await this.tdrawService.updateMap(this.organizationId, this.interventionId, item._id, {
        name,
      });
      item.name = resp.name;
    });
  }

  async onMapStateDuplicate(item: MapState) {
    const dialogRef = this.dialog.open(DuplicateVersionModalComponent, {
      id: 'duplicateHistoryModal',
      width: '450px',
      data: item.name + ' (copy)',
    });
    dialogRef.afterClosed().subscribe(async (name) => {
      if (!name) {
        return;
      }

      try {
        const mapState = await this.tdrawService.duplicateMap(this.organizationId, this.interventionId, item._id);
        const statistics = this.selectedMapState.statistics;
        await this.tdrawService.updateMap(
          this.organizationId,
          this.interventionId,
          mapState._id,
          new MapStateUpdateDto({ name, statistics })
        );

        if (mapState.type === 'gis') {
          this.goToGisEditModePage(mapState);
        } else {
          this.goToPicEditModePage(mapState);
        }
      } catch (e) {
        console.error(e);
      }
    });
  }

  async onMapStateDownload(item: MapState) {
    alert('Sorry, This function not implemented yet');
  }

  async onLoad3dModel() {
    this.removeAnnotationsFromMap(this.selectedMapState);
    await this.loadMapStateDetailInfo(true);
  }

  private async loadMapStateDetailInfo(with3dModels = false) {
    this.selectedMapState = await this.tdrawService.getMapState(
      this.organizationId,
      this.interventionId,
      this.selectedMapState._id
    );
    this.viewModeManager.setMapState(this.selectedMapState);

    // Check if MapState contains any 3D models
    this.mustShowLoad3dModelBtn = !with3dModels && this.selectedMapState.medias.some(media => media.active3d === true);

    if (this.selectedMapState.type === 'gis') {
      this.gisManager.loadDataOnMap(this.viewModeManager, true, true, with3dModels);
    } else {
      this.gisManager.toggleAllMapBackgroundLayers(false);
      this.picManager.loadDataOnMap(this.viewModeManager, true);
    }
  }

  private removeAnnotationsFromMap(mapState: MapState) {
    this.picManager.unloadBackgroundMedia();
    this.viewModeManager.mapManager.deleteAllAnnotationSources();
  }
}
