import { Directive, Input, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { MatErrorStateMatcherValidator } from './mat-error-state-matcher.validator';

@Directive({
  selector: 'input[appMatErrorStateMatcher]',
})
export class MatErrorStateMatcherDirective implements OnChanges {
  // tslint:disable-next-line:no-input-rename
  @Input('appMatErrorStateMatcher')
  condition: boolean;

  private validator: MatErrorStateMatcherValidator;

  constructor(private matInput: MatInput) {
    this.validator = new MatErrorStateMatcherValidator(false);
    this.matInput.errorStateMatcher = this.validator;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const componentChanges = changes as PropertyMap<MatErrorStateMatcherDirective, SimpleChange>;

    const conditionChange = componentChanges.condition;
    if (conditionChange && conditionChange.currentValue !== conditionChange.previousValue) {
      this.validator.hasError = conditionChange.currentValue;
    }
  }
}
