<app-mid-right-panel [closeCallback]="close()" [title]="'DIALOGS.SHARE_DRONE_LINK.SHARE_BROADCAST_LINK' | translate">
  <app-mid-right-panel-content>
    <div class="row m-0 px-4 pb-4">
      <ng-container *ngIf="!isBroadcastLinkGenerated && state.shareBy === ''">
        <div class="section col-md-12">
          <div class="instruction mb-3">
            {{ 'DIALOGS.SHARE_DRONE_LINK.INSTRUCTIONS' | translate }}
          </div>

          <div class="warning">
            <i class="fas fa-exclamation-triangle"></i>
            <div class="warning-text">
              {{ 'DIALOGS.SHARE_DRONE_LINK.WARNING' | translate }}
            </div>
          </div>
        </div>

        <div class="section function-buttons">
          <app-mid-button
            class="w-100 px-4"
            type="primary"
            size="wide"
            (action)="onGenerateNewShareLink()"
            [disabled]="state.isLoading"
          >
            {{ 'DIALOGS.SHARE_DRONE_LINK.ACTIVATE_SHARING' | translate }}
          </app-mid-button>
        </div>
      </ng-container>

      <ng-container *ngIf="isBroadcastLinkGenerated && state.shareBy === ''">
        <div class="container">
          <div class="section col-md-12">
            <div class="instruction mb-3">
              {{ 'DIALOGS.SHARE_DRONE_LINK.INSTRUCTIONS' | translate }}
            </div>
            <div class="info">
              <i class="fas fa-info"></i>
              <div class="text">
                {{ 'DIALOGS.SHARE_DRONE_LINK.INFORMATION' | translate }}
              </div>
            </div>
          </div>
          <div class="section function-buttons">
            <app-mid-button
              *ngIf="isAdmin"
              class="w-100 px-4"
              type="primary"
              size="wide"
              (action)="onDeleteLink()"
              [disabled]="state.isLoading"
            >
              {{ 'DIALOGS.SHARE_DRONE_LINK.DISABLE_BROADCAST_SHARING' | translate }}
            </app-mid-button>
            <div class="text-center">
              {{ 'DIALOGS.SHARE_DRONE_LINK.TIME_REMAINING' | translate }} {{ counter | formatTime }}
            </div>
            <br />
            <app-mid-button
              class="w-100 px-4"
              type="secondary"
              size="wide"
              (action)="onCopyBroadcastLink()"
              [disabled]="state.isLoading"
            >
              {{ 'DIALOGS.SHARE_DRONE_LINK.COPY_LINK' | translate }}
            </app-mid-button>
            <br />
            <app-mid-button class="w-100 px-4" type="secondary" size="wide" (action)="shareByMail()">
              {{ 'DIALOGS.SHARE_DRONE_LINK.SHARE_BY_MAIL' | translate }}
            </app-mid-button>
            <app-mid-button *ngIf="isAdmin" class="w-100 px-4" type="secondary" size="wide" (action)="shareBySMS()">
              {{ 'DIALOGS.SHARE_DRONE_LINK.SHARE_BY_SMS' | translate }}
            </app-mid-button>
            <app-mid-button *ngIf="isAdmin" class="w-100 px-4" type="secondary" size="wide" (action)="shareBySMSList()">
              {{ 'DIALOGS.SHARE_TDRAW_LINK.SHARE_BY_LIST_SMS' | translate }}
            </app-mid-button>
            <app-mid-button class="w-100 px-4" type="secondary" size="wide" (action)="qrCode()">
              QR code
            </app-mid-button>
            <!-- TODO: Add possibility to share broadcast link via Whatsapp -->
            <!--<app-mid-button
              class="w-100 px-4"
              type="secondary"
              size="wide"
            >
              {{ 'DIALOGS.SHARE_DRONE_LINK.SHARE_BY_WHATSAPP' | translate }}
            </app-mid-button>-->
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="state.shareBy === 'sms' && isAdmin">
        <div class="section col-md-12">
          <form #formShareBySMS="ngForm">
            <div class="wrapper">
              <app-mid-input-phone-number
                size="wide"
                [label]="'DIALOGS.SHARE_DRONE_LINK.PHONE_NUMBER' | translate"
                [required]="true"
                #phone="appMidPhoneNumberInput"
                name="phoneNumber"
                [(value)]="data.phoneNumber"
              >
                <span class="text-danger" *ngIf="!formShareBySMS.pristine && phone.invalid">
                  {{ 'DIALOGS.SHARE_DRONE_LINK.PHONE_VALIDATOR_LABEL' | translate }}</span
                >
              </app-mid-input-phone-number>
              <section class="section function-buttons">
                <app-mid-button
                  class="w-100 px-1"
                  type="primary"
                  size="wide"
                  (action)="sendSMS()"
                  [disabled]="formShareBySMS.form?.invalid"
                >
                  {{ 'DIALOGS.SHARE_DRONE_LINK.SHARE_BROADCAST_LINK' | translate }}
                </app-mid-button>
                <app-mid-button class="w-100 px-1" type="secondary" size="wide" (action)="cancel()">
                  {{ 'DIALOGS.SHARE_DRONE_LINK.SHARE_BY_BACK' | translate }}
                </app-mid-button>
              </section>
            </div>
          </form>
        </div>
      </ng-container>
      <ng-container *ngIf="state.shareBy === 'mail' && isAdmin">
        <div class="section col-md-12">
          <form #formShareByMail="ngForm">
            <div class="wrapper">
              <app-mid-input
                type="email"
                size="wide"
                #mail="appMidInput"
                name="mail"
                [(value)]="data.mail"
                [required]="true"
                [label]="'DIALOGS.SHARE_DRONE_LINK.EMAIL' | translate"
              >
              </app-mid-input>
              <span class="text-danger" *ngIf="!formShareByMail.pristine && formShareByMail.invalid">
                {{ 'DIALOGS.SHARE_DRONE_LINK.EMAIL_VALIDATOR_LABEL' | translate }}</span
              >
              <section class="section function-buttons">
                <app-mid-button
                  class="w-100 px-1"
                  type="primary"
                  size="wide"
                  (action)="sendMail()"
                  [disabled]="formShareByMail.form?.invalid"
                >
                  {{ 'DIALOGS.SHARE_DRONE_LINK.SHARE_BROADCAST_LINK' | translate }}
                </app-mid-button>
                <app-mid-button class="w-100 px-1" type="secondary" size="wide" (action)="cancel()">
                  {{ 'DIALOGS.SHARE_DRONE_LINK.SHARE_BY_BACK' | translate }}
                </app-mid-button>
              </section>
            </div>
          </form>
        </div>
      </ng-container>
      <ng-container *ngIf="state.shareBy === 'smsList' && isAdmin">
        <div class="section col-md-12">
          <form #formShareByListSMS="ngForm">
            <div class="wrapper">
              <app-mid-input
                size="wide"
                #listInput="appMidInput"
                name="listInput"
                [matAutocomplete]="auto"
                [label]="'DISTRIBUTION_LIST' | translate"
                [placeholder]="'NAME' | translate"
              >
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectDistributionList($event)">
                  <mat-option *ngFor="let list of distributionLists" [value]="list">
                    {{ list.name }}
                  </mat-option>
                </mat-autocomplete>
              </app-mid-input>

              <section class="section function-buttons">
                <app-mid-button
                  class="w-100 px-1"
                  type="primary"
                  size="wide"
                  (action)="sendSMSList()"
                  [disabled]="!selectedDistributionList"
                >
                  {{ 'DIALOGS.SHARE_DRONE_LINK.SHARE_BROADCAST_LINK' | translate }}
                </app-mid-button>
                <app-mid-button class="w-100 px-1" type="secondary" size="wide" (action)="cancel()">
                  {{ 'DIALOGS.SHARE_DRONE_LINK.SHARE_BY_BACK' | translate }}
                </app-mid-button>
              </section>
            </div>
          </form>
        </div>
      </ng-container>
      <ng-container *ngIf="state.shareBy === 'qrCode' && isAdmin">
        <div class="section col-md-12">
          <form #formShareBySMS="ngForm">
            <div class="wrapper">
              <div class="container text-center">
                <qrcode [qrdata]="this.broadcastLink" [width]="240" [errorCorrectionLevel]="'M'"></qrcode>
              </div>
              <section class="section function-buttons">
                <app-mid-button class="w-100 px-1" type="secondary" size="wide" (action)="cancel()">
                  {{ 'DIALOGS.SHARE_DRONE_LINK.SHARE_BY_BACK' | translate }}
                </app-mid-button>
              </section>
            </div>
          </form>
        </div>
      </ng-container>
    </div>
  </app-mid-right-panel-content>
</app-mid-right-panel>
