import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MediaDashboardBlockComponent } from './media-dashboard-block/media-dashboard-block.component';
import { CoreModule } from 'src/app/core/core.module';
import { MidgardControlsModule } from 'src/app/midgard-controls/midgard-controls.module';
import { MediaListComponent } from './media-list/media-list.component';
import { MediaCardComponent } from './media-card/media-card.component';
import { MatMenuModule } from '@angular/material/menu';
import { MediaUploadDialogComponent } from './media-upload/media-upload-dialog/media-upload-dialog.component';
import { ProgressComponent } from './media-upload/progress/progress.component';
import { DndDirective } from './media-upload/dnd.directive';
import { MediaUploadProgressComponent } from './media-upload/media-upload-progress/media-upload-progress.component';
import { MediaNotificationProgressComponent } from './media-upload/media-notification-progress/media-notification-progress.component';
import { MediaUploadComponent } from './media-upload/media-upload.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { InterventionCommonModule } from '../../intervention/common/intervention-common.module';
import { MediaInterventionsDialogComponent } from './media-interventions-dialog/media-interventions-dialog.component';
import { MediaRightPanelComponent } from './media-right-panel/media-right-panel.component';
import { FormsModule } from '@angular/forms';
import { MediaTagsComponent } from './media-tags/media-tags.component';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    MediaDashboardBlockComponent,
    MediaListComponent,
    MediaCardComponent,
    MediaUploadDialogComponent,
    ProgressComponent,
    DndDirective,
    MediaUploadProgressComponent,
    MediaNotificationProgressComponent,
    MediaUploadComponent,
    MediaInterventionsDialogComponent,
    MediaRightPanelComponent,
    MediaTagsComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    MidgardControlsModule,
    MatMenuModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    InterventionCommonModule,
    FormsModule,
    MatInputModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatIconModule,
  ],
  exports: [
    MediaDashboardBlockComponent,
    MediaListComponent,
    MediaCardComponent,
    MediaUploadProgressComponent,
    MediaNotificationProgressComponent,
    MediaUploadComponent,
    MediaRightPanelComponent,
    MediaTagsComponent,
  ],
})
export class MediaCommonModule {}
