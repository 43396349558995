import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'tdraw-update-statistics-modal',
  templateUrl: './update-statistics-modal.component.html',
  styleUrls: ['./update-statistics-modal.component.scss'],
})
export class UpdateStatisticsModalComponent {
  constructor(private dialogRef: MatDialogRef<UpdateStatisticsModalComponent>) {}

  onSubmit() {
    this.dialogRef.close(true);
  }

  onClose() {
    this.dialogRef.close(false);
  }
}
