<div
  class="alert-box"
  [class.active]="selectedAlert && alert?.date === selectedAlert?.date"
  *ngIf="alert"
  (click)="onAlertSelected(alert)"
>
  <div class="date">{{ alert.date | date : 'dd/MM/yyyy - HH:mm:ss z' : 'GTM' }}</div>
  <div class="status" [ngSwitch]="alert?.statusAlert">
    <span *ngSwitchCase="'Detected'">{{ 'ALERTS.FIRE_DETECTED' | translate }}</span>
    <span *ngSwitchCase="'FalsePositive'" class="status-false">{{ 'ALERTS.FIRE_NO_CONFIRMED' | translate }}</span>
    <span *ngSwitchCase="'Validated'" class="status-validated">{{ 'ALERTS.FIRE_CONFIRMED' | translate }}</span>
  </div>
</div>
