import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { DroneService } from 'src/app/autogenerated/droneService';
import { Drone, UpdateDroneInput } from 'src/app/autogenerated/model';
import { ClipboardService } from 'src/app/core/utils/clipboard.service';
import { DroneModel } from 'src/app/drone/models/drone-model.enum';
import { droneModels } from 'src/app/drone/models/drone-models.constant';
import { DroneStoreService } from 'src/app/drone/store';
import { MidSelectItem } from 'src/app/midgard-controls/models';
import { MID_RIGHT_PANEL_DATA } from 'src/app/midgard-controls/services/dom.service';
import { MidRightPanelService } from 'src/app/midgard-controls/services/mid-right-panel.service';
import { MidSelectItemUtils } from 'src/app/midgard-controls/utils/mid-select-item-utils';
import { DroneUtils } from '../../utils/drone-utils';

export interface DronePropertiesInput {
  drone: Drone;
}

@Component({
  selector: 'app-drone-properties',
  templateUrl: './drone-properties.component.html',
  styleUrls: ['./drone-properties.component.scss'],
})
export class DronePropertiesComponent implements OnInit {
  drone: Drone;

  errorMessage: string;
  droneModels: MidSelectItem[];
  currentDrone: Drone;

  serverLink: string;
  fullRtmpLink: string;

  state: {
    isLoading: boolean;
    isFailed: boolean;
  };

  readonly DroneModel = DroneModel;

  constructor(
    private readonly translate: TranslateService,
    private readonly droneService: DroneService,
    private readonly droneStoreService: DroneStoreService,
    private readonly clipboardService: ClipboardService,
    private readonly midRightPanelService: MidRightPanelService,
    @Inject(MID_RIGHT_PANEL_DATA) inputtingData: DronePropertiesInput
  ) {
    this.drone = inputtingData.drone;
    this.droneModels = MidSelectItemUtils.fromMap(droneModels);
    this.errorMessage = '';
    this.state = {
      isLoading: false,
      isFailed: false,
    };
  }

  async ngOnInit(): Promise<void> {
    this.currentDrone = cloneDeep(this.drone);
    const indexOther = this.droneModels.findIndex((it) => it.value === DroneModel.Other);
    this.droneModels[indexOther].name = await this.translate.get('DIALOGS.CREATE_DRONE.MODEL_OTHER').toPromise();
    this.updateRtmpLink();
  }

  updateRtmpLink(): void {
    this.fullRtmpLink = DroneUtils.getRtmpStreamUrl(this.currentDrone);
    this.serverLink = this.fullRtmpLink.replace(this.currentDrone.rtmpStreamId, '');
  }

  async onCopyLink(): Promise<void> {
    await this.copyToClipboard(this.fullRtmpLink);
  }

  close(): void {
    this.midRightPanelService.close();
  }

  async onUpdate(): Promise<void> {
    this.state.isLoading = true;
    this.state.isFailed = false;

    try {
      const droneInput = new UpdateDroneInput({
        id: this.currentDrone._id,
        name: this.currentDrone.name,
        droneModel: this.currentDrone.droneModel,
        currentIntervention: this.currentDrone.currentIntervention,
      });
      const drone: Drone = await this.droneService.updateDrone(droneInput);
      this.droneStoreService.updateDrone(this.currentDrone.organization.toString(), drone);
      this.close();
    } catch (ex) {
      this.errorMessage = ex.message;
      this.state.isFailed = true;
      console.log(this.errorMessage);
    } finally {
      this.state.isLoading = false;
    }
  }

  private async copyToClipboard(value: string): Promise<void> {
    // TODO show notification
    await this.clipboardService.copyToClipboard(value);
  }
}
