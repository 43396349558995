<div class="map-panel" [class.d-none]="data.mode === RightPanelMode.Hidden">
  <ng-content></ng-content>
</div>

<div class="right-toggle-button" [class.right-sticked-btn]="data.mode === RightPanelMode.Hidden">
  <button (click)="toggle()">
    <i
      class="fas"
      [class.fa-chevron-right]="data.mode !== RightPanelMode.Hidden"
      [class.fa-chevron-left]="data.mode === RightPanelMode.Hidden"
    ></i>
  </button>
</div>
