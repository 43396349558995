import { environment } from 'src/environments/environment';
import mapboxgl, { LngLatLike, Marker, Popup } from 'mapbox-gl';
import { BackgroundViewType } from '../models/dto/enums/background-view-type.enum';
import { BackgroundView3dType } from '../models/dto/enums/background-view-3d-type.enum';
import { BackgroundInfoType } from '../models/dto/enums/background-info-type.enum';
import { MapStateNestedBackgroundDto } from '../models/dto';
import { OrganizationMedia } from 'src/app/autogenerated/model';
import { MediaService } from 'src/app/autogenerated/mediaService';
import { PinpointImageModalComponent } from '../components/modals/pinpoint-image-modal/pinpoint-image-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ServiceLocator } from 'src/app/global/locator.service';
import { AuthenticationStoreService } from 'src/app/authentication/store';
import { Subject, Subscription } from 'rxjs';
import { MapStateNestedMedia } from '../models/map-state-nested-media';
import _ from 'lodash';
import { DeviceType, Flight } from '../models/flight.model';
import { DroneTelemetryService } from 'src/app/autogenerated/droneTelemetryService';
import { BasemapService } from '../services/basemap.service';
import { tdrawConfig } from '../tdraw.config';
import { MapModeManager } from './map-mode-manager';
import { MapStateFlightsDto } from '../models/dto/map-state-flights.dto';
import { Model3dLayer } from './model3d-layer';

// CSS Class used by pinpoints
const PinpointImageClass = 'pinpoint-image';

// Tiles layers will be displayed beneath the specified layer
const OffsetLayerTiles = 'elevation-lines';

// Class to keep track of Flight data and states
class FlightState {
  isPathActive: boolean = false;
  isIconActive: boolean = false;
  marker?: Marker = null;
  intervalId: any = null;
  flight?: Flight = null;
  lastTime: number = 0;
  fulldata: [number, number][] = [];
}

/**
 * GisManager handles most logic of GIS Maps
 *
 * Examples of responsabilities:
 * - MapState.Background (including the changes of Basemap layers)
 * - Media Data (Tiles, Projection (Pinpoints), 3D Models)
 * - Geo Data (Trajectories)
 *
 * Basemap layers handling require to call "load(map)" method first
 * in order to load the required Sources and Layers to the Map
 */
export class GisManager {
  isLoaded = false;

  // Active data
  mediaStates: MapStateNestedMedia[] = [];
  loading3dModelsInfo = {
    percent: 100,
    individualPercents: {},
  };

  // Keep track of Flight data and states
  flightStates: { [flightId: string]: FlightState } = {};

  // Keep track of Map markers
  private activePinpointsMarkers: Map<string, Marker> = new Map();

  private mapBackground: Partial<MapStateNestedBackgroundDto> = {
    view: BackgroundViewType.Streets,
    view3d: [],
    info: [],
  };

  public readonly TileSourcePrefix = 'tiles-';
  public readonly Model3dLayerPrefix = 'model3d-';
  public readonly FlightLayerPrefix = 'flight-';

  private mediaService: MediaService;
  private dialog: MatDialog;
  private droneTelemetryService: DroneTelemetryService;
  private basemapService: BasemapService;

  private authToken: string;

  // Event emitters
  private subjectMedias: Subject<MapStateNestedMedia[]>;
  private subjectMapBackground: Subject<MapStateNestedBackgroundDto>;
  private subjectPinpointOpened: Subject<OrganizationMedia>;

  constructor(private map: mapboxgl.Map) {
    this.mediaService = ServiceLocator.injector.get(MediaService);
    this.dialog = ServiceLocator.injector.get(MatDialog);
    this.droneTelemetryService = ServiceLocator.injector.get(DroneTelemetryService);
    this.basemapService = ServiceLocator.injector.get(BasemapService);

    const authenticationStoreService = ServiceLocator.injector.get(AuthenticationStoreService);
    authenticationStoreService.getAccessToken().subscribe((token: string) => {
      this.authToken = token;
    });

    this.initPinpointImageClickListener();

    this.subjectMedias = new Subject();
    this.subjectMapBackground = new Subject();
    this.subjectPinpointOpened = new Subject();
  }

  async load() {
    // Load Map styles (sources and layers) in the Map
    await this.basemapService.load(this.map);
    this.isLoaded = true;
  }

  loadDataOnMap(mapModeManager: MapModeManager, withPinpoints = true, withTiles = true, with3dModels = true) {
    const mapState = mapModeManager.getMapState();
    mapModeManager.displayMapStateDataOnMap();
    this.loadMedias(mapState.medias, withPinpoints, withTiles, with3dModels);
    this.loadMapBackground(mapState.background);
    this.loadFlights(mapState.flights);
  }

  onMediasChanged(callback: (data: MapStateNestedMedia[]) => void) {
    this.subjectMedias.subscribe(callback);
  }

  private notifyMediasChanged() {
    this.subjectMedias.next(this.mediaStates);
  }

  onMapBackgroundChanged(callback: (data: MapStateNestedBackgroundDto) => void) {
    this.subjectMapBackground.subscribe(callback);
  }

  private notifyMapBackgroundChanged() {
    this.subjectMapBackground.next(this.getMapBackground());
  }

  onPinpointOpened(callback: (image: OrganizationMedia) => void): Subscription {
    return this.subjectPinpointOpened.subscribe(callback);
  }

  private notifyPinpointOpened(image: OrganizationMedia) {
    this.subjectPinpointOpened.next(image);
  }

  private updateInternalMapBackgroundLayerState(
    category: 'view3d' | 'info',
    value: BackgroundInfoType | BackgroundView3dType,
    isEnabled: boolean
  ) {
    const mapStateInfo: any[] = this.mapBackground[category];
    if (isEnabled) {
      if (!mapStateInfo.includes(value)) {
        mapStateInfo.push(value);
      }
    } else {
      this.mapBackground[category] = mapStateInfo.filter((e) => e !== value);
    }
    this.notifyMapBackgroundChanged();
  }

  changeMapBackgroundView(mapView: BackgroundViewType): void {
    this.basemapService.changeStyle(this.map, mapView);
    this.mapBackground.view = mapView;
  }

  getMapBackground(): MapStateNestedBackgroundDto {
    return new MapStateNestedBackgroundDto({
      ...this.mapBackground,
      center: this.map.getCenter().toArray() as [number, number],
      zoom: this.map.getZoom(),
    });
  }

  toggleMapBackgroundLayer(type: BackgroundInfoType | BackgroundView3dType, isEnabled: boolean) {
    switch (type) {
      case BackgroundInfoType.Dfci:
        this.basemapService.toggleDFCI(this.map, isEnabled);
        this.updateInternalMapBackgroundLayerState('info', BackgroundInfoType.Dfci, isEnabled);
        return;
      case BackgroundInfoType.LevelCurves:
        this.basemapService.toggleLevelCurves(this.map, isEnabled);
        this.updateInternalMapBackgroundLayerState('info', BackgroundInfoType.LevelCurves, isEnabled);
        return;
      case BackgroundView3dType.Building:
        this.basemapService.toggle3DBuildings(this.map, isEnabled);
        this.updateInternalMapBackgroundLayerState('view3d', BackgroundView3dType.Building, isEnabled);
        return;
      case BackgroundView3dType.Terrain:
        this.basemapService.toggle3DTerrain(this.map, isEnabled);
        this.updateInternalMapBackgroundLayerState('view3d', BackgroundView3dType.Terrain, isEnabled);
    }
  }

  toggleAllMapBackgroundLayers(isEnabled: boolean) {
    // 3D specials
    const elems3dView = Object.values(BackgroundView3dType);
    for (let value of elems3dView) {
      this.toggleMapBackgroundLayer(value, isEnabled);
    }

    // Others
    const elemsInfo = Object.values(BackgroundInfoType);
    for (let value of elemsInfo) {
      this.toggleMapBackgroundLayer(value, isEnabled);
    }
  }

  loadMapBackground(data: MapStateNestedBackgroundDto) {
    // Center and Zoom
    this.map.setCenter(data.center as [number, number]);
    this.map.setZoom(data.zoom);

    // Map Style
    this.changeMapBackgroundView(data.view);

    // 3D specials
    const elems3dView = Object.values(BackgroundView3dType);
    for (let value of elems3dView) {
      this.toggleMapBackgroundLayer(value, data.view3d.includes(value));
    }

    // Others
    const elemsInfo = Object.values(BackgroundInfoType);
    for (let value of elemsInfo) {
      this.toggleMapBackgroundLayer(value, data.info.includes(value));
    }
  }

  loadFlights(states: MapStateFlightsDto) {
    this.hideAllFlights();

    for (const flightId in states) {
      const state = states[flightId];

      if (state.isPathActive) {
        this.toggleRealTimeFlightPath(flightId);
      }

      if (state.isIconActive) {
        this.toggleRealTimeFlightIcon(flightId, false);
      }
    }
  }

  loadMedias(medias: MapStateNestedMedia[], withPinpoints = true, withTiles = true, with3dModels = true) {
    if (!withPinpoints && !withTiles && !with3dModels) {
      return;
    }

    this.removeAllPinpoints();
    this.removeAllTiles();
    this.removeAll3dModels();

    const getMedias: Promise<any>[] = [];
    for (const media of medias) {
      getMedias.push(
        this.mediaService.getMedia({ _id: media.id }).then((orgMedia) => {
          const mediaState = this.createEmptyMediaState(media.id);
          if (withPinpoints && media.activePin) {
            this.displayPinpoint(orgMedia);
          }
          if (withTiles && media.activeProj) {
            this.displayTiles(orgMedia);
          }
          if (with3dModels && media.active3d) {
            mediaState.offset3d = media.offset3d;
            this.display3dModel(orgMedia);
          }
        })
      );
    }

    Promise.all(getMedias).then(() => {
      this.notifyMediasChanged();
    });
  }

  synchronizeMediaFeaturesAndStates(mediaIds: string[]) {
    // Disable features and remove states of medias which aren't there anymore
    for (const media of this.mediaStates) {
      if (!mediaIds.includes(media.id)) {
        this.removeMediaFeaturesAndStates(media.id);
      }
    }

    // Create new state for each new media which was not there before
    const currentMediaIds = this.mediaStates.map((e) => e.id);
    for (const mediaId of mediaIds) {
      if (!currentMediaIds.includes(mediaId)) {
        this.createEmptyMediaState(mediaId);
      }
    }

    this.notifyMediasChanged();
  }

  private createEmptyMediaState(mediaId: string): MapStateNestedMedia {
    const mediaState = new MapStateNestedMedia({
      id: mediaId,
    });
    this.mediaStates.push(mediaState);
    return mediaState;
  }

  private removeMediaFeaturesAndStates(mediaId: string) {
    const mediaState = this.getMediaState(mediaId);
    if (mediaState.activePin) {
      this.removePinpoint(mediaId);
    }
    if (mediaState.activeProj) {
      this.removeTiles(mediaId);
    }
    if (mediaState.active3d) {
      this.remove3dModel(mediaId);
    }
    this.mediaStates = this.mediaStates.filter((e) => e.id !== mediaId);
  }

  getMediaState(mediaId: string) {
    return this.mediaStates.find((e) => e.id === mediaId);
  }

  toggle3dModel(image: OrganizationMedia) {
    const imageId = image._id;
    const enabled = this.getMediaState(imageId).active3d;
    if (enabled) {
      this.remove3dModel(imageId);
    } else {
      this.display3dModel(image);
    }
    this.notifyMediasChanged();
  }

  private display3dModel(image: OrganizationMedia) {
    const imageId = image._id;
    const model3dLayer = new Model3dLayer(this, image);
    this.map.addLayer(model3dLayer);
    this.getMediaState(imageId).active3d = true;
  }

  private remove3dModel(imageId: string) {
    const id = this.Model3dLayerPrefix + imageId;
    if (this.map.getLayer(id)) {
      this.map.removeLayer(id);
    }
    this.getMediaState(imageId).active3d = false;
  }

  private removeAll3dModels() {
    for (const media of this.mediaStates) {
      if (media.active3d) {
        this.map.removeLayer(this.Model3dLayerPrefix + media.id);
        media.active3d = false;
      }
    }
  }

  set3dModelOffset(mediaId: string, offset: number) {
    this.getMediaState(mediaId).offset3d = offset;
    this.notifyMediasChanged();
  }

  toggleTiles(image: OrganizationMedia, forcedState: boolean = null) {
    const enabled = this.getMediaState(image._id).activeProj;
    if (forcedState !== true && enabled) {
      this.removeTiles(image._id);
    }
    if (forcedState !== false && !enabled) {
      this.displayTiles(image);
    }
    this.notifyMediasChanged();
  }

  private displayTiles(image: OrganizationMedia) {
    const imageId = image._id;
    const bounds = image.bounds;

    const id = this.TileSourcePrefix + imageId;
    this.map.addSource(id, {
      type: 'raster',
      tiles: [environment.backend + 'tiles/' + imageId + '/{z}/{x}/{y}.png'],
      tileSize: 256,
      scheme: 'tms',
      ...(bounds?.length ? { bounds } : {}),
    });

    this.map.addLayer(
      {
        id: id,
        source: id,
        type: 'raster',
      },
      OffsetLayerTiles
    );

    this.getMediaState(imageId).activeProj = true;
  }

  private removeTiles(imageId: string) {
    const id = this.TileSourcePrefix + imageId;
    if (this.map.getSource(id)) {
      this.map.removeLayer(id);
      this.map.removeSource(id);
    }
    this.getMediaState(imageId).activeProj = false;
  }

  private removeAllTiles() {
    const sources = this.map.getStyle().sources;
    const sourceIds = Object.keys(sources);
    for (let sid of sourceIds) {
      if (sid.startsWith(this.TileSourcePrefix)) {
        this.map.removeLayer(sid);
        this.map.removeSource(sid);
      }
    }
    for (const media of this.mediaStates) {
      media.activeProj = false;
    }
  }

  togglePinpoint(image: OrganizationMedia, forcedState: boolean = null, centerOnMarker: boolean = true) {
    const enabled = this.getMediaState(image._id).activePin;
    if (forcedState !== true && enabled) {
      this.removePinpoint(image._id);
    }
    if (forcedState !== false && !enabled) {
      this.displayPinpoint(image, centerOnMarker);
    }
    this.notifyMediasChanged();
  }

  private displayPinpoint(image: OrganizationMedia, centerOnMarker: boolean = false): void {
    if (!image.gps) {
      return;
    }
    const imageId = image._id;

    // Create marker html element
    const el = document.createElement('div');
    el.className = 'map-pinpoint-marker';

    // Get image information
    const coordinates: [number, number] = [image.gps.longitude, image.gps.latitude];
    const imageUrl = this.mediaService.getDownloadUrl(image) + `&token=${this.authToken}`;
    const imagePreviewUrl = this.mediaService.getDownloadUrl(image, true) + `&token=${this.authToken}`;

    // Center on marker
    if (centerOnMarker) {
      this.map.setCenter(coordinates);
      if (this.map.getZoom() < 13) {
        this.map.setZoom(13);
      }
    }

    // Display marker
    const marker = new Marker(el)
      .setLngLat(coordinates)
      .setPopup(
        new Popup({
          closeButton: false,
          offset: 12,
        }).setHTML(`<img src='${imagePreviewUrl}' data-src='${imageUrl}' class='${PinpointImageClass}'/>`)
      )
      .addTo(this.map);

    // Allow to open image properties on click
    marker.getElement().addEventListener(
      'click',
      (e) => {
        marker.togglePopup();
        e.stopPropagation();
        if (marker.getPopup().isOpen()) {
          this.notifyPinpointOpened(image);
        }
      },
      false
    );

    // Save state
    this.getMediaState(imageId).activePin = true;
    this.activePinpointsMarkers[imageId] = marker;
  }

  private removePinpoint(imageId: string) {
    const marker = this.activePinpointsMarkers[imageId];
    marker.remove();
    this.getMediaState(imageId).activePin = false;
    delete this.activePinpointsMarkers[imageId];
  }

  private removeAllPinpoints() {
    for (const imageId in this.activePinpointsMarkers) {
      const marker = this.activePinpointsMarkers[imageId];
      marker.remove();
    }
    for (const media of this.mediaStates) {
      media.activePin = false;
    }
    this.activePinpointsMarkers = new Map();
  }

  /**
   * If necessary :
   * - create an empty Marker (for Icon)
   * - create an empty Source + Layer (for Path)
   * - create a FlightState to store complete flight data and states
   * - start an Interval to refresh FlightState data
   */
  private prepareFlightDataStore(flightId: string) {
    const sourceId = this.FlightLayerPrefix + flightId;

    // Be sure to always have a Source and a Layer for this flight (Path)
    if (!this.map.getSource(sourceId)) {
      this.map.addSource(sourceId, {
        type: 'geojson',
        lineMetrics: true,
        data: {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'LineString',
                coordinates: [],
              },
            },
          ],
        } as any,
      });
      this.map.addLayer({
        id: sourceId + '-polyline',
        type: 'line',
        source: sourceId,
        filter: ['all'],
        paint: {
          'line-opacity': 1,
          'line-width': 2,
          'line-gradient': ['interpolate', ['linear'], ['line-progress'], 0, 'yellow', 1, 'red'],
        },
        layout: {
          visibility: 'none',
        },
      });
    }

    // Be sure we have created a data store for this flight
    if (!this.flightStates[flightId]) {
      this.flightStates[flightId] = new FlightState();
    }

    // Be sure to always have a Marker for this flight (Icon)
    if (!this.flightStates[flightId].marker) {
      // Create marker html element
      const el = document.createElement('div');
      // el.className = flight.deviceType === DeviceType.Robot ? 'map-robot-marker' : 'map-drone-marker';
      this.flightStates[flightId].marker = new Marker(el).setLngLat([null, null]).addTo(this.map);
    }

    // Be sure there is an interval running for the flight
    this.initRealTimeFlightInterval(flightId);
  }

  /**
   * Start an Interval to refresh FlightState every X seconds
   */
  private initRealTimeFlightInterval(flightId: string) {
    const state = this.flightStates[flightId];

    // If there is already an interval for this flight, stop here
    if (state.intervalId) {
      return;
    }

    // Gather new data every X seconds
    state.intervalId = setInterval(async () => {
      // If nothing is active, clear the interval
      if (!state.isPathActive && !state.isIconActive) {
        clearInterval(state.intervalId);
        state.intervalId = null;
      }

      // If data is older than a day, clear the interval
      const currentTime = new Date().getTime();
      const lastTime = state.lastTime;
      const diffTime = currentTime - lastTime;
      if (lastTime != 0 && diffTime > 86400000) {
        clearInterval(state.intervalId);
        state.intervalId = null;
      }

      // Get new data from server and update the FlightState
      const newFlight = await this.droneTelemetryService.getFlightWithDataAfterDate(flightId, lastTime);
      if (!newFlight?._id) {
        // In case of error from the server, do nothing more
        // We don't update the state because it could just be
        // a temporary issue so maybe we will get updated data soon ?
        return;
      }
      state.flight = newFlight;
      state.lastTime = newFlight.endDate.getTime();
      state.fulldata.push(...(newFlight.data ?? []));

      // Refresh map elements with new data in store
      this.updateFlightPath(flightId);
      this.updateFlightIcon(flightId);
    }, 2000);
  }

  /**
   * Get complete (initial) Flight data and update the FlightState
   */
  private async getInitialFlightData(flightId: string): Promise<boolean> {
    const state = this.flightStates[flightId];

    // If we already have data, stop here
    if (state.fulldata.length) {
      return true;
    }

    const flightWithData = await this.droneTelemetryService.getFlightWithData(flightId);
    if (!flightWithData?._id) {
      // In case of error from the server we don't remove the flight because it
      // could just be a temporary issue so we don't want the MapState to be updated
      // this.hideFlight(flightId);
      return false;
    }
    state.flight = flightWithData;
    state.fulldata = flightWithData.data ?? [];
    state.lastTime = flightWithData.endDate.getTime();
    return true;
  }

  /**
   * Update Path with latest data in FlightState
   */
  private updateFlightPath(flightId: string) {
    const state = this.flightStates[flightId];
    if (!state.isPathActive) {
      return;
    }

    const sourceId = this.FlightLayerPrefix + flightId;
    const source = this.map.getSource(sourceId) as any;
    const sourceData = source.serialize().data;
    sourceData.features[0].geometry.coordinates = state.fulldata;
    source.setData(sourceData as any);
  }

  /**
   * Update Icon with latest data in FlightState
   */
  private updateFlightIcon(flightId: string) {
    const state = this.flightStates[flightId];
    if (!state.isIconActive) {
      return;
    }

    const marker = state.marker;
    const flight = state.flight;

    // Create marker html element
    marker.getElement().className = flight.deviceType === DeviceType.Robot ? 'map-robot-marker' : 'map-drone-marker';

    // Add an information Marker at the end of the flight
    let content = flight.startDate.toLocaleString();
    if (flight.endDate) {
      content += ' -<br>' + flight.endDate.toLocaleString();
    }
    const lastPoint = state.fulldata[state.fulldata.length - 1] ?? [null, null];

    marker
      .setLngLat(lastPoint)
      .setRotation(flight.heading)
      .setPopup(
        new Popup({
          closeButton: false,
          focusAfterOpen: false,
        }).setHTML(`
        <div class='${tdrawConfig.markerPopupClass}'>
          <h4>${flight.droneName}</h4>
          <p>${content}</p>
        </div>`)
      );
  }

  private hideFlight(flightId: string) {
    const flightState = this.flightStates[flightId];
    if (flightState.isIconActive) {
      this.toggleRealTimeFlightIcon(flightId);
    }
    if (flightState.isPathActive) {
      this.toggleRealTimeFlightPath(flightId);
    }
  }

  private hideAllFlights() {
    for (const flightId in this.flightStates) {
      this.hideFlight(flightId);
    }
  }

  async toggleRealTimeFlightPath(flightId: string) {
    this.prepareFlightDataStore(flightId);

    const state = this.flightStates[flightId];
    const sourceId = this.FlightLayerPrefix + flightId;

    // Display the Path
    if (!state.isPathActive) {
      // Be sure there is initial Data
      if (!(await this.getInitialFlightData(flightId))) {
        // If no initial Data, just don't load the Flight this time
        return;
      }
      state.isPathActive = true;
      this.updateFlightPath(flightId);
      this.map.setLayoutProperty(sourceId + '-polyline', 'visibility', 'visible');
    } else {
      // Hide the Path
      state.isPathActive = false;
      this.map.setLayoutProperty(sourceId + '-polyline', 'visibility', 'none');
    }
  }

  async toggleRealTimeFlightIcon(flightId: string, centerOnIcon: boolean = true) {
    this.prepareFlightDataStore(flightId);

    const state = this.flightStates[flightId];

    // Display the Icon
    if (!state.isIconActive) {
      // Be sure there is initial Data
      if (!(await this.getInitialFlightData(flightId))) {
        // If no initial Data, just don't load the Flight this time
        return;
      }
      state.isIconActive = true;
      this.updateFlightIcon(flightId);
      if (centerOnIcon) {
        this.map.setCenter(state.marker.getLngLat());
      }
    } else {
      // Hide the Icon
      state.isIconActive = false;
      state.marker.setLngLat([null, null]);
      state.marker.getPopup().remove();
    }
  }

  private initPinpointImageClickListener() {
    this.map.getContainer().addEventListener('click', (e) => {
      const target: any = e.target;
      const classes = [...target.classList, ...target.parentElement?.classList];

      if (classes.includes(PinpointImageClass)) {
        this.dialog.open(PinpointImageModalComponent, {
          maxWidth: '90vw',
          maxHeight: '90vh',
          data: {
            imageUrl: target.dataset.src,
          },
        });
      }
    });
  }

  geolocalizeMe() {
    navigator.geolocation.getCurrentPosition(
      (loc) => {
        const { latitude, longitude } = loc.coords;
        const coords = [longitude, latitude] as LngLatLike;
        // Center the map and add a marker
        this.map.setCenter(coords);
        const marker = new Marker().setLngLat(coords).addTo(this.map);

        // Remove the marker after timeout
        setTimeout(() => marker.remove(), 5000);
      },
      () => {
        console.error('Access to localization is not authorized');
      },
      { timeout: 10000 }
    );
  }
}
