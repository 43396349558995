import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Drone, Intervention } from 'src/app/autogenerated/model';
import { InterventionService } from '../../../autogenerated/interventionService';
import { MidSelectItem } from '../../../midgard-controls/models';
import { Subscription } from 'rxjs';
import { OrganizationStoreService } from '../../../organization/store';
import { DroneStoreService } from '../../store';

@Component({
  selector: 'app-select-drone-to-assign',
  templateUrl: './select-drone-to-assign.component.html',
  styleUrls: ['./select-drone-to-assign.component.scss'],
})
export class SelectDroneToAssignComponent implements OnInit {
  interventionId: string;
  selectedDroneId: string;
  drones: Drone[];
  organizationId: string;

  data: {
    intervention: Intervention;
    drones: MidSelectItem[];
  };

  defaultInterventionName: string;

  private organizationSubscription: Subscription;
  private dronesSubscription: Subscription;
  private interventionsSubscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<SelectDroneToAssignComponent, string>,
    private readonly interventionService: InterventionService,
    private readonly organizationStoreService: OrganizationStoreService,
    private readonly droneStoreService: DroneStoreService
  ) {
    this.data = {
      intervention: new Intervention({
        type: undefined,
      }),
      drones: [],
    };
  }

  async ngOnInit(): Promise<void> {
    this.organizationSubscription = this.organizationStoreService
      .getCurrentOrganization()
      .subscribe(async (organization) => {
        this.organizationId = organization ? organization._id : null;
        if (!this.organizationId) {
          return;
        }

        this.updateInterventionsSubscription(organization._id);
        this.updateDronesSubscription(organization._id);
      });
  }

  private updateDronesSubscription(organizationId: string): void {
    if (!organizationId) {
      return;
    }

    if (this.dronesSubscription) {
      this.dronesSubscription.unsubscribe();
      this.dronesSubscription = null;
    }

    this.dronesSubscription = this.droneStoreService.getOrganizationDrones(organizationId).subscribe((drones) => {
      this.data.drones = this.convertDronesToMidSelectItem(drones);
    });
  }

  private updateInterventionsSubscription(organizationId: string): void {
    if (!organizationId) {
      return;
    }

    if (this.interventionsSubscription) {
      this.interventionsSubscription.unsubscribe();
      this.interventionsSubscription = null;
    }
  }

  private convertDronesToMidSelectItem(drones: Drone[]): MidSelectItem[] {
    if (drones) {
      return drones.map((drone) => {
        const midSelectItem: MidSelectItem = {
          value: drone._id,
          name: drone.name,
          isSelected: false,
        };
        return midSelectItem;
      });
    }
    return [];
  }

  onSelectDrone() {
    const selectedDrone = this.data.drones.find((drone) => drone.isSelected);
    this.selectedDroneId = selectedDrone.value;
    if (selectedDrone) {
      this.dialogRef.close(this.selectedDroneId);
    }
  }

  onCancel() {
    this.dialogRef.close();
  }
}
