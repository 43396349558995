<div class="version-element" [class.selected]="isSelected" (click)="onSelectMapState()">
  <div class="version-labels">
    <p class="font-weight-bold map-state-name" [title]="item.name">{{ item.name }}</p>
    <ng-container *ngIf="item.publishedAt">
      <p>
        <span class="font-weight-bold mr-1">{{ 'PUBLISHED_AT' | translate }}</span
        >{{ item.publishedAt | date : 'HH:mm (dd/MM/yy)' }}
      </p>
      <p>
        {{ 'BY' | translate }} <span class="creator-name">{{ item.creator.fullname }}</span>
      </p>
    </ng-container>
    <ng-container *ngIf="!item.publishedAt">
      <p>
        {{ 'IN_PROGRESS_BY' | translate }} <span class="creator-name">{{ item.creator.fullname }}</span>
      </p>
      <p>
        <app-mid-badge>
          <span>{{ 'IN_PRIVATE_STATE' | translate }}</span>
        </app-mid-badge>
      </p>
    </ng-container>
  </div>

  <div class="version-actions">
    <app-mid-icon-button type="primary" *ngIf="!item.publishedAt && item.type === 'gis'" (action)="openGisEditMap()">
      <i class="fas fa-pen fa-sm mid-btn-icon"></i>
    </app-mid-icon-button>
    <app-mid-icon-button type="primary" *ngIf="!item.publishedAt && item.type === 'pic'" (action)="openPicEditMap()">
      <i class="fas fa-pen fa-sm mid-btn-icon"></i>
    </app-mid-icon-button>
    <app-mid-icon-button type="secondary" *ngIf="item.publishedAt && item.type === 'gis'" (action)="openGisViewMap()">
      <i class="fas fa-eye fa-sm mid-btn-icon"></i>
    </app-mid-icon-button>
    <app-mid-icon-button type="secondary" *ngIf="item.publishedAt && item.type === 'pic'" (action)="openPicViewMap()">
      <i class="fas fa-eye fa-sm mid-btn-icon"></i>
    </app-mid-icon-button>
    <tdraw-version-list-element-menu
      class="ml-2"
      [mapState]="item"
      (click)="$event.stopPropagation()"
      (publish)="onPublish()"
      (rename)="onRename()"
      (duplicate)="onDuplicate()"
      (deletingMapState)="onDeleteMapState()"
      (download)="onDownload()"
    ></tdraw-version-list-element-menu>
  </div>
</div>
