import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { LocationSearchResult } from 'src/app/tdraw/models/location-search-result.model';
import { LocationService } from 'src/app/tdraw/services/location.service';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { MidInputComponent } from '../../../../midgard-controls/mid-input/mid-input.component';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Unsubscribe } from 'src/app/core/decorators';

@Unsubscribe()
@Component({
  selector: 'tdraw-location-search-bar',
  templateUrl: './location-search-bar.component.html',
  styleUrls: ['./location-search-bar.component.scss'],
})
export class LocationSearchBarComponent implements AfterViewInit, OnDestroy {
  @Input()
  value: string;
  @Output()
  valueChange: EventEmitter<string>;
  @Input()
  label: string;
  @Input()
  placeholder: string;
  @Input()
  disabled: boolean = false;

  @Output()
  onItemSelected: EventEmitter<LocationSearchResult>;

  // Autocompletion stuff
  locationResults: LocationSearchResult[];
  @ViewChild('locationInput')
  private locationMidInput: MidInputComponent;
  locationInput: Subscription;

  constructor(private locationService: LocationService) {
    this.valueChange = new EventEmitter();
    this.onItemSelected = new EventEmitter();
    this.locationResults = [];
  }

  onValueChange() {
    this.valueChange.emit(this.value);
  }

  ngAfterViewInit() {
    if (this.locationMidInput) {
      this.locationInput = this.locationMidInput.input.valueChanges
        .pipe(
          filter((res) => res?.length > 2),
          debounceTime(300),
          distinctUntilChanged()
        )
        .subscribe((text: string) => {
          this.locationService.search(text, (data) => {
            this.locationResults = data;
          });
        });
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const index = event.option.value;
    const target = this.locationResults[index];
    this.locationMidInput.input.control.setValue(target?.title);
    // this.value = target.title;
    this.locationResults = [];
    this.valueChange.emit(this.value);
    this.onItemSelected.emit(target);
  }

  ngOnDestroy(): void {}
}
