import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MediaType } from 'src/app/autogenerated/model';
import { MediaDialogService } from 'src/app/media/media-dialog.service';
import { NewPicVersionData } from 'src/app/tdraw/models/new-pic-version-data.model';

export interface DialogData {
  interventionId: string;
}

@Component({
  selector: 'tdraw-create-pic-version-modal',
  templateUrl: './create-pic-version-modal.component.html',
  styleUrls: ['./create-pic-version-modal.component.scss'],
})
export class CreatePicVersionModalComponent {
  data: NewPicVersionData;
  interventionId: string;

  constructor(
    private mediaDialogService: MediaDialogService,
    public dialogRef: MatDialogRef<CreatePicVersionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: DialogData
  ) {
    this.interventionId = modalData.interventionId;
    this.data = new NewPicVersionData();
  }

  onSubmit() {
    this.dialogRef.close(this.data);
  }

  onClose() {
    this.dialogRef.close();
  }

  async onMediaSelection() {
    const mediaId = await this.mediaDialogService.openSelectMediaDialog(
      'SELECT_MEDIA_TO_INTERVENTION',
      MediaType.picture,
      this.data.mediaId,
      this.interventionId
    );
    // We conserve the previous selection in case of cancellation
    if (mediaId) {
      this.data.mediaId = mediaId;
    }
  }
}
