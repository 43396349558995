import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AccessCodeService } from './api/accessCode.service';
import { DroneService } from './api/drone.service';
import { InterventionService } from './api/intervention.service';
import { OrganizationDistributionListService } from './api/organizationDistributionList.service';
import { OrganizationGroupService } from './api/organizationGroup.service';
import { OrganizationsService } from './api/organizations.service';
import { OrganizationsMediaService } from './api/organizationsMedia.service';
import { UsersService } from './api/users.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AccessCodeService,
    DroneService,
    InterventionService,
    OrganizationDistributionListService,
    OrganizationGroupService,
    OrganizationsService,
    OrganizationsMediaService,
    UsersService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
