import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanMatch,
  Route,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AccessTokenAuthenticationService } from './access-code-authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AccessCodeGuard implements CanMatch {
  private token: string;

  constructor(private readonly accessTokenAuthenticationService: AccessTokenAuthenticationService) {
    this.token = accessTokenAuthenticationService.token;
    accessTokenAuthenticationService.getAccessTokenObservable().subscribe((token) => {
      this.token = token;
    });
  }

  async canMatch(route: Route, segments: UrlSegment[]): Promise<boolean> {
    return !!this.token;
  }
}
