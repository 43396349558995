<div class="input-container" [class.input-label]="!!label">
  <label *ngIf="label" [class.required]="required">{{ label }}</label>
  <ngx-intl-tel-input
    [cssClass]="{
      default: size === MidInputSize.default,
      wide: size === MidInputSize.wide
    }"
    [preferredCountries]="preferredCountries"
    [enableAutoCountrySelect]="true"
    [enablePlaceholder]="true"
    [searchCountryFlag]="true"
    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
    [selectFirstCountry]="false"
    [selectedCountryISO]="CountryISO.France"
    [maxLength]="'15'"
    [phoneValidation]="true"
    [separateDialCode]="separateDialCode"
    [numberFormat]="PhoneNumberFormat.National"
    [(ngModel)]="value"
    (ngModelChange)="onValueChange()"
    [required]="required"
    #midInputPhoneNumber="ngModel"
    [name]="name"
    [disabled]="disabled"
  >
  </ngx-intl-tel-input>
  <ng-content></ng-content>
</div>
