import mapboxgl, { GeoJSONSource, LngLatLike, Marker, Popup } from 'mapbox-gl';
import * as turf from '@turf/turf';
import { GuidService } from 'src/app/global/guid.service';
import AnnotationsHelpers from './annotations-helpers';
import _ from 'lodash';
import { Feature, FeatureCollection, GeometryTypes } from '@turf/turf';
import { GeometryType } from '../models/geometry-type.enum';
import { FeatureCalculation } from '../models/feature-calculation.model';
import { ServiceLocator } from 'src/app/global/locator.service';
import { RegularMarker } from '../models/regular-marker.model';
import { MatDialog } from '@angular/material/dialog';
import { EditRegularMarkerModalComponent } from '../components/modals/edit-regular-marker-modal/edit-regular-marker-modal.component';
import { tdrawConfig } from '../tdraw.config';
import { environment } from 'src/environments/environment';

// CSS Class used by regular markers
const MarkerDeleteBtnClass = 'marker-delete-btn';
const MarkerEditBtnClass = 'marker-edit-btn';

/**
 * MapManager handles Mapbox logic outside of MapboxDraw scope
 *
 * Examples of responsabilities:
 * - Logic of Sources, Layers and Features (GeoJSON Annotations)
 * - RegularMarkers
 * - Snapshots
 */
export class MapManager {
  public readonly AnnotationSourcePrefix = 'annotation-';

  protected guidService: GuidService;
  protected dialog: MatDialog;

  private layersBySource: { [sourceId: string]: string[] } = {};

  // Regular Markers
  public regularMarkers: RegularMarker[] = [];
  private regularMarkersMarkers: { [guid: string]: Marker } = {};

  // Used to navigate through features
  protected previous: { index: number; features: mapboxgl.MapboxGeoJSONFeature[] } = {
    index: null,
    features: [],
  };

  constructor(protected map: mapboxgl.Map) {
    this.guidService = ServiceLocator.injector.get(GuidService);
    this.dialog = ServiceLocator.injector.get(MatDialog);

    this.initMarkerActionsListener();
  }

  navigateThroughFeatures(features: mapboxgl.MapboxGeoJSONFeature[]): mapboxgl.MapboxGeoJSONFeature | null {
    // Filter to only keep Features representing an Annotation
    const filtered = features.filter((feature) => {
      return feature.source.startsWith(this.AnnotationSourcePrefix) && feature.id !== null;
    });
    if (!filtered.length) {
      return null;
    }

    // Reduce the filtered array to elimate any duplicated feature caused by MapboxGL vectorization
    const ids = filtered.map((object) => object['source']);
    const uniques = filtered.filter((object, index) => !ids.includes(object['source'], index + 1));

    // First time we call this, so just return the first item found
    if (!this.previous.features.length) {
      this.previous.features = uniques;
      this.previous.index = 0;
      return uniques[0];
    }

    // Compare the current and previous sourceIds to determine if
    // we are navigating through the same features than before or not
    const currentSourceIds = uniques.map((feature) => feature.source);
    const previousSourceIds = this.previous.features.map((feature) => feature.source);
    const same = _.intersection(currentSourceIds, previousSourceIds);

    // We are navigating through the same features than before, let's return the next one
    if (same.length === this.previous.features.length && same.length === uniques.length) {
      this.previous.index = (this.previous.index + 1) % same.length;
      return this.previous.features[this.previous.index];
    }

    // We are not navigating through the same features than before, but let's be sure
    // we won't return the same feature twice. Except if it's the only one present
    if (uniques.length !== 1 && uniques[0].source === this.previous.features[this.previous.index].source) {
      this.previous.index = 1;
    } else {
      this.previous.index = 0;
    }

    this.previous.features = uniques;
    return uniques[this.previous.index];
  }

  getAnnotationSourceData(sourceId: string): FeatureCollection {
    const source = this.map.getSource(sourceId) as any;
    if (!source) {
      return null;
    }
    return source.serialize().data;
  }

  getListOfAnnotationSourceIds(): string[] {
    return Object.keys(this.map.getStyle().sources).filter((sourceId) => {
      return sourceId.startsWith(this.AnnotationSourcePrefix);
    });
  }

  deleteAllAnnotationSources() {
    const sourceIds = this.getListOfAnnotationSourceIds();
    for (const sourceId of sourceIds) {
      this.deleteSource(sourceId);
    }
  }

  deleteSource(sourceId: string) {
    this.deleteLayersForSource(sourceId);
    this.map.removeSource(sourceId);
  }

  deleteLayersForSource(sourceId: string) {
    for (let layerId of this.layersBySource[sourceId]) {
      this.map.removeLayer(layerId);
    }
    this.layersBySource[sourceId] = [];
  }

  createLayersForSource(sourceId: string, data: FeatureCollection) {
    // Get layer definitions (Mapbox styles)
    const featureType = data.features[0].geometry.type as GeometryTypes;
    const layers = AnnotationsHelpers.getLayerDef(sourceId, featureType, data);
    if (!layers) {
      return null;
    }

    // Create a Mapbox layer for each definition
    this.layersBySource[sourceId] = [];
    for (let layer of layers) {
      this.map.addLayer(layer);
      this.layersBySource[sourceId].push(layer.id);
    }
  }

  updateOrCreateSource(data: FeatureCollection, sourceId: string) {
    if (this.map.getSource(sourceId)) {
      this.updateSource(data, sourceId);
    } else {
      this.createSource(data, sourceId);
    }
  }

  updateSource(data: FeatureCollection, sourceId: string) {
    // Update source data
    const source = this.map.getSource(sourceId) as GeoJSONSource;
    source.setData(data as any);

    // Update layers (needed to update MapIcons text color and position)
    this.deleteLayersForSource(sourceId);
    this.createLayersForSource(sourceId, data);
  }

  createSource(data: FeatureCollection, id?: string): string | null {
    // Create source
    const sourceId = id ?? this.generateSourceId();
    this.map.addSource(sourceId, {
      type: 'geojson',
      data: data as any,
      generateId: true,
    });

    // Create layers
    this.createLayersForSource(sourceId, data);
    return sourceId;
  }

  showSource(sourceId: string) {
    for (let layerId of this.layersBySource[sourceId]) {
      this.map.setLayoutProperty(layerId, 'visibility', 'visible');
    }
  }

  hideSource(sourceId: string) {
    for (let layerId of this.layersBySource[sourceId]) {
      this.map.setLayoutProperty(layerId, 'visibility', 'none');
    }
  }

  bringSourceToFront(sourceId: string) {
    for (let layerId of this.layersBySource[sourceId]) {
      this.map.moveLayer(layerId);
    }
  }

  private generateSourceId() {
    return this.AnnotationSourcePrefix + this.guidService.generate();
  }

  calculateMetrics(feature: Feature): FeatureCalculation | null {
    const featureType = feature.geometry.type;
    let bearing = null;
    let invBearing = null;

    if (featureType === GeometryType.Polygon) {
      const area = +turf.area(feature).toFixed(4);
      const perimeter = +turf.length(feature).toFixed(4);
      const radiusInKm = feature.properties?.radiusInKm?.toFixed(4);
      return new FeatureCalculation({ featureType, area, perimeter, radiusInKm });
    } else if (featureType === GeometryType.LineString) {
      const length = +turf.length(feature).toFixed(4);
      const coordinates = (feature.geometry as any).coordinates;
      if (coordinates.length == 2) {
        const point1 = turf.point(coordinates[0]);
        const point2 = turf.point(coordinates[1]);
        bearing = +turf.bearing(point1, point2).toFixed(1);
        invBearing = +(bearing + 180).toFixed(4);
      }
      return new FeatureCalculation({ featureType, length, bearing, invBearing });
    } else if (featureType === GeometryType.Point) {
      const coordinates = (feature.geometry as any).coordinates;
      const latitude = coordinates[1].toFixed(6);
      const longitude = coordinates[0].toFixed(6);
      return new FeatureCalculation({ featureType, latitude, longitude });
    } else if (featureType === GeometryType.MultiLineString) {
      const coordinates = (feature.geometry as any).coordinates[0][0];
      const latitude = coordinates[1].toFixed(6);
      const longitude = coordinates[0].toFixed(6);
      const wind = feature.properties?.wind;
      return new FeatureCalculation({ featureType, latitude, longitude, wind });
    }

    return null;
  }

  getGeoJSONFeatures(sourceId: string): Feature[] {
    return (this.map.getSource(sourceId) as any).serialize().data.features;
  }

  getGeoJSONFeature(sourceId: string, featureId: string): Feature {
    let features = this.getGeoJSONFeatures(sourceId);
    if (isNaN(+featureId)) {
      // Handle a String ID
      return _.find(features, function (feature) {
        return feature.id === featureId;
      });
    } else {
      // Handle an Integer ID (index)
      return features[featureId];
    }
  }

  /**
   * Get the bounding box based on loaded geojson data
   */
  getDataBBox(): turf.BBox {
    const sources: any[] = _.filter(this.map.getStyle().sources, (s) => {
      return s.type === 'geojson';
    });

    const features = [];
    for (let source of sources) {
      features.push(...source.data.features);
    }

    return features.length ? turf.bbox(turf.featureCollection(features)) : null;
  }

  async printSnapshot(inPlace: boolean = false) {
    const data = await this.takeSnapshot(inPlace);
    const win = window.open('about:blank', '_new');
    win.document.open();
    win.document.write(`
      <html>
        <head><title>Midgard</title></head>
        <body onload="window.print()" onafterprint="window.close()">
          <img style="max-width:100%; max-height:100%" src="${data}">
        </body>
      </html>
    `);
    win.document.close();
  }

  async downloadSnapshot(inPlace: boolean = false) {
    const data = await this.takeSnapshot(inPlace);
    const link = document.createElement('a');
    link.download = 'screenshot.png';
    link.href = data;
    link.click();
  }

  takeSnapshot(inPlace: boolean = false): Promise<string> {
    if (inPlace) {
      return new Promise<string>((resolve) => {
        this.map.once('render', () => {
          resolve(this.map.getCanvas().toDataURL());
        });
        // Force trigger a "render" event
        this.map.triggerRepaint();
      });
    }

    // If not "in-place", fit to the extents first
    return new Promise<string>((resolve) => {
      // Fit bounds before taking a snapshot
      const bbox: any = this.getDataBBox();
      if (bbox != null) {
        this.map.fitBounds(bbox, {
          padding: 50,
          animate: false,
        });
      }

      // Wait to load content before taking a screenshot
      setTimeout(() => {
        this.map.once('render', () => {
          resolve(this.map.getCanvas().toDataURL());
        });

        // Force trigger a "render" event
        this.map.triggerRepaint();
      }, 1000);
    });
  }

  setMapPosition(position: { center?: LngLatLike; zoom?: number }) {
    const center = position?.center;
    const zoom = position?.zoom;
    if (center) {
      this.map.setCenter(center);
    }
    if (zoom) {
      this.map.setZoom(zoom);
    }
  }

  loadRegularMarkers(markers: RegularMarker[], withControls: boolean = false, imgPathPrefix: string = '') {
    this.removeAllRegularMarkers();
    for (const marker of markers) {
      this.displayRegularMarker(marker, withControls, imgPathPrefix);
    }
  }

  displayRegularMarker(rm: RegularMarker, withControls: boolean = false, imgPathPrefix: string = '') {
    const guid = rm.guid;

    // RegularMarker may have a custom icon
    let el = null;
    if (rm.img) {
      const url = `${environment.backend}${imgPathPrefix}/${rm.img}`;
      el = document.createElement('div');
      el.className = 'map-regular-marker';
      el.style.backgroundImage = `url(${url})`;
    }

    // Create a Marker and link a Popup to it
    const marker = new Marker(el)
      .setLngLat([rm.lng, rm.lat])
      .setPopup(this.getRegularMarkerPopup(rm, withControls))
      .addTo(this.map);

    // Avoid getting click event on the Map when clicking on the Marker
    marker.getElement().addEventListener(
      'click',
      (e) => {
        marker.togglePopup();
        e.stopPropagation();
      },
      false
    );

    // In edit mode, regular markers are draggable
    if (withControls) {
      marker.setDraggable(true);
      marker.on('dragend', () => {
        const lngLat = marker.getLngLat();
        const regularMarker = this.regularMarkers.find((e) => e.guid === guid);
        regularMarker.lat = lngLat.lat;
        regularMarker.lng = lngLat.lng;
      });
    }

    this.removeRegularMarker(guid);
    this.regularMarkers.push(rm);
    this.regularMarkersMarkers[guid] = marker;
  }

  private removeRegularMarker(guid: string) {
    const index = this.regularMarkers.findIndex((e) => e.guid === guid);
    if (index === -1) {
      return;
    }

    this.regularMarkers.splice(index, 1);
    this.regularMarkersMarkers[guid]?.remove();
    delete this.regularMarkersMarkers[guid];
  }

  private removeAllRegularMarkers() {
    for (const guid in this.regularMarkersMarkers) {
      this.removeRegularMarker(guid);
    }
  }

  private getRegularMarkerPopup(rm: RegularMarker, withControls: boolean) {
    const guid = rm.guid;
    const info = `<p>Lng: ${rm.lng.toFixed(7)} / Lat: ${rm.lat.toFixed(7)}`;
    const controls = withControls
      ? `<p class='controls'><button class='${MarkerDeleteBtnClass} btn btn-danger' id='${guid}'><i class='fa fa-trash'> </i></button><button class='${MarkerEditBtnClass} btn btn-info' id='${guid}'><i class='fas fa-edit'> </i></button></p>`
      : '';

    return new Popup({
      closeButton: false,
      focusAfterOpen: false,
    }).setHTML(`<div class='${tdrawConfig.markerPopupClass}'>${rm.content}<hr>${info}${controls}</div>`);
  }

  private displayRegularMarkerEditModal(guid: string) {
    const marker = this.regularMarkers.find((e) => e.guid === guid);
    const dialogRef = this.dialog.open(EditRegularMarkerModalComponent, {
      width: '900px',
    });
    dialogRef.componentInstance.setContent(marker.content);
    dialogRef.afterClosed().subscribe((content: string) => {
      if (!content) {
        return;
      }
      marker.content = content;
      this.regularMarkersMarkers[guid].setPopup(this.getRegularMarkerPopup(marker, true));
    });
  }

  private initMarkerActionsListener() {
    this.map.getContainer().addEventListener('click', (e) => {
      const target: any = e.target;
      const classes = [...target.classList, ...target.parentElement?.classList];

      // Delete button
      if (classes.includes(MarkerDeleteBtnClass)) {
        const guid = target.id || target.parentElement.id;
        this.removeRegularMarker(guid);
      }

      // Edit button
      if (classes.includes(MarkerEditBtnClass)) {
        const guid = target.id || target.parentElement.id;
        this.displayRegularMarkerEditModal(guid);
      }
    });
  }
}
