import {
  DbClass,
  Intervention,
  CreateInterventionDto,
  UpdateInterventionDto,
  MediaFilterType,
  OrganizationMedia,
  InterventionFilterParameters,
  InterventionFilterParametersDto,
  UpdateInterventionAlertDto,
  Alert,
  TimeOfDay,
} from '../model';
import { BackendClientService } from '../../global/backend-client.service';
import { ApiService } from 'src/app/core/api';

export class InterventionServiceAutoGenerated {
  constructor(private beClient: BackendClientService, private apiService: ApiService) {}

  async createInterventionV1(
    organizationId: string,
    createInterventionDto: CreateInterventionDto
  ): Promise<Intervention> {
    let result: Intervention;
    try {
      result = await this.beClient
        .post<Intervention>(`api/v1/organization/${organizationId}/intervention`, createInterventionDto)
        .toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return result;
  }

  async getInterventionV1(organizationId: string, interventionId: string): Promise<Intervention> {
    let result: Intervention;
    try {
      result = await this.beClient
        .get<Intervention>(`api/v1/organization/${organizationId}/intervention/${interventionId}`)
        .toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return result;
  }

  async updateInterventionV1(
    organizationId: string,
    interventionId: string,
    updateInterventionDto: UpdateInterventionDto
  ): Promise<Intervention> {
    let result: Intervention;
    try {
      result = await this.beClient
        .put<Intervention>(
          `api/v1/organization/${organizationId}/intervention/${interventionId}`,
          updateInterventionDto
        )
        .toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return result;
  }

  async reopenInterventionV1(organizationId: string, interventionId: string): Promise<Intervention> {
    let result: Intervention;
    try {
      result = await this.beClient
        .put<Intervention>(`api/v1/organization/${organizationId}/intervention/${interventionId}/reopen`, null)
        .toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return result;
  }
  async updateInterventionFilterParametersV1(
    organizationId: string,
    interventionId: string,
    interventionFilterParametersDto: InterventionFilterParametersDto
  ): Promise<InterventionFilterParameters> {
    let result: InterventionFilterParameters;
    try {
      result = await this.beClient
        .put<InterventionFilterParameters>(
          `api/v1/organization/${organizationId}/intervention/${interventionId}/parameters`,
          interventionFilterParametersDto
        )
        .toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return result;
  }

  async getInterventionFilterParametersV1(
    organizationId: string,
    interventionId: string
  ): Promise<InterventionFilterParameters> {
    let result: InterventionFilterParameters;
    try {
      result = await this.beClient
        .get<InterventionFilterParameters>(
          `api/v1/organization/${organizationId}/intervention/${interventionId}/parameters`
        )
        .toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return result;
  }

  async deleteInterventionV1(organizationId: string, interventionId: string): Promise<boolean> {
    let result: boolean;
    try {
      result = await this.beClient
        .delete<boolean>(`api/v1/organization/${organizationId}/intervention`, interventionId)
        .toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return result;
  }

  async closeInterventionV1(organizationId: string, interventionId: string): Promise<Intervention> {
    let result: Intervention;
    try {
      result = await this.beClient
        .put<Intervention>(`api/v1/organization/${organizationId}/intervention/${interventionId}/close`, null)
        .toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return result;
  }

  async archiveInterventionV1(organizationId: string, interventionId: string): Promise<Intervention> {
    let result: Intervention;
    try {
      result = await this.beClient
        .put<Intervention>(`api/v1/organization/${organizationId}/intervention/${interventionId}/archive`, null)
        .toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return result;
  }

  async createIntervention(createInterventionDto: CreateInterventionDto): Promise<Intervention> {
    let result: Intervention;
    try {
      result = await this.beClient
        .put<Intervention>('interventionService/createIntervention', createInterventionDto)
        .toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return result;
  }

  async updateIntervention(intervention: Intervention): Promise<Intervention> {
    let result: Intervention;
    try {
      result = await this.beClient
        .post<Intervention>('interventionService/updateIntervention', intervention)
        .toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return result;
  }

  async closeIntervention(intervention: DbClass): Promise<Intervention> {
    let result: Intervention;
    try {
      result = await this.beClient
        .post<Intervention>('interventionService/closeIntervention', intervention)
        .toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return result;
  }

  async deleteIntervention(intervention: DbClass): Promise<Intervention> {
    let result: Intervention;
    try {
      result = await this.beClient
        .post<Intervention>('interventionService/deleteIntervention', intervention)
        .toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return result;
  }

  async attachDroneToInterventionV1(organizationId: string, interventionId: string, droneId: string): Promise<boolean> {
    let result: boolean;
    try {
      result = await this.beClient
        .put<boolean>(
          `api/v1/organization/${organizationId}/intervention/${interventionId}/attach/drone/${droneId}`,
          null
        )
        .toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return result;
  }

  async getMediaV1(
    organizationId: string,
    interventionId: string,
    filters: MediaFilterType[]
  ): Promise<OrganizationMedia[]> {
    let result: OrganizationMedia[];
    let query: string = '';
    try {
      if (filters?.length) {
        query += filters.map((it) => `filter=${it}`).join('&');
      }
      result = await this.beClient
        .get<OrganizationMedia[]>(
          `api/v1/organization/${organizationId}/intervention/${interventionId}/media${query ? '?' + query : ''}`
        )
        .toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return result;
  }

  async assignMediaToIntervention(
    organizationId: string,
    interventionId: string,
    mediaId: string
  ): Promise<OrganizationMedia> {
    let result: OrganizationMedia;
    try {
      result = await this.beClient
        .put<OrganizationMedia>(
          `api/v1/organization/${organizationId}/intervention/${interventionId}/media/${mediaId}/assign`,
          null
        )
        .toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return result;
  }

  async getAlerts(
    organizationId: string,
    interventionId?: string,
    filter?: {
      timeOfDay?: TimeOfDay;
      days?: number;
    }
  ): Promise<Alert[]> {
    let result: Alert[];
    let query: string = '';
    try {
      if (interventionId) {
        query += `intervention=${interventionId}`;
      }
      if (filter?.timeOfDay) {
        query += (query ? '&' : '') + `timeOfDay=${filter.timeOfDay}`;
      }
      if (filter?.days) {
        query += (query ? '&' : '') + `days=${filter.days}`;
      }
      result = await this.beClient
        .get<Alert[]>(
          `api/v1/organization/${organizationId}/intervention/alerts/smoke-detection${query ? '?' + query : ''}`
        )
        .toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return result;
  }

  async updateInterventionAlert(
    organizationId: string,
    alertId: string,
    updateInterventionAlertDto: UpdateInterventionAlertDto
  ): Promise<UpdateInterventionAlertDto> {
    let result: UpdateInterventionAlertDto;
    try {
      result = await this.beClient
        .put<UpdateInterventionAlertDto>(
          `api/v1/organization/${organizationId}/intervention/alerts/${alertId}`,
          updateInterventionAlertDto
        )
        .toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return result;
  }

  // TODO: unused methods, remove in future updates

  // async detachDroneToInterventionV1(organizationId: string, interventionId: string, droneId: string): Promise<boolean> {
  //   let result: boolean;
  //   try {
  //     result = await this.beClient
  //       .put<boolean>(
  //         `api/v1/organization/${organizationId}/intervention/${interventionId}/detach/drone/${droneId}`,
  //         null
  //       )
  //       .toPromise();
  //   } catch (error) {
  //     const errorResponse = this.apiService.handleError(error);
  //     throw errorResponse;
  //   }
  //   return result;
  // }

  // async getInterventionsV1(organizationId: string): Promise<Intervention[]> {
  //   let result: Intervention[];
  //   try {
  //     result = await this.beClient
  //       .get<Intervention[]>(`api/v1/organization/${organizationId}/intervention`)
  //       .toPromise();
  //   } catch (error) {
  //     const errorResponse = this.apiService.handleError(error);
  //     throw errorResponse;
  //   }
  //   return result;
  // }
}
