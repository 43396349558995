<div>
  <h2>{{ 'CREATE_USER' | translate }}</h2>
</div>
<div>
  <form>
    <div class="form-group">
      <label for="fullname">{{ 'FULLNAME' | translate }}</label>
      <input
        name="fullname"
        [(ngModel)]="newUser.fullname"
        type="text"
        class="form-control"
        placeholder="{{ 'FULLNAME' | translate }}"
      />
    </div>
    <div class="form-group">
      <label for="email">{{ 'EMAIL' | translate }}</label>
      <input name="email" [(ngModel)]="newUser.email" type="email" class="form-control" placeholder="Enter email" />
    </div>
    <div class="form-group">
      <label for="password">{{ 'PASSWORD' | translate }}</label>
      <input
        [(ngModel)]="newUser.password"
        name="password"
        type="password"
        class="forms-control"
        placeholder="Password"
      />
    </div>
    <div type="submit" class="btn btn-primary" (click)="createUser()">{{ 'SUBMIT' | translate }}</div>
  </form>
</div>
