import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MediaDeleteComponent } from './media-delete/media-delete.component';
import { MediaPageComponent } from './media-page/media-page.component';
import { MediaPictureComponent } from './media-picture/media-picture.component';
import { MediaVideoComponent } from './media-video/media-video.component';
import { CoreModule } from '../core/core.module';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MediaInterventionComponent } from './media-intervention/media-intervention.component';
import { NewMediaNotificationComponent } from './new-media-notification/new-media-notification.component';
import { SearchSectionComponent } from './search-section/search-section.component';
import { MidgardControlsModule } from '../midgard-controls/midgard-controls.module';
import { MediaCommonModule } from './common/media-common.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MediaSelectComponent } from './media-select/media-select.component';
import { SharedMediaComponent } from './shared-media/shared-media.component';
import { MediaShareComponent } from './media-share/media-share.component';
import { MediaShareViewComponent } from './media-share-view/media-share-view.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { QRCodeModule } from 'angularx-qrcode';
import { MediaAllComponent } from './media-all/media-all.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MediaFilterComponent } from './media-filter/media-filter.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    MediaDeleteComponent,
    MediaPageComponent,
    MediaPictureComponent,
    MediaVideoComponent,
    MediaInterventionComponent,
    NewMediaNotificationComponent,
    SearchSectionComponent,
    MediaSelectComponent,
    SharedMediaComponent,
    MediaShareComponent,
    MediaShareViewComponent,
    MediaAllComponent,
    MediaFilterComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    CoreModule,
    MidgardControlsModule,
    MediaCommonModule,
    MatDialogModule,
    MatInputModule,
    MatMenuModule,
    MatTooltipModule,
    QRCodeModule,
    InfiniteScrollModule,
    MatAutocompleteModule,
    SharedModule,
  ],
  exports: [
    MediaPageComponent,
    MediaAllComponent,
    MediaPictureComponent,
    MediaVideoComponent,
    SearchSectionComponent,
    MediaSelectComponent,
  ],
})
export class MediaModule {}
