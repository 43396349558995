import { Component, OnDestroy, OnInit } from '@angular/core';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { Intervention, Organization } from 'src/app/autogenerated/model';
import { Unsubscribe } from 'src/app/core/decorators';
import { TemplateService } from 'src/app/global/template.service';
import { InterventionTabContentComponent } from '../intervention-tab-container/intervention-tab-container.component';

@Unsubscribe()
@Component({
  selector: 'app-intervention-tab-map',
  templateUrl: './intervention-tab-map.component.html',
  styleUrls: ['./intervention-tab-map.component.scss'],
})
export class InterventionTabMapComponent implements InterventionTabContentComponent, OnInit, OnDestroy {
  intervention: Intervention;
  organization: Organization;
  hasAdminPermissions: boolean;

  private organizationSubsription: Subscription;

  constructor(private templateService: TemplateService) {}

  ngOnInit() {
    this.templateService.$activeComponent.next('INTERVENTION.TAB.TDRAW');
  }

  ngOnDestroy(): void {
    // for Unsubscribe
  }
}
