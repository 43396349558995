import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { OrganizationDto } from 'src/api';
import { Unsubscribe } from 'src/app/core/decorators';
import { OrganizationStoreService } from 'src/app/organization/store';
import { isEmpty } from 'lodash';

@Unsubscribe()
@Component({
  selector: 'app-organization-tabbed-view',
  templateUrl: './organization-tabbed-view.component.html',
  styleUrls: ['./organization-tabbed-view.component.scss'],
})
export class OrganizationTabbedViewComponent implements OnInit, OnDestroy {
  organization: OrganizationDto;

  private organizationSubscription: Subscription;

  constructor(
    private readonly organizationStoreService: OrganizationStoreService,
    private readonly route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getCurrentOrganization();
  }

  private getCurrentOrganization() {
    this.organizationSubscription = this.organizationStoreService
      .getCurrentOrganization()
      .subscribe(async (organization) => {
        const org = organization as OrganizationDto;

        const currentOrganization = this.organization;
        this.organization = org ? org : null;

        if (!this.organization) {
          await this.router.navigate(['']);
          return;
        }

        if (!isEmpty(currentOrganization)) {
          if (currentOrganization.id !== this.organization.id) {
            await this.router.navigate(['/organization', this.organization.id], { relativeTo: this.route });
          }
        }
      });
  }

  ngOnDestroy(): void {}
}
