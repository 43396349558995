import { MapState } from '../models/map-state.model';
import * as tokml from 'tokml';
import { AnnotationCategory } from '../models/annotation-category.enum';
import { FeatureProperty } from '../models/feature-property.enum';

/**
 * Export MapState annotations to KML using mapbox/tokml
 * https://github.com/mapbox/tokml
 *
 * GeoJSON Styles must follow this structure
 * in order to get exported into the output KML:
 * https://github.com/mapbox/simplestyle-spec/tree/master/1.1.0
 */
export class MapStateKmlExporter {
  constructor(private mapState: MapState) {}

  getKML() {
    const toConvert = this.getAnnotationsMergedAsGeoJSON();
    return tokml(toConvert, {
      simplestyle: true,
    });
  }

  private getAnnotationsMergedAsGeoJSON() {
    const annotations = this.mapState.annotations;

    const shapeFeatures = [];
    for (const annotation of annotations) {
      if (annotation.category != AnnotationCategory.Shapes) {
        continue;
      }

      for (const feature of annotation.data.features) {
        shapeFeatures.push({
          type: 'Feature',
          geometry: feature.geometry,
          properties: {
            ...feature.properties,
            ...this.getGeoJSONSimpleStyleSpecProperties(feature.properties),
          },
        });
      }
    }

    return {
      features: shapeFeatures,
      type: 'FeatureCollection',
    };
  }

  private getGeoJSONSimpleStyleSpecProperties(properties) {
    const output = {};

    // Opacity
    const opacity = properties[FeatureProperty.Opacity];
    if (opacity) {
      output['stroke-opacity'] = opacity;
      output['fill-opacity'] = opacity;
    }

    // Color
    const color = properties[FeatureProperty.Color];
    if (color) {
      output['stroke'] = color;
      output['fill'] = color;
    }

    return output;
  }
}
