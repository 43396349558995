import { Intervention } from './../../autogenerated/model';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { required, validate } from 'src/app/shared';
import {
  SetCurrentInterventionAction,
  SetOrganizationInterventionAction,
  SetOrganizationInterventionsAction,
  UpdateInterventionAction,
} from './intervention.actions';
import {
  selectCurrentInterventionByOrganizationId,
  selectInterventionById,
  selectOrganizationInterventionsByOrganizationId,
} from './intervention.selectors';

@Injectable({
  providedIn: 'root',
})
export class InterventionStoreService {
  private readonly store: Store<AppState>;

  constructor(store: Store<AppState>) {
    this.store = store;
  }

  @validate
  getCurrentIntervention(@required organizationId: string): Observable<string> {
    const currentIntervention$ = this.store.pipe(
      select(selectCurrentInterventionByOrganizationId, { id: organizationId })
    );
    return currentIntervention$;
  }

  @validate
  setCurrentIntervention(@required organizationId: string, @required interventionId: string) {
    this.store.dispatch(new SetCurrentInterventionAction(organizationId, interventionId));
  }

  @validate
  addOrganizationIntervention(@required organizationId: string, @required intervention: Intervention) {
    this.store.dispatch(new SetOrganizationInterventionAction(organizationId, intervention));
  }

  @validate
  getOrganizationInterventions(@required organizationId: string): Observable<Intervention[]> {
    const interventions$ = this.store.pipe(
      select(selectOrganizationInterventionsByOrganizationId, { id: organizationId })
    );
    return interventions$;
  }

  @validate
  setOrganizationInterventions(@required organizationId: string, @required interventions: Intervention[]) {
    this.store.dispatch(new SetOrganizationInterventionsAction(organizationId, interventions));
  }

  @validate
  getInterventionById(@required interventionId: string): Observable<Intervention> {
    const intervention$ = this.store.pipe(select(selectInterventionById, { id: interventionId }));
    return intervention$;
  }

  @validate
  updateIntervention(@required organizationId: string, @required intervention: Intervention) {
    this.store.dispatch(new UpdateInterventionAction(organizationId, intervention));
  }

  // TODO: Unused, remove in future
  // @validate
  // getIntervention(@required interventionId: string): Observable<Intervention> {
  //   const intervention$ = this.store.pipe(select(selectInterventionById, { id: interventionId }));
  //
  //   return intervention$;
  // }
}
