/**
 * MidGard API
 * MidGard API summary
 *
 * OpenAPI spec version: 2.0
 * Contact: contact@midgard-ai.co
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface AccessCodeCreationDto { 
    /**
     * The ObjectId of the Organization
     */
    defaultOrganization: string;
    /**
     * friendly user name
     */
    name?: string;
    /**
     * number of days
     */
    duration: number;
    /**
     * The ObjectId array of the Intervention
     */
    interventions?: Array<string>;
    /**
     * code give access to all drone or only those assigned to the interventions
     */
    droneAccess: AccessCodeCreationDto.DroneAccessEnum;
}
export namespace AccessCodeCreationDto {
    export type DroneAccessEnum = 'all' | 'intervention';
    export const DroneAccessEnum = {
        All: 'all' as DroneAccessEnum,
        Intervention: 'intervention' as DroneAccessEnum
    };
}