import MapboxDraw from '@mapbox/mapbox-gl-draw';
import mapboxgl from 'mapbox-gl';
import * as turf from '@turf/turf';
import AnnotationsHelpers from './annotations-helpers';
import { CircleMode, DragCircleMode, DirectMode, SimpleSelectMode } from 'mapbox-gl-draw-circle';
import _ from 'lodash';
import { tdrawConfig } from '../tdraw.config';
import { Feature, FeatureCollection } from '@turf/turf';
import { Modes as ConeRotateModes } from './cone-rotate-mode';
import { AnnotationType } from '../models/annotation-type.enum';
import { AnnotationCategory } from '../models/annotation-category.enum';
import { MapIconsService } from '../services/map-icons.service';
import { MapManager } from './map-manager';
import { GeometryType } from '../models/geometry-type.enum';
import { FeatureProperty } from '../models/feature-property.enum';
import { CircleType } from '../models/circle-type.enum';
import { ServiceLocator } from 'src/app/global/locator.service';

const DrawCircleModes = {
  draw_circle: CircleMode,
  drag_circle: DragCircleMode,
  direct_select: DirectMode,
  simple_select: SimpleSelectMode,
};

/**
 * MapDrawManager handles MapboxDraw logic
 *
 * Examples of responsabilities:
 * - Drawing on the Map (from or to a Mapbox Source)
 * - Editing Features (Annotations) properties
 * - Custom Features creation (Cone, Circle)
 */
export class MapDrawManager extends MapManager {
  private draw: MapboxDraw;
  private currentIconKey: string;

  protected mapIconsService: MapIconsService;

  constructor(protected map: mapboxgl.Map) {
    super(map);
    this.mapIconsService = ServiceLocator.injector.get(MapIconsService);
  }

  private initMapboxDrawInstance(styles?: any) {
    this.stopDrawing();

    this.draw = new MapboxDraw({
      userProperties: true,
      controls: {
        line_string: false,
        polygon: false,
        trash: false,
        combine_features: false,
        uncombine_features: false,
        point: false,
      },
      styles: styles ?? AnnotationsHelpers.getRegularDrawStyles(),
      modes: {
        ...MapboxDraw.modes,
        ...DrawCircleModes,
        ...ConeRotateModes,
      },
    });

    this.map.addControl(this.draw);
  }

  // Method Override
  navigateThroughFeatures(features: mapboxgl.MapboxGeoJSONFeature[]): mapboxgl.MapboxGeoJSONFeature | null {
    /*
      Because we hide the mapboxgl source when we edit a feature in Mapbox Draw, that means
      the initial mapboxgl source corresponding to the currently edited feature is not listed in the
      map.queryRenderedFeatures result, and so we can't apply MapService@navigateThroughFeatures logic.

      In order to solve this problem, we add the feature (which is inside the hidden mapboxgl source)
      to the currently queried list of features, allowing the logic to be applicable anyway.

      This addition should not causes issues because this feature should ends up beeing returned
      by the method only if it is the only Annotation present in the list of queried features,
      while it should conserve initial order and structure of the "navigated" array of features.
    */

    // Currently selected feature as in MapboxGL (not draw)
    const currentFeature = this.previous.features[this.previous.index];

    if (currentFeature) {
      let clickedOnCurrentlyEditedFeature = false;
      for (let feature of features) {
        if (feature.source.startsWith('mapbox-gl-draw')) {
          clickedOnCurrentlyEditedFeature = true;
          break;
        }
      }

      // Do the addition if the newly "clicked" list of features contains the current Mapbox Draw feature
      if (clickedOnCurrentlyEditedFeature) {
        features.push(currentFeature);
      }
    }

    return super.navigateThroughFeatures(features);
  }

  getCurrentlyDrawnFeature(): Feature {
    return this.draw.getSelected().features?.[0] as any;
  }

  updateSourceWithDrawnData(sourceId: string) {
    const data = this.getDrawnData();
    this.updateSource(data, sourceId);
  }

  /**
   * Load data from a Mapbox Source into MapboxDraw and start drawing.
   * Attachs MapboxDraw instance to the Map, enter into MapboxDraw mode.
   * @param sourceId string
   */
  startDrawingFromSource(sourceId: string) {
    // Hide Mapbox Source to display only MapboxDraw source.
    this.hideSource(sourceId);
    const initialSourceData = this.getAnnotationSourceData(sourceId) as any;

    // Change applied style according to Feature type, if any
    const feature = initialSourceData?.features?.[0];
    const featureType = feature.geometry.type;
    const isCone = feature.properties?.[FeatureProperty.IsCone];
    const iconKey = feature.properties?.[FeatureProperty.Icon];

    let styles = null;
    if (isCone) {
      styles = AnnotationsHelpers.getConeDrawStyles();
    } else if (featureType === GeometryType.LineString && iconKey) {
      styles = AnnotationsHelpers.getLinePatternDrawStyles(iconKey);
    }
    this.initMapboxDrawInstance(styles);

    // If the source already contains a feature, load it and pre-select it
    const featureId = feature.id;
    if (featureId) {
      this.draw.set(initialSourceData);

      // Make Polygons and LineStrings directly selected
      if (featureType === GeometryType.LineString || featureType === GeometryType.Polygon) {
        this.draw.changeMode('direct_select', {
          featureId: featureId,
        });
      } else {
        this.draw.changeMode('simple_select', {
          featureIds: [featureId],
        });
      }

      // Rotate mode by default for Cones
      if (isCone) {
        this.coneRotateMode();
      }
    }
  }

  /**
   * Stop drawing, exit MapboxDraw mode.
   * Detachs MapboxDraw instance from the Map.
   */
  stopDrawing() {
    if (this.map.hasControl(this.draw)) {
      this.map.removeControl(this.draw);
    }
  }

  getDrawnData(): FeatureCollection {
    return this.draw.getAll() as FeatureCollection;
  }

  setFeatureProperty(feature: Feature, property: FeatureProperty, value: any) {
    // Some property values need to be numeric
    if ([FeatureProperty.Opacity, FeatureProperty.Size, FeatureProperty.Rotation].includes(property)) {
      value = +value;
    }

    // Set the property to the feature
    this.draw.setFeatureProperty(feature.id.toString(), property, value);

    // Workaround to force feature style refresh
    if (
      [
        FeatureProperty.Color,
        FeatureProperty.Opacity,
        FeatureProperty.Size,
        FeatureProperty.Rotation,
        FeatureProperty.Trigram,
        FeatureProperty.Icon,
      ].includes(property)
    ) {
      this.draw.add(this.draw.get(feature.id.toString()));
    }
  }

  handleFeatureCreation(feature: Feature) {
    // Set general default properties
    const allDefaults = tdrawConfig.defaultFeatureProperties;
    for (let property in allDefaults) {
      this.setFeatureProperty(feature, <FeatureProperty>property, allDefaults[property]);
    }

    // Set type default properties
    const featureType = feature.geometry.type;
    const typeDefaults = tdrawConfig.defaultDrawFeatureProperties[featureType] ?? [];
    for (let property in typeDefaults) {
      this.setFeatureProperty(feature, <FeatureProperty>property, typeDefaults[property]);
    }

    // Set icon for Point type
    if ((featureType === GeometryType.Point || featureType === GeometryType.LineString) && this.currentIconKey) {
      this.setFeatureProperty(feature, FeatureProperty.Icon, this.currentIconKey);
    }
  }

  getAnnotationType(feature: Feature): AnnotationType {
    const featureType = feature.geometry.type.toString();
    if (featureType === 'MultiLineString') {
      return AnnotationType.Cone;
    }
    return featureType.toLowerCase() as AnnotationType;
  }

  getAnnotationCategory(feature: Feature): AnnotationCategory {
    const featureType = feature.geometry.type.toString();
    if (featureType !== 'Point') {
      return AnnotationCategory.Shapes;
    }

    const iconKey = feature.properties?.icon;
    if (iconKey) {
      const mapIcon = this.mapIconsService.getMapIcon(iconKey);
      if (mapIcon) {
        return mapIcon.category;
      }
    }

    return AnnotationCategory.Others;
  }

  drawIconMode(iconKey: string) {
    this.currentIconKey = iconKey;
    this.initMapboxDrawInstance();
    this.draw.changeMode('draw_point');
  }

  drawLineMode(patternKey?: string) {
    this.currentIconKey = patternKey ?? null;
    const styles = this.currentIconKey ? AnnotationsHelpers.getLinePatternDrawStyles(this.currentIconKey) : null;
    this.initMapboxDrawInstance(styles);
    this.draw.changeMode('draw_line_string');
  }

  drawPolygonMode() {
    this.initMapboxDrawInstance();
    this.draw.changeMode('draw_polygon');
  }

  coneRotateMode() {
    this.draw.changeMode('cone_rotate_mode' as any);
  }

  coneMoveMode() {
    this.draw.changeMode('simple_select', {
      featureIds: this.draw.getSelectedIds(),
    });
  }

  getDrawMode(): string {
    return this.draw.getMode();
  }

  createCircle(lat: number, lng: number, type: CircleType) {
    const center = [lng, lat];
    const properties = {
      ...tdrawConfig.defaultFeatureProperties,
      ...tdrawConfig.defaultCustomFeatureProperties[type],
      [FeatureProperty.IsCircle]: true,
      [FeatureProperty.Center]: center,
    };
    const circle = turf.circle(center, properties[FeatureProperty.RadiusInKm]);
    circle.id = this.guidService.generate();
    circle.properties = properties;
    return circle;
  }

  changeDrawCircleRadius(radiusInKm: number): Feature {
    // Access the currently drawn circle
    const featureCollection = this.draw.getSelected();
    const feature: any = featureCollection.features[0];
    if (!feature?.properties?.[FeatureProperty.IsCircle]) {
      return;
    }

    // Create a new circle with new radius
    const properties = {
      ...feature.properties,
      [FeatureProperty.RadiusInKm]: radiusInKm,
    };
    const center = properties.center;
    const circle = turf.circle(center, radiusInKm);
    circle.id = feature.id;
    circle.properties = properties;

    // Set the newly created circle as drawn data
    featureCollection.features = [circle];
    this.draw.set(featureCollection);
    return circle;
  }

  createCone(lat: number, lng: number, wind: number): Feature {
    const mouse = [lng, lat];
    const mousePoint = turf.point(mouse);

    // Linear calculation
    const metersByMinForOnekmh = 0.5;
    const metersFor3Hours = wind <= 100 ? metersByMinForOnekmh * wind * 180 : (wind * 50 - 2000) * 3; // Non-linear stuff workaround

    // Get length information according to wind speed
    const middleLength = metersFor3Hours / 1000;
    const step = middleLength / 12;
    const sideLength = middleLength * 0.8;
    const stepLength = 0.0056 * wind;

    // Base
    let topPoint = turf.destination(mousePoint, middleLength, 0);
    let leftPoint = turf.destination(mousePoint, sideLength, -22.5);
    let rightPoint = turf.destination(mousePoint, sideLength, 22.5);

    let middleLine = turf.lineString([mouse, turf.getCoord(topPoint)]);
    let outerCone = turf.lineString([turf.getCoord(leftPoint), mouse, turf.getCoord(rightPoint)]);

    // Steps
    let stepList = [1, 2, 3, 5, 6, 7, 9, 10, 11];
    let stepLinesCoords = [];
    let stepStartOffset = turf.destination(mousePoint, stepLength / 2, -90);

    for (let i = 0; i < stepList.length; i++) {
      let ratio = stepList[i];
      let stepStart = turf.destination(stepStartOffset, step * ratio, 0);
      let stepEnd = turf.destination(stepStart, stepLength, 90);
      let stepLine = turf.lineString([turf.getCoord(stepStart), turf.getCoord(stepEnd)]);
      stepLinesCoords.push(turf.getCoords(stepLine));
    }

    // Arcs
    let arc1 = turf.lineArc(mousePoint, 4 * step, -22.50001, 22.50001);
    let arc2 = turf.lineArc(mousePoint, 8 * step, -22.50001, 22.50001);

    // Arrow
    let leftArrowPoint = turf.destination(topPoint, stepLength * 3, -135);
    let rightArrowPoint = turf.destination(topPoint, stepLength * 3, 135);
    let arrow = turf.lineString([
      turf.getCoord(leftArrowPoint),
      turf.getCoord(topPoint),
      turf.getCoord(rightArrowPoint),
    ]);

    // Cone
    return {
      id: this.guidService.generate(),
      type: 'Feature',
      properties: {
        ...tdrawConfig.defaultFeatureProperties,
        ...tdrawConfig.defaultCustomFeatureProperties['Cone'],
        [FeatureProperty.IsCone]: true,
        [FeatureProperty.Wind]: wind,
      },
      geometry: {
        coordinates: [
          turf.getCoords(middleLine),
          turf.getCoords(outerCone),
          turf.getCoords(arc1),
          turf.getCoords(arc2),
          turf.getCoords(arrow),
          ...stepLinesCoords,
        ],
        type: GeometryType.MultiLineString,
      },
    };
  }

  changeDrawnConeWind(wind: number): Feature {
    // Access the currently drawn cone
    const featureCollection = this.draw.getSelected();
    const feature: any = featureCollection.features[0];
    if (!feature?.properties?.[FeatureProperty.IsCone]) {
      return;
    }

    // Get current cone center and bearing
    const middleLine = feature.geometry.coordinates[0];
    const center = middleLine[0];
    const angle = turf.bearing(center, middleLine[1]);

    // Create a new cone based on current center and new wind
    const newCone = this.createCone(center[1], center[0], wind);
    feature.geometry = newCone.geometry;
    feature.properties[FeatureProperty.Wind] = wind;

    // Apply same rotation to the new cone
    const rotatedFeature = turf.transformRotate(feature, angle, {
      pivot: center,
      mutate: false,
    });

    // Set the newly created cone as drawn data
    featureCollection.features = [rotatedFeature];
    this.draw.set(featureCollection);
    return rotatedFeature;
  }
}
