import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import {
  FeatureModule,
  OrganizationDto,
  OrganizationGroupDto,
  OrganizationGroupService,
  OrganizationsService,
  UserDto,
} from 'src/api';
import { MID_RIGHT_PANEL_DATA } from 'src/app/midgard-controls/services/dom.service';
import { MidRightPanelService } from 'src/app/midgard-controls/services/mid-right-panel.service';
import { map, some } from 'lodash';
import { Subscription } from 'rxjs';
import { Unsubscribe } from 'src/app/core/decorators';

const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*(\.\w{2,})+$/;

class RightPanelData {
  org: OrganizationDto;
}

@Unsubscribe()
@Component({
  selector: 'app-new-organization-user',
  templateUrl: './new-organization-user.component.html',
  styleUrls: ['./new-organization-user.component.scss'],
})
export class NewOrganizationUserComponent implements OnInit, OnDestroy {
  org: OrganizationDto;
  newUser: UserDto;

  allGroups: OrganizationGroupDto[];
  selectedGroups: OrganizationGroupDto[];
  features: FeatureModule[];

  private groupsSubscriptions: Subscription;
  private organizationFeaturesSubscription: Subscription;

  @ViewChild('newOrganizationUserForm', { static: false })
  newOrganizationUserForm: NgForm;
  isMultipleGroupMode: boolean = false;

  constructor(
    @Inject(MID_RIGHT_PANEL_DATA) private readonly rightPanelData: RightPanelData,
    private readonly midRightPanelService: MidRightPanelService,
    private readonly organizationsService: OrganizationsService,
    private readonly organizationGroupService: OrganizationGroupService
  ) {
    this.org = rightPanelData.org;
    this.newUser = {
      id: '',
      fullname: '',
      email: '',
      phoneNumber: '',
    };
    this.allGroups = [];
    this.selectedGroups = [];
    this.features = [];
  }

  ngOnInit(): void {
    this.loadGroups();
    this.loadOrganizationsFeatures();
  }

  ngOnDestroy(): void {}

  onSave(): void {
    this.midRightPanelService.close({ user: this.newUser, groups: this.selectedGroups });
  }

  onClose(): void {
    this.midRightPanelService.close();
  }

  onEmailChange(event): void {
    if (!emailRegex.test(event)) {
      this.newOrganizationUserForm.form.controls.userEmail.setErrors({ invalid: true });
    }
  }

  onGroupsChanged(groups: OrganizationGroupDto[]) {
    this.selectedGroups = groups;
  }

  private loadOrganizationsFeatures() {
    this.organizationFeaturesSubscription = this.organizationsService
      .organizationControllerGetOrganizationFeatures(this.org.id)
      .subscribe((features) => {
        this.features = features;
        this.isMultipleGroupMode = this.getGroupMode(features);
      });
  }

  private loadGroups() {
    this.groupsSubscriptions = this.organizationGroupService
      .orgOrganizationGroupControllerGetOrgGroups(this.org.id)
      .subscribe((groups) => {
        const filteredGroups = groups.filter((group) => group.organization === this.org.id);
        this.allGroups = filteredGroups;
      });
  }

  private getGroupMode(features: FeatureModule[]) {
    const featuresIds = map(features, (feature) => feature.featureId);
    const isGroupModeMultiple = some(featuresIds, (featureId) => featureId === 'groups');
    return isGroupModeMultiple;
  }
}
