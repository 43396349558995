<div class="input-container" [class.input-label]="!!label">
  <label *ngIf="label" [class.required]="required">
    {{ label }}
  </label>
  <ng-container *ngIf="!matAutocomplete; else elseAutocomplete">
    <input
      [placeholder]="placeholder"
      [type]="type"
      [(ngModel)]="value"
      (ngModelChange)="onValueChange()"
      #midInput="ngModel"
      [name]="name"
      [disabled]="disabled"
      [required]="required"
      [class.default]="size === MidInputSize.default"
      [class.wide]="size === MidInputSize.wide"
      [autocomplete]="autocomplete"
      [email]="type === 'email'"
      [style.padding-left]="iconLeft ? '40px' : '4px'"
    />
  </ng-container>
  <ng-template #elseAutocomplete>
    <input
      [placeholder]="placeholder"
      [type]="type"
      [(ngModel)]="value"
      (ngModelChange)="onValueChange()"
      #midInput="ngModel"
      [name]="name"
      [disabled]="disabled"
      [required]="required"
      [class.default]="size === MidInputSize.default"
      [class.wide]="size === MidInputSize.wide"
      [autocomplete]="autocomplete"
      [matAutocomplete]="matAutocomplete"
      [style.padding-left]="iconLeft ? '40px' : '4px'"
    />
  </ng-template>
  <ng-content></ng-content>

  <div *ngIf="fasType" [class]="iconLeft ? 'fas-icon mr-auto' : 'fas-icon ml-auto'" (click)="onFasChange()">
    <i class="fas {{ fasType }}" #tooltip="matTooltip" [matTooltip]="helpMessage"></i>
  </div>
</div>
