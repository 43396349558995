import proj4 from 'proj4';
import defs from 'proj4js-definitions';
import { tdrawConfig } from '../tdraw.config';

/**
 * A list of helper functions
 */
export default class CoordinatesHelpers {
  /**
   * Register a list of projections used by proj4
   */
  static registerProjections() {
    // Be sure proj4 knows all projections
    proj4.defs(defs);
  }

  /**
   * Get UTM zone corresponding to specified lat/lng
   */
  static getUtmZone(lat: number, lng: number) {
    // Handle special areas (for Svalbard and Norway)
    if (lat >= 72 && lat < 84) {
      if (lng >= 0 && lng < 9) {
        return 31;
      }
      if (lng >= 9 && lng < 21) {
        return 33;
      }
      if (lng >= 21 && lng < 33) {
        return 35;
      }
      if (lng >= 33 && lng < 42) {
        return 37;
      }
    }
    if (lat >= 56 && lat < 64 && lng >= 3 && lng <= 12) {
      return 32;
    }
    return Math.floor((lng + 180) / 6) + 1;
  }

  /**
   * Get UTM EPSG corresponding to specified lat/lng
   */
  static getUtmEPSG(lat: number, lng: number) {
    const zone = CoordinatesHelpers.getUtmZone(lat, lng);
    let code = 32600;
    code += zone;
    if (lat < 0) {
      code += 100;
    }
    return code;
  }

  /**
   * Project lat/lng to a specific coordinates system
   */
  static project(lat: number, lng: number, toEPSG: number | string) {
    const epsg = 'EPSG:' + toEPSG;
    return proj4('EPSG:4326', epsg, [lng, lat]);
  }

  /**
   * Transform a coordinate from EPSG:4326 (WGS84) to EPSG:27572 (Lambert II)
   * @param coord
   */
  static coordFromWGS84To27572(coord: number[]): number[] {
    return proj4('EPSG:4326', 'EPSG:27572', coord);
  }

  /**
   * Transform a coordinate from EPSG:27572 (Lambert II) to EPSG:4326 (WGS84)
   * @param coord
   */
  static coordFrom27572ToWGS84(coord: number[]): number[] {
    return proj4('EPSG:27572', 'EPSG:4326', coord);
  }

  /**
   * Transform an array of coords from EPSG:27572 to EPSG:4326 (WGS84)
   * Simplify the output to 6 decimals precision
   * @param coords
   */
  static coordsArrayFrom27572ToWGS84(coords: number[][]): number[][] {
    let out = [];
    coords.forEach((e: number[]) => {
      let coord = this.coordFrom27572ToWGS84(e);
      out.push([coord[0].toFixed(6), coord[1].toFixed(6)]);
    });
    return out;
  }

  /**
   * Check a DFCI string is valid
   * @param dfci
   */
  static isValidDFCIString(dfci: string): boolean {
    /*
      The regex matches the following:

      [X][X]
      [X][X][0,2,4,6,8][0,2,4,6,8]
      [X][X][0,2,4,6,8][0,2,4,6,8][Y][0-9]
      [X][X][0,2,4,6,8][0,2,4,6,8][Y][0-9][1-5]

      Where
      X => A B C D E F G H K L M N
      Y => A B C D E F G H K L
    */
    const reg = tdrawConfig.dfciRegex;
    return reg.test(dfci);
  }

  /**
   * Return true if coord is in DFCI grid area
   * @param coord [x, y] array in specified projection
   * @param projection projection used by coord
   */
  static isValidDFCICoord(coord: number[], projection: string): boolean {
    if (projection) {
      coord = proj4(projection, 'EPSG:27572', coord);
    }
    // Test extent
    if (0 > coord[0] || coord[0] > 1200000) return false;
    if (1600000 > coord[1] || coord[1] > 2700000) return false;
    return true;
  }

  /**
   * Return coordinates in EPSG:27572 from a DFCI index
   * @param index DFCI index
   */
  static fromDFCI(index: string) {
    const indexLength = index.length;

    // Level 0
    let step = 100000;
    let x = index.charCodeAt(0) - 65;
    let y = index.charCodeAt(1) - 65;
    x = (x < 8 ? x : x - 2) * step;
    y = (y < 8 ? y : y - 2) * step + 1500000;
    if (indexLength === 2) {
      return [x + step / 2, y + step / 2];
    }

    // Level 1
    step /= 5;
    x += (+index.charAt(2) / 2) * step;
    y += (+index.charAt(3) / 2) * step;
    if (indexLength === 4) {
      return [x + step / 2, y + step / 2];
    }

    // Level 2
    let x0 = index.charCodeAt(4) - 65;
    step /= 10;
    x += (x0 < 8 ? x0 : x0 - 2) * step;
    y += +index.charAt(5) * step;
    if (indexLength === 6) {
      return [x + step / 2, y + step / 2];
    }

    // Level 3
    switch (index.charAt(6)) {
      case '1':
        return [x + step / 4, y + (3 * step) / 4];
      case '2':
        return [x + (3 * step) / 4, y + (3 * step) / 4];
      case '3':
        return [x + (3 * step) / 4, y + step / 4];
      case '4':
        return [x + step / 4, y + step / 4];
    }
    return [x + step / 2, y + step / 2];
  }

  /**
   * Get DFCI value from coordinates
   * @param coord [x, y] array in specified projection
   * @param projection projection used by coord
   * @param level DFCI grid precision
   */
  static toDFCI(coord: number[], projection: string, level: number = 3): string {
    if (projection) {
      coord = proj4(projection, 'EPSG:27572', coord);
    }
    let x = coord[0];
    let y = coord[1];
    let s = '';
    // Level 0
    let step = 100000;
    s +=
      String.fromCharCode(65 + Math.floor((x < 800000 ? x : x + 200000) / step)) +
      String.fromCharCode(65 + Math.floor((y < 2300000 ? y : y + 200000) / step) - 1500000 / step);

    if (level === 0) return s;
    // Level 1
    let step1 = 100000 / 5;
    s += 2 * Math.floor((x % step) / step1);
    s += 2 * Math.floor((y % step) / step1);
    if (level === 1) return s;
    // Level 2
    let step2 = step1 / 10;
    let x0 = Math.floor((x % step1) / step2);
    s += String.fromCharCode(65 + (x0 < 8 ? x0 : x0 + 2));
    s += Math.floor((y % step1) / step2);
    if (level === 2) return s;
    // Level 3
    let x3 = Math.floor((x % step2) / 500);
    let y3 = Math.floor((y % step2) / 500);
    if (x3 < 1) {
      if (y3 > 1) s += '.1';
      else s += '.4';
    } else if (x3 > 2) {
      if (y3 > 1) s += '.2';
      else s += '.3';
    } else if (y3 > 2) {
      if (x3 < 2) s += '.1';
      else s += '.2';
    } else if (y3 < 1) {
      if (x3 < 2) s += '.4';
      else s += '.3';
    } else {
      s += '.5';
    }
    return s;
  }

  /**
   * Convert degree from DD to DMS for GPS coordinates (latitude and longitude), e.g.,
   * - from [47.3199270, -0.5514545] to [47° 19' 11.737" N, 1° 26' 54.764"] W
   * @param dms
   */
  static convertDDToDMS(angle) {
    if (!angle) {
      return `0° 0' 0"`;
    }
    let d = Math.floor(angle);
    const minfloat = (angle - d) * 60;
    let m = Math.floor(minfloat);
    const secfloat = (minfloat - m) * 60;
    let s = secfloat;
    // After rounding, the seconds might become 60
    if (s > 60) {
      m++;
      s -= 60;
    }
    if (m === 60) {
      d++;
      m = 0;
    }
    if (d < 0) {
      d *= -1;
    }
    return `${d}° ${m}' ${s.toFixed(3)}"`;
  }

  /**
   * Convert geographical coordinates (latitude and longitude) from DMS to DD
   * @param dms
   */
  static convertGeoDMSToDD(dmsGeoCoord: string) {
    // ParseDMS
    const dms = dmsGeoCoord.split(/[^\d\w\.]+/);
    // convert DMS to DD
    let lng: number = CoordinatesHelpers.convertDMSToDD(+dms[0], +dms[1], +dms[2], dms[3]);
    let lat: number = CoordinatesHelpers.convertDMSToDD(+dms[4], +dms[5], +dms[6], dms[7]);
    return [lat.toFixed(6), lng.toFixed(6)];
  }

  /**
   * Convert DMS to DD
   * @param degrees
   * @param minutes
   * @param seconds
   * @param direction
   * @returns
   */
  static convertDMSToDD(degrees: number, minutes: number, seconds: number, direction: string) {
    let dd = degrees + minutes / 60 + seconds / 3600;
    // Don't do anything for N or E
    if (direction === 'S' || direction === 'W') {
      dd *= -1;
    }
    return dd;
  }

  /**
   * Check if DMS (Degrees Minutes And Seconds) string is valid, e.g.,
   * - 42°36'53.38"N20°18'32.18"E
   * - 42° 36' 53.38'' N 20° 18' 32.18'' E
   * @param value
   * @returns
   */
  static isValidDMSString(value: string): boolean {
    const DMSRegex =
      /^([0-8]?\d)(°|\s)(\s*)([0-5]?\d)('|\s)(\s*)([0-5]?\d(\.\d{1,4})?)("|'')?(\s*)[NEWSnews](\s*)([0-8]?\d)(°|\s)(\s*)([0-5]?\d)('|\s)(\s*)([0-5]?\d(\.\d{1,4})?)("|'')?(\s*)[NEWSnews]$/;
    return DMSRegex.test(value);
  }

  /**
   * Check if DD (Decimal Degrees) string is valid
   * @param value
   * @returns
   */
  static isValidDDString(value: string): boolean {
    const DDRegex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)(,\s*)[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
    return DDRegex.test(value);
  }

  /**
   * Check if DD string or DMS are valid
   * @param value
   * @returns
   */
  static isValidDegrees(value: string): boolean {
    if (this.isValidDDString(value)) return true;
    if (this.isValidDMSString(value)) return true;
    return false;
  }
}
