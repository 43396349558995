<app-mid-icon-button type="secondary" [matMenuTriggerFor]="interventionMenu">
  <i class="fas fa-ellipsis-v fa-sm mid-btn-icon"></i>
</app-mid-icon-button>
<mat-menu #interventionMenu="matMenu" class="mid-menu">
  <div class="mb-2 ml-2">{{ 'ASSIGNED_TO' | translate }}: {{ mapState.name }}</div>
  <div class="mid-menu-item" (click)="onRename()">
    <i class="fas fa-copy mr-2 mid-menu-item-icon"></i>
    <span>{{ 'RENAME' | translate }}</span>
  </div>
  <div *ngIf="!mapState.publishedAt" class="mid-menu-item" (click)="onPublish()">
    <i class="fas fa-share mr-2 mid-menu-item-icon"></i>
    <span>{{ 'PUBLISH' | translate }}</span>
  </div>
  <div class="mid-menu-item" (click)="onShare()" *ngIf="mapState.publishedAt !== null">
    <i class="fas fa-share mr-2 mid-menu-item-icon"></i>
    <span>{{ 'SHARE' | translate }}</span>
  </div>
  <div class="mid-menu-item" (click)="onDuplicate()">
    <i class="fas fa-copy mr-2 mid-menu-item-icon"></i>
    <span>{{ 'TDRAW.DUPLICATE_AND_MODIFY' | translate }}</span>
  </div>
  <!-- <div class="mid-menu-item" (click)="onDownload()">
    <i class="fas fa-download mr-2 mid-menu-item-icon"></i>
    <span>{{ 'DOWNLOAD' | translate }}</span>
  </div> -->
  <div class="mid-menu-item" (click)="onDeleteMapState()">
    <i class="fas fa-times mr-2 mid-menu-item-icon mid-menu-item-red"></i>
    <span class="mid-menu-item-red">{{ 'REMOVE' | translate }}</span>
  </div>
</mat-menu>
