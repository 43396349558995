import { UntypedFormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export class MatErrorStateMatcherValidator implements ErrorStateMatcher {
  hasError: boolean;

  constructor(hasError: boolean) {
    this.hasError = hasError;
  }

  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return this.hasError;
  }
}
