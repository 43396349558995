import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DroneStreamPreviewComponent } from './drone-stream-preview/drone-stream-preview.component';
import { DroneStreamPlayerDirective } from './drone-stream-player.directive';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CoreModule } from 'src/app/core/core.module';
import { InterventionCommonModule } from 'src/app/intervention/common/intervention-common.module';
import { DroneStreamPlayerComponent } from './drone-stream-player/drone-stream-player.component';
import { DroneStreamShareButtonComponent } from './drone-stream-share-button/drone-stream-share-button.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SelectDroneToAssignComponent } from './select-drone-to-assign/select-drone-to-assign.component';
import { MatSelectModule } from '@angular/material/select';
import { DroneCardComponent } from './drone-card/drone-card.component';
import { DroneListComponent } from './drone-list/drone-list.component';
import { DronesComponent } from './drones/drones.component';
import { MidgardControlsModule } from 'src/app/midgard-controls/midgard-controls.module';
import { DronesDashboardBlockComponent } from './drones-dashboard-block/drones-dashboard-block.component';
import { ClipboardModule } from 'ngx-clipboard';
import { DroneSingleStreamComponent } from './drone-single-stream/drone-single-stream.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { CreateDroneRightPanelModule } from '../dialogs/create-drone-right-panel/create-drone-right-panel.module';
import { DroneShareLinkComponent } from '../dialogs/drone-share-link/drone-share-link.component';
import { DronePropertiesComponent } from '../dialogs/drone-properties/drone-properties.component';
import { AssociateDroneToInterventionComponent } from '../dialogs/associate-drone-to-intervention/associate-drone-to-intervention.component';
import { QRCodeModule } from 'angularx-qrcode';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@NgModule({
  declarations: [
    DroneStreamPreviewComponent,
    DroneStreamPlayerDirective,
    DroneStreamPlayerComponent,
    DroneStreamShareButtonComponent,
    SelectDroneToAssignComponent,
    DroneCardComponent,
    DroneListComponent,
    DronesComponent,
    DronesDashboardBlockComponent,
    DroneSingleStreamComponent,
    DroneShareLinkComponent,
    DronePropertiesComponent,
    AssociateDroneToInterventionComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    CoreModule,
    InterventionCommonModule,
    MatTooltipModule,
    MatSelectModule,
    MidgardControlsModule,
    ClipboardModule,
    MatDialogModule,
    MatMenuModule,
    CreateDroneRightPanelModule,
    QRCodeModule,
    MatAutocompleteModule,
  ],
  exports: [
    DroneStreamPreviewComponent,
    DroneStreamPlayerDirective,
    DroneStreamPlayerComponent,
    DroneCardComponent,
    DroneListComponent,
    DronesComponent,
    DronesDashboardBlockComponent,
    DroneSingleStreamComponent,
  ],
})
export class DroneCommonModule {}
