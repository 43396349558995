import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocationSearchResultType } from '../models/location-search-result-type.enum';
import { LocationSearchResult } from '../models/location-search-result.model';
import CoordinatesHelpers from '../misc/coordinates-helpers';
import { TranslateService } from '@ngx-translate/core';
import { tdrawConfig } from '../tdraw.config';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  private currentSearch: LocationSearchResult[] = [];

  constructor(private httpClient: HttpClient, private translate: TranslateService) {}

  search(search: string, callback?: (data: LocationSearchResult[]) => void) {
    if (CoordinatesHelpers.isValidDDString(search)) {
      this.searchByDegrees(search, LocationSearchResultType.DD, callback);
    } else if (CoordinatesHelpers.isValidDMSString(search)) {
      this.searchByDegrees(search, LocationSearchResultType.DMS, callback);
    } else if (CoordinatesHelpers.isValidDFCIString(search.toUpperCase())) {
      this.searchByDFCI(search, callback);
    } else {
      this.searchByAddress(search, callback);
    }
  }

  /**************************
   * Search by address
   * @param search
   * @param callback
   **************************/
  private searchByAddress(search: string, callback?: (data: LocationSearchResult[]) => void) {
    this.currentSearch = [];

    this.httpClient
      .get(this.baseUrl(search))
      .toPromise()
      .then((data) => {
        const elements = this.parseSearchData(data);
        callback?.(elements);
      });
  }

  private parseSearchData(data) {
    const features = data.features;
    this.currentSearch = [];
    for (let i in features) {
      const feature = this.extractLocationApiMapboxCom(features[i], i);
      // const feature = this.extractLocationDataGouvFr(features[i], i);
      this.currentSearch.push(feature);
    }
    return this.currentSearch;
  }

  private baseUrl(search): string {
    return (
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${search}.json` +
      `?limit=5&proximity=ip&types=place%2Cpostcode%2Caddress&language=${this.translate.currentLang}&access_token=${tdrawConfig.mapAccessToken}`
    );
    // return 'https://api-adresse.data.gouv.fr/search/?limit=5&q=' + search;
  }

  /**
   * Specific to api.mapbox.com response
   * @param feature
   * @param index
   */
  private extractLocationApiMapboxCom(feature, index) {
    return new LocationSearchResult({
      title: feature.place_name,
      coordinates: feature.geometry.coordinates,
      type: LocationSearchResultType.ADDRESS,
      index: +index,
    });
  }

  /**
   * Specific to data.gouv.fr response
   * @param feature
   * @param index
   */
  // private extractLocationDataGouvFr(feature, index) {
  //   return new LocationSearchResult({
  //     title: feature.properties.label,
  //     coordinates: feature.geometry.coordinates,
  //     type: LocationSearchResultType.ADDRESS,
  //     index: +index,
  //   });
  // }

  /**************************
   * Search by DFCI
   * @param search
   * @param callback
   * @returns
   **************************/
  private searchByDFCI(search: string, callback?: (data: LocationSearchResult[]) => void) {
    this.currentSearch = [];
    search = search.toUpperCase();

    if (!CoordinatesHelpers.isValidDFCIString(search)) {
      callback?.(null);
      return;
    }

    const coord27572 = CoordinatesHelpers.fromDFCI(search);
    const coordinates = CoordinatesHelpers.coordFrom27572ToWGS84(coord27572) as [number, number];
    const result = new LocationSearchResult({
      title: search,
      coordinates: coordinates,
      type: LocationSearchResultType.DFCI,
      index: 0,
    });
    this.currentSearch = [result];
    callback?.(this.currentSearch);
  }

  /**
   * Search by degrees (GPS coordinates)
   * @param search
   * @param type
   * @param callback
   * @returns
   */
  private searchByDegrees(
    search: string,
    type: LocationSearchResultType,
    callback?: (data: LocationSearchResult[]) => void
  ) {
    this.currentSearch = [];
    let value: string[];

    if (type == LocationSearchResultType.DMS) value = CoordinatesHelpers.convertGeoDMSToDD(search);
    if (type == LocationSearchResultType.DD) value = search.split(',').reverse();

    if (!CoordinatesHelpers.isValidDegrees(search)) {
      callback?.(null);
      return;
    }

    const coordinates: [number, number] = [+value[0], +value[1]];
    const result = new LocationSearchResult({
      title: search,
      coordinates: coordinates,
      type: type,
      index: 0,
    });
    this.currentSearch = [result];
    callback?.(this.currentSearch);
  }
}
