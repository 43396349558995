import { Intervention } from './../../autogenerated/model';
import { cloneDeep, forEach, map } from 'lodash';
import { Assert } from 'src/app/shared';
import {
  InterventionActions,
  InterventionActionType,
  SetCurrentInterventionAction,
  SetOrganizationInterventionAction,
  SetOrganizationInterventionsAction,
  UpdateInterventionAction,
} from './intervention.actions';
import { CoreActionTypes } from 'src/app/core/store';

export interface InterventionState {
  selectedByOrganizationId: StringMap<string>;
  allByInterventionId: StringMap<Intervention>;
  groupByOrganizationId: StringMap<Intervention[]>;
}

export const interventionInitialState: InterventionState = {
  selectedByOrganizationId: {},
  allByInterventionId: {},
  groupByOrganizationId: {},
};

export function interventionReducer(state: InterventionState, action: InterventionActions): InterventionState {
  Assert.isNotNull(action, 'action');

  switch (action.type) {
    case InterventionActionType.SetCurrentIntervention:
      return setCurrentIntervention(state, action);

    case InterventionActionType.SetOrganizationInterventions:
      return setOrganizationInterventions(state, action);

    case InterventionActionType.SetOrganizationIntervention:
      return setOrganizationIntervention(state, action);

    case InterventionActionType.UpdateIntervention:
      return updateIntervention(state, action);

    case CoreActionTypes.ResetState:
      return resetStateActionReducer();

    default:
      return state;
  }
}

export function setCurrentIntervention(
  state: InterventionState,
  action: SetCurrentInterventionAction
): InterventionState {
  Assert.isNotNull(state, 'state');
  Assert.isNotNull(action, 'action');

  const newState: InterventionState = {
    ...state,
    selectedByOrganizationId: {
      ...state.selectedByOrganizationId,
      [action.organizationId]: action.interventionId,
    },
  };

  return newState;
}

export function setOrganizationInterventions(
  state: InterventionState,
  action: SetOrganizationInterventionsAction
): InterventionState {
  Assert.isNotNull(state, 'state');
  Assert.isNotNull(action, 'action');

  forEach(action.interventions, (intervention: Intervention) => {
    state.allByInterventionId[intervention._id] = intervention;
  });

  const newState: InterventionState = {
    ...state,
    groupByOrganizationId: {
      ...state.groupByOrganizationId,
      [action.organizationId]: action.interventions,
    },
  };

  return newState;
}

export function setOrganizationIntervention(
  state: InterventionState,
  action: SetOrganizationInterventionAction
): InterventionState {
  Assert.isNotNull(state, 'state');
  Assert.isNotNull(action, 'action');

  state.allByInterventionId[action.intervention._id] = action.intervention;

  const organizationInterventions = state.groupByOrganizationId[action.organizationId];
  const newOrganizationInterventions = [...organizationInterventions, action.intervention];

  const newState: InterventionState = {
    ...state,
    groupByOrganizationId: {
      ...state.groupByOrganizationId,
      [action.organizationId]: newOrganizationInterventions,
    },
  };

  return newState;
}

export function updateIntervention(state: InterventionState, action: UpdateInterventionAction): InterventionState {
  Assert.isNotNull(state, 'state');
  Assert.isNotNull(action, 'action');

  state.allByInterventionId[action.intervention._id] = action.intervention;
  const interventions = map(state.groupByOrganizationId[action.organizationId], (intervention: Intervention) =>
    intervention._id === action.intervention._id ? action.intervention : intervention
  );

  const newState: InterventionState = {
    ...state,
    allByInterventionId: {
      ...state.allByInterventionId,
    },
    groupByOrganizationId: {
      ...state.groupByOrganizationId,
      [action.organizationId]: interventions,
    },
  };

  return newState;
}

export function resetStateActionReducer(): InterventionState {
  const newState: InterventionState = cloneDeep(interventionInitialState);

  return newState;
}
