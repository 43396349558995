import { BackendClientService } from '../../global/backend-client.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss'],
})
export class NewUserComponent implements OnInit {
  public newUser = { fullname: '', email: '', password: '' };

  constructor(private client: BackendClientService) {}

  ngOnInit() {}
  public createUser() {
    this.client.put('user', this.newUser).subscribe(() => {
      this.newUser = { fullname: '', email: '', password: '' };
    });
  }
}
