<!-- content -->
<div class="assign-intervention-header">
  <div class="assign-intervention-title">
    <span class="title">{{ 'INTERVENTION_SELECT_OR_CREATE' | translate }}</span>
  </div>
</div>

<div class="assign-intervention-body">
  <div class="w-100 select-intervention-combobox-block">
    <label>{{ 'INTERVENTION_NAME_ASSIGN' | translate }}</label>
    <app-mid-select-intervention-combobox
      (interventionSelected)="getSelectedIntervention($event)"
      [organizationId]="organizationId"
      [excludeClosed]="true"
    ></app-mid-select-intervention-combobox>
  </div>
</div>

<div class="d-flex justify-content-center pt-3 pb-3 mt-2">
  <app-mid-button
    class="ml-1 pr-2"
    type="primary"
    icon="fas fa-check"
    (action)="onSelectIntervention()"
    [disabled]="!selectedIntervention"
  >
    {{ 'INTERVENTION_SELECT' | translate }}
  </app-mid-button>
  <app-mid-button type="secondary" (action)="onCancel()">{{ 'COMMON_CANCEL' | translate }}</app-mid-button>
</div>
