import { OrganizationMedia } from 'src/app/autogenerated/model';
import { environment } from 'src/environments/environment';
import { GisManager } from './gis-manager';
import _ from 'lodash';
import mapboxgl, { CustomLayerInterface } from 'mapbox-gl';

import * as THREE from 'three';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { TextGeometry, TextGeometryParameters } from 'three/examples/jsm/geometries/TextGeometry';
import fontHelvetikerRegular from 'three/examples/fonts/helvetiker_regular.typeface.json';

export enum Model3dToolType {
  HeightMeasure = 1,
}

export const Model3dStore = {
  activeTool: null as Model3dToolType,
  toolbarIsVisible: false,

  toggleActiveTool: function (toolType: Model3dToolType) {
    if (this.activeTool === toolType) {
      this.disableActiveTool();
    } else {
      this.activeTool = toolType;
    }
  },

  disableActiveTool: function () {
    this.activeTool = null;
  },
};

export class Model3dLayer implements CustomLayerInterface {
  public type: 'custom' = 'custom';
  public renderingMode: '3d' = '3d';
  public id: string;

  private mtl: string;
  private obj: string;

  private canvas: HTMLCanvasElement;
  private map: mapboxgl.Map;
  private renderer: THREE.WebGLRenderer;
  private raycaster: THREE.Raycaster;
  private camera: THREE.PerspectiveCamera;
  private scene: THREE.Scene;

  private heightLine: THREE.Line = null;
  private heightLinePoints: THREE.Vector3[] = [];
  private heightText: THREE.Mesh = null;
  private fontParams: TextGeometryParameters;

  private mouseClickListener = null;
  private mouseMoveListener = null;

  constructor(private gisManager: GisManager, private image: OrganizationMedia) {
    const imageId = image._id;
    this.id = gisManager.Model3dLayerPrefix + imageId;
    this.mtl = environment.backend + '3dmodels/' + imageId + '/odm_textured_model_geo.mtl';
    this.obj = environment.backend + '3dmodels/' + imageId + '/odm_textured_model_geo.obj';
  }

  public onAdd(map: mapboxgl.Map, gl: WebGLRenderingContext): void {
    // Store important variables into this object
    this.canvas = map.getCanvas();
    this.map = map;
    this.camera = new THREE.PerspectiveCamera();
    this.scene = new THREE.Scene();
    this.raycaster = new THREE.Raycaster();

    // Create THREE.js lights, otherwise the model would be black
    const directionalLight = new THREE.DirectionalLight(0xffffff);
    directionalLight.position.set(0, -70, 100).normalize();
    this.scene.add(directionalLight);

    const directionalLight2 = new THREE.DirectionalLight(0xffffff);
    directionalLight2.position.set(0, 70, 100).normalize();
    this.scene.add(directionalLight2);

    // Load the model and associated textures
    const loader = new MTLLoader();
    loader.load(this.mtl, (materials) => {
      materials.preload();
      const objLoader = new OBJLoader();
      objLoader.setMaterials(materials);
      objLoader.load(
        this.obj,
        (object) => {
          this.scene.add(object);
        },
        (xhr) => {
          if (xhr.lengthComputable) {
            // Store percent of this task in the service
            const percent = Math.round((xhr.loaded / xhr.total) * 100);
            this.gisManager.loading3dModelsInfo.individualPercents[this.id] = percent;

            // Select the smallest percent
            this.gisManager.loading3dModelsInfo.percent = _.reduce(
              this.gisManager.loading3dModelsInfo.individualPercents,
              (prev, percent: number) => {
                return Math.min(prev, percent);
              },
              100
            );

            // Once completed, remove it from the listing
            if (percent == 100) {
              delete this.gisManager.loading3dModelsInfo.individualPercents[this.id];
            }
          }
        }
      );
    });

    // Use the Mapbox GL JS map canvas to render the THREE.js scene
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      context: gl,
      antialias: true,
    });
    this.renderer.autoClear = false;

    // Setup font used for text display
    const fontLoader = new FontLoader();
    const font = fontLoader.parse(fontHelvetikerRegular);
    this.fontParams = {
      font: font,
      size: 1,
      height: 0.1,
      curveSegments: 12,
      bevelEnabled: false,
      bevelThickness: 10,
      bevelSize: 1,
      bevelOffset: 0,
      bevelSegments: 5,
    };

    // Add listeners
    this.mouseClickListener = this.onMouseClick.bind(this);
    this.mouseMoveListener = this.onMouseMove.bind(this);
    this.canvas.addEventListener('click', this.mouseClickListener);
    this.canvas.addEventListener('mousemove', this.mouseMoveListener);
  }

  public onRemove(map: mapboxgl.Map, gl: WebGLRenderingContext): void {
    // When calling map.removeLayer, clean some stuff
    this.canvas.removeEventListener('click', this.mouseClickListener);
    this.canvas.removeEventListener('mousemove', this.mouseMoveListener);
  }

  public render(gl: WebGLRenderingContext, matrix: number[]): void {
    // Apply the Mapbox camera transformations to the THREE.js camera
    // Allowing to move the THREE.js camera
    // according to the movements of the Mapbox camera
    const mapboxCameraTransformationMatrix = new THREE.Matrix4().fromArray(matrix);
    const modelTransformationMatrix = this.getModelTransformationMatrix();
    const res = mapboxCameraTransformationMatrix.multiply(modelTransformationMatrix);
    this.camera.projectionMatrix = res;

    // Make the text always face the camera
    if (this.heightText) {
      // Create a rotation matrix and apply it to the text mesh
      const mat4 = new THREE.Matrix4();
      mat4.lookAt(this.raycaster.ray.origin, this.heightText.position, new THREE.Vector3(0, 0, 1));
      this.heightText.setRotationFromMatrix(mat4);
    }

    this.renderer.resetState();
    this.renderer.render(this.scene, this.camera);
    this.map.triggerRepaint();
  }

  private onMouseClick(event: MouseEvent) {
    // Only operate if the tool is active
    if (Model3dStore.activeTool === null) {
      return;
    }

    // Update the raycaster everytime we click
    const canvasPos = this.getCanvasRelativePosition(event);
    const pos = this.getNormalizedPosition(canvasPos);
    this.updateRaycaster(pos);

    // Reset the height line if needed
    if (this.heightLinePoints.length === 2) {
      this.heightLinePoints = [];
      this.hideDistance();
      return;
    }

    // Calculate objects intersecting the picking ray
    var intersects = this.raycaster.intersectObjects(this.scene.children);
    if (intersects.length) {
      const res = intersects[0];
      this.heightLinePoints.push(res.point);
    }
  }

  private onMouseMove(event: MouseEvent) {
    // Only operate if the tool is active
    if (Model3dStore.activeTool === null) {
      return;
    }

    if (this.heightLinePoints.length === 0) {
      return;
    }

    // We need to update the raycaster even if we already selected
    // 2 points because otherwise we can't make the text
    // face the camera in the 'render' method
    const canvasPos = this.getCanvasRelativePosition(event);
    const pos = this.getNormalizedPosition(canvasPos);
    this.updateRaycaster(pos);

    if (this.heightLinePoints.length === 1) {
      // Get the nearest point on the ray to the line which
      // represent the "height axis" of the first point
      const firstPoint = this.heightLinePoints[0];
      const rayPoint = new THREE.Vector3();
      const currentPoint = new THREE.Vector3();
      const highestPoint = new THREE.Vector3(firstPoint.x, firstPoint.y, 20000);
      this.raycaster.ray.distanceSqToSegment(firstPoint, highestPoint, rayPoint, currentPoint);
      this.displayDistance([firstPoint, currentPoint]);
    }
  }

  private getModelTransformationMatrix() {
    // Calculate transformations to apply to THREE.js camera
    const imageId = this.image._id;
    const modelOrigin = this.image.model3d;
    const modelAltitude = this.gisManager.getMediaState(imageId).offset3d;
    const modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(modelOrigin, modelAltitude);
    const modelTransformations = {
      scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits(),
      translateX: modelAsMercatorCoordinate.x,
      translateY: modelAsMercatorCoordinate.y,
      translateZ: modelAsMercatorCoordinate.z,
      rotateX: 0,
      rotateY: 0,
      rotateZ: 0,
    };

    // Create a transformation Matrix for the THREE.js camera
    const modelTransformationMatrix = new THREE.Matrix4()
      .makeTranslation(
        modelTransformations.translateX,
        modelTransformations.translateY,
        modelTransformations.translateZ
      )
      .scale(new THREE.Vector3(modelTransformations.scale, -modelTransformations.scale, modelTransformations.scale))
      .multiply(new THREE.Matrix4().makeRotationAxis(new THREE.Vector3(1, 0, 0), modelTransformations.rotateX))
      .multiply(new THREE.Matrix4().makeRotationAxis(new THREE.Vector3(0, 1, 0), modelTransformations.rotateY))
      .multiply(new THREE.Matrix4().makeRotationAxis(new THREE.Vector3(0, 0, 1), modelTransformations.rotateZ));

    return modelTransformationMatrix;
  }

  /**
   * Update raycaster using normalized {x, y} target position
   */
  private updateRaycaster({ x, y }) {
    const camInverseProjection = this.camera.projectionMatrix.clone();
    camInverseProjection.invert();
    const cameraPosition = new THREE.Vector3().applyMatrix4(camInverseProjection);
    const mousePosition = new THREE.Vector3(x, y, 1).applyMatrix4(camInverseProjection);
    const viewDirection = mousePosition.clone().sub(cameraPosition).normalize();
    this.raycaster.set(cameraPosition, viewDirection);
  }

  /**
   * Return the mouse position in the canvas (in pixels)
   */
  private getCanvasRelativePosition(event: MouseEvent) {
    const rect = this.canvas.getBoundingClientRect();
    return {
      x: ((event.clientX - rect.left) * this.canvas.width) / rect.width,
      y: ((event.clientY - rect.top) * this.canvas.height) / rect.height,
    };
  }

  /**
   * Return a pixel position in the Map canvas normalized between {-1, 1}
   */
  private getNormalizedPosition({ x, y }) {
    const pos = new THREE.Vector2();
    pos.x = (x / this.canvas.width) * 2 - 1;
    pos.y = (y / this.canvas.height) * -2 + 1; // note we flip Y
    return pos;
  }

  /**
   * Draw a line with distance displayed
   */
  private displayDistance(points) {
    // Delete existing text and line if any
    this.hideDistance();

    // Create a new text and line
    const distance = points[0].distanceTo(points[1]);

    const heightLineGeom = new THREE.BufferGeometry().setFromPoints(points);
    const heightLineMat = new THREE.LineBasicMaterial({ color: 0xff0000, linewidth: 2, depthTest: false });
    this.heightLine = new THREE.Line(heightLineGeom, heightLineMat);
    this.heightLine.frustumCulled = false;
    this.scene.add(this.heightLine);

    const textGeom = new TextGeometry(distance.toFixed(2) + ' m', this.fontParams);
    const textMat = new THREE.MeshBasicMaterial({ color: 0xff0000, depthTest: false });
    this.heightText = new THREE.Mesh(textGeom, textMat);
    this.heightText.position.set(points[0].x, points[0].y, points[0].z + distance / 2);
    this.scene.add(this.heightText);
  }

  /**
   * Remove the objects which are used
   * to display the distance from the scene
   */
  private hideDistance() {
    if (this.heightText) {
      this.scene.remove(this.heightText);
    }
    if (this.heightLine) {
      this.scene.remove(this.heightLine);
    }
  }
}
