import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { Organization } from 'src/app/autogenerated/model';
import { required, validate } from 'src/app/shared';
import { SetCurrentOrganizationAction } from './organization.actions';
import { selectCurrentOrganization } from './organization.selectors';

@Injectable({
  providedIn: 'root',
})
export class OrganizationStoreService {
  private readonly store: Store<AppState>;

  constructor(store: Store<AppState>) {
    this.store = store;
  }

  getCurrentOrganization(): Observable<Organization> {
    const currentOrganization$ = this.store.select(selectCurrentOrganization);

    return currentOrganization$;
  }

  @validate
  setCurrentOrganization(@required organization: Organization) {
    this.store.dispatch(new SetCurrentOrganizationAction(organization));
  }
}
