import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateInterventionComponent } from './create-intervention/create-intervention.component';
import { CoreModule } from 'src/app/core/core.module';
import { FormsModule } from '@angular/forms';
import { MidgardControlsModule } from 'src/app/midgard-controls/midgard-controls.module';
import { InterventionCommonModule } from '../../common/intervention-common.module';

@NgModule({
  declarations: [CreateInterventionComponent],
  imports: [CommonModule, CoreModule, FormsModule, MidgardControlsModule, InterventionCommonModule],
  exports: [CreateInterventionComponent],
})
export class CreateInterventionRightPanelModule {}
