<ng-container>
  <div class="right-panel" [class.main-menu]="isMainMenu" (mousedown)="onClickInside($event)">
    <app-mid-icon-button class="close-panel" type="primary" shadow="false" (action)="close()">
      <i class="fas fa-times"></i>
    </app-mid-icon-button>

    <div class="header-panel" #headerPanel>
      <ng-content select="app-mid-right-panel-header"></ng-content>
    </div>

    <div
      class="content-panel"
      [class.with-header]="headerPanel.hasChildNodes()"
      [class.with-footer]="footerPanel.hasChildNodes()"
    >
      <h2 *ngIf="title" class="title">{{ title }}</h2>
      <ng-content select="app-mid-right-panel-content"></ng-content>
    </div>

    <div class="footer-panel" #footerPanel>
      <ng-content select="app-mid-right-panel-footer"></ng-content>
    </div>
  </div>
</ng-container>
