import { FeatureCollection } from '@turf/helpers';

export class MapAnnotationNestedDto {
  constructor(sourceId: string) {
    this.sourceId = sourceId;
  }

  _id: string;
  map_id: string;
  sourceId: string;
  category: string; // AnnotationCategory
  type: string;
  data: FeatureCollection | Record<any, any>;
}
