import { Injectable } from '@angular/core';
import { orderBy, isArray, fill } from 'lodash';
import { SortOrder } from './sort-order';
import { required, validate } from 'src/app/shared';

@Injectable({
  providedIn: 'root',
})
export class SortService {
  @validate
  sort<T>(
    @required items: T[],
    @required fields: string | string[],
    @required sortOrders: SortOrder | SortOrder[]
  ): T[] {
    if (isArray(fields) && fields.length > 1 && !isArray(sortOrders)) {
      sortOrders = fill(Array(fields.length), sortOrders);
    }

    const sortedItems = orderBy(items, fields, sortOrders);
    return sortedItems;
  }
}
