import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Intervention } from 'src/app/autogenerated/model';
import { Unsubscribe } from 'src/app/core/decorators';
import { OrganizationStoreService } from 'src/app/organization/store';
import { required, validate } from 'src/app/shared';
import { InterventionStoreService } from '../../store';
import {
  SelectInterventionComponent,
  SelectInterventionDialogData,
} from '../select-intervention/select-intervention.component';

@Unsubscribe()
@Component({
  selector: 'app-intervention-name-badge',
  templateUrl: './intervention-name-badge.component.html',
  styleUrls: ['./intervention-name-badge.component.scss'],
})
export class InterventionNameBadgeComponent implements OnChanges, OnDestroy {
  @Input()
  interventionId: string;

  @Input()
  isLinkButtonType: boolean;

  @Output()
  readonly interventionChanged: EventEmitter<Intervention>;

  intervention: Intervention;

  private interventionSubscription: Subscription;

  constructor(
    private interventionStoreService: InterventionStoreService,
    private organizationStoreService: OrganizationStoreService,
    private dialog: MatDialog
  ) {
    this.interventionChanged = new EventEmitter<Intervention>();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.cancelInterventionSubscription();

    const componentChanges = changes as PropertyMap<InterventionNameBadgeComponent, SimpleChange>;

    const interventionIdChange = componentChanges.interventionId;
    if (!interventionIdChange) {
      return;
    }
    if (!interventionIdChange.currentValue) {
      this.intervention = null;
      return;
    }

    this.onInterventionChanged(this.interventionId);
  }

  ngOnDestroy(): void {}

  @validate
  onInterventionChanged(@required interventionId: string) {
    this.interventionSubscription = this.interventionStoreService
      .getInterventionById(interventionId)
      .subscribe((intervention) => (this.intervention = intervention));
  }

  @validate
  async chooseIntervention(@required event: Event) {
    event.stopPropagation();

    const currentOrganization = await this.organizationStoreService.getCurrentOrganization().pipe(take(1)).toPromise();

    if (!currentOrganization) {
      return;
    }

    const organizationId: string = currentOrganization._id;
    const data: SelectInterventionDialogData = { organizationId, intervention: this.intervention };
    const dialogRef = this.dialog.open<SelectInterventionComponent, any, Intervention>(SelectInterventionComponent, {
      id: 'assignInterventionToDrone',
      width: '550px',
      data: { organizationId: organizationId, intervention: this.intervention },
      closeOnNavigation: true,
    });

    dialogRef.afterClosed().subscribe((intervention: Intervention) => {
      if (intervention && intervention._id) {
        this.interventionChanged.next(intervention);
      }
    });
  }

  private cancelInterventionSubscription() {
    if (!this.interventionSubscription) {
      return;
    }

    try {
      this.interventionSubscription.unsubscribe();
    } catch {
      // ignore
    } finally {
      this.interventionSubscription = null;
    }
  }
}
