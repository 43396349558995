import { Feature } from '@turf/turf';
import { MapState } from '../models/map-state.model';
import { MapManager } from './map-manager';
import { MapModeManager } from './map-mode-manager';

/**
 * ViewModeManager handles Map interactions for View mode pages
 *
 * Examples of responsabilities:
 * - Keep track of selected feature
 * - Triggers different events about Features
 */
export class ViewModeManager extends MapModeManager {
  constructor(protected map: mapboxgl.Map, protected mapState?: MapState) {
    super(map, mapState);
    this.isEditMode = false;
    this.mapManager = new MapManager(map);
    this.initListeners();
  }

  //=====
  // Internal logic
  //=====

  private setSelectedFeature(feature: Feature) {
    this.selectedFeature = feature;
    this.notifyFeatureSelectedChanged();
  }

  private closeViewMode() {
    // Remove any "selected feature" style
    if (this.selectedFeature) {
      if (this.map.getSource(this.selectedSourceId)) {
        this.map.removeFeatureState({
          source: this.selectedSourceId,
        });
      }
    }

    // Clean any selected reference
    this.map.getCanvas().style.cursor = '';
    this.selectedSourceId = null;
    this.selectedFeature = null;
    this.notifyFeatureUnselected();
  }

  //=====
  // Listeners
  //=====

  private initListeners() {
    this.map.on('click', (e) => this.onMapClick(e));
  }

  private onMapClick(e) {
    // Select features in a bbox of 5px around the clicked point
    const features: mapboxgl.MapboxGeoJSONFeature[] = this.map.queryRenderedFeatures([
      [e.point.x - 5, e.point.y - 5],
      [e.point.x + 5, e.point.y + 5],
    ]);
    if (!features.length) {
      this.closeViewMode();
      return;
    }

    // Navigate through features to be able to select the ones in background aswell
    const feature = this.mapManager.navigateThroughFeatures(features);
    if (!feature) {
      this.closeViewMode();
      return;
    }
    this.initSourceSelection(feature.source);
  }

  //=====
  // Misc
  //=====

  initSourceSelection(sourceId: string) {
    // Clean any selected item first
    this.closeViewMode();
    // Should always be 0 because we only have ONE feature by source of annotation
    const featureId = '0';
    this.mapManager.bringSourceToFront(sourceId);
    this.selectedSourceId = sourceId;
    this.map.setFeatureState(
      {
        source: sourceId,
        id: featureId,
      },
      {
        highlight: true,
      }
    );
    // Here we can't assign the feature directly because it may
    // represent a tiled vector feature and it does not contain a String ID
    const realFeature = this.mapManager.getGeoJSONFeature(sourceId, featureId);
    this.setSelectedFeature(realFeature);
  }
}
