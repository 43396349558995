import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  MediaFileDto,
  OrganizationDistributionListDto,
  OrganizationDistributionListService,
  OrganizationsMediaService,
  SharedMediaFileDto,
} from 'src/api';
import { MID_RIGHT_PANEL_DATA } from 'src/app/midgard-controls/services/dom.service';
import { MidSelectItem } from '../../midgard-controls/models';
import { isEmpty } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { MediaApiClientService } from '../../../api-services/media-api-client.service';
import { required, validate } from '../../shared';
import { OrganizationService } from '../../autogenerated/organizationService';
import { MidInputComponent } from 'src/app/midgard-controls/mid-input/mid-input.component';
import { Subscription } from 'rxjs';
import { OrganizationStoreService } from 'src/app/organization/store';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

enum TdrawSharingMode {
  SMS = 'sms',
  EMAIL = 'email',
  QRCODE = 'qrcode',
  LIST_SMS = 'list_sms',
}

@Component({
  selector: 'app-media-share',
  templateUrl: './media-share.component.html',
  styleUrls: ['./media-share.component.scss'],
})
export class MediaShareComponent implements OnInit {
  media: MediaFileDto;
  existingShares: SharedMediaFileDto[] = [];
  duration: number = null;
  durationOptions = [1, 2, 7, 15, 30];
  optionsSelectItems: MidSelectItem[] = [];

  canCreate = false;

  dataToShare: {
    phoneNumber: any;
    mail: string;
  };

  mediaShareLink: string;
  sharingMode?: TdrawSharingMode;

  organizationId: string;

  @ViewChild('listInput')
  private listInput: MidInputComponent;
  selectedDistributionList: OrganizationDistributionListDto;
  distributionLists: OrganizationDistributionListDto[];

  // Subscriptions
  private organizationSubscription: Subscription;

  get TdrawSharingMode() {
    return TdrawSharingMode;
  }

  constructor(
    private readonly organizationsMediaService: OrganizationsMediaService,
    private readonly organizationService: OrganizationService,
    private readonly mediaApiClientService: MediaApiClientService,
    @Inject(MID_RIGHT_PANEL_DATA) private readonly data: { media: MediaFileDto },
    private translate: TranslateService,
    private readonly organizationStoreService: OrganizationStoreService,
    private readonly organizationDistributionListService: OrganizationDistributionListService
  ) {
    this.media = data.media;

    this.dataToShare = {
      phoneNumber: null,
      mail: null,
    };

    this.mediaShareLink = null;

    this.organizationSubscription = this.organizationStoreService
      .getCurrentOrganization()
      .subscribe(async (organization) => {
        if (!organization || this.organizationId === organization._id) {
          return;
        }
        this.organizationId = organization._id;
        this.loadDistributionLists();
      });
  }

  async ngOnInit() {
    const [createPermission, existingShares] = await Promise.all([
      this.organizationsMediaService.mediaShareControllerGetCreateSharePermission(this.media.id).toPromise(),
      this.organizationsMediaService.sharedMediaFileControllerGetMediaShares(this.media.id).toPromise(),
    ]);
    this.canCreate = createPermission.hasPermission;
    this.existingShares = existingShares;
    this.optionsSelectItems = await this.convertDurationInMidSelectItems(this.durationOptions);
  }

  async onGenerateNewShareLink() {
    const share = await this.createShareLink(this.media, this.duration);
    if (!isEmpty(share)) {
      this.existingShares.push(share);
    }
    this.duration = null;
  }

  async createShareLink(media: MediaFileDto, duration: number) {
    try {
      const shareLink = await this.organizationsMediaService
        .mediaShareControllerCreate({
          duration: duration,
          media: media.id,
          organization: media.organization,
        })
        .toPromise();
      if (isEmpty(shareLink)) {
        return null;
      }
      return shareLink;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  shareDeleted(share: SharedMediaFileDto) {
    this.existingShares = this.existingShares.filter((x) => x.id !== share.id);
  }

  private async convertDurationInMidSelectItems(options) {
    const midSelectItems = [];
    const singular = ' ' + (await this.translate.get('DURATION.DAY.ONE').toPromise());
    const plural = ' ' + (await this.translate.get('DURATION.DAY.MANY').toPromise());

    for (const option of options) {
      const translatedLabel = option + (option > 1 ? plural : singular);
      const midSelectItem: MidSelectItem = {
        isSelected: false,
        name: translatedLabel,
        value: option,
      };
      midSelectItems.push(midSelectItem);
    }
    return midSelectItems;
  }

  shareUsingMode(share: SharedMediaFileDto, mode: TdrawSharingMode) {
    this.mediaShareLink = this.mediaApiClientService.getShareLink(share);
    this.sharingMode = mode;
  }

  async sendMail(): Promise<void> {
    if (!this.dataToShare.mail) {
      return;
    }
    await this.sendMailRequest(this.dataToShare.mail);
    this.sharingMode = null;
  }

  async sendSMS(): Promise<void> {
    if (!this.dataToShare.phoneNumber) {
      return;
    }
    await this.sendSmsRequest(this.dataToShare.phoneNumber.e164Number);
    this.mediaShareLink = null;
  }

  cancel(): void {
    this.mediaShareLink = null;
    this.sharingMode = null;
  }

  async sendSMSList() {
    const phones = this.selectedDistributionList.phones;
    phones.forEach(async (phone) => {
      await this.sendSmsRequest(phone);
    });
    this.sharingMode = null;
  }

  selectDistributionList(event: MatAutocompleteSelectedEvent): void {
    const list = event.option.value;
    this.selectedDistributionList = list;
    this.listInput.input.control.setValue(list.name);
  }

  private async loadDistributionLists() {
    this.distributionLists = await this.organizationDistributionListService
      .orgOrganizationDistributionListControllerGetOrgDistributionLists(this.organizationId)
      .toPromise();
  }

  @validate
  private async sendMailRequest(@required mail: string): Promise<void> {
    await this.organizationService.sendMediaShareLinkByMail(mail, this.mediaShareLink);
    this.sharingMode = null;
  }

  @validate
  private async sendSmsRequest(@required phoneNumber: string): Promise<void> {
    await this.organizationService.sendMediaShareLinkBySMS(phoneNumber, this.mediaShareLink);
    this.sharingMode = null;
  }
}
