import { ConnectedPosition, Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { MidTooltipPosition } from './mid-tooltip-position.enum';
import { MidTooltipComponent } from './mid-tooltip.component';

@Directive({
  selector: '[appMidTooltip]',
})
export class MidTooltipDirective implements OnInit {
  @Input('appMidTooltip') text: string;
  @Input('appMidTooltipPosition') position: MidTooltipPosition = MidTooltipPosition.bottom;
  @Input('appMidTooltipWidth') width: string | number = '200px';
  // @Input('appMidTooltipDelay') delay: string;

  private overlayRef: OverlayRef;

  constructor(
    private elementRef: ElementRef,
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder
  ) {}

  ngOnInit(): void {
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([this.setPosition(this.position)]);

    this.overlayRef = this.overlay.create({ positionStrategy, width: this.width });
  }

  @HostListener('mouseenter')
  show() {
    // Attach tooltip portal to overlay
    const midTooltipRef: ComponentRef<MidTooltipComponent> = this.overlayRef.attach(
      new ComponentPortal(MidTooltipComponent)
    );
    // Pass content to tooltip component instance
    midTooltipRef.instance.text = this.text;
    midTooltipRef.instance.position = this.position;
    midTooltipRef.instance.parentPosition = this.elementRef.nativeElement.getBoundingClientRect();
  }

  @HostListener('mouseout')
  hide() {
    this.overlayRef.detach();
  }

  private setPosition(position: MidTooltipPosition): ConnectedPosition {
    switch (position) {
      case MidTooltipPosition.top:
        return {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
          offsetY: -5,
        };
      case MidTooltipPosition.left:
        return {
          originX: 'start',
          originY: 'top',
          overlayX: 'end',
          overlayY: 'top',
          offsetX: -5,
        };
      case MidTooltipPosition.right:
        return {
          originX: 'end',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'top',
          offsetX: 5,
        };
      default:
        // MidTooltipPlacement.bottom
        return {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
          offsetY: 5,
        };
    }
  }
}
