<section class="interventions-page">
  <div class="mid-header-menu">
    <div class="filter-section">
      <div class="search-filter">
        <div class="search-input">
          <input
            type="text"
            placeholder="{{ 'FIND_BY_NAME' | translate }}"
            [(ngModel)]="fieldText"
            (ngModelChange)="onFilterChanged()"
            autocomplete="off"
          />
          <i class="fas fa-search"></i>
        </div>
        <div class="search-filter-buttons">
          <button
            class="search-section-btn filter-btn"
            [class.active]="interventionFilterType === 'all'"
            (click)="setInterventionFilterType('all')"
          >
            <span>{{ 'COMMON_ALL' | translate }}</span>
          </button>
          <button
            class="search-section-btn filter-btn"
            [class.active]="interventionFilterType === InterventionType.OPS"
            (click)="setInterventionFilterType(InterventionType.OPS)"
          >
            <span>{{ 'INTERVENTION_TYPE.OPS' | translate }}</span>
          </button>
          <button
            class="search-section-btn filter-btn"
            [class.active]="interventionFilterType === InterventionType.PRV"
            (click)="setInterventionFilterType(InterventionType.PRV)"
          >
            <span>{{ 'INTERVENTION_TYPE.PRV' | translate }}</span>
          </button>
          <button
            class="search-section-btn filter-btn"
            [class.active]="interventionFilterType === InterventionType.COM"
            (click)="setInterventionFilterType(InterventionType.COM)"
          >
            <span>{{ 'INTERVENTION_TYPE.COM' | translate }}</span>
          </button>
          <button
            class="search-section-btn filter-btn"
            [class.active]="interventionFilterType === InterventionType.TRAINING"
            (click)="setInterventionFilterType(InterventionType.TRAINING)"
          >
            <span>{{ 'INTERVENTION_TYPE.TRAINING' | translate }}</span>
          </button>
        </div>
      </div>
      <app-mid-button type="primary" icon="fas fa-plus" (action)="onOpenCreateIntervention()">{{
        'INTERVENTION_ADD_NEW' | translate
      }}</app-mid-button>
    </div>
  </div>

  <div class="interventions-page-content" [class.overlay-loader]="state.isLoading">
    <ng-container *ngFor="let interventions of dataSource.groupedItems; trackBy: trackByItem">
      <div class="interventions-date-info text-in-lines">
        <p class="interventions-date mt-3">
          {{ interventions.key | date : 'MMMM yyyy' : undefined : translate.getDefaultLang() }}
        </p>
      </div>
      <div class="interventions-list">
        <app-intervention-card
          *ngFor="let intervention of interventions.value; trackBy: trackByItem"
          [intervention]="intervention"
          (associateWithDroneEvent)="onOpenAssociateWithDrone()"
          class="interventions-card"
        ></app-intervention-card>
      </div>
    </ng-container>
  </div>
</section>
