import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlContainer, NgForm, NgModel } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MidChipListIconType } from '../enums/icon-type.enum';
export interface MidChipType {
  value: string;
  name: string;
}

@Component({
  selector: 'app-mid-chip-list',
  templateUrl: './mid-chip-list.component.html',
  styleUrls: ['./mid-chip-list.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  exportAs: 'appMidChipList',
})
export class MidChipListComponent implements OnInit, OnChanges {
  @Input()
  values: string[] = [];

  @Output()
  valuesChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  @Input()
  name = 'appMidInputName_' + ((Math.random() * 10000) | 0);

  @Input()
  placeholder = '';

  // @Input()
  selectable = false;

  @Input()
  iconType: MidChipListIconType;

  @Input()
  removable = true;

  @Input()
  verticalOrientation = false;

  // used for autocompletion
  @Input()
  allValues: MidChipType[] = [];
  filteredChips: Observable<MidChipType[]> = of([]);
  @ViewChild('chipInput')
  chipInput: NgModel;
  chipInputValue: string;

  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  get IconType() {
    return MidChipListIconType;
  }

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.allValues?.length) {
      this.filteredChips = this.chipInput?.control.valueChanges.pipe(
        startWith(null as string),
        map((name: string | null) => this.autocompleteByName(name))
      );
    }
  }

  valueName(value: string) {
    if (!this.allValues?.length) {
      return value;
    }
    return this.allValues?.find((it) => it.value === value)?.name || value;
  }

  add(event: MatChipInputEvent) {
    const value = (event.value || '').trim();
    if (!this.allValues?.length && value) {
      // add any values
      this.values.push(value);
      this.valuesChange.emit(this.values);
    } else if (this.allValues?.length && value) {
      // add only values from a given list
      this.addValueByName(value);
    }
    // this.input.control.markAsDirty();
    event.input.value = '';
  }

  remove(value: string) {
    const index = this.values.indexOf(value);
    if (index >= 0) {
      this.values.splice(index, 1);
      this.valuesChange.emit(this.values);
      this.chipInput.control.updateValueAndValidity({ emitEvent: true });
      this.chipInput.control.markAsDirty();
    }
  }

  isSelected(value: string) {
    return this.values?.includes(value);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.addValueByName(event.option.viewValue);
    this.chipInput.viewToModelUpdate('');
    this.chipInput.control.setValue(null);
  }

  private addValueByName(name: string) {
    const chip = this.allValues.find((e) => e.name === name);
    if (!!chip && !this.values.includes(chip.value)) {
      this.values.push(chip.value);
      this.valuesChange.emit(this.values);
    }
  }

  private autocompleteByName(name: string): any {
    return this.allValues?.filter(
      (chip) => !this.values?.includes(chip.value) && (!name || chip.name?.toLowerCase()?.includes(name?.toLowerCase()))
    );
  }
}
