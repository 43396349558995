import { ApiService } from './../core/api/api.service';

import { InterventionServiceAutoGenerated } from './services/interventionService.service';
import { Injectable } from '@angular/core';
import { BackendClientService } from '../global/backend-client.service';
import { DbClass, Intervention } from './model';
import { InterventionService as InterventionServiceV2 } from 'src/api';
import moment from 'moment';
import _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class InterventionService extends InterventionServiceAutoGenerated {
  constructor(
    client: BackendClientService,
    apiService: ApiService,
    private interventionServiceV2: InterventionServiceV2
  ) {
    super(client, apiService);
  }

  async getInterventionsWithMapping(organization: DbClass): Promise<Intervention[]> {
    let interventions: Intervention[] = await this.interventionServiceV2
      .organizationInterventionControllerFind(organization._id)
      .toPromise();

    interventions = _.filter(interventions, (intervention) => !intervention.isDeleted);
    interventions = _.forEach(interventions, (intervention) => {
      intervention.updatedDate = intervention.updatedDate
        ? moment.utc(intervention.updatedDate).local().toDate()
        : undefined;
      intervention.startDate = intervention.startDate ? moment.utc(intervention.startDate).local().toDate() : undefined;
      intervention.endDate = intervention.endDate ? moment.utc(intervention.endDate).local().toDate() : undefined;
      intervention.isClosed = intervention.endDate
        ? moment(intervention.endDate).local() < moment(new Date()).local()
        : false;
      if (!intervention.statistics) {
        intervention.statistics = {
          victims: [],
          engagedResources: [],
          engagedStaff: [],
          otherServices: [],
        };
      }
    });
    interventions = _.orderBy(interventions, ['isClosed', 'isArchived', 'updatedDate'], ['asc', 'asc', 'desc']);

    return interventions;
  }
}
