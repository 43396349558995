<app-mid-right-panel [title]="'DIALOGS.DRONE_PROPERTIES.TITLE' | translate">
  <app-mid-right-panel-content>
    <div class="row m-0 px-4 pb-4">
      <section class="section col-md-12">
        <div class="drone-properties-item" [ngSwitch]="currentDrone.droneModel">
          <div class="drone-properties-title">
            {{ 'DIALOGS.DRONE_PROPERTIES.RTMP_TITLE' | translate }}
          </div>
          <ng-container *ngSwitchCase="'OBS_STUDIO'">
            <div class="drone-properties-info">
              {{ 'DIALOGS.DRONE_PROPERTIES.STREAM_LINK_INFO_OBS' | translate }}
            </div>
            <div class="drone-properties-server">{{ 'DIALOGS.DRONE_PROPERTIES.SERVER' | translate }} :</div>
            <div class="drone-properties-link">{{ serverLink }}</div>
            <div class="drone-properties-server">{{ 'DIALOGS.DRONE_PROPERTIES.STREAM_KEY' | translate }} :</div>
            <div class="drone-properties-link">{{ currentDrone.rtmpStreamId }}</div>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <div class="drone-properties-info">
              {{ 'DIALOGS.DRONE_PROPERTIES.STREAM_LINK_INFO' | translate }}
            </div>
            <div class="drone-properties-link">{{ fullRtmpLink }}</div>
          </ng-container>
          <app-mid-button type="secondary" (action)="onCopyLink(); $event.stopPropagation()">
            {{ 'DIALOGS.DRONE_PROPERTIES.STREAM_COPY_LINK' | translate }}
          </app-mid-button>
        </div>
        <div class="drone-properties-item">
          <div class="drone-properties-title">
            {{ 'DIALOGS.DRONE_PROPERTIES.DETAILS_TITLE' | translate }}
          </div>
          <div class="drone-properties-created">
            {{ 'DIALOGS.DRONE_PROPERTIES.DRONE_ADDED_DATE' | translate }}
            {{ currentDrone.creationDate | date : 'dd/MM/yyyy HH:mm' }}
          </div>
          <form #formDroneProperties="ngForm" novalidate>
            <app-mid-input
              size="wide"
              [(value)]="currentDrone.name"
              #name="appMidInput"
              name="name"
              [required]="true"
              [label]="'DIALOGS.CREATE_DRONE.NAME_LABEL' | translate"
              [placeholder]="'DIALOGS.CREATE_DRONE.NAME_PLACEHOLDER' | translate"
              [disabled]="state.isLoading"
            >
              <span *ngIf="!formDroneProperties.pristine && name.invalid" class="warning-input-text">{{
                'COMMON_REQUIRED_FIELD' | translate
              }}</span>
            </app-mid-input>
            <app-mid-select
              size="wide"
              name="droneModel"
              [(items)]="droneModels"
              #droneModel="appMidSelect"
              [required]="true"
              [label]="'DIALOGS.CREATE_DRONE.MODEL_LABEL' | translate"
              [placeholder]="'DIALOGS.CREATE_DRONE.MODEL_PLACEHOLDER' | translate"
              [(selectedItem)]="currentDrone.droneModel"
              [disabled]="state.isLoading"
              (selectedItemChange)="updateRtmpLink()"
            >
              <span *ngIf="!formDroneProperties.pristine && droneModel.invalid" class="warning-input-text">{{
                'COMMON_REQUIRED_FIELD' | translate
              }}</span>
              <span *ngIf="currentDrone.droneModel === DroneModel.Other" class="warning-input-text">{{
                'DIALOGS.CREATE_DRONE.OTHERS_DRONES_MIGHT_NOT_OPTIMIZED_FOR_YOUR_APP' | translate
              }}</span>
            </app-mid-select>
          </form>
        </div>
      </section>
    </div>
  </app-mid-right-panel-content>
  <app-mid-right-panel-footer>
    <div class="row m-0 py-3 px-4">
      <app-mid-button
        class="pr-2"
        type="primary"
        (click)="onUpdate()"
        [disabled]="formDroneProperties.form?.invalid || state.isLoading"
        >{{ 'DIALOGS.COMMON.SAVE' | translate }}
      </app-mid-button>
      <!--
      <app-mid-button class="pr-2" type="secondary" (action)="panelDroneProperties.close()">{{
        'DIALOGS.COMMON.CANCEL' | translate
      }}</app-mid-button>
      -->
    </div>
  </app-mid-right-panel-footer>
</app-mid-right-panel>
