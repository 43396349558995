import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanMatch,
  Route,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { take } from 'rxjs/operators';
import { AuthenticationStoreService } from '../authentication/store';
import { authenticationConfig } from '../authentication/authentication.config';
import { AuthenticationService } from '../authentication/authentication.service';
import { Observable } from 'rxjs';
import { InterventionService } from 'src/api';

@Injectable({
  providedIn: 'root',
})
export class InterventionGuard implements CanMatch {
  constructor(private readonly interventionService: InterventionService) {}
  async canMatch(route: Route, segments: UrlSegment[]) {
    const interventionId = segments[0]?.path;
    const intervention = await this.interventionService.interventionsControllerGet(interventionId).toPromise();
    return !!intervention;
  }
}
