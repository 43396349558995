<div
  class="d-flex align-items-center"
  [class.theme-switch]="type === SwitchType.themeSwitch"
  [class.light-theme]="!state"
>
  <ng-container *ngIf="labelPosition === LabelPosition.before">
    <div *ngIf="!state" class="mr-2">
      <span>{{ textOn | translate }}</span>
    </div>
    <div *ngIf="state" class="mr-2">
      <span>{{ textOff | translate }}</span>
    </div>
  </ng-container>
  <label class="switch">
    <input mat-checkbox type="checkbox" [(ngModel)]="state" (change)="toggle()" [disabled]="disabled" />
    <span class="slider round"></span>
  </label>
  <ng-container *ngIf="labelPosition === LabelPosition.after">
    <div *ngIf="!state" class="label col-md-8">
      <span>{{ textOn | translate }}</span>
    </div>
    <div *ngIf="state" class="label col-md-8">
      <span>{{ textOff | translate }}</span>
    </div>
  </ng-container>
</div>
