import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Intervention } from 'src/app/autogenerated/model';
import { Unsubscribe } from 'src/app/core/decorators';
import { interventionConfig } from '../intervention.config';
import { InterventionStoreService } from '../store';
import { BreadcrumbStateService } from '../../layout/services/breadcrumb-state.service';

@Unsubscribe()
@Component({
  selector: 'app-intervention-page',
  templateUrl: './intervention-page.component.html',
  styleUrls: ['./intervention-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InterventionPageComponent implements OnInit, OnDestroy {
  interventionId: string;
  intervention: Intervention;

  private routeParamsSubscription: Subscription;
  private interventionSubscription: Subscription;

  constructor(
    private breadcrumbService: BreadcrumbStateService,
    private route: ActivatedRoute,
    private interventionStore: InterventionStoreService
  ) {}

  async ngOnInit() {
    this.routeParamsSubscription = this.route.params.subscribe((params) => {
      this.interventionId = params[interventionConfig.routes.params.interventionId] || null;
      this.onInterventionChanged();
    });
  }

  onInterventionChanged() {
    this.interventionSubscription = this.interventionStore
      .getInterventionById(this.interventionId)
      .subscribe((intervention) => {
        this.intervention = intervention;
        this.breadcrumbService.setBreadcrumbDataState({
          'intervention.label': this.intervention.name,
        });
      });
  }

  ngOnDestroy(): void {}
}
