import { MapAnnotationNestedDto, MapStateNestedBackgroundDto, MapStateNestedStatisticsDto } from '.';
import { MapStateNestedMedia } from '../map-state-nested-media';
import { RegularMarker } from '../regular-marker.model';
import { MapStateFlightsDto } from './map-state-flights.dto';
import { MapStateNestedBackgroundMediaDto } from './map-state-nested-background-media.dto';

export class MapStateUpdateDto {
  name?: string;
  background?: MapStateNestedBackgroundDto;
  backgroundMedia?: MapStateNestedBackgroundMediaDto;
  annotations?: MapAnnotationNestedDto[];
  medias?: MapStateNestedMedia[];
  regularMarkers?: RegularMarker[];
  statistics?: MapStateNestedStatisticsDto;
  flights?: MapStateFlightsDto;

  constructor({
    name,
    background,
    backgroundMedia,
    annotations,
    medias,
    regularMarkers,
    statistics,
    flights,
  }: Partial<MapStateUpdateDto> = {}) {
    this.name = name;
    this.background = background;
    this.backgroundMedia = backgroundMedia;
    this.annotations = annotations;
    this.medias = medias;
    this.regularMarkers = regularMarkers;
    this.statistics = statistics;
    this.flights = flights;
  }
}
