<mid-simple-dashboard-block
  [title]="'DRONES_DASHBOARD.VIDEO_STREAMS' | translate"
  class="drones-dashboard-block-container"
>
  <mid-block-carousel
    class="w-100"
    (leftArrowClick)="onPreviousDrone()"
    (rightArrowClick)="onNextDrone()"
    [isLeftArrowEnabled]="isPreviousPageExists"
    [isRightArrowEnabled]="isNextPageExists"
  >
    <div class="drone-dashboard" [ngSwitch]="dashboardBehavior$?.getValue()">
      <!-- add drone -->
      <div class="add-drone" *ngSwitchCase="DashboardBehavior.addDrone">
        {{ 'DRONES_DASHBOARD.MSG.ADD_DRONE' | translate }}
      </div>
      <!-- assign drone -->
      <div class="assign-drone" *ngSwitchCase="DashboardBehavior.assignDrone">
        {{ 'DRONES_DASHBOARD.MSG.ASSIGN_DRONE' | translate }}
      </div>
      <!-- drone cards -->
      <ng-container *ngSwitchDefault>
        <app-drone-card
          class="drone-card"
          *ngFor="let drone of dataSource.paginatedItems"
          [isAdmin]="isAdmin"
          [organizationId]="organizationId"
          [drone]="drone"
          [isSmallCard]="true"
        >
        </app-drone-card>
      </ng-container>
    </div>
  </mid-block-carousel>
  <div class="d-flex justify-content-center pt-3 pb-3 mt-2" [ngSwitch]="dashboardBehavior$?.getValue()">
    <!-- add drone -->
    <app-mid-button
      *ngSwitchCase="DashboardBehavior.addDrone"
      type="primary"
      (action)="onOpenCreateDrone()"
      icon="fas fa-helicopter"
    >
      {{ 'DRONES_ADD' | translate }}
    </app-mid-button>
    <!-- drones exist -->
    <app-mid-button *ngSwitchDefault type="secondary" (action)="goToAllDrones()" icon="fas fa-helicopter">
      {{ 'COMMON_SEE_ALL' | translate }} ({{ dataSource.filteredItems.length }})
    </app-mid-button>
    <div class="ml-1">
      <app-mid-button
        *ngIf="isLinkBtnActive"
        type="primary"
        (action)="onOpenAssignDroneToInterventionDialog()"
        icon="fas fa-helicopter"
        [disabled]="intervention?.isArchived"
      >
        {{ 'LINK_DRONE_TO_INTERVENTION' | translate }}
      </app-mid-button>
    </div>
  </div>
</mid-simple-dashboard-block>
