import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MapState } from 'src/app/tdraw/models/map-state.model';
import { ConfirmModalService } from 'src/app/midgard-controls/services/confirm-modal.service';
import { MidRightPanelService } from 'src/app/midgard-controls/services/mid-right-panel.service';
import { MapStateSharePanelComponent } from '../mapstate-share-panel/mapstate-share-panel.component';

@Component({
  selector: 'tdraw-version-list-element-menu',
  templateUrl: './version-list-element-menu.component.html',
  styleUrls: ['./version-list-element-menu.component.scss'],
})
export class VersionListElementMenuComponent {
  @Input()
  mapState: MapState;

  @Output()
  publish: EventEmitter<void>;

  @Output()
  rename: EventEmitter<void>;

  @Output()
  duplicate: EventEmitter<void>;

  @Output()
  download: EventEmitter<void>;

  @Output()
  deletingMapState: EventEmitter<void>;

  constructor(private confirmModalService: ConfirmModalService, private midRightPanelService: MidRightPanelService) {
    this.publish = new EventEmitter();
    this.rename = new EventEmitter();
    this.duplicate = new EventEmitter();
    this.download = new EventEmitter();
    this.deletingMapState = new EventEmitter();
  }

  onPublish() {
    this.publish.next();
  }

  onRename() {
    this.rename.next();
  }

  onDuplicate() {
    this.duplicate.next();
  }

  onDownload() {
    this.download.next();
  }

  async onShare() {
    this.midRightPanelService.open(MapStateSharePanelComponent, { mapState: this.mapState });
  }

  async onDeleteMapState() {
    const onDeletingMapStateConfirmed = await this.confirmModalService.showConfirmModal(
      'TDRAW.DELETE_CONFIRM_MESSAGE',
      'remove'
    );
    if (onDeletingMapStateConfirmed) {
      this.deletingMapState.next();
    }
  }
}
