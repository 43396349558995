import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlContainer, NgForm, NgModel } from '@angular/forms';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input-gg';
import { MidInputSize } from '../enums/input-size.enum';

@Component({
  selector: 'app-mid-input-phone-number',
  templateUrl: './mid-input-phone-number.component.html',
  styleUrls: ['./mid-input-phone-number.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  exportAs: 'appMidPhoneNumberInput',
})
export class MidInputPhoneNumberComponent implements OnInit {
  @Input()
  value: string;

  @Output()
  valueChange: EventEmitter<string>;

  @Input()
  name = 'appMidInputName_' + ((Math.random() * 10000) | 0);

  @Input()
  label = '';

  @Input()
  size: MidInputSize = MidInputSize.default;

  @Input()
  disabled = false;

  @Input()
  required = false;

  @ViewChild('midInputPhoneNumber')
  input: NgModel;

  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.Italy, CountryISO.UnitedKingdom];

  constructor() {
    this.valueChange = new EventEmitter();
  }

  ngOnInit() {}
  changePreferredCountries() {
    this.preferredCountries = [CountryISO.France, CountryISO.Italy];
  }

  onValueChange() {
    this.valueChange.emit(this.value);
  }

  get valid() {
    return this.input?.valid;
  }

  get invalid() {
    return this.input?.invalid;
  }

  get dirty() {
    return this.input?.dirty;
  }

  get pristine() {
    return this.input?.pristine;
  }

  get touched() {
    return this.input?.touched;
  }

  get untouched() {
    return this.input?.untouched;
  }

  get MidInputSize() {
    return MidInputSize;
  }
}
