import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationStoreService } from '../store';
import { AuthenticationService } from '../authentication.service';
import { appConfig } from 'src/app/app.config';
import { UserProfilesStoreService } from 'src/app/user-profile/store';
import { UserAuth, UiLanguage } from 'src/app/autogenerated/model';
import { required, validate } from 'src/app/shared';
import { LayoutStoreService } from 'src/app/layout/store';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent {
  constructor(
    private readonly authenticationStoreService: AuthenticationStoreService,
    private readonly userProfilesStoreService: UserProfilesStoreService,
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router,
    private readonly layoutStoreService: LayoutStoreService
  ) {}

  @validate
  async onLoggedIn(@required authDetails: UserAuth) {
    this.authenticationStoreService.authenticate(
      authDetails._id,
      authDetails.token,
      authDetails.defaultOrganization?.toString()
    );
    this.userProfilesStoreService.setCurrentUser(authDetails);
    this.layoutStoreService.setDefaultLanguage(authDetails.defaultLanguage || UiLanguage.fr);

    const returnUrl = await this.authenticationService.getReturnUrl();
    if (returnUrl) {
      this.authenticationService.clearReturnUrl();
      await this.router.navigate([returnUrl]);
    } else {
      await this.router.navigate([appConfig.routes.home]);
    }
  }
}
