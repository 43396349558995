import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MediaType } from '../autogenerated/model';
import { DisplayMode } from '../midgard-controls/enums/display-mode.enum';
import { MediaSelectComponent } from './media-select/media-select.component';

@Injectable({
  providedIn: 'root',
})
export class MediaDialogService {
  constructor(private dialog: MatDialog) {}

  /**
   * Open a Dialog allowing the selection of a single media
   * If submitted, a single mediaId is returned, there must be one
   * If cancelled, null value is returned
   */
  openSelectMediaDialog(
    title: string,
    mediaType: MediaType,
    selectedMediaId: string = null,
    interventionId: string = null
  ): Promise<string | null> {
    const config = {
      id: 'componentModal',
      width: '80%',
      height: '80vh',
      data: {
        title: title,
        mediaType: mediaType,
        interventionId: interventionId,
        selectedMediaIds: selectedMediaId ? [selectedMediaId] : [],
        displayMode: DisplayMode.select,
      },
    };

    const newSelectedMediaId = new Promise<string | null>((resolve) => {
      const dialogRef = this.dialog.open(MediaSelectComponent, config);
      dialogRef.afterClosed().subscribe((mediaId?: string) => {
        resolve(mediaId);
      });
    });
    return newSelectedMediaId;
  }

  /**
   * Open a Dialog allowing the selection of multiple medias
   * If submitted, an array of mediaId is returned, an empty array is possible
   * If cancelled, null value is returned
   */
  openMultiSelectMediaDialog(
    title: string,
    mediaType: MediaType,
    selectedMediaIds: string[] = [],
    interventionId: string = null,
    edithMode: boolean
  ): Promise<string[] | null> {
    const config = {
      id: 'componentModal',
      width: '80%',
      height: '80vh',
      data: {
        title: title,
        mediaType: mediaType,
        interventionId: interventionId,
        selectedMediaIds: selectedMediaIds,
        displayMode: DisplayMode.multiselect,
        edithMode: edithMode,
      },
    };

    const newSelectedMediaIds = new Promise<string[] | null>((resolve) => {
      const dialogRef = this.dialog.open(MediaSelectComponent, config);
      dialogRef.afterClosed().subscribe((mediaIds?: string[]) => {
        resolve(mediaIds ?? []);
      });
    });
    return newSelectedMediaIds;
  }
}
