import { Drone } from '../../autogenerated/model';
import { Action } from '@ngrx/store';
import { ResetStateAction } from '../../core/store';

export enum DroneActionType {
  SetOrganizationDrone = '[Drone] SetOrganizationDrone',
  SetOrganizationDrones = '[Drone] SetOrganizationDrones',
  UpdateDroneAction = '[Drone] UpdateDroneAction',
  DeleteDroneAction = '[Drone] DeleteDroneAction',
}

export class SetOrganizationDroneAction implements Action {
  readonly type = DroneActionType.SetOrganizationDrone;

  readonly organizationId: string;
  readonly drone: Drone;

  constructor(organizationId: string, drone: Drone) {
    this.organizationId = organizationId;
    this.drone = drone;
  }
}

export class SetOrganizationDronesAction implements Action {
  readonly type = DroneActionType.SetOrganizationDrones;

  readonly organizationId: string;
  readonly drones: Drone[];

  constructor(organizationId: string, drones: Drone[]) {
    this.organizationId = organizationId;
    this.drones = drones;
  }
}

export class UpdateDroneAction implements Action {
  readonly type = DroneActionType.UpdateDroneAction;

  readonly organizationId: string;
  readonly drone: Drone;

  constructor(organizationId: string, drone: Drone) {
    this.organizationId = organizationId;
    this.drone = drone;
  }
}

export class DeleteDroneAction implements Action {
  readonly type = DroneActionType.DeleteDroneAction;

  readonly organizationId: string;
  readonly drone: Drone;

  constructor(organizationId: string, drone: Drone) {
    this.organizationId = organizationId;
    this.drone = drone;
  }
}

export type DroneActions =
  | SetOrganizationDronesAction
  | SetOrganizationDroneAction
  | UpdateDroneAction
  | DeleteDroneAction
  | ResetStateAction;
