import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ClipboardService {
  async copyToClipboard(value: string): Promise<void> {
    if (navigator?.clipboard) {
      try {
        await navigator.clipboard.writeText(value);
        return;
      } catch (e) {
        console.error(e);
      }
    }
    this.copyToClipboardForOldBrowsers(value);
  }

  private copyToClipboardForOldBrowsers(value: string): void {
    const element = document.createElement('textarea');
    element.value = value;
    element.style.opacity = '0';
    document.body.appendChild(element);
    try {
      element.select();
      document.execCommand('copy');
    } finally {
      document.body.removeChild(element);
    }
  }
}
