import { DbClass, ActivityStreamItem } from '../model';
import { BackendClientService } from '../../global/backend-client.service';
import { ApiService } from 'src/app/core/api';

export class ActivityStreamApiServiceAutoGenerated {
  constructor(private beClient: BackendClientService, private apiService: ApiService) {}

  async getActivityStream(organization: DbClass): Promise<ActivityStreamItem[]> {
    try {
      const result = await this.beClient
        .post<ActivityStreamItem[]>('activityStreamApiService/getActivityStream', organization)
        .toPromise();

      return result;
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }
}
