import { Component, Inject, OnInit } from '@angular/core';
import { MediaType } from 'src/app/autogenerated/model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DisplayMode } from 'src/app/midgard-controls/enums/display-mode.enum';
import { MediaPageLocation } from '../../midgard-controls/enums/media-page-location.enum';

export interface DialogData {
  title: string;
  mediaType: MediaType;
  interventionId: string;
  selectedMediaIds: string[];
  displayMode: DisplayMode;
  edithMode: boolean;
}

@Component({
  selector: 'app-media-select',
  templateUrl: './media-select.component.html',
  styleUrls: ['./media-select.component.scss'],
})
export class MediaSelectComponent implements OnInit {
  interventionId: string;
  selectedMediaIds: string[] = [];
  displayMode: DisplayMode = DisplayMode.select;
  edithMode: boolean;

  get MediaType() {
    return MediaType;
  }

  get DisplayMode() {
    return DisplayMode;
  }

  get MediaPageLocation() {
    return MediaPageLocation;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public modalData: DialogData,
    private dialogRef: MatDialogRef<MediaSelectComponent>
  ) {}

  ngOnInit(): void {
    this.interventionId = this.modalData.interventionId;
    this.displayMode = this.modalData.displayMode;
    this.edithMode = this.modalData.edithMode;
  }

  onSelectMedia(selectedMediaIds: string[]) {
    this.selectedMediaIds = selectedMediaIds;
  }

  confirmSelection() {
    this.dialogRef.close(this.displayMode === DisplayMode.select ? this.selectedMediaIds[0] : this.selectedMediaIds);
  }

  closeModal() {
    this.dialogRef.close(null);
  }
}
