import { Component, Input } from '@angular/core';

@Component({
  selector: 'tdraw-map-loading-bar',
  templateUrl: './map-loading-bar.component.html',
  styleUrls: ['./map-loading-bar.component.scss'],
})
export class MapLoadingBarComponent {
  @Input()
  percent: number;

  @Input()
  title: string;

  constructor() {}
}
