<!-- TODO: Replace placeholder text with proper icon according to MID-366 -->
<div class="mb-3">
  <app-mid-input
    size="wide"
    [(value)]="value"
    (valueChange)="onValueChange()"
    #locationInput="appMidInput"
    name="locationInput"
    [matAutocomplete]="auto"
    [disabled]="disabled"
    [label]="label"
    [placeholder]="'LOCATION_SEARCH.PLACEHOLDER' | translate"
    [fasType]="'fa-question-circle help'"
    [helpMessage]="'LOCATION_SEARCH.HELP' | translate"
  >
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option *ngFor="let result of locationResults" [value]="result.index">
        {{ result.title }}
      </mat-option>
    </mat-autocomplete>
  </app-mid-input>
</div>
