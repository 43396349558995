<div class="intervention-card" [attr.disabled]="!intervention">
  <div *ngIf="intervention">
    <div class="d-flex justify-content-between mb-3">
      <h5 class="w-100 text-center text-truncate">
        {{ 'COMMON.INTERVENTION' | translate }} № {{ intervention.interventionNumber | defaultValue }}
      </h5>
      <app-mid-icon-button type="secondary" role="button" (action)="goToInterventionSettings()">
        <i class="fas fa-pen"></i>
      </app-mid-icon-button>
    </div>

    <div class="name text-truncate mb-2">
      {{ intervention.name | defaultValue }}
    </div>

    <div class="badges row mx-0 mb-2">
      <app-mid-badge *ngIf="!intervention.isClosed" class="pr-2" badgeClass="badge-success">
        {{ 'INTERVENTION.COMMON.OPENED' | translate }}
      </app-mid-badge>
      <app-mid-badge *ngIf="intervention.isClosed" class="pr-2" badgeClass="badge-danger">
        {{ 'INTERVENTION.COMMON.CLOSED' | translate }}
      </app-mid-badge>
      <app-mid-badge *ngIf="intervention.isArchived" class="pr-2" badgeClass="badge-danger">
        {{ 'INTERVENTION.COMMON.ARCHIVE' | translate }}
      </app-mid-badge>
      <ng-container [ngSwitch]="intervention.type">
        <app-mid-badge *ngSwitchCase="InterventionType.OPS" class="pr-2" badgeClass="badge-danger">
          {{ 'INTERVENTION_TYPE.OPS' | translate }}
        </app-mid-badge>
        <app-mid-badge *ngSwitchCase="InterventionType.PRV" class="pr-2" badgeClass="badge-success">
          {{ 'INTERVENTION_TYPE.PRV' | translate }}
        </app-mid-badge>
        <app-mid-badge *ngSwitchCase="InterventionType.COM" class="pr-2" badgeClass="badge-warning">
          {{ 'INTERVENTION_TYPE.COM' | translate }}
        </app-mid-badge>
        <app-mid-badge *ngSwitchCase="InterventionType.TRAINING" class="pr-2" badgeClass="badge-success">
          {{ 'INTERVENTION_TYPE.TRAINING' | translate }}
        </app-mid-badge>
      </ng-container>
    </div>

    <div class="tags row mx-0 mb-3">
      <div class="tag pr-2" *ngFor="let tag of intervention.tags"><i class="fas fa-tag pr-1"></i> {{ tag }}</div>
    </div>

    <div class="dates mb-3" *ngIf="intervention.startDate || intervention.updatedDate">
      <p class="date" *ngIf="intervention.startDate">
        <span>{{ 'INTERVENTION_CARD.CONTENT.START_AT' | translate }}</span>
        <span>{{ intervention.startDate | date : 'dd/MM/yyyy HH:mm' }}</span>
      </p>
      <p class="date" *ngIf="intervention.updatedDate">
        <span>{{ 'INTERVENTION_CARD.CONTENT.UPDATED_AT' | translate }}</span>
        <span>{{ intervention.updatedDate | date : 'dd/MM/yyyy HH:mm' }}</span>
      </p>
    </div>

    <div class="labels">
      <p>
        <i class="fas fa-helicopter pr-1"></i>
        {{ numberDrones | defaultValue : 0 }} {{ 'COMMON.DRONES' | translate }}
      </p>
      <p>
        <i class="fas fa-photo-video pr-1"></i>
        {{ numberMedia | defaultValue : 0 }} {{ 'COMMON.MEDIA' | translate }}
      </p>
    </div>

    <div class="drone-channel">
      <i class="fas fa-mobile"></i>
      <span>{{ 'INTERVENTION.LABEL.DRONE_CHANNEL' | translate }}: </span>
      {{ intervention.droneChannel | defaultValue }}
    </div>
  </div>
</div>
