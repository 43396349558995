import {
  DbClass,
  OrganizationMedia,
  DroneLiveSnapshotMedia,
  MediaNavigationInfo,
  DroneLiveStreamMedia,
  RenameMediaInput,
  GetPaginationInput,
  MediaFilterResponse,
  MediaType,
  UpdateMediaTags,
  BatchMediaDeleteResults,
} from '../model';
import { BackendClientService, PaginateResult } from '../../global/backend-client.service';
import { ApiService } from 'src/app/core/api';
import { firstValueFrom } from 'rxjs';

export class MediaServiceAutoGenerated {
  constructor(private beClient: BackendClientService, private apiService: ApiService) {}

  async getMediaV1(
    organizationId: string,
    filter?: {
      interventionId?: string;
      type?: MediaType;
      favorites?: boolean;
      name?: string;
      limit?: number;
      offset?: number;
      startDate?: Date;
      endDate?: Date;
    }
  ): Promise<MediaFilterResponse> {
    let result: MediaFilterResponse;
    try {
      let query = '';
      if (filter?.interventionId) {
        query += (query.length ? '&' : '') + 'intervention=' + filter?.interventionId;
      }
      if (filter?.type) {
        query += (query.length ? '&' : '') + 'type=' + filter?.type;
      }
      if (filter?.favorites) {
        query += (query.length ? '&' : '') + 'favorites=true';
      }
      if (filter?.name) {
        query += (query.length ? '&' : '') + 'name=' + filter?.name;
      }
      if (filter?.limit > 0) {
        query += (query.length ? '&' : '') + 'limit=' + filter?.limit;
      }
      if (filter?.offset > -1) {
        query += (query.length ? '&' : '') + 'offset=' + filter?.offset;
      }
      if (filter?.startDate) {
        query += (query.length ? '&' : '') + 'startDate=' + filter?.startDate;
      }
      if (filter?.endDate) {
        query += (query.length ? '&' : '') + 'endDate=' + filter?.endDate;
      }
      query = query.length ? '?' + query : '';
      result = await this.beClient
        .get<MediaFilterResponse>(`api/v1/organization/${organizationId}/media${query}`)
        .toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return result;
  }

  async getMediaNavigationInfoV1(
    organizationId: string,
    mediaId: string,
    filter?: {
      interventionId?: string;
      type?: MediaType;
      name?: string;
    }
  ): Promise<MediaNavigationInfo> {
    let result: MediaNavigationInfo;
    try {
      let query = '';
      if (filter?.interventionId) {
        query += (query.length ? '&' : '') + 'intervention=' + filter?.interventionId;
      }
      if (filter?.type) {
        query += (query.length ? '&' : '') + 'type=' + filter?.type;
      }
      if (filter?.name) {
        query += (query.length ? '&' : '') + 'name=' + filter?.name;
      }
      query = query.length ? '?' + query : '';
      result = await this.beClient
        .get<MediaNavigationInfo>(`api/v1/organization/${organizationId}/media/${mediaId}/navigation${query}`)
        .toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return result;
  }

  async renameMedia(
    organizationId: string,
    mediaId: string,
    renameMediaInput: RenameMediaInput
  ): Promise<OrganizationMedia> {
    let result: OrganizationMedia;
    try {
      result = await this.beClient
        .put<OrganizationMedia>(`api/v1/organization/${organizationId}/media/${mediaId}/name/update`, renameMediaInput)
        .toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return result;
  }

  async updateMediaTags(
    organizationId: string,
    mediaId: string,
    updateMediaTags: UpdateMediaTags
  ): Promise<OrganizationMedia> {
    let updatedMedia: OrganizationMedia;
    try {
      updatedMedia = await this.beClient
        .put<OrganizationMedia>(`api/v1/organization/${organizationId}/media/${mediaId}/tags/update`, updateMediaTags)
        .toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return updatedMedia;
  }

  async toggleFavoriteMedia(organizationId: string, mediaId: string): Promise<OrganizationMedia> {
    let updatedMedia: OrganizationMedia;
    try {
      updatedMedia = await this.beClient
        .put<OrganizationMedia>(`api/v1/organization/${organizationId}/media/${mediaId}/favorite/toggle`, undefined)
        .toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return updatedMedia;
  }

  async sendGPSPosition(
    organizationId: string,
    mediaId: string,
    phoneNumber: string,
    currentMedia: any,
    mapUrl: string,
    mediaShareLink: string
  ): Promise<void> {
    const body = {
      phoneNumber: phoneNumber.trim(),
      latitude: currentMedia.gps.latitude,
      longitude: currentMedia.gps.longitude,
      name: currentMedia.mediaName,
      mapUrl: mapUrl.trim(),
      mediaShareLink: mediaShareLink.trim(),
    };

    try {
      await this.beClient
        .post(`api/v1/organization/${organizationId}/media/${mediaId}/send/sms/shared-media-position`, body)
        .toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }

  async getMedia(input: DbClass): Promise<OrganizationMedia> {
    let result: OrganizationMedia;
    try {
      result = await this.beClient.post<OrganizationMedia>('mediaService/getMedia', input).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return result;
  }

  async deleteMedia(input: DbClass): Promise<OrganizationMedia> {
    let result: OrganizationMedia;
    try {
      result = await this.beClient.post<OrganizationMedia>('mediaService/deleteMedia', input).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return result;
  }

  async deleteMediasInBatch(input: DbClass[]): Promise<BatchMediaDeleteResults[]> {
    let result: BatchMediaDeleteResults[];
    try {
      result = await firstValueFrom(
        this.beClient.post<BatchMediaDeleteResults[]>('mediaService/batchDeleteMedias', input)
      );
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return result;
  }

  async downloadMultipleMedias(organizationId: string, mediaIds: string[]) {
    console.log(organizationId, mediaIds);
    try {
      this.beClient.post(`api/v1/organization/${organizationId}/media/downloadSelected`, { mediaIds: mediaIds });
    } catch (e) {
      console.error(e);
    }
  }

  async getAllMediaIds(
    organizationId: string,
    filter?: {
      interventionId?: string;
      type?: MediaType;
      name?: string;
    }
  ): Promise<string[]> {
    let result: string[];
    try {
      let query = '';
      if (filter?.interventionId) {
        query += (query.length ? '&' : '') + 'intervention=' + filter?.interventionId;
      }
      if (filter?.type) {
        query += (query.length ? '&' : '') + 'type=' + filter?.type;
      }
      if (filter?.name) {
        query += (query.length ? '&' : '') + 'name=' + filter?.name;
      }
      query = query.length ? '?' + query : '';

      result = await firstValueFrom(
        this.beClient.get<string[]>(`api/v1/organization/${organizationId}/media/allMediaIds${query}`)
      );
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return result;
  }
}
