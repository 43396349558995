import { environment } from '../environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, Injector, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { ClipboardModule } from 'ngx-clipboard';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { Interceptor } from './global/interceptor';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MyErrorHandler } from './global/ErrorHandler';
import { UserProfilesModule } from './user-profile/user-profile.module';
import { CoreModule } from './core/core.module';
import { UserManagementModule } from './user-management/user-management.module';
import { appReducer, metaReducersProvider } from './app.reducer';
import { LayoutModule } from './layout/layout.module';
import { AuthenticationModule } from './authentication/authentication.module';
import { StoreModule } from '@ngrx/store';
import { StorageModule } from '@ngx-pwa/local-storage';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DroneModule } from './drone/drone.module';
import { MediaModule } from './media/media.module';
import { AdministrationModule } from './administration/administration.module';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { cookieConfig } from './cookie-consent.config';
import { TdrawModule } from './tdraw/tdraw.module';
import { OrganizationModule } from './organization/organization.module';
import { InterventionModule } from './intervention/intervention.module';
import { featuresToken } from './core/features';
import { appFeatures } from './app-features';
import { CommonModule } from '@angular/common';
import { DashboardModule } from './dashboard/dashboard.module';
import { ServiceLocator } from './global/locator.service';

import { ApiModule, Configuration as ApiConfiguration } from '../api';
import { MidgardApiConfiguration } from '../app/midgard-api-configuration';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';
import localeIt from '@angular/common/locales/it';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
import { AccessCodeLayoutModule } from './access-token-layout/access-code-layout.module';

registerLocaleData(localeFr, 'fr');
registerLocaleData(localeEn, 'en');
registerLocaleData(localeIt, 'it');
registerLocaleData(localeDe, 'de');
registerLocaleData(localeEs, 'es');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ClipboardModule,
    TranslateModule.forRoot({
      defaultLanguage: 'fr',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AuthenticationModule,
    CoreModule,
    StoreModule.forRoot(appReducer, {
      runtimeChecks: {
        strictActionImmutability: false,
        strictStateImmutability: false,
      },
    }),
    LayoutModule,
    DashboardModule,
    AppRoutingModule,
    InterventionModule,
    OrganizationModule,
    UserProfilesModule,
    UserManagementModule,
    DroneModule,
    MediaModule,
    AdministrationModule,
    TdrawModule,
    StorageModule.forRoot({ IDBNoWrap: true }),
    BrowserAnimationsModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    AccessCodeLayoutModule,
    ApiModule,
  ],
  providers: [
    metaReducersProvider,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true,
    },
    { provide: ErrorHandler, useClass: MyErrorHandler },
    { provide: featuresToken, useValue: appFeatures },
    { provide: ApiConfiguration, useClass: MidgardApiConfiguration },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private injector: Injector) {
    ServiceLocator.injector = injector;
  }
}

// we have calls generated in azure media player to the backend, those need credential to
// we simply hook all calls to the backend and add credentials
const origOpen = XMLHttpRequest.prototype.open;
XMLHttpRequest.prototype.open = function () {
  origOpen.apply(this, arguments);
  if (arguments.length > 1) {
    const url: string = arguments[1];
    if (url.startsWith(environment.backend)) {
      this.withCredentials = true;
    }
  }
};
