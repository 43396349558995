import { Component, Input } from '@angular/core';
import { MidExpansionPanelType } from '../enums/expansion-panel-type.enum';

@Component({
  selector: 'app-mid-expansion-panel',
  templateUrl: './mid-expansion-panel.component.html',
  styleUrls: ['./mid-expansion-panel.component.scss'],
})
export class MidExpansionPanelComponent {
  @Input()
  type: MidExpansionPanelType;

  @Input()
  title: string;

  @Input()
  isExpanded: boolean;

  get MidExpansionPanelType() {
    return MidExpansionPanelType;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }
}
