import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout/layout.component';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CoreModule } from '../core/core.module';
import { LayoutStoreModule } from './store';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { OrganizationLandingCardComponent } from './organization-landing-card/organization-landing-card.component';
import { MediaModule } from '../media/media.module';
import { DroneModule } from '../drone/drone.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { StandardNotificationComponent } from './notification/templates/standard-notification/standard-notification.component';
import { PermanentNotificationComponent } from './notification/templates/permanent-notification/permanent-notification.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { InterventionModule } from '../intervention/intervention.module';
import { ActivityStreamModule } from '../activity-stream/activity-stream.module';
import { FeaturesPageComponent } from './features-page/features-page.component';
import { FeaturesFormComponent } from './features-form/features-form.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MidgardControlsModule } from '../midgard-controls/midgard-controls.module';
import { MainAppMenuComponent } from './main-app-menu/main-app-menu.component';
import { MediaCommonModule } from '../media/common/media-common.module';
import { MainMediaMenuComponent } from './main-media-menu/main-media-menu.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    LayoutComponent,
    OrganizationLandingCardComponent,
    StandardNotificationComponent,
    PermanentNotificationComponent,
    FeaturesPageComponent,
    FeaturesFormComponent,
    MainAppMenuComponent,
    MainMediaMenuComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    CoreModule,
    LayoutStoreModule,
    DroneModule,
    MediaModule,
    MediaCommonModule,
    MatSnackBarModule,
    MatMenuModule,
    MidgardControlsModule,
    MatBadgeModule,
    MatTabsModule,
    InterventionModule,
    ActivityStreamModule,
    MidgardControlsModule,
    MatIconModule,
  ],
  exports: [LayoutComponent, FeaturesPageComponent, HeaderComponent],
})
export class LayoutModule {}
