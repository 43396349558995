import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AccessCodeDtoWithToken, OrganizationsService } from 'src/api';
import { UiLanguage } from '../autogenerated/model';
import { LoggerService } from '../core/logger.service';
import { LocalStorageService, SessionStorageService } from '../core/storage';
import { WebSocketService } from '../core/web-socket.service';
import { LayoutStoreService } from '../layout/store';
import { OrganizationStoreService } from '../organization/store';

@Injectable({
  providedIn: 'root',
})
export class AccessTokenAuthenticationService {
  private _accessCode: AccessCodeDtoWithToken;
  private accessToken$ = new Subject<string>();
  getAccessTokenObservable() {
    return this.accessToken$.asObservable();
  }

  get token() {
    return this._accessCode?.token;
  }
  get accessCode() {
    return this._accessCode;
  }

  constructor(private readonly storage: LocalStorageService, private readonly layoutStoreService: LayoutStoreService) {
    this._accessCode = this.storage.get('accessCode');
    if (!!this._accessCode) {
      this.accessToken$.next(this._accessCode.token);
    }
  }

  setAccessCode(accessCode: AccessCodeDtoWithToken) {
    this._accessCode = accessCode;
    this.storage.set('accessCode', accessCode);
    this.accessToken$.next(accessCode?.token);
    this.layoutStoreService.setDefaultLanguage(accessCode?.defaultLanguage || UiLanguage.fr);
  }
}
