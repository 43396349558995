import { Component, OnInit } from '@angular/core';
import { IComponentWithData } from '../../../autogenerated/model';

@Component({
  selector: 'app-SimpleText',
  templateUrl: './SimpleText.component.html',
  styleUrls: ['./SimpleText.component.scss'],
})
export class SimpleTextComponent implements OnInit, IComponentWithData {
  data: { text: string };

  constructor() {}

  ngOnInit() {}
}
