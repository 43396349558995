import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Drone, Intervention } from 'src/app/autogenerated/model';
import { DronesViewStyle } from '../../../midgard-controls/enums/drone-view-style.enum';
import { Subscription } from 'rxjs';
import { LayoutStoreService } from '../../../layout/store';
import _ from 'lodash';
import { ScreenType } from 'src/app/midgard-controls/enums/screen-type.enum';
import { OrganizationService } from 'src/app/autogenerated/organizationService';
import { OrganizationStoreService } from 'src/app/organization/store';
import { Unsubscribe } from '../../../core/decorators';
import { OrganizationGroupService } from 'src/api';

@Unsubscribe()
@Component({
  selector: 'app-drone-list',
  templateUrl: './drone-list.component.html',
  styleUrls: ['./drone-list.component.scss'],
})
export class DroneListComponent implements OnInit, OnDestroy {
  @Input()
  drones: Drone[];

  @Input()
  intervention: Intervention;

  @Output()
  droneDeleted: EventEmitter<Drone> = new EventEmitter<Drone>();

  viewStyle: DronesViewStyle;
  isAdmin: boolean;
  organizationId: string;

  private organizationSubscription: Subscription;
  private droneViewStyleSubscription: Subscription;

  constructor(
    private layoutStoreService: LayoutStoreService,
    private organizationStoreService: OrganizationStoreService,
    private organizationGroupService: OrganizationGroupService,
    private organizationService: OrganizationService
  ) {
    this.isAdmin = false;
    this.viewStyle = DronesViewStyle.block;
  }

  get DronesViewStyle() {
    return DronesViewStyle;
  }

  async ngOnInit() {
    this.subscribeOnOrganization();
    this.subscribeOnDroneViewStyle();
  }

  onDroneDeleted() {
    this.droneDeleted.next(null);
  }

  onMainDroneChange(selectedDrone: Drone) {
    const screenType = this.getScreenType();
    if (screenType === ScreenType.desktop) {
      const selectedDroneId = selectedDrone._id;
      this.swapMainDrone(this.drones, selectedDroneId);
      return;
    }
    return;
  }

  ngOnDestroy(): void {}

  private subscribeOnOrganization() {
    this.organizationSubscription = this.organizationStoreService
      .getCurrentOrganization()
      .subscribe(async (organization) => {
        if (!organization || this.organizationId === organization._id) {
          return;
        }
        this.isAdmin = await this.checkForAdminRole(organization._id);
      });
  }

  private subscribeOnDroneViewStyle() {
    this.droneViewStyleSubscription = this.layoutStoreService
      .getDronesViewStyle()
      .subscribe((dronesViewStyle: DronesViewStyle) => {
        this.viewStyle = dronesViewStyle;
      });
  }

  private async checkForAdminRole(organizationId: string): Promise<boolean> {
    try {
      const isAdmin = await this.organizationGroupService
        .orgOrganizationGroupControllerIsOrgAdmin(organizationId)
        .toPromise();
      return isAdmin;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  private getScreenType() {
    const screenWidth = window.innerWidth;
    const screenType = screenWidth >= 992 ? ScreenType.desktop : ScreenType.mobile;
    return screenType;
  }

  private swapMainDrone(drones: Drone[], newMainDroneId: string) {
    const newMainDronePosition = this.findDronePositionInArray(newMainDroneId);
    [drones[0], drones[newMainDronePosition]] = [drones[newMainDronePosition], drones[0]];
  }

  private findDronePositionInArray(droneId: string): number {
    return _.findIndex(this.drones, (drone) => drone._id === droneId);
  }
}
