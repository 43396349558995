import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { authenticationConfig } from './authentication/authentication.config';
import { RecoverPasswordPageComponent } from './authentication/recover-password-page/recover-password-page.component';
import { LoginPageComponent } from './authentication/login-page/login-page.component';
import { AuthenticationGuard } from './authentication/authentication.guard';
import { LayoutComponent } from './layout/layout/layout.component';
import { MyProfileComponent } from './user-profile/my-profile/my-profile.component';
import { DronesComponent } from './drone/common/drones/drones.component';
import { DroneSingleStreamComponent } from './drone/common/drone-single-stream/drone-single-stream.component';
import { MediaPageComponent } from './media/media-page/media-page.component';
import { MediaAllComponent } from './media/media-all/media-all.component';
import { ShareStreamComponent } from './drone/share-stream/share-stream.component';
import { AdministrationComponent } from './administration/administration/administration.component';
import { OrganizationsComponent } from './administration/organizations/organizations.component';
import { InterventionsPageComponent } from './intervention/interventions-page/interventions-page.component';
import { interventionConfig } from './intervention/intervention.config';
import { InterventionIdResolverService } from './intervention/intervention-id-resolver.service';
import { InterventionTabContainerComponent } from './intervention/intervention-tab-container/intervention-tab-container.component';
import { InterventionPageComponent } from './intervention/intervention-page/intervention-page.component';
import { InterventionTabDashboardComponent } from './intervention/intervention-tab-dashboard/intervention-tab-dashboard.component';
import { InterventionTabStreamingComponent } from './intervention/intervention-tab-streaming/intervention-tab-streaming.component';
import { InterventionTabMapComponent } from './intervention/intervention-tab-map/intervention-tab-map.component';
import { InterventionTabMediaComponent } from './intervention/intervention-tab-media/intervention-tab-media.component';
import { InterventionTabSettingsComponent } from './intervention/intervention-tab-settings/intervention-tab-settings.component';
import { InterventionTabAnalysesComponent } from './intervention/intervention-tab-analyses/intervention-tab-analyses.component';
import { FeaturesPageComponent } from './layout/features-page/features-page.component';
import { TdrawGisEditModePageComponent } from './tdraw/pages/tdraw-gis-edit-mode-page/tdraw-gis-edit-mode-page.component';
import { DashboardPageComponent } from './dashboard/dashboard-page/dashboard-page.component';
import { TdrawGisViewModePageComponent } from './tdraw/pages/tdraw-gis-view-mode-page/tdraw-gis-view-mode-page.component';
import { OrganizationNavConfiguration } from './user-management/organization-nav-configuration';
import { OrganizationTabbedViewComponent } from './user-management/organization-tabbed-view/organization-tabbed-view.component';
import { OrganizationPropertiesRouteComponent } from './user-management/organization-properties-route/organization-properties-route.component';
import { TdrawPicEditModePageComponent } from './tdraw/pages/tdraw-pic-edit-mode-page/tdraw-pic-edit-mode-page.component';
import { TdrawPicViewModePageComponent } from './tdraw/pages/tdraw-pic-view-mode-page/tdraw-pic-view-mode-page.component';
import { OrganizationUserRouteComponent } from './user-management/organization-user-route/organization-user-route.component';
import { OrganizationGroupsRouteComponent } from './user-management/organization-groups-route/organization-groups-route.component';
import { SharedMediaComponent } from './media/shared-media/shared-media.component';
import { TdrawSharedGisPageComponent } from './tdraw/pages/tdraw-shared-gis-page/tdraw-shared-gis-page.component';
import { TdrawSharedPicPageComponent } from './tdraw/pages/tdraw-shared-pic-page/tdraw-shared-pic-page.component';
import { AccessCodeRouteComponent } from './user-management/access-code-route/access-code-route.component';
import { AccessCodeGuard } from './authentication/access-code.guard';
import { AccessCodeLoginRouteComponent } from './access-token-layout/access-code-login-route/access-code-login-route.component';
import { AccessCodeProfileComponent } from './access-token-layout/access-code-profile/access-code-profile.component';
import { InterventionGuard } from './intervention/intervention.guard';
import { OrganizationDistributionListRouteComponent } from './user-management/organization-distribution-list-route/organization-distribution-list-route.component';
import { MapStateIdResolverService } from './tdraw/mapstate-id-resolver.service';

enum customContainerClasses {
  default = 'mid-container',
  map = 'map-mid-container',
  intervention = 'intervention-mid-container',
}

enum customContentClasses {
  default = 'content',
  drone = 'drone-content',
  intervention = 'intervention-content',
  droneStream = 'drone-stream-content',
}

const routes: Routes = [
  { path: authenticationConfig.routes.resetPassword, component: RecoverPasswordPageComponent },
  { path: authenticationConfig.routes.loginAccessCode, component: AccessCodeLoginRouteComponent },

  {
    path: 'my-profile',
    data: { breadcrumb: { label: 'BREADCRUMBS.PROFILE', additionalIcon: 'fa-user' } },
    pathMatch: 'full',
    canMatch: [AccessCodeGuard],
    component: AccessCodeProfileComponent,
  },

  {
    path: '',
    component: LayoutComponent,
    canMatch: [AuthenticationGuard],
    children: [
      {
        path: '',
        component: DashboardPageComponent,
        pathMatch: 'full',
      },
      {
        path: 'my-profile',
        data: { breadcrumb: { label: 'BREADCRUMBS.PROFILE', additionalIcon: 'fa-user' } },
        pathMatch: 'full',
        component: MyProfileComponent,
      },

      // Feature toggle
      {
        path: 'features',
        data: { breadcrumb: { label: 'BREADCRUMBS.FEATURES' } },
        pathMatch: 'full',
        component: FeaturesPageComponent,
      },

      //interventions
      // interventions
      {
        path: 'interventions',
        children: [
          {
            path: '',
            data: {
              breadcrumb: { label: 'BREADCRUMBS.INTERVENTIONS', additionalIcon: 'fa-truck' },
            },
            component: InterventionsPageComponent,
            pathMatch: 'full',
          },
          {
            path: ':interventionId',
            component: InterventionPageComponent,
            canMatch: [InterventionGuard],
            children: [
              {
                path: '',
                component: InterventionTabContainerComponent,
                data: {
                  customClasses: {
                    customContainerClass: customContainerClasses.default,
                    customContentClass: customContentClasses.intervention,
                  },
                  componentType: InterventionTabDashboardComponent,
                  breadcrumb: {
                    label: '',
                    additionalIcon: 'fa-globe',
                    formatterLabel: 'BREADCRUMBS.INTERVENTION_NAME_FORMAT',
                  },
                },
                resolve: {
                  interventionId: InterventionIdResolverService,
                },
              },
              {
                path: interventionConfig.routes.streaming,
                component: InterventionTabContainerComponent,
                data: {
                  customClasses: {
                    customContainerClass: customContainerClasses.default,
                    customContentClass: customContentClasses.intervention,
                  },
                  componentType: InterventionTabStreamingComponent,
                  breadcrumb: {
                    label: '',
                    additionalIcon: 'fa-helicopter',
                    formatterLabel: 'BREADCRUMBS.INTERVENTION_NAME_FORMAT',
                  },
                },
                resolve: {
                  interventionId: InterventionIdResolverService,
                },
              },
              {
                path: `${interventionConfig.routes.tdraw}/home`,
                component: InterventionTabContainerComponent,
                data: {
                  customClasses: {
                    customContainerClass: customContainerClasses.default,
                    customContentClass: customContentClasses.intervention,
                  },
                  componentType: InterventionTabMapComponent,
                  breadcrumb: {
                    label: '',
                    additionalIcon: 'fa-map',
                    formatterLabel: 'BREADCRUMBS.INTERVENTION_NAME_FORMAT',
                  },
                },
                resolve: {
                  interventionId: InterventionIdResolverService,
                },
              },
              {
                path: interventionConfig.routes.media,
                component: InterventionTabContainerComponent,
                data: {
                  customClasses: {
                    customContainerClass: customContainerClasses.default,
                    customContentClass: customContentClasses.intervention,
                  },
                  componentType: InterventionTabMediaComponent,
                  breadcrumb: {
                    label: '',
                    additionalIcon: 'fa-photo-video',
                    formatterLabel: 'BREADCRUMBS.INTERVENTION_NAME_FORMAT',
                  },
                },
                resolve: {
                  interventionId: InterventionIdResolverService,
                },
              },
              {
                path: interventionConfig.routes.settings,
                component: InterventionTabContainerComponent,
                data: {
                  customClasses: {
                    customContainerClass: customContainerClasses.default,
                    customContentClass: customContentClasses.intervention,
                  },
                  componentType: InterventionTabSettingsComponent,
                  breadcrumb: {
                    label: '',
                    additionalIcon: 'fa-cog',
                    formatterLabel: 'BREADCRUMBS.INTERVENTION_NAME_FORMAT',
                  },
                },
                resolve: {
                  interventionId: InterventionIdResolverService,
                },
              },
              {
                path: interventionConfig.routes.analyses,
                component: InterventionTabContainerComponent,
                data: {
                  customClasses: {
                    customContainerClass: customContainerClasses.default,
                    customContentClass: customContentClasses.intervention,
                  },
                  componentType: InterventionTabAnalysesComponent,
                  breadcrumb: {
                    label: '',
                    additionalIcon: 'fa-search',
                    formatterLabel: 'BREADCRUMBS.INTERVENTION_NAME_FORMAT',
                  },
                },
                resolve: {
                  interventionId: InterventionIdResolverService,
                },
              },
            ],
          },
          {
            path: `:interventionId/${interventionConfig.routes.tdraw}`,
            canMatch: [InterventionGuard],
            children: [
              // Tactical drawing: gis
              {
                path: `gis/:mapStateId/view`,
                data: {
                  customClasses: {
                    customContainerClass: customContainerClasses.map,
                    customContentClass: customContentClasses.default,
                  },
                  breadcrumb: { label: 'BREADCRUMBS.TDRAW.GIS_VIEW', additionalIcon: 'fa-map' },
                },
                resolve: {
                  interventionId: InterventionIdResolverService,
                  mapStateId: MapStateIdResolverService,
                },
                pathMatch: 'full',
                component: TdrawGisViewModePageComponent,
              },
              {
                path: `gis/:mapStateId/edit`,
                data: {
                  customClasses: {
                    customContainerClass: customContainerClasses.map,
                    customContentClass: customContentClasses.default,
                  },
                  breadcrumb: { label: 'BREADCRUMBS.TDRAW.GIS_EDIT', additionalIcon: 'fa-map' },
                },
                resolve: {
                  interventionId: InterventionIdResolverService,
                  mapStateId: MapStateIdResolverService,
                },
                pathMatch: 'full',
                component: TdrawGisEditModePageComponent,
              },
              // Tactical drawing: pic
              {
                path: `pic/:mapStateId/view`,
                data: {
                  customClasses: {
                    customContainerClass: customContainerClasses.map,
                    customContentClass: customContentClasses.default,
                  },
                  breadcrumb: { label: 'BREADCRUMBS.TDRAW.PIC_VIEW', additionalIcon: 'fa-map' },
                },
                resolve: {
                  interventionId: InterventionIdResolverService,
                  mapStateId: MapStateIdResolverService,
                },
                pathMatch: 'full',
                component: TdrawPicViewModePageComponent,
              },
              {
                path: `pic/:mapStateId/edit`,
                data: {
                  customClasses: {
                    customContainerClass: customContainerClasses.map,
                    customContentClass: customContentClasses.default,
                  },
                  breadcrumb: { label: 'BREADCRUMBS.TDRAW.PIC_EDIT', additionalIcon: 'fa-map' },
                },
                resolve: {
                  interventionId: InterventionIdResolverService,
                  mapStateId: MapStateIdResolverService,
                },
                pathMatch: 'full',
                component: TdrawPicEditModePageComponent,
              },
            ],
          },
        ],
      },

      // drone & drones
      {
        path: 'drones',
        data: {
          customClasses: {
            customContainerClass: customContainerClasses.default,
            customContentClass: customContentClasses.drone,
          },
          breadcrumb: { label: 'BREADCRUMBS.DRONES', additionalIcon: 'fa-helicopter' },
        },
        pathMatch: 'full',
        component: DronesComponent,
      },
      {
        path: 'drone/:id/stream',
        data: {
          customClasses: {
            customContainerClass: customContainerClasses.default,
            customContentClass: customContentClasses.droneStream,
          },
        },
        pathMatch: 'full',
        component: DroneSingleStreamComponent,
      },

      // organizations
      {
        path: 'organization/:id',
        component: OrganizationTabbedViewComponent,
        children: [
          {
            path: OrganizationNavConfiguration.routes.main,
            component: OrganizationPropertiesRouteComponent,
            pathMatch: 'full',
          },
          {
            path: OrganizationNavConfiguration.routes.users,
            component: OrganizationUserRouteComponent,
            pathMatch: 'full',
          },
          {
            path: OrganizationNavConfiguration.routes.groups,
            component: OrganizationGroupsRouteComponent,
            pathMatch: 'full',
          },
          {
            path: OrganizationNavConfiguration.routes.distribution,
            component: OrganizationDistributionListRouteComponent,
            pathMatch: 'full',
          },
          { path: OrganizationNavConfiguration.routes.profile, component: MyProfileComponent, pathMatch: 'full' },
          {
            path: OrganizationNavConfiguration.routes.accessCode,
            component: AccessCodeRouteComponent,
            pathMatch: 'full',
          },
        ],
      },

      // media
      {
        path: 'media',
        data: { breadcrumb: { label: 'BREADCRUMBS.MEDIA', additionalIcon: 'fa-photo-video' } },
        pathMatch: 'full',
        component: MediaPageComponent,
      },
      {
        path: 'media/:id',
        data: { breadcrumb: { label: 'BREADCRUMBS.MEDIA', additionalIcon: 'fa-photo-video' } },
        pathMatch: 'full',
        component: MediaAllComponent,
      },

      // admin
      { path: 'admin', pathMatch: 'full', component: AdministrationComponent },
      { path: 'admin/organizations', pathMatch: 'full', component: OrganizationsComponent },
    ],
  },

  // share
  { path: 'share/stream/:id', pathMatch: 'full', component: ShareStreamComponent },
  { path: 'share/media/:id', pathMatch: 'full', component: SharedMediaComponent },
  { path: 'share/tdrawgis/:id', pathMatch: 'full', component: TdrawSharedGisPageComponent },
  { path: 'share/tdrawpic/:id', pathMatch: 'full', component: TdrawSharedPicPageComponent },

  { path: '', component: LoginPageComponent },

  // only for static html
  // { path: 'design', pathMatch: 'full', component: MasterStyleComponent },
  // { path: 'test', pathMatch: 'full', component: null }
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
