import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MapStateGroup } from '../../../../models/map-state-group.model';
import { InterventionStoreService } from '../../../../../intervention/store';
import { MapState } from '../../../../models/map-state.model';
import { InfopointData } from '../../../../misc/infopoint-manager';

@Component({
  selector: 'tdraw-history-panel',
  templateUrl: './history-panel.component.html',
  styleUrls: ['./history-panel.component.scss'],
})
export class HistoryPanelComponent implements OnChanges {
  @Input()
  interventionId: string;

  @Input()
  mapStateGroups: MapStateGroup[];

  @Input()
  selectedMapState: MapState;

  @Input()
  infopoint?: InfopointData;

  @Output()
  mapStateSelected: EventEmitter<MapState>;

  @Output()
  mapStatePublish: EventEmitter<MapState>;

  @Output()
  mapStateRename: EventEmitter<MapState>;

  @Output()
  mapStateDuplicate: EventEmitter<MapState>;

  @Output()
  mapStateDownload: EventEmitter<MapState>;

  @Output()
  mapStateDelete: EventEmitter<MapState>;

  @Output()
  newGisVersion: EventEmitter<any>;

  @Output()
  newPicVersion: EventEmitter<any>;

  isArchived = false;

  constructor(readonly translate: TranslateService, private interventionStore: InterventionStoreService) {
    this.mapStateSelected = new EventEmitter();
    this.mapStatePublish = new EventEmitter();
    this.mapStateRename = new EventEmitter();
    this.mapStateDuplicate = new EventEmitter();
    this.mapStateDownload = new EventEmitter();
    this.mapStateDelete = new EventEmitter();
    this.newGisVersion = new EventEmitter();
    this.newPicVersion = new EventEmitter();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (this.interventionId) {
      await this.interventionStore
        .getInterventionById(this.interventionId)
        .subscribe((intervention) => (this.isArchived = intervention.isArchived));
    }
  }

  onMapStateSelected(item: MapState) {
    this.mapStateSelected.next(item);
  }

  onMapStatePublish(item: MapState) {
    this.mapStatePublish.emit(item);
  }

  onMapStateRename(item: MapState) {
    this.mapStateRename.emit(item);
  }

  onMapStateDuplicate(item: MapState) {
    this.mapStateDuplicate.emit(item);
  }

  onMapStateDownload(item: MapState) {
    this.mapStateDownload.emit(item);
  }

  onMapStateDelete(item: MapState) {
    this.mapStateDelete.emit(item);
  }

  onNewGisVersionClick() {
    this.newGisVersion.emit();
  }

  onNewPicVersionClick() {
    this.newPicVersion.emit();
  }
}
