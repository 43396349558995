import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AccessCodeCreationDto, AccessCodeService, InterventionDto, OrganizationDto } from 'src/api';
import { MID_RIGHT_PANEL_DATA } from 'src/app/midgard-controls/services/dom.service';
import _ from 'lodash';
import { MidRightPanelService } from 'src/app/midgard-controls/services/mid-right-panel.service';
import { MidSelectItem } from 'src/app/midgard-controls/models';
import { TranslateService } from '@ngx-translate/core';
import { MidSelectInterventionComboboxComponent } from '../../midgard-controls/mid-select-intervention-combobox/mid-select-intervention-combobox.component';

export class NewAccessCodeComponentData {
  org: OrganizationDto;
}

@Component({
  selector: 'app-new-access-code',
  templateUrl: './new-access-code.component.html',
  styleUrls: ['./new-access-code.component.scss'],
})
export class NewAccessCodeComponent implements OnInit {
  newAccessCode: AccessCodeCreationDto = {
    defaultOrganization: null,
    duration: 7,
    name: '',
    droneAccess: AccessCodeCreationDto.DroneAccessEnum.Intervention,
  };

  droneAccessValues = AccessCodeCreationDto.DroneAccessEnum;

  interventions: InterventionDto[] = [];

  durationOptions = [1, 2, 7, 15, 30];

  @ViewChild('interventionSelection') interventionSelection: MidSelectInterventionComboboxComponent;
  optionsSelectItems: MidSelectItem[];

  constructor(
    @Inject(MID_RIGHT_PANEL_DATA) public readonly data: NewAccessCodeComponentData,
    private readonly midRightPanelService: MidRightPanelService,
    private readonly translate: TranslateService,
    private readonly accessCodeService: AccessCodeService
  ) {}

  async ngOnInit() {
    this.optionsSelectItems = await this.convertDurationInMidSelectItems(this.durationOptions);
  }

  private isInterventionSelected(interventionId: string) {
    return !!_.find(this.interventions, (x) => x.id === interventionId);
  }

  onInterventionSelected(intervention: InterventionDto): void {
    if (!this.isInterventionSelected(intervention?.id)) {
      this.interventions.push(intervention);
    }
    setTimeout(() => {
      this.resetInterventionSelection();
    });
  }
  private resetInterventionSelection() {
    const leftInterventions = _.filter(
      this.interventionSelection.dataSource.allItems,
      (x) => !this.isInterventionSelected(x.id)
    );
    this.interventionSelection.setInterventionSelection(leftInterventions);
    this.interventionSelection.data.selectedIntervention = null;
    this.interventionSelection.data.fieldText = '';
    this.interventionSelection.data.isInterventionNameExists = false;
    this.interventionSelection.interventionSelected = null;
    this.interventionSelection.onFilterChanged('');
  }

  private async convertDurationInMidSelectItems(options) {
    const midSelectItems: MidSelectItem[] = [];
    const singular = ' ' + (await this.translate.get('DURATION.DAY.ONE').toPromise());
    const plural = ' ' + (await this.translate.get('DURATION.DAY.MANY').toPromise());

    for (let option of options) {
      const translatedLabel = option + (option > 1 ? plural : singular);
      const midSelectItem: MidSelectItem = {
        isSelected: false,
        name: translatedLabel,
        value: option,
      };
      midSelectItems.push(midSelectItem);
    }
    return midSelectItems;
  }

  removeIntervention(intervention: InterventionDto) {
    this.interventions = _.filter(this.interventions, (x) => x.id !== intervention.id);
    const leftInterventions = this.interventionSelection.dataSource.allItems.concat([intervention]);
    this.interventionSelection.setInterventionSelection(leftInterventions);
  }
  async create() {
    this.newAccessCode.defaultOrganization = this.data.org.id;
    this.newAccessCode.interventions = _.map(this.interventions, (x) => x.id);
    const accessCode = await this.accessCodeService.accessCodeControllerCreate(this.newAccessCode).toPromise();
    this.midRightPanelService.close(accessCode);
  }
  close() {
    this.midRightPanelService.close();
  }
}
