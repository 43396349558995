import moment from 'moment';
import { MapAnnotationNestedDto, MapStateNestedBackgroundDto, MapStateNestedStatisticsDto } from './dto';
import { MapStateFlightsDto } from './dto/map-state-flights.dto';
import { MapStateNestedMedia } from './map-state-nested-media';
import { RegularMarker } from './regular-marker.model';

export class MapState {
  _id: string;
  name: string;
  background?: MapStateNestedBackgroundDto;
  backgroundMedia?: any;
  type: string;
  interventionId: string;
  organizationId: string;
  creator: {
    _id: string;
    fullname: string;
  };
  createdAt: Date;
  updatedAt: Date;
  publishedAt?: Date;
  annotations?: MapAnnotationNestedDto[];
  medias: MapStateNestedMedia[];
  regularMarkers: RegularMarker[];
  previous?: string;
  next?: string;
  basedOn?: string;
  hasSnapshot: boolean;
  statistics: MapStateNestedStatisticsDto;
  flights: MapStateFlightsDto;

  static buildFromMapStateResponse(dto) {
    const mapState = new MapState();

    mapState._id = dto._id;
    mapState.name = dto.name;
    mapState.creator = dto.creator;
    mapState.createdAt = moment.utc(dto.created_at).local().toDate();
    mapState.background = dto.background;
    mapState.backgroundMedia = dto.backgroundMedia;
    mapState.type = dto.type;
    mapState.annotations = dto.annotations;
    mapState.interventionId = dto.intervention_id;
    mapState.organizationId = dto.organization_id;
    mapState.publishedAt = dto.published_at ? moment.utc(dto.published_at).local().toDate() : null;
    mapState.updatedAt = dto.updated_at ? moment.utc(dto.updated_at).local().toDate() : null;
    mapState.medias = dto.medias ?? [];
    mapState.regularMarkers = dto.regularMarkers ?? [];
    mapState.hasSnapshot = dto.hasSnapshot ?? false;
    mapState.basedOn = dto.based_on;
    mapState.statistics = dto.statistics ?? {
      victims: [],
      engagedResources: [],
      engagedStaff: [],
      otherServices: [],
    };
    mapState.flights = dto.flights ?? {};

    return mapState;
  }
}
