<app-mid-right-panel [title]="'CREATE_ORGANIZATION_USER' | translate">
  <app-mid-right-panel-content>
    <div class="row m-0 px-4 pb-4">
      <section class="section col-md-12">
        <form class="new-organization-user" #newOrganizationUserForm="ngForm" novalidate>
          <app-group-select-dropdown
            *ngIf="features"
            [previousGroups]="selectedGroups"
            [multiple]="isMultipleGroupMode"
            [groups]="allGroups"
            (selectedGroups)="onGroupsChanged($event)"
          >
          </app-group-select-dropdown>
          <app-mid-input
            size="wide"
            type="text"
            #userFullname="appMidInput"
            name="userFullname"
            [required]="true"
            [label]="'FULLNAME' | translate"
            [placeholder]="'FULLNAME' | translate"
            [(value)]="newUser.fullname"
          >
            <span *ngIf="!userFullname.pristine && userFullname.invalid" class="warning-input-text">{{
              'COMMON_REQUIRED_FIELD' | translate
            }}</span>
          </app-mid-input>

          <app-mid-input
            size="wide"
            type="email"
            #userEmail="appMidInput"
            name="userEmail"
            [required]="true"
            [label]="'EMAIL' | translate"
            [placeholder]="'EMAIL' | translate"
            [(value)]="newUser.email"
            (valueChange)="onEmailChange($event)"
          >
            <span *ngIf="!userEmail.pristine && !userEmail.value?.length" class="warning-input-text">{{
              'COMMON_REQUIRED_FIELD' | translate
            }}</span>
            <span
              *ngIf="
                !userEmail.pristine &&
                newOrganizationUserForm.controls.userEmail?.errors?.invalid &&
                userEmail.value?.length
              "
              class="warning-input-text"
              >{{ 'INVALID_EMAIL' | translate }}</span
            >
          </app-mid-input>

          <app-mid-input-phone-number
            size="wide"
            [label]="'DIALOGS.SHARE_DRONE_LINK.PHONE_NUMBER' | translate"
            [required]="true"
            name="phoneNumber"
            #phoneNumber="appMidPhoneNumberInput"
            [(value)]="newUser.phoneNumber"
          >
            <span class="text-danger" *ngIf="phoneNumber.touched && phoneNumber.invalid">
              {{ 'DIALOGS.SHARE_DRONE_LINK.PHONE_VALIDATOR_LABEL' | translate }}</span
            >
          </app-mid-input-phone-number>
        </form>
      </section>
    </div>
  </app-mid-right-panel-content>
  <app-mid-right-panel-footer>
    <div class="row m-0 py-3 px-4">
      <app-mid-button
        class="pr-2"
        type="primary"
        (action)="onSave()"
        [disabled]="newOrganizationUserForm.form?.invalid || !selectedGroups.length"
      >
        {{ 'SAVE' | translate }}
      </app-mid-button>
      <app-mid-button class="pr-2" type="secondary" (action)="onClose()">
        {{ 'COMMON_CANCEL' | translate }}
      </app-mid-button>
    </div>
  </app-mid-right-panel-footer>
</app-mid-right-panel>
