import { Component, Inject, OnInit } from '@angular/core';
import { AuthenticationStoreService } from 'src/app/authentication/store';
import HugeUploader from 'huge-uploader';
import { environment } from 'src/environments/environment';
import { OrganizationDto, OrganizationsService } from 'src/api';
import { take } from 'rxjs/operators';
import { MidRightPanelService } from 'src/app/midgard-controls/services/mid-right-panel.service';
import { MID_RIGHT_PANEL_DATA } from 'src/app/midgard-controls/services/dom.service';

class DataDto {
  org: OrganizationDto;
}

@Component({
  selector: 'app-organization-change-logo',
  templateUrl: './organization-change-logo.component.html',
  styleUrls: ['./organization-change-logo.component.scss'],
})
export class OrganizationChangeLogoComponent implements OnInit {
  constructor(
    @Inject(MID_RIGHT_PANEL_DATA) private readonly data: DataDto,
    private readonly authenticationStoreService: AuthenticationStoreService,
    private readonly midRightPanelService: MidRightPanelService,
    private readonly organizationService: OrganizationsService
  ) {}

  ngOnInit(): void {}

  /** prepare the file list of the upload service */
  async SendLogo(files: File[]) {
    for (const file of files) {
      const token = await this.authenticationStoreService.getAccessToken().pipe(take(1)).toPromise();

      const uploader = new HugeUploader({
        endpoint: environment.backend + `api/v2/organizations/${this.data.org.id}/logo?token=${token}`,
        file: file,
        postParams: { filename: file.name },
      });

      uploader.on('finish', (body) => {
        this.midRightPanelService.close();
        this.organizationService.organizationControllerGetOrganization(this.data.org.id).toPromise();
      });
    }
  }
}
