<app-mid-expansion-panel
  type="primary"
  title="{{ 'INTERVENTION_STATS.OTHER_SERVICES.TITLE' | translate }} ({{ total }})"
  [isExpanded]="isExpanded"
>
  <div class="pl-3">
    <!--
    <app-mid-checkbox
      [items]="services"
      [(selectedItem)]="data.servicesList"
      #checkbox="appMidCheckbox"
      name="checkboxModel"
    ></app-mid-checkbox>
    -->
    <div class="checklist" *ngFor="let item of services; let i = index">
      <input
        *ngIf="defaultServices.includes(item)"
        id="{{ name }}_id_{{ i }}"
        [(ngModel)]="item.isChecked"
        name="{{ name }}_{{ i }}"
        type="checkbox"
        [value]="item.value"
        (change)="onChange($event)"
        [disabled]="!isEditable"
      />
      <input
        *ngIf="!defaultServices.includes(item)"
        class="x-black"
        id="{{ name }}_id_{{ i }}"
        [(ngModel)]="item.isChecked"
        name="{{ name }}_{{ i }}"
        type="checkbox"
        [value]="item.value"
        (change)="onChange($event)"
        [disabled]="!isEditable"
      />
      <label for="{{ name }}_id_{{ i }}">{{ item.name }}</label>
    </div>
  </div>
  <div class="add-other-service" *ngIf="isEditable">
    <label>{{ 'INTERVENTION_STATS.OTHER_SERVICES.OTHER_SERVICE_LABEL' | translate }}</label>
    <div class="input-panel d-flex flex-row d-flex justify-content-between">
      <input
        type="text"
        class="form-control"
        [(ngModel)]="newServiceName"
        name="{{ name }}_newService"
        placeholder="{{ 'INTERVENTION_STATS.OTHER_SERVICES.SERVICE_NAME_PLACEHOLDER' | translate }}"
        #newService
      />
      <button (click)="AddService(newService.value)">{{ 'INTERVENTION_STATS.ADD' | translate }}</button>
    </div>
  </div>
</app-mid-expansion-panel>
