<app-modal-header
  text="{{ 'TDRAW.MODAL.REGULAR_MARKER_CONTENT.TITLE' | translate }}"
  (closeAction)="onClose()"
></app-modal-header>
<div class="body">
  <p class="font-weight-bold">{{ 'TDRAW.MODAL.REGULAR_MARKER_CONTENT.BODY_TITLE' | translate }}</p>
  <p *ngIf="isCreatingMarker">{{ 'TDRAW.MODAL.REGULAR_MARKER_CONTENT.BODY_SUBTITLE' | translate }}</p>

  <app-mid-textarea [(value)]="content" size="wide"> </app-mid-textarea>

  <div class="buttons">
    <app-mid-button type="secondary" (action)="onClose()">{{ 'COMMON_CANCEL' | translate }}</app-mid-button>
    <app-mid-button type="primary" (action)="onSubmit()"
      ><i class="fas fa-check"></i> {{ 'VALIDATE' | translate }}</app-mid-button
    >
  </div>
</div>
