<app-mid-right-panel
  #panelAssociateDroneToIntervention="appMidRightPanel"
  [title]="'LINK_DRONE_TO_INTERVENTION' | translate"
>
  <app-mid-right-panel-content>
    <div class="row m-0 px-4 pb-4">
      <section class="section col-md-12">
        <div class="drone-properties-item">
          <label>{{ 'INTERVENTION_NAME_ASSIGN' | translate }}</label>
          <app-mid-select-intervention-combobox
            (interventionSelected)="getSelectedIntervention($event)"
            [organizationId]="organizationId"
            [excludeClosed]="true"
          ></app-mid-select-intervention-combobox>
        </div>
      </section>
    </div>
  </app-mid-right-panel-content>
  <app-mid-right-panel-footer>
    <div class="row m-0 py-3 px-4">
      <app-mid-button
        type="primary"
        icon="fas fa-check"
        (action)="onSubmit()"
        class="pr-2"
        [disabled]="!selectedIntervention"
        >{{ 'ASSOCIATE' | translate }}</app-mid-button
      >
      <app-mid-button class="pr-2" type="secondary" (action)="panelAssociateDroneToIntervention.close()">{{
        'DIALOGS.COMMON.CANCEL' | translate
      }}</app-mid-button>
    </div>
  </app-mid-right-panel-footer>
</app-mid-right-panel>
