/**
 * MidGard API
 * MidGard API summary
 *
 * OpenAPI spec version: 2.0
 * Contact: contact@midgard-ai.co
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AccessCodeCreationDto } from '../model/accessCodeCreationDto';
import { AccessCodeDto } from '../model/accessCodeDto';
import { AccessCodeDtoWithToken } from '../model/accessCodeDtoWithToken';
import { AccessCodeLoginDto } from '../model/accessCodeLoginDto';
import { AccessCodeUpdateDto } from '../model/accessCodeUpdateDto';
import { ApiHttpException } from '../model/apiHttpException';
import { DroneDto } from '../model/droneDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AccessCodeService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * create Access Code
     * 
     * @param body Access code data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accessCodeControllerCreate(body: AccessCodeCreationDto, observe?: 'body', reportProgress?: boolean): Observable<AccessCodeDto>;
    public accessCodeControllerCreate(body: AccessCodeCreationDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AccessCodeDto>>;
    public accessCodeControllerCreate(body: AccessCodeCreationDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AccessCodeDto>>;
    public accessCodeControllerCreate(body: AccessCodeCreationDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling accessCodeControllerCreate.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<AccessCodeDto>('post',`${this.basePath}/api/v2/access-code`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * remove access code
     * 
     * @param accessCodeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accessCodeControllerDelete(accessCodeId: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public accessCodeControllerDelete(accessCodeId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public accessCodeControllerDelete(accessCodeId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public accessCodeControllerDelete(accessCodeId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (accessCodeId === null || accessCodeId === undefined) {
            throw new Error('Required parameter accessCodeId was null or undefined when calling accessCodeControllerDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('delete',`${this.basePath}/api/v2/access-code/${encodeURIComponent(String(accessCodeId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Access Codes
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accessCodeControllerGetAccessCodes(observe?: 'body', reportProgress?: boolean): Observable<Array<AccessCodeDto>>;
    public accessCodeControllerGetAccessCodes(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AccessCodeDto>>>;
    public accessCodeControllerGetAccessCodes(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AccessCodeDto>>>;
    public accessCodeControllerGetAccessCodes(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<AccessCodeDto>>('get',`${this.basePath}/api/v2/access-code`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the Access Code
     * 
     * @param accessCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accessCodeControllerGetByCode(accessCode: string, observe?: 'body', reportProgress?: boolean): Observable<AccessCodeDto>;
    public accessCodeControllerGetByCode(accessCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AccessCodeDto>>;
    public accessCodeControllerGetByCode(accessCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AccessCodeDto>>;
    public accessCodeControllerGetByCode(accessCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (accessCode === null || accessCode === undefined) {
            throw new Error('Required parameter accessCode was null or undefined when calling accessCodeControllerGetByCode.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<AccessCodeDto>('get',`${this.basePath}/api/v2/access-code/${encodeURIComponent(String(accessCode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the associated drones data
     * 
     * @param accessCodeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accessCodeControllerGetDrones(accessCodeId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<DroneDto>>;
    public accessCodeControllerGetDrones(accessCodeId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DroneDto>>>;
    public accessCodeControllerGetDrones(accessCodeId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DroneDto>>>;
    public accessCodeControllerGetDrones(accessCodeId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (accessCodeId === null || accessCodeId === undefined) {
            throw new Error('Required parameter accessCodeId was null or undefined when calling accessCodeControllerGetDrones.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DroneDto>>('get',`${this.basePath}/api/v2/access-code/${encodeURIComponent(String(accessCodeId))}/drones`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * login with access code
     * 
     * @param body code
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accessCodeControllerLogin(body: AccessCodeLoginDto, observe?: 'body', reportProgress?: boolean): Observable<AccessCodeDtoWithToken>;
    public accessCodeControllerLogin(body: AccessCodeLoginDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AccessCodeDtoWithToken>>;
    public accessCodeControllerLogin(body: AccessCodeLoginDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AccessCodeDtoWithToken>>;
    public accessCodeControllerLogin(body: AccessCodeLoginDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling accessCodeControllerLogin.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<AccessCodeDtoWithToken>('post',`${this.basePath}/api/v2/access-code/login`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Access Code
     * 
     * @param body Access Code new data
     * @param accessCodeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accessCodeControllerUpdate(body: AccessCodeUpdateDto, accessCodeId: string, observe?: 'body', reportProgress?: boolean): Observable<AccessCodeDto>;
    public accessCodeControllerUpdate(body: AccessCodeUpdateDto, accessCodeId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AccessCodeDto>>;
    public accessCodeControllerUpdate(body: AccessCodeUpdateDto, accessCodeId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AccessCodeDto>>;
    public accessCodeControllerUpdate(body: AccessCodeUpdateDto, accessCodeId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling accessCodeControllerUpdate.');
        }

        if (accessCodeId === null || accessCodeId === undefined) {
            throw new Error('Required parameter accessCodeId was null or undefined when calling accessCodeControllerUpdate.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<AccessCodeDto>('put',`${this.basePath}/api/v2/access-code/${encodeURIComponent(String(accessCodeId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
