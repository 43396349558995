<div class=" {{ customClassesService.CustomClasses.customContainerClass }} h-75">
  <!-- header -->
  <app-header class="main-header"></app-header>

  <!-- content -->
  <div class="{{ customClassesService.CustomClasses.customContentClass }} h-100">
    <div class="container h-100">
      <div class="row align-items-center h-100">
        <div class="col-10 col-md-6 mx-auto">
          <div class="jumbotron text-center text-secondary">
            {{ 'ACCESS_CODE.PROFILE' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- footer -->
</div>
