import {
  Component,
  ViewChild,
  ElementRef,
  Input,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  SimpleChange,
  HostListener,
} from '@angular/core';
const VIDEO_SLIDE_BAR_PADDING = 16;
const VIDEO_SLIDE_BAR_HEIGHT = 16;
const VIDEO_THUMB_POINTER_DISTANCE = 32;
const VIDEO_JUMP_TIME = 5;
const VIDEO_JUMP_SHIFT_TIME = 1;
const VIDEO_JUMP_SHIFT_ALT_TIME = 15;

export enum KEY_CODE {
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37,
}

@Component({
  selector: 'app-media-video',
  templateUrl: './media-video.component.html',
  styleUrls: ['./media-video.component.scss'],
})
export class MediaVideoComponent implements OnChanges, AfterViewInit {
  @Input()
  previewUrl: string;

  @Input()
  mediaUrl = '';

  @Input()
  streamTime: Date;

  @ViewChild('video', { static: false })
  video: ElementRef<HTMLVideoElement>;
  @ViewChild('thumb', { static: false })
  thumb: ElementRef<HTMLVideoElement>;
  @ViewChild('thumbContainer', { static: false })
  thumbContainer: ElementRef<HTMLElement>;

  isVideoThumbVisible = false;

  thumbCurrentTime: Date;

  ngOnChanges(changes: SimpleChanges): void {
    const componentChanges = changes as PropertyMap<MediaVideoComponent, SimpleChange>;
    const mediaUrlChange = componentChanges.mediaUrl;
    const isMediaUrlChanged = mediaUrlChange?.currentValue !== mediaUrlChange?.previousValue;
    if (!isMediaUrlChanged) {
      return;
    }
    this.initPlayer();
  }

  ngAfterViewInit(): void {
    this.initPlayer();
  }

  mouseOnVideo(pointerEvent: PointerEvent) {
    const pointerY =
      this.video.nativeElement.parentElement.offsetTop + this.video.nativeElement.clientHeight - pointerEvent.pageY;
    if (pointerY > VIDEO_SLIDE_BAR_HEIGHT) {
      this.isVideoThumbVisible = false;
      return;
    }

    const elementWidth = this.video.nativeElement.clientWidth;
    const position_fraction = (pointerEvent.x - VIDEO_SLIDE_BAR_PADDING) / (elementWidth - 2 * VIDEO_SLIDE_BAR_PADDING);
    if (position_fraction < 0 || position_fraction > 1.0) {
      this.isVideoThumbVisible = false;
      return;
    }

    this.isVideoThumbVisible = true;
    const duration = this.video.nativeElement.duration;
    this.thumb.nativeElement.currentTime = position_fraction * duration;
    this.thumbCurrentTime = this.getCurrentTime(this.thumb.nativeElement.currentTime);

    const thumbX =
      Math.min(Math.max(pointerEvent.pageX, 50), this.video.nativeElement.clientWidth - 50) -
      this.thumbContainer.nativeElement.clientWidth / 2;
    const thumbY = VIDEO_SLIDE_BAR_HEIGHT + VIDEO_THUMB_POINTER_DISTANCE;

    this.thumbContainer.nativeElement.style.left = thumbX + 'px';
    this.thumbContainer.nativeElement.style.bottom = thumbY + 'px';
  }

  mouseLeaveVideo() {
    this.isVideoThumbVisible = false;
  }

  private initPlayer() {
    if (!this.video || !this.video.nativeElement) {
      return;
    }

    this.video.nativeElement.load();
    this.video.nativeElement.muted = true;
    if (!this.thumb || !this.thumb.nativeElement) {
      return;
    }

    this.thumb.nativeElement.load();
    this.thumb.nativeElement.muted = true;
  }

  private getCurrentTime(currentTime: number) {
    let date: Date;
    if (this?.streamTime) {
      // start stream date
      date = new Date(this?.streamTime);
      date.setSeconds(date.getSeconds() + currentTime);
      return date;
    }
    date = new Date();
    date.setHours(0, 0, 0 + currentTime);
    return date;
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.RIGHT_ARROW && !event.shiftKey) {
      this.video.nativeElement.currentTime += VIDEO_JUMP_TIME;
    }

    if (event.keyCode === KEY_CODE.LEFT_ARROW && !event.shiftKey) {
      this.video.nativeElement.currentTime -= VIDEO_JUMP_TIME;
    }

    if (event.shiftKey && event.keyCode === KEY_CODE.RIGHT_ARROW) {
      this.video.nativeElement.currentTime += VIDEO_JUMP_SHIFT_TIME;
    }

    if (event.shiftKey && event.keyCode === KEY_CODE.LEFT_ARROW) {
      this.video.nativeElement.currentTime -= VIDEO_JUMP_SHIFT_TIME;
    }

    if (event.altKey && event.keyCode === KEY_CODE.RIGHT_ARROW) {
      this.video.nativeElement.currentTime += VIDEO_JUMP_SHIFT_ALT_TIME;
    }

    if (event.altKey && event.keyCode === KEY_CODE.LEFT_ARROW) {
      this.video.nativeElement.currentTime -= VIDEO_JUMP_SHIFT_ALT_TIME;
    }
  }
}
