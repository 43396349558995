<div class="mt-2 mb-0 text-center">{{ 'MAP_OPTIONS' | translate }}</div>

<tdraw-location-search-bar
  (onItemSelected)="onLocationSelected($event)"
  [label]="'CENTER_MAP_QUESTION' | translate"
></tdraw-location-search-bar>

<app-mid-expansion-panel title="{{ 'MAP_TYPE' | translate }}" isExpanded="true" type="primary" class="basemap-body">
  <mat-radio-group aria-label="Select an option" [(ngModel)]="mapView">
    <mat-radio-button checked [value]="BackgroundViewType.Streets" (change)="changeMapBackgroundView($event)">{{
      'PLAN' | translate
    }}</mat-radio-button>
    <mat-radio-button disabled>{{ 'IGN' | translate }}</mat-radio-button>
    <mat-radio-button [value]="BackgroundViewType.Satellite" (change)="changeMapBackgroundView($event)">{{
      'SATELLITE' | translate
    }}</mat-radio-button>
    <mat-radio-button [value]="BackgroundViewType.SatelliteStreet" (change)="changeMapBackgroundView($event)">{{
      'SATELLITE_STREET' | translate
    }}</mat-radio-button>
    <mat-radio-button [value]="BackgroundViewType.Dark" (change)="changeMapBackgroundView($event)">{{
      'DARK' | translate
    }}</mat-radio-button>
  </mat-radio-group>
</app-mid-expansion-panel>

<app-mid-expansion-panel title="{{ '3D' | translate }}" isExpanded="true" type="primary">
  <div class="basemap-body">
    <mat-checkbox
      class="ml-0 mb-0"
      (change)="toggleMapBackgroundLayer(BackgroundView3dType.Terrain, $event)"
      [(ngModel)]="hasTerrain"
      >{{ '3D_TERRAIN' | translate }}</mat-checkbox
    >
    <mat-checkbox
      class="ml-0 mb-0"
      (change)="toggleMapBackgroundLayer(BackgroundView3dType.Building, $event)"
      [(ngModel)]="hasBuildings"
    >
      {{ '3D_BUILDINGS' | translate }}</mat-checkbox
    >
  </div>
</app-mid-expansion-panel>

<app-mid-expansion-panel title="{{ 'MAP_INFO' | translate }}" isExpanded="true" type="primary">
  <div class="basemap-body">
    <mat-checkbox
      class="ml-0 mb-0"
      (change)="toggleMapBackgroundLayer(BackgroundInfoType.Dfci, $event)"
      [(ngModel)]="hasDfci"
      >{{ 'DFCI_GRID' | translate }}</mat-checkbox
    >
    <mat-checkbox
      class="ml-0 mb-0"
      (change)="toggleMapBackgroundLayer(BackgroundInfoType.LevelCurves, $event)"
      [(ngModel)]="hasLevels"
      >{{ 'LEVEL_CURVES' | translate }}</mat-checkbox
    >
    <mat-checkbox class="ml-0 mb-0" disabled>{{ 'SIDE_POINTS' | translate }}</mat-checkbox>
  </div>
</app-mid-expansion-panel>

<!-- TODO: future functionality -->
<!-- 
<span class="text-in-lines"> {{ 'OR' | translate | uppercase }} </span>

<p class="choose-label">{{ 'ALSO_CHOOSE_IMAGE' | translate }}</p>

<p class="img-icon">
  <i class="fas fa-image"></i>
</p>

<app-mid-button type="primary" size="wide">{{ 'SELECT_IMAGE' | translate }}</app-mid-button> 
-->
