import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export function debounce(milliseconds: number): MethodDecorator {
  return function decorate(
    target: any,
    propertyKey: string,
    descriptor: PropertyDescriptor
  ): TypedPropertyDescriptor<any> {
    const originalMethod: Function = descriptor.value;
    let originalContext: any = target;
    const subject: Subject<any[]> = new Subject();

    descriptor.value = function (...args: any[]): void {
      originalContext = this;
      subject.next(args);
    };

    subject.pipe(debounceTime(milliseconds)).subscribe((args: any[]) => {
      originalMethod.call(originalContext, ...args);
    });

    return descriptor;
  };
}
