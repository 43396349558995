<div class="panel-container">
  <app-mid-statistics-victims [isEditable]="false" [items]="statistics.victims"></app-mid-statistics-victims>
  <app-mid-statistics-engaged-staff
    [isEditable]="false"
    [items]="statistics.engagedStaff"
  ></app-mid-statistics-engaged-staff>
  <app-mid-statistics-engaged-resources
    [isEditable]="false"
    [items]="statistics.engagedResources"
  ></app-mid-statistics-engaged-resources>
  <app-mid-statistics-other-services
    [isEditable]="false"
    [items]="statistics.otherServices"
  ></app-mid-statistics-other-services>
</div>
<br />
<div class="panel-container" *ngFor="let group of groups">
  <div class="header primary" [class.hidden-layer]="!visibleGroups[group.category]">
    <div class="d-flex">
      <div class="fas fa-layer-group fa-lg header-icon"></div>
      <span>{{ group.name | translate }} ({{ group.items.length }})</span>
    </div>
    <div class="d-flex">
      <div (click)="toggleGroupVisibility(group)">
        <i
          class="fas mr-3"
          [class.fa-eye]="visibleGroups[group.category]"
          [class.hide-icon]="visibleGroups[group.category]"
          [class.fa-eye-slash]="!visibleGroups[group.category]"
        ></i>
      </div>
      <div (click)="toggleGroupExpanded(group)">
        <i
          class="fas"
          [class.fa-chevron-down]="!expandedGroups[group.category]"
          [class.fa-chevron-up]="expandedGroups[group.category]"
        ></i>
      </div>
    </div>
  </div>
  <div *ngIf="expandedGroups[group.category]" class="inner-content">
    <div
      *ngFor="let item of group.items"
      class="w-100 mr-2 l-item inner-content-element"
      [class.bg-info]="item.sourceId === mapModeManager.selectedSourceId"
    >
      <div *ngIf="item.icon" class="annotation-icon {{ item.icon.cssclass }}"></div>
      <img *ngIf="item.type === AnnotationType.Polygon" src="/assets/images/polygon.png" width="40" height="40" />
      <img *ngIf="item.type === AnnotationType.LineString" src="/assets/images/polyline.png" width="40" height="40" />
      <img *ngIf="item.type === AnnotationType.Cone" src="/assets/images/prop-cone.svg" width="40" height="40" />
      <p class="ml-3" (click)="onFeatureClick(item)">{{ item.label }}</p>
      <i
        (click)="toggleItemVisibility(item)"
        class="fas mr-3"
        [class.fa-eye]="visibleItems[item.sourceId]"
        [class.hide-icon]="visibleItems[item.sourceId]"
        [class.fa-eye-slash]="!visibleItems[item.sourceId]"
      ></i>
    </div>
  </div>
</div>

<!-- <div class="text-center mr-5 mt-5 w-100" *ngIf="groups | isEmpty">
  {{ 'TDRAW.LAYERS_EMPTY' | translate }}
</div> -->
