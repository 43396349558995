import { MediaUploadService } from '../media-upload.service';
import { Intervention, Organization } from '../../../../autogenerated/model';
import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrganizationStoreService } from '../../../../organization/store';
import { every, last } from 'lodash';
import { appConfig } from 'src/app/app.config';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { firstValueFrom } from 'rxjs';
import { UploadingFile } from '../models/uploading-file';
import _ from 'lodash';

@UntilDestroy()
@Component({
  selector: 'app-media-upload-dialog',
  templateUrl: './media-upload-dialog.component.html',
  styleUrls: ['./media-upload-dialog.component.scss'],
})
export class MediaUploadDialogComponent implements OnInit, OnDestroy {
  @ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef;

  organizationId: string;
  selectedIntervention: Intervention;
  state: {
    isNotAllowedFileType: boolean;
  };
  allowedFileTypes: string;
  imageTypes: string[];
  videoTypes: string[];
  formattedFileTypes: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { intervention: Intervention },
    private mediaUploadService: MediaUploadService,
    private readonly organizationStoreService: OrganizationStoreService,
    private dialogRef: MatDialogRef<MediaUploadDialogComponent>,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private translate: TranslateService
  ) {
    this.selectedIntervention = data.intervention;
    this.matIconRegistry.addSvgIcon(
      'caution',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/exclamation-triangle.svg')
    );
    this.state = {
      isNotAllowedFileType: false,
    };
  }

  async ngOnInit() {
    if (!this.selectedIntervention) {
      this.organizationStoreService
        .getCurrentOrganization()
        .pipe(untilDestroyed(this))
        .subscribe((organization: Organization) => {
          if (organization?._id === this.organizationId) {
            return;
          }
          this.organizationId = organization._id;
        });
    }

    this.imageTypes = appConfig.allowedImageTypes.map((filetype) => filetype.split(/[\/.]/)[1]);
    this.videoTypes = appConfig.allowedVideoTypes.map((filetype) => filetype.split(/[\/.]/)[1]);
    this.allowedFileTypes = appConfig.allowedImageTypes.join(',') + appConfig.allowedVideoTypes.join(',');
    this.formattedFileTypes = await this.formatFileTypes();
  }

  ngOnDestroy(): void {}

  /** prepare the file list of the upload service */
  prepareAndSendFiles(files: File[]) {
    if (!this.checkFileTypes(files)) {
      this.state.isNotAllowedFileType = true;
      this.fileDropEl.nativeElement.value = '';
      return;
    }

    this.state.isNotAllowedFileType = false;
    const uploadingFiles = _.map(
      files,
      (file: File) => new UploadingFile({ file: file, intervention: this.selectedIntervention })
    );

    this.mediaUploadService.upload(uploadingFiles);
    this.fileDropEl.nativeElement.value = '';
    this.dialogRef.close(true);
  }
  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareAndSendFiles($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareAndSendFiles(files);
  }

  getSelectedIntervention(selectedIntervention: Intervention) {
    this.selectedIntervention = selectedIntervention;
  }

  async formatFileTypes(): Promise<string> {
    const imagesTitle = (await firstValueFrom(this.translate.get('IMAGES'))) + ': ';
    const videosTitle = (await firstValueFrom(this.translate.get('VIDEOS'))) + ': ';
    return imagesTitle + this.imageTypes.join(', ') + '\n' + videosTitle + this.videoTypes.join(', ');
  }

  private checkFileTypes(files: File[]): boolean {
    const allowedTypes = [...this.imageTypes, ...this.videoTypes];
    if (every(files, (file) => allowedTypes.includes(last(file.name?.split('.'))?.toLowerCase()))) {
      return true;
    }

    return false;
  }
}
