import { ActivityNewMedia, IComponentWithData, MediaType } from '../../../autogenerated/model';
import { OrganizationMedia } from 'src/app/autogenerated/model';
import { take } from 'rxjs/operators';
import { AuthenticationStoreService } from '../../../authentication/store';
import { MediaService } from '../../../autogenerated/mediaService';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-media',
  templateUrl: './new-media.component.html',
  styleUrls: ['./new-media.component.scss'],
})
export class NewMediaComponent implements IComponentWithData, OnInit {
  mediaUrl: string;
  data: ActivityNewMedia;
  media: OrganizationMedia;

  get MediaType() {
    return MediaType;
  }

  constructor(private mediaService: MediaService, private authenticationStoreService: AuthenticationStoreService) {}

  async ngOnInit(): Promise<void> {
    if (!this.data?.media) {
      return;
    }

    const mediaId = this.getMediaId(this.data);
    this.media = await this.getMediaById(mediaId);

    if (!this.media) {
      return;
    }

    const token = await this.authenticationStoreService.getAccessToken().pipe(take(1)).toPromise();
    const url = this.mediaService.getDownloadUrl(this.media);
    this.mediaUrl = `${url}?token=${token}`;
  }

  goToMedia() {
    this.mediaService.navigateToMedia(this.media);
  }

  getMediaId(data: ActivityNewMedia) {
    let mediaId = (data.media as OrganizationMedia)?._id;
    if (!mediaId) {
      mediaId = data.media as string;
      return mediaId;
    }
    return null;
  }

  async getMediaById(mediaId: string) {
    try {
      if (mediaId) {
        const media = await this.mediaService.getMedia({ _id: mediaId });
        return media;
      }
      return null;
    } catch (e) {
      console.error(e);
      return null;
    }
  }
}
