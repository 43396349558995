import { Intervention } from '../../../autogenerated/model';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MediaUploadDialogComponent } from './media-upload-dialog/media-upload-dialog.component';
import { InformModalComponent } from 'src/app/shared/modals/inform-modal/inform-modal.component';

@Component({
  selector: 'app-media-upload',
  templateUrl: './media-upload.component.html',
  styleUrls: ['./media-upload.component.scss'],
})
export class MediaUploadComponent {
  @Input()
  intervention: Intervention;

  constructor(public dialog: MatDialog) {}

  openDialog() {
    const config = {
      id: 'newMediaUpload',
      width: '80%',
      data: {
        intervention: this.intervention && this.intervention.isArchived !== true ? this.intervention : null,
      },
    };

    const dialogRef = this.dialog.open(MediaUploadDialogComponent, config);

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.dialog.open(InformModalComponent, {
          width: '450px',
          data: {
            message: 'FILE_UPLOADING_INFO_MESSAGE',
          },
        });
      }
    });
  }
}
