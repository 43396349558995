import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { firstValueFrom, Subscription } from 'rxjs';
import { MediaService } from 'src/app/autogenerated/mediaService';
import {
  DbClass,
  Intervention,
  MediaEventType,
  MediaFilterResponse,
  MediaType,
  Organization,
  OrganizationMedia,
} from 'src/app/autogenerated/model';
import { Unsubscribe } from 'src/app/core/decorators';
import { LoggerService } from 'src/app/core/logger.service';
import { WebSocketService } from 'src/app/core/web-socket.service';
import { TemplateService } from 'src/app/global/template.service';
import { rightPanelDisplayState } from 'src/app/midgard-controls/enums/display-state.enum';
import { OrganizationStoreService } from 'src/app/organization/store';
import { MediaUploadService } from '../common/media-upload/media-upload.service';
import { DisplayMode } from '../../midgard-controls/enums/display-mode.enum';
import { RightPanelType } from 'src/app/midgard-controls/enums/right-panel-type';
import { OrganizationGroupService } from 'src/api';
import { MediaPageLocation } from '../../midgard-controls/enums/media-page-location.enum';
import { appConfig } from 'src/app/app.config';
import { GroupedItemsData, DataSourceService } from '../../core/collections';
import { ConfirmModalService } from 'src/app/midgard-controls/services/confirm-modal.service';
import { MediaOperationsService } from '../common/media-operations.service';

export interface IMediaFilter {
  interventionId: string | null;
  searchRequest: string;
  type: MediaType | null;
  more: null | {
    startDate?: Date | null;
    endDate?: Date | null;
  };
  limit: number;
  offset: number;
}

interface MediaDataState {
  foundItemsCount: number;
  groupedMedia: GroupedItemsData<OrganizationMedia>[];
  favoriteMedia: OrganizationMedia[];
  mediaList: OrganizationMedia[];
}

interface NextMedia {
  favorites: MediaFilterResponse;
  media: MediaFilterResponse;
}

@Unsubscribe()
@Component({
  selector: 'app-media-page',
  templateUrl: './media-page.component.html',
  styleUrls: ['./media-page.component.scss'],
})
export class MediaPageComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  intervention: Intervention;

  @Input()
  interventionId: string; // TODO: duplicate with intervention

  @Input()
  isInterventionRequired: boolean; // TODO: duplicate with intervention

  @Input()
  hasAdminPermissions = false;

  @Input()
  displayMode: DisplayMode = DisplayMode.default;

  @Input()
  mediaType: MediaType;

  @Input()
  enableFavorite = true;

  @Input()
  mediaPageLocation: MediaPageLocation;

  @Input()
  edithMode: boolean;

  @Output()
  onSelectMedia: EventEmitter<string[]> = new EventEmitter();

  selectedMediaIds: string[] = [];
  organization: Organization;

  state: {
    isLoading: boolean;
    canDelete: boolean;
    rightPanelDisplayState: rightPanelDisplayState;
    selectedMediaProperties: OrganizationMedia;
    filter: IMediaFilter;
    infiniteScrollSelector: string;
    multiselect: boolean;
  };

  dataState: MediaDataState;

  private organizationSubscription: Subscription;
  private incomingDataSubscription: Subscription;
  private refreshDataSubscription: Subscription;
  private mediaUploadSubscription: Subscription;

  private multiselectStartTimeout: NodeJS.Timeout;
  private multiselectEndTimeout: NodeJS.Timeout;

  get RightPanelType() {
    return RightPanelType;
  }

  get InfiniteScrollConfig() {
    return appConfig.infiniteScroll;
  }

  get DisplayMode() {
    return DisplayMode;
  }

  MULTISELECT_DELAY = 200;
  RESET_DELAY = 500;

  constructor(
    public mediaUploadService: MediaUploadService,
    private templateService: TemplateService,
    private organizationStoreService: OrganizationStoreService,
    private activatedRoute: ActivatedRoute,
    private mediaService: MediaService,
    private loggerService: LoggerService,
    private webSocketService: WebSocketService,
    readonly translate: TranslateService,
    private organizationGroupService: OrganizationGroupService,
    private readonly dataSourceService: DataSourceService,
    private confirmModalService: ConfirmModalService,
    private mediaOperationsService: MediaOperationsService
  ) {
    // header name component
    // TODO: DS check
    this.templateService.$activeComponent.next('MEDIA');
    this.state = {
      isLoading: false,
      canDelete: false,
      rightPanelDisplayState: rightPanelDisplayState.hidden,
      selectedMediaProperties: null,
      infiniteScrollSelector: '.content',
      multiselect: false,
      filter: {
        interventionId: null,
        searchRequest: '',
        type: null,
        more: null,
        limit: appConfig.media.pageSize,
        offset: 0,
      },
    };

    this.dataState = {
      foundItemsCount: 0,
      groupedMedia: [],
      favoriteMedia: [],
      mediaList: [],
    };

    this.state.rightPanelDisplayState = rightPanelDisplayState.hidden;

    // header name component
    this.templateService.$activeComponent.next('MEDIA');

    this.activatedRoute.params.subscribe(async (params) => {
      const interventionId = params['id'];
      if (interventionId) {
        this.interventionId = interventionId;
        this.state.filter.interventionId = interventionId;
      }
    });

    this.organizationSubscription = this.organizationStoreService.getCurrentOrganization().subscribe(async (org) => {
      if (!org) {
        return;
      }
      this.organization = org;

      this.state.canDelete = await firstValueFrom(
        this.organizationGroupService.orgOrganizationGroupControllerIsOrgAdmin(org._id)
      );
    });

    this.incomingDataSubscription = this.webSocketService.incomingData.subscribe(
      async (message: { media: OrganizationMedia; type: MediaEventType }) => {
        if (!message || !message.media || !message.type) {
          return;
        }
        // is a new media?
        if (message.type.toString() !== MediaEventType.NewMedia.toString()) {
          return;
        }
        // of this intervention?
        const media: OrganizationMedia = message.media;
        if (media.intervention !== this.interventionId) {
          return;
        }

        await this.loadMedia();
      }
    );

    this.refreshDataSubscription = this.mediaUploadService.mediaUploadFinished.subscribe(() => {
      this.loadMedia();
    });
  }

  async ngOnInit() {
    this.subscribeMultiselect();

    if (!this.isInterventionRequired) {
      await this.loadMedia();
    }

    switch (this.mediaPageLocation) {
      case MediaPageLocation.intervention:
        this.state.infiniteScrollSelector = '.interventionID-content';
        break;
      case MediaPageLocation.mediaSelect:
        this.state.infiniteScrollSelector = '.dialog-body';
        break;
      default:
        this.state.infiniteScrollSelector = '.content';
    }

    this.mediaUploadSubscription = this.mediaUploadService.filesChange$.subscribe(async (uploadingFiles) => {
      const loadingMedia = await Promise.all(
        uploadingFiles.map(async (file) => {
          const currentDate = new Date();

          return new OrganizationMedia({
            creationDate: currentDate,
            __guid: file.guid,
            __isLoading: true,
          });
        })
      );

      const newMediaList = [...loadingMedia, ...this.dataState.mediaList];

      this.dataState.groupedMedia = this.dataSourceService.groupedItems(newMediaList, 'day', 'creationDate');
    });
  }

  ngOnDestroy(): void {
    window.removeEventListener('keydown', this.keydownEventListener);
  }

  async ngOnChanges(changes: SimpleChanges) {
    const componentChanges = changes as PropertyMap<MediaPageComponent, SimpleChange>;

    const interventionIdChange = componentChanges.interventionId;
    if (interventionIdChange && interventionIdChange.currentValue) {
      await this.loadMedia();
    }

    const mediaTypeChange = componentChanges.mediaType;
    if (mediaTypeChange && mediaTypeChange.currentValue) {
      this.state.filter.type = this.mediaType;
      await this.onMediaFilterChange();
    }
  }

  onDisplaySelectedMediaProperties(media: OrganizationMedia) {
    this.state.selectedMediaProperties = media;
    this.state.rightPanelDisplayState = rightPanelDisplayState.displaying;
  }

  onChangeRightPanelDisplayState(state: rightPanelDisplayState) {
    this.state.rightPanelDisplayState = state;
  }

  async onDeleteMedia(mediaId: string) {
    try {
      await this.mediaService.deleteMedia({ _id: mediaId });
      await this.loadMedia();
    } catch (error) {
      console.error(error);
    }
  }

  async onRefreshMedia(mediaId: string) {
    try {
      await this.loadMedia();
    } catch (error) {
      console.error(error);
    }
  }

  async onMediaFilterChange() {
    this.state.filter.offset = 0;
    this.state.filter.limit = appConfig.media.pageSize;
    await this.loadMedia();
  }

  async loadMoreMedia() {
    this.state.isLoading = true;
    this.state.filter.offset += appConfig.media.pageSize;

    try {
      await this.loadMedia(true);
    } finally {
      this.state.isLoading = false;
    }
  }

  selectMediaId(mediaId: string) {
    if (this.displayMode === DisplayMode.select) {
      if (this.selectedMediaIds[0] === mediaId) {
        this.selectedMediaIds = [];
      } else {
        this.selectedMediaIds = [mediaId];
      }
    }

    if (this.displayMode === DisplayMode.multiselect) {
      const index = this.selectedMediaIds.indexOf(mediaId);
      if (index === -1) {
        this.selectedMediaIds.push(mediaId);
      } else {
        this.selectedMediaIds.splice(index, 1);
      }
    }

    this.onSelectMedia.emit(this.selectedMediaIds);
  }

  onSwitchSelectMode(displayMode: DisplayMode) {
    if (displayMode == DisplayMode.default) {
      this.selectedMediaIds = [];
    }
    this.displayMode = displayMode;
  }

  onSwitchMultiselectMode() {
    this.selectedMediaIds = [];
  }

  async onDeleteSelectedMedias() {
    const confirmed = await this.confirmModalService.showConfirmModal(
      'MEDIA_PAGE.DELETE_SELECTED_MEDIAS_CONFIRM_MESSAGE',
      'remove',
      'left'
    );
    if (confirmed) {
      const mappedMediaIds = _.map(this.selectedMediaIds, (mediaId) => new DbClass({ _id: mediaId }));

      try {
        await this.mediaService.deleteMediasInBatch(mappedMediaIds);
        await this.loadMedia();
        this.selectedMediaIds = [];
      } catch (e) {
        console.error(e);
      }
    }
  }

  async getDownloadPath(mediaIds: string[]): Promise<string[]> {
    if (!this.organization) {
      return [];
    }

    const mediasPathPromises = mediaIds.map(async (mediaId) => {
      const media = await this.mediaService.getMedia({ _id: mediaId });
      return this.mediaService.getDownloadUrl(media, true);
    });

    return Promise.all(mediasPathPromises);
  }

  async onDownloadSelectedMedias() {
    if (!this.organization?._id) {
      return;
    }

    const mediasPaths = await this.getDownloadPath(this.selectedMediaIds);

    const mediaInfoPromises = this.selectedMediaIds.map(async (mediaId, index) => {
      const media = await this.mediaService.getMedia({ _id: mediaId });
      return { path: mediasPaths[index], media };
    });

    const mediaInfos = await Promise.all(mediaInfoPromises);

    for (const mediaInfo of mediaInfos) {
      this.mediaOperationsService.downloadMedia(mediaInfo.path, mediaInfo.media);
    }
  }

  async onSelectAllMedias() {
    if (this.organization?._id && this.state.filter?.interventionId) {
      this.selectedMediaIds = await this.mediaService.getAllMediaIds(this.organization._id, this.state.filter);
    }
  }

  async onToggleFavoriteMedia() {
    await this.loadMedia();
  }

  trackByItem(index: number, item: { key: Date; value: OrganizationMedia[] }): string {
    return item.key.toISOString();
  }

  private keydownEventListener = (event) => {
    if (!this.edithMode) {
      return;
    }

    if (event.key === 'c' && event.ctrlKey) {
      clearTimeout(this.multiselectEndTimeout);
      clearTimeout(this.multiselectStartTimeout);

      this.multiselectStartTimeout = setTimeout(this.startMultiselectMode, 200);
    } else {
      this.displayMode = DisplayMode.default;
    }
  };

  private startMultiselectMode = () => {
    this.displayMode = DisplayMode.multiselect;
    this.multiselectEndTimeout = setTimeout(this.endMultiselectMode, 500);
  };

  private endMultiselectMode = () => {
    this.displayMode = DisplayMode.default;
    this.selectedMediaIds = [];
  };

  // some issues with check "Control"
  // private keydownEventListener = (event) => {
  //   // check "Control" only
  //   console.log('here', event.key);
  //   if (!event.ctrlKey || event.key !== 'Control') {
  //     clearTimeout(this.multiselectStartTimeout);
  //     return;
  //   }
  //
  //   clearTimeout(this.multiselectEndTimeout);
  //   clearTimeout(this.multiselectStartTimeout);
  //
  //   this.multiselectStartTimeout = setTimeout(() => {
  //     this.displayMode = DisplayMode.multiselect;
  //     this.multiselectEndTimeout = setTimeout(() => {
  //       this.displayMode = DisplayMode.default;
  //       this.selectedMediaIds = [];
  //     }, 500);
  //   }, 200);
  // };

  private subscribeMultiselect() {
    window.addEventListener('keydown', this.keydownEventListener);
  }

  private async loadMedia(shouldAppendCurrentMediaToNext = false): Promise<void> {
    if (!this.organization?._id) {
      return;
    }

    if (this.interventionId && this.interventionId !== this.state.filter.interventionId) {
      this.state.filter.interventionId = this.interventionId;
    }

    const nextMedia = await this.getNextMedia(
      this.state.filter,
      this.enableFavorite
    );

    if (!nextMedia) {
      this.dataState = this.getDataDefaultState();
      return;
    }

    const appendedMedia: NextMedia = !shouldAppendCurrentMediaToNext
      ? nextMedia
      : this.appendCurrentMediaToNext(nextMedia, this.dataState.mediaList);

    const mediaDataState = this.prepareMediaDataState(appendedMedia.favorites, appendedMedia.media);

    this.dataState = mediaDataState;
  }

  private appendCurrentMediaToNext(nextMedia: NextMedia, existingMedia: OrganizationMedia[]): NextMedia {
    const newMediaList = {
      ...nextMedia,
      media: {
        ...nextMedia.media,
        results: [...existingMedia, ...nextMedia.media.results],
      },
    };
    return { ...nextMedia, ...newMediaList };
  }

  private async getNextMedia(
    mediaFilter: IMediaFilter,
    enableFavorite: boolean
  ): Promise<{ favorites: MediaFilterResponse; media: MediaFilterResponse } | null> {
    const organizationId = this.organization?._id;
    if (!organizationId) {
      return;
    }

    try {
      const favoritesPromise = enableFavorite
        ? this.mediaService.getMediaV1(organizationId, {
            favorites: true,
            interventionId: mediaFilter.interventionId || null,
            limit: 128,
            type: mediaFilter.type,
            name: mediaFilter.searchRequest || null,
          })
        : null;
      const mediaPromise = this.mediaService.getMediaV1(organizationId, {
        interventionId: mediaFilter.interventionId || null,
        offset: mediaFilter.offset,
        limit: mediaFilter.limit,
        type: mediaFilter.type,
        name: mediaFilter.searchRequest || null,
        startDate: mediaFilter.more?.startDate || null,
        endDate: mediaFilter.more?.endDate || null,
      });

      const [favorites, media] = await Promise.all([favoritesPromise, mediaPromise]);

      return { favorites, media };
    } catch (error) {
      this.loggerService.captureException(error);
    }

    return null;
  }

  private prepareMediaDataState(favorites: MediaFilterResponse, media: MediaFilterResponse): MediaDataState {
    try {
      const favoriteMediaResult = favorites?.results?.length ? favorites.results : [];

      const groupedMedia = this.dataSourceService.groupedItems(media.results, 'day', 'creationDate');

      return {
        foundItemsCount: media.count,
        favoriteMedia: favoriteMediaResult,
        mediaList: media.results,
        groupedMedia,
      };
    } catch (error) {
      this.loggerService.captureException(error);
    }

    return this.getDataDefaultState();
  }

  private getDataDefaultState(): MediaDataState {
    return {
      foundItemsCount: 0,
      groupedMedia: [],
      favoriteMedia: [],
      mediaList: [],
    } as MediaDataState;
  }
}
