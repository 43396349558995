import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BackgroundInfoType } from 'src/app/tdraw/models/dto/enums/background-info-type.enum';
import { BackgroundView3dType } from 'src/app/tdraw/models/dto/enums/background-view-3d-type.enum';
import { BackgroundViewType } from 'src/app/tdraw/models/dto/enums/background-view-type.enum';
import { LocationSearchResult } from 'src/app/tdraw/models/location-search-result.model';
import { GisManager } from 'src/app/tdraw/misc/gis-manager';

@Component({
  selector: 'tdraw-basemap-tab',
  templateUrl: './basemap-tab.component.html',
  styleUrls: ['./basemap-tab.component.scss'],
})
export class BasemapTabComponent implements OnInit {
  @Input()
  gisManager: GisManager;

  @Output()
  selectLocation: EventEmitter<LocationSearchResult>;

  // Map Style
  mapView: BackgroundViewType = BackgroundViewType.Streets;
  // 3D
  hasBuildings: boolean = false;
  hasTerrain: boolean = false;
  // Others
  hasDfci: boolean = false;
  hasLevels: boolean = false;

  // Enums
  get BackgroundViewType() {
    return BackgroundViewType;
  }
  get BackgroundView3dType() {
    return BackgroundView3dType;
  }
  get BackgroundInfoType() {
    return BackgroundInfoType;
  }

  constructor() {
    this.selectLocation = new EventEmitter();
  }

  ngOnInit() {
    this.gisManager.onMapBackgroundChanged((data) => {
      this.hasDfci = data.info.includes(BackgroundInfoType.Dfci);
      this.hasLevels = data.info.includes(BackgroundInfoType.LevelCurves);
      this.hasBuildings = data.view3d.includes(BackgroundView3dType.Building);
      this.hasTerrain = data.view3d.includes(BackgroundView3dType.Terrain);
      this.mapView = data.view;
    });
  }

  onLocationSelected(result: LocationSearchResult) {
    this.selectLocation.emit(result);
  }

  toggleMapBackgroundLayer(type: BackgroundInfoType | BackgroundView3dType, event) {
    this.gisManager.toggleMapBackgroundLayer(type, event.checked);
  }

  changeMapBackgroundView(event) {
    this.gisManager.changeMapBackgroundView(event.value);
  }
}
