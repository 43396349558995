import { BackgroundInfoType } from './enums/background-info-type.enum';
import { BackgroundView3dType } from './enums/background-view-3d-type.enum';
import { BackgroundViewType } from './enums/background-view-type.enum';

export class MapStateNestedBackgroundDto {
  center: [number, number];
  zoom?: number;
  view?: BackgroundViewType;
  view3d?: BackgroundView3dType[];
  info?: BackgroundInfoType[];

  constructor(init: MapStateNestedBackgroundDto) {
    this.center = init?.center;
    this.zoom = init?.zoom || 1;
    this.view = init?.view || BackgroundViewType.Streets;
    this.view3d = init?.view3d || [];
    this.info = init?.info || [];
  }
}
