<div class="organization-access-code">
  <div class="container-fluid">
    <div class="organization-access-code-btn">
      <app-mid-button type="primary" icon="fas fa-plus" (action)="onAddAccessCodeClick()">{{
        'ACCESS_CODE.CREATE' | translate
      }}</app-mid-button>
    </div>
    <div class="organizations-access-code-wrap">
      <table class="organizations-access-code-list" [class.overlay-loader]="state.isLoading">
        <tr class="organization-item-header">
          <th width="150">
            <div class="organization-item-header-col">
              <div>
                {{ 'NAME' | translate }}
              </div>
            </div>
          </th>
          <th width="110">
            <div class="organization-item-header-col">
              <div>
                {{ 'ACCESS_CODE.CODE' | translate }}
                &nbsp;
              </div>
            </div>
          </th>
          <th width="200">
            <div class="organization-item-header-col">
              <div>
                {{ 'INTERVENTIONS' | translate }}
              </div>
            </div>
          </th>
          <th width="160">
            <div class="organization-item-header-col">
              <div>
                {{ 'ACCESS_CODE.VALID_UNTIL' | translate }}
              </div>
            </div>
          </th>
          <th width="150">
            <div class="organization-item-header-col">
              <div>
                {{ 'ACCESS_CODE.CREATED_BY' | translate }}
              </div>
            </div>
          </th>
          <th width="110">
            <div class="organization-item-header-col">
              <div>
                {{ 'QR_CODE' | translate }}
              </div>
            </div>
          </th>
          <th width="100">
            <div class="organization-item-header-col">
              <div>
                {{ 'ACTION_TABLE' | translate }}
              </div>
            </div>
          </th>
        </tr>
        <tbody *ngIf="!state.isLoading || accessCodes.length > 0">
          <tr *ngFor="let accessCode of accessCodes">
            <td>
              <div class="organization-access-code-item-col">
                <div>
                  {{ accessCode.name }}
                </div>
              </div>
            </td>
            <td>
              <div class="organization-access-code-item-col">
                <div class="row p-0 m-0 w-100">
                  <div class="col p-0 m-0">
                    <span *ngIf="!state.visibleAccessCodes.has(accessCode.id)">######</span>
                    <span *ngIf="state.visibleAccessCodes.has(accessCode.id)">{{ accessCode.code }}</span>
                  </div>
                  <div class="col-3 p-0 m-0 text-center">
                    <i
                      class="fas fa-eye menu-component-icon"
                      (click)="showCodes(accessCode)"
                      *ngIf="!state.visibleAccessCodes.has(accessCode.id)"
                    ></i>
                    <i
                      class="fas fa-eye-slash menu-component-icon"
                      (click)="hideCodes(accessCode)"
                      *ngIf="state.visibleAccessCodes.has(accessCode.id)"
                    ></i>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <div class="organization-access-code-item-col">
                <div>
                  <!-- TBD:make a decent list -->
                  <span *ngFor="let intervention of accessCode.interventionDtos; let isLast = last"
                    >{{ intervention.name }} {{ isLast ? '' : ',' }}
                  </span>
                </div>
              </div>
            </td>
            <td>
              <div class="organization-access-code-item-col">
                <div>
                  {{ accessCode.validUntil | date : 'dd/MM/yyyy HH:mm' }}
                </div>
              </div>
            </td>
            <td>
              <div class="organization-access-code-item-col">
                <div>{{ accessCode.createdByDto.fullname }}</div>
              </div>
            </td>
            <td>
              <div class="organization-access-code-item-col">
                <div>
                  <i class="fa fa-qrcode" (click)="showQrCode(accessCode)"></i>
                </div>
              </div>
            </td>
            <td>
              <div class="organization-access-code-item-col">
                <div>
                  <button class="organization-access-code-item-btn" (click)="delete(accessCode)">
                    <i class="fa fa-times"></i>
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="state.isLoading || accessCodes.length == 0">
          <tr>
            <td [colSpan]="5">
              {{ 'ACCESS_CODE.NO_ACCESS_CODE_FOUND' | translate }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
