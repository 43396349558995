import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { OrganizationGroupCreationDto } from '../../../api';
import { MidSelectItem } from '../../midgard-controls/models';
import { forEach, isEmpty } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-role-select',
  templateUrl: './role-select.component.html',
  styleUrls: ['./role-select.component.scss'],
})
export class RoleSelectComponent implements OnInit {
  @Output()
  selectedRole: EventEmitter<OrganizationGroupCreationDto.RoleEnum>;

  roles: OrganizationGroupCreationDto.RoleEnum[] = [
    OrganizationGroupCreationDto.RoleEnum.None,
    OrganizationGroupCreationDto.RoleEnum.Member,
    OrganizationGroupCreationDto.RoleEnum.Admin,
    OrganizationGroupCreationDto.RoleEnum.DroneOperator,
  ];
  rolesSelectItems: MidSelectItem[];
  currentRole: OrganizationGroupCreationDto.RoleEnum;

  constructor(private translate: TranslateService) {
    this.rolesSelectItems = [];
    this.selectedRole = new EventEmitter();
    this.currentRole = null;
  }

  get isEmpty() {
    return isEmpty;
  }

  async ngOnInit(): Promise<void> {
    this.rolesSelectItems = await this.convertRolesToMidSelectItems(this.roles);
  }

  onRoleSelected(selectedRole: string) {
    const role = OrganizationGroupCreationDto.RoleEnum[selectedRole];
    this.currentRole = role;
    this.selectedRole.emit(this.currentRole);
  }

  private async convertRolesToMidSelectItems(roles: OrganizationGroupCreationDto.RoleEnum[]): Promise<MidSelectItem[]> {
    const selectItems = [];
    forEach(roles, async (role) => {
      const translateRoleName = await this.translateRoleName(role);
      const selectItem: MidSelectItem = {
        name: translateRoleName,
        value: role,
        isSelected: false,
      };
      selectItems.push(selectItem);
    });

    return selectItems;
  }

  private async translateRoleName(roleName: string): Promise<string> {
    if (!roleName) {
      return '';
    }
    const translateRoleName = await this.translate.get('GROUP_ROLE.' + roleName.toUpperCase()).toPromise();
    return translateRoleName;
  }
}
