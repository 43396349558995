<div class="intervention-type-button-toggles-container">
  <label [class.required]="required">{{ 'INTERVENTION_TYPE.LABEL' | translate }}</label>
  <mat-button-toggle-group
    appearance="none"
    multiple="false"
    [(ngModel)]="type"
    (ngModelChange)="onTypeChange($event)"
    #interventionTypeModel="ngModel"
    name="interventionType"
    matInput
    [required]="required"
    [disabled]="disabled"
  >
    <mat-button-toggle
      *ngFor="let button of interventionTypeButtonToggles"
      [value]="button.value"
      [ngStyle]="button.value === type && { 'background-color': button.color }"
      >{{ button.name }}</mat-button-toggle
    >
    <!-- <mat-icon aria-hidden="false" aria-label="Help" matTooltip="{{ 'INTERVENTION_TYPE.TOOLTIP' | translate }}"
      >help</mat-icon
    > -->
    <i class="fas fa-question-circle help" [appMidTooltip]="'INTERVENTION_TYPE.TOOLTIP' | translate"></i>
  </mat-button-toggle-group>
  <span *ngIf="required && interventionTypeModel.invalid" class="text-danger font-weight-bold">{{
    'COMMON_REQUIRED_FIELD' | translate
  }}</span>
</div>
