import { StorageService } from './storage-service';
import { required, Assert, validate } from 'src/app/shared';
import { LoggerService } from '../logger.service';
import { isUndefined, isNull } from 'lodash';

export abstract class WebStorageService implements StorageService {
  private readonly storage: Storage;
  private readonly loggerService: LoggerService;

  protected constructor(storage: Storage, loggerService: LoggerService) {
    Assert.isNotNull(storage, 'storage');
    Assert.isNotNull(loggerService, 'loggerService');

    this.storage = storage;
    this.loggerService = loggerService;
  }

  @validate
  set(@required key: string, value?: any): void {
    const serializedValue = JSON.stringify(value);
    this.storage.setItem(key, serializedValue);
  }

  @validate
  remove(@required key: string): void {
    this.storage.removeItem(key);
  }

  @validate
  get<T>(@required key: string): T {
    let value: T;

    if (this.has(key)) {
      const serializedValue = this.storage.getItem(key);
      try {
        value = JSON.parse(serializedValue);
      } catch (e) {
        this.loggerService.error(`Failed to parse local storage value: '${value}'.`, e);
        value = undefined;
      }
    }

    return value;
  }

  @validate
  has(@required key: string): boolean {
    const value: any = this.storage.getItem(key);
    const isExists: boolean = !isUndefined(value) && !isNull(value);

    return isExists;
  }
}
