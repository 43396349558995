import { cloneDeep } from 'lodash';
import { UserProfilesActions, UserProfilesActionType, SetCurrentUserAction } from './user-profiles.actions';
import { Assert } from 'src/app/shared';
import { CoreActionTypes } from '../../core/store';
import { User } from 'src/app/autogenerated/model';

export interface UserProfilesState {
  currentUser: User;
  currentUserId: string;
}

export const usersInitialState: UserProfilesState = {
  currentUser: null,
  currentUserId: null,
};

export function userProfilesReducer(state: UserProfilesState, action: UserProfilesActions): UserProfilesState {
  Assert.isNotNull(action, 'action');

  switch (action.type) {
    case UserProfilesActionType.SetCurrentUser:
      return setCurrentUserActionReducer(state, action);

    case CoreActionTypes.ResetState:
      return resetStateActionReducer();

    default:
      return state;
  }
}

export function setCurrentUserActionReducer(state: UserProfilesState, action: SetCurrentUserAction): UserProfilesState {
  Assert.isNotNull(state, 'state');
  Assert.isNotNull(action, 'action');
  Assert.isNotNull(action.item, 'action.item');

  const newState: UserProfilesState = {
    ...state,
    currentUser: action.item,
    currentUserId: action.item._id,
  };

  return newState;
}

export function resetStateActionReducer(): UserProfilesState {
  const newState: UserProfilesState = cloneDeep(usersInitialState);

  return newState;
}
