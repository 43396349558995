/**
 * MidGard API
 * MidGard API summary
 *
 * OpenAPI spec version: 2.0
 * Contact: contact@midgard-ai.co
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface OrganizationGroupCreationDto { 
    /**
     * The ObjectId of the Organization
     */
    organization: string;
    /**
     * The role this group grant to group members
     */
    role: OrganizationGroupCreationDto.RoleEnum;
    /**
     * The organization name
     */
    name: string;
}
export namespace OrganizationGroupCreationDto {
    export type RoleEnum = 'None' | 'Admin' | 'Member' | 'DroneOperator';
    export const RoleEnum = {
        None: 'None' as RoleEnum,
        Admin: 'Admin' as RoleEnum,
        Member: 'Member' as RoleEnum,
        DroneOperator: 'DroneOperator' as RoleEnum
    };
}