import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-mid-badge',
  templateUrl: './mid-badge.component.html',
  styleUrls: ['./mid-badge.component.scss'],
})
export class MidBadgeComponent {
  @Input()
  badgeClass: string;
}
