<!-- content -->

<mat-dialog-content>
  <form
    name="formNewIntervention"
    #formNewIntervention="ngForm"
    class="row justify-content-start px-3"
    (keypress)="onKeyPress($event, nameModel.valid, interventionTypeModel.valid)"
  >
    <h2 mat-dialog-title>
      <ng-container *ngIf="!interventionId">{{ 'INTERVENTION_NEW_TITLE' | translate }}</ng-container>
      <ng-container *ngIf="interventionId">{{ 'INTERVENTION_EDIT_TITLE' | translate }}</ng-container>
    </h2>
    <div class="w-100">
      <mat-form-field class="w-100 py-4">
        <mat-label>{{ 'INTERVENTION_NAME' | translate }}</mat-label>
        <input
          matInput
          class="mid-form-control"
          type="text"
          required
          [(ngModel)]="name"
          #nameModel="ngModel"
          name="name"
          [disabled]="state.isLoading"
          [appMatErrorStateMatcher]="state.isFailed || (nameModel.touched && nameModel.invalid)"
          (ngModelChange)="onNameChanged()"
        />
        <mat-error>
          <div *ngIf="nameModel.invalid">{{ 'COMMON_REQUIRED_FIELD' | translate }}</div>
          <div *ngIf="state.isFailed">{{ errorMessage | translate }}</div>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="w-100">
      <mat-label class="mid-text-primary" [class.mid-color-error]="state.isInterventionTypeRequiredError"
        >{{ 'INTERVENTION_TYPE.LABEL' | translate }} *</mat-label
      ><br />
      <mat-button-toggle-group
        name="fontStyle"
        [(ngModel)]="interventionType"
        #interventionTypeModel="ngModel"
        required
        (ngModelChange)="onInterventionTypeChanged()"
      >
        <mat-button-toggle [value]="InterventionType.COM">{{ 'INTERVENTION_TYPE.COM' | translate }}</mat-button-toggle>
        <mat-button-toggle [value]="InterventionType.OPS">{{ 'INTERVENTION_TYPE.OPS' | translate }}</mat-button-toggle>
        <mat-button-toggle [value]="InterventionType.PRV">{{ 'INTERVENTION_TYPE.PRV' | translate }}</mat-button-toggle>
        <mat-button-toggle [value]="InterventionType.TRAINING">{{
          'INTERVENTION_TYPE.TRAINING' | translate
        }}</mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="w-100">
      <mat-form-field class="w-100 py-4">
        <mat-label>{{ 'INTERVENTION_NUMBER' | translate }}</mat-label>
        <input
          matInput
          class="mid-form-control"
          type="text"
          [(ngModel)]="interventionNumber"
          name="interventionNumber"
          [disabled]="state.isLoading"
        />
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    class="btn mid-btn-primary mt-3 mb-0 w-50"
    (click)="onSaveIntervention()"
    [disabled]="!formNewIntervention.valid"
  >
    <ng-container *ngIf="!interventionId">{{ 'CREATE' | translate }}</ng-container>
    <ng-container *ngIf="interventionId">{{ 'COMMON_UPDATE' | translate }}</ng-container>
  </button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button class="btn mt-3 mb-0 w-50" mat-dialog-close>{{ 'COMMON_CANCEL' | translate }}</button>
</mat-dialog-actions>
