import { Injectable } from '@angular/core';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { DomService } from './dom.service';

export class BaseMidRightPanel<T> {
  data: T;
}

@Injectable({
  providedIn: 'root',
})
export class MidRightPanelService {
  activeComponent: any;
  activeData: any;

  private onClose$: Subject<{ type: string; data: any }>;
  private onUpdate$: Subject<{ type: string; data: any }>;

  constructor(private readonly domService: DomService) {
    this.onClose$ = new Subject();
    this.onUpdate$ = new Subject();
  }

  open<T>(component: any, data: T = {} as T) {
    const componentRef = this.domService.createComponentRef(component, data);

    if (this.isActive() && componentRef.instance instanceof component && _.isEqual(data, this.activeData)) {
      componentRef.destroy();
      this.activeComponent.instance.update();
      return this.onUpdate();
    }

    this.destroy();
    this.activeComponent = componentRef;
    const componentElement = this.domService.getDomElementFromComponentRef(componentRef);
    this.domService.addChild(componentElement);
    return this.onClose();
  }

  close(data: any = {}): void {
    this.onClose$.next({
      type: 'close',
      data,
    });
    this.onClose$.complete();
    this.domService.destroyRef(this.activeComponent);
    this.activeComponent = null;
  }

  private destroy(): void {
    if (this.activeComponent) {
      this.domService.destroyRef(this.activeComponent);
    }
    this.activeComponent = null;
    this.activeData = null;
  }

  private onClose() {
    this.onClose$ = new Subject();
    return this.onClose$.asObservable();
  }

  private onUpdate() {
    this.onUpdate$ = new Subject();
    return this.onUpdate$.asObservable();
  }

  private isActive(): boolean {
    return !!this.activeComponent;
  }
}
