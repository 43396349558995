import { TranslateService } from '@ngx-translate/core';
import mapboxgl from 'mapbox-gl';
import { ServiceLocator } from 'src/app/global/locator.service';
import { BackgroundViewType } from '../models/dto/enums/background-view-type.enum';
import { tdrawConfig } from '../tdraw.config';
import { BasemapService } from '../services/basemap.service';

export default class MapCompareTool {
  private basemapService: BasemapService;
  private translate: TranslateService;

  private initialMap: mapboxgl.Map;
  private clonedMap: mapboxgl.Map;

  private slider: HTMLElement;
  private compareContainer: HTMLElement;
  private backgroundSelector: HTMLElement;
  private bounds: DOMRect;

  private onInitialMapMove: any;
  private onClonedMapMove: any;
  private onClonedMapResize: any;
  private onSliderMouseDown: any;
  private onSliderMove: any;
  private onMouseUp: any;

  constructor(map: mapboxgl.Map) {
    this.translate = ServiceLocator.injector.get(TranslateService);
    this.basemapService = ServiceLocator.injector.get(BasemapService);

    this.initialMap = map;
    this.createEvents();
  }

  async start() {
    const initialMapHtmlElement = this.initialMap.getContainer();
    const parentHtmlElement = initialMapHtmlElement.parentElement;
    const clonedMapHtmlElement = <HTMLElement>initialMapHtmlElement.cloneNode();
    clonedMapHtmlElement.removeAttribute('id');

    this.slider = document.createElement('div');
    this.slider.className = 'compare-slider';

    this.backgroundSelector = document.createElement('div');
    this.backgroundSelector.className = 'background-selector';
    await this.generateBackgroundSelectorContent();

    this.compareContainer = document.createElement('div');
    this.compareContainer.className = 'mapboxgl-compare';
    this.compareContainer.appendChild(this.slider);
    this.compareContainer.appendChild(this.backgroundSelector);

    parentHtmlElement.appendChild(clonedMapHtmlElement);
    parentHtmlElement.appendChild(this.compareContainer);

    this.clonedMap = new mapboxgl.Map({
      accessToken: tdrawConfig.mapAccessToken,
      container: clonedMapHtmlElement,
      style: tdrawConfig.mapStyle,
      center: this.initialMap.getCenter(),
      zoom: this.initialMap.getZoom(),
      bearing: this.initialMap.getBearing(),
      pitch: this.initialMap.getPitch(),
    });

    this.clonedMap.once('load', async () => {
      await this.basemapService.load(this.clonedMap);

      // Toggle 3D terrain
      if (this.initialMap.getTerrain()) {
        this.basemapService.toggle3DTerrain(this.clonedMap, true);
      }
    });

    this.bounds = initialMapHtmlElement.getBoundingClientRect();
    this.setSlider(this.bounds.width / 2);

    this.startEvents();
  }

  stop() {
    this.stopEvents();
    this.initialMap.getContainer().style.clipPath = null;
    this.clonedMap.remove();
    this.clonedMap.getContainer().remove();
    this.compareContainer.remove();
  }

  createEvents() {
    this.onInitialMapMove = () => {
      this.stopMapSync();
      this.applySameMapPosition(this.initialMap, this.clonedMap);
      this.startMapSync();
    };

    this.onClonedMapMove = () => {
      this.stopMapSync();
      this.applySameMapPosition(this.clonedMap, this.initialMap);
      this.startMapSync();
    };

    this.onClonedMapResize = () => {
      this.bounds = this.initialMap.getContainer().getBoundingClientRect();
      this.setSlider(this.bounds.width / 2);
    };

    this.onSliderMouseDown = (e) => {
      document.addEventListener('touchmove', this.onSliderMove);
      document.addEventListener('mousemove', this.onSliderMove);
    };

    this.onMouseUp = () => {
      document.removeEventListener('mousemove', this.onSliderMove);
      document.removeEventListener('touchmove', this.onSliderMove);
    };

    this.onSliderMove = (e) => {
      this.setSlider(this.getX(e));
    };
  }

  startEvents() {
    this.clonedMap.on('resize', this.onClonedMapResize);
    this.slider.addEventListener('mousedown', this.onSliderMouseDown);
    this.slider.addEventListener('touchstart', this.onSliderMouseDown);
    document.addEventListener('mouseup', this.onMouseUp);
    document.addEventListener('touchend', this.onMouseUp);
    this.startMapSync();
  }

  stopEvents() {
    this.stopMapSync();
    this.clonedMap.off('resize', this.onClonedMapResize);
    this.slider.removeEventListener('mousedown', this.onSliderMouseDown);
    this.slider.removeEventListener('touchstart', this.onSliderMouseDown);
    document.removeEventListener('mouseup', this.onMouseUp);
    document.removeEventListener('touchend', this.onMouseUp);
    document.removeEventListener('mousemove', this.onSliderMove);
    document.removeEventListener('touchmove', this.onSliderMove);
  }

  startMapSync() {
    this.initialMap.on('move', this.onInitialMapMove);
    this.clonedMap.on('move', this.onClonedMapMove);
  }

  stopMapSync() {
    this.initialMap.off('move', this.onInitialMapMove);
    this.clonedMap.off('move', this.onClonedMapMove);
  }

  applySameMapPosition(from: mapboxgl.Map, to: mapboxgl.Map) {
    const center = from.getCenter();
    const zoom = from.getZoom();
    const bearing = from.getBearing();
    const pitch = from.getPitch();
    to.jumpTo({ center, zoom, bearing, pitch });
  }

  setSlider(x: number) {
    const xPos = Math.min(x, this.bounds.width);
    const xPosInitial = this.bounds.width - xPos;
    const pos = `translate(${xPos}px, 0)`;
    const clipA = `inset(0px ${xPosInitial}px 0px 0px)`;
    const clipB = `inset(0px 0px 0px ${xPos}px)`;

    this.compareContainer.style.transform = pos;
    this.initialMap.getContainer().style.clipPath = clipB;
    this.clonedMap.getContainer().style.clipPath = clipA;
  }

  getX(e: any): number {
    const xPos = e.touches ? e.touches[0].clientX : e.clientX;
    let x = xPos - this.bounds.left;
    if (x < 0) {
      x = 0;
    } else if (x > this.bounds.width) {
      x = this.bounds.width;
    }
    return x;
  }

  private async generateBackgroundSelectorContent() {
    // Create title
    const title = document.createElement('p');
    title.innerHTML = await this.translate.get('MAP_TYPE').toPromise();

    // Create select
    const select = await this.generateBackgroundSelectorSelect();

    // Append all children
    this.backgroundSelector.appendChild(title);
    this.backgroundSelector.appendChild(select);
  }

  private async generateBackgroundSelectorSelect() {
    // Create select
    const select = document.createElement('select');
    select.addEventListener('change', (e) => {
      this.basemapService.changeStyle(this.clonedMap, select.value as BackgroundViewType);
    });

    // Create options
    const optionsData = [
      [BackgroundViewType.Streets, 'PLAN'],
      [BackgroundViewType.Satellite, 'SATELLITE'],
      [BackgroundViewType.SatelliteStreet, 'SATELLITE_STREET'],
      [BackgroundViewType.Dark, 'DARK'],
    ];
    for (const opt of optionsData) {
      const option = document.createElement('option');
      option.value = opt[0];
      option.innerText = await this.translate.get(opt[1]).toPromise();
      select.appendChild(option);
    }
    return select;
  }
}
