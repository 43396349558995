import moment from 'moment';

export enum DeviceType {
  Robot = 'robot',
  Drone = 'drone',
}

export class Flight {
  _id: string;
  droneId: string;
  droneName: string;
  startDate: Date;
  endDate?: Date;
  deviceType: DeviceType;
  heading: number;
  data?: [number, number][];

  static buildFromResponse(dto) {
    const flight = new Flight();

    flight._id = dto._id;
    flight.droneId = dto.droneId;
    flight.droneName = dto.droneName ?? 'Drone';
    flight.startDate = moment.utc(dto.startDate).local().toDate();
    flight.endDate = dto.endDate ? moment.utc(dto.endDate).local().toDate() : null;
    flight.data = dto.hasOwnProperty('data') ? dto.data : null;
    flight.deviceType = dto.deviceType ?? DeviceType.Drone;
    flight.heading = dto.heading ?? 0;

    return flight;
  }
}
