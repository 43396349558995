import { Pipe, PipeTransform } from '@angular/core';
import { validate, required } from '../../shared';
import { FeaturesService } from './features.service';

@Pipe({
  name: 'isFeatureEnabled',
})
export class IsFeatureEnabledPipe implements PipeTransform {
  private readonly featuresService: FeaturesService;

  constructor(featuresService: FeaturesService) {
    this.featuresService = featuresService;
  }

  @validate
  transform(@required featureId: string): boolean {
    const isEnabled = this.featuresService.checkIsFeatureEnabled(featureId);

    return isEnabled;
  }
}
