import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlContainer, NgForm, NgModel } from '@angular/forms';
import { MidInputSize } from '../enums/input-size.enum';
import { _MatAutocompleteBase } from '@angular/material/autocomplete';

@Component({
  selector: 'app-mid-input',
  templateUrl: './mid-input.component.html',
  styleUrls: ['./mid-input.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  exportAs: 'appMidInput',
})
export class MidInputComponent implements OnInit {
  @Input()
  value: string;

  @Output()
  valueChange: EventEmitter<string>;

  @Input()
  name = 'appMidInputName_' + ((Math.random() * 10000) | 0);

  @Input()
  label = '';

  @Input()
  placeholder = '';

  @Input()
  size: MidInputSize = MidInputSize.default;

  @Input()
  disabled = false;

  @Input()
  required = false;

  @Input()
  autocomplete = 'off';

  @Input()
  type = 'text';

  @Input()
  helpMessage = '';

  @Input('matAutocomplete')
  matAutocomplete: _MatAutocompleteBase;
  @Output()
  matAutocompleteChange: EventEmitter<_MatAutocompleteBase>;

  @ViewChild('midInput')
  input: NgModel;

  @Input()
  iconLeft: boolean;

  @Input()
  fasType: boolean;

  @Output()
  fasChange: EventEmitter<boolean>;

  constructor() {
    this.valueChange = new EventEmitter();
    this.matAutocompleteChange = new EventEmitter<_MatAutocompleteBase>();
    this.fasChange = new EventEmitter();
  }

  ngOnInit() {
    this.matAutocompleteChange.emit(this.matAutocomplete);
  }

  onValueChange() {
    this.valueChange.emit(this.value);
  }

  get MidInputSize() {
    return MidInputSize;
  }

  get valid() {
    return this.input?.valid;
  }

  get invalid() {
    return this.input?.invalid;
  }

  get dirty() {
    return this.input?.dirty;
  }

  get pristine() {
    return this.input?.pristine;
  }

  get touched() {
    return this.input?.touched;
  }

  get untouched() {
    return this.input?.untouched;
  }

  onFasChange() {
    this.fasChange.emit(!this.fasType);
  }
}
