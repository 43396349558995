<div class="media-card">
  <i *ngIf="isSelected" class="fa-solid fa-circle-check select-icon"></i>
  <div class="media-card-top-buttons">
    <button
      *ngIf="state.shared && displayMode === DisplayMode.default"
      class="media-card-fixed-btn"
      (click)="onShare()"
    >
      <i class="fas fa-share-alt"></i>
    </button>
    <button
      *ngIf="displayMode === DisplayMode.default"
      class="media-card-fixed-btn"
      [class.disabled]="isLoading"
      (click)="toggleFavorite()"
    >
      <i class="fa-star" [class.fa-solid]="isFavorite" [class.fa-regular]="!isFavorite"></i>
    </button>
    <button
      *ngIf="displayMode !== DisplayMode.select"
      class="media-card-fixed-btn"
      [class.disabled]="isLoading"
      [matMenuTriggerFor]="menu"
    >
      <i class="fas fa-ellipsis-v"></i>
    </button>
    <mat-menu #menu="matMenu" class="mid-menu">
      <div class="mid-menu-item" (click)="onDisplaySelectedMediaProperties()">
        <i class="fas fa-pen mr-1 mid-menu-item-icon"></i>
        <span>{{ 'EDIT_MEDIA_PROPERTIES' | translate }}</span>
      </div>
      <div class="mid-menu-item" (click)="openDialog()">
        <i class="fas fa-random mr-1 mid-menu-item-icon"></i>
        <span>{{ 'ASSIGN_TO_ANOTHER_INTERVENTION' | translate }}</span>
      </div>
      <div class="mid-menu-item" (click)="downloadMedia()">
        <i class="fas fa-download mr-1 mid-menu-item-icon"></i>
        <span>{{ 'DOWNLOAD' | translate }}</span>
      </div>
      <div *ngIf="media?.mediaType === MediaType.picture" class="mid-menu-item" (click)="openPrintDialog()">
        <i class="fas fa-print mr-1 mid-menu-item-icon"></i>
        <span>{{ 'PRINT' | translate }}</span>
      </div>
      <div *ngIf="!isFavorite" class="mid-menu-item" (click)="toggleFavorite()">
        <i class="fas fa-heart mr-1 mid-menu-item-icon"></i>
        <span>{{ 'MEDIA_CARD.MENU.ADD_TO_FAVORITES' | translate }}</span>
      </div>
      <div *ngIf="isFavorite" class="mid-menu-item" (click)="toggleFavorite()">
        <i class="fas fa-heart mr-1 mid-menu-item-icon"></i>
        <span>{{ 'MEDIA_CARD.MENU.REMOVE_FROM_FAVORITES' | translate }}</span>
      </div>
      <div class="mid-menu-item" (click)="onShare()">
        <i class="fas fa-share-alt mr-1 mid-menu-item-icon"></i>
        <span>{{ 'SHARE' | translate }}</span>
      </div>
      <div class="mid-menu-item mid-menu-item-fill-red" (click)="onDeleteMedia()">
        <i class="fas fa-times mr-1 mid-menu-item-icon"></i>
        <span>{{ 'REMOVE' | translate }}</span>
      </div>
    </mat-menu>
  </div>
  <div class="image-block" [class.media-multiselect]="displayMode === DisplayMode.multiselect" (click)="selectMedia()">
    <ng-container [ngSwitch]="media?.mediaType">
      <div *ngSwitchCase="MediaType.video" class="media-video" [matMenuTriggerFor]="menu">
        <i class="fas fa-play-circle"></i>
      </div>
      <ng-container [ngSwitch]="previewUrl" *ngSwitchDefault>
        <img
          *ngIf="!previewUrl; else preview"
          class="media-img"
          [attr.src]="getDownloadPath() | secureImage | async"
          alt=""
        />
        <ng-template #preview>
          <img class="media-img" [attr.src]="previewUrl | secureImage | async" alt="" />
        </ng-template>
      </ng-container>
    </ng-container>
  </div>
  <div *ngIf="isLoading" class="loader-container" [class.d-none]="state.loadingError">
    <span class="loading-name">{{ media?.name }}</span>
    <span class="loading-progress">{{ state.loadingProgress }}%</span>
    <mat-progress-bar class="loader" [value]="state.loadingProgress"></mat-progress-bar>
  </div>
  <div *ngIf="state.loadingError" class="loading-error">
    <span>{{ 'MEDIA_CARD.LOADING_ERROR' | translate }}</span>
  </div>
  <div class="media-card-footer" [class.intervention-view-mode]="mediaPageLocation === MediaPageLocation.intervention">
    <div class="text-truncate intervention-name">
      <span class="pl-2">{{ interventionName }}</span>
    </div>
    <div class="media-card-duration text-truncate text-nowrap">
      <span>{{ mediaCreationDate | date : 'HH:mm' }}</span>
    </div>
  </div>
  <div class="media-name text-truncate">
    <ng-container [ngSwitch]="media?.mediaType">
      <i *ngSwitchCase="MediaType.video" class="fas fa-video"></i>
      <i *ngSwitchCase="MediaType.picture" class="far fa-image"></i>
    </ng-container>
    <div class="text-truncate" [matTooltip]="media.name || ''">
      <span class="ml-2">{{ media?.name }}</span>
    </div>
  </div>
</div>
