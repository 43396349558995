export enum ActionForClientType {
  Created = 'Created',
  Updated = 'Updated',
  Deleted = 'Deleted',
}

export interface IMessageForClient {
  readonly actionType: ActionForClientType;
  readonly subject: any;
  readonly subjectType: string;
}

export class BaseMessage {
  constructor(init?: Partial<BaseMessage>) {
    Object.assign(this, init);
  }
}

export class MessageForClient<T extends BaseMessage> {
  readonly actionType: ActionForClientType;
  readonly subject: T;
  readonly subjectType: string;

  constructor({ actionType, subject, subjectType }: Partial<MessageForClient<T>> = {}) {
    this.actionType = actionType;
    this.subject = subject;
    this.subjectType = subjectType;
  }
}
