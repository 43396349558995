<div
  class="media-page"
  infiniteScroll
  [infiniteScrollDistance]="InfiniteScrollConfig.distance"
  [infiniteScrollThrottle]="InfiniteScrollConfig.throttle"
  (scrolled)="loadMoreMedia()"
  [fromRoot]="InfiniteScrollConfig.fromRoot"
  [infiniteScrollContainer]="state.infiniteScrollSelector"
>
  <!-- <div class="media-header">
    <h1>{{ 'MEDIA' | translate }}</h1>
    <mid-view-block></mid-view-block>
  </div> -->
  <div *ngIf="edithMode">
    <app-search-section
      [displayMode]="displayMode"
      [intervention]="intervention"
      [(mediaFilter)]="state.filter"
      (mediaFilterChange)="onMediaFilterChange()"
      (switchSelectMode)="onSwitchSelectMode($event)"
      (switchMultiselectMode)="onSwitchMultiselectMode()"
      (deleteSelectedMedias)="onDeleteSelectedMedias()"
      (downloadSelectedMedias)="onDownloadSelectedMedias()"
      (selectAllMedias)="onSelectAllMedias()"
    >
    </app-search-section>
  </div>

  <h5 *ngIf="dataState.foundItemsCount" class="search-result mt-3">
    {{ dataState.foundItemsCount }} {{ 'FOUND_ITEMS' | translate }}
  </h5>

  <div class="media-page-content" *ngIf="enableFavorite && dataState.favoriteMedia?.length">
    <div class="media-date-info text-in-lines">
      <p class="media-date mt-3">
        <i class="fa-solid fa-star"></i>
        <span>{{ 'MEDIA_CARD.MENU.FAVORITES' | translate }}</span>
      </p>
    </div>
    <app-media-list
      [mediaTypeFilter]="state.filter?.type"
      [interventionIdFilter]="interventionId"
      [organization]="organization"
      [mediaList]="dataState.favoriteMedia"
      [canDelete]="state.canDelete"
      [mediaPageLocation]="mediaPageLocation"
      (deleteMedia)="onDeleteMedia($event)"
      (refreshMedia)="onRefreshMedia($event)"
      [displayMode]="displayMode"
      [selectedMediaIds]="selectedMediaIds"
      (onSelectedMediaId)="selectMediaId($event)"
      (selectedMediaProperties)="onDisplaySelectedMediaProperties($event)"
      (toggleFavoriteMedia)="onToggleFavoriteMedia()"
    ></app-media-list>
  </div>

  <div class="media-page-content" *ngIf="dataState.groupedMedia">
    <div *ngFor="let group of dataState.groupedMedia; trackBy: trackByItem">
      <div class="media-date-info text-in-lines">
        <p class="media-date mt-3">{{ group.key | date : 'longDate' : undefined : translate.getDefaultLang() }}</p>
      </div>
      <!-- todo : add icon heart for favorite medias -->
      <app-media-list
        [mediaTypeFilter]="state.filter?.type"
        [interventionIdFilter]="interventionId"
        [organization]="organization"
        [mediaList]="group.value"
        [canDelete]="state.canDelete"
        [mediaPageLocation]="mediaPageLocation"
        [displayMode]="displayMode"
        [selectedMediaIds]="selectedMediaIds"
        (onSelectedMediaId)="selectMediaId($event)"
        (deleteMedia)="onDeleteMedia($event)"
        (refreshMedia)="onRefreshMedia($event)"
        (selectedMediaProperties)="onDisplaySelectedMediaProperties($event)"
        (toggleFavoriteMedia)="onToggleFavoriteMedia()"
      ></app-media-list>
    </div>
    <div class="loader" *ngIf="state.isLoading">
      <div [class.overlay-loader]="true"></div>
    </div>

    <app-media-right-panel
      [displayState]="state.rightPanelDisplayState"
      [media]="state.selectedMediaProperties"
      [type]="RightPanelType.default"
      (displayStateChanged)="onChangeRightPanelDisplayState($event)"
    >
    </app-media-right-panel>
  </div>

  <div *ngIf="dataState.groupedMedia && dataState.foundItemsCount === 0">
    <p class="text-center">{{ 'GENERAL_EMPTY' | translate }}</p>
  </div>
  <div *ngIf="!dataState.groupedMedia">
    <p class="text-center">{{ 'LOADING' | translate }}</p>
  </div>
</div>
