import { Intervention } from '../../autogenerated/model';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { InterventionType, Organization } from 'src/app/autogenerated/model';
import {
  DataSource,
  DataSourceService,
  DataSourceState,
  FieldSortOrder,
  GroupedItemsData,
} from 'src/app/core/collections';
import { LoggerService } from 'src/app/core/logger.service';
import { TemplateService } from 'src/app/global/template.service';
import { OrganizationStoreService } from 'src/app/organization/store';
import { required, validate } from 'src/app/shared';
import { interventionConfig } from '../intervention.config';
import { InterventionStoreService } from '../store';
import { InterventionService } from 'src/app/autogenerated/interventionService';
import { ActivatedRoute, Router } from '@angular/router';
import { Unsubscribe } from 'src/app/core/decorators';
import { MidRightPanelService } from 'src/app/midgard-controls/services/mid-right-panel.service';
import { CreateInterventionComponent } from '../dialogs/create-intervention-right-panel/create-intervention/create-intervention.component';
import { AssociateWithDroneComponent } from '../dialogs/associate-with-drone-right-panel/associate-with-drone/associate-with-drone.component';
import { TranslateService } from '@ngx-translate/core';

export type InterventionFilterType =
  | 'all'
  | InterventionType.OPS
  | InterventionType.PRV
  | InterventionType.COM
  | InterventionType.TRAINING;

@Unsubscribe()
@Component({
  selector: 'app-interventions-page',
  templateUrl: './interventions-page.component.html',
  styleUrls: ['./interventions-page.component.scss'],
})
export class InterventionsPageComponent implements OnInit, OnDestroy {
  organization: Organization;
  hasAdminRole: boolean;

  fieldText: string;
  interventionFilterType: InterventionFilterType;
  dataSource: DataSource<Intervention>;
  dataSourceState: DataSourceState<Intervention>;
  groupedInterventionData: GroupedItemsData<Intervention>[];

  state: {
    isLoading: boolean;
    isFailed: boolean;
  };

  private currentOrganizationSubscription: Subscription;
  private interventionsSubscription: Subscription;
  private associateWithDroneSubscription: Subscription;

  get InterventionType() {
    return InterventionType;
  }

  constructor(
    private readonly interventionService: InterventionService,
    private readonly interventionStoreService: InterventionStoreService,
    private readonly organizationStoreService: OrganizationStoreService,
    private readonly loggerService: LoggerService,
    private readonly dialog: MatDialog,
    private readonly templateService: TemplateService,
    private readonly activateRoute: ActivatedRoute,
    private router: Router,
    private readonly dataSourceService: DataSourceService,
    private readonly midRightPanelService: MidRightPanelService,
    readonly translate: TranslateService
  ) {
    this.templateService.$activeComponent.next('INTERVENTIONS');
    this.interventionFilterType = 'all';

    this.dataSource = new DataSource<Intervention>();
    this.fieldText = '';
    this.dataSourceState = new DataSourceState<Intervention>({
      filter: this.fieldText,
      searchableFields: interventionConfig.list.interventions.searchableFields,
      customFilter: (interventionModels: Intervention[]) =>
        this.filterInterventionModels(interventionModels, this.interventionFilterType),
      sortOrder: new FieldSortOrder(
        interventionConfig.list.interventions.listOrder.fieldName,
        interventionConfig.list.interventions.listOrder.sortOrder
      ),
      page: 1,
      pageSize: Number.MAX_VALUE,
    });

    this.state = {
      isLoading: false,
      isFailed: false,
    };
  }

  ngOnInit(): void {
    this.currentOrganizationSubscription = this.organizationStoreService
      .getCurrentOrganization()
      .subscribe(async (organization: Organization) => {
        this.organization = organization;
        // await this.updateInterventionsSubscription(organization._id);
        await this.loadInterventions(this.organization._id);
      });
  }

  ngOnDestroy(): void {}

  // TODO: DELETE because unused
  // async updateInterventionsSubscription(organizationId: string) {
  //   if (!organizationId) {
  //     return;
  //   }

  //   if (this.interventionsSubscription) {
  //     this.interventionsSubscription.unsubscribe();
  //     this.interventionsSubscription = null;
  //   }

  //   this.interventionsSubscription = this.interventionStoreService
  //     .getOrganizationInterventions(organizationId)
  //     .subscribe((interventions) => {
  //       this.updateDataSource(interventions);
  //     });
  // }

  onClearFilter(): void {
    this.fieldText = '';
    this.onFilterChanged();
  }

  onFilterChanged(): void {
    this.dataSourceState.filter = this.fieldText;
    this.updateDataSource();
  }

  setInterventionFilterType(type): void {
    this.interventionFilterType = type;
    this.updateDataSource();
  }

  // TODO: DELETE because unused
  // tryToLoadInterventions() {
  //   if (!this.organization) {
  //     return;
  //   }
  //   this.loadInterventions(this.organization._id);
  // }

  onOpenAssociateWithDrone(): void {
    const data = { intervention: this.dataSource.paginatedItems && this.dataSource.paginatedItems[0] };
    this.associateWithDroneSubscription = this.midRightPanelService
      .open(AssociateWithDroneComponent, data)
      .subscribe(() => {
        this.loadInterventions(this.organization._id);
      });
  }

  onOpenCreateIntervention(): void {
    const data = { organizationId: this.organization?._id };
    this.midRightPanelService.open(CreateInterventionComponent, data);
  }

  @validate
  trackByItem(index: number, @required item: Intervention): string {
    return item._id;
  }

  private filterInterventionModels(items: Intervention[], filter: InterventionFilterType): Intervention[] {
    if (this.interventionFilterType === 'all') {
      return items;
    }
    return items.filter((item) => item.type === filter);
  }

  @validate
  private async loadInterventions(@required organizationId: string) {
    this.state.isLoading = true;
    this.state.isFailed = false;

    try {
      const interventions = await this.interventionService.getInterventionsWithMapping({ _id: organizationId });
      this.interventionStoreService.setOrganizationInterventions(organizationId, _.cloneDeep(interventions));
      this.updateDataSource(interventions);
    } catch (error) {
      this.state.isFailed = true;
      this.loggerService.captureException(error);
    } finally {
      this.state.isLoading = false;
    }
  }

  private updateDataSource(interventions: Intervention[] = null) {
    this.dataSource = interventions
      ? this.dataSourceService.createDataSource(interventions, this.dataSourceState)
      : this.dataSourceService.filterDataSource(this.dataSource, this.dataSourceState);
    this.dataSource.groupedItems = this.dataSourceService.groupedItems(
      this.dataSource.filteredItems,
      'month',
      'startDate'
    );
  }
}
