import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccessCodeService } from 'src/api';
import { appConfig } from 'src/app/app.config';
import { AccessTokenAuthenticationService } from 'src/app/authentication/access-code-authentication.service';
import { AuthenticationStoreService } from 'src/app/authentication/store';
import { UserAuth } from 'src/app/autogenerated/model';
import { UserProfilesStoreService } from 'src/app/user-profile/store';

@Component({
  selector: 'app-access-code-login-route',
  templateUrl: './access-code-login-route.component.html',
  styleUrls: ['./access-code-login-route.component.scss'],
})
export class AccessCodeLoginRouteComponent implements OnInit {
  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly accessCodeService: AccessCodeService,
    private readonly accessTokenAuthenticationService: AccessTokenAuthenticationService,
    private readonly authenticationStoreService: AuthenticationStoreService,
    private readonly userProfilesStoreService: UserProfilesStoreService
  ) {}

  async performLogin(authDetails: UserAuth) {
    this.authenticationStoreService.authenticate(
      authDetails._id,
      authDetails.token,
      authDetails.defaultOrganization?.toString()
    );
    this.userProfilesStoreService.setCurrentUser(authDetails);

    await this.router.navigate([appConfig.routes.home]);
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(async (params) => {
      const code = params['code'];
      await this.tryLoginWithCode(code);
    });
  }

  private async tryLoginWithCode(code: string) {
    if (!code) {
      return;
    }
    const accessCodeToken = await this.accessCodeService.accessCodeControllerLogin({ code: code }).toPromise();
    if (!accessCodeToken) return;
    this.accessTokenAuthenticationService.setAccessCode(accessCodeToken);
    await this.performLogin(accessCodeToken as UserAuth);
  }
}
