<ng-container [ngSwitch]="recoverPasswordState">
  <div *ngSwitchCase="RecoverPasswordState.waitingEmailForSentCode">
    <h4>{{ 'CHANGE_PASSWORD_ENTER_EMAIL_TO_CHANGE_PASSWORD' | translate }}</h4>
    <div class="row mt-4 ml-0">
      <form name="formRecoverPassword" #formRecoverPassword="ngForm" class="w-100">
        <div class="col-12 col-lg-6 p-0">
          <app-mid-input
            type="email"
            label="{{ 'EMAIL' | translate }}"
            [required]="true"
            [size]="MidInputSize.wide"
            (valueChange)="data.username = $event"
          >
          </app-mid-input>
        </div>
      </form>
    </div>

    <div class="row mt-5 ml-0">
      <div class="col-12 col-lg-6 p-0">
        <div class="mt-3">
          <app-mid-button
            type="primary"
            [size]="MidButtonSize.wide"
            [disabled]="state.isLoading || formRecoverPassword.invalid"
            (action)="onSendResetCode()"
          >
            {{ 'CHANGE_PASSWORD_SEND_PASSWORD_RESET_EMAIL' | translate }}
          </app-mid-button>
        </div>
        <div class="mt-3">
          <app-mid-button
            type="primary"
            [size]="MidButtonSize.wide"
            [disabled]="state.isLoading || formRecoverPassword.invalid"
            (action)="onSendResetCodeBySMS()"
          >
            {{ 'CHANGE_PASSWORD_SEND_PASSWORD_RESET_SMS' | translate }}
          </app-mid-button>
        </div>
        <div class="mt-3">
          <app-mid-button
            type="secondary"
            [size]="MidButtonSize.wide"
            [disabled]="state.isLoading"
            (action)="onCancel()"
          >
            {{ 'COMMON_CANCEL' | translate }}
          </app-mid-button>
        </div>
      </div>
    </div>
  </div>

  <div class="check-mail-container" *ngSwitchCase="RecoverPasswordState.checkMailForCode">
    <h4>
      {{ 'CHANGE_PASSWORD_RESET_CODE_EMAIL_HAS_SENT' | translate }}
    </h4>
    <div class="mt-4 ml-0">
      <div class="col-12 col-lg-6 p-0">
        <span>
          {{ 'CHANGE_PASSWORD_RESET_CODE_CHECK_EMAIL_MESSAGE' | translate }}
        </span>
      </div>
      <div class="mt-5 ml-0">
        <div class="col-12 col-lg-6 p-0">
          <app-mid-button type="primary" [size]="MidButtonSize.wide" (action)="onReadCheckEmailMessage()">
            {{ 'INTRO_BUTTON_DONE' | translate }}
          </app-mid-button>
        </div>
      </div>
    </div>
  </div>

  <div class="check-mail-container" *ngSwitchCase="RecoverPasswordState.checkSMSForCode">
    <h4>
      {{ 'CHANGE_PASSWORD_RESET_CODE_SMS_HAS_SENT' | translate }}
    </h4>
    <div class="mt-4 ml-0">
      <div class="col-12 col-lg-6 p-0">
        <span>
          {{ 'CHANGE_PASSWORD_RESET_CODE_CHECK_EMAIL_MESSAGE' | translate }}
        </span>
      </div>
      <div class="mt-5 ml-0">
        <div class="col-12 col-lg-6 p-0">
          <app-mid-button type="primary" [size]="MidButtonSize.wide" (action)="onReadCheckEmailMessage()">
            {{ 'INTRO_BUTTON_DONE' | translate }}
          </app-mid-button>
        </div>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="RecoverPasswordState.resetCodeRequested">
    <h4>{{ 'RECOVER_PASSWORD.RESET_PASSWORD.TITLE' | translate }}</h4>
    <span class="font-italic">{{ data.username }}</span>
    <div class="row mt-2 ml-0">
      <div class="col-md-12 col-sm-12 text-with-border mt-4 mb-3">
        {{ 'RECOVER_PASSWORD.RESET_PASSWORD.ENTER_CODE_TITLE' | translate }}
      </div>
      <div class="col-md-6 col-sm-12 p-0">
        <app-mid-input
          placeholder="{{ 'RECOVER_PASSWORD.RESET_PASSWORD.ENTER_CODE_PLACEHOLDER' | translate }}"
          [size]="MidInputSize.wide"
          [disabled]="state.isLoading"
          [value]="data.resetCode"
          (valueChange)="data.resetCode = $event; onResetCodeChanged()"
          type="text"
        >
          <span *ngIf="state.triesLeft" class="warning-input-text">
            {{ 'SECURITY_CODE_ATTEMPTS_LEFT' | translate }}
            <span class="ml-1">{{ state.triesLeft }}</span>
          </span>
          <span class="warning-input-text" *ngIf="state.isNewResetCodeRequested">{{
            'SECURITY_CODE_RESET' | translate
          }}</span>
        </app-mid-input>

        <span class="mt-2">{{ 'RECOVER_PASSWORD.RESET_PASSWORD.ENTER_CODE_RESEND_MESSAGE' | translate }}</span>
        <a
          class="ml-1 change-email-link"
          href="javascript:void(0)"
          [class.disabled]="!state.isResetCodeResendEnabled || state.isLoading"
          (click)="onResendResetCode()"
          *ngIf="state.sendBy === 'email'"
        >
          {{ 'RECOVER_PASSWORD.RESET_PASSWORD.ENTER_CODE_RESEND' | translate }}
        </a>
        <span
          class="change-email-link"
          *ngIf="state.secondsBeforeResend > 0"
          [class.disabled]="!state.isResetCodeResendEnabled || state.isLoading"
        >
          ({{ state.secondsBeforeResend }})
        </span>
        <a
          class="ml-1 change-email-link"
          href="javascript:void(0)"
          [class.disabled]="!state.isResetCodeResendEnabled || state.isLoading"
          (click)="onSendResetCodeBySMS()"
          *ngIf="state.sendBy === 'sms'"
          >{{ 'RECOVER_PASSWORD.RESET_PASSWORD.ENTER_CODE_RESEND' | translate }}</a
        >
      </div>

      <div class="col-md-12 col-sm-12 text-with-border mt-4 mb-1">
        {{ 'RECOVER_PASSWORD.RESET_PASSWORD.ENTER_PASSWORD_TITLE' | translate }}
      </div>
      <div class="col-md-6 col-sm-12 p-0">
        <app-mid-input
          label="{{ 'RECOVER_PASSWORD.RESET_PASSWORD.ENTER_PASSWORD_NEW_PASSWORD_LABEL' | translate }}"
          [size]="MidInputSize.wide"
          [type]="state.passwordVisibility ? 'text' : 'password'"
          (valueChange)="data.newPassword = $event"
          [disabled]="state.isLoading"
        >
        </app-mid-input>
        <div class="password-eye" (click)="changePasswordVisibility()">
          <i
            class="fas mx-2"
            [class.fa-eye]="state.passwordVisibility"
            [class.hide-icon]="state.passwordVisibility"
            [class.fa-eye-slash]="!state.passwordVisibility"
          ></i>
        </div>
        <app-mid-input
          label="{{ 'RECOVER_PASSWORD.RESET_PASSWORD.ENTER_PASSWORD_CONFIRM_PASSWORD_LABEL' | translate }}"
          [size]="MidInputSize.wide"
          [type]="state.confirmPasswordVisibility ? 'text' : 'password'"
          (valueChange)="data.newPasswordConfirmation = $event"
        >
        </app-mid-input>
        <div class="password-eye" (click)="changeConfirmPasswordVisibility()">
          <i
            class="fas mx-2"
            [class.fa-eye]="state.confirmPasswordVisibility"
            [class.hide-icon]="state.confirmPasswordVisibility"
            [class.fa-eye-slash]="!state.confirmPasswordVisibility"
          ></i>
        </div>
      </div>
      <div class="col-md-6 col-sm-12 p-3 d-flex flex-column validation-labels">
        {{ 'USER_PROFILE.PASSWORD_VALIDATION_RULES.LABEL' | translate }}
        <span class="rule" [class.checked]="hasPasswordEightCharacters()"
          ><i class="fas fa-check mr-1"></i> 8 {{ 'USER_PROFILE.PASSWORD_VALIDATION_RULES.LENGTH' | translate }}</span
        >
        <span class="rule" [class.checked]="hasPasswordLowerCase()"
          ><i class="fas fa-check mr-1"></i>
          {{ 'USER_PROFILE.PASSWORD_VALIDATION_RULES.LOWERCASE' | translate }} (a-z)</span
        >
        <span class="rule" [class.checked]="hasPasswordUpperCase()"
          ><i class="fas fa-check mr-1"></i> {{ 'USER_PROFILE.PASSWORD_VALIDATION_RULES.UPPERCASE' | translate }} letter
          (A-Z)</span
        >
        <span class="rule" [class.checked]="hasPasswordDigit()"
          ><i class="fas fa-check mr-1"></i>
          {{ 'USER_PROFILE.PASSWORD_VALIDATION_RULES.NUMBER' | translate }} (0-9)</span
        >
        <span class="rule" [class.checked]="hasSpecialCharacter()"
          ><i class="fas fa-check mr-1"></i> {{ 'USER_PROFILE.PASSWORD_VALIDATION_RULES.SPECIAL' | translate }}
        </span>
      </div>
    </div>

    <div class="row mt-5 ml-0">
      <div class="col-md-6 col-sm-12 p-0">
        <app-mid-button
          (action)="onResetPassword()"
          type="primary"
          [size]="MidButtonSize.wide"
          [disabled]="!matchNewPasswordToPasswordRules() || state.isLoading"
        >
          <i *ngIf="state.isLoading" class="fas fa-sync fa-spin mr-1"></i>{{ 'CHANGE_PASSWORD_ACTION' | translate }}
        </app-mid-button>
        <div class="mt-3">
          <app-mid-button (action)="onCancel()" type="secondary" [size]="MidButtonSize.wide">
            {{ 'COMMON_BACK' | translate }}</app-mid-button
          >
        </div>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="RecoverPasswordState.passwordChanged">
    <h4>{{ 'CHANGE_PASSWORD_SUCCESSFUL_TITLE' | translate }}</h4>
    <span>{{ 'CHANGE_PASSWORD_SUCCESSFUL_DESCRIPTION' | translate }}</span>
    <div class="mt-4">
      <app-mid-button (action)="onNavigateToApp()" type="primary" [disabled]="!matchNewPasswordToPasswordRules()">{{
        'CHANGE_PASSWORD_GO_TO_APP' | translate
      }}</app-mid-button>
    </div>
  </div>
</ng-container>
