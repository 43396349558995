<mat-dialog-content class="text-{{ data.align }} text-white" [innerHtml]="data.message | translate">
  <!-- {{ data.message | translate }} -->
</mat-dialog-content>
<mat-dialog-actions class="answer-buttons">
  <app-mid-button
    *ngIf="data.status === 'validate'"
    type="primary"
    [size]="MidButtonSize.wide"
    [mat-dialog-close]="true"
  >
    {{ 'SUBMIT' | translate }}
  </app-mid-button>
  <app-mid-button
    *ngIf="data.status === 'remove'"
    type="destructive"
    [size]="MidButtonSize.wide"
    [mat-dialog-close]="true"
  >
    {{ 'REMOVE' | translate }}
  </app-mid-button>
  <app-mid-button type="secondary" [size]="MidButtonSize.wide" mat-dialog-close>{{
    'COMMON_CANCEL' | translate
  }}</app-mid-button>
</mat-dialog-actions>
