import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from 'src/app/core/core.module';
import { DashboardPageComponent } from './dashboard-page/dashboard-page.component';
import { MidgardControlsModule } from 'src/app/midgard-controls/midgard-controls.module';
import { DroneCommonModule } from 'src/app/drone/common/drone-common.module';
import { InterventionDetailsCardComponent } from './intervention-details-card/intervention-details-card.component';
import { MediaCommonModule } from 'src/app/media/common/media-common.module';

@NgModule({
  declarations: [DashboardPageComponent, InterventionDetailsCardComponent],
  imports: [CommonModule, CoreModule, MidgardControlsModule, DroneCommonModule, MediaCommonModule],
  exports: [DashboardPageComponent],
})
export class InterventionPageDashboardModule {}
