<app-mid-right-panel #panelEditList="appMidRightPanel" [title]="'DISTRIBUTION_LIST_ACTIONS.EDIT' | translate">
  <app-mid-right-panel-content>
    <div class="m-0 px-4 w-100">
      <div class="phone-input-container" *ngFor="let phone of data.phones; let i = index; trackBy: trackByFn">
        <!-- TODO: Must do phone number validation -->
        <div class="phone-input">
          <app-mid-input size="wide" [(value)]="data.phones[i]" [placeholder]="'PHONE_HINT' | translate">
          </app-mid-input>
        </div>

        <app-mid-button type="primary" (action)="deleteItem(i)">
          <span class="fa fa-trash"></span>
        </app-mid-button>
      </div>

      <app-mid-button type="primary" size="wide" (action)="addItem()">
        {{ 'DISTRIBUTION_LIST_ACTIONS.NEW_ITEM' | translate }}
      </app-mid-button>
    </div>
  </app-mid-right-panel-content>
  <app-mid-right-panel-footer>
    <div class="row m-0 py-3 px-4">
      <app-mid-button type="primary" icon="fas fa-check" (action)="updateList()" class="pr-2">
        {{ 'SAVE' | translate }}</app-mid-button
      >
      <app-mid-button class="pr-2" type="secondary" (action)="panelEditList.close()">{{
        'DIALOGS.COMMON.CANCEL' | translate
      }}</app-mid-button>
    </div>
  </app-mid-right-panel-footer>
</app-mid-right-panel>
