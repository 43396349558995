import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModalComponent, DialogData } from '../../shared/modals/confirm-modal/confirm-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ConfirmModalService {
  constructor(private dialog: MatDialog) {}

  showConfirmModal(
    modalMessage: string,
    status: 'validate' | 'remove',
    alignMessage: 'left' | 'right' | 'center' = 'center'
  ): Promise<boolean> {
    return new Promise((resolve) => {
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        id: 'confirm-modal',
        width: '450px',
        data: {
          message: modalMessage,
          status: status,
          align: alignMessage,
        } as DialogData,
      });

      dialogRef.afterClosed().subscribe((result) => {
        resolve(!!result);
      });
    });
  }
}
