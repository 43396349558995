import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlContainer, NgForm, NgModel } from '@angular/forms';
import { Victims } from '../models';

@Component({
  selector: 'app-mid-statistics-victims',
  templateUrl: './mid-statistics-victims.component.html',
  styleUrls: ['./mid-statistics-victims.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  exportAs: 'appMidStatisticsVictims',
})
export class MidStatisticsVictimsComponent implements OnInit, OnChanges {
  @Input()
  items: Victims[];

  @Input()
  isEditable: boolean;

  @Input()
  isExpanded: boolean;

  @Output()
  itemsChange: EventEmitter<Victims[]>;

  name = 'appMidStatisticsVictims_' + ((Math.random() * 10000) | 0);
  @ViewChild('appMidStatisticsVictims')
  input: NgModel;

  get total(): number {
    return this.items?.reduce((sum, i) => sum + i.value, 0);
  }

  constructor() {
    this.itemsChange = new EventEmitter<Victims[]>();
  }

  private readonly defaultVictims: Victims[] = [
    { name: 'UA', value: 0 },
    { name: 'UR', value: 0 },
    { name: 'IMPLI', value: 0 },
    { name: 'DCD', value: 0 },
  ];

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.items?.length) {
      this.items = this.defaultVictims;
    }
  }

  ngOnInit(): void {}

  add(name: string) {
    const object = this.items.findIndex((obj) => obj.name === name);
    if (this.items[object].value >= 0) {
      this.input.control.markAsDirty();
      this.items[object].value++;
      this.itemsChange.emit(this.items);
    }
  }

  remove(name: string) {
    const object = this.items.findIndex((obj) => obj.name === name);
    if (this.items[object].value !== 0) {
      this.input.control.markAsDirty();
      this.items[object].value--;
      this.itemsChange.emit(this.items);
    }
  }
}
