import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from 'src/app/core/core.module';
import { FormsModule } from '@angular/forms';
import { MidgardControlsModule } from 'src/app/midgard-controls/midgard-controls.module';
import { AssociateWithDroneComponent } from './associate-with-drone/associate-with-drone.component';

@NgModule({
  declarations: [AssociateWithDroneComponent],
  imports: [CommonModule, CoreModule, FormsModule, MidgardControlsModule],
  exports: [AssociateWithDroneComponent],
})
export class AssociateWithDroneRightPanelModule {}
