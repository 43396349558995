<app-modal-header
  text="{{ 'TDRAW.MODAL.TDRAW_EXPORT.TITLE' | translate }}"
  (closeAction)="onClose()"
></app-modal-header>
<div class="body">
  <app-mid-select
    [label]="'TDRAW.MODAL.TDRAW_EXPORT.SELECT_FORMAT' | translate"
    size="wide"
    [items]="exportOptions"
    [selectedItem]="exportOptionSelected"
    (selectedItemChange)="exportOptionSelected = $event"
    [placeholder]="'TDRAW.MODAL.TDRAW_EXPORT.SELECT_FORMAT' | translate"
  >
  </app-mid-select>

  <div class="buttons">
    <app-mid-button type="secondary" (action)="onClose()">{{ 'COMMON_CANCEL' | translate }}</app-mid-button>
    <app-mid-button type="primary" (action)="export()"
      ><i class="fas fa-check"></i> {{ 'EXPORT' | translate }}</app-mid-button
    >
  </div>
</div>
