import * as mapboxgl from 'mapbox-gl';
import CoordinatesHelpers from './coordinates-helpers';

/**
 * Data structure for informations
 */
export interface InfopointData {
  lat: number; // Latitude WGS84
  lng: number; // Longitude WGS84
  dfci?: string; // DFCI coord
  elevation?: number;
  utmEpsg?: number | string;
  utmCoords?: number[];
}

/**
 * InfopointManager allows to get access to various
 * location data such as latitude, longitude, dfci...
 */
export class InfopointManager {
  constructor(private map: mapboxgl.Map) {}

  private async getElevation(lat: number, lng: number) {
    const result = await fetch(`https://api.elevationapi.com/api/Elevation?lat=${lat}&lon=${lng}`).then((res) =>
      res.json()
    );

    const elevation = result.geoPoints?.[0]?.elevation;
    return elevation ? Math.round(elevation) : null;
  }

  /**
   * Get informations about a location from a Map event
   * Informations are detailled in Info interface
   * @param evt Mapboxgl JS event
   */
  private async getClickInformations(evt: mapboxgl.MapMouseEvent & mapboxgl.EventData): Promise<InfopointData> {
    const latLng = evt.lngLat;
    const lngLatArray = [latLng.lng, latLng.lat];
    const dfci = CoordinatesHelpers.isValidDFCICoord(lngLatArray, 'EPSG:4326')
      ? CoordinatesHelpers.toDFCI(lngLatArray, 'EPSG:4326')
      : null;
    const lng = +latLng.lng.toFixed(7);
    const lat = +latLng.lat.toFixed(7);
    const elevation = await this.getElevation(lat, lng);
    const utmEpsg = CoordinatesHelpers.getUtmEPSG(lat, lng);
    const utmCoords = CoordinatesHelpers.project(lat, lng, utmEpsg);
    return { lat, lng, dfci, elevation, utmEpsg, utmCoords: [Math.round(utmCoords[0]), Math.round(utmCoords[1])] };
  }

  /**
   * Init the Infopoint so that click events on the Map
   * return informations on the location
   * @param callback
   */
  init(callback: (arg0: InfopointData) => void): void {
    // Init the map click listener
    this.map.on('click', async (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
      const info = await this.getClickInformations(e);
      callback(info);
    });
  }
}
