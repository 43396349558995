import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { Drone, UpdateInterventionDto } from 'src/app/autogenerated/model';
import { Intervention } from 'src/app/autogenerated/model';
import { MidChipType } from 'src/app/midgard-controls/mid-chip-list/mid-chip-list.component';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import _, { cloneDeep } from 'lodash';
import { OrganizationService } from 'src/app/autogenerated/organizationService';
import { MatDialog } from '@angular/material/dialog';
import { coreConfig } from 'src/app/core/core.config';
import { PromiseUtils } from 'src/app/core/utils/promise-utils';
import { InterventionService } from 'src/app/autogenerated/interventionService';
import { InterventionStoreService } from 'src/app/intervention/store';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { interventionConfig } from 'src/app/intervention/intervention.config';
import { ConfirmModalService } from '../../../../midgard-controls/services/confirm-modal.service';
import { DroneStoreService } from '../../../../drone/store';
import { Unsubscribe } from '../../../../core/decorators';
import { Subscription } from 'rxjs';
import { OrganizationStoreService } from '../../../../organization/store';
import { MidChipListIconType } from 'src/app/midgard-controls/enums/icon-type.enum';
import { DroneService } from 'src/api';
import { MediaService } from 'src/app/autogenerated/mediaService';

@Unsubscribe()
@Component({
  selector: 'app-settings-page',
  templateUrl: './settings-page.component.html',
  styleUrls: ['./settings-page.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class SettingsPageComponent implements OnInit, OnChanges, OnDestroy {
  organizationId: string;

  @Input()
  intervention: Intervention;

  @Input()
  hasAdminPermissions = false;

  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  drones: Drone[] = [];

  data: {
    intervention: Intervention;
    allDrones: MidChipType[];
    attachedDronesIds: string[];
  };

  state: {
    isEditable: boolean;
    isLoading: boolean;
    isUpdated: boolean;
    isFailed: boolean;
  } = {
    isEditable: true,
    isLoading: false,
    isUpdated: false,
    isFailed: false,
  };
  errorMessage: string;

  private INTERVENTION_DRONE_STATE_USED = '';

  @ViewChild('formUpdateIntervention', { static: true })
  private form: NgForm;
  private dronesSubscription: Subscription;
  private organizationSubscription: Subscription;

  get IconType() {
    return MidChipListIconType;
  }

  constructor(
    private readonly translate: TranslateService,
    private organizationService: OrganizationService,
    private droneService: DroneService,
    private interventionService: InterventionService,
    private interventionStoreService: InterventionStoreService,
    private droneStoreService: DroneStoreService,
    private dialog: MatDialog,
    private router: Router,
    private confirmModalService: ConfirmModalService,
    private readonly organizationStoreService: OrganizationStoreService,
    private readonly mediaService: MediaService
  ) {
    this.data = {
      intervention: {},
      allDrones: null,
      attachedDronesIds: null,
    };
  }

  async ngOnInit(): Promise<void> {
    this.INTERVENTION_DRONE_STATE_USED = await this.translate
      .get('INTERVENTION_DRONE_STATE_USED')
      .pipe(map((it) => ` (${it})`))
      .toPromise();

    this.organizationSubscription = this.organizationStoreService.getCurrentOrganization().subscribe((org) => {
      if (!org) {
        return;
      }
      this.organizationId = org._id;
    });

    this.dronesSubscription = this.droneStoreService
      .getOrganizationDrones(this.organizationId)
      .subscribe((drones) => (this.drones = cloneDeep(drones)));

    await this.loadData();
  }

  ngOnDestroy(): void {}

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const componentChanges = changes as PropertyMap<SettingsPageComponent, SimpleChange>;
    const interventionChange = componentChanges.intervention;
    const hasInterventionChanges =
      interventionChange?.currentValue && interventionChange?.currentValue !== interventionChange?.previousValue;
    if (!hasInterventionChanges) {
      return;
    }
    await this.loadData();
  }

  /**
   * TODO: delete unused ?
   */
  get formatDateTime() {
    return interventionConfig.format.date + ' à ' + interventionConfig.format.date;
  }

  /**
   * Save modified intervention data
   */
  async onSaveIntervention(interventionForm: NgForm) {
    this.state.isLoading = true;
    this.state.isUpdated = false;
    this.state.isFailed = false;

    try {
      const interventionPromise = this.interventionService.updateInterventionV1(
        this.organizationId,
        this.intervention?._id,
        {
          type: this.data.intervention.type,
          nature: this.data.intervention.nature,
          motive: this.data.intervention.motive,
          startDate: this.data.intervention.startDate,
          endDate: this.data.intervention.endDate,
          interventionNumber: this.data.intervention.interventionNumber,
          name: this.data.intervention.name,
          city: this.data.intervention.city,
          droneChannel: this.data.intervention.droneChannel,
          tags: this.data.intervention.tags,
          remotePilots: this.data.intervention.remotePilots,
          notes: this.data.intervention.notes,
          attachedDronesIds: this.data.attachedDronesIds,
          statistics: this.data.intervention.statistics || {
            victims: [],
            engagedResources: [],
            engagedStaff: [],
            otherServices: [],
          },
        } as UpdateInterventionDto
      );
      const minTimeoutPromise = PromiseUtils.delay(coreConfig.forms.minRefreshTimeout);

      const [updatedIntervention] = await Promise.all([interventionPromise, minTimeoutPromise]);
      this.interventionStoreService.updateIntervention(this.organizationId, updatedIntervention);

      this.state.isUpdated = true;

      setTimeout(() => {
        this.state.isUpdated = false;
        interventionForm.form.markAsPristine();
      }, coreConfig.forms.showNotificationTime);
    } catch (ex) {
      this.errorMessage = ex.message;
      this.state.isFailed = true;
    } finally {
      this.state.isLoading = false;
    }
  }

  /**
   * Undo all current modifications
   */
  async onUndoModification() {
    await this.loadData();
  }

  /**
   * Delete all medias of this intervention with confirmation
   */
  async onDeleteInterventionMedias() {
    const confirmed = await this.confirmModalService.showConfirmModal(
      'INTERVENTION_SETTINGS.DELETE_INTERVENTION_MEDIAS_CONFIRM_MESSAGE',
      'remove',
      'left'
    );
    if (confirmed) {
      // TODO: use this.mediaService.getAllMediaIds && this.mediaService.deleteMediasInBatch
      const res = await this.mediaService.getMediaV1(this.organizationId, {
        interventionId: this.intervention?._id,
        limit: 999999,
      });
      for (const media of res.results) {
        await this.mediaService.deleteMedia(media);
      }
    }
  }

  /**
   * Close this intervention with confirmation
   */
  async onCloseIntervention() {
    const isCloseInterventionConfirmed = await this.confirmModalService.showConfirmModal(
      'CLOSE_INTERVENTION_CONFIRM_MESSAGE',
      'validate'
    );
    if (isCloseInterventionConfirmed) {
      await this.closeIntervention();
    }
  }

  /**
   * Close this intervention
   */
  private async closeIntervention() {
    this.state.isLoading = true;
    this.state.isUpdated = false;
    this.state.isFailed = false;

    try {
      const updatedIntervention = await PromiseUtils.setMinDelay(
        this.interventionService.closeInterventionV1(this.organizationId, this.intervention?._id),
        coreConfig.forms.minRefreshTimeout
      );
      this.interventionStoreService.updateIntervention(this.organizationId, updatedIntervention);
      this.data.intervention = updatedIntervention;
      this.state.isUpdated = true;
      setTimeout(() => {
        this.state.isUpdated = false;
      }, coreConfig.forms.showNotificationTime);
    } catch (ex) {
      this.errorMessage = ex.message;
      this.state.isFailed = true;
    } finally {
      this.state.isLoading = false;
    }
  }

  /**
   * Reopen this intervention with confirmation
   */
  async onOpenIntervention() {
    const isOpenInterventionConfirmed = await this.confirmModalService.showConfirmModal(
      'INTERVENTION_SETTINGS.REOPEN_CONFIRM_MESSAGE',
      'validate'
    );
    if (isOpenInterventionConfirmed) {
      await this.OpenIntervention();
    }
  }

  async OpenIntervention() {
    this.state.isLoading = true;
    this.state.isUpdated = false;
    this.state.isFailed = false;

    try {
      const updatedIntervention = await PromiseUtils.setMinDelay(
        this.interventionService.reopenInterventionV1(this.organizationId, this.intervention?._id),
        coreConfig.forms.minRefreshTimeout
      );
      this.interventionStoreService.updateIntervention(this.organizationId, updatedIntervention);
      this.data.intervention = updatedIntervention;
      this.state.isUpdated = true;
      setTimeout(() => {
        this.state.isUpdated = false;
      }, coreConfig.forms.showNotificationTime);
    } catch (e) {
      console.log(e);
      this.state.isFailed = true;
    } finally {
      this.state.isLoading = false;
    }
  }

  /**
   * Archive this intervention with confirmation
   */
  async onArchiveIntervention() {
    const isArchivedInterventionConfirmed = await this.confirmModalService.showConfirmModal(
      'ARCHIVE_INTERVENTION_CONFIRM_MESSAGE',
      'validate'
    );
    if (isArchivedInterventionConfirmed) {
      await this.archiveIntervention();
    }
  }

  async archiveIntervention() {
    this.state.isLoading = true;
    this.state.isUpdated = false;
    this.state.isFailed = false;

    try {
      const updatedIntervention = await PromiseUtils.setMinDelay(
        this.interventionService.archiveInterventionV1(this.organizationId, this.intervention?._id),
        coreConfig.forms.minRefreshTimeout
      );
      this.interventionStoreService.updateIntervention(this.organizationId, updatedIntervention);
      this.data.intervention = updatedIntervention;
      this.state.isUpdated = true;
      setTimeout(() => {
        this.state.isUpdated = false;
      }, coreConfig.forms.showNotificationTime);
    } catch (err) {
      console.log(err);
      this.state.isFailed = true;
    } finally {
      this.state.isLoading = false;
    }
  }

  /**
   * Delete this intervention with confirmation
   */
  async onDeleteIntervention() {
    const isDeletingInterventionConfirmed = await this.confirmModalService.showConfirmModal(
      'DELETE_INTERVENTION_CONFIRM_MESSAGE',
      'remove'
    );
    if (isDeletingInterventionConfirmed) {
      await this.deleteIntervention();
    }
  }

  /**
   * Delete this intervention which have to be closed before
   */
  async deleteIntervention() {
    this.state.isLoading = true;
    this.state.isUpdated = false;
    this.state.isFailed = false;

    try {
      await PromiseUtils.setMinDelay(
        this.interventionService.deleteInterventionV1(this.organizationId, this.intervention?._id),
        coreConfig.forms.minRefreshTimeout
      );
      await this.router.navigate(['/interventions']);
    } catch (ex) {
      this.errorMessage = ex.message;
      this.state.isFailed = true;
    } finally {
      this.state.isLoading = false;
    }
  }

  /**
   * Load intervention data
   */
  private async loadData() {
    this.form.control.markAsPristine();

    // check to load default data
    if (!this.organizationId || !this.intervention) {
      this.data.intervention = new Intervention({
        type: undefined,
        startDate: new Date(),
        statistics: {
          victims: [],
          engagedResources: [],
          engagedStaff: [],
          otherServices: [],
        },
      });
      this.data.allDrones = null;
      this.data.attachedDronesIds = null;
      return;
    }

    // load data
    this.state.isEditable = false;
    this.state.isLoading = true;
    this.state.isFailed = false;

    try {
      this.data.intervention = cloneDeep(this.intervention);
      if (!this.data.intervention.statistics) {
        this.data.intervention.statistics = {
          victims: [],
          engagedResources: [],
          engagedStaff: [],
          otherServices: [],
        };
      }
      const drones: Drone[] = await this.droneService.orgDroneControllerGetOrgDrones(this.organizationId).toPromise();
      this.droneStoreService.setOrganizationDrones(this.organizationId, _.cloneDeep(drones));
      this.data.allDrones = drones.map((drone) => ({
        value: drone._id,
        name:
          drone.name +
          (!!drone.currentIntervention && drone.currentIntervention !== this.intervention._id
            ? this.INTERVENTION_DRONE_STATE_USED
            : ''),
      }));
      this.data.attachedDronesIds = drones
        .filter((drone) => drone.currentIntervention === this.intervention._id)
        .map((drone) => drone._id);
    } catch (ex) {
      this.state.isFailed = true;
    } finally {
      this.state.isEditable = true;
      this.state.isLoading = false;
    }
  }
}
