import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { appConfig } from 'src/app/app.config';
import { MediaService } from 'src/app/autogenerated/mediaService';
import { DroneLiveStreamMedia, MediaNavigationInfo, MediaType, OrganizationMedia } from 'src/app/autogenerated/model';
import { Unsubscribe } from 'src/app/core/decorators';
import { PagingService } from 'src/app/core/paging.service';
import { TemplateService } from 'src/app/global/template.service';
import { InterventionStoreService } from 'src/app/intervention/store';
import { NavigationService } from 'src/app/midgard-controls/navigation.service';
import { required } from 'src/app/shared';
import { MediaOperationsService } from '../common/media-operations.service';
import { isEmpty } from 'lodash';
import { AuthenticationStoreService } from 'src/app/authentication/store';
import { RightPanelType } from 'src/app/midgard-controls/enums/right-panel-type';

@Unsubscribe()
@Component({
  selector: 'app-media-all',
  templateUrl: './media-all.component.html',
  styleUrls: ['./media-all.component.scss'],
})
export class MediaAllComponent implements OnInit, OnDestroy {
  media: OrganizationMedia = null;
  mediaUrl: string;
  navInfo: MediaNavigationInfo = null;
  mediaTypeFilter?: MediaType;
  interventionIdFilter?: string;
  interventionName?: string;
  previewUrl: string;
  showVideoThumb = false;

  private paramsSubscription: Subscription;
  private queryParamsSubscription: Subscription;

  get mediaType() {
    return MediaType;
  }

  get startStreamDate() {
    return (this.media as DroneLiveStreamMedia)?.startDate;
  }

  get dateTimeFormat() {
    return appConfig.format.dateTimeFormat;
  }

  get longDateTimeFormat() {
    return appConfig.format.longDateTimeFormat;
  }

  get isEmpty() {
    return isEmpty;
  }

  get RightPanelType() {
    return RightPanelType;
  }

  constructor(
    private templateService: TemplateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authenticationStoreService: AuthenticationStoreService,
    private mediaService: MediaService,
    private pagingService: PagingService,
    private mediaOperationsService: MediaOperationsService,
    private navigationService: NavigationService,
    private interventionStoreService: InterventionStoreService,
    readonly translate: TranslateService
  ) {
    this.templateService.$activeComponent.next('INTERVENTION.TAB.MEDIA');
    this.templateService.pushToAll('mid-media');
  }

  ngOnInit() {
    this.queryParamsSubscription = this.activatedRoute.queryParams.subscribe((params) => {
      this.mediaTypeFilter = params['type'];
      this.interventionIdFilter = params['interventionId'];
    });

    this.paramsSubscription = this.activatedRoute.params.subscribe((params) => {
      const mediaId = params['id'];
      this.onMediaChanged(mediaId);
    });
  }

  ngOnDestroy(): void {}

  goToNextMedia() {
    if (this.navInfo) {
      this.mediaService.navigateToMedia(this.navInfo.nextMedia, {
        type: this.mediaTypeFilter,
        interventionId: this.interventionIdFilter,
      });
    }
  }

  goToPreviousMedia() {
    if (this.navInfo) {
      this.mediaService.navigateToMedia(this.navInfo.previousMedia, {
        type: this.mediaTypeFilter,
        interventionId: this.interventionIdFilter,
      });
    }
  }

  async close() {
    if (this.interventionIdFilter) {
      await this.router.navigate(['interventions', this.interventionIdFilter, 'media']);
      return;
    }
    await this.router.navigate(['media']);
  }

  async goBack() {
    await this.navigationService.goBack();
  }

  downloadMedia(): void {
    const mediaPath = this.getDownloadPath(this.media).split('?')[0];
    this.mediaOperationsService.downloadMedia(mediaPath, this.media);
  }

  openPrintDialog(): void {
    const mediaPath = this.getDownloadPath(this.media).split('?')[0];
    this.mediaOperationsService.openPrintDialog(mediaPath);
  }

  private getDownloadPath(@required media: OrganizationMedia): string {
    return this.mediaService.getDownloadUrl(media);
  }

  private async onMediaChanged(mediaId: string) {
    this.media = await this.mediaService.getMedia({ _id: mediaId });
    if (!this.media) {
      return;
    }

    // header name component
    this.templateService.$media.next(this.media);
    const mediaUrl = this.mediaService.getDownloadUrl(this.media);
    switch (this.media.mediaType) {
      case MediaType.picture:
        this.mediaUrl = mediaUrl;
        break;
      case MediaType.video:
        const token = await this.authenticationStoreService.getAccessToken().pipe(take(1)).toPromise();
        this.mediaUrl = `${mediaUrl}?token=${token}`;
        this.previewUrl = this.mediaUrl;
        break;
      default:
        break;
    }

    const mediaNavigationInfo = await this.mediaService.getMediaNavigationInfoV1(
      this.media.organization as string,
      this.media._id,
      {
        type: this.mediaTypeFilter,
        interventionId: this.interventionIdFilter,
      }
    );
    if (mediaNavigationInfo) {
      this.navInfo = mediaNavigationInfo;
      this.pagingService.snapshotPage =
        Math.floor((mediaNavigationInfo.total - mediaNavigationInfo.index) / this.pagingService.limit) + 1;
    }

    const intervention = this.media?.intervention
      ? await this.interventionStoreService
          .getInterventionById(this.media?.intervention as string)
          .pipe(take(1))
          .toPromise()
      : null;
    this.interventionName = intervention?.name || '';
  }
}
