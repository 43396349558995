import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ControlContainer, NgForm, NgModel } from '@angular/forms';
import { MidInputSize } from '../enums/input-size.enum';

@Component({
  selector: 'app-mid-input-date-time',
  templateUrl: './mid-input-date-time.component.html',
  styleUrls: ['./mid-input-date-time.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  exportAs: 'appMidInputDateTime',
})
export class MidInputDateTimeComponent {
  @Input()
  value: Date;
  @Output()
  valueChange: EventEmitter<Date>;

  @Input()
  name = 'appMidInputDateTimeName_' + ((Math.random() * 10000) | 0);

  @Input()
  label = '';

  @Input()
  size: MidInputSize = MidInputSize.default;

  @Input()
  disabled: string;

  @Input()
  required: boolean = false;

  @ViewChild('midInput')
  private input: NgModel;

  constructor() {
    this.valueChange = new EventEmitter();
  }

  get strDateTimeValue() {
    const date = !!this.value?.getFullYear?.call ? this.value : new Date();
    return (
      date.getFullYear().toString() +
      '-' +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + date.getDate()).slice(-2) +
      'T' +
      date.toTimeString().slice(0, 5)
    );
    // return date.toISOString().slice(0, 16);
  }
  set strDateTimeValue(strDateTime: string) {
    try {
      this.value = strDateTime ? new Date(strDateTime) : new Date();
    } catch (error) {
      this.value = new Date();
    }
  }

  onValueChange() {
    this.valueChange.emit(this.value);
  }

  get MidInputSize() {
    return MidInputSize;
  }

  get valid() {
    return this.input?.valid;
  }

  get invalid() {
    return this.input?.invalid;
  }

  get dirty() {
    return this.input?.dirty;
  }

  get pristine() {
    return this.input?.pristine;
  }

  get touched() {
    return this.input?.touched;
  }

  get untouched() {
    return this.input?.untouched;
  }
}
