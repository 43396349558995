<div class="media-list">
  <app-media-card
    *ngFor="let media of mediaList; trackBy: trackByItem"
    [media]="media"
    [mediaTypeFilter]="mediaTypeFilter"
    [interventionIdFilter]="interventionIdFilter"
    [canDelete]="canDelete"
    [mediaPageLocation]="mediaPageLocation"
    (mediaToDelete)="onDeleteMedia($event)"
    (mediaToRefresh)="onRefreshMedia($event)"
    [displayMode]="displayMode"
    [isSelected]="selectedMediaIds.includes(media._id)"
    (onSelectedMediaId)="selectedMediaId($event)"
    (selectedMediaProperties)="onDisplaySelectedMediaProperties($event)"
    (toggleFavoriteMedia)="onToggleFavorite($event)"
    [isFavorite]="media.favoriteForUsers?.includes(userId)"
    class="media-card"
  >
  </app-media-card>
</div>
