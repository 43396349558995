import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlContainer, NgForm, NgModel } from '@angular/forms';
import { MidSelectItem, MidSelectSize } from '../models';

@Component({
  selector: 'app-mid-select',
  templateUrl: './mid-select.component.html',
  styleUrls: ['./mid-select.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  exportAs: 'appMidSelect',
})
export class MidSelectComponent implements OnChanges {
  @Input()
  items: MidSelectItem[] = [];
  @Output()
  itemsChange: EventEmitter<MidSelectItem[]>;

  @Input()
  selectedItem = '';
  @Output()
  selectedItemChange: EventEmitter<string>;

  @Input()
  name = 'appMidSelectName_' + ((Math.random() * 10000) | 0);

  @Input()
  label = '';

  @Input()
  placeholder = '';

  @Input()
  size: MidSelectSize = MidSelectSize.default;

  @Input()
  disabled: false;

  @Input()
  required: false;

  @ViewChild('midSelect')
  select: NgModel;

  get MidSelectSize() {
    return MidSelectSize;
  }

  constructor() {
    this.itemsChange = new EventEmitter<MidSelectItem[]>();
    this.selectedItemChange = new EventEmitter<string>();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.selectedItem) {
      this.selectedItem = '';
    }
  }

  onItemsChange($event: any) {
    this.items.forEach((it) => (it.isSelected = it.value === $event));
    this.itemsChange.emit(this.items);
    this.selectedItemChange.emit(this.selectedItem);
  }

  get valid() {
    return this.select?.valid;
  }

  get invalid() {
    return this.select?.invalid;
  }

  get dirty() {
    return this.select?.dirty;
  }

  get pristine() {
    return this.select?.pristine;
  }

  get touched() {
    return this.select?.touched;
  }

  get untouched() {
    return this.select?.untouched;
  }
}
