<app-mid-right-panel [isMainMenu]="true">
  <app-mid-right-panel-header>
    <div class="container justify-content-around py-1 logos">
      <img (click)="goTo('/')" class="mid-logo" src="/assets/images/midgard-logo-black.png" />
      <img *ngIf="organizationLogoUrl" (click)="goTo('/')" class="mid-org-logo" [src]="organizationLogoUrl" />
    </div>
  </app-mid-right-panel-header>
  <app-mid-right-panel-content>
    <div class="row m-0 px-4 pb-4">
      <section class="section col-md-12">
        <app-mid-toggle-switch
          textOn="MAIN_APP_MENU.DARK_THEME"
          textOff="MAIN_APP_MENU.DARK_THEME"
          [(state)]="ColorTheme.dark === currentTheme"
          (action)="toggleTheme()"
          [labelPosition]="LabelPosition.before"
          [type]="SwitchType.themeSwitch"
        >
        </app-mid-toggle-switch>

        <!-- [labelPosition]="LabelPosition.before" -->
      </section>
      <section class="section col-md-12">
        <h2 class="title">{{ 'MAIN_APP_MENU.MY_ACCOUNT' | translate }}</h2>
        <app-mid-button
          type="secondary"
          class="main-menu-button"
          [routerLink]="myProfileRoute"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          (action)="close()"
        >
          <div class="main-menu-button-content">
            <i class="fas fa-user"></i>
            {{ 'MAIN_APP_MENU.MY_PROFILE' | translate }}
          </div>
        </app-mid-button>
      </section>
      <section class="section col-md-12">
        <h2 class="title">{{ 'MAIN_APP_MENU.MENU' | translate }}</h2>
        <app-mid-button
          type="secondary"
          class="main-menu-button"
          [routerLink]="homeRoute"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          (action)="close()"
        >
          <div class="main-menu-button-content">
            <i class="fas fa-home"></i>
            {{ 'MAIN_APP_MENU.HOME' | translate }}
          </div>
        </app-mid-button>
        <app-mid-button
          type="secondary"
          class="main-menu-button"
          [routerLink]="interventionsRoute"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          (action)="close()"
        >
          <div class="main-menu-button-content">
            <i class="fas fa-truck"></i>
            {{ 'MAIN_APP_MENU.INTERVENTIONS' | translate }}
          </div>
        </app-mid-button>
        <app-mid-button
          type="secondary"
          class="main-menu-button"
          [routerLink]="dronesRoute"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          (action)="close()"
        >
          <div class="main-menu-button-content">
            <i class="fas fa-helicopter"></i>
            {{ 'MAIN_APP_MENU.DRONES' | translate }}
          </div>
        </app-mid-button>
        <app-mid-button type="secondary" class="main-menu-button" [disabled]="true">
          <!-- [routerLink]="mapsRoute"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (action)="close()" -->
          <div class="main-menu-button-content">
            <i class="fas fa-map"></i>
            {{ 'MAIN_APP_MENU.MAPS' | translate }}
          </div>
        </app-mid-button>
        <app-mid-button
          type="secondary"
          class="main-menu-button"
          [routerLink]="mediaRoute"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          (action)="close()"
        >
          <div class="main-menu-button-content">
            <i class="fas fa-photo-video"></i>
            {{ 'MAIN_APP_MENU.MEDIA' | translate }}
          </div>
        </app-mid-button>
      </section>
      <section class="section col-md-12">
        <h2 class="title">{{ 'MAIN_APP_MENU.ADMINISTRATION' | translate }}</h2>
        <app-mid-button
          type="secondary"
          class="main-menu-button"
          [routerLink]="organizationRoute"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          (action)="close()"
        >
          <div class="main-menu-button-content">
            <i class="fas fa-file"></i>
            {{ 'MAIN_APP_MENU.MY_ORGANIZATION' | translate }}
            <span>{{ selectedOrganization.name }}</span>
            <i
              class="fas fa-caret-square-down fas-menu"
              *ngIf="organizations?.length > 1"
              [matMenuTriggerFor]="menuOrganizations"
              click-stop-propagation
            ></i>
          </div>
        </app-mid-button>
        <mat-menu #menuOrganizations="matMenu" class="mid-menu">
          <div *ngFor="let item of organizations" class="mid-menu-item" (click)="changeOrganization(item._id)">
            <i class="fas fa-file mr-1 mid-menu-item-icon"></i>
            <span>{{ item.name }}</span>
          </div>
        </mat-menu>
        <app-mid-button type="secondary" class="main-menu-button" [disabled]="true">
          <!-- [routerLink]="statisticsRoute"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (action)="close()" -->
          <div class="main-menu-button-content">
            <i class="fas fa-chart-area"></i>
            {{ 'MAIN_APP_MENU.STATISTICS' | translate }}
          </div>
        </app-mid-button>
        <app-mid-button type="secondary" class="main-menu-button" [disabled]="true">
          <!-- [routerLink]="activitiesRoute"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (action)="close()" -->
          <div class="main-menu-button-content">
            <i class="fas fa-star"></i>
            {{ 'MAIN_APP_MENU.FOLLOW_ACTIVITIES' | translate }}
          </div>
        </app-mid-button>
      </section>
      <section class="section col-md-12">
        <h2 class="title"></h2>
        <app-mid-button type="secondary" class="main-menu-button log-out" (action)="onSignOut()">
          <div class="main-menu-button-content">
            <i class="fas fa-sign-out-alt"></i>
            {{ 'MAIN_APP_MENU.LOG_OUT' | translate }}
          </div>
        </app-mid-button>
      </section>
    </div>
  </app-mid-right-panel-content>
  <!--
    <app-mid-right-panel-footer>
      <p>FOOTER</p>
    </app-mid-right-panel-footer>
    -->
</app-mid-right-panel>
