import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '../core/core.module';
import { MidBadgeComponent } from './mid-badge/mid-badge.component';
import { MidButtonComponent } from './mid-button/mid-button.component';
import { MidIconButtonComponent } from './mid-icon-button/mid-icon-button.component';
import { MidExpansionPanelComponent } from './mid-expansion-panel/mid-expansion-panel.component';
import { ModalHeaderComponent } from './modal-header/modal-header.component';
import { MidInputComponent } from './mid-input/mid-input.component';
import { MidInputDateTimeComponent } from './mid-input-date-time/mid-input-date-time.component';
import { MidButtonToggleGroupComponent } from './mid-button-toggle-group/mid-button-toggle-group.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MidChipListComponent } from './mid-chip-list/mid-chip-list.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MidBlockCarouselComponent } from './mid-block-carousel/mid-block-carousel.component';
import { MidSimpleDashboardBlockComponent } from './mid-simple-dashboard-block/mid-simple-dashboard-block.component';
import { MidRightPanelComponent } from './mid-right-panel/mid-right-panel.component';
import { MidRightPanelHeaderComponent } from './mid-right-panel-header/mid-right-panel-header.component';
import { MidRightPanelContentComponent } from './mid-right-panel-content/mid-right-panel-content.component';
import { MidRightPanelFooterComponent } from './mid-right-panel-footer/mid-right-panel-footer.component';
import { MidViewBlockComponent } from './mid-view-block/mid-view-block.component';
import { MidSideModalComponent } from './mid-side-modal/mid-side-modal.component';
import { MatMenuModule } from '@angular/material/menu';
import { MidSelectComponent } from './mid-select/mid-select.component';
import { MatSelectModule } from '@angular/material/select';
import { MidBackButtonComponent } from './mid-back-button/mid-back-button.component';
import { MidViewBlockButtonsComponent } from './mid-view-block-buttons/mid-view-block-buttons.component';
import { MidInputPhoneNumberComponent } from './mid-input-phone-number/mid-input-phone-number.component';
import { MidComponentModalComponent } from './mid-component-modal/mid-component-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MidCheckboxComponent } from './mid-checkbox/mid-checkbox.component';
import { GroupSelectDropdown } from '../user-management/group-select-dropdown/group-select-dropdown.component';
import { MidStatisticsVictimsComponent } from './mid-statistics-victims/mid-statistics-victims.component';
import { MidStatisticsEngagedStaffComponent } from './mid-statistics-engaged-staff/mid-statistics-engaged-staff.component';
import { MidStatisticsOtherServicesComponent } from './mid-statistics-other-services/mid-statistics-other-services.component';
import { MidStatisticsEngagedResourcesComponent } from './mid-statistics-engaged-resources/mid-statistics-engaged-resources.component';
import { MidTooltipDirective } from './mid-tooltip/mid-tooltip.directive';
import { MidTooltipComponent } from './mid-tooltip/mid-tooltip.component';
import { MidToggleSwitchComponent } from './mid-toggle-switch/mid-toggle-switch.component';
import { MidTextareaComponent } from './mid-textarea/mid-textarea.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { MidSelectInterventionComboboxComponent } from './mid-select-intervention-combobox/mid-select-intervention-combobox.component';
import { MatInputModule } from '@angular/material/input';
import { MidSelectLangComponent } from './mid-select-lang/mid-select-lang.component';
import { CountryFlagDirective } from './mid-select-lang/country-flag.directive';

@NgModule({
  declarations: [
    MidButtonComponent,
    MidIconButtonComponent,
    MidBadgeComponent,
    MidExpansionPanelComponent,
    ModalHeaderComponent,
    MidInputComponent,
    MidInputDateTimeComponent,
    MidSelectComponent,
    MidButtonToggleGroupComponent,
    MidChipListComponent,
    MidBlockCarouselComponent,
    MidSimpleDashboardBlockComponent,
    MidRightPanelComponent,
    MidRightPanelHeaderComponent,
    MidRightPanelContentComponent,
    MidRightPanelFooterComponent,
    MidViewBlockComponent,
    MidSideModalComponent,
    MidBackButtonComponent,
    MidViewBlockButtonsComponent,
    MidInputPhoneNumberComponent,
    MidComponentModalComponent,
    MidCheckboxComponent,
    GroupSelectDropdown,
    MidStatisticsVictimsComponent,
    MidStatisticsEngagedStaffComponent,
    MidStatisticsOtherServicesComponent,
    MidStatisticsEngagedResourcesComponent,
    MidTooltipComponent,
    MidTooltipDirective,
    MidToggleSwitchComponent,
    MidTextareaComponent,
    MidSelectInterventionComboboxComponent,
    MidSelectLangComponent,
    CountryFlagDirective,
  ],
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    MatButtonToggleModule,
    MatIconModule,
    MatTooltipModule,
    MatMenuModule,
    MatFormFieldModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatSelectModule,
    NgxIntlTelInputModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatInputModule,
  ],
  exports: [
    MidButtonComponent,
    MidIconButtonComponent,
    MidBadgeComponent,
    MidExpansionPanelComponent,
    ModalHeaderComponent,
    MidInputComponent,
    MidInputDateTimeComponent,
    MidSelectComponent,
    MidButtonToggleGroupComponent,
    MidChipListComponent,
    MidBlockCarouselComponent,
    MidSimpleDashboardBlockComponent,
    MidRightPanelComponent,
    MidRightPanelHeaderComponent,
    MidRightPanelContentComponent,
    MidRightPanelFooterComponent,
    MidViewBlockComponent,
    MidBackButtonComponent,
    MidInputPhoneNumberComponent,
    MidComponentModalComponent,
    MidCheckboxComponent,
    GroupSelectDropdown,
    MidStatisticsVictimsComponent,
    MidStatisticsEngagedStaffComponent,
    MidStatisticsOtherServicesComponent,
    MidStatisticsEngagedResourcesComponent,
    MidTooltipDirective,
    MidToggleSwitchComponent,
    MidTextareaComponent,
    MidSelectInterventionComboboxComponent,
    MidSelectLangComponent,
  ],
})
export class MidgardControlsModule {}
