import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Intervention, Organization, OrganizationMedia } from '../../../autogenerated/model';
import { Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrganizationStoreService } from '../../../organization/store';
import { InterventionService } from '../../../autogenerated/interventionService';
import { Unsubscribe } from 'src/app/core/decorators';

@Unsubscribe()
@Component({
  selector: 'app-media-interventions-dialog',
  templateUrl: './media-interventions-dialog.component.html',
  styleUrls: ['./media-interventions-dialog.component.scss'],
})
export class MediaInterventionsDialogComponent implements OnInit, OnDestroy {
  private organization: Organization;
  private organizationSubscription: Subscription;

  organizationId: string;
  selectedIntervention: Intervention;
  selectedMedia: OrganizationMedia;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { intervention: Intervention; media: OrganizationMedia },
    private dialogRef: MatDialogRef<MediaInterventionsDialogComponent>,
    private readonly organizationStoreService: OrganizationStoreService,
    private readonly interventionService: InterventionService
  ) {
    this.selectedIntervention = data.intervention;
    this.selectedMedia = data.media;
  }

  ngOnInit() {
    this.organizationSubscription = this.organizationStoreService
      .getCurrentOrganization()
      .subscribe((organization: Organization) => {
        if (organization?._id === this.organizationId) {
          return;
        }
        this.organization = organization;
        this.organizationId = organization._id;
      });
  }

  ngOnDestroy() {}

  async onSubmit() {
    const media = await this.interventionService.assignMediaToIntervention(
      this.organizationId,
      this.selectedIntervention._id,
      this.selectedMedia._id
    );
    this.dialogRef.close(media);
  }

  onClose() {
    this.dialogRef.close();
  }

  getSelectedIntervention(selectedIntervention: Intervention) {
    this.selectedIntervention = selectedIntervention;
  }
}
