<div class="row m-0 p-0" *ngIf="drone?.streamingStatus == 'streaming'" [routerLink]="['/drone', drone._id, 'stream']">
  <!-- icons -->
  <div class="col-3 m-0 p-0 pt-1 pl-1 pb-1">
    <img src="/assets/images/mid-activity-stream-icon-live.png" />
  </div>

  <!-- content -->
  <div class="col-9 m-0 p-0">
    <div class="row m-0 p-0">
      <div class="col-12 m-0 p-0 mid-activity-stream-drone-live-title">
        <app-drone-stream-status [drone]="drone"></app-drone-stream-status>
      </div>

      <div class="row m-0 p-0 pt-1 pb-1 mid-activity-stream-drone-live-content">&nbsp;</div>

      <div class="col-12 m-0 p-0 text-right mid-activity-stream-drone-live-date">
        {{ data?.date | date : 'dd/MM/yyyy HH:mm' }}
      </div>
    </div>
  </div>
</div>

<div class="row m-0 p-0" *ngIf="drone?.streamingStatus != 'streaming'">
  <!-- content -->
  <div class="col-12 m-0 p-0">
    <div class="row m-0 p-0">
      <div class="col-12 m-0 p-0 mid-activity-stream-drone-live-title">
        {{ drone?.name }}: {{ 'LIVE_STREAM_ON' | translate }}
      </div>

      <div class="col-12 m-0 p-0 pt-1 text-right mid-activity-stream-drone-live-date">
        {{ data?.date | date : 'dd/MM/yyyy HH:mm' }}
      </div>
    </div>
  </div>
</div>
