import { Intervention, Organization, StreamingStatus, UpdateDroneInput } from '../../../autogenerated/model';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { DroneService } from 'src/app/autogenerated/droneService';
import { DroneService as DroneServiceV2, OrganizationGroupService } from 'src/api';
import { LiveStreamService } from 'src/app/autogenerated/liveStreamService';
import { Drone } from 'src/app/autogenerated/model';
import { TemplateService } from 'src/app/global/template.service';
// import { IntroJs } from 'intro.js';
import { OrganizationService } from 'src/app/autogenerated/organizationService';
import { OrganizationStoreService } from 'src/app/organization/store';
import { DroneStoreService } from '../../store';
import { SelectDroneToAssignComponent } from '../select-drone-to-assign/select-drone-to-assign.component';
import { MatDialog } from '@angular/material/dialog';
import _, { cloneDeep } from 'lodash';
import { Router } from '@angular/router';
import { Unsubscribe } from 'src/app/core/decorators';
import { MidRightPanelService } from 'src/app/midgard-controls/services/mid-right-panel.service';
import { CreateDroneComponent } from '../../dialogs/create-drone-right-panel/create-drone/create-drone.component';

@Unsubscribe()
@Component({
  selector: 'app-drones',
  templateUrl: './drones.component.html',
  styleUrls: ['./drones.component.scss'],
})
export class DronesComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  intervention: Intervention;

  @Input()
  isInterventionRequired = false;

  drones: Drone[] = [];
  allDrones: Drone[] = [];
  showNewDroneDialog = false;
  isAdmin = false;

  state: {
    isLoading: boolean;
    isFailed: boolean;
  };

  private selectDroneModalSubscription: Subscription;
  private createDroneSubscription: Subscription;
  private dronesSubscription: Subscription;
  private organizationSubscription: Subscription;
  private currentOrganization: Organization;

  constructor(
    private readonly organizationService: OrganizationService,
    private readonly templateService: TemplateService,
    private readonly liveStreamService: LiveStreamService,
    private readonly droneService: DroneService,
    private readonly droneServiceV2: DroneServiceV2,
    private readonly organizationStoreService: OrganizationStoreService,
    private readonly droneStoreService: DroneStoreService,
    private readonly dialog: MatDialog,
    private readonly midRightPanelService: MidRightPanelService,
    private readonly organizationGroupService: OrganizationGroupService,
    public router: Router
  ) {
    this.templateService.$activeComponent.next('COMMON.DRONES');

    this.state = {
      isLoading: false,
      isFailed: false,
    };
  }

  async ngOnInit(): Promise<void> {
    this.organizationSubscription = this.organizationStoreService
      .getCurrentOrganization()
      .subscribe(async (organization: Organization) => {
        this.currentOrganization = organization;
        this.isAdmin = await this.organizationGroupService
          .orgOrganizationGroupControllerIsOrgAdmin(this.currentOrganization._id)
          .toPromise();
        this.updateDroneSubscription(this.currentOrganization._id);
        await this.tryToLoadDrones();
      });
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const componentChanges = changes as PropertyMap<DronesComponent, SimpleChange>;
    const interventionChange = componentChanges.intervention;
    const hasInterventionIdChanges =
      interventionChange?.currentValue?._id &&
      interventionChange?.currentValue?._id !== interventionChange?.previousValue?._id;
    if (!hasInterventionIdChanges) {
      return;
    }
    await this.tryToLoadDrones();
  }

  onOpenAssignDroneToInterventionDialog(): void {
    if (this.selectDroneModalSubscription) {
      this.selectDroneModalSubscription.unsubscribe();
      this.selectDroneModalSubscription = null;
    }

    const dialogRef = this.dialog.open<SelectDroneToAssignComponent, any, Drone>(SelectDroneToAssignComponent, {
      id: 'assignDroneToIntervention',
      width: '450px',
      data: { interventionId: this.intervention, drones: this.allDrones },
      closeOnNavigation: true,
    });

    this.selectDroneModalSubscription = dialogRef.afterClosed().subscribe(async (droneId: string) => {
      if (droneId) {
        const droneInput = new UpdateDroneInput({ id: droneId, currentIntervention: this.intervention._id });
        const updatedDrone = await this.droneService.updateDrone(droneInput);
        this.droneStoreService.updateDrone(this.currentOrganization._id, updatedDrone);
      }
    });
  }

  deactivateDrone(drone: Drone): void {
    this.liveStreamService.stopEvent(drone);
  }

  ngOnDestroy(): void {}

  onOpenCreateDrone(): void {
    this.createDroneSubscription = this.midRightPanelService.open(CreateDroneComponent).subscribe(() => {
      this.tryToLoadDrones();
    });
  }

  async tryToLoadDrones(): Promise<void> {
    if (!this.currentOrganization) {
      return;
    }
    if (this.isInterventionRequired && !this.intervention) {
      return;
    }
    await this.loadDrones(this.currentOrganization._id);
  }

  private updateDroneSubscription(organizationId: string): void {
    if (this.dronesSubscription) {
      this.dronesSubscription.unsubscribe();
      this.dronesSubscription = null;
    }

    this.dronesSubscription = this.droneStoreService.getOrganizationDrones(organizationId).subscribe((drones) => {
      this.allDrones = cloneDeep(drones);
      this.drones = this.isInterventionRequired ? this.filterDrones(drones) : this.sortDrones(drones);
    });
  }

  private async loadDrones(organizationId: string): Promise<void> {
    this.state.isLoading = true;
    this.state.isFailed = false;

    try {
      const drones = await this.droneServiceV2.orgDroneControllerGetOrgDrones(organizationId).toPromise();
      this.allDrones = _.cloneDeep(drones);
      this.droneStoreService.setOrganizationDrones(organizationId, _.cloneDeep(drones));
      this.drones = this.isInterventionRequired ? this.filterDrones(drones) : this.sortDrones(drones);
    } catch (ex) {
      this.state.isFailed = true;
    } finally {
      this.state.isLoading = false;
    }
  }

  private filterDrones(drones: Drone[]): Drone[] {
    const filteredDrones = _.filter(drones, (item) => item.currentIntervention === this.intervention._id);
    return this.sortDrones(filteredDrones);
  }

  private sortDrones(drones: Drone[]): Drone[] {
    const sortedDrones = _.sortBy(drones, (drone) => (drone.streamingStatus === StreamingStatus.streaming ? -1 : 0));
    return sortedDrones;
  }

  // TODO: restore later
  // private async showDroneIntro(): Promise<void> {
  //   const isDroneIntroShown = await this.layoutStoreService.getIsDroneIntroShown().pipe(take(1)).toPromise();
  //   if (!isDroneIntroShown) {
  //     const intro: IntroJs = await this.introService.createIntroJs();
  //
  //     setTimeout(() => {
  //       intro.goToStepNumber(this.startDroneStepNumber).start();
  //       this.layoutStoreService.setIsDroneIntroShown();
  //       if (this.drones.length > 0) {
  //         this.layoutStoreService.setIsFirstDroneIntroShown();
  //       }
  //     }, 500);
  //   }
  // }
  //
  // private async showFirstDroneIntro(): Promise<void> {
  //   const isFirstDroneIntroShown = await this.layoutStoreService.getIsFirstDroneIntroShown().pipe(take(1)).toPromise();
  //   if (!isFirstDroneIntroShown) {
  //     const intro: IntroJs = await this.introService.createIntroJs();
  //
  //     setTimeout(() => {
  //       intro.goToStepNumber(this.startFirstDroneStepNumber).start();
  //       this.layoutStoreService.setIsFirstDroneIntroShown();
  //     }, 500);
  //   }
  // }
}
