import {
  Organization,
  DbClass,
  Drone,
  OrganizationMedia,
  DroneLiveStreamMedia,
  DroneLiveSnapshotMedia,
  getUsersOutput,
  UserOrganizationInput,
  AddUserOutput,
  OrganizationMember,
} from '../model';
import { BackendClientService } from '../../global/backend-client.service';
import { ApiService } from 'src/app/core/api';
import { trim } from 'lodash';

export class OrganizationServiceAutoGenerated {
  constructor(private beClient: BackendClientService, private apiService: ApiService) {}

  async getOrganizations(): Promise<Organization[]> {
    let result: Organization[];
    try {
      result = await this.beClient.post<Organization[]>('organizationService/getOrganizations', {}).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return result;
  }

  async getAllOrganizations(): Promise<Organization[]> {
    let result: Organization[];
    try {
      result = await this.beClient.post<Organization[]>('organizationService/getAllOrganizations', {}).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return result;
  }

  async getOrganization(input: DbClass): Promise<Organization> {
    let result: Organization;
    try {
      result = await this.beClient.post<Organization>('organizationService/getOrganization', input).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return result;
  }

  async create(organization: Organization): Promise<Organization> {
    let result: Organization;
    try {
      result = await this.beClient.post<Organization>('organizationService/create', organization).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return result;
  }

  async getMedias(input: DbClass): Promise<OrganizationMedia[]> {
    let result: OrganizationMedia[];
    try {
      result = await this.beClient.post<OrganizationMedia[]>('organizationService/getMedias', input).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return result;
  }

  async getLiveStreamMedia(input: DbClass): Promise<DroneLiveStreamMedia[]> {
    let result: DroneLiveStreamMedia[];
    try {
      result = await this.beClient
        .post<DroneLiveStreamMedia[]>('organizationService/getLiveStreamMedia', input)
        .toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return result;
  }

  async getSnapShotsMedia(input: DbClass): Promise<DroneLiveSnapshotMedia[]> {
    let result: DroneLiveSnapshotMedia[];
    try {
      result = await this.beClient
        .post<DroneLiveSnapshotMedia[]>('organizationService/getSnapShotsMedia', input)
        .toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return result;
  }

  async getUsers(input: DbClass): Promise<getUsersOutput> {
    let result: getUsersOutput;
    try {
      result = await this.beClient.post<getUsersOutput>('organizationService/getUsers', input).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return result;
  }

  async addUser(userOrganizationInput: UserOrganizationInput): Promise<AddUserOutput> {
    let result: AddUserOutput;
    try {
      result = await this.beClient
        .post<AddUserOutput>('organizationService/addUser', userOrganizationInput)
        .toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return result;
  }
  async setUser(userOrganizationInput: UserOrganizationInput): Promise<OrganizationMember> {
    let result: OrganizationMember;
    try {
      result = await this.beClient
        .post<OrganizationMember>('organizationService/setUser', userOrganizationInput)
        .toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return result;
  }

  async deleteUser(userOrganizationInput: UserOrganizationInput): Promise<boolean> {
    let result: boolean;
    try {
      result = await this.beClient.post<boolean>('organizationService/removeUser', userOrganizationInput).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
    return result;
  }

  async sendTdrawShareLinkByMail(mail: string, shareLink: string): Promise<void> {
    const body = {
      email: trim(mail),
      url: shareLink,
    };
    try {
      await this.beClient.post('organizationService/sendTdrawShareLinkByMail', body).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }

  async sendTdrawShareLinkBySMS(phoneNumber: string, shareLink: string): Promise<void> {
    const body = {
      phoneNumber: trim(phoneNumber),
      url: shareLink,
    };
    try {
      await this.beClient.post('organizationService/sendTdrawShareLinkBySMS', body).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }

  async sendMediaShareLinkByMail(mail: string, shareLink: string): Promise<void> {
    const body = {
      email: trim(mail),
      url: shareLink,
    };
    try {
      await this.beClient.post('organizationService/sendMediaShareLinkByMail', body).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }

  async sendMediaShareLinkBySMS(phoneNumber: string, shareLink: string): Promise<void> {
    const body = {
      phoneNumber: trim(phoneNumber),
      url: shareLink,
    };

    try {
      await this.beClient.post('organizationService/sendMediaShareLinkBySMS', body).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }
}
