<app-modal-header
  text="{{ 'TDRAW.MODAL.DUPLICATE_VERSION.TITLE' | translate }}"
  (closeAction)="onClose()"
></app-modal-header>
<div class="body">
  <form #formDuplicateMap="ngForm">
    <p [innerHTML]="'TDRAW.MODAL.DUPLICATE_VERSION.NAME_LABEL' | translate"></p>
    <app-mid-input
      class="d-flex justify-content-center"
      [value]="newName"
      (valueChange)="newName = $event"
    ></app-mid-input>
    <div class="buttons">
      <app-mid-button type="secondary" (action)="onClose()">{{ 'COMMON_CANCEL' | translate }}</app-mid-button>
      <app-mid-button type="primary" (action)="onSubmit()">
        <i class="fas fa-check"></i> {{ 'VALIDATE' | translate }}
      </app-mid-button>
    </div>
  </form>
</div>
