<div class="media-share-view" *ngIf="!isEmpty(user)">
  <div class="delete-icon" (click)="onDeleteShare()">
    <i class="fas fa-times"></i>
  </div>

  <span>{{ 'DIALOGS.SHARE_MEDIA_LINK.SHARE_CREATION' | translate : { name: user?.fullname } }}</span>
  <span>
    {{
      'DIALOGS.SHARE_MEDIA_LINK.SHARE_LINK_VALID' | translate : { date: share?.validUntil | date : 'dd/MM/yyyy HH:mm' }
    }}
  </span>

  <app-mid-button class="w-100" type="secondary" size="wide" (action)="copyShareLink()">
    {{ 'COPY' | translate }}
  </app-mid-button>
</div>
