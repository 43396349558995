import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import {
  Drone,
  DroneStreamSharing,
  Intervention,
  StreamingStatus,
  UpdateDroneInput,
} from '../../../autogenerated/model';
import { DroneService } from '../../../autogenerated/droneService';
import { ConfirmModalService } from '../../../midgard-controls/services/confirm-modal.service';
import { DroneStoreService } from '../../store';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { InterventionService } from 'src/app/autogenerated/interventionService';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Unsubscribe } from 'src/app/core/decorators';
import { DroneShareLinkComponent } from '../../dialogs/drone-share-link/drone-share-link.component';
import {
  DronePropertiesComponent,
  DronePropertiesInput,
} from '../../dialogs/drone-properties/drone-properties.component';
import { BaseMidRightPanel, MidRightPanelService } from 'src/app/midgard-controls/services/mid-right-panel.service';
import { AssociateDroneToInterventionComponent } from '../../dialogs/associate-drone-to-intervention/associate-drone-to-intervention.component';
import { InterventionStoreService } from 'src/app/intervention/store';
import { DroneService as DroneServiceV2 } from 'src/api';

@Unsubscribe()
@Component({
  selector: 'app-drone-card',
  templateUrl: './drone-card.component.html',
  styleUrls: ['./drone-card.component.scss'],
})
export class DroneCardComponent implements OnInit, OnDestroy {
  @Input()
  drone: Drone;

  @Input()
  showInterventionBadge = true;

  @Input()
  isSmallCard = false;

  @Input()
  isFreeSpaceClickable = false;

  @Input()
  singleStreamView = false;

  @Input()
  isAdmin: boolean;

  @Input()
  organizationId: string;

  @Output()
  freeSpaceClicked: EventEmitter<Drone>;

  interventionSubscription: Subscription;
  intervention: Intervention;
  associateDroneToInterventionSubscription: Subscription;
  droneShareLinkSubscription: Subscription;

  broadcastLink: string;
  isBroadcastLinkGenerated: boolean;
  currentSharing: DroneStreamSharing;

  private snapshotPromise: Promise<any> | null = null;

  get StreamingStatus() {
    return StreamingStatus;
  }

  constructor(
    private readonly interventionService: InterventionService,
    private readonly interventionStoreService: InterventionStoreService,
    private readonly droneService: DroneService,
    private readonly droneServiceV2: DroneServiceV2,
    private readonly droneStoreService: DroneStoreService,
    private readonly confirmModalService: ConfirmModalService,
    private readonly router: Router,
    private readonly location: Location,
    private readonly midRightPanelService: MidRightPanelService
  ) {
    this.freeSpaceClicked = new EventEmitter<Drone>();
  }

  async ngOnInit(): Promise<void> {
    await this.initCurrentSharing(this.drone);
    // take from cache
    if (this.drone.currentIntervention) {
      this.interventionSubscription = this.interventionStoreService
        .getInterventionById(this.drone.currentIntervention as string)
        .subscribe((cacheIntervention: Intervention) => {
          this.intervention = cacheIntervention ?? null;
        });
    } else {
      this.intervention = null;
    }
  }

  ngOnDestroy(): void {}

  async onDeleteDrone() {
    const isDeletingDroneConfirmed = await this.confirmModalService.showConfirmModal(
      'DELETE_DRONE_CONFIRM_MESSAGE',
      'remove'
    );
    if (isDeletingDroneConfirmed) {
      await this.deleteDrone();
    }
  }

  onFreeSpaceClicked() {
    if (this.isFreeSpaceClickable) {
      this.freeSpaceClicked.next(this.drone);
    }
  }

  async interventionChanged(intervention: Intervention): Promise<void> {
    const updateDroneInput = new UpdateDroneInput({ id: this.drone._id, currentIntervention: intervention._id });
    await this.updateDrone(updateDroneInput);
  }

  async onUnlinkDroneToIntervention() {
    const isUnlinkDroneToInterventionConfirmed = await this.confirmModalService.showConfirmModal(
      'UNLINK_DRONE_TO_INTERVENTION_CONFIRM_MESSAGE',
      'validate'
    );
    if (isUnlinkDroneToInterventionConfirmed) {
      await this.unlinkDroneToIntervention();
    }
  }

  createSnapshot(event) {
    const playerTime = new Date().getTime();
    const button = event.currentTarget;
    button.classList.add('mid-loading');
    const wait = setTimeout(null, 1000);
    const snapshot = this.droneServiceV2.droneControllerSnapshot(this.drone._id, playerTime).toPromise();

    const currentPromise = Promise.all([wait, snapshot]);
    this.snapshotPromise = currentPromise;
    this.snapshotPromise.then(() => {
      if (currentPromise !== this.snapshotPromise) {
        // the user clicked again before this was resolved
        return;
      }
      // button became green
      button.classList.add('mid-saved');

      // after 1 second everything goes to normal if the user didn't press again
      setTimeout(() => {
        if (currentPromise !== this.snapshotPromise) {
          // the user clicked again before this was resolved
          return;
        }
        button.classList.remove('mid-loading');
        button.classList.remove('mid-saved');
      }, 1000);
    });
  }

  onOpenDroneShareLink(): void {
    const data = { drone: this.drone, isAdmin: this.isAdmin };
    this.droneShareLinkSubscription = this.midRightPanelService
      .open(DroneShareLinkComponent, data)
      .subscribe((output: BaseMidRightPanel<{ isShared: boolean }>) => {
        this.isBroadcastLinkGenerated = output.data.isShared;
      });
  }

  onOpenDroneProperties(): void {
    const data = { drone: this.drone } as DronePropertiesInput;
    this.midRightPanelService.open(DronePropertiesComponent, data);
  }

  onOpenAssociateDroneToIntervention(): void {
    const data = { drone: this.drone, isAdmin: this.isAdmin };
    this.associateDroneToInterventionSubscription = this.midRightPanelService
      .open(AssociateDroneToInterventionComponent, data)
      .subscribe((output: BaseMidRightPanel<{ intervention: Intervention }>) => {
        this.intervention = output.data.intervention;
      });
  }

  redirectToStream(): void {
    this.router.navigate([this.broadcastLink]);
  }

  private async updateDrone(updateDroneInput: UpdateDroneInput): Promise<void> {
    const updatedDrone = await this.droneService.updateDrone(updateDroneInput);
    this.drone = updatedDrone;
    this.droneStoreService.updateDrone(this.organizationId, updatedDrone);
  }

  private async deleteDrone(): Promise<void> {
    const deletedDrone = await this.droneService.deleteDrone(this.drone);
    this.droneStoreService.deleteDrone(this.organizationId, deletedDrone);
  }

  private async unlinkDroneToIntervention(): Promise<void> {
    const droneInput = new UpdateDroneInput({ id: this.drone._id, currentIntervention: null });
    await this.updateDrone(droneInput);
    this.intervention = null;
  }

  private async initCurrentSharing(drone: Drone) {
    try {
      const sharings = await this.droneService.getSharings(drone);
      this.currentSharing = sharings[sharings.length - 1];
      if (this.currentSharing) {
        this.broadcastLink = await this.formatDroneStreamSharing(this.currentSharing);
        this.isBroadcastLinkGenerated = true;
      } else {
        this.isBroadcastLinkGenerated = false;
      }
    } catch (e) {
      console.error(e);
      this.resetData();
    }
  }

  private async formatDroneStreamSharing(sharing: DroneStreamSharing): Promise<string> {
    const urlTree = this.router.createUrlTree(['share', 'stream', sharing._id]);
    const path = this.location.prepareExternalUrl(urlTree.toString());
    return path;
  }

  private resetData() {
    this.broadcastLink = '';
    this.isBroadcastLinkGenerated = false;
  }
}
