<div class="single-stream">
  <div class="single-stream-container">
    <div class="edit-panel">
      <div class="labels">
        <div class="d-flex align-items-center">
          <app-mid-icon-button type="primary" class="mr-1" (action)="close()">
            <i class="fas fa-times"></i>
          </app-mid-icon-button>
          <div class="text-truncate" *ngIf="intervention">
            {{ intervention.name | defaultValue }} - № {{ intervention.interventionNumber | defaultValue }}
          </div>
        </div>
      </div>
    </div>
    <app-drone-card
      [isAdmin]="isAdmin"
      [organizationId]="organizationId"
      [drone]="drone"
      [singleStreamView]="true"
    ></app-drone-card>
  </div>
</div>
