<div class="media-source-button-toggles-container">
  <label>{{ label | translate }}</label>
  <mat-button-toggle-group
    appearance="none"
    multiple="false"
    [(ngModel)]="type"
    (ngModelChange)="onTypeChange($event)"
    #mediaSourceModel="ngModel"
    name="interventionType"
    matInput
  >
    <mat-button-toggle
      *ngFor="let button of mediaSourceButtonToggles"
      [value]="button.value"
      [ngStyle]="button.value === type && { 'background-color': button.color }"
      >{{ button.name }}
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
