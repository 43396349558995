import { StringUtils } from './utils/string-utils';

// tslint:disable:no-invalid-this
if (!String.prototype.format) {
  /**
   * Replaces the format item in a specified string with the string representation of
   * a corresponding object in a specified array.
   * @param args - An object array that contains zero or more objects to format.
   * @returns A copy of format in which the format items have been replaced by the string
   * representation of the corresponding objects in args.
   */
  // TODO: Check is args are comming in array like format.
  String.prototype.format = function (...args: Object[]): string {
    const arrayiedArgs: Object[] = Array.prototype.slice.call(args);

    let params: Object[] = [this];
    params = params.concat(arrayiedArgs);

    return StringUtils.format.apply(this, params);
  };
} else {
  // tslint:disable-next-line:no-console
  console.warn('"String.prototype.format" already defined.');
}

if (!String.prototype.formatWithMap) {
  /**
   * Replaces the format item in a specified string with the string representation of
   * a corresponding object in a specified literal object.
   * @param map - An object that contains zero or more objects to format.
   * @returns A copy of format in which the format items have been replaced by the string
   * representation of the corresponding objects in args.
   */
  String.prototype.formatWithMap = function (map: { [key: string]: any }): string {
    return StringUtils.formatWithMap(<string>this, map);
  };
} else {
  // tslint:disable-next-line:no-console
  console.warn('"String.prototype.formatWithMap" already defined.');
}
