<div class="container-fluid dashboard-page-container">
  <div class="row">
    <app-interventions-dashboard-block class="col-12 col-md-12 col-lg-4"></app-interventions-dashboard-block>
    <app-drones-dashboard-block class="col-12 col-md-12 col-lg-8"></app-drones-dashboard-block>
  </div>

  <div class="row mt-3">
    <div class="col-12 col-md-12 col-lg-4">
      <div class="add-intervention">
        <app-mid-button
          *ngIf="interventionsPermission?.create"
          (action)="onOpenCreateIntervention()"
          type="secondary"
          icon="fas fa-plus"
          class="ml-1"
        >
          {{ 'DIALOGS.CREATE_INTERVENTION.ACTION_LABEL' | translate }}
        </app-mid-button>
      </div>
      <div class="add-drone">
        <app-mid-button
          *ngIf="dronesPermission?.create"
          (action)="onOpenCreateDrone()"
          type="secondary"
          icon="fas fa-plus"
          class="mr-1"
        >
          {{ 'DRONES_ADD' | translate }}
        </app-mid-button>
      </div>
    </div>

    <mid-simple-dashboard-block
      title="{{ 'INTERVENTIONS_MAP' | translate }}"
      class="map-block col-12 col-md-12 col-lg-4"
    >
      <app-interventions-map></app-interventions-map>
    </mid-simple-dashboard-block>

    <mid-simple-dashboard-block title="{{ 'MEDIAS' | translate }}" class="media-block col-12 col-md-12 col-lg-4">
      <div class="bottom-btns w-100">
        <app-mid-button type="secondary" [routerLink]="['media']" icon="fas fa-photo-video" class="mr-1">
          {{ 'COMMON_SEE_ALL' | translate }} ({{ mediaCount }})
        </app-mid-button>
      </div>
      <img class="w-100" src="assets/images/media-demo-image_fr.png" alt="" role="button" [routerLink]="['media']" />
    </mid-simple-dashboard-block>
  </div>
</div>
