<app-mid-expansion-panel
  title="{{ 'INTERVENTION_STATS.COMMITTED_RESOURCES.TITLE' | translate }} ({{ total }})"
  type="primary"
  [isExpanded]="isExpanded"
>
  <table>
    <tr *ngFor="let item of items; let i = index">
      <td>
        <div class="item-name">
          <span class="name">{{ item.name }}</span>
          <button
            *ngIf="!isDefaultRessource(item)"
            type="button"
            class="btn btn-primary btn-delete"
            (click)="RemoveRessource(item.name)"
            [disabled]="!isEditable"
          >
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>
      </td>
      <td>
        <div class="item-counter">
          <button type="button" class="btn btn-primary" (click)="remove(item.name)" [disabled]="!isEditable">
            <i class="fa fa-minus fa-sm icon"></i>
          </button>
          <input
            [(ngModel)]="item.value"
            name="{{ name }}_{{ i }}"
            type="text"
            value="{{ item.value }}"
            #appMidStatisticsEngagedResources="ngModel"
            [disabled]="!isEditable"
          />
          <button type="button" class="btn btn-primary" (click)="add(item.name)" [disabled]="!isEditable">
            <i class="fa fa-plus fa-sm icon"></i>
          </button>
        </div>
      </td>
    </tr>
    <tr>
      <td>
        <div class="item-name">
          <span class="name">{{ 'INTERVENTION_STATS.TOTAL' | translate }}</span>
          <!-- <i class="fas fa-question-circle"></i> -->
        </div>
      </td>
      <td>
        <div class="item-total">
          <input type="text" value="{{ total }}" disabled />
        </div>
      </td>
    </tr>
  </table>
  <div class="add-resource" *ngIf="isEditable">
    <label>{{ 'INTERVENTION_STATS.COMMITTED_RESOURCES.COMMITTED_RESOURCE_LABEL' | translate }}</label>
    <div class="input-panel">
      <input
        type="text"
        class="form-control"
        [(ngModel)]="newResourceName"
        name="{{ name }}_newResource"
        placeholder="{{ 'INTERVENTION_STATS.COMMITTED_RESOURCES.RESOURCE_NAME_PLACEHOLDER' | translate }}"
        #newResource="ngModel"
      />
      <button (click)="AddResource(newResource.value)">{{ 'INTERVENTION_STATS.ADD' | translate }}</button>
    </div>
  </div>
</app-mid-expansion-panel>
