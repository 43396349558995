import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { OrganizationGroupDto } from '../../../api';
import { isEmpty, map } from 'lodash';

export interface GroupDropdownItem {
  value: OrganizationGroupDto;
  viewValue: string;
}

@Component({
  selector: 'app-group-select-dropdown',
  templateUrl: './group-select-dropdown.component.html',
  styleUrls: ['./group-select-dropdown.component.scss'],
})
export class GroupSelectDropdown implements OnChanges {
  @Input()
  previousGroups: OrganizationGroupDto[] = [];

  @Input()
  groups: OrganizationGroupDto[] = [];

  @Input()
  multiple: boolean = false;

  @Output()
  selectedGroups: EventEmitter<OrganizationGroupDto[]>;

  allGroups: GroupDropdownItem[] = [];
  currentGroups: OrganizationGroupDto[] = [];
  currentGroup: OrganizationGroupDto;

  constructor() {
    this.selectedGroups = new EventEmitter();
    this.currentGroup = {
      id: '',
      name: '',
      organization: '',
      role: 'None',
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!isEmpty(this.groups)) {
      this.allGroups = this.convertGroupsToGroupDropdownItem(this.groups);
      if (!isEmpty(this.allGroups)) {
        if (this.multiple) {
          this.currentGroups = map(this.convertGroupsToGroupDropdownItem(this.previousGroups), (group) => group.value);
          return;
        }
        this.currentGroup = this.convertGroupsToGroupDropdownItem(this.previousGroups)[0].value;
      }
    }
  }

  onGroupsChanged() {
    const emittingGroups = this.multiple ? this.currentGroups : [this.currentGroup];
    this.selectedGroups.emit(emittingGroups);
  }

  private convertGroupsToGroupDropdownItem(groups: OrganizationGroupDto[]): GroupDropdownItem[] {
    if (!groups) {
      return [];
    }

    const newGroups = map(groups, (group) => {
      const newGroup: GroupDropdownItem = {
        value: group,
        viewValue: group.name,
      };
      return newGroup;
    });
    return newGroups;
  }
}
