import { Injectable, Inject, Optional } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Params } from '@angular/router';
import { isEmpty } from 'lodash';
import { Assert, required, validate } from '..';

@Injectable({
  providedIn: 'root',
})
export class ExternalRouterService {
  private readonly location: Location;

  constructor(@Optional() @Inject('Location') location?: Location) {
    this.location = location || (window && window.location);

    Assert.isNotNull(this.location, 'location', 'Cannot use ExternalRouterService because "{0}" is not supported.');
  }

  @validate
  navigate(@required url: string, queryParams: Params = {}) {
    let href = url;

    if (!isEmpty(queryParams)) {
      const params = new HttpParams({ fromObject: queryParams });
      const paramsAsString = params.toString();
      const separator = href.indexOf('?') === -1 ? '?' : '&';

      href += `${separator}${paramsAsString}`;
    }

    this.location.href = href;
  }

  reload() {
    this.location.reload();
  }
}
