<div class="mid-header-menu">
  <div class="filter-section">
    <div class="search-filter">
      <div class="search-input">
        <input
          [(ngModel)]="mediaFilter.searchRequest"
          (ngModelChange)="onSearchRequest()"
          type="text"
          placeholder="{{ 'SEARCH_BY_NAME' | translate }}"
        />
        <i class="fas fa-search"></i>
      </div>
      <div class="search-filter-buttons" *ngIf="displayMode === DisplayMode.default">
        <button
          (click)="onMediaTypeChange(null)"
          class="search-section-btn filter-btn"
          [class.active]="mediaFilter.type === null"
        >
          <span>{{ 'COMMON_ALL' | translate }}</span>
        </button>
        <button
          (click)="onMediaTypeChange(MediaType.picture)"
          class="search-section-btn filter-btn"
          [class.active]="mediaFilter.type === MediaType.picture"
        >
          <span>{{ 'IMAGES' | translate }}</span>
        </button>
        <button
          (click)="onMediaTypeChange(MediaType.video)"
          class="search-section-btn filter-btn"
          [class.active]="mediaFilter.type === MediaType.video"
        >
          <span>{{ 'VIDEOS' | translate }}</span>
        </button>
      </div>
      <button
        *ngIf="displayMode === DisplayMode.default"
        class="search-section-btn search-filter-extra-btn"
        [class.active]="!!mediaFilter.more"
        (click)="onOpenMediaFilter()"
      >
        <span>{{ 'MORE_FILTERS' | translate }}</span>
      </button>
    </div>
    <div *ngIf="displayMode === DisplayMode.default">
      <p (click)="switchDisplayMode()">{{ 'MEDIA_PAGE.MULTIPLE_USE_CTRL' | translate }}</p>
    </div>
    <div *ngIf="displayMode === DisplayMode.multiselect" class="multiselect-wrapper">
      <p (click)="switchDisplayMode()">{{ 'MEDIA_PAGE.MULTIPLE_ENABLED' | translate }}</p>
      <a (click)="onSelectAllMedias()" class="selection-link">{{ 'MEDIA_PAGE.SELECT_ALL' | translate }}</a>
      <a (click)="onSwitchMultiselectMode()" class="selection-link">{{ 'MEDIA_PAGE.DESELECT_ALL' | translate }}</a>
      <div class="icon-wrapper" (click)="onDeleteSelectedMedias()">
        <i class="fa-solid fa-trash delete-selection-icon"></i>
      </div>
      <button class="media-card-fixed-btn" [matMenuTriggerFor]="menu">
        <i class="fas fa-ellipsis-v"></i>
      </button>
      <mat-menu #menu="matMenu" class="mid-menu">
        <div class="mid-menu-item" (click)="onDownloadSelectedMedias()">
          <i class="fas fa-download mr-1 mid-menu-item-icon"></i>
          <span>{{ 'MEDIA_PAGE.DOWNLOAD_SELECTED_MEDIA' | translate }}</span>
        </div>
      </mat-menu>
    </div>
    <app-media-upload *ngIf="displayMode === DisplayMode.default" [intervention]="intervention"></app-media-upload>
  </div>
</div>
