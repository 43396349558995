<app-mid-right-panel [title]="'DIALOGS.SHARE_TDRAW_LINK.SHARE_LINK' | translate">
  <app-mid-right-panel-content>
    <div class="row m-0 px-4 pb-4">
      <ng-container *ngIf="existingShares.length > 0 && !sharingMode">
        <div class="section col-md-12">
          <div class="info">
            <i class="fas fa-info"></i>
            <div class="text">
              {{ 'DIALOGS.SHARE_TDRAW_LINK.INFORMATION' | translate }}
            </div>
          </div>
        </div>
        <div class="section">
          <div class="section-title">{{ 'DIALOGS.SHARE_TDRAW_LINK.EXISTING' | translate }}</div>
        </div>
        <ul class="list-group w-100">
          <li class="list-group-item mid-list-group-item" *ngFor="let share of existingShares">
            <div class="media-share-view">
              <div class="delete-icon" (click)="deleteShare(share)">
                <i class="fas fa-times"></i>
              </div>

              <span>{{
                'DIALOGS.SHARE_TDRAW_LINK.SHARE_CREATION' | translate : { name: usernames[share.sharedBy] }
              }}</span>
              <span>
                {{
                  'DIALOGS.SHARE_TDRAW_LINK.SHARE_LINK_VALID'
                    | translate : { date: share?.validUntil | date : 'dd/MM/yyyy HH:mm' }
                }}
              </span>

              <app-mid-button class="w-100" type="secondary" size="wide" (action)="copyShareLink(share)">
                {{ 'COPY' | translate }}
              </app-mid-button>
            </div>
            <div class="section function-buttons">
              <div class="media-share-view">
                <app-mid-button
                  class="w-100"
                  type="secondary"
                  size="wide"
                  (action)="shareUsingMode(share, TdrawSharingMode.EMAIL)"
                >
                  {{ 'DIALOGS.SHARE_TDRAW_LINK.SHARE_BY_MAIL' | translate }}
                </app-mid-button>
                <app-mid-button
                  class="w-100"
                  type="secondary"
                  size="wide"
                  (action)="shareUsingMode(share, TdrawSharingMode.SMS)"
                >
                  {{ 'DIALOGS.SHARE_TDRAW_LINK.SHARE_BY_SMS' | translate }}
                </app-mid-button>
                <app-mid-button
                  class="w-100"
                  type="secondary"
                  size="wide"
                  (action)="shareUsingMode(share, TdrawSharingMode.LIST_SMS)"
                >
                  {{ 'DIALOGS.SHARE_TDRAW_LINK.SHARE_BY_LIST_SMS' | translate }}
                </app-mid-button>
                <app-mid-button
                  class="w-100"
                  type="secondary"
                  size="wide"
                  (action)="shareUsingMode(share, TdrawSharingMode.QRCODE)"
                >
                  QR code
                </app-mid-button>
              </div>
            </div>
          </li>
        </ul>
      </ng-container>
      <ng-container *ngIf="!sharingMode">
        <div class="section col-md-12">
          <div class="instruction mb-3">
            {{ 'DIALOGS.SHARE_TDRAW_LINK.INSTRUCTIONS' | translate }}
          </div>

          <div class="warning">
            <i class="fas fa-exclamation-triangle"></i>
            <div class="warning-text">
              {{ 'DIALOGS.SHARE_TDRAW_LINK.WARNING' | translate }}
            </div>
          </div>
        </div>

        <div class="duration-select w-100">
          <app-mid-select
            [label]="'DIALOGS.SHARE_TDRAW_LINK.EXPIRED_IN' | translate"
            size="wide"
            [items]="optionsSelectItems"
            [selectedItem]="duration"
            (selectedItemChange)="duration = $event"
            [placeholder]="'DIALOGS.SHARE_TDRAW_LINK.SELECT_LINK_DURATION' | translate"
          >
          </app-mid-select>
          <app-mid-button
            class="w-100"
            type="primary"
            size="wide"
            (action)="onGenerateNewShareLink()"
            [disabled]="!duration"
          >
            {{ 'DIALOGS.SHARE_TDRAW_LINK.ACTIVATE_SHARING' | translate }}
          </app-mid-button>
        </div>
      </ng-container>
      <ng-container *ngIf="sharingMode === TdrawSharingMode.EMAIL">
        <div class="section col-md-12">
          <form #formShareByMail="ngForm">
            <div class="wrapper">
              <app-mid-input
                type="email"
                size="wide"
                #mail="appMidInput"
                name="mail"
                [(value)]="dataToShare.mail"
                [required]="true"
                [label]="'DIALOGS.SHARE_TDRAW_LINK.EMAIL' | translate"
              >
              </app-mid-input>
              <span class="text-danger" *ngIf="!formShareByMail.pristine && formShareByMail.invalid">
                {{ 'DIALOGS.SHARE_TDRAW_LINK.EMAIL_VALIDATOR_LABEL' | translate }}</span
              >
              <section class="section function-buttons">
                <div class="media-share-view">
                  <app-mid-button
                    class="w-100"
                    type="primary"
                    size="wide"
                    (action)="sendMail()"
                    [disabled]="formShareByMail.form?.invalid"
                  >
                    {{ 'DIALOGS.SHARE_TDRAW_LINK.SHARE_TDRAW_LINK' | translate }}
                  </app-mid-button>
                  <app-mid-button class="w-100" type="secondary" size="wide" (action)="cancel()">
                    {{ 'DIALOGS.SHARE_TDRAW_LINK.SHARE_BY_BACK' | translate }}
                  </app-mid-button>
                </div>
              </section>
            </div>
          </form>
        </div>
      </ng-container>
      <ng-container *ngIf="sharingMode === TdrawSharingMode.SMS">
        <div class="section col-md-12">
          <form #formShareBySMS="ngForm">
            <div class="wrapper">
              <app-mid-input-phone-number
                size="wide"
                [label]="'DIALOGS.SHARE_TDRAW_LINK.PHONE_NUMBER' | translate"
                [required]="true"
                #phone="appMidPhoneNumberInput"
                name="phoneNumber"
                [(value)]="dataToShare.phoneNumber"
              >
                <span class="text-danger" *ngIf="!formShareBySMS.pristine && phone.invalid">
                  {{ 'DIALOGS.SHARE_TDRAW_LINK.PHONE_VALIDATOR_LABEL' | translate }}</span
                >
              </app-mid-input-phone-number>
              <section class="section function-buttons">
                <div class="media-share-view">
                  <app-mid-button
                    class="w-100 px-1"
                    type="primary"
                    size="wide"
                    (action)="sendSMS()"
                    [disabled]="formShareBySMS.form?.invalid"
                  >
                    {{ 'DIALOGS.SHARE_TDRAW_LINK.SHARE_TDRAW_LINK' | translate }}
                  </app-mid-button>
                  <app-mid-button class="w-100 px-1" type="secondary" size="wide" (action)="cancel()">
                    {{ 'DIALOGS.SHARE_TDRAW_LINK.SHARE_BY_BACK' | translate }}
                  </app-mid-button>
                </div>
              </section>
            </div>
          </form>
        </div>
      </ng-container>
      <ng-container *ngIf="sharingMode === TdrawSharingMode.LIST_SMS">
        <div class="section col-md-12">
          <form #formShareByListSMS="ngForm">
            <div class="wrapper">
              <app-mid-input
                size="wide"
                #listInput="appMidInput"
                name="listInput"
                [matAutocomplete]="auto"
                [label]="'DISTRIBUTION_LIST' | translate"
                [placeholder]="'NAME' | translate"
              >
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectDistributionList($event)">
                  <mat-option *ngFor="let list of distributionLists" [value]="list">
                    {{ list.name }}
                  </mat-option>
                </mat-autocomplete>
              </app-mid-input>

              <section class="section function-buttons">
                <div class="media-share-view">
                  <app-mid-button
                    class="w-100 px-1"
                    type="primary"
                    size="wide"
                    (action)="sendSMSList()"
                    [disabled]="!selectedDistributionList"
                  >
                    {{ 'DIALOGS.SHARE_TDRAW_LINK.SHARE_TDRAW_LINK' | translate }}
                  </app-mid-button>
                  <app-mid-button class="w-100 px-1" type="secondary" size="wide" (action)="cancel()">
                    {{ 'DIALOGS.SHARE_TDRAW_LINK.SHARE_BY_BACK' | translate }}
                  </app-mid-button>
                </div>
              </section>
            </div>
          </form>
        </div>
      </ng-container>
      <ng-container *ngIf="sharingMode === TdrawSharingMode.QRCODE">
        <div class="section col-md-12">
          <form #formShareBySMS="ngForm">
            <div class="wrapper">
              <div class="container text-center">
                <qrcode [qrdata]="tdrawShareLink" [width]="240" [errorCorrectionLevel]="'M'"></qrcode>
              </div>
              <section class="section function-buttons">
                <app-mid-button class="w-100 px-1" type="secondary" size="wide" (action)="cancel()">
                  {{ 'DIALOGS.SHARE_TDRAW_LINK.SHARE_BY_BACK' | translate }}
                </app-mid-button>
              </section>
            </div>
          </form>
        </div>
      </ng-container>
    </div>
  </app-mid-right-panel-content>
</app-mid-right-panel>
