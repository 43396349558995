<div class="lists-container">
  <table class="table">
    <thead>
      <tr>
        <th scope="col">{{ 'NAME' | translate }}</th>
        <th scope="col">{{ 'RECIPIENTS' | translate }}</th>
        <th scope="col">{{ 'ACTION_TABLE' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let list of lists" class="col-12 mid-organization-item">
        <td>
          {{ list.name }}
        </td>

        <td>
          {{ list.phones.length }}
        </td>

        <td>
          <div>
            <app-mid-button type="secondary" [matMenuTriggerFor]="menu">
              <i class="fas fa-ellipsis-v"></i>
            </app-mid-button>
            <mat-menu #menu="matMenu" class="mid-menu">
              <div class="mid-menu-item" (click)="onEditList(list)">
                <i class="fas fa-pen mr-1 mid-menu-item-icon"></i>
                <span> {{ 'DISTRIBUTION_LIST_ACTIONS.EDIT' | translate }} </span>
              </div>

              <div class="mid-menu-item" (click)="deleteList(list)">
                <i class="fas fa-link mr-1 mid-menu-item-icon"></i>
                <span> {{ 'DISTRIBUTION_LIST_ACTIONS.DELETE' | translate }} </span>
              </div>
            </mat-menu>
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <app-mid-button type="secondary" (action)="onAddList()">
    {{ 'DISTRIBUTION_LIST_ACTIONS.ADD' | translate }}
  </app-mid-button>
</div>
