import { Action } from '@ngrx/store';
import { User } from 'src/app/autogenerated/model';
import { Assert } from 'src/app/shared';
import { ResetStateAction } from '../../core/store';

export enum UserProfilesActionType {
  SetCurrentUser = '[UserProfiles] UpdateCurrentUser',
}

export class SetCurrentUserAction implements Action {
  readonly type = UserProfilesActionType.SetCurrentUser;

  readonly item: User;

  constructor(item: User) {
    Assert.isNotNull(item, 'item');
    this.item = item;
  }
}

export type UserProfilesActions = SetCurrentUserAction | ResetStateAction;
