<div class="input-container" [class.input-label]="!!label">
  <label *ngIf="label" [class.required]="required">{{ label }}</label>
  <input
    type="datetime-local"
    [(ngModel)]="strDateTimeValue"
    (ngModelChange)="onValueChange()"
    #midInput="ngModel"
    [name]="name"
    [disabled]="disabled"
    [required]="required"
    [class.default]="size === MidInputSize.default"
    [class.wide]="size === MidInputSize.wide"
  />
  <ng-content></ng-content>
</div>
