import { ApiService } from './../core/api/api.service';
import { Router } from '@angular/router';
import { environment } from './../../environments/environment';
import { MediaType, OrganizationMedia } from './model';
import { MediaServiceAutoGenerated } from './services/mediaService.service';
import { Injectable } from '@angular/core';
import { BackendClientService } from '../global/backend-client.service';
import { LoggerService } from '../core/logger.service';

@Injectable({
  providedIn: 'root',
})
export class MediaService extends MediaServiceAutoGenerated {
  constructor(
    client: BackendClientService,
    private router: Router,
    private loggerService: LoggerService,
    apiService: ApiService
  ) {
    super(client, apiService);
  }

  /** this method is an help have here the url computation for media, ie we might want to drive that or change it */
  public getDownloadUrl(media: OrganizationMedia, preview: boolean = false) {
    if (!media) {
      return '';
    }
    const url = environment.backend + 'file/' + media.organization + '/' + media._id + media.ext;
    if (media.mediaType === MediaType.picture) {
      return url + '?' + (preview ? 'preview' : 'view') + '=true';
    }
    return url;
  }

  /** activate the route for the media */
  public navigateToMedia(
    media: OrganizationMedia,
    queryFilter?: {
      interventionId?: string;
      type?: MediaType;
    }
  ) {
    if (!media) {
      return;
    }

    // let base = '';
    // switch (media.__t) {
    //   case 'DroneLiveStreamMedia': {
    //     base = '/video/';
    //     break;
    //   }
    //   case 'DroneLiveSnapshotMedia': {
    //     base = '/snapshot/';
    //     break;
    //   }
    // }

    // if (!base) {
    //   // we failed by type, let's try by mediaType
    //   switch (media.mediaType) {
    //     case MediaType.video: {
    //       base = '/video/';
    //       break;
    //     }
    //     case MediaType.picture: {
    //       base = '/picture/';
    //       break;
    //     }
    //   }
    // }

    let base = '/media/';

    if (!base) {
      this.loggerService.error(media.__t + ' ' + media.mediaType, ' no case defined');
    }
    if (base) {
      this.router.navigate([base, media._id], { queryParams: queryFilter ?? undefined });
    }
  }
}
