export class Breadcrumb {
  label: string;
  url: string;
  icon: string;
  additionalIcon: string;
  formatterLabel: string;

  constructor({ label, url, icon, additionalIcon, formatterLabel }: Partial<Breadcrumb> = {}) {
    this.label = label;
    this.url = url;
    this.icon = icon;
    this.additionalIcon = additionalIcon;
    this.formatterLabel = formatterLabel;
  }
}
