import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OrganizationGroupDto } from '../../../api';

@Component({
  selector: 'app-organization-users-filter',
  templateUrl: './organization-users-filter.component.html',
  styleUrls: ['./organization-users-filter.component.scss'],
})
export class OrganizationUsersFilterComponent {
  @Input()
  group: OrganizationGroupDto;

  @Output()
  filterResetted: EventEmitter<void>;

  constructor() {
    this.group = null;
    this.filterResetted = new EventEmitter();
  }

  resetFilter() {
    this.filterResetted.emit();
  }
}
