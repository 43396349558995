<div class="block-container">
  <div class="header">
    <span class="title">{{ 'INTERVENTIONS' | translate }}</span>
    <!-- <i (click)="onAddIntervention()" class="add-intervention-icon fas fa-plus-circle"></i> -->
  </div>
  <div>
    <div class="intervention-tabs">
      <div
        class="option"
        (click)="onChangeInterventionType('all')"
        [class.active]="state.interventionFilterType === 'all'"
      >
        {{ 'COMMON_ALL' | translate }}
      </div>
      <div
        class="option"
        (click)="onChangeInterventionType(InterventionType.OPS)"
        [class.active]="state.interventionFilterType === InterventionType.OPS"
      >
        {{ 'INTERVENTION_TYPE.OPS' | translate }}
      </div>
      <div
        class="option"
        (click)="onChangeInterventionType(InterventionType.PRV)"
        [class.active]="state.interventionFilterType === InterventionType.PRV"
      >
        {{ 'INTERVENTION_TYPE.PRV' | translate }}
      </div>
      <div
        class="option"
        (click)="onChangeInterventionType(InterventionType.COM)"
        [class.active]="state.interventionFilterType === InterventionType.COM"
      >
        {{ 'INTERVENTION_TYPE.COM' | translate }}
      </div>
      <div
        class="option"
        (click)="onChangeInterventionType(InterventionType.TRAINING)"
        [class.active]="state.interventionFilterType === InterventionType.TRAINING"
      >
        {{ 'INTERVENTION_TYPE.TRAINING' | translate }}
      </div>
    </div>

    <mid-block-carousel
      (leftArrowClick)="onPreviousIntervention()"
      (rightArrowClick)="onNextIntervention()"
      [isLeftArrowEnabled]="isPreviousPageExists"
      [isRightArrowEnabled]="isNextPageExists"
    >
      <app-intervention-card
        [intervention]="dataSource.paginatedItems && dataSource.paginatedItems[0]"
        (associateWithDroneEvent)="onOpenAssociateWithDrone()"
      ></app-intervention-card>
    </mid-block-carousel>

    <div class="d-flex justify-content-center pt-3 pb-3 mt-2">
      <app-mid-button (action)="goToAllInterventions()" type="secondary" icon="fas fa-truck"
        >{{ 'COMMON_SEE_ALL' | translate }} ({{ dataSource.allItems.length }})</app-mid-button
      >
      <app-mid-button
        *ngIf="interventionPermission?.create"
        (action)="onOpenCreateIntervention()"
        type="secondary"
        icon="fas fa-plus"
        class="ml-1"
      >
        {{ 'DIALOGS.CREATE_INTERVENTION.ACTION_LABEL' | translate }}
      </app-mid-button>
    </div>
  </div>
</div>
