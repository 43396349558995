<tdraw-right-panel-container>
  <div role="content">
    <div class="content">
      <a
        class="back-link mb-1 font-weight-bold"
        href="javascript:void(0)"
        (click)="backToToolbox()"
        *ngIf="hasBackButton"
        ><i class="fas fa-chevron-left"></i> {{ 'BACK_TOOLBOX' | translate }}</a
      >
      <h4 class="mb-4 font-weight-bold">{{ 'OBJECT_INFORMATION' | translate }}</h4>

      <div class="text-with-border mt-4">{{ 'GENERAL_INFORMATION' | translate }}</div>

      <div class="fields mt-2 w-100">
        <div *ngIf="selectedFeature.geometry?.type === 'Point'">
          <span>{{ 'TRIGRAM' | translate }}</span>
          <div>
            {{ selectedFeature.properties[FeatureProperty.Trigram] || 'NO_VALUE' | translate }}
          </div>
        </div>

        <div *ngIf="mapIcon?.specialType === SpecialType.Sector">
          <span>{{ 'TDRAW.SECTOR_TITLE' | translate }}</span>
          <div>
            {{ selectedFeature.properties[FeatureProperty.SectorTitle] || 'NO_VALUE' | translate }}
          </div>

          <span>{{ 'TDRAW.SECTOR_CHIEF' | translate }}</span>
          <div>
            {{ selectedFeature.properties[FeatureProperty.SectorChief] || 'NO_VALUE' | translate }}
          </div>

          <span>{{ 'TDRAW.HIGH_FREQ' | translate }}</span>
          <div>
            {{ selectedFeature.properties[FeatureProperty.HighFreq] || 'NO_VALUE' | translate }}
          </div>

          <span>{{ 'TDRAW.LOW_FREQ' | translate }}</span>
          <div>
            {{ selectedFeature.properties[FeatureProperty.LowFreq] || 'NO_VALUE' | translate }}
          </div>
        </div>

        <div *ngIf="mapIcon?.specialType !== SpecialType.Sector">
          <span>{{ 'NAME' | translate }}</span>
          <div>
            {{ selectedFeature.properties[FeatureProperty.Title] || 'NO_VALUE' | translate }}
          </div>

          <span>{{ 'DESCRIPTION' | translate }}</span>
          <div class="w-100">
            {{ selectedFeature.properties[FeatureProperty.Description] || 'NO_VALUE' | translate }}
          </div>
        </div>

        <div *ngIf="mapIcon?.category === AnnotationCategory.Resources">
          <span>{{ 'TDRAW.SECTOR_BELONG' | translate }}</span>
          <div>
            {{ sectorsById[selectedFeature.properties[FeatureProperty.SectorBelong]]?.title || 'NO_VALUE' | translate }}
          </div>
        </div>
      </div>

      <div class="text-with-border mt-4" *ngIf="calculation">{{ 'ANALYZE' | translate }}</div>

      <div class="prop-labels mt-4 mb-4" *ngIf="calculation">
        <div *ngIf="calculation.area">
          <span class="font-weight-bold">{{ 'SURFACE' | translate }} : </span
          ><span>{{ calculation.area }} m² ({{ (calculation.area / 10000).toFixed(3) }} ha)</span> <br />
        </div>

        <div *ngIf="calculation.perimeter">
          <span class="font-weight-bold">{{ 'PERIMETER' | translate }} : </span
          ><span>{{ calculation.perimeter }} km</span> <br />
        </div>

        <div *ngIf="calculation.radiusInKm">
          <span class="font-weight-bold">{{ 'RADIUS' | translate }} : </span
          ><span>{{ calculation.radiusInKm }} km</span> <br />
        </div>

        <div *ngIf="calculation.length">
          <span class="font-weight-bold">{{ 'LENGTH' | translate }} : </span><span>{{ calculation.length }} km</span>
          <br />
        </div>

        <div *ngIf="calculation.latitude">
          <span class="font-weight-bold">{{ 'LATITUDE' | translate }} : </span><span>{{ calculation.latitude }}</span>
          <br />
        </div>

        <div *ngIf="calculation.longitude">
          <span class="font-weight-bold">{{ 'LONGITUDE' | translate }} : </span
          ><span>{{ calculation.longitude }}</span> <br />
        </div>

        <div *ngIf="calculation.wind">
          <span class="font-weight-bold">{{ 'WIND_SPEED' | translate }} : </span
          ><span>{{ calculation.wind }} km/h</span> <br />
        </div>
      </div>
    </div>
  </div>
</tdraw-right-panel-container>
