import { UserServiceAutoGenerated } from './services/userService.service';
import { Injectable } from '@angular/core';
import { BackendClientService } from '../global/backend-client.service';
import { ApiService } from '../core/api';

@Injectable({
  providedIn: 'root',
})
export class UserService extends UserServiceAutoGenerated {
  constructor(client: BackendClientService, apiService: ApiService) {
    super(client, apiService);
  }
}
