import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DroneService } from 'src/app/autogenerated/droneService';
import { DroneService as DroneServiceV2, OrganizationGroupService } from 'src/api';
import { Drone, Intervention, UpdateDroneInput } from 'src/app/autogenerated/model';
import { OrganizationService } from 'src/app/autogenerated/organizationService';
import { DataSource, DataSourceService, DataSourceState, FieldSortOrder, SortOrder } from 'src/app/core/collections';
import { OrganizationStoreService } from 'src/app/organization/store';
import { required, validate } from 'src/app/shared';
import { DroneStoreService } from '../../store';
import { SelectDroneToAssignComponent } from '../select-drone-to-assign/select-drone-to-assign.component';
import _, { cloneDeep } from 'lodash';
import { Unsubscribe } from 'src/app/core/decorators';
import { MidRightPanelService } from 'src/app/midgard-controls/services/mid-right-panel.service';
import { CreateDroneComponent } from '../../dialogs/create-drone-right-panel/create-drone/create-drone.component';

enum DronesDashboardBehavior {
  default,
  addDrone,
  assignDrone,
}

@Unsubscribe()
@Component({
  selector: 'app-drones-dashboard-block',
  templateUrl: './drones-dashboard-block.component.html',
  styleUrls: ['./drones-dashboard-block.component.scss'],
})
export class DronesDashboardBlockComponent implements OnInit, OnDestroy {
  @Input()
  intervention: Intervention;

  @Input()
  isInterventionRequired = false;

  @Input()
  isLinkBtnActive: boolean;

  organizationId: string;

  dataSource: DataSource<Drone>;
  dataSourceState: DataSourceState<Drone>;
  allDrones: Drone[] = [];
  showNewDroneDialog = false;
  isAdmin: boolean;

  dashboardBehavior$ = new BehaviorSubject(DronesDashboardBehavior.default);

  get DashboardBehavior() {
    return DronesDashboardBehavior;
  }

  private organizationSubscription: Subscription;
  private selectDroneModalSubscription: Subscription;
  private dronesSubscription: Subscription;
  private createDroneSubscription: Subscription;

  private readonly startPageNumber = 1;
  private readonly minSingleViewportWidth = 1320; // 992;

  state: {
    isLoading: boolean;
    isFailed: boolean;
    currentInterventionIndex: number;
    isLeftArrowEnabled: boolean;
    isRightArrowEnabled: boolean;
  };

  get isPreviousPageExists(): boolean {
    return this.dataSourceState.page > this.startPageNumber;
  }

  get isNextPageExists(): boolean {
    return this.dataSourceState.page * this.dataSourceState.pageSize < (this.dataSource.filteredItems?.length || 0);
  }

  constructor(
    private readonly router: Router,
    private readonly organizationStoreService: OrganizationStoreService,
    private readonly organizationService: OrganizationService,
    private readonly droneService: DroneService,
    private readonly droneServiceV2: DroneServiceV2,
    private readonly droneStoreService: DroneStoreService,
    private readonly dataSourceService: DataSourceService,
    private readonly dialog: MatDialog,
    private readonly organizationGroupService: OrganizationGroupService,
    private readonly midRightPanelService: MidRightPanelService
  ) {
    this.dataSource = new DataSource<Drone>();

    const pageSize = this.calculatePageSize(document.documentElement.clientWidth);

    this.dataSourceState = new DataSourceState<Drone>({
      filter: '',
      searchableFields: ['name'],
      sortOrder: new FieldSortOrder(['streamingStatus', 'lastConnection'], [SortOrder.asc, SortOrder.asc]),
      page: this.startPageNumber,
      pageSize: pageSize,
    });

    this.state = {
      isLoading: false,
      isFailed: false,
      currentInterventionIndex: 0,
      isLeftArrowEnabled: false,
      isRightArrowEnabled: false,
    };

    this.isAdmin = false;

    this.dashboardBehavior$.subscribe();
  }

  async ngOnInit() {
    this.organizationSubscription = this.organizationStoreService
      .getCurrentOrganization()
      .subscribe(async (organization) => {
        if (!organization || this.organizationId === organization._id) {
          return;
        }

        this.organizationId = organization._id;

        this.isAdmin = await this.checkForAdminRole(organization._id);
        await this.tryToLoadDrones(organization._id);
        this.updateDroneSubscription(organization._id);
      });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.dataSourceState.pageSize = this.calculatePageSize(document.documentElement.clientWidth);
    this.changeDataSourcePage(this.dataSourceState.page);
  }

  onPreviousDrone(): void {
    this.changeDataSourcePage(this.dataSourceState.page - 1);
  }

  onNextDrone(): void {
    this.changeDataSourcePage(this.dataSourceState.page + 1);
  }

  onOpenCreateDrone(): void {
    this.createDroneSubscription = this.midRightPanelService.open(CreateDroneComponent).subscribe(() => {
      this.onDronesUpdate();
    });
  }

  async goToAllDrones(): Promise<void> {
    if (this.intervention?._id) {
      await this.router.navigate(['interventions', this.intervention?._id, 'streaming']);
    } else {
      await this.router.navigate([`drones`]);
    }
  }

  async onDronesUpdate(): Promise<void> {
    await this.tryToLoadDrones(this.organizationId);
  }

  onOpenAssignDroneToInterventionDialog(): void {
    const dialogRef = this.dialog.open<SelectDroneToAssignComponent, any, Drone>(SelectDroneToAssignComponent, {
      id: 'assignDroneToIntervention',
      width: '450px',
      data: { interventionId: this.intervention, drones: this.allDrones },
      closeOnNavigation: true,
    });

    this.selectDroneModalSubscription = dialogRef.afterClosed().subscribe(async (droneId: string) => {
      if (droneId) {
        const droneInput = new UpdateDroneInput({ id: droneId, currentIntervention: this.intervention._id });
        const updatedDrone = await this.droneService.updateDrone(droneInput);
        this.droneStoreService.updateDrone(this.organizationId, updatedDrone);
      }
    });
  }

  ngOnDestroy(): void {}

  private updateDroneSubscription(organizationId: string): void {
    if (!organizationId) {
      return;
    }

    if (this.dronesSubscription) {
      this.dronesSubscription.unsubscribe();
      this.dronesSubscription = null;
    }

    this.dronesSubscription = this.droneStoreService.getOrganizationDrones(organizationId).subscribe((drones) => {
      this.allDrones = cloneDeep(drones);
      const updatedDrones = this.isInterventionRequired ? this.filterDrones(drones, this.intervention?._id) : drones;

      if (updatedDrones) {
        this.dataSource = this.dataSourceService.createDataSource(updatedDrones, this.dataSourceState);
      }

      this.updateDashboardBehavior();
    });
  }

  @validate
  private async tryToLoadDrones(@required organizationId: string): Promise<void> {
    if (this.isInterventionRequired && !this.intervention) {
      return;
    }
    await this.loadDrones(organizationId);
  }

  @validate
  private async loadDrones(@required organizationId: string): Promise<void> {
    this.state.isLoading = true;
    this.state.isFailed = false;

    try {
      const drones = await this.droneServiceV2.orgDroneControllerGetOrgDrones(organizationId).toPromise();
      this.droneStoreService.setOrganizationDrones(organizationId, _.cloneDeep(drones));
    } catch (error) {
      this.state.isFailed = true;
    } finally {
      this.state.isLoading = false;
    }
  }

  private filterDrones(drones: Drone[], interventionId: string): Drone[] {
    const filteredDrones = _.filter(drones, (item) => item.currentIntervention === interventionId);
    return filteredDrones;
  }

  private updateDashboardBehavior(): DronesDashboardBehavior {
    if (!this.allDrones?.length) {
      this.dashboardBehavior$.next(DronesDashboardBehavior.addDrone);
      return;
    }
    if (this.intervention?._id && !this.dataSource?.allItems?.length) {
      this.dashboardBehavior$.next(DronesDashboardBehavior.assignDrone);
      return;
    }
    this.dashboardBehavior$.next(DronesDashboardBehavior.default);
  }

  @validate
  private changeDataSourcePage(@required page: number): void {
    let currentPageNumber = Math.min(this.dataSource.filteredItems?.length || this.startPageNumber, page); // max limit
    currentPageNumber = Math.max(this.startPageNumber, currentPageNumber); // min limit

    this.dataSourceState.page = currentPageNumber;
    this.dataSource = this.dataSourceService.filterDataSource(this.dataSource, this.dataSourceState);
  }

  private calculatePageSize(viewportWidth: number): number {
    const pageSize = viewportWidth < this.minSingleViewportWidth ? 1 : 2;
    return pageSize;
  }

  private async checkForAdminRole(organizationId: string): Promise<boolean> {
    try {
      const isAdmin = await this.organizationGroupService
        .orgOrganizationGroupControllerIsOrgAdmin(organizationId)
        .toPromise();
      return isAdmin;
    } catch (e) {
      console.error(e);
      return false;
    }
  }
}
