import { MapState } from './map-state.model';

export class MapStateGroup {
  date: Date;
  mapStates: MapState[];

  constructor({ date, mapStates }: Partial<MapStateGroup> = {}) {
    this.date = date;
    this.mapStates = mapStates;
  }
}
