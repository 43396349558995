import { Component, Inject, OnInit } from '@angular/core';
import { MID_RIGHT_PANEL_DATA } from 'src/app/midgard-controls/services/dom.service';
import { MidRightPanelService } from 'src/app/midgard-controls/services/mid-right-panel.service';

@Component({
  selector: 'app-access-code-qr',
  templateUrl: './access-code-qr.component.html',
  styleUrls: ['./access-code-qr.component.scss'],
})
export class AccessCodeQrComponent implements OnInit {
  constructor(@Inject(MID_RIGHT_PANEL_DATA) public readonly data: { link: string }) {}

  ngOnInit(): void {}
}
