import { featuresConfig } from './features.config';
import { StorageType } from '../storage';
import { Assert, StringUtils } from '../../shared';
import { FeaturesManager } from './features-manager';
import { Feature } from './feature';
import { isEmpty, each, find } from 'lodash';

const logFeaturesOverrides = (featuresDefaults: Feature[], featuresOverrides: StringMap<boolean>) => {
  console.group('Features overrides:');

  each(featuresOverrides, (isEnabled: boolean, featureId: string) => {
    const feature = find(featuresDefaults, (item: Feature) => item.id === featureId);
    console.log(
      `${feature ? feature.name : featureId}: %c${isEnabled ? 'enabled' : 'disabled'}`,
      `color:${isEnabled ? 'green' : 'red'}`
    );
  });

  console.groupEnd();
};

export const loadFeatures = (featuresDefaults: Feature[]) => {
  Assert.isNotNull(featuresDefaults, 'featuresDefaults');

  let storage: Storage;
  switch (featuresConfig.storageType) {
    case StorageType.Local:
      storage = localStorage;
      break;
    case StorageType.Session:
      storage = sessionStorage;
      break;
    default:
      throw new Error(`"${featuresConfig.storageType}" is not supported storage type for features overrides.`);
  }

  Assert.isNotNull(storage, 'storage');

  const featuresOverridesAsString = storage.getItem(featuresConfig.storageKey);
  let featuresOverrides: StringMap<boolean>;
  try {
    featuresOverrides = JSON.parse(featuresOverridesAsString);
  } catch {
    if (!StringUtils.isNullOrEmpty(featuresOverridesAsString)) {
      console.warn(`Failed to parse features overrides: ${featuresOverridesAsString}`);
    }

    featuresOverrides = {};
  }

  FeaturesManager.intitialize(featuresDefaults, featuresOverrides);

  const hasOverrides = !isEmpty(featuresOverrides);
  if (hasOverrides) {
    logFeaturesOverrides(featuresDefaults, featuresOverrides);
  }
};
