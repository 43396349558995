import { Intervention } from './../autogenerated/model';
import { SortOrder } from '../core/collections';

export const interventionConfig = {
  store: {
    key: 'interventions',
  },
  routes: {
    interventions: 'interventions',
    params: {
      interventionId: 'interventionId',
    },
    streaming: 'streaming',
    tdraw: 'tdraw',
    media: 'media',
    settings: 'settings',
    analyses: 'analyses',
  },
  format: {
    date: 'dd/MM/yy',
    time: 'HH:mm',
  },
  filter: {
    searchableFields: ['name'] as FieldsOf<Intervention>,
  },
  list: {
    minRefreshTimeout: 1000,
    interventions: {
      searchableFields: ['name', 'interventionNumber', 'type', 'description', 'updatedDate'] as FieldsOf<Intervention>,
      sortOrder: {
        fieldName: 'name' as FieldOf<Intervention>,
        sortOrder: SortOrder.desc,
      },
      listOrder: {
        fieldName: ['isClosed', 'isArchived', 'updatedDate'] as FieldsOf<Intervention>,
        sortOrder: [SortOrder.asc, SortOrder.asc, SortOrder.desc],
      },
    },
  },
  create: {
    defaults: {
      isShared: false,
    },
    validation: {
      name: {
        maxLength: 64,
        allowedCharacters: ['-', '_', '.'],
      },
    },
  },
  alertsRefreshTimeout: 5000,
};
