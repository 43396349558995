<div class="group-container">
  <table class="table">
    <thead>
      <tr>
        <th scope="col">{{ 'NAME' | translate }}</th>
        <th scope="col">{{ 'ROLE_TABLE' | translate }}</th>
        <th scope="col">{{ 'ORGANIZATION_TAB.USERS' | translate }}</th>
        <th scope="col">{{ 'ACTION_TABLE' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let group of groups" class="col-12 mid-organization-item">
        <td>
          {{ group.name | translate }}
        </td>
        <td>
          {{ 'GROUP_ROLE.' + group.role.toUpperCase() | translate }}
        </td>
        <td class="group-filter" (click)="onFilterUsers(group)">
          {{ groupUserMap.get(group)?.length || 0 }}
        </td>
        <td>
          <div>
            <app-mid-button type="secondary" *ngIf="groupPermissionMap.get(group)?.update" [matMenuTriggerFor]="menu">
              <i class="fas fa-ellipsis-v"></i>
            </app-mid-button>
            <mat-menu #menu="matMenu" class="mid-menu">
              <div class="mid-menu-item" (click)="deleteGroup(group)">
                <i class="fas fa-link mr-1 mid-menu-item-icon"></i>
                <span> {{ 'GROUP_ACTIONS.DELETE' | translate }} </span>
              </div>
            </mat-menu>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <app-mid-button type="secondary" (action)="onAddGroup()" *ngIf="groupCreatePermission?.hasPermission">
    {{ 'GROUP_ACTIONS.ADD' | translate }}
  </app-mid-button>
</div>
