import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import mapboxgl from 'mapbox-gl';
import { TdrawService } from 'src/app/autogenerated/tdrawService';
import { OrganizationStoreService } from 'src/app/organization/store';
import { tdrawConfig } from '../../tdraw.config';
import { InfopointManager, InfopointData } from '../../misc/infopoint-manager';
import { MapState } from '../../models/map-state.model';
import { MapStateUpdateDto } from '../../models/dto';
import _ from 'lodash';
import CoordinatesHelpers from '../../misc/coordinates-helpers';
import { DuplicateVersionModalComponent } from '../../components/modals/duplicate-version-modal/duplicate-version-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Unsubscribe } from 'src/app/core/decorators';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { RightPanelData, RightPanelMode } from '../../models/right-panel-data';
import { interventionConfig } from 'src/app/intervention/intervention.config';
import { MapIconsService } from '../../services/map-icons.service';
import { GisManager } from '../../misc/gis-manager';
import MapCompareTool from '../../misc/map-compare-tool';
import { LocationSearchResult } from '../../models/location-search-result.model';
import { ViewModeManager } from '../../misc/view-mode-manager';
import { ExportMapStateModalComponent } from '../../components/modals/export-mapstate-modal/export-mapstate-modal.component';
import { OrganizationMedia } from 'src/app/autogenerated/model';
import { rightPanelDisplayState } from 'src/app/midgard-controls/enums/display-state.enum';
import { RightPanelType } from 'src/app/midgard-controls/enums/right-panel-type';

@Unsubscribe()
@Component({
  selector: 'app-tdraw-gis-view-mode-page',
  templateUrl: './tdraw-gis-view-mode-page.component.html',
  styleUrls: ['./tdraw-gis-view-mode-page.component.scss'],
})
export class TdrawGisViewModePageComponent implements OnInit, OnDestroy {
  // Input
  interventionId: string;
  organizationId: string;
  mapStateId: string;

  // Map
  map: mapboxgl.Map;
  currentInfo: InfopointData;
  date = new Date();
  gisManager: GisManager;
  viewModeManager: ViewModeManager;

  // State
  mapState: MapState;
  rightPanelData: RightPanelData = { mode: RightPanelMode.Hidden };

  // Pinpoint image selection
  pinpointMedia: OrganizationMedia = null;
  pinpointMediaPanelDisplayState = rightPanelDisplayState.hidden;

  // Subscriptions
  private organizationSubscription: Subscription;
  private featureSelectedChangedSubscription: Subscription;
  private featureUnselectedSubscription: Subscription;
  private pinpointOpenedSubscription: Subscription;

  // MapCompare
  private mapCompare: MapCompareTool;
  isCompareMode: boolean = false;

  // Enums
  get RightPanelMode() {
    return RightPanelMode;
  }
  get PinpointMediaPanelType() {
    return RightPanelType;
  }

  constructor(
    readonly translate: TranslateService,
    activatedRoute: ActivatedRoute,
    private router: Router,
    private organizationStoreService: OrganizationStoreService,
    private tdrawService: TdrawService,
    private dialog: MatDialog,
    private mapIconsService: MapIconsService
  ) {
    this.mapStateId = activatedRoute.snapshot.data.mapStateId;
    this.interventionId = activatedRoute.snapshot.data.interventionId;

    this.organizationSubscription = this.organizationStoreService
      .getCurrentOrganization()
      .subscribe(async (organization) => {
        if (!organization || this.organizationId === organization._id) {
          return;
        }
        this.organizationId = organization._id;
      });
  }

  async ngOnInit() {
    this.mapState = await this.tdrawService.getMapState(this.organizationId, this.interventionId, this.mapStateId);
    this.initMap();
  }

  ngOnDestroy(): void {}

  private initMap() {
    // Be sure that all projections we use are loaded in proj4
    CoordinatesHelpers.registerProjections();

    this.map = new mapboxgl.Map({
      accessToken: tdrawConfig.mapAccessToken,
      container: 'map-gis-view',
      style: tdrawConfig.mapStyle,
      center: tdrawConfig.defaultMapCenter,
      zoom: 13,
    });
    this.map.addControl(new mapboxgl.ScaleControl());
    this.map.addControl(new mapboxgl.NavigationControl(), 'bottom-left');

    this.gisManager = new GisManager(this.map);
    this.viewModeManager = new ViewModeManager(this.map, this.mapState);

    // Initialize Infopoint to get informations on map click events
    new InfopointManager(this.map).init((info: InfopointData) => {
      this.currentInfo = info;
    });

    // Listeners
    this.map.on('load', () => this.onMapLoad());
    this.featureSelectedChangedSubscription = this.viewModeManager.onFeatureSelectedChanged((data) => {
      this.setRightPanelMode(RightPanelMode.Properties);
    });
    this.featureUnselectedSubscription = this.viewModeManager.onFeatureUnselected(() => {
      this.exitPropertiesPanel();
    });
    this.pinpointOpenedSubscription = this.gisManager.onPinpointOpened((image: OrganizationMedia) => {
      this.pinpointMedia = image;
      this.pinpointMediaPanelDisplayState = rightPanelDisplayState.displaying;
    });

    // Hide 204 Errors from TMS Tiles
    this.map.on('error', (e) => {
      if (e.source?.scheme === 'tms') {
        return;
      }
      console.error(e);
    });

    this.mapCompare = new MapCompareTool(this.map);
  }

  private async onMapLoad() {
    await this.gisManager.load();
    this.mapIconsService.loadImages(this.map);

    // Wait for map prerequisites to be fully loaded before loading data
    this.map.once('idle', () => {
      this.gisManager.loadDataOnMap(this.viewModeManager);
    });
  }

  private exitPropertiesPanel() {
    if (this.rightPanelData.mode !== RightPanelMode.Hidden) {
      this.rightPanelData.mode = RightPanelMode.Navigation;
    }
  }

  setRightPanelMode(mode: RightPanelMode) {
    this.rightPanelData.mode = mode;
  }

  flyMapToLocation(location: LocationSearchResult) {
    const center = location.coordinates;
    const zoom = 13;
    this.map.flyTo({ center, zoom });
  }

  close() {
    this.router.navigate([`interventions/${this.interventionId}/${interventionConfig.routes.tdraw}/home`]);
  }

  toggleCompare() {
    if (this.isCompareMode) {
      this.mapCompare.stop();
      this.isCompareMode = false;
    } else {
      this.mapCompare.start();
      this.isCompareMode = true;
    }
  }

  geolocalizeMe() {
    this.gisManager.geolocalizeMe();
  }

  openExportModal() {
    this.dialog.open(ExportMapStateModalComponent, {
      width: '900px',
      data: {
        mapState: this.mapState,
      },
    });
  }

  async printSnapshot() {
    this.viewModeManager.mapManager.printSnapshot(true);
  }

  async downloadSnapshot() {
    this.viewModeManager.mapManager.downloadSnapshot(true);
  }

  async duplicateHistory() {
    const dialogRef = this.dialog.open(DuplicateVersionModalComponent, {
      id: 'duplicateHistoryModal',
      width: '450px',
      data: this.mapState.name + ' (copy)',
    });
    dialogRef.afterClosed().subscribe((name) => {
      if (!name) {
        return;
      }
      this.onHistoryDuplication(name, this.mapState.statistics);
    });
  }

  private async onHistoryDuplication(name: string, statistics: any) {
    try {
      this.mapState = await this.tdrawService.duplicateMap(this.organizationId, this.interventionId, this.mapStateId);
      this.mapStateId = this.mapState._id;
      if (name) {
        await this.tdrawService.updateMap(
          this.organizationId,
          this.interventionId,
          this.mapStateId,
          new MapStateUpdateDto({ name, statistics })
        );
      }
      this.router.navigate([
        `interventions/${this.interventionId}/${interventionConfig.routes.tdraw}/gis/${this.mapState._id}/edit`,
      ]);
    } catch (e) {
      console.error(e);
    }
  }
}
