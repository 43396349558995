import { DataMergeService } from './../global/datamerge.service';
import { MyErrorHandler } from '../global/ErrorHandler';
import { MessageType, Organization, OrganizationSubscription, User } from '../autogenerated/model';

import { environment } from '../../environments/environment';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { Subject, Observable, Observer, Subscription } from 'rxjs';
import { Injectable, EventEmitter } from '@angular/core';
import { OrganizationStoreService } from '../organization/store';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { coreConfig } from './core.config';
import { IMessageForClient } from './shared/web-socket-messages.dto';
import { take } from 'rxjs/operators';
import { UserProfilesStoreService } from '../user-profile/store';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  incomingData = new EventEmitter<IMessageForClient>(true);

  organizationSubscription: Subscription;
  userSubscription: Subscription;

  private subject: Subject<any>;
  private currentOrganization: Organization;
  private currentUser: Organization;

  constructor(
    private organizationStoreService: OrganizationStoreService,
    private userProfilesStoreService: UserProfilesStoreService,
    private errorHandler: MyErrorHandler,
    private dataMergeService: DataMergeService
  ) {}

  async connect() {
    this.subject = this.create();

    this.subject.subscribe((message: MessageEvent) => {
      const data = this.convertToMessageEventType(message);
      if (!data) {
        return;
      }
      this.incomingData.emit(data);
    });

    // TODO: unsubscribe
    // this.userSubscription = 
    this.userProfilesStoreService.getCurrentUser().subscribe((user: User) => {
        this.currentUser = user;
      }).unsubscribe();
    this.organizationSubscription = this.organizationStoreService
      .getCurrentOrganization()
      .subscribe((organization) => this.updateSubjectForOrganization({
        organization: organization,
        user: this.currentUser
      }));

    // const organization = await this.organizationStoreService.getCurrentOrganization().pipe(take(1)).toPromise();
    // const user = await this.organizationStoreService.getCurrentUser().pipe(take(1)).toPromise();
    // this.updateSubjectForOrganization(organization);
  }

  private updateSubjectForOrganization(connection: { organization: Organization, user: User }): void {
    if (!connection?.organization && this.currentOrganization?._id === connection.organization?._id) {
      console.log('Web Socket new organization: ' + connection.organization?.name);
      return;
    }
    this.currentOrganization = connection.organization;

    console.log('Web Socket new organization: ' + connection.organization?.name);
    this.subject.next(
      new OrganizationSubscription({
        messageType: MessageType.subscription,
        organization: { ...connection.organization, user: connection.user },
      })
    );
  }

  private create(): Subject<any> {
    const options = {
      connectionTimeout: coreConfig.websocket.webSocketConnectionTimeout,
      maxRetries: coreConfig.websocket.webSocketMaxRetries,
    };

    const ws = new ReconnectingWebSocket(environment.webSocket, [], options);

    const observable = new Observable((subscriber) => {
      ws.onmessage = subscriber.next.bind(subscriber);
      ws.onerror = subscriber.error.bind(subscriber);
      ws.onclose = subscriber.complete.bind(subscriber);
      return ws.close.bind(ws);
    });

    const observer: Observer<any> = {
      next: (data: any) => {
        if (ws.readyState === WebSocket.OPEN) {
          // ws.send(JSON.stringify(data));
          // compatibility with Nest backend
          ws.send(JSON.stringify({ event: 'message', data }));
        }
      },
      complete: () => {
        ws.close();
      },
      error: (err) => {
        // the server might rebooted
        // we try reconnect in 3 seconds;
        this.errorHandler.handleError(err);
        ws.close();
      },
    };

    return new AnonymousSubject<any>(observer, observable);
  }

  private convertToMessageEventType(message: MessageEvent): IMessageForClient {
    let data = JSON.parse(message?.data);

    if (data?.data) {
      data = data.data;
    }
    if (!data) {
      return null;
    }

    data = this.dataMergeService.parseData(data);

    const rawMessage = data as IMessageForClient;

    return rawMessage;
  }
}
