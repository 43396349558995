import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { Drone, DroneLiveStreamMedia, OrganizationMedia } from '../../../autogenerated/model';
import { rightPanelDisplayState } from '../../../midgard-controls/enums/display-state.enum';
import { MidButtonSize } from 'src/app/midgard-controls/enums/button-size.enum';
import { MediaService } from '../../../autogenerated/mediaService';
import { RightPanelType } from 'src/app/midgard-controls/enums/right-panel-type';
import moment from 'moment';
import { isEmpty } from 'lodash';
import mapboxgl, { Marker } from 'mapbox-gl';
import { tdrawConfig } from '../../../tdraw/tdraw.config';
import { MapboxStyleSwitcherControl } from 'mapbox-gl-style-switcher';
import { DroneStoreService } from 'src/app/drone/store';
import { Subscription } from 'rxjs';
import { Unsubscribe } from 'src/app/core/decorators';
import { required, validate } from '../../../shared';
import { OrganizationsMediaService, SharedMediaFileDto } from '../../../../api';
import { MediaApiClientService } from '../../../../api-services/media-api-client.service';
import CoordinatesHelpers from 'src/app/tdraw/misc/coordinates-helpers';

// We can add more types ...
enum GPSSharingMode {
  SMS = 'sms',
}

type CurrentMedia = {
  mediaName: string;
  mediaDate: {
    importedDate: string;
    importedTime: Date;
  };
  mediaTags: string[];
  source?: string;
  streamDate?: {
    startedDate: string;
    startedTime: Date;
  };
  gps?: {
    latitude: string;
    longitude: string;
    altitude: number;
  };
};

@Unsubscribe()
@Component({
  selector: 'app-media-right-panel',
  templateUrl: './media-right-panel.component.html',
  styleUrls: ['./media-right-panel.component.scss'],
})
export class MediaRightPanelComponent implements OnChanges, OnDestroy {
  private readonly RIGHT_PANEL_WIDTH = 350;

  @Input()
  media: OrganizationMedia;

  @Input()
  displayState: rightPanelDisplayState;

  @Input()
  type: RightPanelType;

  @Output()
  displayStateChanged: EventEmitter<rightPanelDisplayState>;

  currentMedia: CurrentMedia;
  drone: Drone;
  private droneSubscription: Subscription;
  map: mapboxgl.Map;

  sharingMode?: GPSSharingMode;

  dataToShare: {
    phoneNumber: any;
  };

  mapsUrl: string;

  share: SharedMediaFileDto;

  mediaShareLink: string;

  get GPSSharingMode() {
    return GPSSharingMode;
  }

  get MediaRightPanelDisplayState() {
    return rightPanelDisplayState;
  }

  get MidButtonSize() {
    return MidButtonSize;
  }

  get RightPanelType() {
    return RightPanelType;
  }

  constructor(
    private mediaService: MediaService,
    private readonly droneStoreService: DroneStoreService,
    private readonly organizationsMediaService: OrganizationsMediaService,
    private readonly mediaApiClientService: MediaApiClientService
  ) {
    this.displayStateChanged = new EventEmitter();
    this.currentMedia = this.getDefaultCurrentMedia();
    this.displayState = rightPanelDisplayState.hidden;

    this.dataToShare = {
      phoneNumber: null,
    };
  }

  async ngOnChanges(changes: SimpleChanges) {
    const componentChanges = changes as PropertyMap<MediaRightPanelComponent, SimpleChange>;
    const mediaChange = componentChanges.media;
    const hasMediaChanges = mediaChange?.currentValue && mediaChange?.currentValue !== mediaChange?.previousValue;
    if (hasMediaChanges) {
      await this.loadData();
    }
    // If we need to display the component again with the same data
    // we still need to initialize the Map
    if (this.media) {
      if (this.displayState === rightPanelDisplayState.displaying) {
        setTimeout(() => this.initMap(), 100);
      }
    }
  }

  ngOnDestroy(): void {
    this.removeMap();
  }

  /**
   * TODO: unused => delete
   */
  updateMediaTags(tags: string[]) {
    this.currentMedia.mediaTags = tags;
  }

  /**
   * TODO: unused => delete
   */
  checkToSave() {
    return !!(!this.currentMedia.mediaName && isEmpty(this.currentMedia.mediaTags));
  }

  toggle() {
    if (this.displayState === rightPanelDisplayState.hidden) {
      this.displayState = rightPanelDisplayState.displaying;
      setTimeout(() => this.initMap(), 100);
    } else {
      this.displayState = rightPanelDisplayState.hidden;
    }
  }

  async save() {
    if (this.currentMedia.mediaName !== this.media.name) {
      await this.mediaService.renameMedia(this.media.organization as string, this.media._id, {
        _id: this.media._id,
        newName: this.currentMedia.mediaName,
      });
    }
    if (this.currentMedia.mediaTags) {
      await this.mediaService.updateMediaTags(this.media.organization as string, this.media._id, {
        _id: this.media._id,
        tags: this.currentMedia.mediaTags,
      });
    }
    this.onClose();
  }

  onClose() {
    this.displayState = rightPanelDisplayState.hidden;
    this.displayStateChanged.emit(this.displayState);
    // this.resetData();
    this.removeMap();
  }

  @HostListener('document:mousedown', ['$event'])
  onClickOutside($event) {
    if (
      this.displayState === rightPanelDisplayState.displaying &&
      $event.x < window.innerWidth - this.RIGHT_PANEL_WIDTH
    ) {
      this.onClose();
    }
  }

  hasMediaGPSLngLat() {
    return !!(this.media?.gps?.longitude && this.media?.gps?.latitude);
  }

  private getDefaultCurrentMedia(): CurrentMedia {
    return {
      mediaName: '',
      mediaDate: { importedDate: '', importedTime: new Date() },
      mediaTags: [],
      streamDate: null,
      source: '',
      gps: null,
    };
  }

  /**
   * Reset data
   */
  private resetData() {
    Object.assign(this.currentMedia, this.getDefaultCurrentMedia());
    if (this.droneSubscription?.closed === false) {
      this.droneSubscription.unsubscribe();
    }
    this.drone = null;
    this.removeMap();
  }

  /**
   * Load media data
   */
  private async loadData() {
    this.resetData();
    this.currentMedia.mediaName = this.media?.name;
    this.currentMedia.mediaDate.importedDate = moment(this.media?.creationDate).format('L');
    this.currentMedia.mediaDate.importedTime = this.media?.creationDate;
    this.currentMedia.mediaTags = this.media?.tags;
    if ((this.media as DroneLiveStreamMedia)?.startDate) {
      this.currentMedia.streamDate = {
        startedDate: moment((this.media as DroneLiveStreamMedia).startDate).format('L'),
        startedTime: (this.media as DroneLiveStreamMedia).startDate,
      };
    }
    if ((this.media as DroneLiveStreamMedia)?.drone) {
      this.droneSubscription = this.droneStoreService
        .getDroneById((this.media as DroneLiveStreamMedia)?.drone as string)
        .subscribe((drone) => {
          this.drone = drone;
          this.currentMedia.source = this.drone?.name;
        });
    }
    if (this.hasMediaGPSLngLat()) {
      this.currentMedia.gps = {
        latitude:
          CoordinatesHelpers.convertDDToDMS(this.media.gps?.latitude) + (this.media.gps?.latitude < 0 ? ' S ' : ' N '),
        longitude:
          CoordinatesHelpers.convertDDToDMS(this.media.gps?.longitude) +
          (this.media.gps?.longitude < 0 ? ' W ' : ' E '),
        altitude: this.media.gps?.altitude,
      };
      this.mapsUrl = `https://www.google.com/maps/place/${this.media.gps?.latitude},${this.media.gps?.longitude}`;
    }
  }

  private removeMap() {
    if (this.map?.loaded()) {
      this.map.remove();
    }
  }

  private initMap() {
    if (!this.hasMediaGPSLngLat()) {
      this.removeMap();
      return;
    }

    const location: mapboxgl.LngLatLike = [this.media.gps.longitude, this.media.gps.latitude];

    // create map
    this.map = new mapboxgl.Map({
      accessToken: tdrawConfig.mapAccessToken,
      container: 'create-media-map',
      style: tdrawConfig.mapStyle,
      center: location,
      zoom: 13,
    });

    this.map.addControl(new MapboxStyleSwitcherControl());

    // display pinpoint
    this.map.on('load', () => {
      const el = document.createElement('div');
      el.className = 'map-pinpoint-marker';
      new Marker(el).setLngLat(location).addTo(this.map);
    });
  }

  shareUsingMode(mode: GPSSharingMode) {
    this.sharingMode = mode;
  }

  async sendSMS(): Promise<void> {
    if (!this.dataToShare.phoneNumber) {
      return;
    }

    try {
      this.share = await this.organizationsMediaService
        .mediaShareControllerCreate({
          duration: 1,
          media: this.media.id,
          organization: this.media.organization as string,
        })
        .toPromise();
    } catch (e) {
      console.log(e);
      return null;
    }

    this.mediaShareLink = this.mediaApiClientService.getShareLink(this.share);

    await this.sendSmsRequest(this.dataToShare.phoneNumber.e164Number);
    this.sharingMode = null;
    setTimeout(() => this.initMap(), 100);
  }

  cancel(): void {
    this.sharingMode = null;
    setTimeout(() => this.initMap(), 100);
  }

  @validate
  private async sendSmsRequest(@required phoneNumber: string): Promise<void> {
    await this.mediaService.sendGPSPosition(
      this.media.organization as string,
      this.media._id,
      phoneNumber,
      this.currentMedia,
      this.mapsUrl,
      this.mediaShareLink
    );
  }
}
