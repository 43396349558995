import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { required, RouterUtils, validate } from '../shared';
import { interventionConfig } from './intervention.config';

@Injectable({
  providedIn: 'root',
})
export class InterventionIdResolverService {
  @validate
  resolve(@required route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> {
    const interventionId = RouterUtils.getRouteParamRecursively(interventionConfig.routes.params.interventionId, route);
    return of(interventionId);
  }
}
