import { DroneService } from './../../../autogenerated/droneService';
import { ActivityDroneLaunch } from './../../../autogenerated/model';
import { Drone } from 'src/app/autogenerated/model';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-DroneLive',
  templateUrl: './DroneLive.component.html',
  styleUrls: ['./DroneLive.component.scss'],
})
export class DroneLiveComponent implements OnInit {
  drone: Drone;
  data: ActivityDroneLaunch;

  constructor(private droneService: DroneService) {}

  ngOnInit() {
    this.droneService.getDrone({ _id: this.data.drone.toString() }).then((drone) => {
      this.drone = drone;
    });
  }
}
