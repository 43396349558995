<ng-container *ngIf="editedUser && selectedGroups">
  <td>
    <div class="organization-user-item-col">
      <div>
        {{ editedUser?.fullname }}
      </div>
    </div>
  </td>

  <td>
    <div class="organization-user-item-col">
      <div class="user-groups" *ngIf="!isEditMode">
        {{ selectedGroupsNames }}
      </div>
      <div *ngIf="canAdmin(user) && isEditMode" class="mid-select-rules">
        <app-group-select-dropdown
          [previousGroups]="previousSelectedGroups"
          [multiple]="isGroupModeMultiple"
          [groups]="availableGropes"
          (selectedGroups)="onGroupChanged($event)"
        ></app-group-select-dropdown>
      </div>
    </div>
  </td>

  <td>
    <div class="organization-user-item-col">
      <div>
        {{ editedUser?.email }}
      </div>
    </div>
  </td>

  <td>
    <div class="organization-user-item-col">
      <div>
        {{ editedUser?.phoneNumber }}
      </div>
    </div>
  </td>

  <td>
    <div class="organization-user-item-col">
      <mat-menu #menu="matMenu" class="mid-menu">
        <div class="mid-menu-item" (click)="onDelete(user)">
          <i class="fas fa-times mr-1 mid-menu-item-icon"></i>
          <span> {{ 'REMOVE' | translate }} </span>
        </div>
      </mat-menu>
      <div *ngIf="isEditMode" class="organization-user-item-col-btns">
        <button class="organization-user-item-btn" (click)="onSave(editedUser)">
          <i class="fas fa-save"></i>
        </button>
        <button class="organization-user-item-btn" (click)="onEdit()">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <div *ngIf="!isEditMode" class="organization-user-item-col-btns">
        <button
          *ngIf="canAdmin(user)"
          class="organization-user-item-btn"
          (click)="onEdit()"
          [disabled]="hasGlobalEditMode"
        >
          <i class="fas fa-pen"></i>
        </button>
        <button
          class="organization-user-item-btn"
          [disabled]="hasGlobalEditMode"
          [matMenuTriggerFor]="menu"
          *ngIf="isAdmin"
        >
          <i class="fas fa-ellipsis-v"></i>
        </button>
      </div>
    </div>
  </td>
</ng-container>
