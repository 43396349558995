import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { CoreModule } from '../core/core.module';
import { UserProfilesStoreModule } from './store';
import { AuthenticationModule } from '../authentication/authentication.module';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MidgardControlsModule } from '../midgard-controls/midgard-controls.module';

@NgModule({
  declarations: [MyProfileComponent],
  imports: [
    CoreModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    UserProfilesStoreModule,
    AuthenticationModule,
    MatInputModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MidgardControlsModule,
  ],
})
export class UserProfilesModule {}
