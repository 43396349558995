import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ContextPermissionsDto, DroneService, InterventionService, OrganizationsService } from 'src/api';
import { MediaService } from 'src/app/autogenerated/mediaService';
import { Unsubscribe } from 'src/app/core/decorators';
import { LoggerService } from 'src/app/core/logger.service';
import { CreateDroneComponent } from 'src/app/drone/dialogs/create-drone-right-panel/create-drone/create-drone.component';
import { TemplateService } from 'src/app/global/template.service';
import { CreateInterventionComponent } from 'src/app/intervention/dialogs/create-intervention-right-panel/create-intervention/create-intervention.component';
import { MidRightPanelService } from 'src/app/midgard-controls/services/mid-right-panel.service';
import { OrganizationStoreService } from 'src/app/organization/store';

@Unsubscribe()
@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss'],
})
export class DashboardPageComponent implements OnInit, OnDestroy {
  organizationId: string;
  mediaCount = 0;
  showNewDroneDialog = false;
  interventionsPermission: ContextPermissionsDto;
  dronesPermission: ContextPermissionsDto;

  private organizationSubscription: Subscription;

  constructor(
    private readonly templateService: TemplateService,
    private readonly router: Router,
    private readonly organizationStoreService: OrganizationStoreService,
    private readonly mediaService: MediaService,
    private readonly droneService: DroneService,
    private readonly interventionService: InterventionService,
    private readonly logger: LoggerService,
    private readonly midRightPanelService: MidRightPanelService
  ) {
    this.templateService.$activeComponent.next('DASHBOARD');
    this.templateService.pushToAll('');
  }

  async ngOnInit() {
    // TODO: restore later
    // const isLandingIntroShown = await this.layoutStoreService.getIsLandingIntroShown().pipe(take(1)).toPromise();
    // if (!isLandingIntroShown) {
    //   const intro: IntroJs = await this.introService.createIntroJs();

    //   setTimeout(() => {
    //     intro.start();
    //     this.layoutStoreService.setIsLandingIntroShown();
    //   }, 500);
    // }

    this.organizationSubscription = this.organizationStoreService
      .getCurrentOrganization()
      .subscribe(async (organization) => {
        this.organizationId = organization ? organization._id : null;
        if (!this.organizationId) {
          return;
        }

        this.mediaService
          .getMediaV1(this.organizationId, { limit: 0 })
          .then((it) => (this.mediaCount = it.count))
          .catch((error) => this.logger.error(error));

        this.droneService
          .orgDroneControllerGetPermissions(this.organizationId)
          .toPromise()
          .then((dronesPermission) => {
            this.dronesPermission = dronesPermission;
          })
          .catch((error) => this.logger.error(error));

        this.interventionService
          .organizationInterventionControllerGetPermissions(this.organizationId)
          .toPromise()
          .then((interventionsPermission) => {
            this.interventionsPermission = interventionsPermission;
          })
          .catch((error) => this.logger.error(error));
      });
  }

  onOpenCreateDrone(): void {
    this.midRightPanelService.open(CreateDroneComponent);
  }

  onOpenCreateIntervention(): void {
    const data = { organizationId: this.organizationId };
    this.midRightPanelService.open(CreateInterventionComponent, data);
  }

  ngOnDestroy(): void {
    // for Unsubscribe
  }

  goTo(pagePath: string): void {
    this.router.navigate([pagePath]);
  }
}
