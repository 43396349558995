<app-modal-header text="{{ 'TDRAW.MODAL.NEW_GIS.TITLE' | translate }}" (closeAction)="onClose()"></app-modal-header>
<div class="body">
  <form name="formNewMap" #formNewMap="ngForm" novalidate>
    <app-mid-input
      size="wide"
      [(value)]="newMap.name"
      #mapName="appMidInput"
      name="mapName"
      [required]="true"
      [label]="'TDRAW.MODAL.NEW_GIS.NAME_LABEL' | translate"
      [placeholder]="'TDRAW.MODAL.NEW_GIS.NAME_PLACEHOLDER' | translate"
    >
      <span *ngIf="formNewMap.invalid && mapName.invalid" class="text-danger font-weight-bold">{{
        'COMMON_REQUIRED_FIELD' | translate
      }}</span>
    </app-mid-input>

    <tdraw-location-search-bar
      (onItemSelected)="onLocationSelected($event)"
      [label]="'CENTER_MAP_QUESTION' | translate"
    ></tdraw-location-search-bar>
    <div id="create-version-map"></div>

    <p class="mb-0 mt-3">{{ 'TDRAW.MODAL.NEW_GIS.CONFIRM_MAP_CENTER_AND_ZOOM' | translate }}</p>
    <div class="buttons">
      <app-mid-button type="secondary" (action)="onClose()">{{ 'COMMON_CANCEL' | translate }}</app-mid-button>
      <app-mid-button type="primary" (action)="onSubmit()" [disabled]="formNewMap.invalid || formNewMap.pristine">
        <i class="fas fa-check"></i> {{ 'VALIDATE_AND_CREATE' | translate }}
      </app-mid-button>
    </div>
  </form>
</div>
