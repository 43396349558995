<app-simple-header [sharingType]="'tdrawgis'"></app-simple-header>
<div class="edit-panel" *ngIf="!isNotAvailable">
  <div class="d-flex">
    <div class="labels ml-3">
      <div class="description-box">
        <div class="description">
          {{ date | date : 'dd MMMM yyyy / HH:mm' : undefined : translate.getDefaultLang() }}
        </div>
        <div class="description" *ngIf="currentInfo">
          <span>{{ currentInfo.lng }} / {{ currentInfo.lat }}</span>
          <span class="ml-3" *ngIf="currentInfo.elevation">{{ currentInfo.elevation }} m</span>
          <span class="dfci">{{ currentInfo.dfci }}</span>
          <span class="ml-3"
            >{{ currentInfo.utmCoords[0] }} / {{ currentInfo.utmCoords[1] }} (EPSG:{{ currentInfo.utmEpsg }})</span
          >
        </div>
      </div>
    </div>
  </div>

  <app-mid-button [matMenuTriggerFor]="actionsMenu" type="secondary">
    {{ 'TDRAW.ACTIONS' | translate }} <i class="fas fa-ellipsis-v ml-3"></i>
  </app-mid-button>
</div>

<mat-menu #actionsMenu="matMenu" class="mid-menu" panelClass="pt-0">
  <div class="mid-menu-item" (click)="geolocalizeMe()">
    <i class="fas fa-location mr-2 mid-menu-item-icon"></i>
    {{ 'TDRAW.GEOLOCALIZE_ME' | translate }}
  </div>
  <div class="mid-menu-item" (click)="printSnapshot()">
    <i class="fas fa-print mr-2 mid-menu-item-icon"></i>
    {{ 'TDRAW.PRINT' | translate }}
  </div>
  <div class="mid-menu-item" (click)="downloadSnapshot()">
    <i class="fas fa-camera mr-2 mid-menu-item-icon"></i>
    {{ 'TDRAW.SCREENSHOT' | translate }}
  </div>
  <div class="mid-menu-item" (click)="toggleCompare()">
    <i class="fas fa-equals mr-2 mid-menu-item-icon"></i>
    {{ (isCompareMode ? 'COMPARE_STOP' : 'COMPARE_START') | translate }}
  </div>
</mat-menu>

<div class="map-container">
  <div class="mapbox-container">
    <div id="map-shared-gis" *ngIf="!isNotAvailable"></div>
    <div id="error-fullscreen" *ngIf="isNotAvailable">
      <h3>{{ 'UNAVAILABLE_MAP_TITLE' | translate }}</h3>
      {{ 'UNAVAILABLE_MAP_CONTENT' | translate }}
    </div>
  </div>

  <tdraw-right-panel [data]="rightPanelData" *ngIf="!isNotAvailable">
    <tdraw-readonly-properties-panel
      *ngIf="rightPanelData.mode === RightPanelMode.Properties"
      [viewModeManager]="viewModeManager"
      (goToToolbox)="setRightPanelMode(RightPanelMode.Navigation)"
    >
    </tdraw-readonly-properties-panel>

    <tdraw-tools-panel *ngIf="gisManager?.isLoaded" [class.d-none]="rightPanelData.mode !== RightPanelMode.Navigation">
      <tdraw-tools-panel-tab [title]="'BASEMAP' | translate" fa-icon="fas fa-map">
        <tdraw-basemap-tab [gisManager]="gisManager" (selectLocation)="flyMapToLocation($event)"></tdraw-basemap-tab>
      </tdraw-tools-panel-tab>

      <tdraw-tools-panel-tab [title]="'LAYERS' | translate" fa-icon="fas fa-layer-group">
        <tdraw-layers-tab [mapModeManager]="viewModeManager" [statistics]="mapState?.statistics"> </tdraw-layers-tab>
      </tdraw-tools-panel-tab>
    </tdraw-tools-panel>
  </tdraw-right-panel>
</div>
