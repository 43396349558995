import { Intervention } from '../../../autogenerated/model';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface SelectInterventionDialogData {
  organizationId: string;
  intervention: Intervention;
}

@Component({
  selector: 'app-select-intervention',
  templateUrl: './select-intervention.component.html',
  styleUrls: ['./select-intervention.component.scss'],
})
export class SelectInterventionComponent {
  organizationId: string;
  selectedIntervention: Intervention;

  state: {
    isLoading: boolean;
  };

  constructor(
    public dialogRef: MatDialogRef<SelectInterventionComponent, Intervention>,
    @Inject(MAT_DIALOG_DATA) public data: SelectInterventionDialogData
  ) {
    this.organizationId = data.organizationId;
    this.state = {
      isLoading: false,
    };
  }

  async onSelectIntervention(): Promise<void> {
    this.dialogRef.close(this.selectedIntervention);
  }

  onCancel() {
    this.dialogRef.close();
  }

  getSelectedIntervention(selectedIntervention: Intervention) {
    this.selectedIntervention = selectedIntervention;
  }
}
