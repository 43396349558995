import { Component, OnDestroy, OnInit } from '@angular/core';
import { tdrawConfig } from '../../tdraw/tdraw.config';
import mapboxgl from 'mapbox-gl';
import { TdrawService } from 'src/app/autogenerated/tdrawService';
import { InterventionStoreService } from 'src/app/intervention/store';
import { OrganizationStoreService } from 'src/app/organization/store';
import { Subscription } from 'rxjs';
import { Intervention, Organization } from 'src/app/autogenerated/model';
import { ContextPermissionsDto, InterventionService as InterventionServiceV2 } from 'src/api';
import * as turf from '@turf/turf';
import _ from 'lodash';
import { Unsubscribe } from 'src/app/core/decorators';
import { InterventionService } from '../../autogenerated/interventionService';
import { MidRightPanelService } from 'src/app/midgard-controls/services/mid-right-panel.service';
import { CreateInterventionComponent } from 'src/app/intervention/dialogs/create-intervention-right-panel/create-intervention/create-intervention.component';
import { LoggerService } from 'src/app/core/logger.service';

@Unsubscribe()
@Component({
  selector: 'app-interventions-map',
  templateUrl: './interventions-map.component.html',
  styleUrls: ['./interventions-map.component.scss'],
})
export class InterventionsMapComponent implements OnInit, OnDestroy {
  private sourceId = 'points';
  private organization: Organization;
  private map: mapboxgl.Map;

  // Subscriptions
  private organizationSubscription: Subscription;

  interventionsPermission: ContextPermissionsDto;

  organizationId: string;
  demoMap = false;

  state: {
    isLoading: boolean;
    isFailed: boolean;
  };

  constructor(
    private readonly interventionStoreService: InterventionStoreService,
    private readonly interventionService: InterventionService,
    private readonly logger: LoggerService,
    private readonly organizationStoreService: OrganizationStoreService,
    private tdrawService: TdrawService,
    private readonly midRightPanelService: MidRightPanelService,
    private readonly interventionServiceV2: InterventionServiceV2
  ) {
    this.state = {
      isLoading: false,
      isFailed: false,
    };
  }

  ngOnInit(): void {
    this.organizationSubscription = this.organizationStoreService
      .getCurrentOrganization()
      .subscribe(async (organization: Organization) => {
        if (organization?._id === this.organizationId) {
          return;
        }
        this.organization = organization;
        this.organizationId = organization._id;
        await this.loadInterventions(organization._id);
      });
  }

  ngOnDestroy(): void {}

  onOpenCreateIntervention(): void {
    const data = { organizationId: this.organizationId };
    this.midRightPanelService.open(CreateInterventionComponent, data);
  }

  private async loadInterventions(organizationId: string) {
    this.state.isLoading = true;
    this.state.isFailed = false;

    this.interventionServiceV2
      .organizationInterventionControllerGetPermissions(this.organizationId)
      .toPromise()
      .then((interventionsPermission) => {
        this.interventionsPermission = interventionsPermission;
      })
      .catch((error) => this.logger.error(error));

    try {
      // todo: There is some thing wrong here.
      const interventions = await this.interventionService.getInterventionsWithMapping({ _id: organizationId });
      this.interventionStoreService.setOrganizationInterventions(organizationId, _.cloneDeep(interventions));

      const openedInterventions = _.filter(interventions, (e) => {
        return !e.isClosed;
      });

      await this.initMap(openedInterventions);
    } catch (error) {
      this.state.isFailed = true;
    } finally {
      this.state.isLoading = false;
    }
  }

  private async generateMapPoints(interventions: Intervention[]): Promise<turf.FeatureCollection> {
    const points = [];

    for (const intervention of interventions) {
      const mapStates = await this.tdrawService.getMapStates(this.organization._id, intervention._id);
      const publishedMapStates = _.filter(mapStates, (e) => {
        return e.publishedAt != null;
      });

      const mapState = publishedMapStates?.[0];
      const coordinates = mapState?.background?.center;
      if (!coordinates) {
        continue;
      }

      const intId = intervention._id;
      const intName = intervention.name;
      const intUrl = `/interventions/${intId}`;
      points.push(
        turf.point(coordinates, {
          html: `
          <div onclick="location.href='${intUrl}';" style="cursor: pointer;">
            <p class='interventions-map-popup'>${intName}</p>
          </div>`,
        })
      );
    }

    return turf.featureCollection(points);
  }

  private getMapOptions(data): mapboxgl.MapboxOptions | null {
    const featuresLength = data?.features?.length;
    const mapOptions: mapboxgl.MapboxOptions = {
      accessToken: tdrawConfig.mapAccessToken,
      container: 'interventions-map',
      style: tdrawConfig.mapStyle,
    };

    if (featuresLength === 0) {
      return null;
    } else if (featuresLength === 1) {
      mapOptions.center = (data.features[0].geometry as any).coordinates;
      mapOptions.zoom = tdrawConfig.interventionsMap.defaultMaxZoom;
    } else {
      mapOptions.bounds = turf.bbox(data) as any;
      mapOptions.fitBoundsOptions = {
        padding: 20,
      };
    }

    return mapOptions;
  }

  private displayDemoMap() {
    this.demoMap = true;
  }

  private async initMap(interventions: Intervention[]) {
    if (this.map) {
      this.map.remove();
    }
    const data = await this.generateMapPoints(interventions);
    const mapOptions = this.getMapOptions(data);

    if (!mapOptions) {
      return this.displayDemoMap();
    }

    this.map = new mapboxgl.Map(mapOptions);
    this.map.addControl(new mapboxgl.FullscreenControl());
    this.map.on('load', () => this.onMapLoad(data));
    this.map.on('click', this.sourceId, (e) => this.onMapPointsClick(e));
    this.map.on('mouseenter', this.sourceId, () => {
      this.map.getCanvas().style.cursor = 'pointer';
    });
    this.map.on('mouseleave', this.sourceId, () => {
      this.map.getCanvas().style.cursor = '';
    });

    // TODO: Workaround for bad map height
    setTimeout(() => {
      this.map.resize();
    }, 500);
  }

  private onMapPointsClick(e) {
    const feature = e.features[0];
    const coordinates = (feature.geometry as any).coordinates.slice();
    const html = feature.properties.html;
    new mapboxgl.Popup({ closeButton: false }).setLngLat(coordinates).setHTML(html).addTo(this.map);
  }

  private onMapLoad(data) {
    // Workaround for multiple sources existing
    if (this.map.getSource(this.sourceId)) {
      this.map.removeLayer(this.sourceId);
      this.map.removeSource(this.sourceId);
    }
    this.map.addSource(this.sourceId, {
      type: 'geojson',
      generateId: true,
      data: data,
    });

    this.map.addLayer({
      id: this.sourceId,
      type: 'circle',
      source: this.sourceId,
      layout: {
        visibility: 'visible',
      },
      filter: ['all', ['in', '$type', 'Point']],
      paint: {
        'circle-color': 'red',
        'circle-opacity': 0.5,
        'circle-radius': 15,
      },
    });
  }
}
