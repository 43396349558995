import { Action } from '@ngrx/store';
import { Assert } from 'src/app/shared';
import { DronesViewStyle } from '../../midgard-controls/enums/drone-view-style.enum';

export enum LayoutActionType {
  EditDefaultLanguage = '[Layout] Edit',
  EditDefaultTheme = '[Layout] EditDefaultTheme',
  SetDronesViewStyle = '[Layout] SetDronesViewStyle',
  SetLandingIntroShownFlag = '[Layout] SetLandingIntroShown',
  SetDroneIntroShownFlag = '[Layout] SetDroneIntroShown',
  SetFirstDroneIntroShownFlag = '[Layout] SetFirstDroneIntroShown',
}

export class EditDefaultLanguageAction implements Action {
  readonly type = LayoutActionType.EditDefaultLanguage;
  readonly language: string;

  constructor(language: string) {
    Assert.isNotNull(language, 'language');
    this.language = language;
  }
}

export class EditDefaultThemeAction implements Action {
  readonly type = LayoutActionType.EditDefaultTheme;
  readonly theme: string;

  constructor(theme: string) {
    Assert.isNotNull(theme, 'theme');
    this.theme = theme;
  }
}

export class SetDronesViewStyleAction implements Action {
  readonly type = LayoutActionType.SetDronesViewStyle;
  readonly viewStyle: DronesViewStyle;

  constructor(viewStyle: DronesViewStyle) {
    Assert.isNotNull(viewStyle, 'viewStyle');
    this.viewStyle = viewStyle;
  }
}

export class SetLandingIntroShownFlagAction implements Action {
  readonly type = LayoutActionType.SetLandingIntroShownFlag;
}

export class SetDroneIntroShownFlagAction implements Action {
  readonly type = LayoutActionType.SetDroneIntroShownFlag;
}

export class SetFirstDroneIntroShownFlagAction implements Action {
  readonly type = LayoutActionType.SetFirstDroneIntroShownFlag;
}

export type LayoutActions =
  | EditDefaultLanguageAction
  | EditDefaultThemeAction
  | SetDronesViewStyleAction
  | SetLandingIntroShownFlagAction
  | SetDroneIntroShownFlagAction
  | SetFirstDroneIntroShownFlagAction;
