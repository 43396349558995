import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { InterventionService } from 'src/app/autogenerated/interventionService';
import { ContextPermissionsDto, InterventionService as InterventionServiceV2 } from 'src/api';
import { Intervention, InterventionType } from 'src/app/autogenerated/model';
import { LoggerService } from 'src/app/core/logger.service';
import { AddInterventionComponent } from 'src/app/intervention/add-intervention/add-intervention.component';
import { OrganizationStoreService } from 'src/app/organization/store';
import { required, validate } from 'src/app/shared';
import { DataSource, DataSourceService, DataSourceState, FieldSortOrder } from 'src/app/core/collections';
import { interventionConfig } from 'src/app/intervention/intervention.config';
import { Router } from '@angular/router';
import { InterventionFilterType } from '../interventions-page/interventions-page.component';
import { Unsubscribe } from 'src/app/core/decorators';
import { MidRightPanelService } from 'src/app/midgard-controls/services/mid-right-panel.service';
import { CreateInterventionComponent } from '../dialogs/create-intervention-right-panel/create-intervention/create-intervention.component';
import { AssociateWithDroneComponent } from '../dialogs/associate-with-drone-right-panel/associate-with-drone/associate-with-drone.component';

@Unsubscribe()
@Component({
  selector: 'app-interventions-dashboard-block',
  templateUrl: './interventions-dashboard-block.component.html',
  styleUrls: ['./interventions-dashboard-block.component.scss'],
})
export class InterventionsDashboardBlockComponent implements OnInit, OnDestroy {
  organizationId: string;

  dataSource: DataSource<Intervention>;
  dataSourceState: DataSourceState<Intervention>;

  @Output()
  currentInterventionChanged: EventEmitter<Intervention>;

  private readonly startPageNumber = 1;
  private organizationSubscription: Subscription;
  private associateWithDroneSubscription: Subscription;

  state: {
    isLoading: boolean;
    isFailed: boolean;
    interventionFilterType: InterventionFilterType;
    currentInterventionIndex: number;
    isLeftArrowEnabled: boolean;
    isRightArrowEnabled: boolean;
  };
  interventionPermission: ContextPermissionsDto;

  get InterventionType() {
    return InterventionType;
  }

  get isPreviousPageExists(): boolean {
    return this.dataSourceState.page > this.startPageNumber;
  }

  get isNextPageExists(): boolean {
    return this.dataSourceState.page < (this.dataSource.filteredItems?.length || 0);
  }

  constructor(
    private readonly organizationStoreService: OrganizationStoreService,
    private readonly loggerService: LoggerService,
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private readonly interventionService: InterventionService,
    private readonly interventionServiceV2: InterventionServiceV2,
    private readonly dataSourceService: DataSourceService,
    private readonly midRightPanelService: MidRightPanelService
  ) {
    this.currentInterventionChanged = new EventEmitter();
    this.dataSource = new DataSource<Intervention>();

    this.dataSourceState = new DataSourceState<Intervention>({
      filter: '',
      searchableFields: interventionConfig.list.interventions.searchableFields,
      customFilter: (interventionModels: Intervention[]) =>
        this.filterInterventionModels(interventionModels, this.state.interventionFilterType),
      sortOrder: new FieldSortOrder(
        interventionConfig.list.interventions.listOrder.fieldName,
        interventionConfig.list.interventions.listOrder.sortOrder
      ),
      page: this.startPageNumber,
      pageSize: 1,
    });

    this.state = {
      isLoading: false,
      isFailed: false,
      interventionFilterType: 'all',
      currentInterventionIndex: 0,
      isLeftArrowEnabled: false,
      isRightArrowEnabled: false,
    };
  }

  async ngOnInit() {
    this.organizationSubscription = this.organizationStoreService
      .getCurrentOrganization()
      .subscribe(async (organization) => {
        this.organizationId = organization ? organization._id : null;
        if (!this.organizationId) {
          return;
        }

        await this.loadInterventions(this.organizationId);
        this.interventionPermission = await this.interventionServiceV2
          .organizationInterventionControllerGetPermissions(this.organizationId)
          .toPromise();
      });
  }

  onChangeInterventionType(type: InterventionFilterType): void {
    this.state.interventionFilterType = type;
    this.dataSourceState.page = 1;
    this.dataSource = this.dataSourceService.filterDataSource(this.dataSource, this.dataSourceState);
  }

  filterInterventionModels(items: Intervention[], filter: InterventionFilterType): Intervention[] {
    if (filter === 'all') {
      return items;
    }
    const resultItems = _.filter(items, (item: Intervention) => item.type === filter);
    return resultItems;
  }

  onAddIntervention(): void {
    if (!this.organizationId) {
      return;
    }

    const dialogRef = this.dialog.open(AddInterventionComponent, {
      width: '450px',
      data: { organizationId: this.organizationId, defaultInterventionType: this.state.interventionFilterType },
      closeOnNavigation: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.loadInterventions(this.organizationId);
    });
  }

  onPreviousIntervention(): void {
    this.changeDataSourcePage(this.dataSourceState.page - 1);
    this.onCurrentInterventionChanged();
  }

  onNextIntervention(): void {
    this.changeDataSourcePage(this.dataSourceState.page + 1);
    this.onCurrentInterventionChanged();
  }

  onCurrentInterventionChanged(): void {
    this.currentInterventionChanged.emit(this.dataSource.paginatedItems && this.dataSource.paginatedItems[0]);
  }

  goTo(pagePath: string) {
    this.router.navigate([pagePath]);
  }

  goToAllInterventions(): void {
    this.router.navigate([`interventions`]);
  }

  onOpenAssociateWithDrone(): void {
    const data = { intervention: this.dataSource.paginatedItems && this.dataSource.paginatedItems[0] };
    this.associateWithDroneSubscription = this.midRightPanelService
      .open(AssociateWithDroneComponent, data)
      .subscribe(() => {
        this.loadInterventions(this.organizationId);
      });
  }

  onOpenCreateIntervention(): void {
    const data = { organizationId: this.organizationId };
    this.midRightPanelService.open(CreateInterventionComponent, data);
  }

  @validate
  public async loadInterventions(@required organizationId: string, minTimeoutPromise?: Promise<void> | null) {
    this.state.isLoading = true;
    this.state.isFailed = false;

    try {
      const interventionsPromise = this.interventionService.getInterventionsWithMapping({ _id: organizationId });
      let [interventions] = await Promise.all([interventionsPromise, minTimeoutPromise]);
      // we want to show only archived interventions
      interventions = _.filter(interventions, (intervention) => !intervention.isArchived);
      this.dataSource = this.dataSourceService.createDataSource(interventions, this.dataSourceState);
    } catch (error) {
      this.state.isFailed = true;
      this.loggerService.captureException(error);
    } finally {
      this.state.isLoading = false;
    }
  }

  ngOnDestroy(): void {}

  @validate
  private changeDataSourcePage(@required page: number): void {
    let currentPageNumber = Math.min(this.dataSource.filteredItems?.length || this.startPageNumber, page); // max limit
    currentPageNumber = Math.max(this.startPageNumber, currentPageNumber); // min limit

    this.dataSourceState.page = currentPageNumber;
    this.dataSource = this.dataSourceService.filterDataSource(this.dataSource, this.dataSourceState);
  }
}
