<div class="media-container">
  <div class="media-wrapper">
    <div class="edit-panel">
      <div class="labels">
        <div class="d-flex align-items-center">
          <app-mid-icon-button type="primary" class="mr-1" (action)="close()">
            <i class="fas fa-times"></i>
          </app-mid-icon-button>
          <div
            [matTooltip]="interventionName"
            class="intervention-name d-flex justify-content-start align-items-center"
            [class.invisible]="!interventionName"
          >
            <span class="d-none d-md-inline mr-1">{{ 'ASSIGNED_TO' | translate }}:</span>
            <span class="text-truncate">{{ interventionName }}</span>
          </div>
        </div>
        <div class="d-none d-lg-flex justify-content-center align-items-center" [matTooltip]="media?.name">
          <ng-container [ngSwitch]="media?.mediaType">
            <i *ngSwitchCase="mediaType.video" class="fas fa-video mr-2"></i>
            <i *ngSwitchCase="mediaType.picture" class="far fa-image mr-2"></i>
          </ng-container>
          <span class="text-truncate filename">{{ media?.name }}</span>
        </div>
        <div class="d-flex align-items-center">
          <div class="d-flex justify-content-center mr-2">
            <div class="d-flex justify-content-end align-items-center mr-2">
              <span class="d-md-none">{{ media?.creationDate | date : dateTimeFormat }}</span>
              <span class="d-none d-md-inline"
                >{{ 'TAKEN_ON' | translate }}:
                {{ media?.creationDate | date : longDateTimeFormat : undefined : translate.getDefaultLang() }}</span
              >
            </div>
            <!-- index -->
            <div class="d-none d-lg-block index-media" *ngIf="navInfo">
              <span>{{ navInfo.index }} {{ 'OF_AS_ONE_OF_x' | translate }} {{ navInfo.total }}</span>
            </div>
          </div>
          <button class="media-fixed-btn" [matMenuTriggerFor]="actionsMenu">
            <i class="fas fa-ellipsis-v"></i>
          </button>
        </div>
      </div>
      <mat-menu #actionsMenu="matMenu" class="mid-menu">
        <div class="mid-menu-item" (click)="downloadMedia()">
          <i class="fas fa-download mr-1 mid-menu-item-icon"></i>
          <span>{{ 'DOWNLOAD' | translate }}</span>
        </div>
        <div *ngIf="media?.mediaType === mediaType.picture" class="mid-menu-item" (click)="openPrintDialog()">
          <i class="fas fa-print mr-1 mid-menu-item-icon"></i>
          <span>{{ 'PRINT' | translate }}</span>
        </div>
      </mat-menu>
    </div>

    <div class="media-content-wrapper">
      <div class="media-content-container" [class.media-placeholder]="isEmpty(mediaUrl)" [ngSwitch]="media?.mediaType">
        <!-- media content -->
        <!-- picture -->
        <app-media-picture
          *ngSwitchCase="mediaType.picture"
          class="media-content"
          [mediaUrl]="mediaUrl"
        ></app-media-picture>
        <!-- video -->
        <app-media-video
          *ngSwitchCase="mediaType.video"
          class="media-content"
          [mediaUrl]="mediaUrl"
          [previewUrl]="previewUrl"
          [streamTime]="startStreamDate"
        ></app-media-video>
        <!-- before -->
        <div *ngIf="navInfo?.previousMedia" (click)="goToPreviousMedia()" class="media-navigation-left">
          <i class="fas fa-chevron-left"></i>
        </div>
        <!-- next -->
        <div *ngIf="navInfo?.nextMedia" (click)="goToNextMedia()" class="media-navigation-right">
          <i class="fas fa-chevron-right"></i>
        </div>
      </div>
      <div class="d-fixed d-lg-none media-footer">
        <div [matTooltip]="media?.name" class="media-filename text-truncate">
          <ng-container [ngSwitch]="media?.mediaType">
            <i *ngSwitchCase="mediaType.video" class="fas fa-video mr-2"></i>
            <i *ngSwitchCase="mediaType.picture" class="far fa-image mr-2"></i>
          </ng-container>
          <span class="pl-2">{{ media?.name }}</span>
        </div>
        <!-- index -->
        <div class="d-lg-none index-media" *ngIf="navInfo">
          <span>{{ navInfo.index }} {{ 'OF_AS_ONE_OF_x' | translate }} {{ navInfo.total }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
<app-media-right-panel [media]="media" [type]="RightPanelType.toggle"></app-media-right-panel>
