import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DroneService } from 'src/app/autogenerated/droneService';
import { Drone, Intervention, Organization, addDroneOutput } from 'src/app/autogenerated/model';
import { Unsubscribe } from 'src/app/core/decorators';
import { DroneModel } from 'src/app/drone/models/drone-model.enum';
import { droneModels } from 'src/app/drone/models/drone-models.constant';
import { DroneStoreService } from 'src/app/drone/store';
import { MidRightPanelService } from 'src/app/midgard-controls/services/mid-right-panel.service';
import { MidSelectItemUtils } from 'src/app/midgard-controls/utils/mid-select-item-utils';
import { OrganizationStoreService } from 'src/app/organization/store';
import { InterventionService } from '../../../../autogenerated/interventionService';
import { MidSelectItem } from '../../../../midgard-controls/models';

@Unsubscribe()
@Component({
  selector: 'app-create-drone',
  templateUrl: './create-drone.component.html',
  styleUrls: ['./create-drone.component.scss'],
})
export class CreateDroneComponent implements OnInit, OnChanges, OnDestroy {
  organization: Organization;
  organizationId: string;
  selectedIntervention: Intervention;

  // state: {
  //   isLoading: boolean;
  //   isFailed: boolean;
  // };
  errorMessage: string;

  data: {
    drone: Drone;
    droneModel: MidSelectItem[];
  };

  readonly DroneModel = DroneModel;

  private organizationSubscription: Subscription;

  constructor(
    private readonly translate: TranslateService,
    private readonly organizationStoreService: OrganizationStoreService,
    private readonly droneService: DroneService,
    private readonly droneStoreService: DroneStoreService,
    private readonly interventionService: InterventionService,
    private readonly midRightPanelService: MidRightPanelService
  ) {
    this.data = {
      drone: new Drone(),
      droneModel: MidSelectItemUtils.fromMap(droneModels),
    };
  }

  async ngOnInit(): Promise<void> {
    const indexOther = this.data.droneModel.findIndex((it) => it.value === DroneModel.Other);
    this.data.droneModel[indexOther].name = await this.translate.get('DIALOGS.CREATE_DRONE.MODEL_OTHER').toPromise();
    this.organizationSubscription = this.organizationStoreService
      .getCurrentOrganization()
      .subscribe(async (organization) => {
        if (!organization || this.organization === organization || organization?._id === this.organizationId) {
          return;
        }
        this.organization = organization;
        this.organizationId = organization._id;
        await this.loadData();
      });
  }

  ngOnDestroy(): void {}

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    await this.loadData();
  }

  close(): void {
    this.midRightPanelService.close();
    this.organizationSubscription?.unsubscribe();
  }

  getSelectedIntervention(selectedIntervention: Intervention): void {
    this.selectedIntervention = selectedIntervention;
  }

  /**
   * Create a new drone
   */
  async onCreate(): Promise<void> {
    // this.state.isLoading = true;
    // this.state.isFailed = false;

    if (!this.data.drone.organization) {
      this.data.drone.organization = this.organization;
    }

    try {
      const res: addDroneOutput = await this.droneService.addDrone(this.data.drone);
      const drone: Drone = res.drone as Drone;
      this.droneStoreService.addDrone(this.organization._id, drone);
      if (res.success && this.selectedIntervention?._id) {
        await this.interventionService.attachDroneToInterventionV1(
          this.organizationId,
          this.selectedIntervention._id,
          drone._id
        );
      }
      this.close();
    } catch (ex) {
      this.errorMessage = ex.message;
      // this.state.isFailed = true;
      console.log(ex.message);
    } finally {
      // this.state.isLoading = false;
    }
  }

  /**
   * Load data
   */
  private async loadData() {
    if (!this.organization) {
      return;
    }

    this.data.drone.organization = this.organization;

    // TODO: get interventions for linking created done
  }
}
