import { Intervention } from 'src/app/autogenerated/model';
import { SortOrder } from 'src/app/core/collections';

export const midSelectInterventionComboboxConfig = {
  searchableFields: ['name'] as FieldsOf<Intervention>,
  sortOrder: {
    fieldName: ['isClosed', 'isArchived', 'updatedDate'] as FieldsOf<Intervention>,
    sortOrder: [SortOrder.asc, SortOrder.asc, SortOrder.desc],
  },
};
