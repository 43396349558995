import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import mapboxgl from 'mapbox-gl';
import { TdrawService } from 'src/app/autogenerated/tdrawService';
import { OrganizationStoreService } from 'src/app/organization/store';
import { tdrawConfig } from '../../tdraw.config';
import { MapState } from '../../models/map-state.model';
import { MapStateUpdateDto } from '../../models/dto';
import _ from 'lodash';
import CoordinatesHelpers from '../../misc/coordinates-helpers';
import { MatDialog } from '@angular/material/dialog';
import { Unsubscribe } from 'src/app/core/decorators';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { RightPanelData, RightPanelMode } from '../../models/right-panel-data';
import { interventionConfig } from 'src/app/intervention/intervention.config';
import { MapIconsService } from '../../services/map-icons.service';
import { CircleType } from '../../models/circle-type.enum';
import { EditRegularMarkerModalComponent } from '../../components/modals/edit-regular-marker-modal/edit-regular-marker-modal.component';
import { PicManager } from '../../misc/pic-manager';
import { MapIcon } from '../../models/map-icon';
import { UpdateInterventionDto } from '../../../autogenerated/model';
import { UpdateStatisticsModalComponent } from '../../components/modals/update-statistics-modal/update-statistics-modal.component';
import { InterventionService } from '../../../autogenerated/interventionService';
import { InterventionStoreService } from '../../../intervention/store';
import { EditModeManager } from '../../misc/edit-mode-manager';
import { ExportMapStateModalComponent } from '../../components/modals/export-mapstate-modal/export-mapstate-modal.component';

@Unsubscribe()
@Component({
  selector: 'app-tdraw-pic-edit-mode-page',
  templateUrl: './tdraw-pic-edit-mode-page.component.html',
  styleUrls: ['./tdraw-pic-edit-mode-page.component.scss'],
})
export class TdrawPicEditModePageComponent implements OnInit, OnDestroy {
  // Input
  interventionId: string;
  organizationId: string;
  mapStateId: string;

  // Map
  map: mapboxgl.Map;
  date = new Date();
  picManager: PicManager;
  editModeManager: EditModeManager;

  // State
  mapState: MapState;
  rightPanelData: RightPanelData = { mode: RightPanelMode.Navigation };

  // Subscriptions
  private organizationSubscription: Subscription;
  private featureSelectedChangedSubscription: Subscription;
  private featureUnselectedSubscription: Subscription;

  // Enums
  get RightPanelMode() {
    return RightPanelMode;
  }

  constructor(
    readonly translate: TranslateService,
    activatedRoute: ActivatedRoute,
    private router: Router,
    private organizationStoreService: OrganizationStoreService,
    private tdrawService: TdrawService,
    private dialog: MatDialog,
    private mapIconsService: MapIconsService,
    private interventionService: InterventionService,
    private interventionStoreService: InterventionStoreService
  ) {
    this.mapStateId = activatedRoute.snapshot.data.mapStateId;
    this.interventionId = activatedRoute.snapshot.data.interventionId;

    this.organizationSubscription = this.organizationStoreService
      .getCurrentOrganization()
      .subscribe(async (organization) => {
        if (!organization || this.organizationId === organization._id) {
          return;
        }
        this.organizationId = organization._id;
      });
  }

  async ngOnInit() {
    this.mapState = await this.tdrawService.getMapState(this.organizationId, this.interventionId, this.mapStateId);
    this.initMap();
  }

  ngOnDestroy(): void {}

  close() {
    this.router.navigate([`interventions/${this.interventionId}/${interventionConfig.routes.tdraw}/home`]);
  }

  private exitPropertiesPanel() {
    if (this.rightPanelData.mode !== RightPanelMode.Hidden) {
      this.rightPanelData.mode = RightPanelMode.Navigation;
    }
  }

  setRightPanelMode(mode: RightPanelMode) {
    this.rightPanelData.mode = mode;
  }

  private async loadData() {
    this.picManager.loadDataOnMap(this.editModeManager, false);
  }

  async printSnapshot() {
    this.editModeManager.mapManager.printSnapshot(true);
  }

  async downloadSnapshot() {
    this.editModeManager.mapManager.downloadSnapshot(true);
  }

  async saveData() {
    this.editModeManager.saveCurrentDrawingIfAny();

    const updateDto = new MapStateUpdateDto({
      name: this.mapState.name,
      backgroundMedia: {
        mediaId: this.mapState.backgroundMedia.mediaId,
        coordinates: this.picManager.getBackgroundMediaCoordinates(),
      },
      annotations: _.cloneDeep(this.mapState.annotations),
      regularMarkers: this.editModeManager.mapManager.regularMarkers,
      statistics: this.mapState.statistics,
    });

    try {
      await this.tdrawService.updateMap(this.organizationId, this.interventionId, this.mapStateId, updateDto);
    } catch (e) {
      console.error(e);
    }

    this.setRightPanelMode(RightPanelMode.Hidden);
  }

  async publishHistory() {
    const dialogRef = this.dialog.open(UpdateStatisticsModalComponent, {
      id: 'tdrawUpdateStatisticsModal',
      width: '450px',
    });
    const confirm = await dialogRef.afterClosed().toPromise();
    if (confirm) {
      await this.onUpdateStatistics();
    }

    await this.saveData();

    const snapshot = await this.editModeManager.mapManager.takeSnapshot(true);
    await this.tdrawService.publishMap(this.organizationId, this.interventionId, this.mapStateId, snapshot);

    this.router.navigate([
      `interventions/${this.interventionId}/${interventionConfig.routes.tdraw}/pic/${this.mapState._id}/view`,
    ]);
  }

  private async onUpdateStatistics() {
    this.interventionService.getInterventionV1(this.organizationId, this.mapState.interventionId).then(async (data) => {
      data.statistics = this.mapState.statistics;
      try {
        const intervention = await this.interventionService.updateInterventionV1(
          this.organizationId,
          this.mapState.interventionId,
          data as UpdateInterventionDto
        );
        this.interventionStoreService.updateIntervention(this.organizationId, intervention);
      } catch (e) {
        console.log(e);
      }
    });
  }

  drawCone() {
    this.onDrawCone(20);
  }

  markerCreationSelection() {
    const dialogRef = this.dialog.open(EditRegularMarkerModalComponent, {
      width: '900px',
    });
    dialogRef.afterClosed().subscribe((content: string) => {
      if (!content) {
        return;
      }
      this.onDrawRegularMarker(content);
    });
  }

  openExportModal() {
    this.dialog.open(ExportMapStateModalComponent, {
      width: '900px',
      data: {
        mapState: this.mapState,
      },
    });
  }

  private initMap() {
    // Be sure that all projections we use are loaded in proj4
    CoordinatesHelpers.registerProjections();

    this.map = new mapboxgl.Map({
      accessToken: tdrawConfig.mapAccessToken,
      container: 'map-pic-edit',
      style: tdrawConfig.mapStyle,
      center: tdrawConfig.defaultMapCenter,
      zoom: 13,
    });
    this.map.addControl(new mapboxgl.NavigationControl(), 'bottom-left');

    this.picManager = new PicManager(this.map);
    this.editModeManager = new EditModeManager(this.map, this.mapState);

    // Listeners
    this.map.on('load', () => this.onMapLoad());
    this.featureSelectedChangedSubscription = this.editModeManager.onFeatureSelectedChanged((data) => {
      this.setRightPanelMode(RightPanelMode.Properties);
    });
    this.featureUnselectedSubscription = this.editModeManager.onFeatureUnselected(() => {
      this.exitPropertiesPanel();
    });
  }

  private onMapLoad() {
    this.mapIconsService.loadImages(this.map);

    // Wait for map prerequisites to be fully loaded before loading data
    this.map.once('idle', () => this.loadData());
  }

  onDeleteSource() {
    this.editModeManager.deleteSelectedSource();
  }

  private onDrawRegularMarker(content: string) {
    this.editModeManager.startDrawingRegularMarker(content);
  }

  private onDrawCone(wind: number) {
    this.editModeManager.startDrawingCone(wind);
  }

  changeDrawnConeWind(wind) {
    this.editModeManager.changeCurrentConeWind(wind);
  }

  onDrawCircle(type: CircleType) {
    this.editModeManager.startDrawingCircle(type);
  }

  onDrawAllCircles() {
    this.editModeManager.startDrawingAllCircles();
  }

  onDrawIcon(icon: MapIcon) {
    this.editModeManager.startDrawingIcon(icon);
  }

  onDrawLine() {
    this.editModeManager.startDrawingLine();
  }

  onDrawPolygon() {
    this.editModeManager.startDrawingPolygon();
  }
}
