import {
  Component,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { OrganizationGroupService } from 'src/api';
import { MediaService } from 'src/app/autogenerated/mediaService';
import { Intervention, OrganizationMedia } from 'src/app/autogenerated/model';
import { DataSource, DataSourceService, DataSourceState, FieldSortOrder, SortOrder } from 'src/app/core/collections';
import { MediaPageLocation } from 'src/app/midgard-controls/enums/media-page-location.enum';
import { OrganizationStoreService } from 'src/app/organization/store';
import { required, validate } from 'src/app/shared';
import { MediaUploadService } from '../media-upload/media-upload.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

enum MediaDashboardBehavior {
  default,
  importMedia,
}

@UntilDestroy()
@Component({
  selector: 'app-media-dashboard-block',
  templateUrl: './media-dashboard-block.component.html',
  styleUrls: ['./media-dashboard-block.component.scss'],
})
export class MediaDashboardBlockComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  intervention: Intervention;

  organizationId: string;
  canDelete = false;

  dataSource: DataSource<OrganizationMedia>;
  dataSourceState: DataSourceState<OrganizationMedia>;
  dataSourceLength = 0;

  dashboardBehavior$ = new BehaviorSubject(MediaDashboardBehavior.default);

  state: {
    isLoading: boolean;
    isFailed: boolean;
    currentInterventionIndex: number;
    isLeftArrowEnabled: boolean;
    isRightArrowEnabled: boolean;
  };

  private readonly startPageNumber = 1;
  private readonly minSingleViewportWidth = 1320; // 992;

  get DashboardBehavior() {
    return MediaDashboardBehavior;
  }

  get isPreviousPageExists(): boolean {
    return this.dataSourceState.page > this.startPageNumber;
  }

  get isNextPageExists(): boolean {
    return this.dataSourceState.page * this.dataSourceState.pageSize < (this.dataSource.filteredItems?.length || 0);
  }

  get MediaPageLocation() {
    return MediaPageLocation;
  }

  constructor(
    private readonly organizationStoreService: OrganizationStoreService,
    private router: Router,
    private dataSourceService: DataSourceService,
    private mediaService: MediaService,
    private organizationGroupService: OrganizationGroupService,
    private mediaUploadService: MediaUploadService
  ) {
    this.dataSource = new DataSource<OrganizationMedia>();

    const pageSize = this.calculatePageSize(document.documentElement.clientWidth);

    this.dataSourceState = new DataSourceState<OrganizationMedia>({
      filter: '',
      searchableFields: ['creationDate'],
      sortOrder: new FieldSortOrder('creationDate', SortOrder.desc),
      page: this.startPageNumber,
      pageSize: pageSize,
    });

    this.state = {
      isLoading: false,
      isFailed: false,
      currentInterventionIndex: 0,
      isLeftArrowEnabled: false,
      isRightArrowEnabled: false,
    };

    this.dashboardBehavior$.subscribe();
  }

  ngOnInit(): void {
    this.organizationStoreService
      .getCurrentOrganization()
      .pipe(untilDestroyed(this))
      .subscribe(async (organization) => {
        this.organizationId = organization ? organization._id : null;
        if (!this.organizationId) {
          return;
        }

        this.canDelete = await firstValueFrom(
          this.organizationGroupService.orgOrganizationGroupControllerIsOrgAdmin(this.organizationId)
        );

        await this.tryToLoadMedia(this.organizationId);
      });

    this.mediaUploadService.mediaUploadFinished
      .pipe(untilDestroyed(this))
      .subscribe(() => this.tryToLoadMedia(this.organizationId));
  }

  ngOnDestroy(): void {
    this.dashboardBehavior$?.unsubscribe();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const componentChanges = changes as PropertyMap<MediaDashboardBlockComponent, SimpleChange>;
    const interventionChange = componentChanges.intervention;
    const hasInterventionIdChanges =
      interventionChange?.currentValue?._id &&
      interventionChange?.currentValue?._id !== interventionChange?.previousValue?._id;
    if (!hasInterventionIdChanges) {
      return;
    }
    if (this.organizationId) {
      await this.tryToLoadMedia(this.organizationId);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.dataSourceState.pageSize = this.calculatePageSize(document.documentElement.clientWidth);
    this.changeDataSourcePage(this.dataSourceState.page);
  }

  onPreviousMedia() {
    this.changeDataSourcePage(this.dataSourceState.page - 1);
  }

  onNextMedia() {
    this.changeDataSourcePage(this.dataSourceState.page + 1);
  }

  goToAllMedia() {
    if (this.intervention?._id) {
      this.router.navigate(['interventions', this.intervention?._id, 'media']);
    } else {
      this.router.navigate([`media`]);
    }
  }

  async onMediaDeleted(mediaId: string) {
    try {
      await this.mediaService.deleteMedia({ _id: mediaId });
      await this.tryToLoadMedia(this.organizationId);
    } catch (error) {
      console.error(error);
    }
  }

  @validate
  private async tryToLoadMedia(@required organizationId: string): Promise<void> {
    if (!this.intervention) {
      return;
    }
    await this.loadMedia(organizationId);
  }

  @validate
  private async loadMedia(@required organizationId: string, minTimeoutPromise?: Promise<void> | null): Promise<void> {
    this.state.isLoading = true;
    this.state.isFailed = false;

    try {
      const mediaPromise = this.mediaService.getMediaV1(organizationId, {
        interventionId: this.intervention._id,
        limit: 8,
        offset: 0,
      });
      const [media] = await Promise.all([mediaPromise, minTimeoutPromise]);
      this.dataSourceLength = media.count;
      this.dataSource = this.dataSourceService.createDataSource(media.results, this.dataSourceState);
      this.updateDashboardBehavior();
    } catch (error) {
      this.state.isFailed = true;
    } finally {
      this.state.isLoading = false;
    }
  }

  private updateDashboardBehavior(): MediaDashboardBehavior {
    if (!this.dataSourceLength) {
      this.dashboardBehavior$.next(MediaDashboardBehavior.importMedia);
      return;
    }
    this.dashboardBehavior$.next(MediaDashboardBehavior.default);
  }

  @validate
  private changeDataSourcePage(@required page: number): void {
    let currentPageNumber = Math.min(this.dataSource.filteredItems?.length || this.startPageNumber, page); // max limit
    currentPageNumber = Math.max(this.startPageNumber, currentPageNumber); // min limit

    this.dataSourceState.page = currentPageNumber;
    this.dataSource = this.dataSourceService.filterDataSource(this.dataSource, this.dataSourceState);
  }

  private calculatePageSize(viewportWidth: number): number {
    const pageSize = viewportWidth < this.minSingleViewportWidth ? 1 : 2;
    return pageSize;
  }
}
