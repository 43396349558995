<div class="activate-page-container">
  <div class="activate-picture"></div>
  <div class="activate-form-container container-fluid">
    <app-recover-password-form
      [email]="data.email"
      [code]="data.code"
      (passwordChanged)="onPasswordResetCompleted($event)"
      (navigateToHome)="onNavigateToHome()"
      (canceled)="onCanceled()"
    ></app-recover-password-form>
  </div>
</div>
