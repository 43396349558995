<div class="input-with-hint">
  <mat-form-field class="w-100 intervention-form" floatLabel="never">
    <mat-label class="label"> {{ 'SELECT_INTERVENTION' | translate }}</mat-label>
    <input
      type="text"
      #selectIntervention="ngModel"
      matInput
      (input)="onFilterChanged($event.target.value)"
      (focus)="onFilterChanged('')"
      [(ngModel)]="data.fieldText"
      [matAutocomplete]="autoGroup"
    />
    <mat-icon matSuffix svgIcon="arrows"></mat-icon>

    <mat-autocomplete #autoGroup="matAutocomplete">
      <mat-option
        *ngFor="let item of dataSource.filteredItems; trackBy: trackByItem"
        [class.mat-active]="item === data.selectedIntervention"
        [value]="item.name"
        (onSelectionChange)="onFieldSelected(item, $event)"
      >
        <div class="d-flex justify-content-between">
          <span class="text-truncate">{{ item.name }}</span>
          <span class="badge mt-2">
            <ng-container [ngSwitch]="item.type">
              <app-mid-badge *ngSwitchCase="InterventionType.OPS" badgeClass="badge-danger">{{
                'INTERVENTION_TYPE.OPS' | translate
              }}</app-mid-badge>
              <app-mid-badge *ngSwitchCase="InterventionType.PRV" badgeClass="badge-success">{{
                'INTERVENTION_TYPE.PRV' | translate
              }}</app-mid-badge>
              <app-mid-badge *ngSwitchCase="InterventionType.COM" badgeClass="badge-warning">{{
                'INTERVENTION_TYPE.COM' | translate
              }}</app-mid-badge>
              <app-mid-badge *ngSwitchCase="InterventionType.TRAINING" badgeClass="badge-success">{{
                'INTERVENTION_TYPE.TRAINING' | translate
              }}</app-mid-badge>
            </ng-container>
          </span>
        </div>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <ng-container *ngIf="!isHiddenHint">
    <ng-container [ngSwitch]="hintState">
      <div class="hint" *ngSwitchCase="HintState.emptyInput">
        <mat-icon svgIcon="caution"></mat-icon>
        <mat-hint class="select-intervention-mat-hint"> {{ 'INTERVENTION_NOT_SELECTED' | translate }}</mat-hint>
      </div>
      <div class="hint" *ngSwitchCase="HintState.notFoundIntervention">
        <mat-icon svgIcon="caution"></mat-icon>
        <mat-hint class="select-intervention-mat-hint"> {{ 'INTERVENTION_NOT_FOUND' | translate }}</mat-hint>
      </div>
    </ng-container>
  </ng-container>
</div>
