import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DroneService } from 'src/app/autogenerated/droneService';
import { DroneService as DroneServiceV2 } from 'src/api';
import { Drone, Intervention, UpdateDroneInput } from 'src/app/autogenerated/model';
import { OrganizationService } from 'src/app/autogenerated/organizationService';
import { DroneStoreService } from '../../../../drone/store';
import { Unsubscribe } from '../../../../core/decorators';
import { Subscription } from 'rxjs';
import _, { cloneDeep } from 'lodash';
import { OrganizationStoreService } from '../../../../organization/store';
import { MidRightPanelService } from 'src/app/midgard-controls/services/mid-right-panel.service';
import { MID_RIGHT_PANEL_DATA } from 'src/app/midgard-controls/services/dom.service';

export interface AssociateWithDroneInput {
  intervention: Intervention;
}

@Unsubscribe()
@Component({
  selector: 'app-associate-with-drone',
  templateUrl: './associate-with-drone.component.html',
  styleUrls: ['./associate-with-drone.component.scss'],
})
export class AssociateWithDroneComponent implements OnInit, OnDestroy {
  intervention: Intervention;

  selectedDroneId: string;
  drones: Drone[];
  organizationId: string;

  state: {
    isLoading: boolean;
    isFailed: boolean;
  };

  private dronesSubscription: Subscription;
  private organizationSubscription: Subscription;

  constructor(
    private readonly organizationService: OrganizationService,
    private readonly organizationStoreService: OrganizationStoreService,
    private readonly droneService: DroneService,
    private readonly droneServiceV2: DroneServiceV2,
    private readonly droneStoreService: DroneStoreService,
    private readonly midRightPanelService: MidRightPanelService,
    @Inject(MID_RIGHT_PANEL_DATA) inputtingData: AssociateWithDroneInput
  ) {
    this.intervention = inputtingData.intervention;
    this.state = {
      isLoading: false,
      isFailed: false,
    };
  }

  async ngOnInit() {
    this.organizationSubscription = this.organizationStoreService.getCurrentOrganization().subscribe((organization) => {
      this.organizationId = organization ? organization._id : null;
      if (!this.organizationId) {
        return;
      }
      this.updateDroneSubscription(organization._id);
      this.loadDrones(organization._id);
    });
  }

  onSelectDrone(drone: Drone): void {
    this.selectedDroneId = drone._id;
  }

  async associate(): Promise<void> {
    try {
      const droneInput = new UpdateDroneInput({ id: this.selectedDroneId, currentIntervention: this.intervention._id });
      const updatedDrone = await this.droneService.updateDrone(droneInput);
      this.droneStoreService.updateDrone(this.organizationId, updatedDrone);
      this.selectedDroneId = '';
      this.close();
    } catch (error) {
      console.error(error);
    }
  }

  checkBeforeAssociate() {
    return !this.selectedDroneId && !this.state.isLoading;
  }

  close(): void {
    this.midRightPanelService.close();
  }

  ngOnDestroy(): void {}

  private async loadDrones(organizationId: string): Promise<void> {
    this.state.isLoading = true;
    this.state.isFailed = false;

    try {
      const drones = await this.droneServiceV2.orgDroneControllerGetOrgDrones(organizationId).toPromise();
      this.droneStoreService.setOrganizationDrones(organizationId, _.cloneDeep(drones));
    } catch (error) {
      this.state.isFailed = true;
    } finally {
      this.state.isLoading = false;
    }
  }

  private updateDroneSubscription(organizationId: string) {
    if (!organizationId) {
      return;
    }

    if (this.dronesSubscription) {
      this.dronesSubscription.unsubscribe();
      this.dronesSubscription = null;
    }

    this.dronesSubscription = this.droneStoreService.getOrganizationDrones(organizationId).subscribe((drones) => {
      this.drones = cloneDeep(drones);
    });
  }
}
