import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss'],
})
export class ModalHeaderComponent implements OnInit {
  @Input()
  text: string;

  @Output()
  closeAction: EventEmitter<any>;

  constructor() {
    this.closeAction = new EventEmitter();
  }

  ngOnInit(): void {}

  onClose(event) {
    this.closeAction.emit(event);
  }
}
