<button
  [class.disabled]="disabled"
  [class.primary]="type === MidButtonType.primary"
  [class.secondary]="type === MidButtonType.secondary"
  [class.destructive]="type === MidButtonType.destructive"
  [class.wide]="size === MidButtonSize.wide"
  [class.small]="size === MidButtonSize.small"
  (click)="onClick($event)"
>
  <i *ngIf="icon" [ngClass]="icon" class="mr-1"></i>
  <ng-content></ng-content>
</button>
