import { LngLat } from 'mapbox-gl';

export class NewGisVersionData {
  name: string;
  center: LngLat;
  zoom: number;

  constructor({ name, center, zoom }: Partial<NewGisVersionData> = {}) {
    this.name = name;
    this.center = center;
    this.zoom = zoom;
  }
}
