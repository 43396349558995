<div class="container">
  <div class="row">hello admin</div>
  <div class="row">
    <app-new-user></app-new-user>
  </div>
  <div class="row">
    test front end error logging
    <div class="btn btn-primary" (click)="raiseError()">crash!</div>
  </div>
  <div class="row">
    test backend end error logging
    <div class="btn btn-primary" (click)="raiseBeError()">crash!</div>
  </div>
</div>
