import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { OrganizationsService } from 'src/api';
import { InterventionService } from 'src/app/autogenerated/interventionService';
import { FilterName, InterventionFilterParametersDto } from 'src/app/autogenerated/model';
import { FeaturesActivation, OrganizationFeaturesActivation } from '../organizations.component';

@Component({
  selector: 'app-features-dialog',
  templateUrl: './features-dialog.component.html',
  styleUrls: ['./features-dialog.component.scss'],
})
export class FeaturesDialogComponent implements OnInit {
  public enable: string = '';
  public disable: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { org: OrganizationFeaturesActivation },
    private readonly translate: TranslateService,
    private readonly organizationsService: OrganizationsService,
    private interventionService: InterventionService
  ) {}

  async ngOnInit(): Promise<void> {
    this.enable = await this.translate.get('COMMON_DISABLE').toPromise();
    this.disable = await this.translate.get('COMMON_ENABLE').toPromise();
  }

  ngOnDestroy(): void {}

  async toggleFeature(event: boolean, feature: FeaturesActivation) {
    this.data.org.features = this.data.org.features.map((f) => {
      if (f.featureId == feature.featureId) {
        f.isEnabled = event;
      }
      return f;
    });
    if (event) {
      await this.organizationsService
        .organizationControllerActivateFeature({ id: feature.featureId }, this.data.org.id)
        .toPromise();
    } else {
      await this.organizationsService
        .organizationControllerDeactivateFeature({ id: feature.featureId }, this.data.org.id)
        .toPromise();
    }

    //disable features for all intervention, in case feature was already activated
    let filterName: FilterName = undefined;
    for (const name in FilterName) {
      if (name == feature.featureId) {
        filterName = name as FilterName;
      }
    }
    if (filterName && !event) {
      const dtoFilterParameters: InterventionFilterParametersDto = {
        filtersParameters: [{ name: filterName, active: false, applied: false }],
      };
      const interventions = await this.interventionService.getInterventionsWithMapping(this.data.org);
      for (const intervention of interventions) {
        await this.interventionService.updateInterventionFilterParametersV1(
          this.data.org.id,
          intervention._id,
          dtoFilterParameters
        );
      }
    }
  }
}
