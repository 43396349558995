import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MidSelectItem } from 'src/app/midgard-controls/models';
import { MapStateExporter } from 'src/app/tdraw/misc/mapstate-exporter';
import { MapState } from 'src/app/tdraw/models/map-state.model';

@Component({
  selector: 'tdraw-export-mapstate-modal',
  templateUrl: './export-mapstate-modal.component.html',
  styleUrls: ['./export-mapstate-modal.component.scss'],
})
export class ExportMapStateModalComponent {
  private mapState: MapState;

  exportOptions: MidSelectItem[] = [
    {
      name: 'KML',
      value: 'KML',
      isSelected: true,
    },
    {
      name: 'GeoJSON',
      value: 'GeoJSON',
      isSelected: false,
    },
  ];

  exportOptionSelected = 'KML';

  constructor(
    private dialogRef: MatDialogRef<ExportMapStateModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.mapState = data.mapState;
  }

  export() {
    const mapStateExporter = new MapStateExporter(this.mapState);

    switch (this.exportOptionSelected) {
      case 'KML':
        mapStateExporter.downloadKML();
        return;
      case 'GeoJSON':
        mapStateExporter.downloadGeoJSON();
    }
  }

  onClose() {
    this.dialogRef.close();
  }
}
