<div class="settings-container">
  <form name="formUpdateIntervention" #formUpdateIntervention="ngForm" novalidate>
    <div class="row m-0">
      <section class="section-form-group col-md-12">
        <h2 class="section-header">{{ 'INTERVENTION_SETTINGS.SECTION_GENERAL_INFO' | translate }}</h2>
        <div class="row">
          <div class="col-md-8">
            <div class="row">
              <app-select-intervention-type-button-toggles
                class="col-md-12"
                [(type)]="data.intervention.type"
              ></app-select-intervention-type-button-toggles>

              <app-mid-input
                class="col-md-6"
                size="wide"
                [(value)]="data.intervention.name"
                #name="appMidInput"
                name="name"
                [required]="true"
                [label]="'INTERVENTION_NAME' | translate"
                [placeholder]="'INTERVENTION_SETTINGS.PLACEHOLDER_NAME' | translate"
              >
                <span *ngIf="!formUpdateIntervention.pristine && name.invalid" class="text-danger font-weight-bold">{{
                  'COMMON_REQUIRED_FIELD' | translate
                }}</span>
              </app-mid-input>

              <app-mid-input
                class="col-md-6"
                size="wide"
                [(value)]="data.intervention.interventionNumber"
                #interventionNumber="appMidInput"
                name="interventionNumber"
                [label]="'INTERVENTION_NUMBER' | translate"
                [placeholder]="'INTERVENTION_SETTINGS.PLACEHOLDER_NUMBER' | translate"
              ></app-mid-input>

              <app-mid-input
                class="col-md-6"
                size="wide"
                [(value)]="data.intervention.city"
                #city="appMidInput"
                name="city"
                [label]="'INTERVENTION_CITY' | translate"
                [placeholder]="'INTERVENTION_SETTINGS.PLACEHOLDER_CITY' | translate"
              >
                <!-- [required]="true" -->
                <!--
                <span *ngIf="!formUpdateIntervention.pristine && city.invalid">{{
                  'COMMON_REQUIRED_FIELD' | translate
                }}</span>
                -->
              </app-mid-input>
            </div>
          </div>

          <div class="col-md-4">
            <app-mid-input-date-time
              size="wide"
              [(value)]="data.intervention.startDate"
              #startDate="appMidInputDateTime"
              name="startDate"
              [required]="true"
              [label]="'INTERVENTION_START_DATE' | translate"
            >
              <span
                *ngIf="!formUpdateIntervention.pristine && startDate.invalid"
                class="text-danger font-weight-bold"
                >{{ 'COMMON_REQUIRED_FIELD' | translate }}</span
              >
            </app-mid-input-date-time>
            <!--
            <p class="section-text">
              {{ 'INTERVENTION_START_DATE_INFO' | translate }}<br/>
              {{ data.intervention.startDate | date : formatDateTime }}
            </p>
            -->
          </div>
        </div>
      </section>

      <div class="row m-0 p-0 col-md-12">
        <div class="col-md-8 p-0">
          <section class="section-form-group col-md-12">
            <h2 class="section-header">{{ 'INTERVENTION_SETTINGS.SECTION_ADDITIONAL_INFO' | translate }}</h2>
            <div class="row">
              <app-mid-input
                class="col-md-6"
                size="wide"
                [(value)]="data.intervention.nature"
                #nature="appMidInput"
                name="nature"
                [label]="'INTERVENTION_NATURE' | translate"
                [placeholder]="'INTERVENTION_SETTINGS.PLACEHOLDER_NATURE' | translate"
              ></app-mid-input>

              <!--
              <app-mid-input
                class="col-md-6"
                size="wide"
                [(value)]="data.intervention.motive"
                #motive="appMidInput"
                name="motive"
                [label]="'INTERVENTION_MOTIVE' | translate"
                [placeholder]="'INTERVENTION_SETTINGS.PLACEHOLDER_MOTIVE' | translate"
              ></app-mid-input>
              -->

              <app-mid-input
                class="col-md-6"
                size="wide"
                [(value)]="data.intervention.droneChannel"
                #droneChannel="appMidInput"
                name="droneChannel"
                [label]="'INTERVENTION.LABEL.DRONE_CHANNEL' | translate"
                [placeholder]="'INTERVENTION_SETTINGS.PLACEHOLDER_DRONE_CHANNEL' | translate"
              ></app-mid-input>

              <app-mid-textarea
                class="col-md-12"
                size="wide"
                [rows]="5"
                [(value)]="data.intervention.notes"
                #notes="appMidTextarea"
                name="notes"
                [label]="'INTERVENTION_SETTINGS.LABEL_NOTES' | translate"
                [placeholder]="'INTERVENTION_SETTINGS.PLACEHOLDER_NOTES' | translate"
              ></app-mid-textarea>
            </div>
          </section>

          <section class="section-form-group col-md-12">
            <h2 class="section-header">{{ 'INTERVENTION_SETTINGS.SECTION_TAGS' | translate }}</h2>
            <app-mid-chip-list
              [(values)]="data.intervention.tags"
              #interventionTags="appMidChipList"
              [placeholder]="'INTERVENTION_SETTINGS.PLACEHOLDER_TAGS' | translate"
            ></app-mid-chip-list>
          </section>
        </div>

        <div class="col-md-4 p-0">
          <section class="section-form-group col-md-12">
            <h2 class="section-header">{{ 'INTERVENTION_SETTINGS.SECTION_DRONES' | translate }}</h2>
            <app-mid-input
              size="wide"
              [(value)]="data.intervention.remotePilots"
              #remotePilots="appMidInput"
              name="remotePilots"
              [label]="'INTERVENTION_SETTINGS.LABEL_REMOTE_PILOTS' | translate"
              [placeholder]="'INTERVENTION_SETTINGS.PLACEHOLDER_REMOTE_PILOTS' | translate"
            ></app-mid-input>

            <app-mid-chip-list
              [(values)]="data.attachedDronesIds"
              #attachedDrones="appMidChipList"
              [allValues]="data.allDrones"
              [placeholder]="'INTERVENTION_SETTINGS.PLACEHOLDER_DRONES' | translate"
              [iconType]="IconType.drone"
              [verticalOrientation]="true"
            ></app-mid-chip-list>
          </section>
        </div>
      </div>

      <!--
      <section class="section-form-group col-md-12">
        <h2 class="section-header">{{ 'INTERVENTION_SETTINGS.SECTION_OTHER_SERVICES' | translate }}</h2>
        <div class="col-md-5">
          <app-mid-statistics-other-services
            [isEditable]="true"
            [(items)]="data.intervention.statistics.otherServices"
            [isExpanded]="false"
          ></app-mid-statistics-other-services>
        </div>
      </section>
      -->

      <section class="section-form-group col-md-12">
        <h2 class="section-header">
          {{ 'INTERVENTION_SETTINGS.SECTION_STATS' | translate }},
          {{ 'INTERVENTION_SETTINGS.SECTION_OTHER_SERVICES' | translate }}
        </h2>
        <div class="row">
          <div class="col-md-5">
            <app-mid-statistics-victims
              [isEditable]="true"
              [(items)]="data.intervention.statistics.victims"
              [isExpanded]="false"
            >
            </app-mid-statistics-victims>
          </div>
          <div class="col-md-5">
            <app-mid-statistics-engaged-staff
              [isEditable]="true"
              [(items)]="data.intervention.statistics.engagedStaff"
              [isExpanded]="false"
            ></app-mid-statistics-engaged-staff>
          </div>
        </div>
        <div class="row">
          <div class="col-md-5">
            <app-mid-statistics-engaged-resources
              [isEditable]="true"
              [(items)]="data.intervention.statistics.engagedResources"
              [isExpanded]="false"
            ></app-mid-statistics-engaged-resources>
          </div>
          <div class="col-md-5">
            <app-mid-statistics-other-services
              [isEditable]="true"
              [(items)]="data.intervention.statistics.otherServices"
              [isExpanded]="false"
            ></app-mid-statistics-other-services>
          </div>
        </div>
      </section>

      <section class="section-form-group col-md-12">
        <div class="row mt-3 mb-2">
          <app-mid-button
            class="pr-2"
            *ngIf="!data.intervention.isClosed"
            type="primary"
            [disabled]="formUpdateIntervention.invalid || formUpdateIntervention.pristine"
            (action)="onSaveIntervention(formUpdateIntervention)"
            [ngClass]="{
              'mid-in-saving': state.isLoading,
              'mid-saved': state.isUpdated,
              'mid-error': state.isFailed
            }"
          >
            <span *ngIf="state.isLoading"><i class="fa fa-sync-alt fa-spin"></i></span>
            <span *ngIf="state.isFailed"><i class="fa fa-exclamation-triangle"></i></span>
            <span *ngIf="state.isUpdated"><i class="fa fa-check"></i></span>
            <span>
              {{ 'SAVE' | translate }}
            </span>
          </app-mid-button>
          <app-mid-button
            class="pr-2"
            *ngIf="!data.intervention.isClosed"
            type="secondary"
            (action)="onUndoModification()"
            [disabled]="false"
          >
            {{ 'INTERVENTION_SETTINGS.BUTTON_UNDO' | translate }}
          </app-mid-button>
          <app-mid-button class="pr-2 ml-auto" type="destructive" (action)="onDeleteInterventionMedias()">
            <span *ngIf="state.isLoading"><i class="fa fa-sync-alt fa-spin"></i></span>
            <span *ngIf="state.isFailed"><i class="fa fa-exclamation-triangle"></i></span>
            <span *ngIf="!state.isLoading && !state.isFailed"><i class="fas fa-clipboard-check mr-2"></i></span>
            {{ 'INTERVENTION_SETTINGS.BUTTON_DELETE_MEDIAS' | translate }}
          </app-mid-button>
          <app-mid-button
            class="pr-2"
            *ngIf="!data.intervention.isClosed && !data.intervention.isArchived"
            type="destructive"
            (action)="onCloseIntervention()"
          >
            <span *ngIf="state.isLoading"><i class="fa fa-sync-alt fa-spin"></i></span>
            <span *ngIf="state.isFailed"><i class="fa fa-exclamation-triangle"></i></span>
            <span *ngIf="!state.isLoading && !state.isFailed"><i class="fas fa-clipboard-check mr-2"></i></span>
            {{ 'INTERVENTION_SETTINGS.BUTTON_CLOSE' | translate }}
          </app-mid-button>
          <app-mid-button
            class="pr-2"
            *ngIf="data.intervention.isClosed && !data.intervention.isArchived"
            type="primary"
            (action)="onOpenIntervention()"
            [ngClass]="{
              'mid-in-saving': state.isLoading,
              'mid-saved': state.isUpdated,
              'mid-error': state.isFailed
            }"
          >
            <!--<i class="fas fa-clipboard-list mr-2"></i>-->
            <span *ngIf="state.isLoading"><i class="fa fa-sync-alt fa-spin"></i></span>
            <span *ngIf="state.isFailed"><i class="fa fa-exclamation-triangle"></i></span>
            <span *ngIf="!state.isLoading && !state.isFailed"><i class="fas fa-clipboard-list mr-2"></i></span>
            {{ 'INTERVENTION_SETTINGS.REOPEN' | translate }}
          </app-mid-button>
          <app-mid-button
            class="pr-2"
            *ngIf="!data.intervention.isArchived && data.intervention.isClosed"
            type="destructive"
            (action)="onArchiveIntervention()"
            [ngClass]="{
              'mid-in-saving': state.isLoading,
              'mid-saved': state.isUpdated,
              'mid-error': state.isFailed
            }"
          >
            <span *ngIf="state.isLoading"><i class="fa fa-sync-alt fa-spin"></i></span>
            <span *ngIf="state.isFailed"><i class="fa fa-exclamation-triangle"></i></span>
            <span *ngIf="!state.isLoading && !state.isFailed"> <i class="fas fa-archive mr-2"></i></span>
            {{ 'INTERVENTION_SETTINGS.ARCHIVE' | translate }}
          </app-mid-button>
          <app-mid-button
            class="pr-2"
            *ngIf="data.intervention.isClosed && data.intervention.isArchived"
            type="destructive"
            (action)="onDeleteIntervention()"
          >
            <i class="fas fa-times mr-2"></i>
            {{ 'INTERVENTION_SETTINGS.REMOVE' | translate }}
          </app-mid-button>
        </div>
      </section>
    </div>
  </form>
</div>
