import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrganizationDto, OrganizationsService } from 'src/api';

@Component({
  selector: 'app-organization-user-route',
  templateUrl: './organization-user-route.component.html',
  styleUrls: ['./organization-user-route.component.scss'],
})
export class OrganizationUserRouteComponent implements OnInit {
  organization: OrganizationDto;
  constructor(private readonly route: ActivatedRoute, private organizationService: OrganizationsService) {}

  ngOnInit(): void {
    this.route.parent.params.subscribe((params) => {
      const id: string = params.id;
      if (!id) {
        return;
      }
      this.organizationService
        .organizationControllerGetOrganization(id)
        .toPromise()
        .then((org) => {
          if (!org) {
            return;
          }
          this.organization = org;
        });
    });
  }
}
