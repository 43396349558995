<!-- upload drag & drop -->
<!-- header -->
<div class="upload-header">
  <div class="upload-title">
    <span class="title"> {{ 'IMPORT_MEDIA' | translate }} </span>
  </div>
  <div class="close-icon" mat-dialog-close>
    <i class="fas fa-times icon"></i>
  </div>
</div>
<!-- Upload body -->
<div class="upload-body">
  <div class="steps" *ngIf="data.intervention && data.intervention.isArchived !== true; else noIntervention">
    <div class="step">
      <div class="step-main">
        <div class="step-number">
          <span class="number">1</span>
        </div>
        <div class="step-info">
          <span class="info"> {{ 'IMPORT_DESIRED_MEDIA' | translate }} </span>
        </div>
      </div>
      <div class="hint" *ngIf="state.isNotAllowedFileType">
        <mat-icon svgIcon="caution"></mat-icon>
        <mat-hint class="select-intervention-mat-hint">{{ 'NOT_ALLOWED_FILE_TYPE' | translate }}</mat-hint>
      </div>
    </div>
  </div>
  <ng-template #noIntervention>
    <div class="steps">
      <div class="step">
        <div class="step-main">
          <div class="step-number">
            <span class="number">1</span>
          </div>
          <div class="step-info">
            <span class="info"> {{ 'INTERVENTION_SELECT_F0R_LINK_MEDIA' | translate }} </span>
          </div>
        </div>
        <div class="select-intervention-combobox-block">
          <app-mid-select-intervention-combobox
            (interventionSelected)="getSelectedIntervention($event)"
            [organizationId]="organizationId"
          ></app-mid-select-intervention-combobox>
        </div>
      </div>
      <div class="step">
        <div class="step-main">
          <div class="step-number">
            <span class="number">2</span>
          </div>
          <div class="d-flex flex-column">
            <div class="step-info">
              <div>
                <span class="info"> {{ 'IMPORT_DESIRED_MEDIA' | translate }} </span>
                <i [matTooltip]="formattedFileTypes" class="fa fa-question-circle" aria-hidden="true"></i>
              </div>
            </div>
            <div class="warning" *ngIf="state.isNotAllowedFileType">
              <mat-icon svgIcon="caution"></mat-icon>
              <mat-hint class="pl-1 select-intervention-mat-hint">{{ 'NOT_ALLOWED_FILE_TYPE' | translate }}</mat-hint>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <div
    class="mid-container-upload-drag-drop"
    [ngClass]="{ disabled: !selectedIntervention }"
    appDnd
    (fileDropped)="onFileDropped($event)"
  >
    <input
      [disabled]="!selectedIntervention"
      [accept]="allowedFileTypes"
      (change)="fileBrowseHandler($event.target.files)"
      type="file"
      #fileDropRef
      id="fileDropRef"
      multiple
      class="mid-input-upload-files"
    />

    <!-- info user -->
    <div class="mid-user-info">
      <i class="fas fa-photo-video fa-4x mid-upload-files"></i>
      <div class="user-info">
        <h3 class="m-0 pt-2 main-info">{{ 'DRAG_DROP_FILE_HERE' | translate }}</h3>
        <span class="mb-2"> {{ 'OR' | translate }}</span>
        <app-mid-button type="primary" [disabled]="!selectedIntervention">
          {{ 'DRAG_DROP_FILE_BUTTON' | translate }}
        </app-mid-button>
      </div>
    </div>

    <!-- upload files -->
  </div>
</div>
