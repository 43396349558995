import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { required, validate } from 'src/app/shared';
import { AuthenticateAction, ExpireAuthenticationAction, SetReturnUrlAction } from './authentication.actions';
import {
  selectAccessToken,
  selectAuthenticatedOrganizationId,
  selectAuthenticatedUserId,
  selectIsAuthenticated,
  selectReturnUrl,
} from './authentication.selectors';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationStoreService {
  private readonly store: Store<AppState>;

  constructor(store: Store<AppState>) {
    this.store = store;
  }

  @validate
  authenticate(@required userId: string, @required accessToken: string, @required organizationId: string) {
    this.store.dispatch(new AuthenticateAction(userId, accessToken, organizationId));
  }

  expireAuthentication() {
    this.store.dispatch(new ExpireAuthenticationAction());
  }

  checkIsAuthenticated(): Observable<boolean> {
    const isAuthenticated$ = this.store.select(selectIsAuthenticated);

    return isAuthenticated$;
  }

  getAccessToken(): Observable<string> {
    const selectAccessToken$ = this.store.select(selectAccessToken);

    return selectAccessToken$;
  }

  getAuthenticatedUserId(): Observable<string> {
    const selectAuthenticatedUserId$ = this.store.select(selectAuthenticatedUserId);

    return selectAuthenticatedUserId$;
  }

  getAuthenticatedOrganizationId(): Observable<string> {
    const selectAuthenticatedOrganizationId$ = this.store.select(selectAuthenticatedOrganizationId);

    return selectAuthenticatedOrganizationId$;
  }

  setReturnUrl(returnUrl: string) {
    this.store.dispatch(new SetReturnUrlAction(returnUrl));
  }

  getReturnUrl(): Observable<string> {
    const selectReturnUrl$ = this.store.select(selectReturnUrl);

    return selectReturnUrl$;
  }
}
