<div
  #midTooltip
  class="mid-tooltip"
  [class.mid-tooltip-bottom]="position === 'bottom'"
  [class.mid-tooltip-top]="position === 'top'"
  [class.mid-tooltip-left]="position === 'left'"
  [class.mid-tooltip-right]="position === 'right'"
>
  {{ text }}
</div>
