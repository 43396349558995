import {
  AuthenticateAction,
  AuthenticationActions,
  AuthenticationActionType,
  SetReturnUrlAction,
} from './authentication.actions';
import { Assert } from 'src/app/shared';

export interface AuthenticationState {
  isAuthenticated: boolean;
  userId: string;
  organizationId: string;
  accessToken: string;
  returnUrl: string;
}

export const authenticationInitialState: AuthenticationState = {
  isAuthenticated: false,
  userId: null,
  organizationId: null,
  accessToken: null,
  returnUrl: null,
};

export function authenticationReducer(state: AuthenticationState, action: AuthenticationActions): AuthenticationState {
  Assert.isNotNull(action, 'action');

  switch (action.type) {
    case AuthenticationActionType.Authenticate:
      return setAuthentication(state, action);

    case AuthenticationActionType.ExpireAuthentication:
      return {
        ...state,
        isAuthenticated: false,
        userId: null,
        organizationId: null,
        accessToken: null,
      };

    case AuthenticationActionType.SetReturnUrl:
      return setReturnUrlActionReducer(state, action);

    default:
      return state;
  }
}

export function setAuthentication(state: AuthenticationState, action: AuthenticateAction): AuthenticationState {
  Assert.isNotNull(state, 'state');
  Assert.isNotNull(action, 'action');

  const newState: AuthenticationState = {
    ...state,
    userId: action.userId,
    accessToken: action.accessToken,
    organizationId: action.organizationId,
    isAuthenticated: true,
  };

  return newState;
}

export function setReturnUrlActionReducer(state: AuthenticationState, action: SetReturnUrlAction): AuthenticationState {
  Assert.isNotNull(state, 'state');
  Assert.isNotNull(action, 'action');

  const newState: AuthenticationState = {
    ...state,
    returnUrl: action.returnUrl,
  };

  return newState;
}
