<form #roleSelectForm="ngForm">
  <app-mid-select
    [required]="true"
    size="wide"
    name="roleSelector"
    [items]="rolesSelectItems"
    (selectedItemChange)="onRoleSelected($event)"
    [label]="'CREATE_GROUP.GROUP_ROLE' | translate"
    [placeholder]="'CREATE_GROUP.SELECT_GROUP_ROLE' | translate"
  >
    <span *ngIf="!roleSelectForm.pristine && isEmpty(currentRole)" class="warning-input-text">
      {{ 'CREATE_GROUP.SELECT_GROUP_ROLE_ERROR' | translate }}
    </span>
  </app-mid-select>
</form>
