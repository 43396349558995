import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import _ from 'lodash';
import { AnnotationSubCategoryLangKey } from 'src/app/tdraw/models/annotation-category.enum';
import { IconColor, IconState, MapIcon, MapIconGroup } from 'src/app/tdraw/models/map-icon';
import { MapIconsService } from 'src/app/tdraw/services/map-icons.service';

@Component({
  selector: 'tdraw-sitac-selector',
  templateUrl: './sitac-selector.component.html',
  styleUrls: ['./sitac-selector.component.scss'],
})
export class SitacSelectorComponent implements OnInit {
  @Output()
  elementSelected: EventEmitter<MapIcon>;

  private sitacGroups: MapIconGroup[];

  groups: MapIconGroup[] = [];

  // Filters
  filterColor: IconColor = IconColor.Black;
  filterState?: IconState = null;

  // Enums
  get IconState() {
    return IconState;
  }

  get IconColor() {
    return IconColor;
  }

  get AnnotationSubCategoryLangKey() {
    return AnnotationSubCategoryLangKey;
  }

  constructor(private mapIconsService: MapIconsService) {
    this.elementSelected = new EventEmitter();
  }

  ngOnInit() {
    this.sitacGroups = this.mapIconsService.getSITACGroups();
    this.refreshUI();
  }

  applyColorFilter(filterColor: IconColor) {
    this.filterColor = filterColor;
    this.refreshUI();
  }

  applyStateFilter(filterState?: IconState) {
    this.filterState = filterState;
    this.refreshUI();
  }

  onElementSelected(icon: MapIcon) {
    this.elementSelected.emit(icon);
  }

  private refreshUI() {
    const output = [];

    for (let group of this.sitacGroups) {
      const outputGroup = { ...group };
      outputGroup.icons = this.filterGroupIcons(outputGroup.icons);
      if (outputGroup.icons.length) {
        // Group by subcategory on the fly
        outputGroup.subcategorizedIcons = _.groupBy(outputGroup.icons, 'subcategory');
        output.push(outputGroup);
      }
    }

    this.groups = output;
  }

  private filterGroupIcons(icons: MapIcon[]) {
    // Filter color
    icons = icons.filter((icon) => {
      if (this.filterColor === IconColor.Black) {
        return true;
      }

      if (!icon.colors) {
        return false;
      }

      return icon.colors.includes(this.filterColor);
    });

    // Filter state
    return icons.filter((icon) => {
      if (!this.filterState) {
        return true;
      }

      return icon.state === this.filterState;
    });
  }
}
