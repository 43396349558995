import { createFeatureSelector, MemoizedSelector, createSelector } from '@ngrx/store';
import { UserProfilesState } from './user-profiles.reducer';
import { AppState } from 'src/app/app.reducer';
import { userProfileConfig } from '../user-profile.config';

export const selectUsersState: MemoizedSelector<AppState, UserProfilesState> = createFeatureSelector(
  userProfileConfig.store.key
);

export const selectCurrentUser = createSelector(
  selectUsersState,
  (state: UserProfilesState) => state.currentUser ?? null
);
