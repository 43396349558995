<button
  [class.disabled]="disabled"
  [class.primary]="type === MidIconButtonType.primary"
  [class.secondary]="type === MidIconButtonType.secondary"
  [class.shadow]="shadow"
  [style.backgroundColor]="bgColor"
  (click)="onClick($event)"
>
  <ng-content></ng-content>
</button>
