import { Injectable } from '@angular/core';
import _, { reject, map, unionWith, Dictionary } from 'lodash';
import { required, validate } from 'src/app/shared';
import { SortService } from './sort.service';
import { PaginationService } from './pagination.service';
import { FieldSortOrder } from './field-sort-order';
import { FilterService } from './filter.service';
import { DataSourceState } from './data-source-state';
import { DataSource, GroupedItemsData } from './data-source';
import moment, { unitOfTime } from 'moment';

@Injectable({
  providedIn: 'root',
})
export class DataSourceService {
  private readonly sortService: SortService;
  private readonly paginationService: PaginationService;
  private readonly filterService: FilterService;

  constructor(sortService: SortService, paginationService: PaginationService, filterService: FilterService) {
    this.sortService = sortService;
    this.paginationService = paginationService;
    this.filterService = filterService;
  }

  @validate
  createDataSource<T extends object>(@required items: T[], @required state: DataSourceState<T>): DataSource<T> {
    const filteredItems = this.filterItems<T>(
      items,
      state.filter,
      state.customFilter,
      state.searchableFields,
      state.fieldsMappers
    );
    const sortedItems = this.sortItems<T>(filteredItems, state.sortOrder);
    const paginatedItems = this.paginateItems(sortedItems, state.page, state.pageSize);
    const sortedItemsByGroup = this.groupedItems<T>(sortedItems, 'month', 'startDate');

    const dataSource = new DataSource<T>({
      allItems: items,
      filteredItems: sortedItems,
      paginatedItems: paginatedItems,
      // groupedItems: sortedItemsByGroup,
    });

    return dataSource;
  }

  @validate
  filterDataSource<T extends object>(
    @required dataSource: DataSource<T>,
    @required state: DataSourceState<T>
  ): DataSource<T> {
    const filteredItems = this.filterItems<T>(
      dataSource.allItems,
      state.filter,
      state.customFilter,
      state.searchableFields,
      state.fieldsMappers
    );
    const sortedItems = this.sortItems<T>(filteredItems, state.sortOrder);
    const paginatedItems = this.paginateItems(sortedItems, state.page, state.pageSize);
    const sortedItemsByGroup = this.groupedItems<T>(sortedItems, 'month', 'startDate');

    const filteredDataSource = new DataSource<T>({
      allItems: dataSource.allItems,
      filteredItems: sortedItems,
      paginatedItems: paginatedItems,
      // groupedItems: sortedItemsByGroup,
    });

    return filteredDataSource;
  }

  @validate
  sortDataSource<T extends object>(
    @required dataSource: DataSource<T>,
    @required state: DataSourceState<T>
  ): DataSource<T> {
    let filteredItems;
    if (state.sortOrder && state.sortOrder.order) {
      filteredItems = this.sortItems(dataSource.filteredItems, state.sortOrder);
    } else {
      filteredItems = this.filterItems(
        dataSource.allItems,
        state.filter,
        state.customFilter,
        state.searchableFields,
        state.fieldsMappers
      );
    }

    const paginatedItems = this.paginateItems<T>(filteredItems, state.page, state.pageSize);

    const sortedDataSource = new DataSource<T>({
      allItems: dataSource.allItems,
      filteredItems: filteredItems,
      paginatedItems: paginatedItems,
    });

    return sortedDataSource;
  }

  @validate
  paginateDataSource<T extends object>(
    @required dataSource: DataSource<T>,
    @required state: DataSourceState<T>
  ): DataSource<T> {
    const paginatedItems = this.paginateItems(dataSource.filteredItems, state.page, state.pageSize);

    const paginatedDataSource = new DataSource<T>({
      allItems: dataSource.allItems,
      filteredItems: dataSource.filteredItems,
      paginatedItems: paginatedItems,
    });

    return paginatedDataSource;
  }

  @validate
  deleteItemFromDataSource<T extends object>(
    @required dataSource: DataSource<T>,
    @required state: DataSourceState<T>,
    @required itemToDelete: T,
    itemsComparator?: (first: T, second: T) => boolean
  ): DataSource<T> {
    const rejectPredicate = itemsComparator
      ? (value: T) => itemsComparator(itemToDelete, value)
      : (value: T) => value === itemToDelete;

    const updatedItems = reject(dataSource.allItems, rejectPredicate);

    const updatedDataSource = this.createDataSource(updatedItems, state);
    return updatedDataSource;
  }

  @validate
  addItemToDataSource<T extends object>(
    @required dataSource: DataSource<T>,
    @required state: DataSourceState<T>,
    @required newItem: T,
    itemsComparator?: (first: T, second: T) => boolean
  ): DataSource<T> {
    const updatedItems = itemsComparator
      ? unionWith([newItem], dataSource.allItems, itemsComparator)
      : [newItem, ...dataSource.allItems];

    const updatedDataSource = this.createDataSource(updatedItems, state);
    return updatedDataSource;
  }

  @validate
  updateItemInDataSource<T extends object>(
    @required dataSource: DataSource<T>,
    @required state: DataSourceState<T>,
    @required updatedItem: T,
    itemsComparator?: (first: T, second: T) => boolean
  ): DataSource<T> {
    const searchPredicate = itemsComparator
      ? (value: T) => itemsComparator(updatedItem, value)
      : (value: T) => value === updatedItem;

    const updatedItems = map(dataSource.allItems, (value: T) => (searchPredicate(value) ? updatedItem : value));

    const updatedDataSource = this.createDataSource(updatedItems, state);
    return updatedDataSource;
  }

  @validate
  private sortItems<T>(@required items: T[], sortOrder: FieldSortOrder): T[] {
    if (!sortOrder || !sortOrder.order) {
      return items;
    }

    const sortedItems = this.sortService.sort(items, sortOrder.fieldName, sortOrder.order);

    return sortedItems;
  }

  @validate
  private paginateItems<T>(@required items: T[], @required page: number, @required pageSize: number): T[] {
    const pageItems = this.paginationService.getPageItems(items, page, pageSize);
    return pageItems;
  }

  @validate
  private filterItems<T extends object>(
    @required items: T[],
    filter: string,
    customFilter?: Mapper<T[], T[]>,
    searchableFields?: FieldsOf<T>,
    fieldsMappers?: PropertyMapper<T, string>
  ): T[] {
    let filteredItems = this.filterService.filter(items, filter, searchableFields, fieldsMappers);

    if (customFilter) {
      filteredItems = customFilter(filteredItems);
    }

    return filteredItems;
  }

  @validate
  groupedItems<T extends object>(
    itemList: T[],
    duration: unitOfTime.StartOf,
    filedName: string
  ): GroupedItemsData<T>[] {
    if (!itemList?.length) {
      return [];
    }
    const groupedData: Dictionary<T[]> = _.groupBy(itemList, (item) =>
      moment.utc(item[filedName]).local().startOf(duration).toDate()
    );
    return Object.entries(groupedData)
      .map((item) => ({ key: new Date(item[0]), value: item[1] }))
      .sort((item1, item2) => item2.key.getTime() - item1.key.getTime());
  }
}
