import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import mapboxgl from 'mapbox-gl';
import { environment } from 'src/environments/environment';
import { BackgroundViewType } from '../models/dto/enums/background-view-type.enum';
import { tdrawConfig } from '../tdraw.config';

// IGN stuff
const IgnKey = 'beta';
// const IgnLayerName = 'GEOGRAPHICALGRIDSYSTEMS.MAPS.SCAN-EXPRESS.STANDARD';
const IgnLayerName = 'GEOGRAPHICALGRIDSYSTEM.DFCI';

enum Sources {
  DFCI = 'dfci',
  DEM = 'mapbox-dem',
}

enum Layers {
  ElevationLines = 'elevation-lines',
  ElevationLinesLabels = 'elevation-lines-labels',
  Buildings3D = '3d-buildings',
  DFCI = 'dfci',
  Sky = 'sky',
}

// Background layers will be displayed beneath the specified layer
const OffsetLayerBackground = Layers.Sky;

/**
 * Service to control Mapbox Styles and base data layers.
 *
 * First usage of this Service will trigger preloading
 * of native Mapbox Styles, preventing to re-download
 * their composition for every Map.
 *
 * This Service is independant of any state or store (MapState)
 * and thus can be used as a helper to change Mapbox Styles
 * or to toggle some base data layers (buildings, dfci...)
 */
@Injectable({
  providedIn: 'root',
})
export class BasemapService {
  private stylesArePreloaded = false;

  // Will be filled with data by preloadStyles method
  private sources = {};
  private styles = {
    [BackgroundViewType.Streets]: {
      id: 'mapbox/streets-v11',
      layers: [],
    },
    [BackgroundViewType.Satellite]: {
      id: 'mapbox/satellite-v9',
      layers: [],
    },
    [BackgroundViewType.SatelliteStreet]: {
      id: 'mapbox/satellite-streets-v11',
      layers: [],
    },
    [BackgroundViewType.Dark]: {
      id: 'mapbox/dark-v10',
      layers: [],
    },
  };

  constructor(private httpClient: HttpClient) {}

  async load(map: mapboxgl.Map) {
    if (!this.stylesArePreloaded) {
      await this.preloadStyles();
    }

    const sources = this.sources;
    for (const sourceId in sources) {
      if (map.getSource(sourceId)) {
        continue;
      }
      map.addSource(sourceId, sources[sourceId]);
    }

    this.initSources(map);
    this.initLayers(map);
  }

  /**
   * Change Map background using a preloaded Mapbox style
   */
  changeStyle(map: mapboxgl.Map, newStyle: BackgroundViewType) {
    // Remove layers of other Styles from the Map
    for (const viewId in this.styles) {
      if (viewId === newStyle) {
        continue;
      }
      const layers = this.styles[viewId].layers;
      for (const layer of layers) {
        const layerId = layer.id;
        if (map.getLayer(layerId)) {
          map.removeLayer(layerId);
        }
      }
    }

    // Add layers of View to apply
    const newLayers = this.styles[newStyle].layers;
    for (const layer of newLayers) {
      map.addLayer(layer, OffsetLayerBackground);
    }
  }

  //=====
  // Toggleable layers for any GIS map
  //=====

  toggle3DTerrain(map: mapboxgl.Map, isEnabled: boolean) {
    if (isEnabled) {
      map.setTerrain({
        source: Sources.DEM,
        exaggeration: 1,
      });
      map.easeTo({ pitch: 45, bearing: 0 });
    } else {
      map.setTerrain();
      map.resetNorthPitch();
    }
  }

  toggle3DBuildings(map: mapboxgl.Map, isEnabled: boolean) {
    map.setLayoutProperty(Layers.Buildings3D, 'visibility', isEnabled ? 'visible' : 'none');
  }

  toggleDFCI(map: mapboxgl.Map, isEnabled: boolean) {
    map.setLayoutProperty(Layers.DFCI, 'visibility', isEnabled ? 'visible' : 'none');
  }

  toggleLevelCurves(map: mapboxgl.Map, isEnabled: boolean) {
    const visibility = isEnabled ? 'visible' : 'none';
    map.setLayoutProperty(Layers.ElevationLines, 'visibility', visibility);
    map.setLayoutProperty(Layers.ElevationLinesLabels, 'visibility', visibility);
  }

  //=====
  // Definitions of Sources and Layers
  // used by all GIS maps
  //=====

  private async preloadStyles() {
    for (const viewId in this.styles) {
      const style = this.styles[viewId];
      const data: any = await this.httpClient
        .get(`https://api.mapbox.com/styles/v1/${style.id}?access_token=${tdrawConfig.mapAccessToken}`)
        .toPromise();

      const sources = data.sources;
      const layers = data.layers;

      // Load sources
      for (let sourceId in sources) {
        if (this.sources[sourceId]) {
          continue;
        }
        this.sources[sourceId] = sources[sourceId];
      }

      // Save layers definitions for later
      style.layers = layers;
    }
    this.stylesArePreloaded = true;
  }

  private initSources(map: mapboxgl.Map) {
    // Terrain elevation
    map.addSource(Sources.DEM, {
      type: 'raster-dem',
      url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
      tileSize: 512,
      maxzoom: 14,
    });

    // WMTS working example
    // map.addSource('ign', {
    //   'type': 'raster',
    //   'tiles': [
    //     'https://wxs.ign.fr/'+IgnKey+'/geoportail/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=ORTHOIMAGERY.ORTHOPHOTOS&TILEMATRIXSET=PM&TILEMATRIX={z}&TILECOL={x}&TILEROW={y}&STYLE=normal&FORMAT=image/jpeg'
    //   ],
    //   'tileSize': 256
    // });

    // IGN map
    // map.addSource('ign', {
    //   type: 'raster',
    //   tiles: [
    //     'https://wxs.ign.fr/' +
    //     IgnKey +
    //     '/geoportail/r/wms?bbox={bbox-epsg-3857}&format=image/png&SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256&layers=' +
    //     IgnLayerName +
    //     '&STYLES=normal',
    //   ],
    //   tileSize: 256,
    // });

    // DFCI Grid
    map.addSource(Sources.DFCI, {
      type: 'raster',
      tiles: [
        environment.backend +
          'mapserv?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.3.0&request=GetMap&crs=EPSG:3857&transparent=true&width=512&height=512&layers=all&map=../mapfiles/dfci_local.map',
      ],
      tileSize: 512,
    });
  }

  private initLayers(map: mapboxgl.Map) {
    // Sky
    map.addLayer({
      id: Layers.Sky,
      type: 'sky',
      paint: {
        'sky-type': 'atmosphere',
        'sky-atmosphere-sun': [0.0, 0.0],
        'sky-atmosphere-sun-intensity': 15,
      },
    });

    // IGN
    // map.addLayer({
    //   id: 'ign',
    //   type: 'raster',
    //   source: 'ign',
    //   layout: {
    //     visibility: 'none',
    //   },
    // });

    // Elevation lines
    map.addLayer({
      id: Layers.ElevationLines,
      type: 'line',
      source: {
        type: 'vector',
        url: 'mapbox://mapbox.mapbox-terrain-v2',
      },
      'source-layer': 'contour',
      layout: {
        visibility: 'none',
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': '#877b59',
        'line-width': 1,
      },
    });
    map.addLayer({
      id: Layers.ElevationLinesLabels,
      type: 'symbol',
      source: {
        type: 'vector',
        url: 'mapbox://mapbox.mapbox-terrain-v2',
      },
      'source-layer': 'contour',
      filter: ['any', ['==', ['get', 'index'], 10], ['==', ['get', 'index'], 5]],
      layout: {
        visibility: 'none',
        'symbol-placement': 'line',
        'text-field': '{ele} m',
        'text-max-angle': 25,
        'text-padding': 5,
        'text-pitch-alignment': 'viewport',
        'text-size': ['interpolate', ['linear'], ['zoom'], 15, 9.5, 20, 12],
      },
      paint: {
        'text-color': '#000',
        'text-halo-color': 'hsla(0, 0%, 100%, 0.5)',
        'text-halo-width': 1,
      },
    });

    // Buildings
    map.addLayer({
      id: Layers.Buildings3D,
      source: 'composite',
      'source-layer': 'building',
      filter: ['==', 'extrude', 'true'],
      type: 'fill-extrusion',
      minzoom: 15,
      paint: {
        'fill-extrusion-color': [
          'case',
          ['<', ['get', 'height'], 10],
          '#aaaaaa',
          [
            'case',
            ['<', ['get', 'height'], 30],
            '#777777',
            ['case', ['<', ['get', 'height'], 50], '#444444', '#222222'],
          ],
        ],
        'fill-extrusion-height': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'height']],
        'fill-extrusion-base': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'min_height']],
        'fill-extrusion-opacity': 0.9,
      },
      layout: {
        visibility: 'none',
      },
    });

    // DFCI Grid
    map.addLayer({
      id: Layers.DFCI,
      type: 'raster',
      source: Sources.DFCI,
      paint: {},
      layout: {
        visibility: 'none',
      },
    });
  }
}
