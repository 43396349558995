import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Params, Router } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { Intervention } from 'src/app/autogenerated/model';
import { InterventionStoreService } from 'src/app/intervention/store';
import { OrganizationStoreService } from 'src/app/organization/store';
import { MediaUploadDialogComponent } from '../../media/common/media-upload/media-upload-dialog/media-upload-dialog.component';
import { InformModalComponent } from '../../shared/modals/inform-modal/inform-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { MidRightPanelService } from 'src/app/midgard-controls/services/mid-right-panel.service';

@Component({
  selector: 'app-main-media-menu',
  templateUrl: './main-media-menu.component.html',
  styleUrls: ['./main-media-menu.component.scss'],
})
export class MainMediaMenuComponent implements OnInit, OnChanges, OnDestroy {
  interventionId: string;
  intervention: Intervention;

  private organizationSubscription: Subscription;
  private interventionSubscription: Subscription;
  private routeSubscription: Subscription;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly organizationStoreService: OrganizationStoreService,
    private readonly interventionStoreService: InterventionStoreService,
    public dialog: MatDialog,
    private readonly midRightPanelService: MidRightPanelService
  ) {}

  ngOnInit(): void {
    // this.organizationSubscription = this.organizationStoreService.getCurrentOrganization().subscribe((organization) => {
    //   console.log('appMainMediaMenu: ', organization);
    //   if (this.interventionSubscription) {
    //     this.interventionSubscription.unsubscribe();
    //   }
    //   this.interventionSubscription = this.interventionStoreService.getCurrentIntervention(organization._id).pipe(
    //     map(it=> {console.log("getCurrentIntervention ", it); return it},
    //     ),take(1)).subscribe(intervention => this.intervention = intervention);
    // });
    // this.route.paramMap.pipe(
    //   switchMap((params: ParamMap) => {
    //     console.log('appMainMediaMenu: ', params);
    //     return params;
    //   })
    // );
    // this.activatedRoute.params.subscribe(async (params) => {
    //   const interventionId = params['id'];
    //   console.log('appMainMediaMenu Route: ', params);
    //   if (interventionId) {
    //     this.intervention = interventionId;
    //   }
    // });
  }

  ngOnDestroy(): void {
    // this.organizationSubscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // throw new Error('Method not implemented.');
  }

  close(): void {
    this.midRightPanelService.close();
  }

  // for open dialog to add meidas in Media being imported
  openDialog(): void {
    const config = {
      id: 'newMediaUpload',
      width: '80%',
      data: {
        intervention: this.intervention && this.intervention.isClosed !== true ? this.intervention : null,
      },
    };

    const dialogRef = this.dialog.open(MediaUploadDialogComponent, config);

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.dialog.open(InformModalComponent, {
          width: '450px',
          data: {
            message: 'FILE_UPLOADING_INFO_MESSAGE',
          },
        });
      }
    });
  }
}
