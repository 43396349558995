<app-simple-header [sharingType]="'stream'"></app-simple-header>
<div class="container-fluid">
  <div class="row" *ngIf="!inValid">
    <div class="col-12 mid-video h-100">
      <!-- video content -->
      <div class="mid-video-container">
        <!--
        <video
          [appDroneStreamPlayer]="drone"
          [srcUrl]="srcUrl"
          *ngIf="drone?.streamingStatus === 'streaming'"
          class="azuremediaplayer amp-default-skin amp-big-play-centered mid-fix-bottom-controllers mid-show-controllers"
        ></video>
        -->
        <app-drone-stream-player [drone]="drone" [isShareButtonVisible]="false"></app-drone-stream-player>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="inValid">
    {{ 'INVALID_SHARE_LINK' | translate }}
  </div>
</div>
