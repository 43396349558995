import { Injectable } from '@angular/core';
import { required, validate } from '../shared';
import { trim } from 'lodash';
import { ApiService } from '../core/api';
import { BackendClientService } from '../global/backend-client.service';
import { UserAuth } from '../autogenerated/model';

type ResetPasswordResponse = {
  isResetCodeCorrect: boolean;
  isNewResetCodeRequested: boolean;
  triesLeft: number;
};

@Injectable({
  providedIn: 'root',
})
export class AuthenticationApiService {
  constructor(private readonly client: BackendClientService, private readonly apiService: ApiService) {}

  @validate
  async login(@required login: string, @required password: string): Promise<UserAuth> {
    const url = 'user/login';

    const body = {
      email: trim(login),
      password: password,
    };

    let user: UserAuth;
    try {
      user = await this.client.post<UserAuth>(url, body).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }

    return user;
  }

  async logout(): Promise<void> {
    const url = 'user/logout';

    try {
      await this.client.post(url, null).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }

  @validate
  async requestPasswordReset(@required username: string, @required sendBy: string): Promise<void> {
    const url = `user/requestPasswordReset`;

    const body = {
      email: trim(username),
      sendBy: sendBy,
    };

    try {
      await this.client.post(url, body).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }
  }

  @validate
  async resetPassword(
    @required username: string,
    @required code: string,
    @required password: string
  ): Promise<ResetPasswordResponse> {
    const url = `user/resetPassword`;

    const body = {
      email: trim(username),
      code: code,
      password: password,
    };

    let response: ResetPasswordResponse;
    try {
      response = await this.client.post<ResetPasswordResponse>(url, body).toPromise();
    } catch (error) {
      const errorResponse = this.apiService.handleError(error);
      throw errorResponse;
    }

    return response;
  }
}
