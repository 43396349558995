import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import _ from 'lodash';
import { ConeWindValues } from 'src/app/tdraw/models/cone-wind-values';
import { tdrawConfig } from '../../../tdraw.config';

@Component({
  selector: 'tdraw-create-cone-modal',
  templateUrl: './create-cone-modal.component.html',
  styleUrls: ['./create-cone-modal.component.scss'],
})
export class CreateConeModalComponent {
  wind: number = 20;
  coneWindValues: ConeWindValues[];

  constructor(private dialogRef: MatDialogRef<CreateConeModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.coneWindValues = _.values(tdrawConfig.cones);
  }

  onSubmit() {
    this.dialogRef.close(this.wind);
  }

  onClose() {
    this.dialogRef.close();
  }
}
