import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CircleType } from 'src/app/tdraw/models/circle-type.enum';
import { MapStateNestedStatisticsDto } from 'src/app/tdraw/models/dto';
import { MapIcon, MapIconGroup } from 'src/app/tdraw/models/map-icon';
import { MapIconsService } from 'src/app/tdraw/services/map-icons.service';

@Component({
  selector: 'tdraw-gis-symbols-tab',
  templateUrl: './gis-symbols-tab.component.html',
  styleUrls: ['./gis-symbols-tab.component.scss'],
})
export class GisSymbolsTabComponent implements OnInit {
  @Output()
  drawIcon: EventEmitter<MapIcon>;

  @Output()
  drawCircle: EventEmitter<CircleType>;

  @Output()
  drawAllCircles: EventEmitter<void>;

  @Output()
  openConeCreationModal: EventEmitter<void>;

  @Output()
  openMarkerCreationModal: EventEmitter<void>;

  @Input()
  statistics: MapStateNestedStatisticsDto;
  @Output()
  statisticsChange: EventEmitter<MapStateNestedStatisticsDto>;

  // Facade Icons
  facadeIconsBasic: MapIcon[];
  facadeIconsMore: MapIcon[];
  facadeIconsExpanded = false;

  facadeGroup: MapIconGroup;

  constructor(private mapIconsService: MapIconsService) {
    this.drawIcon = new EventEmitter();
    this.drawCircle = new EventEmitter();
    this.drawAllCircles = new EventEmitter();
    this.openConeCreationModal = new EventEmitter();
    this.openMarkerCreationModal = new EventEmitter();
    this.statisticsChange = new EventEmitter<MapStateNestedStatisticsDto>();
  }

  ngOnInit() {
    this.facadeGroup = this.mapIconsService.getFacadeGroup();
    this.facadeIconsBasic = this.mapIconsService.getFacadeLetters('A', 'F');
    this.facadeIconsMore = this.mapIconsService.getFacadeLetters('G', 'Z');
    if (!this.statistics) {
      this.statistics = {
        victims: [],
        engagedResources: [],
        engagedStaff: [],
        otherServices: [],
      };
    }
  }

  onDrawIcon(icon: MapIcon) {
    this.drawIcon.emit(icon);
  }

  onDrawCircle(type: CircleType) {
    this.drawCircle.emit(type);
  }

  onDrawAllCircles() {
    this.drawAllCircles.emit();
  }

  onOpenConeCreationModal() {
    this.openConeCreationModal.emit();
  }

  onOpenMarkerCreationModal() {
    this.openMarkerCreationModal.emit();
  }

  get CircleType() {
    return CircleType;
  }

  onUpdateStatistics() {
    this.statisticsChange.emit(this.statistics);
  }
}
