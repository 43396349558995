import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { Drone, Intervention, Organization } from '../../../autogenerated/model';
import { Subscription } from 'rxjs';
import { OrganizationStoreService } from '../../../organization/store';
import { InterventionService } from '../../../autogenerated/interventionService';
import { MidSelectItem } from '../../../midgard-controls/models';
import { Unsubscribe } from 'src/app/core/decorators';
import { DroneService } from 'src/app/autogenerated/droneService';
import { DroneStoreService } from '../../store';
import { MID_RIGHT_PANEL_DATA } from 'src/app/midgard-controls/services/dom.service';
import { MidRightPanelService } from 'src/app/midgard-controls/services/mid-right-panel.service';

export interface AssociateDroneToInterventionInput {
  drone: Drone;
}

@Unsubscribe()
@Component({
  selector: 'app-associate-drone-to-intervention',
  templateUrl: './associate-drone-to-intervention.component.html',
  styleUrls: ['./associate-drone-to-intervention.component.scss'],
})
export class AssociateDroneToInterventionComponent implements OnInit, OnDestroy {
  drone: Drone;

  errorMessage: string;
  droneModels: MidSelectItem[];
  currentDrone: Drone;
  organizationId: string;
  selectedIntervention: Intervention;

  @Output()
  updatedDroneIntervention: EventEmitter<Intervention>;

  private organizationSubscription: Subscription;

  constructor(
    private readonly organizationStoreService: OrganizationStoreService,
    private readonly interventionService: InterventionService,
    private readonly droneService: DroneService,
    private readonly droneStoreService: DroneStoreService,
    private readonly midRightPanelService: MidRightPanelService,
    @Inject(MID_RIGHT_PANEL_DATA) inputtingData: AssociateDroneToInterventionInput
  ) {
    this.drone = inputtingData.drone;
    this.updatedDroneIntervention = new EventEmitter<Intervention>();
  }

  ngOnInit(): void {
    this.organizationSubscription = this.organizationStoreService
      .getCurrentOrganization()
      .subscribe((organization: Organization) => {
        if (organization?._id === this.organizationId) {
          return;
        }
        this.organizationId = organization._id;
      });
  }

  ngOnDestroy() {}

  async onSubmit() {
    const isAttached = await this.interventionService.attachDroneToInterventionV1(
      this.organizationId,
      this.selectedIntervention._id,
      this.drone._id
    );
    this.drone.currentIntervention = this.selectedIntervention._id;
    // const drone = await this.droneService.updateDrone({
    //   id: this.drone._id,
    //   currentIntervention: this.selectedIntervention._id,
    // });
    this.droneStoreService.updateDrone(this.organizationId, this.drone);
    this.updatedDroneIntervention.emit(this.selectedIntervention);
    this.close();
  }

  getSelectedIntervention(selectedIntervention: Intervention) {
    this.selectedIntervention = selectedIntervention;
  }

  close(): void {
    this.midRightPanelService.close({
      intervention: this.selectedIntervention || null,
    });
  }
}
