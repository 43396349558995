<div class="textarea-container" [class.textarea-label]="!!label">
  <label *ngIf="label" [class.required]="required">{{ label }}</label>
  <textarea
    [placeholder]="placeholder"
    [(ngModel)]="value"
    (ngModelChange)="onValueChange()"
    #midTextarea="ngModel"
    [name]="name"
    [disabled]="disabled"
    [required]="required"
    [rows]="rows"
    [cols]="cols"
    [class.default]="size === MidInputSize.default"
    [class.wide]="size === MidInputSize.wide"
  ></textarea>
  <ng-content></ng-content>
</div>
