<div *ngIf="demoMap" style="position: relative">
  <img class="w-100" src="assets/images/demo-map.png" />

  <div id="demo-content">
    <p>{{ 'INTERVENTIONS_MAP_DEMO' | translate }}</p>
    <app-mid-button *ngIf="interventionsPermission.create" (action)="onOpenCreateIntervention()" type="primary">
      <i class="fas fa-plus pr-2"></i> {{ 'DIALOGS.CREATE_INTERVENTION.ACTION_LABEL' | translate }}
    </app-mid-button>
  </div>
</div>
<div *ngIf="!demoMap" id="interventions-map" class="map"></div>
