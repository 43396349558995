<mat-dialog-content>
  {{ data.message | translate }}

  <div *ngIf="data?.password" class="mt-3">{{ 'COPY_AND_SAVE_PASSWORD' | translate }}: {{ data?.password }}</div>

  <div *ngIf="data?.error" class="mt-3">
    {{ data?.error | translate }}
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <app-mid-button type="primary" (action)="onSubmit()">
    {{ 'OK' | translate }}
  </app-mid-button>
</mat-dialog-actions>
