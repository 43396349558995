<tdraw-right-panel-container>
  <div role="content">
    <div class="tab-body basemap-body">
      <ng-content></ng-content>
    </div>
  </div>

  <div role="footer">
    <div class="tabs">
      <div *ngFor="let tab of tabs" (click)="selectTab(tab)" [class.active-tab]="tab.active" class="tab-btn">
        <i [class]="tab.faIcon"></i><br />
        {{ tab.title }}
      </div>
    </div>
  </div>
</tdraw-right-panel-container>
