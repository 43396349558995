import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Alert } from '../../../autogenerated/model';

@Component({
  selector: 'app-intervention-alert',
  templateUrl: './intervention-alert.component.html',
  styleUrls: ['./intervention-alert.component.scss'],
})
export class InterventionAlertComponent {
  @Input()
  alert: Alert;

  @Input()
  selectedAlert: Alert;

  @Output()
  alertSelected: EventEmitter<Alert> = new EventEmitter();

  onAlertSelected(alert: Alert) {
    this.alertSelected.emit(alert);
  }
}
