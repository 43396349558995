import { point } from '@turf/helpers';
import bearing from '@turf/bearing';
import transformRotate from '@turf/transform-rotate';

/*
// TODO: Can't use that because of a typescript error
// Possible solution: https://stackoverflow.com/questions/54986332/typescript-class-extending-partial-interface

import { DrawCustomMode, DrawCustomModeThis } from '@mapbox/mapbox-gl-draw';

class ConeRotateModef {
  onSetup(opts) {
    const state = {
      center: null,
      feature: null,
      featureId: null,
      featureGeoJson: null,
      startAngle: null,
    };
    return state;
  };

  onMouseMove(state, e) {
    const mouse = e.featureTarget ? 'move' : 'none';
    (this as any).updateUIClasses({ mouse });
  };

  computeRotationCenter(feature) {
    // The very first coordinates is the main angle of the cone
    return point(feature.geometry.coordinates[0][0]);
  };

  onMouseDown(state, e) {
    if (e.featureTarget) {
      const featureId = e.featureTarget.properties.id;
      const feature = (this as any).getFeature(featureId);
      if (feature) {
        const mouse = point(e.lngLat.toArray());
        e.target['dragPan'].disable();

        state.feature = feature;
        state.featureId = featureId;
        state.featureGeoJson = feature.toGeoJSON();
        state.center = this.computeRotationCenter(state.featureGeoJson);
        state.startAngle = bearing(state.center, mouse);
      }
    }
    return state;
  };

  toDisplayFeatures(state, geojson, display) {
    display(geojson);
  };

  onDrag(state, e) {
    if (state.feature) {
      // Get rotation informations
      const { featureGeoJson, center, startAngle } = state;
      const mouse = point([e.lngLat.lng, e.lngLat.lat]);
      const mouseAngle = bearing(center, mouse);
      const rotateAngle = mouseAngle - startAngle;

      // Rotate the feature
      const rotatedFeature = transformRotate(featureGeoJson, rotateAngle, {
        pivot: center,
        mutate: false,
      });

      // Update the rotated feature
      state.feature.incomingCoords(rotatedFeature.geometry.coordinates);
    }
  };

  onMouseUp(state, e) {
    e.target['dragPan'].enable();
    state.center = null;
    state.feature = null;
    state.featureId = null;
    state.featureGeoJson = null;
    state.startAngle = null;
    return state;
  };
}
*/

const ConeRotateMode = {} as any;

ConeRotateMode.onSetup = function (opts) {
  const state = {
    center: null,
    feature: null,
    featureId: null,
    featureGeoJson: null,
    startAngle: null,
  };
  return state;
};

ConeRotateMode.onMouseMove = function (state, e) {
  const mouse = e.featureTarget ? 'move' : 'none';
  this.updateUIClasses({ mouse });
};

ConeRotateMode.computeRotationCenter = function (feature) {
  // The very first coordinates is the main angle of the cone
  return point(feature.geometry.coordinates[0][0]);
};

ConeRotateMode.onMouseDown = function (state, e) {
  if (e.featureTarget) {
    const featureId = e.featureTarget.properties.id;
    const feature = this.getFeature(featureId);
    if (feature) {
      const mouse = point(e.lngLat.toArray());
      e.target['dragPan'].disable();

      state.feature = feature;
      state.featureId = featureId;
      state.featureGeoJson = feature.toGeoJSON();
      state.center = this.computeRotationCenter(state.featureGeoJson);
      state.startAngle = bearing(state.center, mouse);
    }
  }
  return state;
};

ConeRotateMode.toDisplayFeatures = function (state, geojson, display) {
  display(geojson);
};

ConeRotateMode.onDrag = function (state, e) {
  if (state.feature) {
    // Get rotation informations
    const { featureGeoJson, center, startAngle } = state;
    const mouse = point([e.lngLat.lng, e.lngLat.lat]);
    const mouseAngle = bearing(center, mouse);
    const rotateAngle = mouseAngle - startAngle;

    // Rotate the feature
    const rotatedFeature = transformRotate(featureGeoJson, rotateAngle, {
      pivot: center,
      mutate: false,
    });

    // Update the rotated feature
    state.feature.incomingCoords(rotatedFeature.geometry.coordinates);
  }
};

ConeRotateMode.onMouseUp = function (state, e) {
  e.target['dragPan'].enable();
  state.center = null;
  state.feature = null;
  state.featureId = null;
  state.featureGeoJson = null;
  state.startAngle = null;
  return state;
};

export const Modes = {
  cone_rotate_mode: ConeRotateMode,
};
