import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardPageComponent } from './dashboard-page/dashboard-page.component';
import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { ActivityStreamModule } from '../activity-stream/activity-stream.module';
import { CoreModule } from '../core/core.module';
import { DroneModule } from '../drone/drone.module';
import { InterventionModule } from '../intervention/intervention.module';
import { LayoutStoreModule } from '../layout/store';
import { MidgardControlsModule } from '../midgard-controls/midgard-controls.module';
import { CreateInterventionRightPanelModule } from '../intervention/dialogs/create-intervention-right-panel/create-intervention-right-panel.module';
import { CreateDroneRightPanelModule } from '../drone/dialogs/create-drone-right-panel/create-drone-right-panel.module';
import { DroneCommonModule } from '../drone/common/drone-common.module';
import { MediaModule } from '../media/media.module';
import { InterventionsMapComponent } from './interventions-map/interventions-map.component';

@NgModule({
  declarations: [DashboardPageComponent, InterventionsMapComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    CoreModule,
    LayoutStoreModule,
    DroneModule,
    MediaModule,
    MatSnackBarModule,
    MatMenuModule,
    MidgardControlsModule,
    MatBadgeModule,
    MatTabsModule,
    InterventionModule,
    ActivityStreamModule,
    CreateInterventionRightPanelModule,
    CreateDroneRightPanelModule,
    DroneCommonModule,
  ],
  exports: [DashboardPageComponent],
})
export class DashboardModule {}
