import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Organization } from 'src/app/autogenerated/model';
import { OrganizationService } from 'src/app/autogenerated/organizationService';
import { DroneService } from 'src/app/autogenerated/droneService';
import { environment } from 'src/environments/environment';
import { OrganizationsMediaService } from 'src/api';
import { isEmpty } from 'lodash';
import { TdrawService } from 'src/app/autogenerated/tdrawService';

export enum SharingType {
  MEDIA = 'media',
  STREAM = 'stream',
  TDRAW_PIC = 'tdrawpic',
  TDRAW_GIS = 'tdrawgis',
}

@Component({
  selector: 'app-simple-header',
  templateUrl: './simple-header.component.html',
  styleUrls: ['./simple-header.component.scss'],
})
export class SimpleHeaderComponent implements OnInit {
  @Input()
  sharingType: SharingType;

  organization: Organization;
  sharing: { organization?: string | Organization };

  get organizationLogoUrl(): string | null {
    if (!this.organization?.logoFileName) {
      return null;
    }
    return environment.backend + 'public/logos/' + this.organization.logoFileName;
  }

  constructor(
    private readonly organizationService: OrganizationService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly organizationsMediaService: OrganizationsMediaService,
    private readonly droneService: DroneService,
    private readonly tdrawService: TdrawService
  ) {}

  async ngOnInit(): Promise<void> {
    console.log('sharing resource of type ' + this.sharingType);
    this.activatedRoute.params.subscribe(async (params) => {
      const sharingId = params['id'];
      this.sharing = await this.getCurrentSharing(sharingId);

      const currentOrganizationId = this.sharing?.organization as string;
      if (currentOrganizationId) {
        // TODO: add API to get Organization logo with public permission without login
        // this.organization = await this.organizationService.getOrganization({ _id: currentOrganizationId });
      }
    });
  }

  private async getCurrentSharing(sharingId: string) {
    switch (this.sharingType) {
      case SharingType.MEDIA:
        return this.getMediaSharing(sharingId);
      case SharingType.STREAM:
        return this.getStreamSharing(sharingId);
      case SharingType.TDRAW_PIC:
      case SharingType.TDRAW_GIS:
        return this.getTDrawSharing(sharingId);
      default:
        return null;
    }
  }

  private async getStreamSharing(sharingId: string) {
    try {
      const sharing = await this.droneService.getSharing({ _id: sharingId });
      if (!isEmpty(sharing)) {
        return sharing;
      }
      return null;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  private async getMediaSharing(sharingId: string) {
    try {
      const sharing = await this.organizationsMediaService.mediaShareControllerFindOne(sharingId).toPromise();
      if (!isEmpty(sharing)) {
        return sharing;
      }
      return null;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  private async getTDrawSharing(sharingId: string) {
    try {
      const sharing = await this.tdrawService.getSharedMapStateData(sharingId);
      if (!isEmpty(sharing)) {
        return { organization: sharing.organizationId };
      }
      return null;
    } catch (e) {
      console.error(e);
      return null;
    }
  }
}
