<app-media-right-panel
  [displayState]="pinpointMediaPanelDisplayState"
  [media]="pinpointMedia"
  [type]="PinpointMediaPanelType.default"
  (displayStateChanged)="pinpointMediaPanelDisplayState = $event"
>
</app-media-right-panel>

<div class="map-container">
  <div class="mapbox-container">
    <div id="map-home"></div>
    <tdraw-mapmode-info [isEditable]="false"> </tdraw-mapmode-info>
    <button
      *ngIf="mustShowLoad3dModelBtn"
      class="load-3d-model-btn"
      (click)="onLoad3dModel()"
    >
      <i class="fas fa-cube"></i>
      <span>{{ 'TDRAW.LOAD_3D_MODEL' | translate }}</span>
    </button>

    <tdraw-map-loading-bar
      *ngIf="gisManager"
      [title]="'TDRAW.3D_MODEL_LOADING' | translate"
      [percent]="gisManager.loading3dModelsInfo?.percent"
    >
    </tdraw-map-loading-bar>
  </div>

  <tdraw-right-panel [data]="rightPanelData">
    <tdraw-readonly-properties-panel
      *ngIf="rightPanelData.mode === RightPanelMode.Properties"
      [viewModeManager]="viewModeManager"
      (goToToolbox)="setRightPanelMode(RightPanelMode.Navigation)"
    >
    </tdraw-readonly-properties-panel>

    <tdraw-history-panel
      *ngIf="rightPanelData.mode === RightPanelMode.Navigation"
      [interventionId]="interventionId"
      [mapStateGroups]="mapStateGroups"
      [selectedMapState]="selectedMapState"
      [infopoint]="currentInfo"
      (newGisVersion)="onNewGisVersion()"
      (newPicVersion)="onNewPicVersion()"
      (mapStateSelected)="onMapStateSelected($event)"
      (mapStatePublish)="onMapStatePublish($event)"
      (mapStateRename)="onMapStateRename($event)"
      (mapStateDuplicate)="onMapStateDuplicate($event)"
      (mapStateDownload)="onMapStateDownload($event)"
      (mapStateDelete)="onMapStateDelete($event)"
    >
    </tdraw-history-panel>
  </tdraw-right-panel>
</div>
