import { Intervention } from './../../autogenerated/model';
import { Action } from '@ngrx/store';
import moment from 'moment';
import { ResetStateAction } from 'src/app/core/store';

export enum InterventionActionType {
  SetCurrentIntervention = '[Intervention] SetCurrentIntervention',
  SetOrganizationInterventions = '[Intervention] SetOrganizationInterventions',
  SetOrganizationIntervention = '[Intervention] SetOrganizationIntervention',
  UpdateIntervention = '[Intervention] UpdateIntervention',
}

export class SetCurrentInterventionAction implements Action {
  readonly type = InterventionActionType.SetCurrentIntervention;

  readonly organizationId: string;
  readonly interventionId: string;

  constructor(organizationId: string, interventionId: string) {
    this.organizationId = organizationId;
    this.interventionId = interventionId;
  }
}

export class SetOrganizationInterventionsAction implements Action {
  readonly type = InterventionActionType.SetOrganizationInterventions;

  readonly organizationId: string;
  readonly interventions: Intervention[];

  constructor(organizationId: string, interventions: Intervention[]) {
    this.organizationId = organizationId;
    this.interventions = interventions;
  }
}

export class SetOrganizationInterventionAction implements Action {
  readonly type = InterventionActionType.SetOrganizationIntervention;

  readonly organizationId: string;
  readonly intervention: Intervention;

  constructor(organizationId: string, intervention: Intervention) {
    this.organizationId = organizationId;
    this.intervention = intervention;
  }
}

export class UpdateInterventionAction implements Action {
  readonly type = InterventionActionType.UpdateIntervention;

  readonly organizationId: string;
  readonly intervention: Intervention;

  constructor(organizationId: string, intervention: Intervention) {
    this.organizationId = organizationId;
    this.intervention = intervention;
    this.intervention.isClosed = intervention.endDate
      ? moment(intervention.endDate).local() < moment(new Date()).local()
      : false;
  }
}

export type InterventionActions =
  | SetCurrentInterventionAction
  | SetOrganizationInterventionsAction
  | SetOrganizationInterventionAction
  | UpdateInterventionAction
  | ResetStateAction;
