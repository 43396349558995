<!-- BROADCASTING DRONE CARD -->
<ng-container [ngSwitch]="drone?.streamingStatus">
  <div class="drone-card" *ngSwitchCase="StreamingStatus.streaming">
    <button *ngIf="isBroadcastLinkGenerated && !singleStreamView" class="drone-fixed-btn-left">
      <i class="fas fa-share" (click)="redirectToStream()"></i>
    </button>
    <div class="drone-top-buttons">
      <button class="drone-fixed-btn" *ngIf="!singleStreamView">
        <i class="fas fa-expand-arrows-alt" [routerLink]="['/drone', drone?._id, 'stream']"></i>
      </button>
      <button class="drone-fixed-btn" *ngIf="isAdmin" [matMenuTriggerFor]="menu">
        <i class="fas fa-ellipsis-v"></i>
      </button>
      <mat-menu #menu="matMenu" class="mid-menu">
        <div class="mid-menu-info">
          <span>{{ 'DRONE_CARD.MENU.INFO_INTERVENTION' | translate }}</span>
          <span *ngIf="intervention?.name; else elseNoIntervention">{{ intervention?.name }}</span>
          <ng-template #elseNoIntervention>
            <span>{{ 'DRONE_CARD.MENU.NO_INTERVENTION' | translate }}</span>
          </ng-template>
        </div>
        <div class="mid-menu-info">
          <span>{{ 'DRONE_CARD.MENU.BROADCAST_SHARING_STATUS' | translate }}</span>
          <span *ngIf="isBroadcastLinkGenerated">
            <i class="fa-solid fa-eye"></i>
            (<span (click)="onOpenDroneShareLink()" class="share-link-btn">{{
              'DRONE_CARD.MENU.ACTIVE' | translate
            }}</span
            >)
            <!-- (<a (click)="onOpenDroneShareLink()">{{ 'DRONE_CARD.MENU.EDITH_SHARING' | translate }}</a>) -->
          </span>
          <span *ngIf="!isBroadcastLinkGenerated">
            <i class="fa-solid fa-eye-slash"></i>
            (<span (click)="onOpenDroneShareLink()" class="share-link-btn">{{
              'DRONE_CARD.MENU.DISABLE' | translate
            }}</span
            >)
            <!-- (<a (click)="onOpenDroneShareLink()">{{ 'DRONE_CARD.MENU.EDITH_SHARING' | translate }}</a>) -->
          </span>
        </div>
        <div class="mid-menu-item" (click)="onOpenAssociateDroneToIntervention()">
          <i class="fas fa-random mr-1 mid-menu-item-icon"></i>
          <span> {{ 'DRONE_CARD.MENU.ASSOCIATE_TO_INTERVENTION' | translate }} </span>
        </div>
        <div class="mid-menu-item" (click)="onOpenDroneProperties()">
          <i class="fas fa-link mr-1 mid-menu-item-icon"></i>
          <span> {{ 'DRONE_CARD.MENU.SEE_PROPERTIES_OF_DRONE' | translate }} </span>
        </div>
        <div class="mid-menu-item" (click)="onOpenDroneShareLink()">
          <i class="fas fa-share mr-1 mid-menu-item-icon"></i>
          <span> {{ 'DRONE_CARD.MENU.SHARE_STREAM_LINK' | translate }} </span>
        </div>
      </mat-menu>
    </div>
    <ng-container *ngIf="isFreeSpaceClickable; else notClickable">
      <app-drone-stream-player
        class="stream-preview"
        [drone]="drone"
        [isFreeSpaceClickable]="isFreeSpaceClickable"
        (freeSpaceClicked)="onFreeSpaceClicked()"
      ></app-drone-stream-player>
    </ng-container>
    <ng-template #notClickable>
      <app-drone-stream-player
        class="stream-preview"
        [class.fullscreen]="singleStreamView"
        [drone]="drone"
      ></app-drone-stream-player>
    </ng-template>
    <div class="drone-footer">
      <div class="drone-info">
        <i class="fas fa-helicopter"></i>
        <span>{{ drone?.name }} ({{ drone?.droneModel }})</span>
        <app-intervention-name-badge
          *ngIf="singleStreamView && showInterventionBadge"
          class="intervention-badge"
          [interventionId]="drone?.currentIntervention"
          [isLinkButtonType]="true"
          (interventionChanged)="interventionChanged($event)"
        >
        </app-intervention-name-badge>
      </div>
      <div class="drone-camera-info">
        <div
          *ngIf="!intervention || intervention.isGlobalRecording !== false; else notRecording"
          class="drone-rec recording"
          [appMidTooltip]="'DRONE_CARD.CONTENT.RECORDING' | translate"
          click-stop-propagation
        >
          <span>REC</span><i class="fas fa-record-vinyl"></i>
        </div>
        <ng-template #notRecording>
          <div
            class="drone-rec not-recording"
            [appMidTooltip]="'DRONE_CARD.CONTENT.NOT_RECORDING' | translate"
            click-stop-propagation
          >
            <span>REC</span><i class="fas fa-record-vinyl"></i>
          </div>
        </ng-template>

        <button class="mid-icon" (click)="createSnapshot($event)">
          <i class="fas fa-camera mid-fa-icon"></i>
          <i class="fas fa-cog fa-spin mid-fa-loading"></i>
          <i class="fas fa-check mid-fa-success"></i>
        </button>
      </div>
    </div>
  </div>

  <!-- NOT BROADCASTING DRONE CARD -->
  <div *ngSwitchDefault class="drone-card-not_broadcast">
    <div class="drone-top-buttons">
      <button class="drone-fixed-btn" *ngIf="!singleStreamView">
        <i class="fas fa-expand-arrows-alt" [routerLink]="['/drone', drone?._id, 'stream']"></i>
      </button>
      <button class="drone-fixed-btn" *ngIf="isAdmin" [matMenuTriggerFor]="menu">
        <i class="fas fa-ellipsis-v"></i>
      </button>
      <mat-menu #menu="matMenu" class="mid-menu">
        <div class="mid-menu-info">
          <span>{{ 'DRONE_CARD.MENU.INFO_INTERVENTION' | translate }}</span>
          <span *ngIf="intervention?.name; else elseNoIntervention">{{ intervention?.name }}</span>
          <ng-template #elseNoIntervention>
            <span>{{ 'DRONE_CARD.MENU.NO_INTERVENTION' | translate }}</span>
          </ng-template>
        </div>
        <div class="mid-menu-info">
          <span>{{ 'DRONE_CARD.MENU.BROADCAST_SHARING_STATUS' | translate }}</span>
          <span *ngIf="isBroadcastLinkGenerated">
            <i class="fa-solid fa-eye"></i>
            (<span (click)="onOpenDroneShareLink()" class="share-link-btn">{{
              'DRONE_CARD.MENU.ACTIVE' | translate
            }}</span
            >)
            <!-- (<a (click)="onOpenDroneShareLink()">{{ 'DRONE_CARD.MENU.EDITH_SHARING' | translate }}</a>) -->
          </span>
          <span *ngIf="!isBroadcastLinkGenerated">
            <i class="fa-solid fa-eye-slash"></i>
            (<span (click)="onOpenDroneShareLink()" class="share-link-btn">{{
              'DRONE_CARD.MENU.DISABLE' | translate
            }}</span
            >)
            <!-- (<a (click)="onOpenDroneShareLink()">{{ 'DRONE_CARD.MENU.EDITH_SHARING' | translate }}</a>) -->
          </span>
        </div>

        <div class="mid-menu-item" (click)="onOpenAssociateDroneToIntervention()">
          <i class="fas fa-random mr-1 mid-menu-item-icon"></i>
          <span> {{ 'DRONE_CARD.MENU.ASSOCIATE_TO_INTERVENTION' | translate }} </span>
        </div>
        <div class="mid-menu-item" (click)="onOpenDroneProperties()">
          <i class="fas fa-link mr-1 mid-menu-item-icon"></i>
          <span> {{ 'DRONE_CARD.MENU.SEE_PROPERTIES_OF_DRONE' | translate }} </span>
        </div>
        <div class="mid-menu-item" (click)="onOpenDroneShareLink()">
          <i class="fas fa-share mr-1 mid-menu-item-icon"></i>
          <span> {{ 'DRONE_CARD.MENU.SHARE_STREAM_LINK' | translate }} </span>
        </div>
        <div
          class="mid-menu-item mid-menu-item-red"
          *ngIf="drone?.currentIntervention"
          (click)="onUnlinkDroneToIntervention()"
        >
          <i class="fas fa-unlink mr-1 mid-menu-item-icon red-icon"></i>
          <span> {{ 'DRONE_CARD.MENU.UNLINK_DRONE_TO_INTERVENTION' | translate }} </span>
        </div>
        <div class="mid-menu-item mid-menu-item-fill-red" (click)="onDeleteDrone()">
          <i class="fas fa-times mr-1 mid-menu-item-icon"></i>
          <span> {{ 'DRONE_CARD.MENU.REMOVE' | translate }}</span>
        </div>
      </mat-menu>
    </div>
    <div class="drone-mid-info">
      <span class="drone-status"> {{ 'DRONE_CARD.CONTENT.STREAM_NOT_AVAILABLE' | translate }} </span>
      <app-mid-button
        *ngIf="(!isAdmin && isBroadcastLinkGenerated) || isAdmin"
        type="primary"
        (click)="onOpenDroneShareLink()"
      >
        {{ 'DRONE_CARD.CONTENT.SHARE_BROADCAST_LINK' | translate }}
      </app-mid-button>
    </div>
    <div class="drone-footer">
      <div class="drone-info">
        <i class="fas fa-helicopter"></i>
        <span>{{ drone?.name }}{{ drone?.droneModel ? ' (' + drone?.droneModel + ')' : '' }}</span>
        <app-intervention-name-badge
          *ngIf="singleStreamView"
          class="intervention-badge"
          [interventionId]="drone?.currentIntervention"
          [isLinkButtonType]="true"
          (interventionChanged)="interventionChanged($event)"
        >
        </app-intervention-name-badge>
      </div>
    </div>
    <div *ngIf="isFreeSpaceClickable" class="clickable-side" (click)="onFreeSpaceClicked()"></div>
  </div>
</ng-container>
