export class SharedMapStateCreationDto {
  mapStateId: string;
  duration: number;
  type: string;

  constructor({ mapStateId, duration, type }: Partial<SharedMapStateCreationDto> = {}) {
    this.mapStateId = mapStateId;
    this.duration = duration ?? 1;
    this.type = type ?? 'gis';
  }
}
