import { Injectable } from '@angular/core';
import { UserDto, UsersService } from 'src/api';

@Injectable({
  providedIn: 'root',
})
export class UserApiClientService {
  private cache = new Map<string, UserDto>();

  constructor(private readonly usersService: UsersService) {
    usersService.userControllerGet;
  }

  async getUser(userId: string) {
    if (this.cache.has(userId)) return this.cache.get(userId);
    const user = await this.usersService.userControllerGet(userId).toPromise();
    this.cache.set(user.id, user);
    return user;
  }
}
