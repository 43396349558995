import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MapState } from 'src/app/tdraw/models/map-state.model';

@Component({
  selector: 'tdraw-version-list',
  templateUrl: './version-list.component.html',
  styleUrls: ['./version-list.component.scss'],
})
export class VersionListComponent {
  @Input()
  versionItems: MapState[];

  @Input()
  selectedMapState: MapState;

  @Input()
  interventionId: string;

  @Output()
  mapStateSelected: EventEmitter<MapState>;

  @Output()
  publish: EventEmitter<MapState>;

  @Output()
  rename: EventEmitter<MapState>;

  @Output()
  duplicate: EventEmitter<MapState>;

  @Output()
  download: EventEmitter<MapState>;

  @Output()
  deletingMapState: EventEmitter<MapState>;

  constructor() {
    this.mapStateSelected = new EventEmitter();
    this.publish = new EventEmitter();
    this.rename = new EventEmitter();
    this.duplicate = new EventEmitter();
    this.download = new EventEmitter();
    this.deletingMapState = new EventEmitter();
  }

  onMapStateSelected(item: MapState) {
    this.mapStateSelected.next(item);
  }

  onMapStatePublish(item: MapState) {
    this.publish.emit(item);
  }

  onMapStateRename(item: MapState) {
    this.rename.emit(item);
  }

  onMapStateDuplicate(item: MapState) {
    this.duplicate.emit(item);
  }

  onMapStateDownload(item: MapState) {
    this.download.emit(item);
  }

  onDeleteMapState(item: MapState) {
    this.deletingMapState.emit(item);
  }
}
