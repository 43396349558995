import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InterventionService } from 'src/app/autogenerated/interventionService';
import { Alert, TimeOfDay } from 'src/app/autogenerated/model';

export type Filter = {
  timeOfDay?: TimeOfDay;
  days?: number;
};

@Component({
  selector: 'app-intervention-alerts',
  templateUrl: './intervention-alerts.component.html',
  styleUrls: ['./intervention-alerts.component.scss'],
})
export class InterventionAlertsComponent {
  @Input()
  alerts: Alert[] = [];

  @Input()
  interventionId: string;

  @Input()
  organizationId: string;

  @Input()
  selectedAlert: Alert;

  @Output()
  alertSelected: EventEmitter<Alert> = new EventEmitter();

  @Input()
  filter: Filter;

  @Output()
  filterChange: EventEmitter<Filter> = new EventEmitter();

  constructor(private interventionService: InterventionService) {
    this.alerts = [];
    this.filter = { timeOfDay: 'all', days: 3 };
  }

  onAlertSelected(alert: Alert) {
    this.alertSelected.emit(alert);
  }

  trackByItem(index: number, item: Alert): string {
    return item.video_path;
  }

  setFilterDay(days: number) {
    this.filter.days = days;
    this.filterChange.emit(this.filter);
  }

  setFilterTimeOfDay(timeOfDay: 'day' | 'night') {
    if (timeOfDay === 'day' && this.filter.timeOfDay === 'night') {
      this.filter.timeOfDay = 'all';
    } else if (timeOfDay === 'night' && this.filter.timeOfDay === 'day') {
      this.filter.timeOfDay = 'all';
    } else {
      this.filter.timeOfDay = timeOfDay;
    }
    this.filterChange.emit(this.filter);
  }

  resetFilter() {
    this.filter.timeOfDay = 'all';
    this.filter.days = undefined;
    this.filterChange.emit(this.filter);
  }
}
