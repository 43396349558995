import mapboxgl from 'mapbox-gl';
import * as turf from '@turf/turf';
import _ from 'lodash';
import { MediaService } from 'src/app/autogenerated/mediaService';
import { ServiceLocator } from 'src/app/global/locator.service';
import { AuthenticationStoreService } from 'src/app/authentication/store';
import { TdrawService } from 'src/app/autogenerated/tdrawService';
import { FeatureCollection } from '@turf/turf';
import { MapModeManager } from './map-mode-manager';

const SourceId = 'bg-media-source';
const LayerId = 'bg-media-layer';

/**
 * PicManager handles most logic of PIC Maps
 *
 * Examples of responsabilities:
 * - MapState.BackgroundMedia (use of image as Map background)
 */
export class PicManager {
  private mediaService: MediaService;
  private tdrawService: TdrawService;
  private authToken: string;

  constructor(private map: mapboxgl.Map) {
    this.mediaService = ServiceLocator.injector.get(MediaService);
    this.tdrawService = ServiceLocator.injector.get(TdrawService);

    const authenticationStoreService = ServiceLocator.injector.get(AuthenticationStoreService);
    authenticationStoreService.getAccessToken().subscribe((token: string) => {
      this.authToken = token;
    });
  }

  loadDataOnMap(mapModeManager: MapModeManager, inPreview: boolean, sharedMapStateId?: string) {
    const mapState = mapModeManager.getMapState();
    const { mediaId, coordinates } = mapState.backgroundMedia;

    if (sharedMapStateId) {
      // In shared page
      this.loadShareBackgroundMedia(sharedMapStateId, coordinates).then(() => {
        mapModeManager.displayMapStateDataOnMap();
      });
    } else {
      // In regular page
      this.loadBackgroundMedia(mediaId, inPreview, coordinates).then(() => {
        mapModeManager.displayMapStateDataOnMap();
      });
    }
  }

  /**
   * Load a background media for a specific SharedMapState
   */
  async loadShareBackgroundMedia(sharedMapStateId: string, mediaCoords?: number[][]): Promise<void> {
    const imageUrl = this.tdrawService.getSharedMapStateBackgroundImageDownloadUrl(sharedMapStateId);
    await this.displayImageInBackground(imageUrl, mediaCoords);
  }

  async loadBackgroundMedia(mediaId: string, inPreview: boolean, mediaCoords?: number[][]) {
    const preview = false; // inPreview ? 'true' : 'false';
    const media = await this.mediaService.getMedia({ _id: mediaId });
    const downloadUrl = this.mediaService.getDownloadUrl(media, preview);
    const imageUrl = downloadUrl + `&token=${this.authToken}`;
    await this.displayImageInBackground(imageUrl, mediaCoords);
  }

  unloadBackgroundMedia() {
    if (this.map.getSource(SourceId)) {
      this.map.removeLayer(LayerId);
      this.map.removeSource(SourceId);
    }
  }

  getBackgroundMediaCoordinates(): number[][] {
    return (<any>this.map.getSource(SourceId)).coordinates;
  }

  private async displayImageInBackground(imageUrl: string, mediaCoords?: number[][]): Promise<void> {
    this.hideAllLayers();

    // If no mediaCoords are present, we need to compute them from image dimensions
    const coordinates = mediaCoords?.length
      ? mediaCoords
      : await new Promise<number[][]>((resolve, reject) => {
          this.map.loadImage(imageUrl, (e, r) => {
            const coordinates = this.imageSizeToCoordinates(r.width, r.height);
            resolve(coordinates);
          });
        });

    const sw = coordinates[3];
    const ne = coordinates[1];

    this.map.addSource(SourceId, {
      type: 'image',
      url: imageUrl,
      coordinates,
    });

    this.map.addLayer({
      id: LayerId,
      source: SourceId,
      type: 'raster',
      paint: {
        'raster-fade-duration': 0,
      },
    });

    this.map.fitBounds(<any>[sw, ne], {
      animate: false,
    });
  }

  private hideAllLayers() {
    const layers = this.map.getStyle().layers;
    for (let layer of layers) {
      this.map.setLayoutProperty(layer.id, 'visibility', 'none');
    }
  }

  private imageSizeToCoordinates(width, height): number[][] {
    const kmWidth = width / 1000;
    const kmHeight = height / 1000;
    const sw = [10, 39];
    const se = turf.destination(turf.point(sw), kmWidth, 90).geometry.coordinates;
    const nw = turf.destination(turf.point(sw), kmHeight, 0).geometry.coordinates;
    const ne = turf.destination(turf.point(nw), kmWidth, 90).geometry.coordinates;
    return [nw, ne, se, sw];
  }
}
