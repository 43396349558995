import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClipboardService } from 'src/app/core/utils/clipboard.service';
import { OrganizationsMediaService, SharedMediaFileDto, UserDto } from 'src/api';
import { MediaApiClientService } from 'src/api-services/media-api-client.service';
import { UserApiClientService } from 'src/api-services/user-api-client.service';
import { ConfirmModalService } from 'src/app/midgard-controls/services/confirm-modal.service';
import { isEmpty } from 'lodash';

@Component({
  selector: 'app-media-share-view',
  templateUrl: './media-share-view.component.html',
  styleUrls: ['./media-share-view.component.scss'],
})
export class MediaShareViewComponent implements OnInit {
  @Input()
  share: SharedMediaFileDto;

  @Output()
  readonly shareDeleted: EventEmitter<SharedMediaFileDto> = new EventEmitter();

  user: UserDto;

  constructor(
    private readonly usersService: UserApiClientService,
    private readonly clipboardService: ClipboardService,
    private readonly mediaApiClientService: MediaApiClientService,
    private readonly organizationsMediaService: OrganizationsMediaService,
    private readonly confirmModalService: ConfirmModalService
  ) {
    this.user = null;
  }

  get isEmpty() {
    return isEmpty;
  }

  async ngOnInit(): Promise<void> {
    this.user = await this.usersService.getUser(this.share.sharedBy);
  }

  async copyShareLink(): Promise<void> {
    await this.clipboardService.copyToClipboard(this.mediaApiClientService.getShareLink(this.share));
  }

  async onDeleteShare(): Promise<void> {
    const deleteResult = await this.confirmModalService.showConfirmModal(
      'DIALOGS.SHARE_MEDIA_LINK.DELETE_SHARE_LINK_CONFIRM_MESSAGE',
      'remove'
    );
    if (deleteResult) {
      await this.deleteShare();
    }
  }

  async deleteShare(): Promise<void> {
    await this.organizationsMediaService.mediaShareControllerDelete(this.share.id).toPromise();
    this.shareDeleted.emit(this.share);
  }
}
