<app-mid-right-panel #panelNewGroup="appMidRightPanel" [title]="'GROUP_ACTIONS.ADD' | translate">
  <app-mid-right-panel-content>
    <div class="m-0 px-4 w-100">
      <app-mid-input
        required
        size="wide"
        type="text"
        #groupName="appMidInput"
        name="groupName"
        [label]="'CREATE_GROUP.GROUP_NAME' | translate"
        [placeholder]="'CREATE_GROUP.ENTER_GROUP_NAME' | translate"
        [(value)]="name"
      >
        <span *ngIf="!groupName.pristine && groupName.invalid" class="warning-input-text">{{
          'COMMON_REQUIRED_FIELD' | translate
        }}</span>
      </app-mid-input>
    </div>
    <div class="m-0 px-4 w-100">
      <app-role-select (selectedRole)="onRoleSelected($event)"></app-role-select>
    </div>
  </app-mid-right-panel-content>
  <app-mid-right-panel-footer>
    <div class="row m-0 py-3 px-4">
      <app-mid-button
        type="primary"
        icon="fas fa-check"
        (action)="createGroup()"
        class="pr-2"
        [disabled]="isEmpty(this.name) || isEmpty(this.selectedRole)"
      >
        {{ 'ADD' | translate }}</app-mid-button
      >
      <app-mid-button class="pr-2" type="secondary" (action)="panelNewGroup.close()">{{
        'DIALOGS.COMMON.CANCEL' | translate
      }}</app-mid-button>
    </div>
  </app-mid-right-panel-footer>
</app-mid-right-panel>
