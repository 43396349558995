<!-- class="mid-header {{ suppClass }}" -->
<header class="mid-header">
  <nav class="mid-navbar">
    <div class="flex-container">
      <div class="d-flex">
        <!-- logos -->
        <div class="col-auto">
          <div class="polygon">
            <img
              (click)="goTo('/')"
              class="img-fluid mr-3 mid-logo"
              src="/assets/images/midgard-logo-black.png"
              alt=""
            />
            <img *ngIf="organizationLogoUrl" class="img-fluid mid-org-logo" [src]="organizationLogoUrl" alt="" />
          </div>
          <div class="mobile-logo">
            <img (click)="goTo('/')" width="115" src="/assets/images/mobile-logo.png" alt="" />
          </div>
        </div>

        <!-- breadcrumbs -->
        <div class="desktop-breadcrumbs-container col-auto d-sm-block">
          <div class="breadcrumbs">
            <div
              *ngFor="let crumb of breadcrumbStateService.Breadcrumbs; index as i"
              class="crumb"
              (click)="goTo(crumb.url)"
            >
              <div *ngIf="crumb.label">
                <i class="divider fas {{ crumb.icon ? crumb.icon : 'fa-chevron-right' }}"></i>
                <span class="ml-1">
                  <i *ngIf="crumb.additionalIcon" class="fas {{ crumb.additionalIcon }} mr-1"></i
                  >{{ crumb.label | translate }}
                  <i
                    *ngIf="i === breadcrumbStateService.Breadcrumbs.length - 1 && i !== 0"
                    class="crumb-icon fas fa-caret-down"
                  ></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- various icons -->
      <div class="d-flex">
        <div class="icons">
          <!-- Search an intervention -->
          <!-- TODO:
          <i class="fas fa-search"></i>
          -->
          <!-- Follows intervention activities -->
          <!-- TODO:
          <div
            matBadge="{{ mediaUploadService.files.length }}"
            [matBadgeHidden]="mediaUploadService.files.length === 0"
            matBadgeColor="warn"
            matBadgeSize="small"
            #nmt="matMenuTrigger"
            mat-button
            [matMenuTriggerFor]="mainMediaMenu"
            (mousedown)="toggleNotificationMenu($event)"
            [ngClass]="{ 'mid-active': nmt.menuOpen }"
            class="float-right pr-1 pl-1 mid-notification-icon"
          >
            <i class="fas fa-bell"></i>
          </div>
          -->
        </div>

        <!-- media menu -->
        <div
          class="media"
          (click)="onOpenMainMediaMenu()"
          matBadge="{{ mediaUploadService.files.length }}"
          [matBadgeHidden]="mediaUploadService.files.length === 0"
          matBadgeColor="warn"
          matBadgeSize="small"
        >
          <!--
          #nmt="matMenuTrigger"
          mat-button
          [matMenuTriggerFor]="mainMediaMenu"
          (mousedown)="toggleMediaMenu($event)"
          -->
          <div class="media-label text-truncate">
            <i class="fas fa-photo-video"></i>
            <span>{{ 'MAIN_MEDIA_MENU.MEDIA' | translate }}</span>
            <i class="fas fa-chevron-down"></i>
          </div>
          <div class="no-import text-truncate" [ngSwitch]="!!mediaUploadService.files.length">
            <ng-container *ngSwitchCase="true">
              {{ 'MAIN_MEDIA_MENU.STATES.IMPORT' | translate }}
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{ 'MAIN_MEDIA_MENU.STATES.NO_IMPORT' | translate }}
            </ng-container>
          </div>
        </div>

        <!--
        <mat-menu #mainMediaMenu="matMenu" class="mid-notification-panel" xPosition="after">
          <app-media-notification-progress></app-media-notification-progress>
          <app-organization-activity-stream></app-organization-activity-stream>
        </mat-menu>
        -->

        <!-- main app menu -->
        <div class="pr-1 pl-1 mid-user" data-step="5">
          <div class="username">
            <span> {{ username.name }} </span>
            <span> {{ username.surname }} </span>
          </div>
          <div
            class="user-circle {{ username.usernameClass }}"
            (click)="onOpenMainAppMenu()"
            [attr.data-intro]="'INTRO_PROFILE_ICON' | translate"
            [attr.data-title]="'INTRO_TITLE' | translate"
          >
            <span class="user-initials"> {{ username.userInitials }} </span>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <div class="mobile-breadcrumbs-container">
    <div class="breadcrumbs text-truncate">
      <div *ngFor="let crumb of breadcrumbStateService.Breadcrumbs" class="crumb" (click)="goTo(crumb.url)">
        <div *ngIf="crumb.label">
          <i class="divider fas {{ crumb.icon ? crumb.icon : 'fa-chevron-right' }}"></i>
          <span class="ml-2">
            <i *ngIf="crumb.additionalIcon" class="fas {{ crumb.additionalIcon }}"></i>
            {{ crumb.label | translate }}
          </span>
        </div>
      </div>
    </div>
  </div>
</header>
