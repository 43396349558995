import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'tdraw-duplicate-version-modal',
  templateUrl: './duplicate-version-modal.component.html',
  styleUrls: ['./duplicate-version-modal.component.scss'],
})
export class DuplicateVersionModalComponent {
  newName: string;

  constructor(
    private dialogRef: MatDialogRef<DuplicateVersionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.newName = data;
  }

  onSubmit() {
    this.dialogRef.close(this.newName);
  }

  onClose() {
    this.dialogRef.close();
  }
}
