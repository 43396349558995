import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { AnalysesPageComponent } from './analyses-page/analyses-page.component';
import { CoreModule } from 'src/app/core/core.module';
import { MidgardControlsModule } from 'src/app/midgard-controls/midgard-controls.module';
import { InterventionCommonModule } from '../../common/intervention-common.module';
import { PhotogrammetryUploadDialogComponent } from './photogrammetry-upload-dialog/photogrammetry-upload-dialog.component';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [AnalysesPageComponent, PhotogrammetryUploadDialogComponent],
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    MidgardControlsModule,
    InterventionCommonModule,
    MatDialogModule,
    MatProgressBarModule,
    MatCheckboxModule,
  ],
  exports: [AnalysesPageComponent],
})
export class InterventionPageAnalysesModule {}
