import { Injectable } from '@angular/core';
import { MonitoringService } from '../global/monitoring.service';
import { required, validate } from '../shared';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  constructor(private readonly monitoringService: MonitoringService) {}

  @validate
  error(@required message: string, ...details: any[]): void {
    // tslint:disable-next-line: no-console
    console.error(message, ...details);
  }

  @validate
  warning(@required message: string, ...details: any[]): void {
    // tslint:disable-next-line: no-console
    console.warn(message, ...details);
  }

  @validate
  info(@required message: string, ...details: any[]): void {
    // tslint:disable-next-line: no-console
    console.info(message, ...details);
  }

  captureException(exception: any) {
    if (!exception) {
      this.warning('Cannot capture empty exception.');
      return;
    }

    try {
      this.monitoringService.logException(exception);
      this.info(`Captured exception:`, exception);
    } catch (error) {
      this.error('Failed to capture exception.', exception, error);
    }
  }
}
