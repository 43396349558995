import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlContainer, NgForm, NgModel } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { InterventionType } from 'src/app/autogenerated/model';

interface InterventionTypeButtonToggles {
  value: string;
  name: string;
  color: string;
}

@Component({
  selector: 'app-select-intervention-type-button-toggles',
  templateUrl: './select-intervention-type-button-toggles.component.html',
  styleUrls: ['./select-intervention-type-button-toggles.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class SelectInterventionTypeButtonTogglesComponent implements OnInit {
  @Input()
  type: string = null;
  @Output()
  typeChange: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  disabled = false;

  @Input()
  required = true;

  interventionTypeButtonToggles: InterventionTypeButtonToggles[];

  get InterventionType() {
    return InterventionType;
  }

  @ViewChild('interventionTypeModel')
  private interventionTypeModel: NgModel;

  constructor(private readonly translate: TranslateService) {}

  async ngOnInit(): Promise<void> {
    this.interventionTypeButtonToggles = [
      {
        value: InterventionType.OPS,
        name: await this.translate.get('INTERVENTION_TYPE.OPS').toPromise(),
        color: '#dc3545',
      },
      {
        value: InterventionType.PRV,
        name: await this.translate.get('INTERVENTION_TYPE.PRV').toPromise(),
        color: '#28a745',
      },
      {
        value: InterventionType.COM,
        name: await this.translate.get('INTERVENTION_TYPE.COM').toPromise(),
        color: '#ffc107',
      },
      {
        value: InterventionType.TRAINING,
        name: await this.translate.get('INTERVENTION_TYPE.TRAINING').toPromise(),
        color: '#28a745',
      },
    ];
  }

  onTypeChange(event) {
    this.typeChange.emit(event);
  }

  get valid() {
    return this.interventionTypeModel?.valid;
  }

  get invalid() {
    return this.interventionTypeModel?.invalid;
  }

  get dirty() {
    return this.interventionTypeModel?.dirty;
  }

  get pristine() {
    return this.interventionTypeModel?.pristine;
  }

  get touched() {
    return this.interventionTypeModel?.touched;
  }

  get untouched() {
    return this.interventionTypeModel?.untouched;
  }
}
