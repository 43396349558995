import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Intervention, InterventionType } from 'src/app/autogenerated/model';

@Component({
  selector: 'app-intervention-details-card',
  templateUrl: './intervention-details-card.component.html',
  styleUrls: ['./intervention-details-card.component.scss'],
})
export class InterventionDetailsCardComponent implements OnInit {
  @Input()
  intervention: Intervention;

  @Input()
  numberDrones: number = 0;

  @Input()
  numberMedia: number = 0;

  get InterventionType() {
    return InterventionType;
  }

  constructor(private router: Router) {}

  ngOnInit(): void {}

  goToInterventionSettings() {
    this.router.navigate([`interventions/${this.intervention._id}/settings`]);
  }
}
