import { Feature, FeatureCollection } from '@turf/turf';
import _ from 'lodash';
import { Subject, Subscription } from 'rxjs';
import { MapAnnotationNestedDto } from '../models/dto';
import { FeatureProperty } from '../models/feature-property.enum';
import { MapState } from '../models/map-state.model';
import { RegularMarker } from '../models/regular-marker.model';
import { MapManager } from './map-manager';

export abstract class MapModeManager {
  selectedSourceId: string;
  selectedFeature: Feature;
  isEditMode: boolean;

  mapManager: MapManager;

  // Event emitters
  protected subjectFeatureSelectedChanged: Subject<{ feature: Feature; sourceId: string }>;
  protected subjectFeatureUnselected: Subject<void>;
  protected subjectFeaturesChanged: Subject<MapAnnotationNestedDto[]>;

  constructor(protected map: mapboxgl.Map, protected mapState?: MapState) {
    this.subjectFeatureSelectedChanged = new Subject();
    this.subjectFeatureUnselected = new Subject();
    this.subjectFeaturesChanged = new Subject();
  }

  //=====
  // Subscribers
  //=====

  /**
   * Fired when a new feature is selected or when the currently
   * selected feature changed (a property or its shape)
   */
  onFeatureSelectedChanged(callback: (data: { feature: Feature; sourceId: string }) => void): Subscription {
    return this.subjectFeatureSelectedChanged.subscribe(callback);
  }
  /**
   * Fired when feature have been unselected
   * or when there is no new selection
   */
  onFeatureUnselected(callback: () => void): Subscription {
    return this.subjectFeatureUnselected.subscribe(callback);
  }
  /**
   * Fired when change(s) happened to one or many features.
   * Can be fired when the complete dataset changes
   */
  onFeaturesChanged(callback: (annotations: MapAnnotationNestedDto[]) => void): Subscription {
    return this.subjectFeaturesChanged.subscribe(callback);
  }

  protected notifyFeatureSelectedChanged() {
    this.subjectFeatureSelectedChanged.next({
      feature: this.selectedFeature,
      sourceId: this.selectedSourceId,
    });
  }
  protected notifyFeatureUnselected() {
    this.subjectFeatureUnselected.next();
  }
  protected notifyFeaturesChanged() {
    this.subjectFeaturesChanged.next(this.getMapStateAnnotations());
  }

  //=====
  // Misc
  //=====

  setMapState(mapState: MapState) {
    this.mapState = mapState;
    this.notifyFeaturesChanged();
  }

  getMapState() {
    return this.mapState;
  }

  getMapStateAnnotations(): MapAnnotationNestedDto[] {
    return this.mapState?.annotations ?? [];
  }

  getMapType(): string {
    return this.mapState?.type;
  }

  displayRegularMarker(rm: RegularMarker, withControls: boolean = false, s: string) {
    this.mapManager.displayRegularMarker(rm, withControls, `public/assets-kmz/${this.mapState._id}`);
  }

  /**
   * Get feature IDs that represent sectors in the Map with their title
   *
   * The sectors can be assigned to features which are Resources
   * (see MapIcon with category === AnnotationCategory.Resources)
   *
   * The association is done by setting the featureId of the sector
   * to the FeatureProperty.SectorBelong property of the Resource MapIcon
   *
   * If a sector is deleted, the FeatureProperty.SectorBelong property
   * of Resources MapIcon which were using it is set to NULL.
   * (see EditModeManager@deleteSelectedSource method)
   */
  getSectors(): { featureId: string; title: string }[] {
    const annotations = this.getMapStateAnnotations();
    const sectors = [];
    for (const annotation of annotations) {
      const feature = annotation.data.features[0];
      const sectorTitle = feature.properties[FeatureProperty.SectorTitle];
      if (sectorTitle) {
        sectors.push({
          featureId: feature.id,
          title: sectorTitle,
        });
      }
    }
    return sectors;
  }

  displayMapStateDataOnMap(): void {
    if (!this.mapState) {
      return;
    }

    // Create a source for each annotation and displays it
    _.forEach(this.mapState.annotations, (annotation) => {
      const id = annotation.sourceId;
      const data = annotation.data as FeatureCollection;
      this.mapManager.updateOrCreateSource(data, id);
      this.mapManager.showSource(id);
    });

    // Display RegularMarkers
    this.mapManager.loadRegularMarkers(
      this.mapState.regularMarkers,
      this.isEditMode,
      `public/assets-kmz/${this.mapState._id}`
    );
  }

  //=====
  // Need to be overrided
  //=====

  abstract initSourceSelection(sourceId: string): void;
}
