import { Action } from '@ngrx/store';
import { Organization } from 'src/app/autogenerated/model';
import { ResetStateAction } from 'src/app/core/store';

export enum OrganizationActionType {
  SetCurrentOrganization = '[Organization] SetCurrentOrganization',
}

export class SetCurrentOrganizationAction implements Action {
  readonly type = OrganizationActionType.SetCurrentOrganization;

  readonly organization: Organization;

  constructor(organization: Organization) {
    this.organization = organization;
  }
}

export type OrganizationActions = SetCurrentOrganizationAction | ResetStateAction;
