import { NgModule, InjectionToken } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { userProfilesReducer, usersInitialState, UserProfilesState } from './user-profiles.reducer';
import { StoreStateService, featureStoreConfigFactory } from 'src/app/core/store';
import { StoreConfig } from '@ngrx/store/src/store_module';
import { userProfileConfig } from '../user-profile.config';

const featureStoreKey = userProfileConfig.store.key;

export const userProfilesFeatureStoreKeyToken = new InjectionToken(`FeatureStoreKeyInjectionToken:${featureStoreKey}`);
export const userProfilesFeatureStoreInitialStateToken = new InjectionToken<UserProfilesState>(
  `FeatureStoreInitialStateInjectionToken:${featureStoreKey}`
);
export const userProfilesStoreFeatueConfigToken = new InjectionToken<StoreConfig<UserProfilesState>>(
  `FeatureStoreConfigInjectionToken:${featureStoreKey}`
);

@NgModule({
  imports: [StoreModule.forFeature(featureStoreKey, userProfilesReducer, userProfilesStoreFeatueConfigToken)],
  providers: [
    {
      provide: userProfilesFeatureStoreKeyToken,
      useValue: featureStoreKey,
    },
    {
      provide: userProfilesFeatureStoreInitialStateToken,
      useValue: usersInitialState,
    },
    {
      provide: userProfilesStoreFeatueConfigToken,
      deps: [userProfilesFeatureStoreKeyToken, userProfilesFeatureStoreInitialStateToken, StoreStateService],
      useFactory: featureStoreConfigFactory,
    },
  ],
})
export class UserProfilesStoreModule {}
