import { EventEmitter, Injectable } from '@angular/core';
import { AuthenticationStoreService } from 'src/app/authentication/store';
import { LoggerService } from 'src/app/core/logger.service';
import { GuidService } from 'src/app/global/guid.service';

import { environment } from '../../../../../environments/environment';
import HugeUploader from 'huge-uploader';
import { Observable, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { BackendClientService } from 'src/app/global/backend-client.service';
import { TiffStatusMessage } from 'src/app/autogenerated/model';
import { UploadingFile } from 'src/app/media/common/media-upload/models/uploading-file';

export class UploadProgress {
  pauseEvent = new EventEmitter();
  uuid: string;
  projectName: string;
  date: Date;
  numberOfFiles: number;
  numberOfUploadedFiles: number;
  progressByFileMap: Map<string, { detail: number; paused: boolean; ended: boolean }>;
  aborted: boolean;
  paused: boolean;
  uploaders: { togglePause: { (): void } }[];
  worker: Worker;
  mediaId: string;
  constructor(uuid: string, numberOfFiles: number, numberOfUploadedFiles: number, projectName: string, date: Date) {
    this.uuid = uuid;
    this.numberOfFiles = numberOfFiles;
    this.numberOfUploadedFiles = numberOfUploadedFiles;
    this.projectName = projectName;
    this.date = date;
    this.aborted = false;
    this.paused = false;
    this.uploaders = [];
    this.progressByFileMap = new Map();
  }
  isComplete(): boolean {
    return this.numberOfFiles === this.numberOfUploadedFiles;
  }
  addUploader(uploader: { togglePause: { (): void } }) {
    this.uploaders.push(uploader);
  }
  removeUploader(uploader: any) {
    this.uploaders = this.uploaders.filter((value) => value != uploader);
  }
  togglePauseUploader() {
    // we mark all uploader as value.paused = 'false'
    // interpreted as : 'waiting for the last chunk to finish before stopping'
    // Cf.  updateProgress()
    this.progressByFileMap.forEach((value) => {
      if (!value.ended) value.paused = false;
    });
    this.paused = !this.paused;
    for (const uploader of this.uploaders) {
      uploader.togglePause();
    }
  }
  stopUpload() {
    if (!this.aborted) {
      this.aborted = true;
      if (!this.paused) {
        this.togglePauseUploader();
      }
      if (this.worker) {
        this.worker.terminate();
      }
    }
  }
  addWorker(worker: Worker) {
    this.worker = worker;
  }

  // This function have to be called on each progress event of the uploader
  // It updates the progress count percentage, but also registered if each uploader have finish its last chunk
  // If so, we can consider all uploaders as trully paused and fire the 'pauseEvent'
  updateProgress(guid: string, detail: number) {
    this.progressByFileMap.set(guid, { detail, paused: true, ended: detail == 100 });
    this.fireAllPausedEvent();
  }
  fireAllPausedEvent() {
    let allPaused: boolean = true;
    for (const data of this.progressByFileMap) {
      allPaused = allPaused && (data[1].paused || data[1].ended);
    }
    if (this.paused && allPaused) {
      this.pauseEvent.emit();
    }
  }
  getProgress(): number {
    let totalPercentage = 0;
    for (let progressDetail of this.progressByFileMap.values()) {
      totalPercentage += progressDetail.detail;
    }
    return totalPercentage / this.numberOfFiles;
  }
  isUploadBegun(): boolean {
    return this.progressByFileMap.size != 0;
  }
}

@Injectable({
  providedIn: 'root',
})
export class PhotogrammetryUploadService {
  private uploadProgressSubject = new Subject<UploadProgress>();
  uploadProgressObs = this.uploadProgressSubject.asObservable();
  uploadsInProgress: UploadProgress[] = [];

  // POST api/v1/organization/:organizationId/media/intervention/:interventionId/photogrammetry/upload/first/:projectName

  // POST  api/v1/organization/:organizationId/media/:mediaId/intervention/:interventionId/photogrammetry/upload

  // POST  api/v1/organization/:organizationId/media/:mediaId/intervention/:interventionId/photogrammetry/upload/last/:haveLast

  constructor(
    private guidService: GuidService,
    private readonly authenticationStoreService: AuthenticationStoreService,
    private readonly beClient: BackendClientService,
    private readonly loggerService: LoggerService
  ) {}

  getUploadFirstFileUrl(organizationId, interventionId, projectName): string {
    return `api/v1/organization/${organizationId}/media/intervention/${interventionId}/photogrammetry/upload/first/${projectName}`;
  }

  getUploadFileUrl(organizationId, interventionId, mediaId): string {
    return `api/v1/organization/${organizationId}/media/${mediaId}/intervention/${interventionId}/photogrammetry/upload`;
  }

  getSendMediaUrl(organizationId, interventionId, mediaId): string {
    return `api/v1/organization/${organizationId}/media/${mediaId}/intervention/${interventionId}/photogrammetry`;
  }

  getUploadLastFileUrl(organizationId, interventionId, mediaId): string {
    return `api/v1/organization/${organizationId}/media/${mediaId}/intervention/${interventionId}/photogrammetry/upload/last/`;
  }

  getAbortUploadUrl(organizationId, interventionId): string {
    return `api/v1/organization/${organizationId}/media/intervention/${interventionId}/photogrammetry/upload`;
  }

  getStatusUrl(organizationId, interventionId): string {
    return `api/v1/organization/${organizationId}/media/intervention/${interventionId}/photogrammetry/status`;
  }

  async sendFile(
    file: UploadingFile,
    organizationId: string,
    interventionId: string,
    mediaId: string,
    complete: boolean = true,
    subjects: Subject<void>[],
    uploadProgress?: UploadProgress
  ): Promise<void> {
    file.progress = 0;
    file.guid = this.guidService.generate();

    //prepare an observable, it will emit when the file will be uploaded
    const mediaUploadFinishedSubject: Subject<void> = new Subject();
    const fileUploadFinishedObs: Observable<void> = mediaUploadFinishedSubject.asObservable();
    //invoke HugeUploader (optionnal ?token=${token})
    const token = await this.authenticationStoreService.getAccessToken().pipe(first()).toPromise();
    // We choose a chunk size of 1MB, this is small enough to allow play/pause mechanism
    // That do not impact performances
    // NB : let the chunk size to 1MB, unless you want to remove play/pause/abort mechanism
    const uploader = new HugeUploader({
      endpoint: environment.backend + `${this.getUploadFileUrl(organizationId, interventionId, mediaId)}`,
      headers: { Authorization: `Bearer ${token}` },
      file: file.file,
      postParams: { filename: file.file.name },
      chunkSize: 1,
    });
    uploadProgress?.addUploader(uploader);
    // subscribe to events
    uploader.on('error', (err) => {
      this.loggerService.error(err.detail);
      console.error('[Orthophotos Uploads] - Something bad happened', err.detail);

      this.abortProgressBar(uploadProgress);
      this.onUploadFinished(mediaUploadFinishedSubject, complete);
    });

    uploader.on('progress', (progress) => {
      file.progress = progress.detail;
      this.updateUploadProgress(file, uploadProgress);
    });

    uploader.on('finish', (body) => {
      this.incrementProgressBar(uploadProgress);
      uploadProgress?.removeUploader(uploader);
      // The following line just check if all uploads currently running are actually in 'pause' state
      // If so, then emit the 'AllPaused' Event
      uploadProgress?.fireAllPausedEvent();
      this.onUploadFinished(mediaUploadFinishedSubject, complete, body);
    });
    subjects.push(mediaUploadFinishedSubject);
    return fileUploadFinishedObs.toPromise();
  }

  async sendFiles(
    files: UploadingFile[],
    organizationId: string,
    interventionId: string,
    mediaId: string,
    uploadProgress?: UploadProgress
  ): Promise<void[]> {
    const uploadObservableList: Promise<void>[] = [];
    const subjectList: Subject<void>[] = [];
    for (const file of files) {
      const prom = this.sendFile(file, organizationId, interventionId, mediaId, true, subjectList, uploadProgress);
      uploadObservableList.push(prom);
    }
    return Promise.all(uploadObservableList);
  }

  async finalizeProject(
    organizationId: string,
    interventionId: string,
    mediaId: string,
    options: { mustGenerate3D: boolean }
  ): Promise<void> {
    //send a request indicating all files have already been sent
    return this.beClient
      .post<void>(this.getUploadLastFileUrl(organizationId, interventionId, mediaId), options)
      .toPromise();
  }

  async startProject(organizationId: string, interventionId: string, projectName: string): Promise<any> {
    //send a request indicating we starting a new project
    return this.beClient
      .post<any>(this.getUploadFirstFileUrl(organizationId, interventionId, projectName), {})
      .toPromise();
  }

  async sendMedia(organizationId: string, interventionId: string, mediaId: string, childMedia: string[]): Promise<any> {
    //send a request indicating we starting a new project
    return this.beClient
      .post<any>(this.getSendMediaUrl(organizationId, interventionId, mediaId), { childMedia })
      .toPromise();
  }

  async abortProject(organizationId: string, interventionId: string, mediaId: string): Promise<any> {
    //send a request indicating project have been aborted on user request
    return this.beClient.delete<any>(this.getAbortUploadUrl(organizationId, interventionId), mediaId).toPromise();
  }

  async getStatusProject(organizationId: string, interventionId: string): Promise<TiffStatusMessage[]> {
    //get projects that in creation
    return this.beClient.get<TiffStatusMessage[]>(this.getStatusUrl(organizationId, interventionId)).toPromise();
  }

  startProgressBarStatus(numberOfFiles: number, projectName: string, date: Date): UploadProgress {
    const progress = new UploadProgress(this.guidService.generate(), numberOfFiles, 0, projectName, date);
    this.uploadProgressSubject.next(progress);
    this.uploadsInProgress.push(progress);
    return progress;
  }

  togglePauseProgressBar(uploadProgress: UploadProgress): UploadProgress {
    const progress = this.uploadsInProgress.find((data) => data.uuid === uploadProgress.uuid);
    progress.togglePauseUploader();
    this.uploadProgressSubject.next(progress);
    return progress;
  }

  stopUploadProgressBar(uploadProgress: UploadProgress): UploadProgress {
    const progress = this.uploadsInProgress.find((data) => data.uuid === uploadProgress.uuid);
    progress.stopUpload();
    this.uploadProgressSubject.next(progress);
    this.uploadsInProgress = this.uploadsInProgress.reduce((acc, data) => {
      if (data.uuid !== uploadProgress.uuid) {
        acc.push(data);
      }
      return acc;
    }, []);
    return progress;
  }

  private incrementProgressBar(uploadProgress: UploadProgress): UploadProgress {
    if (uploadProgress) {
      uploadProgress.numberOfUploadedFiles++;
      this.uploadProgressSubject.next(uploadProgress);
      if (uploadProgress.isComplete()) {
        this.uploadsInProgress = this.uploadsInProgress.reduce((acc, data) => {
          if (data.uuid !== uploadProgress.uuid) {
            acc.push(data);
          }
          return acc;
        }, []);
      } else {
        this.uploadsInProgress = this.uploadsInProgress.map((data) =>
          data.uuid === uploadProgress.uuid ? uploadProgress : data
        );
      }
    }
    return uploadProgress;
  }

  private updateUploadProgress(file: UploadingFile, uploadProgress: UploadProgress) {
    if (uploadProgress) {
      uploadProgress.updateProgress(file.guid, file.progress);
      if (file.progress != 100) {
        this.uploadProgressSubject.next(uploadProgress);
        this.uploadsInProgress = this.uploadsInProgress.map((data) =>
          data.uuid === uploadProgress.uuid ? uploadProgress : data
        );
      }
    }
    return uploadProgress;
  }

  private abortProgressBar(uploadProgress: UploadProgress): UploadProgress {
    if (uploadProgress) {
      uploadProgress.aborted = true;
      uploadProgress.numberOfUploadedFiles++;
      this.uploadProgressSubject.next(uploadProgress);
    }
    return uploadProgress;
  }

  private onUploadFinished(subject: Subject<any>, complete: boolean, body?: any) {
    console.info('[Orthophotos Uploads] - Upload complete with message : ', { body });
    if (body?.mediaId) {
      subject.next(body?.mediaId);
    } else {
      subject.next(null);
    }

    if (complete) {
      subject.complete();
    }
  }
}
