import { Component, Input } from '@angular/core';

@Component({
  selector: 'tdraw-tools-panel-tab',
  templateUrl: './tools-panel-tab.component.html',
  styleUrls: ['./tools-panel-tab.component.scss'],
})
export class ToolsPanelTabComponent {
  @Input('title')
  title: string;

  @Input('fa-icon')
  faIcon: string;

  @Input()
  active = false;
}
