import { Intervention } from 'src/app/autogenerated/model';

export class UploadingFile {
  file: File;
  progress: number;
  guid: string;
  intervention: Intervention;
  loadingError: boolean;

  constructor({ file, progress, guid, intervention, loadingError }: Partial<UploadingFile> = {}) {
    this.file = file;
    this.progress = progress;
    this.guid = guid;
    this.intervention = intervention;
    this.loadingError = loadingError;
  }
}
