import { Component, Inject, OnInit } from '@angular/core';
import { OrganizationDistributionListService, OrganizationDto } from 'src/api';
import { MID_RIGHT_PANEL_DATA } from 'src/app/midgard-controls/services/dom.service';
import { MidRightPanelService } from 'src/app/midgard-controls/services/mid-right-panel.service';
import { isEmpty } from 'lodash';

export class NewOrganizationDistributionListComponentInput {
  org: OrganizationDto;
}

@Component({
  selector: 'app-new-organization-distribution-list',
  templateUrl: './new-organization-distribution-list.component.html',
  styleUrls: ['./new-organization-distribution-list.component.scss'],
})
export class NewOrganizationDistributionListComponent implements OnInit {
  name: string;

  constructor(
    @Inject(MID_RIGHT_PANEL_DATA) public data: NewOrganizationDistributionListComponentInput,
    private readonly midRightPanelService: MidRightPanelService,
    private readonly organizationDistributionListService: OrganizationDistributionListService
  ) {
    this.name = '';
  }

  get isEmpty() {
    return isEmpty;
  }

  ngOnInit(): void {}

  async createList() {
    const newList = await this.organizationDistributionListService
      .orgOrganizationDistributionListControllerCreate({
        name: this.name,
        organization: this.data.org.id,
      })
      .toPromise();
    this.midRightPanelService.close(newList);
  }
}
