import { Intervention } from '../../autogenerated/model';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { InterventionType } from 'src/app/autogenerated/model';
import { InterventionService } from 'src/app/autogenerated/interventionService';
import { ContextPermissionsDto, InterventionService as InterventionServiceV2 } from 'src/api';
import { InterventionStoreService } from '../store';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PromiseUtils } from 'src/app/core/utils/promise-utils';
import { coreConfig } from 'src/app/core/core.config';
import { interventionConfig } from 'src/app/intervention/intervention.config';
import { ConfirmModalService } from 'src/app/midgard-controls/services/confirm-modal.service';

@Component({
  selector: 'app-intervention-card',
  templateUrl: './intervention-card.component.html',
  styleUrls: ['./intervention-card.component.scss'],
})
export class InterventionCardComponent implements OnChanges {
  @Input()
  intervention: Intervention;

  @Input()
  numberDrones = 0;

  @Input()
  numberMedia = 0;

  @Output()
  associateWithDroneEvent: EventEmitter<any>;

  permissions: ContextPermissionsDto;

  get InterventionType() {
    return InterventionType;
  }

  constructor(
    private interventionService: InterventionService,
    private interventionServiceV2: InterventionServiceV2,
    private interventionStoreService: InterventionStoreService,
    private dialog: MatDialog,
    private router: Router,
    private confirmModalService: ConfirmModalService
  ) {
    this.associateWithDroneEvent = new EventEmitter();
  }
  async ngOnChanges(changes: SimpleChanges) {
    if (this.intervention) {
      this.permissions = await this.interventionServiceV2
        .interventionsControllerGetPermissions(this.intervention._id)
        .toPromise();
    }
  }

  getInterventionDashboardRoute(interventionId: string): string[] {
    if (!interventionId) {
      return;
    }
    return ['/', 'interventions', interventionId];
  }

  getDownloadPath(interventionId: string) {
    return '';
  }

  goToMap() {
    this.router.navigate([`interventions/${this.intervention._id}/${interventionConfig.routes.tdraw}/home`]);
  }

  editIntervention() {
    this.router.navigate([`interventions/${this.intervention._id}/${interventionConfig.routes.settings}`]);
  }

  associateWithDrone() {
    this.associateWithDroneEvent.emit();
  }

  importMedia() {
    this.router.navigate([`interventions/${this.intervention._id}/${interventionConfig.routes.media}`]);
  }

  async onCloseIntervention() {
    const isCloseInterventionConfirmed = await this.confirmModalService.showConfirmModal(
      'CLOSE_INTERVENTION_CONFIRM_MESSAGE',
      'validate'
    );
    if (isCloseInterventionConfirmed) {
      await this.closeIntervention();
    }
  }

  async closeIntervention() {
    try {
      const updatedIntervention = await PromiseUtils.setMinDelay(
        this.interventionService.closeIntervention(this.intervention),
        coreConfig.forms.minRefreshTimeout
      );

      this.interventionStoreService.updateIntervention(this.intervention.organization.toString(), updatedIntervention);
      this.intervention = updatedIntervention;
    } catch (ex) {
      console.log(ex);
    }
  }
}
