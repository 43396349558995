import { Breadcrumb } from './models/breadcrumb/breadcrumb.model';

export const layoutConfig = {
  routes: {
    settings: 'settings',
  },
  store: {
    key: 'layout',
  },
  initialBreadcrumb: {
    label: 'BREADCRUMBS.HOME',
    url: '/',
    icon: 'fa-home',
  } as Breadcrumb,
};
