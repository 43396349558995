export interface GroupedItemsData<T> {
  key: Date;
  value: T[];
}

export class DataSource<T> {
  allItems: T[];
  filteredItems: T[];
  paginatedItems: T[];
  groupedItems?: GroupedItemsData<T>[];

  constructor({ allItems, filteredItems, paginatedItems }: Partial<DataSource<T>> = {}) {
    this.allItems = allItems || [];
    this.filteredItems = filteredItems || this.allItems || [];
    this.paginatedItems = paginatedItems || this.filteredItems || [];
    this.groupedItems = [];
  }
}
