<div class="properties-container">
  <form name="formUpdateOrganization" #formUpdateOrganization="ngForm" novalidate>
    <div class="row m-0">
      <section class="section-form-group col-12">
        <h2 class="section-header">{{ 'ORGANIZATION_SETTINGS.SECTION_INFO' | translate }}</h2>
        <div class="row">
          <div class="col-md-6">
            <div class="row">
              <app-mid-input
                class="col-md-12"
                size="wide"
                #organizationNameField="appMidInput"
                name="organizationNameField"
                [label]="'ORGANIZATION_SETTINGS.NAME_LABEL' | translate"
                [placeholder]="'ORGANIZATION_SETTINGS.NAME_PLACEHOLDER' | translate"
                [(value)]="organizationName"
                [required]="true"
                [disabled]="!canUpdate"
              >
                <span
                  *ngIf="!formUpdateOrganization.pristine && organizationNameField.invalid"
                  class="warning-input-text"
                  >{{ 'COMMON_REQUIRED_FIELD' | translate }}</span
                >
              </app-mid-input>
              <div class="col-md-auto logo-container">
                <label>{{ 'ORGANIZATION_SETTINGS.LOGO_LABEL' | translate }}</label>
                <div class="logo">
                  <img *ngIf="organizationLogoUrl" class="img" [src]="organizationLogoUrl" alt="" />
                </div>
                <app-mid-button *ngIf="canUpdate" type="secondary" size="wide" (action)="onOpenChangeLogo()">
                  {{ 'ORGANIZATION_SETTINGS.CHANGE_LOGO' | translate }}
                </app-mid-button>
              </div>
              <app-mid-select-lang
                *ngIf="org"
                class="col-md language-container"
                size="wide"
                [(items)]="languages"
                #languageModel="appMidSelectLang"
                name="languageModel"
                [required]="true"
                [label]="'ORGANIZATION_SETTINGS.LANGUAGE_LABEL' | translate"
                [(selectedItem)]="org.defaultLanguage"
                [disabled]="!canUpdate"
              >
                <span *ngIf="!formUpdateOrganization.pristine && languageModel.invalid">{{
                  'COMMON_REQUIRED_FIELD' | translate
                }}</span>
                <span *ngIf="state.isFailed">{{ 'UNEXPECTED_ERROR' | translate }}</span>
              </app-mid-select-lang>
            </div>
          </div>
          <div class="col-md-6 map-container">
            <tdraw-location-search-bar
              [(value)]="orgLocation.address"
              (onItemSelected)="onLocationSelected($event)"
              [disabled]="!canUpdate"
              [label]="'ORGANIZATION_SETTINGS.LOCATION_LABEL' | translate"
            ></tdraw-location-search-bar>
            <div id="org-location-map" class="map"></div>
            <div class="map-info mt-2">{{ 'ORGANIZATION_SETTINGS.LOCATION_INFO' | translate }}</div>
          </div>
        </div>
      </section>
    </div>
    <section class="section-form-group col-md-12" *ngIf="canUpdate">
      <div class="row mt-3 mb-2">
        <div class="col-12">
          <app-mid-button
            class="pr-2"
            type="primary"
            [disabled]="!canUpdate || formUpdateOrganization.invalid || formUpdateOrganization.pristine"
            (action)="saveChanges()"
            [ngClass]="{
              'mid-in-saving': state.isLoading,
              'mid-saved': state.isUpdated,
              'mid-error': state.isFailed
            }"
          >
            <i *ngIf="state.isLoading" class="fa fa-sync-alt fa-spin pr-1"></i>
            <i *ngIf="state.isFailed" class="fa fa-exclamation-triangle pr-1"></i>
            <i *ngIf="state.isUpdated" class="fa fa-check pr-1"></i>
            {{ 'SAVE' | translate }}
          </app-mid-button>
          <app-mid-button class="pr-2" type="secondary" (action)="revertChanges()">
            {{ 'CANCEL_MODIFICATION' | translate }}
          </app-mid-button>
        </div>
      </div>
    </section>
  </form>
</div>
