import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'tdraw-rename-version-modal',
  templateUrl: './rename-version-modal.component.html',
  styleUrls: ['./rename-version-modal.component.scss'],
})
export class RenameVersionModalComponent {
  name: string;

  constructor(public dialogRef: MatDialogRef<RenameVersionModalComponent>) {}

  onSubmit() {
    this.dialogRef.close(this.name);
  }

  onClose() {
    this.dialogRef.close();
  }
}
