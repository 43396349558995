import { NgModule, InjectionToken } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { StoreStateService, featureStoreConfigFactory } from 'src/app/core/store';
import { StoreConfig } from '@ngrx/store/src/store_module';
import { droneInitialState, droneReducer, DroneState } from '.';

const featureStoreKey = 'drones';

export const DroneFeatureStoreKeyToken = new InjectionToken(`FeatureStoreKeyInjectionToken:${featureStoreKey}`);
export const DroneFeatureStoreInitialStateToken = new InjectionToken<DroneState>(
  `FeatureStoreInitialStateInjectionToken:${featureStoreKey}`
);
export const DroneStoreFeatueConfigToken = new InjectionToken<StoreConfig<DroneState>>(
  `FeatureStoreConfigInjectionToken:${featureStoreKey}`
);

@NgModule({
  imports: [StoreModule.forFeature(featureStoreKey, droneReducer, DroneStoreFeatueConfigToken)],
  providers: [
    {
      provide: DroneFeatureStoreKeyToken,
      useValue: featureStoreKey,
    },
    {
      provide: DroneFeatureStoreInitialStateToken,
      useValue: droneInitialState,
    },
    {
      provide: DroneStoreFeatueConfigToken,
      deps: [DroneFeatureStoreKeyToken, DroneFeatureStoreInitialStateToken, StoreStateService],
      useFactory: featureStoreConfigFactory,
    },
  ],
})
export class DroneStoreModule {}
