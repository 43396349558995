import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MidTooltipPosition } from './mid-tooltip-position.enum';

@Component({
  selector: 'app-mid-tooltip',
  templateUrl: './mid-tooltip.component.html',
  styleUrls: ['./mid-tooltip.component.scss'],
})
export class MidTooltipComponent implements OnInit {
  @Input() text = '';
  @Input() position = MidTooltipPosition.bottom;

  parentPosition: DOMRect = null;
  @ViewChild('midTooltip', { static: true }) midTooltip: ElementRef;

  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      this.setTooltipPointer();
    }, 0);
  }

  // ngAfterViewInit(): void {
  //   this.setTooltipPointer();
  // }

  private setTooltipPointer() {
    if (this.parentPosition) {
      const position: DOMRect = this.midTooltip.nativeElement.getBoundingClientRect();
      // console.log('parent and midTooltip positions: ', this.parentPosition, position);
      switch (this.position) {
        case MidTooltipPosition.bottom:
        case MidTooltipPosition.top:
          const translateX =
            this.parentPosition.left + this.parentPosition.width / 2 - (position.left + position.width / 2);
          // console.log('translateX = ', translateX);
          this.midTooltip.nativeElement.style.setProperty('--translateX', translateX + 'px');
          this.midTooltip.nativeElement.style.setProperty('--display', 'block');
          break;
        case MidTooltipPosition.left:
        case MidTooltipPosition.right:
          const translateY =
            this.parentPosition.top + this.parentPosition.height / 2 - (position.top + position.height / 2);
          // console.log('translateY = ', translateY);
          this.midTooltip.nativeElement.style.setProperty('--translateY', translateY + 'px');
          this.midTooltip.nativeElement.style.setProperty('--display', 'block');
          break;
        default:
          break;
      }
    }
  }
}
