<div class="cursor-pointer" (click)="chooseIntervention($event)">
  <div class="mx-1 dashed" *ngIf="!intervention">
    <button *ngIf="!isLinkButtonType" class="btn btn-sm btn-secondary pr-2 pl-2 mr-2 mid-btn-active">
      {{ 'CHOOSE_INTERVENTION' | translate }}
    </button>

    <span *ngIf="isLinkButtonType">
      {{ 'CHOOSE_INTERVENTION' | translate }}
    </span>
  </div>

  <span class="mx-1 dashed" *ngIf="interventionId && intervention">{{ intervention?.name }}</span>
</div>
