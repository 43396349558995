import { Component, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import _, { isEmpty } from 'lodash';
import { Subscription } from 'rxjs';
import {
  AccessCodeDto,
  AccessCodeDtoWithInterventions,
  AccessCodeService,
  OrganizationDto,
  OrganizationsService,
} from 'src/api';
import { Unsubscribe } from 'src/app/core/decorators';
import { MidRightPanelService } from 'src/app/midgard-controls/services/mid-right-panel.service';
import { AccessCodeQrComponent } from '../access-code-qr/access-code-qr.component';
import { NewAccessCodeComponent, NewAccessCodeComponentData } from '../new-access-code/new-access-code.component';
import { Location } from '@angular/common';
import { authenticationConfig } from 'src/app/authentication/authentication.config';
import { DOCUMENT } from '@angular/common';

@Unsubscribe()
@Component({
  selector: 'app-access-code',
  templateUrl: './access-code.component.html',
  styleUrls: ['./access-code.component.scss'],
})
export class AccessCodeComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  org: OrganizationDto;
  accessCodes: AccessCodeDtoWithInterventions[] = [];
  private newSubscription: Subscription;

  state = {
    isLoading: true,
    visibleAccessCodes: new Set<string>(),
  };

  constructor(
    private readonly organizationsService: OrganizationsService,
    private readonly midRightPanelService: MidRightPanelService,
    private readonly accessCodeService: AccessCodeService,
    private readonly location: Location,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  ngOnDestroy(): void {}
  async ngOnChanges(changes: SimpleChanges) {
    if (this.org) {
      await this.loadAccessCode();
    }
  }

  private async loadAccessCode() {
    this.state.isLoading = true;
    this.accessCodes = await this.organizationsService.orgAccessCodeControllerGetOrgAccessCode(this.org.id).toPromise();
    this.state.isLoading = false;
  }

  async ngOnInit() {}

  async onAddAccessCodeClick() {
    const data: NewAccessCodeComponentData = { org: this.org };
    this.newSubscription = this.midRightPanelService.open(NewAccessCodeComponent, data).subscribe(async (result) => {
      if (!isEmpty(result.data)) {
        await this.loadAccessCode();
      }
    });
  }

  showCodes(accessCode: AccessCodeDto) {
    this.state.visibleAccessCodes.add(accessCode.id);
  }
  hideCodes(accessCode: AccessCodeDto) {
    this.state.visibleAccessCodes.delete(accessCode.id);
  }
  showQrCode(accessCode: AccessCodeDto) {
    const base = this.document.location.origin;
    const qrUrl =
      base +
      this.location.prepareExternalUrl('/' + authenticationConfig.routes.loginAccessCode) +
      `?code=${accessCode.code}`;
    this.midRightPanelService.open(AccessCodeQrComponent, { link: qrUrl });
  }
  async delete(accessCode: AccessCodeDto) {
    await this.accessCodeService.accessCodeControllerDelete(accessCode.id).toPromise();
    this.accessCodes = _.filter(this.accessCodes, (x) => x.id !== accessCode.id);
  }
}
