<div class="chip-list-container">
  <mat-form-field class="chip-list" appearance="none">
    <!-- <mat-label *ngIf="!!label">{{ label }}</mat-label> -->
    <mat-chip-list #chipList [class.mat-chip-list-stacked]="verticalOrientation" aria-label="chip-list">
      <mat-chip
        *ngFor="let value of values"
        class="chip-item"
        [selectable]="selectable"
        [removable]="removable"
        (removed)="remove(value)"
        [disableRipple]="true"
      >
        <i class="chip-icon fas" [ngClass]="[iconType === IconType.drone ? 'fa-helicopter' : 'fa-tag']"> </i>
        {{ valueName(value) }}
        <i class="fas fa-times" matChipRemove *ngIf="removable"></i>
      </mat-chip>
      <div class="chip-add">
        <i class="fas fa-plus"></i>
        <input
          [(ngModel)]="chipInputValue"
          #chipInput="ngModel"
          [name]="name"
          [placeholder]="placeholder"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="true"
          (matChipInputTokenEnd)="add($event)"
          [matAutocomplete]="auto"
          [matAutocompleteDisabled]="!allValues?.length"
        />
      </div>
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option *ngFor="let chip of filteredChips | async" [value]="chip.value">
        {{ chip.name }}
      </mat-option>
    </mat-autocomplete>
    <ng-content></ng-content>
  </mat-form-field>
</div>
