import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import _ from 'lodash';
import { AnnotationCategoryLangkey } from 'src/app/tdraw/models/annotation-category.enum';
import { AnnotationType } from 'src/app/tdraw/models/annotation-type.enum';
import { MapAnnotationNestedDto, MapStateNestedStatisticsDto } from 'src/app/tdraw/models/dto';
import { MapIcon } from 'src/app/tdraw/models/map-icon';
import { MapIconsService } from 'src/app/tdraw/services/map-icons.service';
import { MapModeManager } from 'src/app/tdraw/misc/map-mode-manager';
import { Subscription } from 'rxjs';
import { Unsubscribe } from 'src/app/core/decorators';

// Classes to handle UI, only used by this component
class Group {
  name: string;
  category: string; // Refers to AnnotationCategory
  items: Item[];

  constructor({ name, category, items }: Partial<Group> = {}) {
    this.name = name;
    this.category = category;
    this.items = items;
  }
}
class Item extends MapAnnotationNestedDto {
  icon?: MapIcon;
  label?: string;
}

@Unsubscribe()
@Component({
  selector: 'tdraw-layers-tab',
  templateUrl: './layers-tab.component.html',
  styleUrls: ['./layers-tab.component.scss'],
})
export class LayersTabComponent implements OnInit, OnDestroy {
  @Input()
  mapModeManager: MapModeManager;

  @Input()
  statistics: MapStateNestedStatisticsDto;

  private annotations: MapAnnotationNestedDto[];
  groups: Group[];
  expandedGroups: { [category: string]: boolean } = {};
  visibleGroups: { [category: string]: boolean } = {};
  visibleItems: { [sourceId: string]: boolean } = {};

  // Subscriptions
  private featuresChangedSubscription: Subscription;

  get AnnotationType() {
    return AnnotationType;
  }

  constructor(private mapIconsService: MapIconsService) {}

  ngOnDestroy(): void {}

  ngOnInit(): void {
    if (!this.statistics) {
      this.statistics = {
        victims: [],
        engagedResources: [],
        engagedStaff: [],
        otherServices: [],
      };
    }

    this.annotations = this.mapModeManager.getMapStateAnnotations();
    this.onAnnotationsChanged();
    this.featuresChangedSubscription = this.mapModeManager.onFeaturesChanged((annotations) => {
      this.annotations = annotations;
      this.onAnnotationsChanged();
    });
  }

  /**
   * Use this function to update internal references of objects
   * in order to refresh or build the Layers panel UI
   */
  onAnnotationsChanged() {
    this.computeLayersForUI();
  }

  onFeatureClick(item: Item) {
    const sourceId = item.sourceId;
    this.setItemVisibility(item, true);
    this.mapModeManager.initSourceSelection(sourceId);
  }

  toggleItemVisibility(item: Item) {
    const newState = !this.visibleItems[item.sourceId];
    this.setItemVisibility(item, newState);
  }

  toggleGroupExpanded(group: Group) {
    const newState = !this.expandedGroups[group.category];
    this.expandedGroups[group.category] = newState;
  }

  toggleGroupVisibility(group: Group) {
    const newState = !this.visibleGroups[group.category];
    for (const item of group.items) {
      this.setItemVisibility(item, newState);
    }
  }

  private setItemVisibility(item: Item, isVisible: boolean) {
    const sourceId = item.sourceId;
    // Disallow changing currently selected feature visibility
    if (this.mapModeManager.selectedSourceId === sourceId) {
      return;
    }

    // Change item and MapboxGL source visibility
    if (isVisible) {
      this.mapModeManager.mapManager.showSource(sourceId);
    } else {
      this.mapModeManager.mapManager.hideSource(sourceId);
    }
    this.visibleItems[sourceId] = isVisible;

    // Change group visibility according to its items visibility
    const group = this.groups.find((e) => e.category === item.category);
    for (const item of group.items) {
      if (this.mapModeManager.selectedSourceId === item.sourceId) {
        continue;
      }
      if (this.visibleItems[item.sourceId]) {
        this.visibleGroups[group.category] = true;
        return;
      }
    }
    this.visibleGroups[group.category] = false;
  }

  private async computeLayersForUI() {
    const categorizedAnnotations = _.groupBy(
      this.annotations,
      (annotation: MapAnnotationNestedDto) => annotation.category
    );
    this.groups = [];

    for (const category in categorizedAnnotations) {
      // Create a Group of Items (Annotations)
      const items: Item[] = categorizedAnnotations[category];
      const group = new Group({
        name: AnnotationCategoryLangkey[category],
        category: category,
        items: items,
      });

      // If the group is newly added, set it expanded
      const isExpanded = this.expandedGroups[category];
      if (isExpanded === undefined) {
        this.expandedGroups[group.category] = true;
      }
      // By default flag the group as NOT visible
      this.visibleGroups[group.category] = false;

      // Loop through each annotation for UI purpose
      for (const item of items) {
        const properties = item.data.features[0].properties;
        const mapIconRef =
          item.type === AnnotationType.Point ? this.mapIconsService.getMapIconReference(properties.icon) : null;
        const sourceId = item.sourceId;

        // If the item is newly added, set it visible
        const isVisible = this.visibleItems[sourceId];
        if (isVisible === undefined || isVisible) {
          // And if any item is visible, flag the group as visible too
          this.visibleGroups[group.category] = true;
          this.visibleItems[item.sourceId] = true;
        }

        // Set additional properties for easier access from UI
        if (mapIconRef) {
          item.icon = this.mapIconsService.getMapIcon(mapIconRef.guiIcon);
        }
        item.label = properties.title;
      }

      // Add the group to the UI
      this.groups.push(group);
    }
  }
}
