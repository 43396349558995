export const OrganizationNavConfiguration = {
  routes: {
    main: '',
    users: 'users',
    groups: 'groups',
    distribution: 'distribution',
    profile: 'me',
    accessCode: 'access-codes',
  },
};
