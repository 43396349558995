<div class="right-panel-section" *ngIf="media">
  <h3 class="section-title">{{ 'MEDIA_RIGHT_PANEL.SECTION.DETAILS' | translate }}</h3>

  <span class="import-info">
    <span>{{ 'MEDIA_RIGHT_PANEL.MEDIA_IMPORTED' | translate }} </span>
    <span>{{ media.importDate }} </span>
    <span>{{ 'AT' | translate }} </span>
    <span>{{ media.importTime }} </span>
  </span>
  <br /><br />

  <p>
    {{ 'MEDIA_RIGHT_PANEL.MEDIA_NAME' | translate }}<br />
    <i class="fas fa-info-circle pr-1 warning-input-text"> </i> {{ media.name }}
  </p>
</div>
