import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsPageComponent } from './settings-page/settings-page.component';
import { CoreModule } from 'src/app/core/core.module';
import { FormsModule } from '@angular/forms';
import { MidgardControlsModule } from 'src/app/midgard-controls/midgard-controls.module';
import { InterventionCommonModule } from '../../common/intervention-common.module';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [SettingsPageComponent],
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    MidgardControlsModule,
    InterventionCommonModule,
    MatIconModule,
    MatTooltipModule,
    MatCheckboxModule,
  ],
  exports: [SettingsPageComponent],
})
export class InterventionPageSettingsModule {}
